import { useDispatch, useSelector } from 'react-redux';
import { setSidebar } from '../redux/actions/uxActions';

const useSidebar = () => {
  const dispatch = useDispatch();
  const { collapsed } = useSelector((state) => state.ux.sidebar);

  const setCollapsed = (collapsed) => {
    dispatch(setSidebar(collapsed));
  };

  return { setCollapsed, collapsed };
};

export default useSidebar;
