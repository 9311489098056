const DuplicateIcon = ({ className = '', fill = '#4bafe1', width = '18', height = '18' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3125 0H5.0625C4.13051 0 3.375 0.755508 3.375 1.6875V3.375H1.6875C0.755508 3.375 0 4.13051 0 5.0625V16.3125C0 17.2445 0.755508 18 1.6875 18H12.9375C13.8695 18 14.625 17.2445 14.625 16.3125V14.625H16.3125C17.2445 14.625 18 13.8695 18 12.9375V1.6875C18 0.755508 17.2445 0 16.3125 0ZM12.7266 16.3125H1.89844C1.84249 16.3125 1.78884 16.2903 1.74928 16.2507C1.70972 16.2112 1.6875 16.1575 1.6875 16.1016V5.27344C1.6875 5.21749 1.70972 5.16384 1.74928 5.12428C1.78884 5.08472 1.84249 5.0625 1.89844 5.0625H3.375V12.9375C3.375 13.8695 4.13051 14.625 5.0625 14.625H12.9375V16.1016C12.9375 16.1575 12.9153 16.2112 12.8757 16.2507C12.8362 16.2903 12.7825 16.3125 12.7266 16.3125ZM16.1016 12.9375H5.27344C5.21749 12.9375 5.16384 12.9153 5.12428 12.8757C5.08472 12.8362 5.0625 12.7825 5.0625 12.7266V1.89844C5.0625 1.84249 5.08472 1.78884 5.12428 1.74928C5.16384 1.70972 5.21749 1.6875 5.27344 1.6875H16.1016C16.1575 1.6875 16.2112 1.70972 16.2507 1.74928C16.2903 1.78884 16.3125 1.84249 16.3125 1.89844V12.7266C16.3125 12.7825 16.2903 12.8362 16.2507 12.8757C16.2112 12.9153 16.1575 12.9375 16.1016 12.9375Z"
      fill={fill}
    />
  </svg>
);

export default DuplicateIcon;
