const Icon4 = ({ color = '#BCBCBC' }) => (
  <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.82038 0C8.65007 0 9.32038 0.670312 9.32038 1.5V3.17344C9.39538 3.18281 9.46569 3.19219 9.54069 3.20625C9.55944 3.21094 9.57351 3.21094 9.59226 3.21562L11.8423 3.62813C12.6579 3.77813 13.1969 4.56094 13.0469 5.37187C12.8969 6.18281 12.1141 6.72656 11.3032 6.57656L9.07663 6.16875C7.60944 5.95313 6.31569 6.09844 5.40632 6.45938C4.49694 6.82031 4.13132 7.31719 4.04694 7.77656C3.95319 8.27812 4.0235 8.55937 4.10319 8.73281C4.18757 8.91563 4.36101 9.12187 4.70319 9.35156C5.46726 9.85312 6.63913 10.1813 8.15788 10.5844L8.29382 10.6219C9.63444 10.9781 11.2751 11.4094 12.4938 12.2062C13.1594 12.6422 13.7876 13.2328 14.1766 14.0578C14.5751 14.8969 14.6594 15.8344 14.4766 16.8328C14.1532 18.6141 12.9251 19.8047 11.4016 20.4281C10.7594 20.6906 10.061 20.8594 9.32038 20.9437V22.5C9.32038 23.3297 8.65007 24 7.82038 24C6.99069 24 6.32038 23.3297 6.32038 22.5V20.8641C6.30163 20.8594 6.27819 20.8594 6.25944 20.8547H6.25007C5.10632 20.6766 3.22663 20.1844 1.96101 19.6219C1.20632 19.2844 0.86413 18.3984 1.20163 17.6437C1.53913 16.8891 2.42507 16.5469 3.17976 16.8844C4.15944 17.3203 5.77194 17.7516 6.70475 17.8969C8.20007 18.1172 9.43288 17.9906 10.2673 17.6484C11.0594 17.325 11.4204 16.8563 11.5235 16.2938C11.6126 15.7969 11.5423 15.5109 11.4626 15.3375C11.3735 15.15 11.2001 14.9437 10.8532 14.7141C10.0844 14.2125 8.90788 13.8844 7.38444 13.4813L7.25319 13.4484C5.91726 13.0922 4.27663 12.6562 3.05788 11.8594C2.39226 11.4234 1.76882 10.8281 1.37976 10.0031C0.986005 9.16406 0.906318 8.22656 1.09382 7.22812C1.43132 5.4375 2.77194 4.275 4.29538 3.67031C4.91882 3.42188 5.60319 3.25313 6.32038 3.15469V1.5C6.32038 0.670312 6.99069 0 7.82038 0Z"
      fill={color}
    />
  </svg>
);

export default Icon4;
