const SearchCloudDownload = ({ fill }) => (
  <svg width="35" height="24" viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.8117 10.425C29.0314 9.85179 29.1546 9.225 29.1546 8.57143C29.1546 5.73214 26.851 3.42857 24.0117 3.42857C22.9564 3.42857 21.9706 3.75 21.1564 4.29643C19.6724 1.725 16.9028 0 13.726 0C8.99029 0 5.15458 3.83571 5.15458 8.57143C5.15458 8.71607 5.15993 8.86071 5.16529 9.00536C2.16529 10.0607 0.0117188 12.9214 0.0117188 16.2857C0.0117188 20.5446 3.46708 24 7.72601 24H27.4403C31.2278 24 34.2974 20.9304 34.2974 17.1429C34.2974 13.8268 31.9403 11.0571 28.8117 10.425ZM21.6921 15.1768L16.0456 20.8232C15.7135 21.1554 15.1671 21.1554 14.8349 20.8232L9.18851 15.1768C8.64743 14.6357 9.03315 13.7143 9.79386 13.7143H13.2974V7.71429C13.2974 7.24286 13.6831 6.85714 14.1546 6.85714H16.726C17.1974 6.85714 17.5831 7.24286 17.5831 7.71429V13.7143H21.0867C21.8474 13.7143 22.2332 14.6357 21.6921 15.1768Z"
      fill={fill}
    />
  </svg>
);

export default SearchCloudDownload;
