import { useEffect, useState } from 'react';

const useOnScreen = (ref, rootMargin = '0px', toggle, rendered = true) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) setIntersecting(true);
        if (!entry.isIntersecting && toggle) setIntersecting(false);
      },
      {
        rootMargin,
      }
    );
    if (ref?.current && rendered) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [ref?.current, rendered]);

  return isIntersecting;
};

export default useOnScreen;
