import React from 'react';
import { Chart as ChartJS, ArcElement, Legend, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { pieChartColors } from '../../../constants/colors';
import './PieChart.scss';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartWithLabels = ({ values }) => {
  const filteredValues = values.filter((entry) => entry.val > 0);
  const isSingleValue = filteredValues.length === 1;
  const chartData = {
    labels: [],
    datasets: [
      {
        data: values.map((entry) => entry.val),
        backgroundColor: values.map((_, index) => pieChartColors[index]),
        borderWidth: 0,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const sum = ctx.chart.data.datasets[0].data.reduce((acc, cur) => acc + cur);
          const percentage = `${((value * 100) / sum).toFixed(0)}%`;
          return value > 0 ? percentage : '';
        },
        color: 'white',
        font: {
          family: 'Montserrat',
          size: '18',
          weight: '600',
        },
        ...(isSingleValue
          ? {
              anchor: 'center',
              align: 'start',
              offset: 19,
            }
          : {}),
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    events: [],
  };

  return (
    <div className="pie-chart-container">
      <Pie
        data={chartData}
        options={chartOptions}
        plugins={[ChartDataLabels]}
        width={150}
        height={150}
      />
    </div>
  );
};
export default PieChartWithLabels;
