import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import DropdownSelect from '../../DropdownSelect/DropdownSelect';
import RowAnswer from '../../RowAnswer/RowAnswer';
import { t } from '../../../i18n/i18n';
import { AnswerTypeEnum, PrefilterNumberConditions } from '../../../constants/enums';

const emptyQuestion = {
  text: '',
  title: '',
  answerType: 'RADIO',
  type: 'PRE_FILTER',
  weight: 1,
  hasGlobalScore: true,
  options: [
    {
      text: '',
      correct: false,
      formula: '',
    },
    {
      text: '',
      correct: false,
      formula: '',
    },
  ],
};

export default function PrefilterQuestionRow({
  handleSubmit,
  editQuestion,
  closeEditor,
  handleEdit,
}) {
  const [question, setQuestion] = useState(emptyQuestion);
  const [answerType, setAnswerType] = useState(emptyQuestion.answerType);
  const [options, setOptions] = useState(question.options);
  const [disabledAdd, setDisabledAdd] = useState(false);

  const handleChange = (field, index, value) => {
    switch (field) {
      case 'question':
        setQuestion({
          ...question,
          text: value,
        });
        break;
      case 'answerType':
        setQuestion({
          ...question,
          answerType: value,
        });
        break;
      case 'text':
      case 'condition':
      case 'correct': {
        const copyOptions = [...question.options];
        if (field === 'correct' && value) {
          copyOptions.forEach((item) => {
            item.weight = 0;
            item.correct = false;
          });
        }
        copyOptions[index] = {
          ...copyOptions[index],
          [field]: value,
          weight: field === 'correct' && value ? 1 : 0,
          formula: '',
        };
        setQuestion({
          ...question,
          options: copyOptions,
        });
        setOptions(copyOptions);
        break;
      }
      default:
        break;
    }
  };

  const addOption = () => {
    if (options.length < 4) {
      const copyOptions = [
        ...options,
        {
          text: '',
          fieldType: '',
          options: [
            {
              text: '',
              correct: '',
              formula: '',
            },
          ],
        },
      ];
      setQuestion({
        ...question,
        options: copyOptions,
      });
      setOptions(copyOptions);
    } else {
      notification.open({ message: t('CREATE_PRE_FILTER_MAX_OPTIONS_REACHED'), type: 'warning' });
    }
  };

  const removeOption = (index) => {
    if (options.length > 1) {
      const newOptions = options.filter((_, i) => i !== index);
      setOptions(newOptions);
    }
  };

  const fieldTypes = [
    {
      id: AnswerTypeEnum.RADIO,
      name: t('CREATE_PRE_FILTER_QUESTION_FIELD_TYPE_MC'),
    },
    {
      id: AnswerTypeEnum.TEXT,
      name: t('CREATE_PRE_FILTER_QUESTION_FIELD_TYPE_NRO'),
    },
  ];

  const numberConditionals = [
    { id: PrefilterNumberConditions.EQUAL, name: t('CREATE_PRE_FILTER_NUMERIC_LIMIT_EQUALS') },
    { id: PrefilterNumberConditions.LESS, name: t('CREATE_PRE_FILTER_NUMERIC_LIMIT_LESS') },
    { id: PrefilterNumberConditions.GREATER, name: t('CREATE_PRE_FILTER_NUMERIC_LIMIT_MORE') },
  ];

  const handleAddQuestion = () => {
    handleSubmit(question);
    setQuestion(emptyQuestion);
    setOptions(emptyQuestion.options);
    setAnswerType(emptyQuestion.answerType);
  };

  const handleEditQuestion = () => {
    handleEdit(question);
    setQuestion(emptyQuestion);
    setOptions(emptyQuestion.options);
    setAnswerType(emptyQuestion.answerType);
  };

  useEffect(() => {
    const disabledForNumber =
      answerType === AnswerTypeEnum.TEXT && !Number(question.options[0].text);
    const disableForEmptyOption =
      answerType === AnswerTypeEnum.RADIO && options.some((opt) => opt.text === '');
    const disableForNotCorrectOption =
      answerType === AnswerTypeEnum.RADIO && !options.some((opt) => opt.correct === true);
    setDisabledAdd(disableForEmptyOption || disableForNotCorrectOption || disabledForNumber);
  }, [answerType, question, options]);

  useEffect(() => {
    if (editQuestion) {
      let optionsFormatted = editQuestion?.options;
      if (editQuestion?.testId && editQuestion.answerType === AnswerTypeEnum.TEXT) {
        optionsFormatted = editQuestion?.acceptedOptions.map((opt) => ({
          text: opt.options[0],
          condition: editQuestion.writeResponse,
        }));
        editQuestion.options = optionsFormatted;
      } else if (editQuestion?.testId && editQuestion.answerType === AnswerTypeEnum.RADIO) {
        optionsFormatted = editQuestion?.acceptedOptions.map((opt) => {
          const option = editQuestion?.options.find((o) => o.id === opt.options[0]);
          return {
            ...option,
            correct: opt.weight === 1,
          };
        });
      }
      setQuestion(editQuestion);
      setOptions(optionsFormatted);
      setAnswerType(editQuestion?.answerType);
    }
  }, [editQuestion]);

  return (
    <div className="container">
      <div className="w-100 col justify-content-center mx-auto border-container my-2 m-3 p-sm-4">
        <div className="row">
          <div className="d-flex justify-content-between w-100 mt-2">
            <span className="font-montserrat font-bold text-muted font-weight-bold text-sm">
              {t('CREATE_PRE_FILTER_QUESTION_TEXT_TITLE')}
            </span>
            <Button
              onClick={(e) => {
                e.preventDefault();
                closeEditor(true);
              }}
              icon={<CloseOutlined />}
              type="link"
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                border: 'none',
              }}
            />
          </div>
          <textarea
            required
            type="text"
            onChange={(e) => handleChange('question', 0, e?.target?.value)}
            name="text"
            value={question.text}
            className="w-100 mt-3 py-2 px-3 font-montserrat maya-blue-thin-border unselected-border outline-none text-xs min-height-add-question"
            rows="3"
            maxLength={5000}
            placeholder={t('CREATE_SCREENING_YOUR_QUESTIONS_MULTIPLE_CHOICE_PLACEHOLDER')}
            data-testid="question-mc-add-question-textarea"
          />
        </div>

        <div className="row my-3">
          <span className="font-montserrat font-bold text-muted font-weight-bold text-sm m-3">
            {t('CREATE_PRE_FILTER_QUESTION_FIELD_TYPE_TITLE')}
          </span>
          <span className="align-items-start">
            <DropdownSelect
              selectedPlaceholder
              alignEnd="justify-content-end"
              className="mr-0 mr-2"
              placeholder={t('CREATE_PRE_FILTER_QUESTION_FIELD_TYPE_PLACEHOLDER')}
              setState={(e) => {
                handleChange('answerType', 0, e?.id);
                setAnswerType(e?.id);
              }}
              selected={answerType}
              options={fieldTypes}
            />
          </span>
        </div>

        <div className="row my-2">
          {answerType === AnswerTypeEnum.RADIO && (
            <div className="w-100 mx-3">
              <span className="font-montserrat font-bold text-muted font-weight-bold text-sm m-3">
                {t('CREATE_PRE_FILTER_QUESTIONS_MC_TITLE')}
              </span>
              {options.map((option, index) => (
                <div key={index} className="w-100 mx-2">
                  <RowAnswer
                    {...option}
                    key={index}
                    index={index}
                    onChange={(field, value) => handleChange(field, index, value)}
                    eachScore={false}
                    correct={option.correct}
                    onRemove={() => removeOption(index)}
                  />
                </div>
              ))}
              <button
                type="button"
                className="font-montserrat bg-transparent border-0 text-xs text-maya-blue font-weight-600 mt-3"
                onClick={addOption}
              >
                {t('CREATE_PRE_FILTER_QUESTIONS_MC_ADD_OPTION')}
              </button>
            </div>
          )}

          {answerType === AnswerTypeEnum.TEXT && (
            <div>
              <div className="w-100 mx-0 row justify-content-between">
                <span className="font-montserrat font-bold text-muted font-weight-bold text-sm m-3">
                  {t('CREATE_PRE_FILTER_QUESTIONS_NRO_CONDITION')}
                </span>

                <span className="align-items-start mt-2 mb-3">
                  <DropdownSelect
                    selectedPlaceholder
                    alignEnd="justify-content-center py-1"
                    placeholder={t('CREATE_PRE_FILTER_QUESTION_FIELD_TYPE_TITLE')}
                    setState={(e) => handleChange('condition', 0, e.id)}
                    selected={question?.options[0]?.condition}
                    options={numberConditionals?.map((item) => ({
                      id: item.id,
                      name: item.name,
                    }))}
                  />
                </span>
                <input
                  type="number"
                  min={0}
                  pattern="\d*"
                  className="w-80 mt-3 mb-5 ml-3 py-2 px-3 font-montserrat maya-blue-thin-border outline-none text-xs"
                  value={question.options[0]?.text}
                  onChange={(e) => handleChange('text', 0, e.target.value)}
                  required
                />
              </div>
            </div>
          )}
        </div>
        <div className="row my-2 justify-content-center">
          <button
            type="submit"
            onClick={(e) => {
              if (editQuestion) handleEditQuestion(e);
              else handleAddQuestion(e);
            }}
            disabled={disabledAdd}
            className="mt-4 align-self-end px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none bg-maya-blue"
          >
            {editQuestion
              ? t('CREATE_PRE_FILTER_EDIT_QUESTION_SELECTED')
              : t('CREATE_PRE_FILTER_ADD_QUESTION_SELECTED')}
          </button>
        </div>
      </div>
    </div>
  );
}
