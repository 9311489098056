import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import { Currencies, Regions } from '../../../constants/enums.js';
import { t } from '../../../i18n/i18n.js';
import { getPlanPrice } from '../../../utils/plans/price.js';
import Price from '../Plan/Price/Price.jsx';
const AddOnFeatures = (props) => {
  const { addOnFeatures, currency } = props;
  return (
    <section data-testid="add-on-features-container" class="container m-0">
      <div class="row justify-content-center w-100">
        <div class="col-sm-12 col-md-6">
          <div className="d-flex flex-column w-100 px-4">
            <span className="row my-2 font-montserrat text-lg font-weight-700 justify-content-start">
              {`${t('PLANS_ADD_FEATURES_BY_USE_LABEL')}`}
            </span>
            {addOnFeatures?.map((feature) => {
              const price = feature?.prices?.find((x) => x.currency == currency);
              return (
                <div className="row justify-content-between my-2 py-2 border-bottom border-1 border-secondary">
                  <div className="d-flex align-items-center">
                    <span className="font-montserrat text-md font-weight-500 justify-content-start mr-2">
                      {t(feature.whitelabelName)}
                    </span>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip align={'center'} placement="top">
                          <span className="font-montserrat text-xs font-weight-700 text-center w-100">
                            {t(feature.whitelabelDescription)}
                          </span>
                        </Tooltip>
                      }
                    >
                      <AiFillInfoCircle color={'lightgray'} size={'1em'} />
                    </OverlayTrigger>
                  </div>

                  <div className="d-flex flex-column align-items-center">
                    <div className="row font-montserrat text-xl font-weight-700 justify-content-start">
                      <Price
                        price={getPlanPrice(
                          price,
                          currency == Currencies.USD ? Regions.USA : Regions.ARGENTINA
                        )}
                        currency={price?.currency}
                      />
                    </div>
                    <span className=" font-montserrat text-xs font-weight-700 justify-content-start">
                      {t('PLANS_FEATURE_ADD_ON_BY_UNIT_LABEL')} {t(`FEATURE_UNIT_${price?.unit}`)}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

AddOnFeatures.propTypes = {
  addOnFeatures: PropTypes.array,
};

export default AddOnFeatures;
