import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import './personalize.scss';
import Evaluations from './Tabs/Evaluations';
import { t } from '../../i18n/i18n';
import Workspace from './Tabs/Workspace';
import processQueryString from '../../utils/processQueryString';

const Personalize = () => {
  const [tab, setTab] = useState(undefined);
  const { search } = useLocation();

  useEffect(() => {
    if (search && processQueryString(search).tab === 'evaluation') {
      setTab(1);
    } else {
      setTab(0);
    }
  }, []);

  return (
    <div className="w-100">
      <Container fluid className="content-min-height px-3 w-100">
        <BackgroundTop
          title="Personalizar"
          routes={[
            {
              text: 'Configuración',
              clickable: false,
            },
          ]}
          className="position-relative"
          showBase
          titleClassname="text-xl"
          marginAuto={false}
          contained={false}
        />
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex flex-row pt-3">
            {[t('PERSONALIZE_EVALUATIONS'), t('PERSONALIZE_WORKSPACE')].map((text, index) => (
              <button
                onClick={() => setTab(index)}
                type="button"
                data-testid={`button-tab-${text}`}
                key={text}
                className={`bg-transparent p-2 d-flex justify-content-center align-items-center mx-2 min-width-button
                  ${
                    index === tab
                      ? 'test-button-filters-personalize-selected'
                      : 'test-button-filters-personalize'
                  }`}
              >
                <span
                  className={`font-montserrat ${
                    index === tab ? 'text-tab-selected' : 'text-tab-select'
                  }`}
                >
                  {text}
                </span>
              </button>
            ))}
          </div>
          <div className="mt-4 w-100">{!tab ? <Evaluations /> : <Workspace />}</div>
        </div>
      </Container>
    </div>
  );
};

export default Personalize;
