import './BackgroundTop.scss';
import { Link } from 'react-router-dom';
import { HOME } from '../../constants/routes';
import BannerNavigationSeparator from '../../assets/background/BannerNavigationSeparator';
import BannerHomeIcon from '../../assets/background/BannerHomeIcon';
import { t } from '../../i18n/i18n';

const BackgroundTop = ({
  className = 'position-absolute',
  show = true,
  height = '3rem',
  title,
  showBase,
  routes = [],
  contained = true,
  marginAuto = true,
}) =>
  show && (
    <div
      data-testid="background-top-component"
      className={`w-100 background-top-height d-flex align-items-center ${className}`}
      style={{
        height,
      }}
    >
      <div
        className={`w-100 mx-auto text-left ${contained ? 'pl-3 pr-3' : ''} ${
          marginAuto ? 'mx-auto' : ''
        }`}
      >
        {(Boolean(routes.length) || showBase) && (
          <div className="banner-links-container d-flex align-items-center">
            <Link
              className="font-montserrat text-decoration-none text-muted d-flex align-items-center"
              to={HOME}
            >
              <BannerHomeIcon className="mr-2" />
              {t('BACKGROUND_TOP_HOME_TEXT')}
            </Link>
            <BannerNavigationSeparator className="ml-2" />
            {Boolean(routes.length) &&
              routes.map((route, index) => (
                <>
                  {route.clickable ? (
                    <Link
                      key={index}
                      className="font-montserrat ml-2 text-decoration-none text-muted"
                      to={route.route}
                    >
                      {t(route.text)}
                    </Link>
                  ) : (
                    <span
                      key={index}
                      className="font-montserrat cursor-normal ml-2 text-decoration-none text-muted"
                    >
                      {t(route.text)}
                    </span>
                  )}
                  <BannerNavigationSeparator className="ml-2" />
                </>
              ))}
            <span className="font-montserrat ml-2 text-decoration-none text-blue-principal font-weight-500">
              {title}
            </span>
          </div>
        )}
      </div>
    </div>
  );

export default BackgroundTop;
