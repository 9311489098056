import { notification, Radio, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFeatures, getPlans } from '../../API/services/alkymersService';
import { getCompanySizes } from '../../API/services/companyService';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import CardsView from '../../components/Plans/CardsView/CardsView.jsx';
import ComparativeTable from '../../components/Plans/ComparativeTable/ComparativeTable.jsx';
import AddOnFeatures from '../../components/Plans/Features/AddOnFeatures.jsx';
import Spinner from '../../components/spinner/Spinner';
import { Countries, Currencies } from '../../constants/enums';
import { PlanType } from '../../constants/enums.js';
import { t } from '../../i18n/i18n';
import { groupByMultipleProperties } from '../../utils/plans/plans.js';
import './Plans.scss';
const Plans = () => {
  const { user } = useSelector((state) => state.auth);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [features, setFeatures] = useState([]);
  const [addOnFeatures, setAddOnFeatures] = useState([]);
  const [planToFeaturesToShow, setPlanToFeaturesToShow] = useState([]);
  const [companySize, setCompanySize] = useState(1);
  const [companySizes, setCompanySizes] = useState([]);
  const [planType, setPlanType] = useState(PlanType.SUBSCRIPTION);
  const [currency, setCurrency] = useState(Currencies.USD);
  const [selectedPlansIds, setSelectedPlansIds] = useState({});

  const plansToShow = plans?.filter((x) => x.type == planType);
  const everyPlanHasUniqueDefinition = plansToShow?.every((x) => x?.data?.length == 1);
  const partnerFromArgentina = user?.country?.isoCode == Countries.ARGENTINA;

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([fetchPlans(), fetchFeatures(), fetchCompanySizes()]);
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const plansWithFeatures = Object.entries(selectedPlansIds)?.map(([key, value]) => {
      const plan = plans?.find((x) => x.name == key);
      const planToReturn = plan?.data?.find((x) => x.id == value);
      return planToReturn;
    });
    if (plansWithFeatures)
      setPlanToFeaturesToShow(plansWithFeatures?.filter((x) => x.type == planType));
  }, [selectedPlansIds, planType]);

  const fetchPlans = async () => {
    try {
      const plansData = await getPlans();
      const plansDataVisible = plansData.filter((plan) => plan?.visible);

      // for grouping plans: replace each plan name by the first word of name column property
      plansDataVisible?.forEach((x) => {
        const planName = x?.name?.split('-');
        x.name = planName?.length ? planName[0]?.trim() : x?.name;
      });

      const groupedByName = groupByMultipleProperties(plansDataVisible, ['name', 'type']);
      setPlans(groupedByName.sort((a, b) => a.order - b.order));
    } catch (error) {
      setPlans(null);
      notification.open({
        message: t('PLANS_LOAD_ERROR'),
        type: 'error',
      });
    }
  };
  const fetchFeatures = async () => {
    try {
      const featuresData = await getFeatures();
      setFeatures(featuresData);
      setAddOnFeatures(featuresData?.filter((x) => x.isAddOnFeature));
    } catch (error) {
      setFeatures(null);
    }
  };
  const fetchCompanySizes = async () => {
    try {
      const sizesData = await getCompanySizes();
      const sizesSelectData = sizesData
        ?.map((x) =>
          x.minCompanyMembers
            ? {
                label: `${!x.maxCompanyMembers ? '+' : ''}${x.minCompanyMembers} ${
                  x.maxCompanyMembers ? `- ${x.maxCompanyMembers}` : ''
                } ${t('PLANS_PLAN_COMPANY_EMPLOYEES_LABEL')}`,
                value: x.id,
              }
            : null
        )
        ?.filter((x) => x);
      setCompanySizes(sizesSelectData);
    } catch (error) {
      setCompanySizes(null);
    }
  };

  const setSelectedPlan = (plan) => {
    setSelectedPlansIds((prevSelectedPlansIds) => {
      return { ...prevSelectedPlansIds, [plan.name]: plan.id };
    });
  };

  return (
    <div
      data-testid="plans-configuration-page"
      className="container-fluid custom-column align-self-baseline"
    >
      {loading ? (
        <Spinner show={loading} />
      ) : (
        <div className="position-relative w-100 d-flex flex-column overflow-md-hidden">
          <section className="container-fluid custom-column">
            <BackgroundTop
              title={t('MENU_ITEM_PLANS_CONSUMPTION')}
              routes={[
                {
                  text: 'CONFIGURATION_PAGE_BANNER',
                  clickable: false,
                },
              ]}
              className="position-relative mb-3 py-3"
              titleClassname="text-xl"
              contained={false}
            />
          </section>
          <section className="container-fluid p-4 custom-column">
            {!plans ? (
              <div data-testid="no-available-plans">{t('PLANS_LOAD_ERROR')}</div>
            ) : plans && plans.length ? (
              <div
                data-testid="plans-container"
                className="container-fluid p-4 custom-column bg-white"
              >
                <span className="row my-1 font-montserrat text-lg font-weight-700 justify-content-center">
                  {`${t('PLANS_TITLE_LABEL')}`}
                </span>
                {partnerFromArgentina ? (
                  <div className="row justify-content-center my-4">
                    <Radio.Group
                      value={currency}
                      onChange={(e) => setCurrency(e.target.value)}
                      className="text-maya-blue border-dark-maya-blue"
                      style={{ display: 'flex', borderRadius: '10px' }}
                    >
                      <Radio.Button
                        ghost
                        value={Currencies.USD}
                        className={`${
                          currency == Currencies.USD
                            ? 'text-maya-blue border-dark-maya-blue'
                            : 'text-secondary'
                        } `}
                        style={{
                          fontWeight: currency == Currencies.USD ? 'bold' : 'normal',
                          fontFamily: 'Montserrat',
                          borderTopLeftRadius: '10px',
                          borderBottomLeftRadius: '10px',
                        }}
                      >
                        {Currencies.USD}
                      </Radio.Button>
                      <Radio.Button
                        ghost
                        value={Currencies.ARS}
                        className={`${
                          currency == Currencies.ARS
                            ? 'text-maya-blue border-dark-maya-blue'
                            : 'text-secondary'
                        } `}
                        style={{
                          fontWeight: currency == Currencies.ARS ? 'bold' : 'normal',
                          fontFamily: 'Montserrat',
                          borderTopRightRadius: '10px',
                          borderBottomRightRadius: '10px',
                        }}
                      >
                        {Currencies.ARS}
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                ) : null}

                <div className="row justify-content-center my-4">
                  <Radio.Group
                    value={planType}
                    onChange={(e) => setPlanType(e.target.value)}
                    style={{ display: 'flex', fontFamily: 'Montserrat' }}
                    className="col-12 col-sm-8 col-md-6 col-lg-4 col-xl-3 text-center"
                  >
                    <Radio.Button
                      ghost
                      value={PlanType.SUBSCRIPTION}
                      className={`${
                        planType == PlanType.SUBSCRIPTION
                          ? 'text-maya-blue border-dark-maya-blue'
                          : 'text-secondary'
                      } `}
                      style={{
                        fontWeight: planType == PlanType.SUBSCRIPTION ? 'bold' : 'normal',
                        fontFamily: 'Montserrat',
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px',
                        width: '50%',
                      }}
                    >
                      {t('PLANS_PLAN_TYPE_SUBSCRIPTION')}
                    </Radio.Button>
                    <Radio.Button
                      ghost
                      value={PlanType.PROJECT}
                      className={`${
                        planType == PlanType.PROJECT
                          ? 'text-maya-blue border-dark-maya-blue'
                          : 'text-secondary'
                      } `}
                      style={{
                        fontWeight: planType == PlanType.PROJECT ? 'bold' : 'normal',
                        fontFamily: 'Montserrat',
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '10px',
                        width: '50%',
                      }}
                    >
                      {t('PLANS_PLAN_TYPE_PROJECT')}
                    </Radio.Button>
                  </Radio.Group>
                </div>
                {!everyPlanHasUniqueDefinition ? (
                  <div className="row justify-content-center my-4">
                    <div className="d-flex flex-column align-items-start col-8 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                      <span className="text-start text-xs font-weight-semibold font-montserrat">
                        {t('PLANS_PLAN_COMPANY_SIZE_LABEL')}
                      </span>
                      <Select
                        defaultValue={companySize}
                        placeholder="Company size"
                        style={{
                          width: '100%',
                          textAlign: 'start',
                          fontFamily: 'Montserrat',
                          borderRadius: '10px',
                        }}
                        value={companySize}
                        onChange={(value) => {
                          setCompanySize(value);
                        }}
                        options={companySizes}
                      />
                    </div>
                  </div>
                ) : null}

                <section className="row justify-content-center w-100">
                  <div className="d-flex justify-content-center w-100">
                    <CardsView
                      plans={plansToShow}
                      isInternationalPrice={currency == Currencies.USD}
                      setSelectedPlan={setSelectedPlan}
                      companySize={companySize}
                    />
                  </div>
                </section>

                <section className="row justify-content-center">
                  <AddOnFeatures addOnFeatures={addOnFeatures} currency={currency} />
                </section>
                {features && features.length ? (
                  <section className="row my-1 d-flex justify-content-center">
                    <ComparativeTable
                      plans={planToFeaturesToShow}
                      features={features}
                      currency={currency}
                    />
                  </section>
                ) : null}
              </div>
            ) : (
              <div data-testid="empty-plans">{t('PLANS_NO_AVAILABLE_LABEL')}</div>
            )}
          </section>
        </div>
      )}
    </div>
  );
};

export default Plans;
