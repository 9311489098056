import './SelectableButton.scss';

export default function SelectableButton({ onClick, className, text, selected, disabled = false }) {
  return (
    <button
      type="button"
      disabled={disabled}
      className={`bg-transparent p-2 d-flex justify-content-center align-items-center 
        ${
          selected
            ? 'component-selectable-button-filters-selected'
            : 'component-selectable-button-filters'
        }
      ${className}`}
      onClick={onClick}
      data-testid={text}
    >
      <span
        className={`font-montserrat test-text-filters  ${
          selected
            ? 'component-selectable-text-filters-selected'
            : 'component-selectable-text-filters'
        }`}
      >
        {text}
      </span>
    </button>
  );
}
