import { useRef } from 'react';
import InputArrow from '../../assets/testgroupABM/InputArrow';
import './NumberInput.scss';

const NumberInput = ({ onChange, value }) => {
  const inputRef = useRef();

  return (
    <div className="number-input-container position-relative d-flex align-items-center maya-blue-thin-border py-1 px-2 ml-2">
      <input
        data-testid="number-input"
        ref={inputRef}
        type="number"
        className="border-0 outline-none w-100 text-light-black font-weight-bold font-montserrat"
        min="0"
        onKeyDown={(e) => {
          const excludedKeys = ['e', '+', '-', '.'];
          if (excludedKeys.includes(e.key)) e.preventDefault();
        }}
        value={value ?? ''}
        onChange={(e) => onChange(e.target.value)}
      />
      <div className="d-flex flex-column justify-content-start position-absolute right-0 number-input-arrows-container pr-2">
        <button
          type="button"
          data-testid="number-input-button-up"
          className="border-0 outline-none bg-transparent text-xs number-input-arrow"
          onClick={() => {
            onChange(Number(value) + 1);
            inputRef.current.stepUp();
          }}
        >
          <InputArrow className="rotate-180" />
        </button>
        <button
          className="border-0 outline-none bg-transparent text-xs number-input-arrow"
          data-testid="number-input-button-down"
          type="button"
          onClick={() => {
            onChange(Math.max(0, Number(value) - 1));
            inputRef.current.stepDown();
          }}
        >
          <InputArrow />
        </button>
      </div>
    </div>
  );
};

export default NumberInput;
