import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

function InfoTooltip({ placement = 'bottom', content, className }) {
  return (
    <>
      <OverlayTrigger placement={placement} overlay={<Tooltip>{content}</Tooltip>}>
        <div className={`${className ?? 'p-2'}`}>
          <FontAwesomeIcon icon={faQuestionCircle} />
        </div>
      </OverlayTrigger>
    </>
  );
}

export default InfoTooltip;
