import './ScoresChart.scss';

export const labels = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

export default function ScoresChart({ data, filterScore }) {
  const numbers = data.map(({ val }) => val);
  const maxNumber = Math.max(...numbers);

  return (
    <div>
      <div className="w-100 d-flex justify-content-around mt-3 align-items-end score-chart-container">
        {data.map(({ val }, index) => {
          const height = Math.round((val / maxNumber) * 100);
          const color =
            index >= filterScore.min / 10 && index < filterScore.max / 10 ? '#4bafe1' : '#D9D9D9';
          return (
            <div
              key={`${index}-${val}`}
              data-testid={`score-chart-bar-${val}`}
              className="h-100 w-100 d-flex flex-column-reverse score-chart-bar-container"
            >
              <div
                className="score-bar-width"
                style={{
                  height: `${height}%`,
                  background: color,
                }}
              />
              <span
                data-testid={`score-chart-bar-label-${val}`}
                style={{ color }}
                className="text-center font-montserrat font-weight-700 text-sm"
              >
                {val}
              </span>
            </div>
          );
        })}
      </div>
      <div className="score-bars-and-labels-separator" />
      <div className="d-flex justify-content-between mb-4">
        {labels.map((val, index) => (
          <div
            key={`${val}-${index}`}
            className="position-relative"
            style={{
              width: index === labels.length - 1 ? '0px' : '2.5rem',
            }}
          >
            <span
              data-testid={`score-chart-range-${val}`}
              style={{
                position: 'absolute',
                left:
                  index === labels.length - 1
                    ? '-0.919rem'
                    : index === 0
                    ? '-0.306rem'
                    : '-0.613rem',
              }}
              className="font-montserrat font-weight-500 text-sm scores-chart-labels-text position-absolute"
            >
              {val}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
