const CalendarClose = ({ className = '', fill = 'unset' }) => (
  <svg
    className={className}
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.74063 11.7094L9.20938 12.2406C9.0625 12.3875 8.825 12.3875 8.67813 12.2406L7 10.5594L5.32188 12.2375C5.175 12.3844 4.9375 12.3844 4.79063 12.2375L4.25938 11.7063C4.1125 11.5594 4.1125 11.3219 4.25938 11.175L5.9375 9.49687L4.25938 7.81875C4.1125 7.67188 4.1125 7.43437 4.25938 7.2875L4.79063 6.75625C4.9375 6.60938 5.175 6.60938 5.32188 6.75625L7 8.43437L8.67813 6.75625C8.825 6.60938 9.0625 6.60938 9.20938 6.75625L9.74063 7.2875C9.8875 7.43437 9.8875 7.67188 9.74063 7.81875L8.05937 9.5L9.7375 11.1781C9.8875 11.325 9.8875 11.5625 9.74063 11.7094ZM14 3.5V14.5C14 15.3281 13.3281 16 12.5 16H1.5C0.671875 16 0 15.3281 0 14.5V3.5C0 2.67188 0.671875 2 1.5 2H3V0.375C3 0.16875 3.16875 0 3.375 0H4.625C4.83125 0 5 0.16875 5 0.375V2H9V0.375C9 0.16875 9.16875 0 9.375 0H10.625C10.8313 0 11 0.16875 11 0.375V2H12.5C13.3281 2 14 2.67188 14 3.5ZM12.5 14.3125V5H1.5V14.3125C1.5 14.4156 1.58438 14.5 1.6875 14.5H12.3125C12.4156 14.5 12.5 14.4156 12.5 14.3125Z"
      fill={fill}
    />
  </svg>
);

export default CalendarClose;
