const CreationSuccess = () => {
  return (
    <svg
      width="263"
      height="263"
      viewBox="0 0 263 263"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M131.5 263C204.125 263 263 204.125 263 131.5C263 58.8745 204.125 0 131.5 0C58.8745 0 0 58.8745 0 131.5C0 204.125 58.8745 263 131.5 263Z"
        fill="#51C0F7"
      />
      <path
        d="M74.5642 188.286C74.5642 188.286 28.8698 171.146 19.0862 215.608L28.742 213.684C28.742 213.684 17.4255 226.691 13.4204 251.368C37.9546 246.589 50.6086 234.882 50.6086 234.882L48.9855 244.59C93.1244 233.431 74.5642 188.286 74.5642 188.286Z"
        fill="url(#paint0_linear_1220_9171)"
      />
      <path
        d="M74.5639 188.285C74.5639 188.285 46.0622 177.592 39.9606 205.327L45.9796 204.125C45.9796 204.125 38.9236 212.24 36.4214 227.629C51.728 224.646 59.618 217.342 59.618 217.342L58.6036 223.399C86.1359 216.433 74.5564 188.277 74.5564 188.277L74.5639 188.285Z"
        fill="url(#paint1_linear_1220_9171)"
      />
      <path
        d="M106.861 186.016L75.6763 155.163L84.2726 146.514L115.449 177.367L106.861 186.016Z"
        fill="url(#paint2_linear_1220_9171)"
      />
      <path
        d="M99.3463 105.569C99.3463 105.569 70.3862 103.427 58.5061 115.383C45.6868 128.277 35.0691 144.057 26.4878 166.607C21.9116 178.645 33.9945 166.202 42.0123 158.875C56.0414 146.048 62.9621 146.311 76.3225 149.37C76.3225 149.37 83.5212 134.627 99.3538 105.569H99.3463Z"
        fill="url(#paint3_linear_1220_9171)"
      />
      <g style={{ mixBlendMode: 'soft-light' }} opacity="0.33">
        <path
          d="M99.3462 105.569C99.3462 105.569 70.3862 103.427 58.5061 115.383C45.6867 128.277 35.069 144.057 26.4877 166.607C25.6311 168.862 25.3606 170.259 25.5334 170.988C25.5334 170.988 37.8568 142.419 51.3675 128.442C64.8707 114.466 73.8728 109.236 99.3462 105.569Z"
          fill="white"
        />
      </g>
      <path
        d="M156.537 162.158C156.537 162.158 158.927 191.035 147.047 202.991C134.235 215.893 118.508 226.615 95.9798 235.362C83.957 240.028 96.3255 227.87 103.599 219.808C116.336 205.703 116.02 198.805 112.834 185.497C112.834 185.497 127.547 178.186 156.537 162.15V162.158Z"
        fill="url(#paint4_linear_1220_9171)"
      />
      <path
        d="M247.739 53.2019C252.555 21.9951 246.416 14.6536 246.416 14.6536C246.416 14.6536 239.007 8.58957 207.77 13.6617C174.775 19.0119 153.833 39.3005 139.57 53.3973C121.123 71.6419 90.69 113.632 76.3076 149.37L94.665 167.344L112.835 185.506C148.535 170.853 190.352 140.142 208.484 121.582C222.498 107.237 242.651 86.1671 247.739 53.2094V53.2019Z"
        fill="url(#paint5_linear_1220_9171)"
      />
      <path
        d="M80.9663 153.922L94.6649 167.335L112.834 185.497C148.535 170.844 190.352 140.133 208.484 121.573C222.498 107.228 242.651 86.1583 247.738 53.2006C252.555 21.9938 246.416 14.6523 246.416 14.6523"
        fill="url(#paint6_linear_1220_9171)"
      />
      <g style={{ mixBlendMode: 'soft-light' }} opacity="0.09">
        <path
          d="M78.915 151.908L110.167 182.829"
          stroke="black"
          stroke-width="1.89"
          stroke-miterlimit="10"
        />
      </g>
      <path
        d="M76.3149 149.368L94.6723 167.342L112.842 185.504C120.056 182.543 127.525 178.921 134.994 174.879L86.7823 127.186C82.7923 134.67 79.223 142.146 76.3225 149.375L76.3149 149.368Z"
        fill="url(#paint7_linear_1220_9171)"
      />
      <path
        style={{ mixBlendMode: 'soft-light' }}
        d="M125.271 138.518L112.834 185.497L109.295 183.167L106.861 186.015L94.7476 174.03L125.271 138.518Z"
        fill="url(#paint8_linear_1220_9171)"
      />
      <g style={{ mixBlendMode: 'soft-light' }}>
        <path
          d="M180.658 22.1895L239.412 80.3275"
          stroke="black"
          stroke-width="1.89"
          stroke-miterlimit="10"
        />
      </g>
      <path
        d="M246.416 14.6536C246.416 14.6536 239.007 8.58957 207.77 13.6617C197.648 15.2998 188.668 18.3506 180.658 22.1979L239.412 80.3285C243.2 72.3032 246.175 63.3161 247.738 53.2019C252.555 21.9951 246.416 14.6536 246.416 14.6536Z"
        fill="url(#paint9_linear_1220_9171)"
      />
      <mask
        id="mask0_1220_9171"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="76"
        y="11"
        width="174"
        height="175"
      >
        <path
          d="M247.738 53.1999C252.555 21.9931 246.416 14.6516 246.416 14.6516C246.416 14.6516 239.006 8.58762 207.77 13.6598C174.774 19.0099 153.832 39.2985 139.57 53.3953C121.122 71.64 90.6895 113.63 76.3071 149.368L94.6645 167.342L112.834 185.504C148.534 170.851 190.351 140.14 208.483 121.58C222.498 107.235 242.651 86.1651 247.738 53.2074V53.1999Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1220_9171)">
        <path
          d="M171.917 20.5331L168.931 23.582L239.663 92.8721L242.649 89.8231L171.917 20.5331Z"
          fill="url(#paint10_linear_1220_9171)"
        />
        <path
          d="M166.568 27.1819L164.58 29.2109L235.312 98.501L237.3 96.4719L166.568 27.1819Z"
          fill="url(#paint11_linear_1220_9171)"
        />
      </g>
      <g style={{ mixBlendMode: 'soft-light' }} opacity="0.09">
        <path
          d="M86.7749 127.172L134.987 174.873"
          stroke="black"
          stroke-width="1.89"
          stroke-miterlimit="10"
        />
      </g>
      <path
        d="M125.023 136.798C125.023 136.798 122.333 132.628 96.3181 158.807C70.3036 184.98 48.1215 210.055 50.1879 212.099C52.2543 214.143 77.1942 191.795 103.209 165.623C129.223 139.451 125.023 136.798 125.023 136.798Z"
        fill="url(#paint12_linear_1220_9171)"
      />
      <g style={{ mixBlendMode: 'soft-light' }} opacity="0.33">
        <path
          d="M125.023 136.798C125.023 136.798 122.333 132.628 96.3181 158.807C70.3036 184.98 48.1215 210.055 50.1879 212.099C50.1879 212.099 57.4617 201.646 76.9838 180.854C96.506 160.062 118.891 137.339 125.023 136.798Z"
          fill="white"
        />
      </g>
      <path
        style={{ mixBlendMode: 'soft-light' }}
        d="M186.587 121.235C199.18 108.543 199.09 88.044 186.399 75.4501C173.707 62.8636 153.208 62.9463 140.614 75.6379C128.028 88.3296 128.11 108.829 140.802 121.422C153.494 134.009 173.993 133.926 186.587 121.235Z"
        fill="url(#paint13_linear_1220_9171)"
      />
      <path
        d="M186.211 120.13C198.73 107.506 198.647 87.1193 186.023 74.6005C173.399 62.0817 153.02 62.1644 140.494 74.7884C127.975 87.4124 128.058 107.799 140.682 120.317C153.306 132.836 173.692 132.754 186.211 120.13Z"
        fill="#D4D4D4"
      />
      <path
        d="M186.324 120.25C198.692 107.784 198.61 87.6457 186.136 75.2847C173.67 62.9162 153.532 62.9988 141.171 75.4725C128.802 87.9387 128.892 108.077 141.358 120.438C153.825 132.807 173.963 132.724 186.324 120.25Z"
        fill="url(#paint14_linear_1220_9171)"
      />
      <path
        d="M145.671 115.11C135.94 105.462 135.88 89.6968 145.529 79.9658C155.177 70.2348 170.942 70.1747 180.673 79.823C190.404 89.4714 190.464 105.236 180.816 114.967C171.167 124.698 155.402 124.758 145.671 115.11Z"
        fill="#76C9F0"
      />
      <path
        d="M146.566 114.876C137.233 105.618 137.173 90.492 146.423 81.1593C155.68 71.8266 170.807 71.7664 180.139 81.0165C189.472 90.2741 189.532 105.4 180.282 114.733C171.025 124.066 155.898 124.126 146.566 114.876Z"
        fill="url(#paint15_linear_1220_9171)"
      />
      <mask
        id="mask1_1220_9171"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="139"
        y="74"
        width="49"
        height="48"
      >
        <path
          d="M146.566 114.878C137.233 105.62 137.173 90.494 146.423 81.1612C155.68 71.8285 170.807 71.7684 180.139 81.0185C189.472 90.2761 189.532 105.402 180.282 114.735C171.025 124.068 155.898 124.128 146.566 114.878Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_1220_9171)">
        <path
          d="M185.32 79.0815L132.476 102.422L136.264 111L189.109 87.6598L185.32 79.0815Z"
          fill="url(#paint16_linear_1220_9171)"
        />
        <path
          d="M190.59 92.3569L137.746 115.697L139.652 120.014L192.497 96.6735L190.59 92.3569Z"
          fill="url(#paint17_linear_1220_9171)"
        />
      </g>
      <path
        d="M143.049 79.5583C143.207 78.8219 142.734 78.1081 141.997 77.9503C141.261 77.7925 140.547 78.2659 140.389 79.0023C140.231 79.7387 140.705 80.4525 141.441 80.6103C142.178 80.7681 142.891 80.2947 143.049 79.5583Z"
        fill="#A3A3A3"
      />
      <path
        d="M138.33 86.9919C138.488 86.2555 138.014 85.5417 137.278 85.3839C136.542 85.2261 135.828 85.6995 135.67 86.4359C135.512 87.1723 135.986 87.8861 136.722 88.0439C137.458 88.2017 138.172 87.7283 138.33 86.9919Z"
        fill="#A3A3A3"
      />
      <path
        d="M148.399 74.4197C148.557 73.6833 148.084 72.9694 147.347 72.8116C146.611 72.6538 145.897 73.1272 145.739 73.8636C145.582 74.6 146.055 75.3139 146.791 75.4717C147.528 75.6295 148.242 75.1561 148.399 74.4197Z"
        fill="#A3A3A3"
      />
      <path
        d="M137.113 103.102C136.632 102.524 135.775 102.449 135.204 102.93C134.633 103.411 134.55 104.267 135.031 104.838C135.512 105.417 136.369 105.492 136.94 105.011C137.518 104.53 137.594 103.674 137.113 103.102Z"
        fill="#A3A3A3"
      />
      <path
        d="M140.014 111.413C139.533 110.834 138.676 110.759 138.105 111.24C137.526 111.721 137.451 112.578 137.932 113.149C138.413 113.727 139.27 113.803 139.841 113.322C140.412 112.841 140.494 111.984 140.014 111.413Z"
        fill="#A3A3A3"
      />
      <path
        d="M136.564 94.3993C136.083 93.8207 135.227 93.7456 134.656 94.2265C134.085 94.7074 134.002 95.564 134.483 96.1351C134.964 96.7062 135.82 96.7889 136.391 96.3079C136.97 95.827 137.045 94.9704 136.564 94.3993Z"
        fill="#A3A3A3"
      />
      <path
        d="M151.375 122.428C150.624 122.451 150.038 123.082 150.06 123.826C150.083 124.57 150.714 125.164 151.458 125.141C152.209 125.118 152.795 124.487 152.773 123.743C152.75 122.999 152.119 122.406 151.375 122.428Z"
        fill="#A3A3A3"
      />
      <path
        d="M159.716 125.255C158.965 125.277 158.378 125.908 158.401 126.652C158.424 127.396 159.055 127.99 159.799 127.967C160.543 127.945 161.136 127.313 161.114 126.57C161.091 125.818 160.46 125.232 159.716 125.255Z"
        fill="#A3A3A3"
      />
      <path
        d="M144.169 117.514C143.417 117.537 142.831 118.168 142.854 118.912C142.876 119.663 143.507 120.25 144.251 120.227C144.995 120.204 145.589 119.573 145.566 118.829C145.544 118.078 144.913 117.492 144.169 117.514Z"
        fill="#A3A3A3"
      />
      <path
        d="M175.556 122.901C175.112 123.502 175.24 124.352 175.841 124.802C176.442 125.246 177.291 125.118 177.742 124.517C178.186 123.916 178.058 123.067 177.457 122.616C176.856 122.172 176.007 122.3 175.556 122.901Z"
        fill="#A3A3A3"
      />
      <path
        d="M182.521 118.174C182.078 118.775 182.206 119.624 182.807 120.075C183.408 120.518 184.257 120.391 184.708 119.79C185.151 119.188 185.024 118.339 184.423 117.888C183.821 117.438 182.972 117.573 182.521 118.174Z"
        fill="#A3A3A3"
      />
      <path
        d="M167.328 125.216C166.885 125.817 167.013 126.666 167.614 127.117C168.215 127.56 169.064 127.432 169.515 126.831C169.958 126.23 169.83 125.381 169.229 124.93C168.628 124.487 167.779 124.615 167.328 125.216Z"
        fill="#A3A3A3"
      />
      <path
        d="M189.75 104.59C189.931 105.319 190.667 105.762 191.396 105.582C192.125 105.402 192.568 104.665 192.388 103.936C192.207 103.207 191.471 102.764 190.742 102.944C190.013 103.125 189.57 103.861 189.75 104.59Z"
        fill="#A3A3A3"
      />
      <path
        d="M190.659 96.5276C190.84 97.2565 191.576 97.6999 192.305 97.5195C193.034 97.3392 193.477 96.6028 193.297 95.8739C193.117 95.145 192.38 94.7017 191.651 94.882C190.922 95.0623 190.479 95.7987 190.659 96.5276Z"
        fill="#A3A3A3"
      />
      <path
        d="M186.985 112.658C187.165 113.387 187.902 113.831 188.631 113.65C189.36 113.47 189.803 112.734 189.623 112.005C189.442 111.276 188.706 110.833 187.977 111.013C187.248 111.193 186.805 111.93 186.985 112.658Z"
        fill="#A3A3A3"
      />
      <path
        d="M185.986 81.6559C186.67 81.964 187.474 81.6559 187.782 80.9721C188.09 80.2883 187.782 79.4843 187.098 79.1762C186.414 78.8681 185.61 79.1762 185.302 79.86C184.994 80.5438 185.302 81.3479 185.986 81.6559Z"
        fill="#A3A3A3"
      />
      <path
        d="M179.817 75.5309C180.5 75.839 181.304 75.5309 181.612 74.8471C181.921 74.1633 181.612 73.3593 180.929 73.0512C180.245 72.7431 179.441 73.0512 179.133 73.735C178.825 74.4188 179.133 75.2229 179.817 75.5309Z"
        fill="#A3A3A3"
      />
      <path
        d="M189.585 89.2224C190.269 89.5304 191.073 89.2224 191.381 88.5386C191.689 87.8548 191.381 87.0507 190.697 86.7426C190.013 86.4346 189.209 86.7426 188.901 87.4264C188.593 88.1102 188.901 88.9143 189.585 89.2224Z"
        fill="#A3A3A3"
      />
      <path
        d="M163.909 70.4897C164.57 70.144 164.833 69.325 164.48 68.6562C164.134 67.9949 163.315 67.7319 162.646 68.0851C161.985 68.4308 161.722 69.2498 162.075 69.9186C162.421 70.5799 163.24 70.8429 163.909 70.4897Z"
        fill="#A3A3A3"
      />
      <path
        d="M155.485 71.9623C156.146 71.6167 156.409 70.7976 156.056 70.1289C155.71 69.4676 154.891 69.2046 154.223 69.5578C153.561 69.9034 153.298 70.7225 153.652 71.3913C153.997 72.0525 154.816 72.3155 155.485 71.9623Z"
        fill="#A3A3A3"
      />
      <path
        d="M172.693 71.7299C173.354 71.3843 173.617 70.5652 173.264 69.8964C172.918 69.2352 172.099 68.9722 171.431 69.3254C170.769 69.671 170.506 70.4901 170.86 71.1588C171.205 71.8201 172.024 72.0831 172.693 71.7299Z"
        fill="#A3A3A3"
      />
      <path
        d="M142.764 79.2751C142.921 78.5387 142.448 77.8249 141.712 77.6671C140.975 77.5093 140.261 77.9827 140.104 78.7191C139.946 79.4555 140.419 80.1693 141.156 80.3271C141.892 80.4849 142.606 80.0115 142.764 79.2751Z"
        fill="#D4D4D4"
      />
      <path
        d="M138.045 86.7068C138.203 85.9704 137.729 85.2565 136.993 85.0987C136.256 84.9409 135.543 85.4143 135.385 86.1507C135.227 86.8871 135.7 87.601 136.437 87.7588C137.173 87.9166 137.887 87.4432 138.045 86.7068Z"
        fill="#D4D4D4"
      />
      <path
        d="M148.114 74.1345C148.272 73.3981 147.798 72.6842 147.062 72.5264C146.325 72.3686 145.611 72.842 145.454 73.5784C145.296 74.3148 145.769 75.0287 146.506 75.1865C147.242 75.3443 147.956 74.8709 148.114 74.1345Z"
        fill="#D4D4D4"
      />
      <path
        d="M136.827 102.817C136.347 102.239 135.49 102.164 134.919 102.644C134.348 103.125 134.265 103.982 134.746 104.553C135.227 105.132 136.084 105.207 136.655 104.726C137.226 104.245 137.308 103.388 136.827 102.817Z"
        fill="#D4D4D4"
      />
      <path
        d="M139.728 111.128C139.247 110.549 138.39 110.474 137.819 110.955C137.248 111.436 137.165 112.293 137.646 112.864C138.127 113.442 138.984 113.517 139.555 113.036C140.126 112.556 140.209 111.699 139.728 111.128Z"
        fill="#D4D4D4"
      />
      <path
        d="M136.279 94.1142C135.798 93.5356 134.941 93.4604 134.37 93.9413C133.799 94.4222 133.716 95.2789 134.197 95.85C134.678 96.421 135.535 96.5037 136.106 96.0228C136.684 95.5419 136.76 94.6852 136.279 94.1142Z"
        fill="#D4D4D4"
      />
      <path
        d="M151.09 122.143C150.338 122.166 149.752 122.797 149.775 123.541C149.797 124.285 150.428 124.878 151.172 124.856C151.924 124.833 152.51 124.202 152.487 123.458C152.465 122.714 151.833 122.121 151.09 122.143Z"
        fill="#D4D4D4"
      />
      <path
        d="M159.43 124.969C158.679 124.992 158.093 125.623 158.115 126.367C158.138 127.111 158.769 127.705 159.513 127.682C160.264 127.659 160.851 127.028 160.828 126.284C160.805 125.54 160.174 124.947 159.43 124.969Z"
        fill="#D4D4D4"
      />
      <path
        d="M143.883 117.229C143.132 117.252 142.545 117.883 142.568 118.627C142.591 119.371 143.222 119.964 143.966 119.942C144.71 119.919 145.303 119.288 145.281 118.544C145.258 117.793 144.627 117.207 143.883 117.229Z"
        fill="#D4D4D4"
      />
      <path
        d="M175.27 122.616C174.827 123.217 174.955 124.066 175.556 124.517C176.157 124.961 177.006 124.833 177.457 124.232C177.9 123.631 177.773 122.781 177.172 122.331C176.57 121.887 175.721 122.015 175.27 122.616Z"
        fill="#D4D4D4"
      />
      <path
        d="M182.236 117.889C181.793 118.49 181.921 119.339 182.522 119.79C183.123 120.233 183.972 120.106 184.423 119.504C184.866 118.903 184.739 118.054 184.137 117.603C183.536 117.152 182.687 117.288 182.236 117.889Z"
        fill="#D4D4D4"
      />
      <path
        d="M167.042 124.931C166.599 125.532 166.727 126.381 167.328 126.832C167.929 127.275 168.778 127.147 169.229 126.546C169.672 125.945 169.545 125.096 168.944 124.645C168.342 124.202 167.493 124.329 167.042 124.931Z"
        fill="#D4D4D4"
      />
      <path
        d="M189.465 104.305C189.645 105.034 190.382 105.477 191.111 105.297C191.84 105.117 192.283 104.38 192.103 103.651C191.922 102.922 191.186 102.479 190.457 102.659C189.728 102.84 189.285 103.576 189.465 104.305Z"
        fill="#D4D4D4"
      />
      <path
        d="M190.374 96.2425C190.555 96.9714 191.291 97.4147 192.02 97.2343C192.749 97.054 193.192 96.3176 193.012 95.5887C192.831 94.8598 192.095 94.4165 191.366 94.5968C190.637 94.7772 190.194 95.5136 190.374 96.2425Z"
        fill="#D4D4D4"
      />
      <path
        d="M186.7 112.373C186.88 113.102 187.617 113.546 188.345 113.365C189.074 113.185 189.518 112.448 189.337 111.72C189.157 110.991 188.421 110.547 187.692 110.728C186.963 110.908 186.52 111.644 186.7 112.373Z"
        fill="#D4D4D4"
      />
      <path
        d="M185.7 81.3708C186.384 81.6789 187.188 81.3708 187.496 80.687C187.804 80.0032 187.496 79.1992 186.812 78.8911C186.129 78.583 185.325 78.8911 185.017 79.5749C184.708 80.2587 185.017 81.0627 185.7 81.3708Z"
        fill="#D4D4D4"
      />
      <path
        d="M179.531 75.2458C180.215 75.5539 181.019 75.2458 181.327 74.562C181.635 73.8782 181.327 73.0742 180.643 72.7661C179.959 72.458 179.155 72.7661 178.847 73.4499C178.539 74.1337 178.847 74.9377 179.531 75.2458Z"
        fill="#D4D4D4"
      />
      <path
        d="M189.299 88.9392C189.983 89.2472 190.787 88.9392 191.095 88.2554C191.403 87.5716 191.095 86.7675 190.412 86.4594C189.728 86.1514 188.924 86.4594 188.616 87.1432C188.308 87.827 188.616 88.6311 189.299 88.9392Z"
        fill="#D4D4D4"
      />
      <path
        d="M163.623 70.2045C164.284 69.8589 164.547 69.0398 164.194 68.371C163.849 67.7098 163.03 67.4468 162.361 67.8C161.7 68.1456 161.437 68.9647 161.79 69.6334C162.135 70.2947 162.954 70.5577 163.623 70.2045Z"
        fill="#D4D4D4"
      />
      <path
        d="M155.2 71.6791C155.861 71.3335 156.124 70.5144 155.771 69.8457C155.425 69.1844 154.606 68.9214 153.937 69.2746C153.276 69.6202 153.013 70.4393 153.366 71.1081C153.712 71.7693 154.531 72.0323 155.2 71.6791Z"
        fill="#D4D4D4"
      />
      <path
        d="M172.407 71.4448C173.069 71.0991 173.332 70.2801 172.978 69.6113C172.633 68.95 171.814 68.687 171.145 69.0402C170.484 69.3859 170.221 70.2049 170.574 70.8737C170.92 71.5349 171.739 71.7979 172.407 71.4448Z"
        fill="#D4D4D4"
      />
      <path
        d="M142.23 77.9945L140.439 79.7852L140.631 79.9764L142.421 78.1858L142.23 77.9945Z"
        fill="#B9B9B9"
      />
      <path
        d="M137.519 85.434L135.728 87.2246L135.919 87.4159L137.71 85.6253L137.519 85.434Z"
        fill="#B9B9B9"
      />
      <path
        d="M147.584 72.8656L145.793 74.6562L145.985 74.8475L147.775 73.0569L147.584 72.8656Z"
        fill="#B9B9B9"
      />
      <path
        d="M136.586 102.688L134.795 104.479L134.986 104.67L136.777 102.879L136.586 102.688Z"
        fill="#B9B9B9"
      />
      <path
        d="M139.484 111.006L137.693 112.797L137.885 112.988L139.675 111.198L139.484 111.006Z"
        fill="#B9B9B9"
      />
      <path
        d="M136.028 93.9906L134.238 95.7812L134.429 95.9725L136.22 94.1819L136.028 93.9906Z"
        fill="#B9B9B9"
      />
      <path
        d="M151.932 122.514L150.141 124.305L150.332 124.496L152.123 122.705L151.932 122.514Z"
        fill="#B9B9B9"
      />
      <path
        d="M160.266 125.334L158.475 127.125L158.666 127.316L160.457 125.526L160.266 125.334Z"
        fill="#B9B9B9"
      />
      <path
        d="M144.729 117.586L142.938 119.377L143.13 119.568L144.92 117.778L144.729 117.586Z"
        fill="#B9B9B9"
      />
      <path
        d="M177.168 122.436L175.377 124.227L175.568 124.418L177.359 122.627L177.168 122.436Z"
        fill="#B9B9B9"
      />
      <path
        d="M184.131 117.707L182.341 119.498L182.532 119.689L184.323 117.899L184.131 117.707Z"
        fill="#B9B9B9"
      />
      <path
        d="M168.931 124.746L167.14 126.537L167.331 126.728L169.122 124.938L168.931 124.746Z"
        fill="#B9B9B9"
      />
      <path
        d="M191.574 102.995L189.784 104.785L189.975 104.976L191.766 103.186L191.574 102.995Z"
        fill="#B9B9B9"
      />
      <path
        d="M192.495 94.9223L190.705 96.7129L190.896 96.9042L192.686 95.1136L192.495 94.9223Z"
        fill="#B9B9B9"
      />
      <path
        d="M188.813 111.065L187.022 112.855L187.214 113.047L189.004 111.256L188.813 111.065Z"
        fill="#B9B9B9"
      />
      <path
        d="M187.05 79.1391L185.26 80.9297L185.451 81.121L187.242 79.3303L187.05 79.1391Z"
        fill="#B9B9B9"
      />
      <path
        d="M180.885 73.0121L179.094 74.8027L179.286 74.994L181.076 73.2034L180.885 73.0121Z"
        fill="#B9B9B9"
      />
      <path
        d="M190.648 86.7094L188.857 88.5L189.049 88.6913L190.839 86.9007L190.648 86.7094Z"
        fill="#B9B9B9"
      />
      <path
        d="M163.791 68.0102L162 69.8008L162.191 69.9921L163.982 68.2015L163.791 68.0102Z"
        fill="#B9B9B9"
      />
      <path
        d="M155.372 69.4789L153.581 71.2695L153.772 71.4608L155.563 69.6702L155.372 69.4789Z"
        fill="#B9B9B9"
      />
      <path
        d="M172.582 69.2524L170.792 71.043L170.983 71.2343L172.773 69.4436L172.582 69.2524Z"
        fill="#B9B9B9"
      />
      <g style={{ mixBlendMode: 'soft-light' }} opacity="0.33">
        <path
          d="M246.416 14.6536C246.416 14.6536 239.007 8.58957 207.77 13.6617C174.775 19.0119 153.833 39.3005 139.57 53.3973C121.123 71.6419 90.69 113.632 76.3076 149.37C76.3076 149.37 142.704 10.0849 246.416 14.6536Z"
          fill="white"
        />
      </g>
      <g style={{ mixBlendMode: 'soft-light' }} opacity="0.33">
        <path
          d="M80.1172 153.058L76.826 156.289L75.6763 155.162L78.9149 151.908L80.1172 153.058Z"
          fill="white"
        />
      </g>
      <g opacity="0.48">
        <mask
          id="mask2_1220_9171"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="76"
          y="11"
          width="174"
          height="175"
        >
          <path
            d="M247.738 53.1999C252.554 21.9931 246.415 14.6516 246.415 14.6516C246.415 14.6516 239.006 8.58762 207.769 13.6598C174.774 19.0099 153.832 39.2985 139.569 53.3953C124.849 67.9505 102.501 97.6244 86.7665 127.178C82.7764 134.662 79.2147 142.147 76.3066 149.368L94.664 167.342L112.834 185.504C148.534 170.851 190.351 140.14 208.483 121.58C222.497 107.235 242.65 86.1651 247.738 53.2074V53.1999Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_1220_9171)">
          <path
            style={{ mixBlendMode: 'soft-light' }}
            d="M112.833 185.497C112.833 185.497 245.288 118.372 246.415 14.6523L283.799 31.056L160.091 221.551C160.091 221.551 135.151 212.188 112.833 185.497Z"
            fill="url(#paint18_linear_1220_9171)"
          />
        </g>
      </g>
      <path
        style={{ mixBlendMode: 'soft-light' }}
        d="M112.834 185.497L115.449 195.01C115.449 195.01 140.923 182.454 156.538 162.15C156.538 162.15 128.344 179.058 112.834 185.497Z"
        fill="url(#paint19_linear_1220_9171)"
      />
      <g style={{ mixBlendMode: 'soft-light' }} opacity="0.33">
        <path
          d="M101.3 223.685C109.13 216.013 112.068 208.604 112.579 207.191C112.459 207.492 112.196 208.071 111.625 209.04C109.882 212.249 107.289 215.728 103.6 219.815C98.8957 225.023 92.0803 231.936 91.0809 234.927C90.893 235.558 90.9907 235.971 91.2762 236.227C91.2762 236.212 92.6063 232.199 101.293 223.693L101.3 223.685Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1220_9171"
          x1="-3.86996"
          y1="264.428"
          x2="73.7"
          y2="191.359"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF8533" />
          <stop offset="1" stop-color="#FFD052" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1220_9171"
          x1="31.5822"
          y1="231.319"
          x2="79.3956"
          y2="184.279"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFD052" />
          <stop offset="1" stop-color="#FFE9AE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1220_9171"
          x1="120.875"
          y1="207.635"
          x2="76.1121"
          y2="134.468"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#767B7F" />
          <stop offset="1" stop-color="#A3A3A3" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1220_9171"
          x1="54.1028"
          y1="128.841"
          x2="125.834"
          y2="210.822"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F02E54" />
          <stop offset="1" stop-color="#8F1931" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1220_9171"
          x1="130.027"
          y1="230.591"
          x2="106.973"
          y2="114.42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F02E54" />
          <stop offset="1" stop-color="#8F1931" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1220_9171"
          x1="151.067"
          y1="57.455"
          x2="204.133"
          y2="182.786"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EEF4FA" />
          <stop offset="1" stop-color="#CDDEED" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1220_9171"
          x1="158.311"
          y1="71.3476"
          x2="203.329"
          y2="177.675"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EEF4FA" />
          <stop offset="1" stop-color="#CDDEED" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1220_9171"
          x1="73.6474"
          y1="122.632"
          x2="121.543"
          y2="181.732"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C7C7C7" />
          <stop offset="1" stop-color="#A3A3A3" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1220_9171"
          x1="108.491"
          y1="151.585"
          x2="118.29"
          y2="181.807"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1D262D" />
          <stop offset="1" stop-color="#1D262D" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1220_9171"
          x1="214.45"
          y1="24.6551"
          x2="313.488"
          y2="104.089"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F02E54" />
          <stop offset="1" stop-color="#8F1931" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1220_9171"
          x1="340.913"
          y1="196.809"
          x2="157.564"
          y2="6.69715"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8F1931" />
          <stop offset="1" stop-color="#F02E54" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1220_9171"
          x1="335.339"
          y1="202.183"
          x2="151.998"
          y2="12.0714"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8F1931" />
          <stop offset="1" stop-color="#F02E54" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1220_9171"
          x1="72.3626"
          y1="152.653"
          x2="128.517"
          y2="232.974"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F02E54" />
          <stop offset="1" stop-color="#8F1931" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1220_9171"
          x1="169.807"
          y1="111.443"
          x2="190.088"
          y2="153.892"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1D262D" />
          <stop offset="0.68" stop-color="#1D262D" stop-opacity="0.32" />
          <stop offset="1" stop-color="#1D262D" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1220_9171"
          x1="149"
          y1="63.367"
          x2="188.826"
          y2="156.529"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C7C7C7" />
          <stop offset="1" stop-color="#A3A3A3" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1220_9171"
          x1="144.259"
          y1="68.7682"
          x2="191.328"
          y2="140.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#ADE5FF" />
          <stop offset="1" stop-color="#76C9F0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_1220_9171"
          x1="186.343"
          y1="78.1206"
          x2="136.028"
          y2="111.439"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F9FBFC" />
          <stop offset="1" stop-color="#F9FBFC" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_1220_9171"
          x1="192.79"
          y1="87.8628"
          x2="142.475"
          y2="121.181"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F9FBFC" />
          <stop offset="1" stop-color="#F9FBFC" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_1220_9171"
          x1="283.596"
          y1="165.554"
          x2="175.082"
          y2="97.7904"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1D262D" />
          <stop offset="1" stop-color="#1D262D" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_1220_9171"
          x1="128.329"
          y1="161.414"
          x2="139.563"
          y2="186.579"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1D262D" />
          <stop offset="1" stop-color="#1D262D" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CreationSuccess;
