const JobBagIcon = (
  <svg width="22" height="18" viewBox="0 0 24 24" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.625 2.25H15.375C15.5813 2.25 15.75 2.41875 15.75 2.625V4.5H8.25V2.625C8.25 2.41875 8.41875 2.25 8.625 2.25ZM6 2.625V4.5H3C1.34531 4.5 0 5.84531 0 7.5V12H9H15H24V7.5C24 5.84531 22.6547 4.5 21 4.5H18V2.625C18 1.17656 16.8234 0 15.375 0H8.625C7.17656 0 6 1.17656 6 2.625ZM24 13.5H15V15C15 15.8297 14.3297 16.5 13.5 16.5H10.5C9.67031 16.5 9 15.8297 9 15V13.5H0V19.5C0 21.1547 1.34531 22.5 3 22.5H21C22.6547 22.5 24 21.1547 24 19.5V13.5Z"
      fill="current-color"
    />
  </svg>
);

export default JobBagIcon;
