import React, { useState } from 'react';
import Modal from '../Modal/Modal';
import { t } from '../../i18n/i18n';

export const QuestionEnableModal = ({
  showModal,
  setShowModal,
  submitAction,
  disabledButtonProps = false,
  isQuestionEnabled = false,
}) => {
  const [disabledButton, setDisabledButton] = useState(false);
  const onClickButton = () => {
    setDisabledButton(true);
    submitAction();
  };

  return (
    <>
      <Modal
        show={showModal}
        className="close-search-modal-container"
        closeBtnClassname="text-maya-blue font-weight-bold"
        setShow={setShowModal}
        showCloseButton
      >
        <div className="d-flex flex-column text-center justify-content-around h-100 gap-1">
          <span className="font-montserrat font-weight-700 text-md">
            {isQuestionEnabled ? t('SKIP_QUESTION_DISABLED') : t('SKIP_QUESTION_ENABLED')}
          </span>
          <span className="font-montserrat font-weight-500 text-sm">
            {isQuestionEnabled
              ? t('SKIP_QUESTION_DISABLED_DESCRIPTION')
              : t('SKIP_QUESTION_ENABLED_DESCRIPTION')}
          </span>
          <button
            onClick={disabledButtonProps && disabledButton ? () => {} : onClickButton}
            type="button"
            className={`text-sm mx-auto font-montserrat ${
              isQuestionEnabled ? 'bg-red-danger' : 'bg-maya-blue'
            } close-search-modal-confirmation-button text-white font-weight-700`}
          >
            {isQuestionEnabled ? t('DEACTIVATE_LABEL') : t('ACTIVATE_LABEL')}
          </button>
          <button
            onClick={() => setShowModal(false)}
            type="button"
            className="border-0 bg-transparent p-2 font-montserrat text-sm font-weight-700 text-red-danger"
          >
            {t('CANCEL_OPERATION_LABEL')}
          </button>
        </div>
      </Modal>
    </>
  );
};
