import React from 'react';
import { useParams } from 'react-router-dom';
import { t } from '../../../i18n/i18n';
import './EditJobPost.scss';
import JobPostForm from '../../../components/JobPostForm/JobPostForm';
import BackgroundTop from '../../../components/BackgroundTop/BackgroundTop';
import { EDIT_JOB_POST } from '../../../constants/routes';

const EditJobPost = () => {
  const { id } = useParams();

  return (
    <div className="w-100 mx-auto h-100 pl-3 pr-3">
      <div>
        <BackgroundTop
          height="3rem"
          title={t('EDIT_JOBPOST_ROUTE_TITLE')}
          className="position-relative"
          titleClassname="text-xl"
          routes={[
            {
              text: 'JOB_POST',
              clickable: false,
            },
          ]}
        />
      </div>
      <div className="position-relative new-job-container p-4 bg-white d-flex w-100 flex-column rounded mx-auto window-height">
        <span className="edit-jobpost-title font-weight-600 font-montserrat">
          {t('EDIT_JOBPOST_SECTION_TITLE')}
          <JobPostForm isEdit jobPostHashId={id} Route={EDIT_JOB_POST.replace(':id', id)} />
        </span>
      </div>
    </div>
  );
};

export default EditJobPost;
