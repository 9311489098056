import React from 'react';
import Timer from '../../assets/lottie/timer.json';
import { t } from '../../i18n/i18n';
import PaymentStatus from './PaymentStatus';

const PaymentPending = () => {
  const options = {
    animationData: Timer,
    loop: true,
  };

  return (
    <PaymentStatus
      options={options}
      title={t('PAYMENT_PENDING_TITLE')}
      subtitle={t('PAYMENT_PENDING_SUBTITLE')}
    />
  );
};

export default PaymentPending;
