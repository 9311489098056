export const isANewTestgroup = (createdAt) => {
  const today = new Date();
  const testDate = new Date(createdAt);
  const diffTime = Math.abs(today - testDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays <= 60;
};

export const doesNotHaveNewTag = (tags) =>
  !tags?.some((tag) => tag.type === 'MARKETING' && tag.displayName === 'Nuevo');
