import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import emailIcon from '../../assets/profileItem/email-black.svg';
import emailIconProfile from '../../assets/profile/profile-email.svg';
import useClickOutside from '../../hooks/useClickOutside';
import { t } from '../../i18n/i18n';
import ContactModal from './ContactModal/ContactModal';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ContactComponent = ({ alkymer, horizontal, className, profileDetails, Icon, title }) => {
  const [show, setShow] = useState(false);
  const partner = useSelector((state) => state.auth.user);
  const ContactButtonRef = useRef();
  const iconToRender = profileDetails ? emailIconProfile : emailIcon;
  useClickOutside(ContactButtonRef, () => setShow(false));
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">{title}</Tooltip>}>
      <div ref={ContactButtonRef} data-tour-id="profile-email-contact">
        <button
          className={`bg-transparent ${className}`}
          onClick={() => setShow(!show)}
          type="button"
        >
          {horizontal && 'Contactar'}
          {Icon ? (
            <div className="ml-3">
              <Icon />
            </div>
          ) : (
            <img src={iconToRender} className={`${horizontal && 'ml-2'}`} alt={t('ICON_EMAIL')} />
          )}
        </button>
        <ContactModal
          profileDetails={profileDetails}
          show={show}
          alkymer={alkymer}
          partner={partner}
        />
      </div>
    </OverlayTrigger>
  );
};

export default ContactComponent;
