import React from 'react';
import useAnimation from '../../hooks/useAnimation';
import { fixPercentage } from '../../utils/numbersUtils';
import './progress.scss';

const Progress = ({ percent, className = '', isCredits = false, barColor = '' }) => {
  const { ref, intersecting } = useAnimation('0px');

  return (
    <div
      data-testid="progress-bar"
      className={`position-relative progress-container ${className}`}
      ref={ref}
    >
      <div
        className={`progress-training transition ${
          !isCredits ? 'progress-training-with-bg' : ''
        } ${barColor} rounded`}
        style={{
          ...(isCredits && {
            background:
              percent >= 86 ? '#F44336' : percent >= 61 && percent <= 85 ? '#FFD329' : '#94F55B',
          }),
          width: `${intersecting ? fixPercentage(percent) : 0}%`,
        }}
      />
      <div className="progress-training-bg w-100 position-absolute rounded" />
    </div>
  );
};

export default Progress;
