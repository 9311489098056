import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import modalGrouper from '../../utils/modal/modalGrouper';
import SearchInput from '../SearchInput/SearchInput';
import modalSearcher from '../../utils/modal/modalSearcher';
import SearchModalList from '../SearchModalList/SearchModalList';
import Modal from '../Modal/Modal';
import './SearchModal.scss';

const SearchModal = ({ show, setShow, data, addFilter, hash, type, removeAppliedFilter }) => {
  const [grouped, setGrouped] = useState({});
  const [keys, setKeys] = useState([]);
  const [search, setSearch] = useState('');
  const [filtered, setFiltered] = useState(null);
  const location = useLocation();

  const closeModal = () => {
    setShow(false);
    if (!location.hash) {
      const html = document.getElementById('html');
      html.style.overflowY = 'auto';
    }
  };

  useEffect(() => {
    if (data) {
      setGrouped(modalGrouper(data, 'name'));
    }
  }, [data]);

  useEffect(() => {
    const groupedKeys = Object.keys(grouped);
    setKeys(groupedKeys);
  }, [grouped]);

  useEffect(() => {
    if (search) {
      const filter = modalSearcher(grouped, search, 'name');
      setFiltered(filter);
      setKeys(Object.keys(filter));
    } else {
      setFiltered(null);
      setKeys(Object.keys(grouped));
    }
  }, [search]);

  const handleInput = (e) => {
    setSearch(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
  };

  return (
    <Modal show={show} setShow={setShow} hash={hash} className="search-modal-card">
      <div className="card-body search-modal-input-container py-0 my-4">
        <SearchInput
          className="search-modal-input"
          value={search}
          onInput={handleInput}
          onSubmit={handleSearch}
        />
      </div>
      <div className="card-body search-modal-card-body py-0 my-4">
        {!search && keys ? (
          <SearchModalList
            type={type}
            keys={keys}
            grouped={grouped}
            addFilter={addFilter}
            closeModal={closeModal}
            removeAppliedFilter={removeAppliedFilter}
          />
        ) : null}
        {search && keys && filtered ? (
          <SearchModalList
            keys={keys}
            type={type}
            grouped={filtered}
            addFilter={addFilter}
            closeModal={closeModal}
            removeAppliedFilter={removeAppliedFilter}
          />
        ) : null}
        {search && !keys.length ? (
          <h4 className="text-muted">No hay resultados para &quot;{search}&quot;</h4>
        ) : null}
      </div>
    </Modal>
  );
};

export default SearchModal;
