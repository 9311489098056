import './CheckboxPartial.scss';

const CheckboxPartial = ({ className = '', onClick, data, fill = 'black' }) => (
  <label className={`checkbox bounce mb-0 ${className}`}>
    <input
      type="checkbox"
      checked
      readOnly
      onClick={(e) => {
        if (onClick) onClick(e);
      }}
      {...data}
    />
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g fill={fill}>
        <path d="M19 14H5c-1.1 0-2-.8-2-2s.8-2 2-2h14c1.1 0 2 .8 2 2s-.8 2-2 2z" />
      </g>
    </svg>
  </label>
);

export default CheckboxPartial;
