const Failed = () => (
  <svg
    data-testid="failed-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM7.7574 16.2427C7.36688 15.8521 7.36688 15.219 7.7574 14.8284L10.5857 12.0001L7.75725 9.17159C7.36672 8.78107 7.36672 8.1479 7.75725 7.75738C8.14777 7.36685 8.78094 7.36685 9.17146 7.75738L12 10.5859L14.8285 7.75737C15.219 7.36685 15.8522 7.36685 16.2427 7.75737C16.6332 8.1479 16.6332 8.78106 16.2427 9.17158L13.4142 12.0001L16.2425 14.8284C16.6331 15.219 16.6331 15.8521 16.2425 16.2427C15.852 16.6332 15.2188 16.6332 14.8283 16.2427L12 13.4143L9.17161 16.2427C8.78109 16.6332 8.14792 16.6332 7.7574 16.2427Z"
      fill="#DC251C"
    />
  </svg>
);

export default Failed;
