import React, { useState } from 'react';
import SubSkillItem from '../SubSkillItem/SubSkillItem';
import Accordion from '../Accordion/Accordion';
import Progress from '../Progress/Progress';
import arrow from '../../assets/header/arrow.svg';
import yellowStart from '../../assets/profile/yellow-star.svg';
import { multipleSort } from '../../utils/sort';
import { t } from '../../i18n/i18n';

const SingleAccordion = (props) => {
  const { index, skill, waiting } = props;
  const [active, setActive] = useState(false);
  const toggleAccordion = () => {
    setActive(!active);
  };
  return (
    <>
      <div key={index} className="d-flex my-2 pt-1 align-items-start position-relative">
        <div className="table-progress position-relative overflow-hidden flex-grow-1">
          <div
            className="my-1 position-relative d-flex align-items-end cursor-pointer"
            aria-hidden="true"
            onClick={() => {
              toggleAccordion();
            }}
          >
            <div>
              <span className="progress-skill-title font-montserrat font-weight-500 d-inline-block">
                {t(skill?.key ?? skill?.displayName ?? skill?.name)}
              </span>
              {!waiting && (
                <span className="progress-skill-counter font-montserrat font-weight-500 ml-2">
                  {skill.totalEngagement ? Math.floor(skill.totalEngagement) : 0}%
                </span>
              )}
            </div>
            {skill?.approvedLevel >= 5 && false && (
              <div className="progress-outstanding d-flex ml-sm-auto mb-1">
                <span className="font-weight-500 text-uppercase font-roboto bg-white d-none d-md-flex align-items-center">
                  {t('SKILLS_OUTSTANDING')}
                </span>
                <img src={yellowStart} alt={t('SKILLS_OUTSTANDING')} />
              </div>
            )}
          </div>
          <Progress percent={waiting ? 2 : skill?.totalEngagement} className="mb-1" />
          {!!skill?.subSkills?.length && (
            <Accordion open={active}>
              <ul className="list-group d-flex flex-wrap flex-row py-4">
                {multipleSort(skill.subSkills, 'approved', 'currentScore', 'DESC').map(
                  (subSkill, index) => (
                    <SubSkillItem key={index} index={index} subSkill={subSkill} />
                  )
                )}
              </ul>
            </Accordion>
          )}
        </div>
        <div
          className="progress-dropdown progress-dropdown-position cursor-pointer z-index-1 right-0 align-items-center justify-content-center"
          aria-hidden="true"
          onClick={() => {
            toggleAccordion();
          }}
        >
          <div className="d-flex align-items-center justify-content-end justify-content-sm-between progress-dropdown">
            {!!skill?.subSkills?.length && (
              <img
                src={arrow}
                alt={t('ICON_ARROW')}
                className={`ml-1 dropdown-arrow ${active ? 'dropdown-arrow-open' : ''}`}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleAccordion;
