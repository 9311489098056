const Icon11 = ({ color = '#BCBCBC' }) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.9364 0.43941C14.3506 -0.14647 13.3991 -0.14647 12.8132 0.43941L7.18875 6.06386C6.60287 6.64974 6.60287 7.60121 7.18875 8.18709L7.93868 8.93702C8.52456 9.5229 9.47603 9.5229 10.0619 8.93702L10.2494 8.74954L15.2552 13.7506L15.0677 13.9381C14.4818 14.524 14.4818 15.4754 15.0677 16.0613L15.8176 16.8113C16.4035 17.3971 17.3549 17.3971 17.9408 16.8113L23.5653 11.1868C24.1512 10.6009 24.1512 9.64945 23.5653 9.06357L22.8154 8.31364C22.2295 7.72776 21.278 7.72776 20.6921 8.31364L20.5046 8.50112L15.4989 3.49536L15.6864 3.30788C16.2722 2.722 16.2722 1.77053 15.6864 1.18465L14.9364 0.434723V0.43941ZM7.81213 13.9381C7.22625 13.3522 6.27478 13.3522 5.6889 13.9381L0.43941 19.1876C-0.14647 19.7735 -0.14647 20.7249 0.43941 21.3108L2.68919 23.5606C3.27507 24.1465 4.22654 24.1465 4.81242 23.5606L10.0619 18.3111C10.6478 17.7252 10.6478 16.7738 10.0619 16.1879L9.99629 16.1223L12.7523 13.371L10.629 11.2477L7.87775 13.999L7.81213 13.9334V13.9381Z"
      fill={color}
    />
  </svg>
);

export default Icon11;
