export const isCompanyEmail = (email) => {
  if (!email) return false;

  const blacklist = process?.env?.RAZZLE_RUNTIME_BLACKLIST_DOMAINS?.split(',') ?? [];
  const emailSplit = email.split('@');
  const domain = emailSplit[emailSplit.length - 1];
  const domainSplit = domain.split('.');
  const domainToValidate = domainSplit[0]?.toLowerCase();
  return !blacklist.includes(domainToValidate);
};

export const errorsReasonSendgrid = [
  'Bounced Address',
  'Receiving server temporarily rejected the message',
  '500 unknown recipient',
];
