import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import alkemyLogo from '../assets/login/logoAlkemy.svg';
import Input from '../components/Input/Input';
import { t } from '../i18n/i18n';
import { editMe, postRequestMainWorkspace } from '../API/services/authService';
import schema from '../validations/registerValidation';
import Button from '../components/Button/Button';
import { setButtonLoading } from '../redux/actions/buttonActions';
import Spinner from '../components/spinner/Spinner';
import './NotApproved.scss';
import NotApprovedInput from '../components/NotApprovedInput/NotApprovedInput';
import { createCompany, getCompanyAssociatedWithEmail } from '../API/services/companyService';
import Check from '../assets/modal/Check';
import { getCountries } from '../API/services/addressService';
import DropdownSelect from '../components/DropdownSelect/DropdownSelect';
import GenericToggle from '../components/GenericToggle/GenericToggle';
import { PartnerWorkspaceRole } from '../constants/enums';
import ModalCustom from '../components/ModalCustom/ModalCustom';

const NotApproved = () => {
  const { user } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.buttons);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [companyDefaultValue, setCompanyDefaultValue] = useState(undefined);
  const [requestAccessMainWorkspace, setRequestAccessMainWorkspace] = useState(false);
  const [showModalRequestAccessMainWorkspace, setShowModalRequestAccessMainWorkspace] =
    useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const { handleSubmit, register, errors, watch } = useForm({
    resolver: yupResolver(schema),
  });
  const [currentStep, setCurrentStep] = useState(1);
  const interestsOptions = [
    { label: t('NEW_ACCOUNT_INTERESTS_ONBOARDINGS') },
    { label: t('NEW_ACCOUNT_INTERESTS_JOBREADY') },
    { label: t('NEW_ACCOUNT_INTERESTS_ALKYMETRICS') },
  ];
  const [selectedInterests, setSelectedInterests] = useState([]);

  const handleSelectOption = (option) => {
    const optionSelected = selectedInterests.find((selected) => selected === option);

    if (optionSelected) {
      setSelectedInterests((state) => state.filter((selected) => selected !== option));
    } else {
      setSelectedInterests([...selectedInterests, option]);
    }
  };

  useEffect(() => {
    const func = async () => {
      const hasCompanyAssociated = await getCompanyAssociatedWithEmail(user?.email);
      const countries = await getCountries();
      if (hasCompanyAssociated.hasCompanyAssociated) {
        setCompanyDefaultValue(hasCompanyAssociated.company);
      }
      setCountries(countries?.filter((country) => country.isCertifiable));
    };
    func();
    setLoading(false);
  }, []);

  const shouldRenderRoleInput = () => {
    if (companyDefaultValue?.displayName?.length) {
      return true;
    }

    if (!companyDefaultValue && watch('companyRequest')?.length < 2) {
      return false;
    }

    if (!companyDefaultValue && watch('companyRequest')?.length >= 2) {
      return true;
    }

    return false;
  };

  const newSubmit = (data) => {
    dispatch(setButtonLoading(true));
    setLoading(true);

    if (companyDefaultValue) {
      if (requestAccessMainWorkspace) {
        postRequestMainWorkspace({
          body: {
            role: PartnerWorkspaceRole.ADMINISTRATOR,
            companyId: String(companyDefaultValue.id),
          },
        }).catch(() => {
          notification.open({
            message: t('ERROR_REQUESTING_ACCESS'),
            type: 'error',
          });
        });
      }
      editMe({
        id: user.id,
        toEdit: {
          ...user,
          ...data,
          interests: selectedInterests,
          company: { ...companyDefaultValue, id: String(companyDefaultValue.id) },
          companyId: String(companyDefaultValue.id),
          approved: true,
          countryId: selectedCountry.id,
        },
      }).finally(() => {
        localStorage.setItem('isOnboarding', true);
        window.location.href = '/';
      });
    }

    if (!companyDefaultValue) {
      createCompany({
        displayName: data.companyRequest,
        email: user.email,
        primaryColor: '#58C1F5',
        secondaryColor: '#FD7779',
      })
        .then((res) => {
          editMe({
            id: user.id,
            toEdit: {
              ...user,
              ...data,
              interests: selectedInterests,
              company: { ...res, id: String(res.id) },
              companyId: String(res.id),
              approved: true,
              countryId: selectedCountry.id,
            },
          });
        })
        .finally(() => {
          window.location.href = '/';
        });
    }
  };
  return (
    <div className="w-100 h-100 d-flex flex-column justify-content-start">
      {user ? (
        <div className="d-flex flex-column">
          <img src={alkemyLogo} alt="" width="150px" height="70" className="brand-logo" />
          <div className="name-container w-100">
            {currentStep === 1 ? (
              <h1 className="font-montserrat font-weight-600 name-welcome text-center">{`¡${t(
                'HELLO_CUSTOM_EMAIL'
              )} ${user.firstName}!`}</h1>
            ) : (
              <>
                {!loading && (
                  <div className="d-flex flex-column last-step-title-container">
                    <span className="font-montserrat font-weight-600 welcome-last-step">
                      {t('PARTNERS_INTERESTS_TITLE')}
                    </span>
                    <span className="font-montserrat">{t('PARTNERS_INTERESTS_SUBTITLE')}</span>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="bg-none not-approved-card w-100">
            <div className="d-flex flex-column">
              {loading ? (
                <Spinner show />
              ) : (
                <>
                  <div className="w-100">
                    <form
                      className="d-flex flex-column px-4 flex-wrap not-approved-form bg-white"
                      noValidate
                      autoComplete="off"
                      onSubmit={handleSubmit(newSubmit)}
                    >
                      <Input
                        className="w-50 flex-grow-1 px-2 px-sm-4 d-none"
                        type="text"
                        label={t('NOT_APPROVED_NAME_LABEL')}
                        name="firstName"
                        register={register}
                        error={errors?.firstName}
                        defaultValue={user?.firstName}
                        disabled={isLoading}
                      />
                      <Input
                        className="w-50 flex-grow-1 px-2 px-sm-4 d-none"
                        type="text"
                        label={t('NOT_APPROVED_LAST_NAME_LABEL')}
                        name="lastName"
                        register={register}
                        error={errors?.lastName}
                        defaultValue={user?.lastName}
                        disabled={isLoading}
                      />
                      <Input
                        className="w-50 flex-grow-1 px-2 px-sm-4 d-none"
                        type="email"
                        label={t('NOT_APPROVED_EMAIL_LABEL')}
                        defaultValue={user?.email}
                        disabled
                      />
                      <NotApprovedInput
                        className={`${
                          currentStep === 1 ? 'd-flex' : 'd-none'
                        } flex-column justify-content-center outline-border sm-2 md-3 flex-grow-1 font-montserrat`}
                        type="text"
                        inputClassName="font-weight-700 text-muted text-xs"
                        isActive={shouldRenderRoleInput()}
                        label={t('WHERE_DO_YOU_WORK_AT')}
                        name="companyRequest"
                        register={register}
                        error={errors?.companyRequest}
                        defaultValue={companyDefaultValue?.displayName}
                        disabled={isLoading}
                        readOnly={companyDefaultValue?.displayName?.length}
                      />
                      {currentStep === 1 && companyDefaultValue?.displayName?.length && (
                        <div>
                          <div className="d-flex flex-column justify-content-center outline-border sm-2 md-3 flex-grow-1 font-montserrat">
                            <label className="font-weight-500 font-montserrat text-sm">
                              {t('REQUEST_ACCESS_TO_WORKSPACE')}
                            </label>
                          </div>
                          <div
                            className="bg-transparent position-relative d-inline-block not-approved-toggle"
                            onClick={() => setShowModalRequestAccessMainWorkspace(true)}
                          >
                            <GenericToggle
                              disabled
                              defaultActive={requestAccessMainWorkspace}
                              actionFunction={() => {}}
                            />
                          </div>
                          <ModalCustom
                            onCancel={() => {
                              setRequestAccessMainWorkspace(false);
                            }}
                            onCancelText={t('CANCEL')}
                            onOk={() => {
                              setRequestAccessMainWorkspace(true);
                              setShowModalRequestAccessMainWorkspace(false);
                            }}
                            classModal="allow-certificate-download-modal-width"
                            subtitleClassName="px-0 px-lg-0"
                            onOkText={t('ACCEPT')}
                            setShow={setShowModalRequestAccessMainWorkspace}
                            show={showModalRequestAccessMainWorkspace}
                            title={t('REQUEST_ACCESS_WORKSPACE_MODAL_TITLE')}
                            subtitle={t('REQUEST_ACCESS_WORKSPACE_MODAL_SUBTITLE')}
                          />
                        </div>
                      )}
                      <div
                        className={`${
                          shouldRenderRoleInput() && currentStep === 1 ? 'd-block' : 'd-none'
                        } mt-2 mb-4`}
                      >
                        <span className="font-weight-500 font-montserrat text-sm">
                          {t('NOT_APPROVED_WHERE_ARE_YOU')}
                        </span>
                        <DropdownSelect
                          roundBorder={false}
                          menuClassnames="first-filter-form-types-dropdown"
                          setState={(country) => {
                            setSelectedCountry(country);
                          }}
                          maxHeight={false}
                          options={countries.map((type) => ({
                            ...type,
                            label: type.displayName,
                          }))}
                          placeholderClassname="text-muted text-xs pl-2"
                          outsidePadding="w-100"
                          selected={selectedCountry && selectedCountry.id}
                          placeholder=""
                          className={`w-100 mt-2 py-2 tech-button-border ${
                            selectedCountry ? 'border-maya-blue' : 'border-unselected-types'
                          }`}
                          selectedPlaceholder
                          arrow={false}
                          bigArrowLightBlue
                        />
                      </div>
                      <NotApprovedInput
                        className={`${
                          shouldRenderRoleInput()
                            ? `${currentStep === 1 ? 'd-flex' : 'd-none'}`
                            : 'd-none'
                        } flex-column justify-content-center outline-border sm-2 md-3 flex-grow-1 font-montserrat`}
                        type="text"
                        inputClassName="font-weight-700 text-muted text-xs"
                        label={t('WHAT_IS_YOUR_ROLE')}
                        defaultValue={user?.role}
                        disabled={isLoading}
                        isActive={watch('role')?.length > 0}
                        register={register}
                        name="role"
                      />
                      {currentStep === 2 && (
                        <>
                          {interestsOptions.map((option) => (
                            <button
                              type="button"
                              className={`bg-white option-container b-radius-8 outline-border  ${
                                selectedInterests.find((selected) => selected === option.label)
                                  ? 'interest-active'
                                  : 'interest-not-active'
                              }`}
                              onClick={() => handleSelectOption(option.label)}
                            >
                              <p className="font-montserrat my-auto font-weight-600 text-sm text-truncate interest-option">
                                {option.label}
                              </p>
                              {selectedInterests.find((selected) => selected === option.label) && (
                                <Check />
                              )}
                            </button>
                          ))}
                        </>
                      )}
                      <div
                        className={`my-1 w-100 d-flex ${
                          currentStep === 1 ? 'justify-content-end' : 'justify-content-between'
                        }`}
                      >
                        {currentStep === 1 ? (
                          <button
                            disabled={!selectedCountry}
                            className="register-navigation-buttons bg-maya-blue active outline-border rounded-pill text-white font-weight-bold text-sm font-roboto text-decoration-none finish-button"
                            type="button"
                            onClick={() => setCurrentStep(2)}
                          >
                            {t('NEW_ACCOUNT_CONTINUE')}
                          </button>
                        ) : (
                          <>
                            <button
                              onClick={() => setCurrentStep(1)}
                              type="button"
                              className="register-navigation-buttons bg-maya-blue active outline-border rounded-pill text-white font-weight-bold text-sm font-roboto text-decoration-none finish-button"
                            >
                              {t('PARTNERS_REGISTER_STEP_GO_BACK')}
                            </button>
                            <Button
                              className="register-navigation-buttons bg-maya-blue outline-border rounded-pill text-white font-weight-bold text-sm font-roboto text-decoration-none finish-button"
                              variant="primary"
                              type="submit"
                              text={t('CREATE_ACCOUNT_FINISH')}
                              disabled={
                                (!companyDefaultValue && watch('companyRequest')?.length < 2) ||
                                selectedInterests.length === 0
                              }
                              load
                            />
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Spinner show />
      )}
    </div>
  );
};

export default NotApproved;
