import { useEffect, useState } from 'react';
import { Window } from '../utils/window';

const useRoles = () => {
  const [userRoles, setUserRoles] = useState([]);

  const roles = {
    ADMIN: 'Admin',
    ALKYMER: 'Alkymer',
    APPLICANT: 'Applicant',
    MELI: 'Meli',
    MENTOR: 'Mentor',
    PARTNER: 'Partner',
    PREACCELERATION: 'Preacceleration',
    TESTGROUPCREATOR: 'TestGroupCreator',
    TESTSCREENINGSPARTNER: 'ScreeningsPartner',
    MARKETPLACEPARTNER: 'MarketplacePartner',
    TESTGROUPRESTARTER: 'TestGroupRestarter',
  };

  useEffect(() => {
    if (Window()) {
      setUserRoles(JSON.parse(localStorage.getItem('roles')) ?? []);
    }
  }, []);

  const loadRoles = () => setUserRoles(JSON.parse(localStorage.getItem('roles')) ?? []);

  const isRole = (role) => userRoles?.includes(role);

  const isRoles = (roles) => {
    let hasRoles = true;
    roles.forEach((role) => {
      if (!userRoles.includes(role)) hasRoles = false;
    });

    return hasRoles;
  };

  return {
    isRole,
    isRoles,
    loadRoles,
    userRoles,
    roles,
  };
};

export default useRoles;
