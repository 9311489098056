export const getGroupSliderTags = (scoreTags) => {
  if (!scoreTags) return undefined;
  const data = {};
  const mins = Object.keys(scoreTags)?.map(Number);
  const ranges = mins?.map((min, index) => {
    if (index + 1 == mins?.length) return [min, 100];
    const max = mins[index + 1] - 1;
    return [min, max];
  });
  data.ranges = ranges;
  data.descriptions = Object.values(scoreTags);
  return data;
};
