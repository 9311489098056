import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Spinner from '../../spinner/Spinner';
import TestItem from '../../TestItem/TestItem';
import RoleTestGroupsEmpty from '../../../assets/evaluation/RoleTestGroupsEmpty';
import RoleTestGroupCross from '../../../assets/evaluation/RoleTestGroupCross';
import DropdownSelect from '../../DropdownSelect/DropdownSelect';
import {
  setNewScreeningData,
  setNewScreeningFilters,
} from '../../../redux/actions/screeningActions';
import useCreateScreening from '../../../hooks/useCreateScreening';
import { formatOrder } from '../../../utils/formatArrayOrder';
import { t } from '../../../i18n/i18n';

const RoleTests = ({ setShow, loadingTests, fetchTests, options = [], isCheck }) => {
  const { testArray, newScreening, newScreeningFilters, handleTests } = useCreateScreening();
  const dispatch = useDispatch();

  const determineAllSelected = () => {
    const availableTestIds = testArray.collection.map((item) => item.id);
    const selectedTestGroupIds = newScreening?.testGroups.map((item) => item.id);

    return availableTestIds.every((item) => selectedTestGroupIds.includes(item));
  };

  const selectAll = () => {
    const selectedTestGroupIds = newScreening?.testGroups?.map((item) => item.id);
    const filteredTestGroups = testArray.collection.filter(
      (item) => !selectedTestGroupIds.includes(item.id)
    );

    dispatch(
      setNewScreeningData({
        ...newScreening,
        testGroups: [...newScreening.testGroups, ...filteredTestGroups],
        allTests: formatOrder([...newScreening.allTests, ...filteredTestGroups]),
      })
    );
  };

  const deselectAll = () => {
    const idsToDeselect = testArray.collection.map((item) => item.id);
    const filteredTestGroups = newScreening?.testGroups.filter(
      (item) => !idsToDeselect.includes(item.id)
    );
    const allTestsFormatted = newScreening?.allTests.filter(
      (item) => !idsToDeselect.includes(item.id)
    );
    dispatch(
      setNewScreeningData({
        ...newScreening,
        testGroups: filteredTestGroups,
        allTests: formatOrder(allTestsFormatted),
      })
    );
  };

  const handleSelectRole = (role) => {
    dispatch(setNewScreeningFilters({ ...newScreeningFilters, selectedRole: role }));
  };

  useEffect(async () => {
    if (newScreeningFilters?.selectedRole?.id) {
      await fetchTests({ rolesIds: newScreeningFilters?.selectedRole?.id });
    }
  }, []);

  return (
    <div className="d-flex flex-column" data-testid="create-evaluation-role-tests">
      {!newScreeningFilters?.selectedRole?.id && (
        <DropdownSelect
          options={options.map((item) => ({ id: item.id, name: item?.displayName }))}
          className="create-evaluation-role-dropdown w-100"
          selected={newScreeningFilters?.selectedRole?.id}
          maxHeight={false}
          arrowPurple
          arrow={false}
          placeholder={t('CREATE_EVALUATION_ROLE_TESTS_INPUT_PLACEHOLDER')}
          searchable
          setState={async (e) => {
            handleSelectRole(e);
            await fetchTests({ rolesIds: e.id });
          }}
          selectedPlaceholder
        />
      )}
      {newScreeningFilters?.selectedRole?.id && !loadingTests && (
        <div className="d-flex align-items-center pb-2">
          <button
            data-testid="create-evaluation-role-tests-select-batch-button"
            className="outline-none align-self-start font-weight-600 d-flex py-2 pl-3 text-base font-montserrat justify-content-center align-items-center text-center bg-transparent selected-role-button"
            onClick={() => handleSelectRole(undefined)}
            type="button"
          >
            {newScreeningFilters?.selectedRole?.name}
            <RoleTestGroupCross classNames="mx-3" />
          </button>
          {isCheck && (
            <button
              className="create-evaluation-secondary-button ml-2 font-montserrat font-weight-700 bg-transparent py-2 px-4 text-xs"
              onClick={() => {
                if (determineAllSelected()) {
                  deselectAll();
                } else {
                  selectAll();
                }
              }}
              type="button"
              data-testid="create-evaluation-select-or-deselect-btn"
            >
              {determineAllSelected()
                ? t('CREATE_EVALUATION_DESELECT_ALL')
                : t('CREATE_EVALUATION_SELECT_ALL')}
            </button>
          )}
        </div>
      )}
      {newScreeningFilters?.selectedRole?.id ? (
        <div
          data-testid="create-evaluation-role-tests-container"
          className={`test-grid-container tests-bg px-2 px-md-4 py-4 overflow-auto test-grid-container-roles bg-gray ${
            loadingTests ? ' d-flex justify-content-center' : ' px-2 px-md-4 py-4'
          }`}
        >
          {loadingTests ? (
            <Spinner show />
          ) : (
            <>
              {testArray?.collection?.map((item) => (
                <TestItem
                  key={item.id}
                  test={item}
                  setShow={setShow}
                  isStar
                  isFavorite={item?.favorite ?? false}
                  testArray={testArray}
                  isCheck={isCheck}
                  handleTests={handleTests}
                  newScreening={newScreening}
                />
              ))}
            </>
          )}
        </div>
      ) : (
        <div className="d-flex mt-2 flex-column align-items-center mx-auto test-grid-container-tests">
          <RoleTestGroupsEmpty />
          <span
            className="text-center font-montserrat font-weight-600 mt-3 mb-2 text-base"
            data-testid="create-evaluation-role-tests-empty-title-span"
          >
            {t('CREATE_EVALUATION_ROLE_TESTS_EMPTY_STATE_TITLE')}
          </span>
          <span
            className="text-center font-montserrat text-base"
            data-testid="create-evaluation-role-tests-empty-subtitle-span"
          >
            {t('CREATE_EVALUATION_ROLE_TESTS_EMPTY_STATE_SUBTITLE')}
          </span>
        </div>
      )}
    </div>
  );
};

export default RoleTests;
