import './ButtonFilters.scss';

const ButtonFilters = ({ text, selected = false, className, onClick }) => (
  <button
    type="button"
    className={`bg-transparent p-2 d-flex justify-content-center align-items-center ${
      selected ? 'component-button-filters-selected' : 'component-button-filters'
    } ${className}`}
    onClick={onClick}
    data-testid={`BUTTON_FILTERS_${text}`}
  >
    <span
      className={`font-montserrat test-text-filters text-nowrap ${
        selected ? 'component-text-filters-selected' : 'component-text-filters'
      }`}
    >
      {text}
    </span>
  </button>
);

export default ButtonFilters;
