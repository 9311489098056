import { all } from 'redux-saga/effects';
import profilesSaga from './profilesSaga';
import errorsSaga from './errorsSaga';
import teamsSaga from './teamsSaga';

function* rootSaga() {
  yield all([profilesSaga, errorsSaga, teamsSaga]);
}

export default rootSaga;
