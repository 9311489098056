const CalendarBasicIcon = ({ className = '', fillClassname = '' }) => (
  <svg
    className={className}
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 0.5C4.55312 0.5 5 0.946875 5 1.5V2.5H9V1.5C9 0.946875 9.44687 0.5 10 0.5C10.5531 0.5 11 0.946875 11 1.5V2.5H12.5C13.3281 2.5 14 3.17188 14 4V5.5H0V4C0 3.17188 0.671875 2.5 1.5 2.5H3V1.5C3 0.946875 3.44688 0.5 4 0.5ZM0 6.5H14V15C14 15.8281 13.3281 16.5 12.5 16.5H1.5C0.671875 16.5 0 15.8281 0 15V6.5ZM2 9V10C2 10.275 2.225 10.5 2.5 10.5H3.5C3.775 10.5 4 10.275 4 10V9C4 8.725 3.775 8.5 3.5 8.5H2.5C2.225 8.5 2 8.725 2 9ZM6 9V10C6 10.275 6.225 10.5 6.5 10.5H7.5C7.775 10.5 8 10.275 8 10V9C8 8.725 7.775 8.5 7.5 8.5H6.5C6.225 8.5 6 8.725 6 9ZM10.5 8.5C10.225 8.5 10 8.725 10 9V10C10 10.275 10.225 10.5 10.5 10.5H11.5C11.775 10.5 12 10.275 12 10V9C12 8.725 11.775 8.5 11.5 8.5H10.5ZM2 13V14C2 14.275 2.225 14.5 2.5 14.5H3.5C3.775 14.5 4 14.275 4 14V13C4 12.725 3.775 12.5 3.5 12.5H2.5C2.225 12.5 2 12.725 2 13ZM6.5 12.5C6.225 12.5 6 12.725 6 13V14C6 14.275 6.225 14.5 6.5 14.5H7.5C7.775 14.5 8 14.275 8 14V13C8 12.725 7.775 12.5 7.5 12.5H6.5ZM10 13V14C10 14.275 10.225 14.5 10.5 14.5H11.5C11.775 14.5 12 14.275 12 14V13C12 12.725 11.775 12.5 11.5 12.5H10.5C10.225 12.5 10 12.725 10 13Z"
      fill="#757575"
      className={fillClassname}
    />
  </svg>
);

export default CalendarBasicIcon;
