import {
  SET_PROFILES,
  SET_PROFILE,
  SET_PROFILES_FILTERS,
  SET_APPLIED_FILTERS,
  SET_PROFILES_NEXT_URL,
  SET_PROFILES_QUERY,
  REFRESH_PROFILES,
  SET_TEXT_QUERY,
  SET_AGE_QUERY,
  SET_PROFILES_LOADING,
  SET_TECHNOLOGIES,
  SET_CERTIFIABLE_TECHNOLOGIES,
  SET_TOTAL_PROFILES,
  SET_SOME_PROFILES,
  SET_ALKYMERS_STATS,
  SET_ALKYMER_ADVANCE,
  GET_TECHNOLOGY_ADVANCE,
  SET_SOME_PROFILES_FILTERS,
  SET_SOME_PROFILES_APPLIED_FILTERS,
  SET_SOME_PROFILES_NEXT_URL,
  SET_FAVORITE_PROFILES,
  SET_NEWS,
  SET_TOP_BY_TECHNOLOGY,
  SET_TOP_LOADING,
  SET_PROFILES_ORDERS,
  SET_SELECTED_VIDEO,
  SET_USEFUL_CONTENT,
  SET_MOST_HIRES_COMPANIES,
  SET_MOST_HIRES_COMPANIES_LOADING,
  SET_ENGAGEMENT_QUERY,
  SET_SAVED_SEARCHES,
  SET_ORDER,
  SET_INVITED_PROFILE,
  SET_INVITED_PROFILE_TEST_GROUPS,
  SET_SHOW_FIRST_FILTER_MODAL,
  SET_TECHNOLOGY_TYPES,
} from '../actionTypes';

const initialState = {
  profiles: [],
  profile: null,
  alkymerAdvance: null,
  showFirstFilterModal: false,
  total: 0,
  availableFilters: {},
  appliedFilters: [],
  availableOrders: [],
  savedSearches: [],
  nextUrl: '',
  refresh: false,
  query: '',
  ageQuery: {
    min: '',
    max: '',
  },
  engagementQuery: {
    minEngagement: '',
    maxEngagement: '',
  },
  textQuery: '',
  loading: false,
  technologies: [],
  technologyTypes: [],
  certifiableTechnologies: [],
  someProfiles: [],
  someProfilesFilters: {
    applied: null,
    available: null,
  },
  someProfilesNextUrl: 0,
  stats: {
    age: [],
    gender: [],
    technologies: [],
    countries: [],
    status: [],
    totalUsers: 0,
  },
  favoriteProfiles: [],
  news: [],
  topByTech: [],
  topLoading: false,
  selectedVideo: {},
  usefulContent: [],
  mostHireCompanies: [],
  mostHireCompaniesLoading: false,
  ordering: { order: '', orderBy: '' },
  invitedProfile: null,
  invitedProfileTestGroups: {},
};

function profilesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROFILES: {
      return { ...state, ...action.payload };
    }
    case SET_PROFILE: {
      return { ...state, ...action.payload };
    }
    case SET_PROFILES_FILTERS: {
      return { ...state, ...action.payload };
    }
    case SET_PROFILES_ORDERS: {
      return { ...state, availableOrders: action.payload };
    }
    case SET_TOTAL_PROFILES: {
      return { ...state, ...action.payload };
    }
    case SET_APPLIED_FILTERS: {
      return { ...state, ...action.payload };
    }
    case SET_PROFILES_NEXT_URL: {
      return { ...state, ...action.payload };
    }
    case SET_PROFILES_QUERY: {
      return { ...state, ...action.payload };
    }
    case REFRESH_PROFILES: {
      return { ...state, ...action.payload };
    }
    case SET_TEXT_QUERY: {
      return { ...state, ...action.payload };
    }
    case SET_AGE_QUERY: {
      return { ...state, ...action.payload };
    }
    case SET_ENGAGEMENT_QUERY: {
      return { ...state, ...action.payload };
    }
    case SET_PROFILES_LOADING: {
      return { ...state, ...action.payload };
    }
    case SET_TECHNOLOGIES: {
      return { ...state, ...action.payload };
    }
    case SET_TECHNOLOGY_TYPES: {
      return { ...state, ...action.payload };
    }
    case SET_CERTIFIABLE_TECHNOLOGIES: {
      return { ...state, ...action.payload };
    }
    case SET_SOME_PROFILES: {
      return { ...state, ...action.payload };
    }
    case SET_SOME_PROFILES_FILTERS: {
      return { ...state, someProfilesFilters: { ...state.someProfilesFilters, ...action.payload } };
    }
    case SET_SOME_PROFILES_APPLIED_FILTERS: {
      return { ...state, someProfilesFilters: { ...state.someProfilesFilters, ...action.payload } };
    }
    case SET_SOME_PROFILES_NEXT_URL: {
      return { ...state, ...action.payload };
    }
    case SET_ALKYMERS_STATS: {
      return { ...state, ...action.payload };
    }
    case SET_ALKYMER_ADVANCE: {
      return { ...state, ...action.payload };
    }
    case GET_TECHNOLOGY_ADVANCE: {
      return { ...state, ...action.payload };
    }
    case SET_FAVORITE_PROFILES: {
      return { ...state, favoriteProfiles: [...action.payload] };
    }
    case SET_NEWS: {
      return { ...state, news: action.payload };
    }
    case SET_TOP_BY_TECHNOLOGY: {
      return { ...state, topByTech: action.payload };
    }
    case SET_TOP_LOADING: {
      return { ...state, ...action.payload };
    }
    case SET_SELECTED_VIDEO: {
      return { ...state, selectedVideo: action.payload };
    }
    case SET_USEFUL_CONTENT: {
      return { ...state, usefulContent: action.payload };
    }
    case SET_MOST_HIRES_COMPANIES: {
      return { ...state, mostHireCompanies: action.payload };
    }
    case SET_MOST_HIRES_COMPANIES_LOADING: {
      return { ...state, mostHireCompaniesLoading: action.payload };
    }
    case SET_SAVED_SEARCHES: {
      return { ...state, savedSearches: action.payload };
    }
    case SET_ORDER: {
      return { ...state, ordering: action.payload };
    }
    case SET_INVITED_PROFILE: {
      return { ...state, ...action.payload };
    }
    case SET_INVITED_PROFILE_TEST_GROUPS: {
      return { ...state, ...action.payload };
    }
    case SET_SHOW_FIRST_FILTER_MODAL: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}

export default profilesReducer;
