import React from 'react';

const StatsIcon = ({ width = '22', height = '16' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5333 12H17.4667C17.7333 12 18 11.7333 18 11.4667V1.86667C18 1.6 17.7333 1.33333 17.4667 1.33333H16.5333C16.2667 1.33333 16 1.6 16 1.86667V11.4667C16 11.7333 16.2667 12 16.5333 12ZM8.53333 12H9.46667C9.73333 12 10 11.7333 10 11.4667V3.2C10 2.93333 9.73333 2.66667 9.46667 2.66667H8.53333C8.26667 2.66667 8 2.93333 8 3.2V11.4667C8 11.7333 8.26667 12 8.53333 12ZM12.5333 12H13.4667C13.7333 12 14 11.7333 14 11.4667V5.86667C14 5.6 13.7333 5.33333 13.4667 5.33333H12.5333C12.2667 5.33333 12 5.6 12 5.86667V11.4667C12 11.7333 12.2667 12 12.5333 12ZM20.6667 14H2V0.666667C2 0.298333 1.70167 0 1.33333 0H0.666667C0.298333 0 0 0.298333 0 0.666667V14.6667C0 15.4029 0.597083 16 1.33333 16H20.6667C21.035 16 21.3333 15.7017 21.3333 15.3333V14.6667C21.3333 14.2983 21.035 14 20.6667 14ZM4.53333 12H5.46667C5.73333 12 6 11.7333 6 11.4667V8.53333C6 8.26667 5.73333 8 5.46667 8H4.53333C4.26667 8 4 8.26667 4 8.53333V11.4667C4 11.7333 4.26667 12 4.53333 12Z"
      fill="#757575"
    />
  </svg>
);

export default StatsIcon;
