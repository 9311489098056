import { SearchDashboardColumns } from './enums';

export const defaultSearchTableHeaders = [
  {
    key: SearchDashboardColumns.PROFILE,
    name: 'DASHBOARD_SEARCH_HEADERS_PROFILE',
    checked: true,
    pinned: false,
    order: 1,
    width: '12rem',
  },
  {
    key: SearchDashboardColumns.SCORE,
    name: 'DASHBOARD_SEARCH_HEADERS_SCORE',
    checked: true,
    pinned: false,
    order: 2,
    width: '7rem',
    sortable: true,
  },
  {
    key: SearchDashboardColumns.TOTALEVENTS,
    name: 'DASHBOARD_SEARCH_HEADERS_INCIDENCES',
    checked: true,
    pinned: false,
    order: 3,
    width: '8rem',
    sortable: true,
  },
  {
    key: SearchDashboardColumns.COUNTRY,
    name: 'DASHBOARD_SEARCH_HEADERS_COUNTRY',
    checked: true,
    pinned: false,
    order: 4,
    width: '5rem',
  },
  {
    key: SearchDashboardColumns.CITY,
    name: 'DASHBOARD_SEARCH_HEADERS_CITY',
    checked: true,
    pinned: false,
    order: 5,
    width: '9rem',
  },
  {
    key: SearchDashboardColumns.PROCESS_STATUS,
    name: 'DASHBOARD_SEARCH_HEADERS_STATE',
    checked: true,
    pinned: false,
    order: 6,
    width: '10rem',
  },
  {
    key: SearchDashboardColumns.COMPLETE,
    name: 'DASHBOARD_SEARCH_HEADERS_COMPLETE',
    checked: true,
    pinned: false,
    order: 7,
    width: '8rem',
    sortable: true,
  },
  {
    key: SearchDashboardColumns.SCREENINGS_STATUS,
    name: 'DASHBOARD_SEARCH_HEADERS_SCREENINGS',
    checked: true,
    pinned: false,
    order: 8,
    width: '7rem',
  },
  {
    key: SearchDashboardColumns.LIMIT_DATE,
    name: 'DASHBOARD_SEARCH_HEADERS_LIMIT_DATE',
    checked: true,
    pinned: false,
    order: 9,
    width: '11rem',
  },
];
