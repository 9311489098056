import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';
import rootSaga from './sagas';

const reduxDevtool = process.env.RAZZLE_RUNTIME_ENV !== 'production';

export const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    reduxDevtool
      ? composeWithDevTools(applyMiddleware(sagaMiddleware))
      : applyMiddleware(sagaMiddleware)
  );

  sagaMiddleware.run(rootSaga);

  return store;
};

export default makeStore();
