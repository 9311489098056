import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'lottie-react';
import { enGB, es, pt } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { notification } from 'antd';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { t } from '../../i18n/i18n';
import {
  ContentToDisplayEnum,
  PartnerWorkspaceRole,
  PlanFeaturesIds,
  ProcessStatusStage,
  TestGroupTypes,
} from '../../constants/enums';
import Ticket from '../../components/Ticket/Ticket';
import Spinner from '../../components/spinner/Spinner';
import HeaderArrowIcon from '../../assets/header/HeaderArrowIcon';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import useSearchDashboard from '../../hooks/useSearchDashboard';
import useTableBatchAction from '../../hooks/useTableBatchAction';
import { useElementSize } from '../../hooks/useElementSize';
import arrowBlue from '../../assets/savedSearch/fe_drop-down-blue.svg';
import DropdownButton from '../../components/DropdownButton/DropdownButton';
import '../ScreeningInvitationsList.scss';
import '../../components/CreateEvaluationSecondStep/CreateScreeningStep2.scss';
import './Search.scss';
import EditEmailTemplate from '../../components/EditEmailTemplate/EditEmailTemplate';
import EmptyComponent from '../../components/EmptyComponent/EmptyComponent';
import HorizontalLoader from '../../components/HorizontalLoader/HorizontalLoader';
import useOnScreen from '../../hooks/useOnScreen';
import {
  getCustomSearch,
  getCustomSearchStageInvitationsData,
  getRecruitSessionMetricsById,
  postSendEmailCustom,
  updateProctoringEnabledRecruitSessionStage,
  updateRecruitSessionStage,
} from '../../API/services/screeningService';
import Person from '../../assets/dashboard/Person';
import CopyLink from '../../assets/search/Link';
import {
  setNewSearchData,
  setSubjectEmail,
  setEmailsData,
  setSearchWasAlreadyCreated,
  setSendEmailFrom,
  setSelectedSearch,
  setKeepSearchData,
  setInvitationIdToScroll,
  setScreeningIdToKeep,
  setSearchInvitationsData,
} from '../../redux/actions/screeningActions';
import ProfilesSearchProvider from '../../components/ProfilesSearchProvider/ProfilesSearchProvider';
import ProfilesSearchDashboard from '../../components/ProfileSearch/ProfilesSearchDashboard';
import useFiltersDashboardSearch from '../../hooks/useFiltersDashboardSearch';
import EditPurpleIcon from '../../assets/common/EditPencilIcon';
import {
  clearAppliedFilters,
  setAppliedFilters,
  setProfilesFilters,
} from '../../redux/actions/profilesActions';
import SaveIcon from '../../assets/dashboard/SaveIcon';
import { editSearchStage } from '../../API/services/companyService';
import useModalDetails from '../../hooks/useModalDetails';
import Modal from '../../components/Modal/Modal';
import SelectableButton from '../../components/SelectableButton/SelectableButton';
import {
  getRecruitSessionCountryStats,
  getRecruitSessionStageLocationStats,
  getRecruitSessionAgeAndGendersMetricsById,
  getRecruitSessionStatsById,
} from '../../API/services/alkymersService';
import FunnelChart from '../../components/SearchChart/FunnelChart/FunnelChart';
import ChartLayout from '../../components/ChartLayout/ChartLayout';
import PieChartWithLabels from '../../components/SearchChart/PieChart/PieChart';
import AddInvitations from '../../assets/search/AddInvitations';
import ChartLegendItem from '../../components/SearchChart/ChartLegendItem/ChartLegendItem';
import { pieChartColors } from '../../constants/colors';
import CopyCheck from '../../assets/search/Check';
import EmptyState from '../../assets/screening/EmptyState.json';
import PaperPlane from '../../assets/search/PaperPlane';
import Bug from '../../assets/search/Bug';
import ModalCustom from '../../components/ModalCustom/ModalCustom';
import { errorsReasonSendgrid } from '../../utils/emailsUtils';
import TrashIcon from '../../assets/dashboard/TrashIcon';
import EnvelopeIcon from '../../assets/dashboard/EnvelopeIcon';
import { generateHours } from '../../constants/hours';
import DropdownSelect from '../../components/DropdownSelect/DropdownSelect';
import CalendarDatePicker from '../../components/CalendarDatePicker/CalendarDatePicker';
import Group from '../../assets/chart/Group';
import ScoresChart from '../../components/SearchChart/ScoresChart/ScoresChart';
import { TrackEvent } from '../../utils/filters/segmentUtils';
import AgeAndGenderChart from '../../components/SearchChart/AgeAndGenderChart/AgeAndGenderChart';
import DuplicateEvaluation from '../../components/DuplicateEvaluation/DuplicateEvaluation';
import { breadcrumbAlkymetricsEvaluation } from '../../constants/breadcrumb';
import SearchTable from '../../components/SearchTable/SearchTable';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import PersonalizeSearchTable from '../../components/PersonalizeSearchTable/PersonalizeSearchTable';
import { formatNumberWithThousandsSeparator } from '../../utils/numbersUtils';
import ConfigIcon from '../../assets/search/ConfigIcon';
import QualitativeFilters from '../../components/QualitativeFilters/QualitativeFilters';
import SearchCloudDownload from '../../assets/dashboard/SearchCloudDownload';
import EmailIcon from '../../assets/dashboard/EmailIcon';
import GenericToggle from '../../components/GenericToggle/GenericToggle';
import InfoIcon from '../../assets/search/InfoIcon';
import { isNullOrUndefined } from '../../utils/typesUtils';
import DisabledFeature from '../../components/DisabledFeature/DisabledFeature';
import { disableForWorkspaceRole } from '../../utils/workspaceRoleValidation';
import { determineDisabledFeature } from '../../utils/determineDisabled';
import { ModalProctoringEnabled } from '../../components/CardScreeningSearch/ModalProctoringEnabled';
import { ModalUserProfileEnabled } from '../../components/CardScreeningSearch/ModalUserProfileEnabled';
import ScienceIcon from '../../assets/sidebar/icons/ScienceIcon';
import { ModalReminderToCandidateEnabled } from '../../components/CardScreeningSearch/ModalReminderToCandidateEnabled';
import { SCREENING_SEARCHES } from '../../constants/routes';
import ModalMoveScreening from '../../components/CardScreeningSearch/ModalMoveScreening';
import MoveScreeningIcon from '../../assets/screeningSearchs/MoveScreeningIcon';

const scoresIntervalsByTens = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

registerLocale('en', enGB);
registerLocale('pt', pt);
registerLocale('es', es);

const Search = () => {
  const { id } = useParams();
  const fetchNextUrlRef = useRef(null);
  const fetchNextUrl = useOnScreen(fetchNextUrlRef, '80px', true);
  const { ref: containerRef, size } = useElementSize();
  const {
    selectedSearch,
    textInput,
    selectedStage,
    loading,
    loadingStage,
    screeningId,
    loadingNextPage,
    handleInput,
    handleSubmit,
    handleOrder,
    fetchNextPage,
    setTextInput,
    showConfirmationModal,
    setShowConfirmationModal,
    confirmCloseSearchModalContent,
    stageScreening,
  } = useSearchDashboard(id);
  const {
    selected,
    quantity,
    invitationsList,
    totalInvitations,
    confirmModalEditMail,
    confirmModalStage,
    editMailVisible,
    confirmModalSendMail,
    confirmModalSendMailSubmissionsReport,
    allSelected,
    showMoreFilters,
    allowCertificateDownloadModal,
    setSelected,
    setAllSelected,
    toggleSelectedId,
    deselectAll,
    selectAllIds,
    updateStageSingleCandidate,
    startStageConfirmation,
    startEditMailConfirmation,
    setEditMailVisible,
    confirmModifyModalStage,
    startModifyStageConfirmation,
    setShowMoreFilters,
    setShowCertificateDownloadModal,
    setModalSendMailSubmissionsReportVisible,
    modalSelectEvaluation,
    startAddingMemberToStageConfirmation,
    updateDateLimitInvitation,
  } = useTableBatchAction(selectedSearch.stages?.[0].id);
  const { handleScreeningModalData, screeningModalContent, closeModal, loadingModal, showModal } =
    useModalDetails();
  const { isLoading, loaderTable, exportCSV, loadingDownload } = useFiltersDashboardSearch(
    selectedSearch.stages?.[0].id
  );
  const [fetched, setFetched] = useState(false);
  const [changeArrow, setChangeArrow] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [partialSelection, setPartialSelection] = useState(false);
  const [copyEmailsSuccess, setCopyEmailsSuccess] = useState(false);
  const [showErrorEmailsModal, setShowErrorEmailsModal] = useState(false);
  const [showProvincesModal, setShowProvincesModal] = useState(false);
  const [showProctoringEnabledModal, setShowProctoringEnabledModal] = useState(false);
  const [showRequireCompletedProfileModal, setShowRequireCompletedProfileModal] = useState(false);
  const [showReminderToCandidatesModal, setShowReminderToCandidatesModal] = useState(false);
  const [showQualitativeFilters, setShowQualitativeFilters] = useState(false);
  const [disabledWorkspaceFunctions, setDisabledWorkspaceFunctions] = useState(true);
  const [appliedQualitativeFilters, setAppliedQualitativeFilters] = useState(0);
  const { template, stageCandidates, newSearch, subjectEmail, invitationIdToScroll } = useSelector(
    (state) => state.screenings
  );
  const { query, appliedFilters } = useSelector((state) => state.profiles);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const today = new Date();
  const dateSearchClose = new Date(selectedSearch.stages?.[0].endDate);
  const hours = generateHours();
  const goToStats = location.search;
  const editDates = location.search.split('editDates=')[1];
  const editName = location.search.split('editName=')[1];
  const savedPersonalization = localStorage.getItem(`searchTablePersonalization-${id}`);

  const { currentRole, user } = useSelector((state) => state.auth);

  useEffect(() => {
    setDisabledWorkspaceFunctions(
      disableForWorkspaceRole(currentRole, [
        PartnerWorkspaceRole.OWNER,
        PartnerWorkspaceRole.ADMINISTRATOR,
      ])
    );
  }, []);

  const setKeepData = (value) => {
    dispatch(setKeepSearchData(value));
    if (value) {
      dispatch(setScreeningIdToKeep(screeningId));
    } else {
      dispatch(setScreeningIdToKeep(undefined));
    }
  };

  const scrollToProfile = (id) => {
    const invitation = document.getElementById(id);
    if (invitation) {
      invitation.scrollTo({ behavior: 'smooth' });
    }
  };

  const toggleSelectAll = () => {
    if (quantity >= invitationsList.length || allSelected) {
      deselectAll();
      setPartialSelection(false);
    } else {
      selectAllIds(invitationsList.map((invitation) => invitation.invitationId));
    }
  };

  const handleSelection = () =>
    Object.values(selected).length === 0 ||
    Object.values(selected).every((value) => value === false)
      ? setIsSelected(false)
      : setIsSelected(true);

  useEffect(() => {
    if (invitationIdToScroll) {
      scrollToProfile(invitationIdToScroll);
    }
  }, []);

  useEffect(() => {
    if (!fetchNextUrl) setFetched(false);
    if (
      fetchNextUrl &&
      !fetched &&
      invitationsList?.length &&
      !loading &&
      !loadingStage &&
      !loadingNextPage &&
      !loaderTable
    ) {
      fetchNextPage();
      setFetched(true);
    }
  }, [fetchNextUrl]);

  const processStatusStageOptions = Object.values(ProcessStatusStage).map((status) => ({
    name: t(`SCREENING_INVITATIONS_PROCESS_STATUS_${status}`),
    value: status,
  }));

  useEffect(() => {
    handleSelection();
  }, [selected]);

  useEffect(() => {
    deselectAll();
  }, [selectedStage]);

  useEffect(() => {
    if (quantity !== invitationsList?.length && quantity !== 0) {
      setPartialSelection(true);
    } else {
      setPartialSelection(false);
    }
  }, [quantity]);

  useEffect(() => {
    setTimeout(() => {
      setKeepData(false);
    }, 10);
    dispatch(setSearchWasAlreadyCreated(false));
    return () => {
      if (
        !history.location.pathname.includes('/screening/profiles') &&
        !history.location.pathname.includes('/profiles/')
      ) {
        dispatch(setProfilesFilters({}));
        dispatch(setInvitationIdToScroll(undefined));
      }
    };
  }, []);
  const { searchInvitations } = useTableBatchAction();
  const [copySuccess, setCopySuccess] = useState(false);

  const copyLink = () => {
    const stage = newSearch?.stages[0];
    if (!searchInvitations.invitationPublicLink) {
      getCustomSearchStageInvitationsData(stage?.id).then((res) => {
        dispatch(setSearchInvitationsData(res));
        navigator.clipboard.writeText(res.invitationPublicLink);
        setCopySuccess(true);

        setTimeout(() => {
          setCopySuccess(false);
        }, 1000);
      });
    } else {
      navigator.clipboard.writeText(searchInvitations?.invitationPublicLink);
      setCopySuccess(true);

      setTimeout(() => {
        setCopySuccess(false);
      }, 1000);
    }
  };

  useEffect(
    () =>
      dispatch(
        setSearchInvitationsData({
          invitationsLeft: 300,
          invitationsUsed: 0,
          recruitSessionStageId: undefined,
          invitationPublicLink: '',
        })
      ),
    []
  );

  const addInvitations = () => {
    if (selectedSearch?.active) {
      TrackEvent('evaluation-dashboard-add-candidates');
      dispatch(setSendEmailFrom('edit'));
      if (dateSearchClose > today) {
        dispatch(
          setNewSearchData({
            ...newSearch,
            ...selectedSearch,
            step: 4,
          })
        );
        dispatch(setSubjectEmail({ subjectEmail: '' }));
        dispatch(setSearchWasAlreadyCreated(true));
        dispatch(setEmailsData({}));
        history.push({
          pathname: '/createsearch/send',
          state: {
            fromName: 'SCREENING_DASHBOARD_CAPSULE_TITLE',
            from: `/search/${id}`,
          },
        });
      } else {
        notification.open({
          message: t('ADD_INVITATIONS_DASHBOARD_DATE_FINISH'),
          icon: <i className="fas fa-times" style={{ color: 'red' }} />,
        });
      }
    } else {
      notification.open({
        message: t('ADD_INVITATIONS_DASHBOARD_SEARCH_CLOSE'),
        icon: <i className="fas fa-times" style={{ color: 'red' }} />,
      });
    }
  };

  const handleGoBack = () => {
    history.push(`/search/${id}`);
    setEditMailVisible(false);
  };

  const handleSendEmail = async (error, setLoading) => {
    const ids = stageCandidates?.collection
      ?.filter((candidate) => selected[candidate.invitationId])
      ?.map(({ invitationId }) => invitationId);

    const isSingleCandidate = Object.keys(selected)?.length <= 1;

    const requestData = {
      emailType: 'CUSTOM_GENERIC',
      emailParams: {
        subject: subjectEmail.subjectEmail,
        html: template.template,
      },
    };

    setLoading(true);
    const idsArray = [];
    for (
      let i = 0;
      Object.values(ids).length > i;
      i += Number(process.env.RAZZLE_RUNTIME_EMAIL_BATCH_SIZE)
    ) {
      idsArray.push(
        Object.values(ids).slice(i, i + Number(process.env.RAZZLE_RUNTIME_EMAIL_BATCH_SIZE))
      );
    }

    if (allSelected) {
      await postSendEmailCustom({
        ...requestData,
        invitationsIds: [],
        allCandidates: allSelected,
        filters: { stage: selectedSearch.stages[0].id, ...query },
      })
        .then(() => {
          notification.open({
            message: t('INVITED_CORRECTLY_EMAIL_GENERIC'),
            icon: <i className="fas fa-check" style={{ color: '#58DDA6' }} />,
          });
          dispatch(setSubjectEmail({ subjectEmail: '' }));
          setLoading(false);
          handleGoBack();
        })
        .catch(() => {
          setLoading(false);
          notification.open({
            message: t('INVITATION_ERROR'),
            icon: <i className="fas fa-times" style={{ color: 'red' }} />,
          });
        });
      return;
    }

    Promise.all(
      idsArray.map(async (items) => {
        await postSendEmailCustom(
          {
            ...requestData,
            invitationsIds: items,
          },
          {}
        );
        dispatch(setSubjectEmail({ subjectEmail: '' }));
      })
    )
      .then(() => {
        if (isSingleCandidate) {
          setSelected({});
        }
        if (ids.length === 1) {
          notification.open({
            message: t('INVITED_SINGLE_PROFILE_CORRECTLY'),
            icon: <i className="fas fa-check" style={{ color: '#58DDA6' }} />,
          });
        } else {
          notification.open({
            message: t('INVITED_CORRECTLY_EMAIL_GENERIC'),
            icon: <i className="fas fa-check" style={{ color: '#58DDA6' }} />,
          });
        }
        setLoading(false);
        handleGoBack();
      })
      .catch(() => {
        setLoading(false);
        notification.open({
          message: t('INVITATION_ERROR'),
          icon: <i className="fas fa-times" style={{ color: 'red' }} />,
        });
      });
  };

  const handleSelectCandidate = (id) => {
    setEditMailVisible(true);
    setSelected({ [id]: true });
    dispatch(setSubjectEmail({ subjectEmail: '' }));
    dispatch(setEmailsData({}));
    dispatch(setSearchWasAlreadyCreated(true));
  };

  const [editDate, setEditDate] = useState(false);
  const [editStageName, setEditStageName] = useState(false);
  const [modalUpdateName, setModalUpdateName] = useState(false);
  const [loadingNewName, setLoadingNewName] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);

  const [stageData, setStageData] = useState({
    startDate: {
      day: undefined,
    },
    endDate: {
      day: undefined,
      hours: undefined,
    },
    stageName: undefined,
    recruitSessionStageId: undefined,
  });
  const [startDate, setStartDate] = useState({
    day: undefined,
  });

  useEffect(() => {
    const date = moment(selectedSearch?.stages?.[0].endDate);
    setStageData({
      ...stageData,
      endDate: {
        ...stageData.endDate,
        day: date,
        hours: {
          hours: date.format('HH'),
          minutes: date.format('mm'),
          seconds: date.format('SS'),
        },
      },
    });
    setStartDate({
      ...startDate,
      day: selectedSearch?.stages?.[0].startDate,
    });
  }, [selectedSearch]);

  const formatDateWithObject = (day, hours, iso = false) => {
    const date = moment(day).add({ hours });
    if (iso) return date.toISOString();
    return date;
  };

  const submitDateEdition = () => {
    const endDay = moment(stageData.endDate.day)
      .set('hours', stageData.endDate.hours.hours)
      .set('minutes', stageData.endDate.hours.minutes)
      .set('seconds', stageData.endDate.hours.seconds)
      .toISOString();

    const formattedDate = formatDateWithObject(endDay, stageData.endDate.hours, true);
    const endDateMiliseconds = new Date(formattedDate).getTime();
    if (endDateMiliseconds < new Date().getTime()) {
      notification.open({
        message: t('EDIT_STAGE_DATE_CANNOT_BE_EARLIER_THAN_TODAY'),
        icon: <i className="fas fa-times" style={{ color: 'red' }} />,
        style: { fontFamily: 'Montserrat' },
      });
    } else if (endDateMiliseconds < new Date(startDate.day).getTime()) {
      notification.open({
        message: t('EDIT_STAGE_DATE_CANNOT_BE_BEFORE_START_DATE'),
        icon: <i className="fas fa-times" style={{ color: 'red' }} />,
        style: { fontFamily: 'Montserrat' },
      });
    } else {
      editSearchStage(selectedSearch.stages[0].id, {
        endDate: formattedDate,
      }).then((res) => {
        const newState = {
          ...selectedSearch,
        };
        newState.stages[0].endDate = moment(res.endDate).utc('-3');
        setSelectedSearch({
          newState,
        });
        setEditDate(false);
        notification.open({
          message: t('EDIT_STAGE_DATE_EDITED_SUCCESSFULLY'),
          type: 'success',
          style: { fontFamily: 'Montserrat' },
        });
      });
    }
  };

  const handleHourChange = (e) => {
    const time = e?.name?.split(':');
    setStageData({
      ...stageData,
      endDate: {
        ...stageData?.endDate,
        hours: {
          ...stageData?.endDate?.hours,
          hours: time[0],
          minutes: time[1],
        },
      },
    });
  };

  const listOfHours = () => {
    let hoursList = hours;
    const formattedDate = `${stageData.endDate.hours.hours}:${stageData.endDate.hours.minutes}`;
    const hoursContainsActualHour = hoursList.some((hour) => hour.name === formattedDate);
    if (!hoursContainsActualHour) {
      hoursList = hoursList
        .concat([
          {
            id: formattedDate,
            name: formattedDate,
          },
        ])
        .sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
    }
    return hoursList;
  };

  const [contentToDisplay, setContentToDisplay] = useState(ContentToDisplayEnum.DASHBOARD);

  useEffect(() => {
    if (goToStats) {
      setContentToDisplay(ContentToDisplayEnum.STATS);
    }
    if (editDates) {
      setEditDate(true);
    }
    if (editName) {
      setEditStageName(true);
    }
  }, []);

  const contentOptions = [
    {
      value: ContentToDisplayEnum.DASHBOARD,
      label: 'DASHBOARD_SEARCH_DASHBOARD_LABEL',
    },
  ];
  if (!disabledWorkspaceFunctions) {
    contentOptions.push({
      value: ContentToDisplayEnum.STATS,
      label: 'DASHBOARD_SEARCH_STATS_LABEL',
    });
  }

  const [searchStats, setSearchStats] = useState(null);
  const [searchStatsLoading, setSearchStatsLoading] = useState(false);

  const formatObjToIterable = (obj) =>
    Object.keys(obj).map((conv) => ({ name: conv, val: obj[conv] }));

  const [countryStatsDetail, setCountryStatsDetail] = useState({});
  const [countryStatsLoading, setCountryStatsLoading] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState();

  useEffect(async () => {
    if (contentToDisplay === ContentToDisplayEnum.STATS && !searchStats) {
      try {
        setSearchStatsLoading(true);
        const stageId = selectedSearch.stages?.[0].id || goToStats.split('stats=')[1];

        setSearchStatsLoading(true);
        const [stageStats, scoreMetrics, agesAndGendersMetrics, recruitSessionLocationStats] =
          await Promise.all([
            getRecruitSessionStatsById(stageId),
            getRecruitSessionMetricsById(stageId),
            getRecruitSessionAgeAndGendersMetricsById(stageId),
            getRecruitSessionStageLocationStats(selectedSearch.stages?.[0].id),
          ]);

        setSearchStats({
          invitationStatus: formatObjToIterable(stageStats?.invitationStatus ?? {}),
          conversions: formatObjToIterable(stageStats?.conversions ?? {}),
          errorEmailsList: stageStats?.errorEmailsList ?? [],
          scoreMetrics: scoreMetrics ?? {
            groupedScores: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            average: 0,
            median: 0,
            dataProcessedLength: 0,
          },
          agesAndGendersMetrics,
          locationStats: recruitSessionLocationStats,
        });

        if (recruitSessionLocationStats.length === 1) {
          setCountryStatsLoading(true);
          setSelectedCountry(recruitSessionLocationStats[0].countryId);
          await getRecruitSessionCountryStats(
            selectedSearch.stages?.[0].id,
            recruitSessionLocationStats[0].countryId
          ).then((res) => {
            setCountryStatsDetail({
              ...countryStatsDetail,
              [recruitSessionLocationStats[0].countryId]: res,
            });
            setCountryStatsLoading(false);
          });
        }
      } finally {
        setSearchStatsLoading(false);
      }
    }
  }, [contentToDisplay]);

  const getCountryStats = async (countryId) => {
    setCountryStatsLoading(true);
    await getRecruitSessionCountryStats(selectedSearch.stages?.[0].id, countryId).then((res) => {
      setSelectedCountry(countryId);
      setCountryStatsDetail({ ...countryStatsDetail, [countryId]: res });
      setShowProvincesModal(true);
      setCountryStatsLoading(false);
    });
  };

  useEffect(() => {
    dispatch(
      setNewSearchData({
        step: 1,
        name: selectedSearch ? selectedSearch?.name : '',
        stagesQuantity: 1,
        stages: selectedSearch?.stages ? [selectedSearch?.stages?.[0]] : [],
      })
    );
  }, [selectedSearch]);

  useEffect(() => {
    if (!searchStats?.conversions?.length && newSearch?.stages?.length) {
      const stage = newSearch?.stages[0];
      getCustomSearchStageInvitationsData(stage?.id).then((res) => {
        dispatch(setSearchInvitationsData(res));
      });
    }
  }, [searchStats, newSearch]);

  const copyEmailsErrorList = () => {
    const emails = searchStats?.errorEmailsList.map(({ email }) => email).join(', ');
    navigator.clipboard.writeText(emails);
    setCopyEmailsSuccess(true);

    setTimeout(() => {
      setCopyEmailsSuccess(false);
    }, 2000);
  };

  const [filterScore, setFilterScore] = useState({
    min: 0,
    max: 0,
  });

  const [maxScoresInterval, setMaxScoresInterval] = useState(scoresIntervalsByTens);
  const [minScoresInterval, setMinScoresInterval] = useState(scoresIntervalsByTens);

  const setMaxFilterScore = (e) => {
    setMinScoresInterval(scoresIntervalsByTens.filter((score) => score < e.id));
    setFilterScore({
      ...filterScore,
      max: e.id,
    });
  };

  const setMinFilterScore = (e) => {
    setMaxScoresInterval(scoresIntervalsByTens.filter((score) => score > e.id));
    setFilterScore({
      ...filterScore,
      min: e.id,
      ...(filterScore.min === 0 && filterScore.max === 0 ? { max: 100 } : {}),
    });
  };

  const [totalCandidatesBetweenRanges, setTotalCandidatesBetweenRanges] = useState(0);

  useEffect(() => {
    if (searchStats?.scoreMetrics?.groupedScores) {
      setTotalCandidatesBetweenRanges(
        searchStats?.scoreMetrics?.groupedScores?.reduce((acc, val, index) => {
          if (index >= filterScore.min / 10 && index < filterScore.max / 10) {
            acc += val;
          }
          return acc;
        }, 0)
      );
    }
  }, [filterScore.min, filterScore.max, searchStats]);

  const statusTabs = [
    {
      title: t('SEARCH_CANDIDATES_STATUS_COUNT_INVITED'),
      filter: ['INVITED'],
      statKey: 'invitedProfiles',
      track: 'search-tab-invited-candidates',
    },
    {
      title: t('SEARCH_CANDIDATES_STATUS_COUNT_CREATED_ACCOUNTS'),
      filter: ['UNSTARTED'],
      statKey: 'createdAccounts',
      track: 'search-tab-created-accounts',
    },
    {
      title: t('SEARCH_CANDIDATES_STATUS_COUNT_STARTED'),
      filter: ['IN_PROGRESS', 'PROCESSING'],
      statKey: 'startedInvitations',
      track: 'search-tab-started-invitations',
    },
    {
      title: t('SEARCH_CANDIDATES_STATUS_COUNT_COMPLETED'),
      filter: ['FINISHED'],
      statKey: 'completedInvitations',
      track: 'search-tab-completed-invitations',
    },
  ];

  const estiloIcono = {
    marginTop: '0rem',
  };

  const addTabFilter = (filterToApply) => {
    const isApplied =
      appliedFilters.length &&
      appliedFilters.some((item) => item?.type === 'status' && filterToApply.includes(item.id));
    if (isApplied) {
      dispatch(
        setAppliedFilters([
          ...(appliedFilters.length
            ? appliedFilters.filter((item) => item?.type !== 'status')
            : []),
        ])
      );
    } else {
      const statusFilters = filterToApply.map((filter) => ({
        type: 'status',
        name: `SCREENINGS_INVITATIONS_${filter}`,
        id: filter,
      }));
      dispatch(
        setAppliedFilters([
          ...(appliedFilters.length
            ? appliedFilters.filter((item) => item?.type !== 'status')
            : []),
          ...statusFilters,
        ])
      );
    }
    setAllSelected(false);
  };

  const onClickProctoringToggle = () => {
    if (selectedSearch.stages) {
      updateProctoringEnabledRecruitSessionStage(selectedSearch.stages[0].id, {
        proctoringEnabled: !selectedSearch.stages[0].proctoringEnabled,
      })
        .then(() => {
          setShowProctoringEnabledModal(false);
          getCustomSearch(selectedSearch?.id).then((res) => {
            dispatch(setSelectedSearch(res));
          });
        })
        .catch(() => {
          setShowProctoringEnabledModal(false);
        });
    }
  };

  const onClickRequireCompletedProfileToggle = () => {
    if (selectedSearch.stages) {
      updateRecruitSessionStage(selectedSearch.stages[0].id, {
        requireCompletedProfile: !selectedSearch.stages[0].requireCompletedProfile,
      })
        .then(() => {
          setShowRequireCompletedProfileModal(false);
          getCustomSearch(selectedSearch?.id).then((res) => {
            dispatch(setSelectedSearch(res));
          });
        })
        .catch(() => {
          setShowRequireCompletedProfileModal(false);
        });
    }
  };

  const onClickReminderToCandidatesToggle = () => {
    if (selectedSearch.stages) {
      updateRecruitSessionStage(selectedSearch.stages[0].id, {
        reminderToCandidates: !(selectedSearch.stages[0].reminderToCandidates ?? true),
      })
        .then(() => {
          setShowReminderToCandidatesModal(false);
          getCustomSearch(selectedSearch?.id).then((res) => {
            dispatch(setSelectedSearch(res));
          });
        })
        .catch(() => {
          setShowReminderToCandidatesModal(false);
        });
    }
  };

  const HandleEditActions = ({ handleCancel, handleEdit, disableEdit }) => (
    <div className="d-flex flex-row">
      <button
        data-testid="CANCEL_EDIT_END_DATE_BUTTON"
        onClick={handleCancel}
        type="button"
        className="font-montserrat align-self-center unstyled-button bg-transparent text-maya-blue font-weight-700 text-xs px-3 py-1 ml-1"
      >
        {t('CANCEL_EDIT_SEARCH_END_DATE')}
      </button>
      <button
        data-testid="SAVE_EDIT_END_DATE_BUTTON"
        onClick={handleEdit}
        disabled={disableEdit}
        type="button"
        className="d-flex flex-row align-items-center align-self-center rounded-pill bg-maya-blue font-weight-700 text-xs px-3 py-1 maya-blue-thin-border text-white"
      >
        <span className="font-montserrat font-weight-700 text-xs mr-2">
          {t('SAVE_EDIT_SEARCH_END_DATE')}
        </span>
        <SaveIcon className="save-icon-size" fill="#FFFFFF" />
      </button>
    </div>
  );

  const EditButton = ({ handleOnClick, className }) => (
    <button
      data-testid="EDIT_END_DATE_BUTTON"
      onClick={handleOnClick}
      type="button"
      className={`"align-self-center unstyled-button bg-transparent ${
        className || 'px-2 py-1 ml-2'
      }`}
    >
      <EditPurpleIcon />
    </button>
  );

  const submitEditStageName = () => {
    setLoadingNewName(true);
    if (stageData.stageName !== selectedSearch.name) {
      editSearchStage(selectedSearch.stages[0].id, {
        stageName: stageData.stageName,
      })
        .then((res) => {
          selectedSearch.stages[0].name = res.name;
          setSelectedSearch({
            ...selectedSearch,
            name: res.name,
          });
          notification.open({
            message: t('EDIT_STAGE_NAME_EDITED_SUCCESSFULLY'),
            type: 'success',
            style: { fontFamily: 'Montserrat' },
          });
          setModalUpdateName(false);
          setEditStageName(false);
          setLoadingNewName(false);
          setStageData({ ...stageData, name: '' });
          history.push(SCREENING_SEARCHES);
        })
        .catch(() => {
          setLoadingNewName(false);
          notification.open({
            message: t('EDIT_STAGE_NAME_EDIT_ERROR'),
            type: 'error',
            style: { fontFamily: 'Montserrat' },
          });
        });
    } else {
      notification.open({
        message: t('EDIT_STAGE_EQUAL_NAME_ERROR'),
        type: 'error',
        style: { fontFamily: 'Montserrat' },
      });
      setLoadingNewName(false);
      setModalUpdateName(false);
    }
  };

  const setMoveScreeningModal = () => {
    setShowMoveModal(true);
    setStageData({
      ...stageData,
      partnerId: user.id,
      id: selectedSearch.stages[0].id,
    });
  };

  return (
    <>
      {allowCertificateDownloadModal}
      {confirmModalEditMail}
      {confirmModalSendMail}
      {confirmModalSendMailSubmissionsReport}
      {confirmModalStage}
      {confirmModifyModalStage}
      {modalSelectEvaluation}
      {editMailVisible && (
        <EditEmailTemplate
          handleSendRequest={handleSendEmail}
          goBack={handleGoBack}
          viewInfo={false}
          emailDashboard
          setEditMailVisible={setEditMailVisible}
        />
      )}
      <ProfilesSearchProvider
        component={QualitativeFilters}
        isLoading={loaderTable}
        show={showQualitativeFilters}
        setShow={setShowQualitativeFilters}
        setQuantity={setAppliedQualitativeFilters}
        testGroups={
          stageScreening?.testGroups?.filter(
            (testGroup) => testGroup.type === TestGroupTypes.QUALITATIVE
          ) ?? []
        }
      />
      <Modal
        show={showModal}
        relativeCloseButton
        className="rounded px-4 py-2 justify-content-center w-80vw"
        showCloseButton={!loadingModal}
        closeBtnClassname="text-maya-blue font-weight-bold"
        setShow={(e) => closeModal(e)}
      >
        {screeningModalContent()}
      </Modal>
      <ModalCustom
        onCancel={() => setModalUpdateName(false)}
        onCancelText={t('CANCEL_OPERATION_LABEL')}
        onOk={submitEditStageName}
        isLoading={loadingNewName}
        classModal="allow-certificate-download-modal-width"
        subtitleClassName="px-0 px-lg-0"
        onOkText={t('UPDATE_NAME_CONFIRM')}
        setShow={setModalUpdateName}
        show={modalUpdateName}
        showCross={false}
        title={t('UPDATE_NAME_CONSECUENCIES')}
        subtitle={t('UPDATE_NAME_CONSECUENCIES_DETAILS')}
      />
      <ModalMoveScreening
        setShowModal={setShowMoveModal}
        showModal={showMoveModal}
        data={stageData}
      />
      <div className={`w-100 mx-auto h-100 ${editMailVisible ? 'd-none' : ''}`}>
        <BackgroundTop
          title={t(`SCREENING_${contentToDisplay}_CAPSULE_TITLE`)}
          routes={breadcrumbAlkymetricsEvaluation}
          className="position-relative"
          titleClassname="text-xl ml-5"
        />
        {loading ? (
          <Spinner show />
        ) : (
          <div className="mw-100">
            <div className="position-absolute" />
            <div>
              <div className="px-2">
                <div ref={containerRef} className="w-100" />
                <div className="position-relative d-flex flex-column">
                  <div className="container-search-dashboard container-info bg-white rounded p-4 mx-auto mb-3">
                    <div className="d-flex flex-wrap justify-content-between align-items-start">
                      <div className="d-flex flex-wrap">
                        {editStageName ? (
                          <div className="d-flex flex-column mx-3">
                            <input
                              minLength={1}
                              maxLength={50}
                              placeholder={t('SCREENING_NEW_NAME_PLACEHOLDER')}
                              value={stageData?.stageName}
                              type="text"
                              onChange={(e) =>
                                setStageData({ ...stageData, stageName: e.target.value })
                              }
                              className="p-2 font-montserrat rounded mt-2 text-sm screening-name-input mb-2"
                            />
                            <HandleEditActions
                              handleCancel={() => setEditStageName(false)}
                              handleEdit={() => setModalUpdateName(true)}
                              disableEdit={!(stageData?.stageName?.length > 0)}
                            />
                          </div>
                        ) : (
                          <h3 className="font-montserrat text-truncate text-base font-weight-600 pl-lg-0 pt-1 text-muted-alkemy">
                            {t('CREATE_SEARCH_CAPSULE_TITLE')}
                            <span className="mr-3 font-montserrat">{`: ${selectedSearch.name}`}</span>
                          </h3>
                        )}
                        {!editStageName && (
                          <EditButton
                            className="mr-4 mb-4"
                            handleOnClick={() => setEditStageName(true)}
                          />
                        )}
                        <Ticket
                          className={`${
                            selectedSearch?.active &&
                            new Date(selectedSearch?.stages?.[0]?.endDate) > new Date()
                              ? 'bg-success'
                              : 'closed-bg'
                          } text-white font-weight-bold font-montserrat text-xs h-50 p-1 rounded mb-3`}
                          text={
                            !selectedSearch?.active
                              ? t('DASHBOARD_SEARCH_CLOSE')
                              : new Date(selectedSearch?.stages?.[0]?.endDate) > new Date()
                              ? t('DASHBOARD_SEARCH_OPEN')
                              : t('DASHBOARD_SEARCH_EXPIRED')
                          }
                        />
                        <Ticket
                          className={`${
                            selectedSearch?.exclusiveUsers ? 'bg-info-subtle' : 'd-none'
                          } text-white font-weight-bold font-montserrat text-xs h-50 p-1 mx-1 rounded mb-3`}
                          text={t('DASHBOARD_SEARCH_EXCLUSIVE')}
                        />
                        <button
                          onClick={() => {
                            handleScreeningModalData(screeningId);
                          }}
                          className={`align-self-center mb-3 pb-1 ml-3 border-0 bg-white text-decoration-none font-montserrat font-weight-600 line-height-17 outline-none letter-spacing-05 mt-1 gray-subtitle text-xs text-maya-blue ${
                            editStageName ? 'mb-5' : ''
                          }`}
                          type="button"
                        >
                          {t('VIEW_SCREENING_DETAILS')}
                        </button>
                      </div>
                      <div
                        className={`d-flex flex-wrap ${
                          selectedSearch?.active ? 'justify-content-between' : 'justify-content-end'
                        }`}
                      >
                        {selectedSearch?.active && (
                          <>
                            <Modal
                              show={showConfirmationModal}
                              className="close-search-modal-container"
                              closeBtnClassname="text-maya-blue font-weight-bold"
                              setShow={setShowConfirmationModal}
                              showCloseButton={false}
                            >
                              {confirmCloseSearchModalContent()}
                            </Modal>
                            <button
                              onClick={() => setShowConfirmationModal(true)}
                              disabled={disabledWorkspaceFunctions}
                              className="btn-close-search align-self-center bg-transparent rounded-pill outline-none font-weight-bold font-montserrat text-xs py-1 px-3"
                              type="button"
                            >
                              {t('SCREENING_DASHBOARD_FINISH_SEARCH')}
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                    {selectedSearch?.stages &&
                      selectedSearch?.active &&
                      !selectedSearch?.stages[0]?.emptyDates && (
                        <DisabledFeature
                          className="d-flex align-items-center w-100"
                          featureId={PlanFeaturesIds.SCREENINGS} // TODO ELIMINAR CAMBIO
                        >
                          <div className="d-flex flex-row align-items-center">
                            <span className="font-montserrat text-black text-base font-weight-500 ml-1">
                              {t('EDIT_STAGE_DATE_FROM')}
                            </span>
                            <span className="font-montserrat font-weight-600 m-1">
                              {moment(selectedSearch.stages[0].startDate).format(
                                'DD/MM/YYYY HH:mm A'
                              )}
                            </span>
                            <span className="font-montserrat font-weight-500 mr-1">
                              {t('EDIT_STAGE_DATE_UNTIL')}
                            </span>
                            {editDate ? (
                              <div className="d-flex flex-row align-items-center">
                                <CalendarDatePicker
                                  dateType="endDate"
                                  iconClassNames="ml-4 pl-2"
                                  buttonClassNames="search-date-input"
                                  showIconAlways
                                  stageData={{
                                    ...stageData,
                                    endDate: {
                                      ...stageData.endDate,
                                      day: stageData.endDate.day,
                                    },
                                  }}
                                  setStageData={setStageData}
                                />
                                <DropdownSelect
                                  className="search-hour-input"
                                  outsidePadding={false}
                                  options={listOfHours()}
                                  selected={`${stageData.endDate.hours.hours}:${stageData.endDate.hours.minutes}`}
                                  selectedPlaceholder
                                  setState={(e) => {
                                    handleHourChange(e);
                                  }}
                                  arrowColor="#303030"
                                  maxHours={23}
                                  maxMinutes={59}
                                />
                              </div>
                            ) : (
                              <span className="font-montserrat font-weight-600 ml-1">
                                {moment(selectedSearch.stages[0].endDate).format(
                                  'DD/MM/YYYY HH:mm A'
                                )}
                              </span>
                            )}
                            {editDate ? (
                              <HandleEditActions
                                handleCancel={() => setEditDate(false)}
                                handleEdit={submitDateEdition}
                              />
                            ) : (
                              <EditButton handleOnClick={() => setEditDate(true)} />
                            )}
                          </div>
                        </DisabledFeature>
                      )}
                    <div className="d-flex mt-4 justify-content-between">
                      <div className="d-flex align-items-center">
                        {contentOptions.map((option) => (
                          <SelectableButton
                            key={option.value}
                            className="mr-2"
                            onClick={() => setContentToDisplay(option.value)}
                            text={t(option.label)}
                            selected={contentToDisplay === option.value}
                          />
                        ))}
                      </div>
                      <div className="d-flex justify-content-between  w-100 flex-md-wrap flex-sm-wrap">
                        <div
                          role="button"
                          tabIndex={0}
                          onKeyDown={() => {}}
                          onClick={() =>
                            !disabledWorkspaceFunctions && setShowCertificateDownloadModal(true)
                          }
                          className="d-flex p-2 align-items-center ml-2  "
                          style={{ maxWidth: '200px' }}
                        >
                          <InfoIcon />
                          <span className="font-montserrat ml-2 font-weight-500 text-xs">
                            {t('SEARCH_DASHBOARD_ALLOW_DOWNLOAD_CERTIFICATES_TEXT')}
                          </span>
                          <GenericToggle
                            disabled
                            isEditTG={disabledWorkspaceFunctions}
                            defaultActive={selectedSearch?.allowCertificateDownload}
                            actionFunction={() => {}}
                          />
                        </div>
                        {selectedSearch?.stages ? (
                          <>
                            <div
                              role="button"
                              tabIndex={0}
                              onKeyDown={() => {}}
                              onClick={() =>
                                !disabledWorkspaceFunctions && setShowProctoringEnabledModal(true)
                              }
                              className="d-flex p-2 align-items-center ml-2  "
                              style={{ maxWidth: '200px' }}
                              // style={{ border: '1px solid black' }}
                            >
                              <InfoIcon />
                              <span className="font-montserrat ml-2 font-weight-500 text-xs">
                                {selectedSearch.stages[0].proctoringEnabled
                                  ? t('DISABLED_PROCTORING_DROPDOWN')
                                  : t('ENABLED_PROCTORING_DROPDOWN')}
                              </span>
                              <GenericToggle
                                disabled
                                isEditTG={disabledWorkspaceFunctions}
                                defaultActive={selectedSearch.stages[0].proctoringEnabled}
                                actionFunction={() => {}}
                              />
                            </div>
                            <ModalProctoringEnabled
                              data={{
                                proctoringEnabled: selectedSearch.stages[0].proctoringEnabled,
                              }}
                              setShowModal={setShowProctoringEnabledModal}
                              showModal={showProctoringEnabledModal}
                              submitAction={onClickProctoringToggle}
                            />
                          </>
                        ) : null}
                        {selectedSearch?.stages ? (
                          <>
                            <div
                              role="button"
                              tabIndex={0}
                              onKeyDown={() => {}}
                              onClick={() =>
                                !disabledWorkspaceFunctions &&
                                setShowRequireCompletedProfileModal(true)
                              }
                              className="d-flex p-2 align-items-center ml-2 "
                              style={{ maxWidth: '200px' }}
                            >
                              <InfoIcon />
                              <span className="font-montserrat ml-2 font-weight-500 text-xs">
                                {selectedSearch.stages[0].requireCompletedProfile
                                  ? t('DISABLE_USER_PROFILE')
                                  : t('ENABLE_USER_PROFILE')}
                              </span>
                              <GenericToggle
                                disabled
                                isEditTG={disabledWorkspaceFunctions}
                                defaultActive={selectedSearch.stages[0].requireCompletedProfile}
                                actionFunction={() => {}}
                              />
                            </div>
                            <ModalUserProfileEnabled
                              data={{
                                requireCompletedProfile:
                                  selectedSearch.stages[0].requireCompletedProfile,
                              }}
                              setShowModal={setShowRequireCompletedProfileModal}
                              showModal={showRequireCompletedProfileModal}
                              submitAction={onClickRequireCompletedProfileToggle}
                            />
                          </>
                        ) : null}
                        {selectedSearch?.stages ? (
                          <>
                            <div
                              role="button"
                              tabIndex={0}
                              onKeyDown={() => {}}
                              onClick={() =>
                                !disabledWorkspaceFunctions &&
                                setShowReminderToCandidatesModal(true)
                              }
                              className="d-flex p-2 align-items-center ml-2 "
                              style={{ maxWidth: '200px' }}
                            >
                              <InfoIcon />
                              <span className="font-montserrat ml-2 font-weight-500 text-xs">
                                {selectedSearch.stages[0].reminderToCandidates ?? true
                                  ? t('DISABLE_TEST_REMINDER')
                                  : t('ENABLE_TEST_REMINDER')}
                              </span>
                              <GenericToggle
                                disabled
                                isEditTG={disabledWorkspaceFunctions}
                                defaultActive={
                                  selectedSearch.stages[0].reminderToCandidates ?? true
                                }
                                actionFunction={() => {}}
                              />
                            </div>
                            <ModalReminderToCandidateEnabled
                              data={{
                                reminderToCandidates:
                                  selectedSearch.stages[0].reminderToCandidates ?? true,
                              }}
                              setShowModal={setShowReminderToCandidatesModal}
                              showModal={showReminderToCandidatesModal}
                              submitAction={onClickReminderToCandidatesToggle}
                            />
                          </>
                        ) : null}
                      </div>

                      <div className="mx-3 font-montserrat ml-2 font-weight-500 text-xs">
                        <DuplicateEvaluation
                          dashboard
                          screeningId={
                            selectedSearch?.stages ? selectedSearch.stages[0].levelId : ''
                          }
                          name={selectedSearch?.name}
                          isDraft={false}
                          disabled={disabledWorkspaceFunctions}
                        />
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => setMoveScreeningModal()}
                          className="d-flex py-2 align-items-left mt-2 font-montserrat text-sm font-weight-500 purple-color "
                          style={{ maxWidth: '200px' }}
                        >
                          {t('MOVE_WORKSPACE_MENU').split(' ')[0]}
                          <MoveScreeningIcon className="ml-1" color="#4bafe1" />
                        </div>
                      </div>
                    </div>
                  </div>
                  {contentToDisplay === ContentToDisplayEnum.DASHBOARD && (
                    <div className="container-info container-search-dashboard pb-0 mb-0 bg-white rounded px-4 pt-4 mx-auto">
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <div className="d-flex flex-row">
                          <div className="d-flex flex-column align-items-center justify-content-start mb-4">
                            {!isNullOrUndefined(selectedSearch?.stats?.totalInvitations) && (
                              <span
                                data-testid="assessment-invitations-total-candidates"
                                className="total-invitations-quantity font-montserrat font-weight-600"
                              >
                                {formatNumberWithThousandsSeparator(
                                  selectedSearch?.stats?.totalInvitations
                                )}
                              </span>
                            )}
                            <span className="font-weight-500 font-montserrat text-sm text-muted">
                              {t('SEARCH_CANDIDATES_STATUS_COUNT_TOTAL_CANDIDATES')}
                            </span>
                          </div>
                          {selectedSearch?.stats?.pendingCreditsEvaluations > 0 && (
                            <div className="d-flex align-items-center ml-1 mb-5">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip className="font-montserrat">
                                    {t('EVALUATIONS_PENDING_CREDITS')}
                                  </Tooltip>
                                }
                              >
                                <div>
                                  <a
                                    className="mx-1 font-montserrat text-sm diamond-background d-flex align-items-center justify-content-center rounded-circle"
                                    href={process.env.RAZZLE_RUNTIME_SALES_CALENDAR_URL}
                                  >
                                    <InfoIcon color="#ff7676" />
                                  </a>
                                </div>
                              </OverlayTrigger>
                            </div>
                          )}
                        </div>
                        <div>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip className="font-montserrat">
                                {t('ADD_INVITATIONS_CHART_TOOLTIP')}
                              </Tooltip>
                            }
                          >
                            <button
                              data-testid="button-add-candidates"
                              onClick={addInvitations}
                              className="align-self-center bg-transparent border-0 outline-none py-1 ml-auto mr-3"
                              disabled={disabledWorkspaceFunctions}
                              type="button"
                            >
                              <Person fill="#757575" />
                            </button>
                          </OverlayTrigger>
                          {loadingDownload ? (
                            <HorizontalLoader className="loader-download-csv align-self-center px-4 py-1 ml-auto" />
                          ) : (
                            <>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip className="font-montserrat">
                                    {t('SEARCH_SUMMARY')}
                                  </Tooltip>
                                }
                              >
                                <button
                                  data-testid="button-download-csv"
                                  onClick={exportCSV}
                                  className={`align-self-center border-0 bg-transparent ${
                                    invitationsList?.length
                                      ? 'selected-border'
                                      : 'unselected-border cursor-not-allowed'
                                  } mx-2 py-1 ml-auto`}
                                  type="button"
                                  disabled={!invitationsList?.length}
                                >
                                  <SearchCloudDownload fill="#757575" />
                                </button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip className="font-montserrat font-size-tiny">
                                    {t('SEARCH_DETAIL_RESULTS')}
                                  </Tooltip>
                                }
                              >
                                <button
                                  data-testid="button-send-submissions-report"
                                  onClick={() => {
                                    setModalSendMailSubmissionsReportVisible(true);
                                  }}
                                  className={`align-self-center border-0 bg-transparent ${
                                    invitationsList?.length
                                      ? 'selected-border'
                                      : 'unselected-border cursor-not-allowed'
                                  } mx-2 py-1 ml-auto`}
                                  type="button"
                                  disabled={!invitationsList?.length}
                                >
                                  <EmailIcon />
                                </button>
                              </OverlayTrigger>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="d-flex align-items-start justify-content-between mb-4">
                        {statusTabs.map((tab, index) => {
                          const selected =
                            appliedFilters.length &&
                            tab.filter.includes(
                              appliedFilters?.find((item) => item.type === 'status')?.id
                            );

                          return (
                            <button
                              data-testid="assessment-invitations-status-tab"
                              key={tab.title}
                              type="button"
                              className={`filter-tab-button text-nowrap d-flex align-items-center justify-content-center font-montserrat text-sm ${
                                selected && 'selected-tab-button'
                              } ${index !== statusTabs.length - 1 && 'mr-3'}`}
                              onClick={() => {
                                addTabFilter(tab.filter);
                                TrackEvent(tab.track);
                              }}
                            >
                              {tab.title}
                              <span className="font-montserrat font-weight-600 ml-2">
                                {selectedSearch?.stats &&
                                  formatNumberWithThousandsSeparator(
                                    selectedSearch?.stats[tab.statKey]
                                  )}
                              </span>
                            </button>
                          );
                        })}
                      </div>
                      <div className="mt-2 mb-4 w-100 d-flex align-items-center">
                        <div className="mr-3">
                          <PersonalizeSearchTable
                            stageScreening={stageScreening}
                            savedPersonalization={savedPersonalization}
                            id={id}
                          />
                        </div>
                        {stageScreening?.testGroups?.some(
                          (testGroup) => testGroup.type === TestGroupTypes.QUALITATIVE
                        ) && (
                          <button
                            type="button"
                            className="button-search-config-qualitative d-flex align-items-center"
                            onClick={() => setShowQualitativeFilters(true)}
                          >
                            <ConfigIcon />
                            <span className="font-montserrat ml-2">
                              {`${t(
                                'DASHBOARD_SEARCH_CONFIG_QUALITATIVE_SKILLS'
                              )} (${appliedQualitativeFilters})`}
                            </span>
                          </button>
                        )}
                        <button
                          type="button"
                          data-testid="dashboard-show-more-filters-button"
                          onClick={() => setShowMoreFilters(!showMoreFilters)}
                          className="d-flex align-items-center bg-transparent border-0 outline-none ml-auto"
                        >
                          <span className="mr-2 font-montserrat text-muted font-weight-600">
                            {showMoreFilters
                              ? t('DASHBOARD_FILTERS_LESS_FILTERS')
                              : t('DASHBOARD_FILTERS_MORE_FILTERS')}
                          </span>
                          <HeaderArrowIcon
                            color="#757575"
                            className={`dropdown-select-arrow ${
                              showMoreFilters && 'dropdown-select-arrow-open'
                            }`}
                          />
                        </button>
                      </div>
                      <div className="d-flex mt-2">
                        <div className="w-100">
                          <ProfilesSearchProvider
                            showFilters={showMoreFilters}
                            handleInput={handleInput}
                            handleSubmit={handleSubmit}
                            textInput={textInput}
                            component={ProfilesSearchDashboard}
                            isLoading={isLoading}
                            setAllSelected={setAllSelected}
                            showSaveBtn={false}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {loaderTable ? (
                    <Spinner show />
                  ) : (
                    <>
                      {loadingStage ? (
                        <Spinner show />
                      ) : (
                        <>
                          {contentToDisplay === ContentToDisplayEnum.DASHBOARD ? (
                            <div className="container-search-dashboard container-info new-search-container bg-white rounded px-4 pb-4 pt-0 mx-auto mb-5">
                              <div className="d-flex justify-content-between mb-0 ml-1 align-items-center mt-1 min-height w-100">
                                <div className="d-flex flex-row align-items-center justify-content-center">
                                  <div className="d-flex align-items-center ml-2">
                                    {Boolean(quantity) && (
                                      <div>
                                        <span className="mr-1 font-montserrat text-sm total-number-text font-weight-bold">
                                          {allSelected ? totalInvitations : quantity}
                                        </span>
                                        <span className="mr-2 font-montserrat text-sm gray-subtitle font-weight-600">
                                          {t('CANDIDATES_SELECTED')}
                                        </span>
                                      </div>
                                    )}
                                    {!allSelected &&
                                      quantity === invitationsList?.length &&
                                      quantity < totalInvitations && (
                                        <button
                                          data-testid="button-select-all-candidates"
                                          onClick={() => setAllSelected(true)}
                                          className="border font-weight-700 font-montserrat text-xs font-bold pill-button text-maya-blue border-maya-blue bg-white"
                                          type="button"
                                        >
                                          {t('SELECT_ALL_THE_SEARCH_PROFILES').replace(
                                            '{totalInvitations}',
                                            totalInvitations
                                          )}
                                        </button>
                                      )}
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  {Boolean(quantity) && (
                                    <div
                                      className="d-flex flex-row align-items-center justify-content-center"
                                      data-testid="batch-actions-container"
                                    >
                                      <div className="d-md-block d-none mr-2">
                                        <DropdownButton
                                          options={processStatusStageOptions}
                                          setState={({ id }) => startStageConfirmation(id)}
                                          id="value"
                                          startPassStageConfirmation
                                          content={
                                            <button
                                              data-testid="btn-dropdow-status-all"
                                              type="button"
                                              className="batch-action-button ml-3 mt-1 d-flex py-1 px-2 maya-blue-thin-border align-items-center"
                                              onClick={() => setChangeArrow(!changeArrow)}
                                            >
                                              <span className="text-xs font-montserrat font-weight-600 mw-100 gray-subtitle">
                                                {t('DASHBOARD_INVITATIONS_PROCESS_STATUS')}
                                              </span>
                                              <img
                                                src={arrowBlue}
                                                alt="arrow"
                                                className={`dropdown-select-arrow ml-2 ${
                                                  changeArrow && 'dropdown-select-arrow-open'
                                                }`}
                                              />
                                            </button>
                                          }
                                        />
                                      </div>
                                      {!disabledWorkspaceFunctions && (
                                        <button
                                          data-testid="button-email-all"
                                          type="button"
                                          className="d-flex flex-row align-items-center pill-button bg-maya-blue text-sm font-montserrat text-white font-weight-700 mr-2"
                                          onClick={startEditMailConfirmation}
                                        >
                                          <span className="mr-2 text-nowrap">
                                            {t('DASHBOARD_SEND_MAIL')}
                                          </span>
                                          <EnvelopeIcon fill="#FFF" />
                                        </button>
                                      )}
                                      {!disabledWorkspaceFunctions && (
                                        <button
                                          data-testid="button-toggle-candidates-batch"
                                          type="button"
                                          className="d-flex flex-row align-items-center pill-button bg-maya-blue text-sm font-montserrat text-white font-weight-700 mr-2"
                                          onClick={() => startModifyStageConfirmation(null, true)}
                                        >
                                          <span className="mr-2 text-nowrap">
                                            {t('DASHBOARD_MODIFY_CANDIDATE')}
                                          </span>
                                          <FontAwesomeIcon icon={faEyeSlash} style={estiloIcono} />
                                        </button>
                                      )}
                                      {!disabledWorkspaceFunctions && (
                                        <button
                                          data-testid="button-toggle-candidates-batch"
                                          type="button"
                                          className="d-flex flex-row align-items-center pill-button bg-maya-blue text-sm font-montserrat text-white font-weight-700 mr-2"
                                          onClick={() => startAddingMemberToStageConfirmation(null)}
                                        >
                                          <span className="mr-2 text-nowrap">
                                            {t('DASHBOARD_ADD_CANDIDATE')}
                                          </span>
                                          <ScienceIcon width="18" height="18" color="#FFF" />
                                        </button>
                                      )}
                                      {!disabledWorkspaceFunctions && (
                                        <button
                                          data-testid="button-delete-candidates-batch"
                                          type="button"
                                          className="d-flex flex-row align-items-center pill-button bg-red-danger text-sm mx-auto font-montserrat font-weight-700 text-white "
                                          onClick={() => startModifyStageConfirmation(null, false)}
                                        >
                                          <span className="mr-2">
                                            {t('DASHBOARD_DELETE_CANDIDATE')}
                                          </span>
                                          <TrashIcon fill="#FFF" />
                                        </button>
                                      )}
                                    </div>
                                  )}
                                  {(!!appliedFilters?.filter(
                                    (filter) =>
                                      filter?.type !== 'order' && filter.type !== 'orderBy'
                                  ).length > 0 ||
                                    Boolean(query.search)) && (
                                    <div className="w-100 d-flex justify-content-end">
                                      <button
                                        className="border ml-2 font-weight-700 font-montserrat text-xs font-bold pill-button text-maya-blue border-maya-blue bg-white"
                                        type="button"
                                        data-testid="clear-filters"
                                        onClick={() => {
                                          setTextInput('');
                                          dispatch(clearAppliedFilters());
                                        }}
                                      >
                                        {t('PROFILES_CLEAR_FILTERS')}
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {invitationsList?.length ? (
                                <>
                                  <SearchTable
                                    partialSelection={partialSelection}
                                    toggleSelectAll={toggleSelectAll}
                                    invitationsList={invitationsList}
                                    quantity={quantity}
                                    handleOrder={handleOrder}
                                    query={query}
                                    id={id}
                                    setKeepData={setKeepData}
                                    invitationIdToScroll={invitationIdToScroll}
                                    updateStageSingleCandidate={updateStageSingleCandidate}
                                    processStatusStageOptions={processStatusStageOptions}
                                    size={size}
                                    selected={selected}
                                    allSelected={allSelected}
                                    toggleSelectedId={toggleSelectedId}
                                    isSelected={isSelected}
                                    handleSelectCandidate={handleSelectCandidate}
                                    startModifyStageConfirmation={startModifyStageConfirmation}
                                    startAddingMemberToStageConfirmation={
                                      startAddingMemberToStageConfirmation
                                    }
                                    loadingNextPage={loadingNextPage}
                                    stageScreening={stageScreening}
                                    disabledVisualizator={disabledWorkspaceFunctions}
                                    updateDateLimitInvitation={updateDateLimitInvitation}
                                  />
                                  <ScrollToTop />
                                </>
                              ) : (
                                <EmptyComponent
                                  className="mt-3 py-4"
                                  title={
                                    appliedFilters.length
                                      ? t('SEARCH_STAGE_EMPTY_FILTERS_TITLE')
                                      : selectedSearch?.stats?.pendingCreditsEvaluations === 0
                                      ? t('SEARCH_STAGE_EMPTY_TITLE')
                                      : t('SEARCH_STAGE_PENDING_CREDITS_TITLE')
                                  }
                                  subtitle={
                                    appliedFilters.length
                                      ? t('SEARCH_STAGE_EMPTY_FILTERS_SUBTITLE')
                                      : selectedSearch?.stats?.pendingCreditsEvaluations === 0
                                      ? t('SEARCH_STAGE_EMPTY_SUBTITLE')
                                      : t('SEARCH_STAGE_PENDING_CREDITS_SUBTITLE')
                                  }
                                />
                              )}
                            </div>
                          ) : contentToDisplay === ContentToDisplayEnum.STATS ? (
                            <div
                              data-testid="stats-container"
                              className="container-search-dashboard container-info bg-white rounded p-4 mx-auto mb-5"
                            >
                              <div className="d-flex w-100 charts-container">
                                {searchStatsLoading ? (
                                  <Spinner show />
                                ) : (
                                  <>
                                    {searchStats &&
                                    searchStats?.conversions?.reduce(
                                      (sum, { val }) => sum + val,
                                      0
                                    ) > 0 ? (
                                      <>
                                        <ModalCustom
                                          show={showErrorEmailsModal}
                                          className="close-search-modal-container"
                                          closeBtnClassname="text-maya-blue font-weight-bold"
                                          setShow={setShowErrorEmailsModal}
                                          showCross={false}
                                          onOk={() => setShowErrorEmailsModal(false)}
                                          onCancel={copyEmailsErrorList}
                                          onOkText={t('CLOSE')}
                                          onCancelText={
                                            copyEmailsSuccess ? t('COPIED') : t('COPY_LIST')
                                          }
                                          title={t(
                                            searchStats?.invitationStatus.find(
                                              (stat) => stat.name === 'errorEmails'
                                            ).val === 1
                                              ? 'COUNTER_ERROR_EMAIL_TITLE_MODAL_SINGULAR'
                                              : 'COUNTER_ERROR_EMAIL_TITLE_MODAL_PLURAL'
                                          ).replace(
                                            '{counter}',
                                            searchStats?.invitationStatus.find(
                                              (stat) => stat.name === 'errorEmails'
                                            ).val
                                          )}
                                          input={
                                            <div className="h-emails-error-container overflow-auto">
                                              {searchStats?.errorEmailsList?.map((e) => (
                                                <div
                                                  key={e.email}
                                                  className={`${
                                                    searchStats?.errorEmailsList.length > 1
                                                      ? 'border-bottom pb-2'
                                                      : ''
                                                  } d-flex mt-3`}
                                                >
                                                  <span className="text-left font-montserrat font-weight-600 text-sm mx-2 w-50 text-start">
                                                    {e.email}
                                                  </span>
                                                  <span className="text-left font-montserrat font-weight text-sm mx-2 w-50 text-start">
                                                    {errorsReasonSendgrid.includes(e.errorReason)
                                                      ? t(e.errorReason)
                                                      : t('ERROR_EMAIL_DEFAULT')}
                                                  </span>
                                                </div>
                                              ))}
                                            </div>
                                          }
                                        />
                                        <ModalCustom
                                          show={showProvincesModal}
                                          classModal="province-stats-detail-modal-container"
                                          okBtnClassname="px-4 py-3"
                                          closeBtnClassname="text-maya-blue font-weight-bold"
                                          setShow={setShowProvincesModal}
                                          showCross={false}
                                          onOk={() => setShowProvincesModal(false)}
                                          showOkButton={!countryStatsLoading}
                                          onOkText={t('CLOSE')}
                                          input={
                                            <>
                                              {countryStatsLoading ? (
                                                <Spinner show />
                                              ) : (
                                                <>
                                                  <div className="d-flex flex-column">
                                                    <span className="h3 font-weight-bold font-montserrat text-base">
                                                      {`${t(
                                                        'COUNTRY_STATS_PROVINCES_OF_COUNTRY'
                                                      ).replace(
                                                        '{country}',
                                                        countryStatsDetail[selectedCountry][0]
                                                          .country
                                                      )}`}
                                                    </span>
                                                  </div>
                                                  <div className="h-emails-error-container overflow-auto chart-provinces-container">
                                                    {countryStatsDetail[selectedCountry]
                                                      ?.sort((a, b) => b.usersCount - a.usersCount)
                                                      .map((stat) => (
                                                        <>
                                                          <div
                                                            key={`${stat.province}-${stat.provinceId}`}
                                                            className={`${
                                                              countryStatsDetail[selectedCountry]
                                                                .length > 1
                                                                ? 'border-bottom-divisor pb-2'
                                                                : ''
                                                            } d-flex mt-1 py-3 px-1 mx-2`}
                                                          >
                                                            <span
                                                              data-testid={`country-chart-modal-province-name-${stat.province}`}
                                                              className="chart-country-province-name-text text-left font-montserrat font-weight-500 text-md w-50 text-start"
                                                            >
                                                              {stat.province}
                                                            </span>
                                                            <span
                                                              data-testid={`country-chart-modal-province-count-${stat.province}`}
                                                              className="chart-country-province-count-text font-montserrat font-weight-400 text-md w-50 text-right"
                                                            >
                                                              {stat.usersCount}
                                                            </span>
                                                          </div>
                                                        </>
                                                      ))}
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          }
                                        />
                                        <div className="w-100 d-flex flex-row charts-section-container flex-wrap">
                                          <div className="chart-column-container">
                                            <ChartLayout
                                              fixedHeight
                                              title={t('CHART_FUNNEL_CONVERSIONS')}
                                            >
                                              <div className="funnel-chart-content h-100">
                                                <FunnelChart
                                                  values={searchStats?.conversions.filter(
                                                    (stat) =>
                                                      stat.name !== 'linkInvitations' &&
                                                      stat.name !== 'mailInvitations' &&
                                                      stat.name !== 'appliedByJobPost'
                                                  )}
                                                />
                                              </div>
                                            </ChartLayout>
                                            <ChartLayout title={t('CHART_PIE_INVITATIONS_CHANNEL')}>
                                              <div className="d-flex flex-column funnel-chart-content h-100">
                                                <div className="d-flex justify-content-between mb-2">
                                                  <div>
                                                    <p className="font-montserrat font-weight-600 total-invitations-number">
                                                      {searchStats?.conversions
                                                        .filter((stat) =>
                                                          [
                                                            'linkInvitations',
                                                            'mailInvitations',
                                                            'appliedByJobPost',
                                                          ].includes(stat.name)
                                                        )
                                                        .reduce((sum, obj) => sum + obj.val, 0)}
                                                    </p>
                                                    <span className="text-md font-montserrat total-invitations-text">
                                                      {t('CHART_PIE_TOTAL_INVITATIONS')}
                                                    </span>
                                                  </div>
                                                  <div className="d-flex justify-content-center align-items-center">
                                                    <OverlayTrigger
                                                      placement="top"
                                                      overlay={
                                                        <Tooltip className="font-montserrat">
                                                          {t('ADD_INVITATIONS_CHART_TOOLTIP')}
                                                        </Tooltip>
                                                      }
                                                    >
                                                      <button
                                                        type="button"
                                                        className="button-default mr-4 bg-transparent"
                                                        onClick={addInvitations}
                                                        disabled={disabledWorkspaceFunctions}
                                                      >
                                                        <AddInvitations />
                                                      </button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                      placement="top"
                                                      overlay={
                                                        <Tooltip className="font-montserrat">
                                                          {copySuccess
                                                            ? t('COPIED_INVITATION_LINK')
                                                            : t('COPY_INVITATION_LINK')}
                                                        </Tooltip>
                                                      }
                                                    >
                                                      <button
                                                        type="button"
                                                        className="button-default bg-transparent"
                                                        onClick={copyLink}
                                                        disabled={disabledWorkspaceFunctions}
                                                      >
                                                        {disabledWorkspaceFunctions ? (
                                                          ''
                                                        ) : copySuccess ? (
                                                          <CopyCheck />
                                                        ) : (
                                                          <CopyLink height="22" />
                                                        )}
                                                      </button>
                                                    </OverlayTrigger>
                                                  </div>
                                                </div>
                                                <div className="d-flex">
                                                  <div className="labels-container">
                                                    {[
                                                      ...searchStats?.conversions.filter((stat) =>
                                                        [
                                                          'linkInvitations',
                                                          'mailInvitations',
                                                          'appliedByJobPost',
                                                        ].includes(stat.name)
                                                      ),
                                                    ]
                                                      .reverse()
                                                      .map((item, index) => (
                                                        <ChartLegendItem
                                                          key={item.val}
                                                          value={item.val}
                                                          text={t(
                                                            `CHART_PIE_INVITATIONS_CHANNEL_${item.name}`
                                                          )}
                                                          bgClassName={pieChartColors[index]}
                                                        />
                                                      ))}
                                                  </div>
                                                  <PieChartWithLabels
                                                    values={[
                                                      ...searchStats?.conversions.filter((stat) =>
                                                        [
                                                          'linkInvitations',
                                                          'mailInvitations',
                                                          'appliedByJobPost',
                                                        ].includes(stat.name)
                                                      ),
                                                    ].reverse()}
                                                  />
                                                </div>
                                              </div>
                                            </ChartLayout>
                                            <ChartLayout
                                              fixedHeight
                                              title={t('CHART_FUNNEL_EMAIL_TRACKING')}
                                            >
                                              <div className="d-flex flex-column funnel-chart-content h-100">
                                                <div className="d-flex">
                                                  <div>
                                                    <p className="font-montserrat font-weight-600 total-invitations-number">
                                                      {
                                                        searchStats?.invitationStatus.find(
                                                          (stat) => stat.name === 'invitationsSent'
                                                        ).val
                                                      }
                                                    </p>
                                                    <p className="mb-2 text-md font-montserrat total-invitations-text">
                                                      <PaperPlane />
                                                      <span className="text-md font-montserrat total-invitations-text ml-1">
                                                        {t('CHART_FUNNEL_TOTAL_EMAIL_INVITATIONS')}
                                                      </span>
                                                    </p>
                                                  </div>
                                                  <div className="ml-3 vertical-line pl-3">
                                                    <p className="font-montserrat font-weight-600 total-invitations-number">
                                                      {
                                                        searchStats?.invitationStatus.find(
                                                          (stat) => stat.name === 'errorEmails'
                                                        ).val
                                                      }
                                                    </p>
                                                    <p className="mb-2 text-md font-montserrat total-invitations-text">
                                                      <Bug />
                                                      <span className="text-md font-montserrat total-invitations-text ml-1">
                                                        {t('CHART_FUNNEL_ERROR_EMAIL_INVITATIONS')}
                                                      </span>
                                                      {searchStats?.errorEmailsList.length > 0 && (
                                                        <button
                                                          type="button"
                                                          onClick={() =>
                                                            setShowErrorEmailsModal(true)
                                                          }
                                                          className="border-0 bg-transparent text-md font-weight-600 font-montserrat text-underline ml-2"
                                                        >
                                                          {t('VIEW_DETAILS')}
                                                        </button>
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                                <FunnelChart
                                                  withPercentagesLabel
                                                  values={searchStats?.invitationStatus.filter(
                                                    (i) => i.name !== 'errorEmails'
                                                  )}
                                                />
                                              </div>
                                            </ChartLayout>
                                          </div>
                                          <div className="chart-column-container">
                                            <ChartLayout
                                              fixedHeight={false}
                                              title={t('CHART_SCORES')}
                                            >
                                              <div className="d-flex flex-column funnel-chart-content">
                                                {searchStats?.scoreMetrics?.dataProcessedLength ===
                                                0 ? (
                                                  <>
                                                    <div className="mx-auto mb-4">
                                                      <Group />
                                                    </div>
                                                    <span className="font-montserrat mt-2 font-weight-600 text-lg mb-1 text-center">
                                                      {t('SCORES_CHART_NO_SCORES')}
                                                    </span>
                                                    <p className="font-montserrat text-lg text-center">
                                                      {t('SCORES_CHART_NO_SCORES_SUBTITLE')}
                                                    </p>
                                                  </>
                                                ) : (
                                                  <div>
                                                    <div className="d-flex">
                                                      <div className="mr-3">
                                                        <p
                                                          data-testid="score-chart-average"
                                                          className="font-montserrat font-weight-600 total-invitations-number"
                                                        >
                                                          {searchStats?.scoreMetrics?.average % 1
                                                            ? searchStats?.scoreMetrics?.average?.toFixed(
                                                                1
                                                              )
                                                            : searchStats?.scoreMetrics?.average}
                                                          <span className="font-montserrat font-weight-600 total-invitations-number ml-1">
                                                            {t('OPTION_WEIGHT_PTS_TEXT')}
                                                          </span>
                                                        </p>
                                                        <span className="text-md font-montserrat total-invitations-text">
                                                          {t('SCORES_CHART_AVERAGE')}
                                                        </span>
                                                      </div>
                                                      <div className="vertical-line pl-3">
                                                        <p
                                                          data-testid="score-chart-median"
                                                          className="font-montserrat font-weight-600 total-invitations-number"
                                                        >
                                                          {searchStats?.scoreMetrics?.median % 1
                                                            ? searchStats?.scoreMetrics?.median?.toFixed(
                                                                1
                                                              )
                                                            : searchStats?.scoreMetrics?.median}
                                                          <span className="font-montserrat font-weight-600 total-invitations-number ml-1">
                                                            {t('OPTION_WEIGHT_PTS_TEXT')}
                                                          </span>
                                                        </p>
                                                        <span className="text-md font-montserrat total-invitations-text">
                                                          {t('SCORES_CHART_MEDIAN')}
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="d-flex mt-3">
                                                      <div className="mr-4">
                                                        <span className="text-sm font-montserrat total-invitations-text">
                                                          {t('SCORES_CHART_MIN_SCORE')}
                                                        </span>
                                                        <DropdownSelect
                                                          options={minScoresInterval.map(
                                                            (score) => ({
                                                              id: score,
                                                              name: score,
                                                              label: score,
                                                            })
                                                          )}
                                                          outsidePadding={false}
                                                          selected={filterScore.min}
                                                          selectedPlaceholder
                                                          setState={setMinFilterScore}
                                                          placeholderClassname="ml-1"
                                                          className="score-dropdown-width font-weight-500"
                                                          arrowColor="#303030"
                                                        />
                                                      </div>
                                                      <div>
                                                        <span className="text-sm font-montserrat total-invitations-text">
                                                          {t('SCORES_CHART_MAX_SCORE')}
                                                        </span>
                                                        <DropdownSelect
                                                          options={maxScoresInterval.map(
                                                            (score) => ({
                                                              id: score,
                                                              name: score,
                                                              label: score,
                                                            })
                                                          )}
                                                          outsidePadding={false}
                                                          selected={filterScore.max}
                                                          selectedPlaceholder
                                                          setState={setMaxFilterScore}
                                                          placeholderClassname="ml-1"
                                                          className="score-dropdown-width font-weight-500"
                                                          arrowColor="#303030"
                                                        />
                                                      </div>
                                                      <div className="d-flex justify-content-center align-items-end ml-3 mb-1">
                                                        <span className="font-montserrat text-sm font-weight-500">
                                                          {t('SCORES_CHART_TALENTS')}:
                                                          <span
                                                            data-testid="total-cantidates-found"
                                                            className={`font-montserrat text-sm font-weight-600 mx-1 ${
                                                              Boolean(
                                                                totalCandidatesBetweenRanges
                                                              ) && 'text-purple'
                                                            }`}
                                                          >
                                                            {totalCandidatesBetweenRanges}
                                                          </span>
                                                          {totalCandidatesBetweenRanges ? (
                                                            <button
                                                              onClick={() => {
                                                                setContentToDisplay(
                                                                  ContentToDisplayEnum.DASHBOARD
                                                                );
                                                                dispatch(
                                                                  setAppliedFilters([
                                                                    {
                                                                      name: 'minScore',
                                                                      id: filterScore.min,
                                                                      type: 'minScore',
                                                                    },
                                                                    {
                                                                      name: 'maxScore',
                                                                      id: filterScore.max,
                                                                      type: 'maxScore',
                                                                    },
                                                                  ])
                                                                );
                                                              }}
                                                              type="button"
                                                              className="font-montserrat text-sm font-weight-700 filter-in-table-btn text-purple"
                                                            >
                                                              {t('SCORES_CHART_FILTER_IN_TABLE')}
                                                            </button>
                                                          ) : (
                                                            <span>
                                                              <em className="font-montserrat apply-a-filter-text total-invitations-text font-weight-400">
                                                                *{t('SCORES_CHART_APPLY_A_FILTER')}
                                                              </em>
                                                            </span>
                                                          )}
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <ScoresChart
                                                      filterScore={filterScore}
                                                      data={searchStats?.scoreMetrics.groupedScores.map(
                                                        (score) => ({ val: score })
                                                      )}
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            </ChartLayout>
                                            {Boolean(searchStats.agesAndGendersMetrics?.total) && (
                                              <ChartLayout
                                                fixedHeight={false}
                                                title={t('AGE_AND_GENDER_CHART_TITLE')}
                                              >
                                                <div className="funnel-chart-content">
                                                  <AgeAndGenderChart
                                                    agesAndGendersMetrics={
                                                      searchStats.agesAndGendersMetrics
                                                    }
                                                  />
                                                </div>
                                              </ChartLayout>
                                            )}
                                            <ChartLayout
                                              styles={{
                                                height: 'auto',
                                              }}
                                              title={t('SEARCH_DASHBOARD_COUNTRIES')}
                                              fixedHeight={false}
                                            >
                                              <div className="d-flex flex-column funnel-chart-content">
                                                <div className="d-flex justify-content-between">
                                                  <div>
                                                    <p
                                                      data-testid="country-chart-total"
                                                      className="font-montserrat font-weight-600 total-invitations-number"
                                                    >
                                                      {searchStats?.locationStats?.length === 1
                                                        ? countryStatsDetail[
                                                            searchStats?.locationStats[0].countryId
                                                          ].length
                                                        : searchStats?.locationStats?.length}
                                                    </p>
                                                    <span className="text-md font-montserrat total-invitations-text">
                                                      {searchStats?.locationStats?.length === 1
                                                        ? t('COUNTRY_STATS_TOTAL_PROVINCES')
                                                        : t('COUNTRY_STATS_TOTAL_COUNTRIES')}
                                                    </span>
                                                  </div>
                                                  {searchStats?.locationStats?.length === 1 ? (
                                                    <div className="d-flex align-items-center chart-country-single-container">
                                                      <img
                                                        data-testid={`country-chart-single-country-img-${searchStats?.locationStats[0].countryName}`}
                                                        className="mr-3 country-flag-large-img"
                                                        alt={`FLAG_${searchStats?.locationStats[0].isoCode}`}
                                                        src={`${process.env.RAZZLE_RUNTIME_ASSETS}/assets/countries/${searchStats?.locationStats[0].isoCode}.svg`}
                                                      />
                                                      <span
                                                        data-testid={`country-chart-single-country-name-${searchStats?.locationStats[0].countryName}`}
                                                        className="font-montserrat text-xxl font-weight-600 text-truncate d-block"
                                                      >
                                                        {searchStats?.locationStats[0].countryName}
                                                      </span>
                                                    </div>
                                                  ) : null}
                                                </div>
                                                <div className="mt-4 h-emails-error-container overflow-auto chart-location-items-container">
                                                  {searchStats?.locationStats?.length === 1 ? (
                                                    <>
                                                      {countryStatsLoading ? (
                                                        <Spinner show />
                                                      ) : (
                                                        <div className="h-emails-error-container overflow-auto chart-provinces-container">
                                                          {countryStatsDetail[selectedCountry]
                                                            ?.sort(
                                                              (a, b) => b.usersCount - a.usersCount
                                                            )
                                                            .map((stat) => (
                                                              <>
                                                                <div
                                                                  key={`${stat.province}-${stat.provinceId}`}
                                                                  className={`${
                                                                    countryStatsDetail[
                                                                      selectedCountry
                                                                    ].length > 1
                                                                      ? 'border-bottom-divisor pb-2'
                                                                      : ''
                                                                  } d-flex mt-1 py-3 px-1 mx-2`}
                                                                >
                                                                  <span
                                                                    data-testid={`country-chart-single-country-province-name-${stat.province}`}
                                                                    className="chart-country-province-name-text text-left font-montserrat font-weight-500 text-md w-50 text-start"
                                                                  >
                                                                    {stat.province}
                                                                  </span>
                                                                  <span
                                                                    data-testid={`country-chart-single-country-province-count-${stat.province}`}
                                                                    className="chart-country-province-name-text font-montserrat font-weight-600 text-md w-50 text-right"
                                                                  >
                                                                    {stat.usersCount}
                                                                  </span>
                                                                </div>
                                                              </>
                                                            ))}
                                                        </div>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {searchStats?.locationStats
                                                        ?.sort(
                                                          (a, b) => b.usersCount - a.usersCount
                                                        )
                                                        .map((stat) => (
                                                          <div
                                                            key={stat.isoCode}
                                                            className="chart-country-item-divisor d-flex justify-content-between pl-2 pr-3 mr-1"
                                                          >
                                                            <div className="chart-country-item d-flex align-items-center">
                                                              <img
                                                                data-testid={`country-chart-country-list-flag-${stat.isoCode}`}
                                                                className="country-flag-img mr-2"
                                                                alt={`FLAG_${stat.isoCode}`}
                                                                src={`${process.env.RAZZLE_RUNTIME_ASSETS}/assets/countries/${stat?.isoCode}.svg`}
                                                              />
                                                              <span
                                                                data-testid={`country-chart-country-list-text-${stat.isoCode}`}
                                                                className="font-montserrat font-weight-500 text-truncate d-block"
                                                              >
                                                                {stat.countryName}
                                                              </span>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                              <span
                                                                data-testid={`country-chart-country-list-count-${stat.isoCode}`}
                                                                className="font-montserrat font-weight-600"
                                                              >
                                                                {stat.usersCount}
                                                              </span>
                                                            </div>
                                                            <div className="chart-country-item-province-container d-flex align-items-center justify-content-end">
                                                              {stat.provincesCount === 1 ? (
                                                                <span
                                                                  data-testid={`country-chart-country-list-single-province-${stat.isoCode}`}
                                                                  className="chart-country-item-province-text font-montserrat font-weight-500"
                                                                >
                                                                  {stat.firstProvince}
                                                                </span>
                                                              ) : (
                                                                <button
                                                                  data-testid={`country-chart-country-list-multiple-provinces-${stat.isoCode}`}
                                                                  type="button"
                                                                  className="chart-country-item-province-detail-btn font-montserrat font-weight-600 border-0 bg-white"
                                                                  onClick={async () => {
                                                                    await getCountryStats(
                                                                      stat.countryId
                                                                    );
                                                                  }}
                                                                >
                                                                  {stat.provincesCount}
                                                                  {t('COUNTRY_STATS_PROVINCES')}
                                                                </button>
                                                              )}
                                                            </div>
                                                          </div>
                                                        ))}
                                                    </>
                                                  )}
                                                </div>
                                              </div>
                                            </ChartLayout>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <div className="w-100 mt-2">
                                        <div className="w-50 mx-auto text-center">
                                          <div className="empty-state-container h-fit-content mx-auto mb-2 d-flex justify-content-center flex-column">
                                            <Lottie animationData={EmptyState} loop />
                                            <p className="font-weight-600 font-montserrat px-2 text-base mb-0 mt-4">
                                              {t('STATS_CHART_NO_DATA')}
                                            </p>
                                          </div>
                                          {selectedSearch?.active ? (
                                            <>
                                              <p className="font-montserrat font-weight-400 font-montserrat text-base text-decoration-none">
                                                {t('STATS_CHART_NO_DATA_SUBTITLE')}
                                              </p>
                                              {disabledWorkspaceFunctions ? (
                                                ''
                                              ) : (
                                                <>
                                                  <DisabledFeature
                                                    className="d-flex flex-column align-items-center"
                                                    childrenClassname="w-100 mt-2"
                                                    featureId={PlanFeaturesIds.SEARCH_LINKS}
                                                  >
                                                    <div className="w-100">
                                                      <div className="d-flex">
                                                        <input
                                                          type="text"
                                                          name="search_link"
                                                          value={
                                                            determineDisabledFeature({
                                                              featureId:
                                                                PlanFeaturesIds.SEARCH_LINKS,
                                                            })
                                                              ? t('NOT_AVAILABLE')
                                                              : searchInvitations?.invitationPublicLink
                                                          }
                                                          className="w-100 url-input-placeholder maya-blue-thin-border py-2 px-3 gray-subtitle font-montserrat text-xs font-weight-600 outline-none"
                                                          disabled
                                                          data-testid="input-search-link"
                                                        />
                                                        <OverlayTrigger
                                                          placement="top"
                                                          overlay={
                                                            <Tooltip
                                                              open={copySuccess}
                                                              className="font-montserrat"
                                                            >
                                                              {t('COPIED_INVITATION_LINK')}
                                                            </Tooltip>
                                                          }
                                                        >
                                                          <button
                                                            type="button"
                                                            className="px-3 py-1 copy-link-btn text-white bg-maya-blue font-montserrat text-xs font-weight-bold outline-none maya-blue-thin-border bg-transparent"
                                                            onClick={copyLink}
                                                          >
                                                            {copySuccess ? (
                                                              <CopyCheck />
                                                            ) : (
                                                              <CopyLink />
                                                            )}
                                                          </button>
                                                        </OverlayTrigger>
                                                      </div>
                                                    </div>
                                                  </DisabledFeature>
                                                  <button
                                                    className="mt-3 mx-auto font-montserrat button-close-modal-submission text-white text-xs font-weight-700 px-3 py-2 d-flex justify-content-center align-items-center border-0 text-decoration-none"
                                                    type="button"
                                                    onClick={addInvitations}
                                                  >
                                                    {t('STATS_CHART_INVITE_BY_EMAIL')}
                                                  </button>
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          ) : null}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div ref={fetchNextUrlRef} />
      </div>
    </>
  );
};

export default Search;
