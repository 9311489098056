const ScoreChart = ({ width = '38' }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8022 17.5575C16.3932 11.619 17.6512 7.73398 20.4262 7.73398C23.2012 7.73398 24.4592 11.619 26.0502 17.5575C27.8262 24.236 29.8612 31.784 37.0762 31.784V28.084C33.0062 28.084 31.5262 23.533 29.6207 16.614C27.9742 10.435 26.2537 4.03398 20.4262 4.03398C14.5987 4.03398 12.8782 10.435 11.2317 16.614C9.38167 23.533 7.84617 28.084 3.77617 28.084V0.333984H0.0761719V37.334H37.0762V33.634H3.77617V31.784C10.9912 31.784 13.0262 24.236 14.8022 17.5575Z"
        fill="#686868"
      />
    </svg>
  );
};

export default ScoreChart;
