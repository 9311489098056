const Ai = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ai-button"
    >
      <path
        d="M3.39323 11.988H0.921875M18.0789 11.988H15.6075M3.39323 7.01273H0.921875M18.0789 7.01273H15.6075M7.01273 3.39323V0.921875M7.01273 18.0789V15.6075M11.988 3.39323V0.921875M11.988 18.0789V15.6075M14.2504 3.39323H4.75037C4.39044 3.39323 4.04524 3.53622 3.79073 3.79073C3.53622 4.04524 3.39323 4.39044 3.39323 4.75037V14.2504C3.39323 14.6103 3.53622 14.9555 3.79073 15.21C4.04524 15.4645 4.39044 15.6075 4.75037 15.6075H14.2504C14.6103 15.6075 14.9555 15.4645 15.21 15.21C15.4645 14.9555 15.6075 14.6103 15.6075 14.2504V4.75037C15.6075 4.39044 15.4645 4.04524 15.21 3.79073C14.9555 3.53622 14.6103 3.39323 14.2504 3.39323Z"
        stroke="#686868"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.57504 5.42773H7.31409L5.42969 13.5706H6.39251L6.82752 11.6621H8.9988L9.42255 13.5706H10.4182L8.57504 5.42773ZM6.95408 10.8468L7.90847 6.3947H7.9516L8.87317 10.8468H6.95408ZM11.2779 5.42773H12.2154V13.5706H11.2779V5.42773Z"
        fill="#686868"
      />
    </svg>
  );
};

export default Ai;
