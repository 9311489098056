const ClockIcon = () => (
  <svg width="30" height="30" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.3345 0C13.562 0 8.98501 1.89588 5.61036 5.27055C2.2357 8.64523 0.339844 13.2223 0.339844 17.9948C0.339844 20.3579 0.805289 22.6979 1.70961 24.8811C2.61392 27.0643 3.9394 29.048 5.61036 30.719C7.28132 32.39 9.26504 33.7155 11.4483 34.6198C13.6315 35.5241 15.9714 35.9896 18.3345 35.9896C20.6976 35.9896 23.0376 35.5241 25.2208 34.6198C27.404 33.7155 29.3877 32.39 31.0587 30.719C32.7296 29.048 34.0551 27.0643 34.9594 24.8811C35.8638 22.6979 36.3292 20.3579 36.3292 17.9948C36.3292 13.2223 34.4333 8.64523 31.0587 5.27055C27.684 1.89588 23.107 0 18.3345 0ZM7.02458 6.68476C10.0242 3.68516 14.0925 2 18.3345 2C22.5766 2 26.6449 3.68516 29.6445 6.68476C32.644 9.68436 34.3292 13.7527 34.3292 17.9948C34.3292 20.0953 33.9155 22.1751 33.1117 24.1157C32.3079 26.0563 31.1297 27.8196 29.6445 29.3048C28.1592 30.7901 26.396 31.9682 24.4554 32.772C22.5149 33.5759 20.435 33.9896 18.3345 33.9896C16.2341 33.9896 14.1542 33.5759 12.2136 32.772C10.2731 31.9682 8.50982 30.7901 7.02458 29.3048C5.53933 27.8196 4.36117 26.0563 3.55737 24.1157C2.75356 22.1751 2.33984 20.0953 2.33984 17.9948C2.33984 13.7527 4.02499 9.68436 7.02458 6.68476ZM19.3347 6.28727C19.3347 5.73499 18.8869 5.28727 18.3347 5.28727C17.7824 5.28727 17.3347 5.73499 17.3347 6.28727V18.5613C17.3347 18.8309 17.4435 19.0891 17.6366 19.2773L25.1898 26.6417C25.5852 27.0273 26.2184 27.0193 26.6039 26.6238C26.9895 26.2284 26.9814 25.5953 26.586 25.2097L19.3347 18.1397V6.28727Z"
      fill="#686868"
    />
  </svg>
);

export default ClockIcon;
