const StarComplete = ({ testid }) => (
  <svg
    data-testid={testid}
    width="20"
    height="18"
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.79619 7.95406L8.27609 8.02989L1.4285 9.0282L1.42778 9.02831C1.0211 9.08729 0.855373 9.59217 1.15227 9.88125L1.15255 9.88153L6.10663 14.7091L6.48336 15.0762L6.39429 15.5946L5.22267 22.4134C5.22267 22.4134 5.22267 22.4134 5.22266 22.4134C5.15282 22.8219 5.58435 23.131 5.94332 22.9407L5.94646 22.939L12.0723 19.7191L12.5375 19.4746L13.0028 19.7191L19.1286 22.939L19.1288 22.9391C19.4947 23.1316 19.9213 22.8158 19.8524 22.4134L18.6808 15.5946L18.5917 15.0762L18.9684 14.7091L23.9225 9.88153L23.9228 9.88125C24.2197 9.59217 24.054 9.08729 23.6473 9.02831L23.6466 9.0282L16.799 8.02989L16.2789 7.95406L16.0464 7.48268L12.9867 1.27904C12.9866 1.27885 12.9865 1.27865 12.9865 1.27846C12.8001 0.903772 12.2704 0.91102 12.0892 1.2772L8.79619 7.95406ZM8.79619 7.95406L9.02868 7.48268L12.0891 1.27757L8.79619 7.95406Z"
      fill="#FFD329"
      stroke="#303030"
      strokeWidth="2"
    />
  </svg>
);

export default StarComplete;
