import React, { useEffect, useRef, useState } from 'react';
import useClickOutside from '../../hooks/useClickOutside';
import { t } from '../../i18n/i18n';
import { Window } from '../../utils/window';
import Checkbox from '../Checkbox/Checkbox';
import './ConfirmModal.scss';

const lsKey = 'favorite-delete-not-ask';

const ConfirmModal = ({ show, setShow, callback, text, confirmButtonText, cancelButtonText }) => {
  const [notAskAgain, setNotAskAgain] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef(null);
  useClickOutside(modalRef, () => setShow(false));

  useEffect(() => {
    if (show) {
      const notAsk = Window().localStorage.getItem(lsKey);
      if (notAsk) {
        setShow(false);
        callback();
      } else {
        setShowModal(true);
      }
    } else {
      setShowModal(false);
    }
  }, [show]);

  const handleNotAskAgain = () => {
    if (notAskAgain) {
      localStorage.setItem('favorite-delete-not-ask', 'true');
    }
  };

  const handleConfirm = () => {
    callback();
    setShow(false);
    handleNotAskAgain();
  };

  const handleCancel = () => {
    setShow(false);
  };

  return (
    <div
      ref={modalRef}
      className={`bg-white position-absolute confirm-modal-container right-0 mr-2 z-index-2 ${
        showModal ? 'show-confirm-modal' : 'not-show-confirm-modal'
      }`}
    >
      <div className={showModal ? 'd-flex flex-column p-3' : 'd-none'}>
        <span className="font-montserrat">{text}</span>
        <div className="d-flex align-items-center mt-3">
          <span className="font-montserrat text-xs mr-1">
            {t('CONFIRM_MODAL_DO_NOT_ASK_AGAIN')}
          </span>
          <Checkbox
            onClick={() => {
              setNotAskAgain((state) => !state);
            }}
          />
        </div>
        <div className="mt-3 d-flex justify-content-end">
          <button type="button" className="button-cancel-modal px-3 mr-2" onClick={handleCancel}>
            <span className="button-cancel-modal-text font-roboto font-weight-bold">
              {cancelButtonText}
            </span>
          </button>
          <button type="button" className="button-confirm-modal px-3" onClick={handleConfirm}>
            <span className="button-confirm-modal-text font-roboto font-weight-bold">
              {confirmButtonText}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};
export default ConfirmModal;
