const InfoIcon = ({ className, color }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style={{ fill: color || '#828282' }}
      d="M12 0C5.37305 0 0 5.37498 0 12C0 18.6289 5.37305 24 12 24C18.627 24 24 18.6289 24 12C24 5.37498 18.627 0 12 0ZM12 5.32258C13.1224 5.32258 14.0323 6.23245 14.0323 7.35484C14.0323 8.47723 13.1224 9.3871 12 9.3871C10.8776 9.3871 9.96774 8.47723 9.96774 7.35484C9.96774 6.23245 10.8776 5.32258 12 5.32258ZM14.7097 17.6129C14.7097 17.9336 14.4497 18.1935 14.129 18.1935H9.87097C9.55031 18.1935 9.29032 17.9336 9.29032 17.6129V16.4516C9.29032 16.131 9.55031 15.871 9.87097 15.871H10.4516V12.7742H9.87097C9.55031 12.7742 9.29032 12.5142 9.29032 12.1935V11.0323C9.29032 10.7116 9.55031 10.4516 9.87097 10.4516H12.9677C13.2884 10.4516 13.5484 10.7116 13.5484 11.0323V15.871H14.129C14.4497 15.871 14.7097 16.131 14.7097 16.4516V17.6129Z"
    />
  </svg>
);

export default InfoIcon;
