import './Spinner.scss';

const Spinner = ({ show }) => {
  if (show) {
    return (
      <div data-testid="spinner" className="d-flex justify-content-center align-center w-100">
        <div className="sk-chase">
          <div className="sk-chase-dot" />
          <div className="sk-chase-dot" />
          <div className="sk-chase-dot" />
          <div className="sk-chase-dot" />
          <div className="sk-chase-dot" />
          <div className="sk-chase-dot" />
        </div>
      </div>
    );
  }
  return null;
};

export default Spinner;
