import { useEffect, useRef, useState } from 'react';
import whiteArrow from '../../assets/savedSearch/fe_drop-down.svg';
import DropDownArrow from '../../assets/screening/DropDownArrow';
import arrowBlue from '../../assets/savedSearch/fe_drop-down-blue.svg';
import { t } from '../../i18n/i18n';
import useClickOutside from '../../hooks/useClickOutside';
import './DropdownSelect.scss';
import ArrowIcon from '../../assets/common/ArrowIcon';
import { isNullOrUndefined } from '../../utils/typesUtils';

const DropdownSelect = ({
  placeholder,
  options = [],
  menuClassnames,
  className,
  setState,
  selected,
  placeholderClassname,
  roundBorder = true,
  allOption,
  selectedPlaceholder,
  listSelect,
  alignCenter,
  outsidePadding,
  position = 'right',
  arrow = true,
  arrowPurple,
  arrowLightBlue,
  arrowColor = '#58C1F5',
  bigArrowLightBlue,
  maxHeight = true,
  responsive,
  disabled,
  arrowDisabled = false,
  searchable,
  width = 'max-content',
  alignEnd,
}) => {
  const [open, setOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [optionSelectedText, setOptionSelectedText] = useState('');

  const ref = useRef(null);
  useClickOutside(ref, () => setOpen(false));

  const handleClick = (value) => {
    setState(value);
    setOpen(false);
  };

  useEffect(() => {
    setOptionSelectedText(
      !isNullOrUndefined(selected)
        ? options.find((op) => op?.id === selected || op?.value === selected)?.label ??
            options.find((op) => op?.id === selected || op?.value === selected)?.name ??
            placeholder
        : placeholder
    );
  }, [selected]);

  useEffect(() => {
    if (options.length && selected) {
      const selectedOption = options.find(
        (option) =>
          (option.id && option.id === selected) || (option.value && option.value === selected)
      );
      if (selectedOption) {
        setState({
          id: selectedOption.value ?? selectedOption.id,
          name: selectedOption.label ?? '',
          ...selectedOption,
        });
      } else {
        setState(null);
      }
    }
  }, []);

  useEffect(() => {
    if (options.length && !filteredOptions.length) {
      setFilteredOptions(options);
    }
  }, [options]);

  return (
    <div
      className={`${outsidePadding ?? 'py-2'} d-flex ${maxHeight && 'dropdown-max-height'} ${
        open && 'dropdown-container-index'
      } ${alignCenter ?? 'align-self-end align-self-sm-center'} ${alignEnd ?? ''}`}
      data-testid="dropdown-select"
    >
      <div
        ref={ref}
        className={`font-roboto font-weight-bold non-selectable dropdown-select-container position-relative w-max-content ${className} ${
          roundBorder && 'border rounded'
        }`}
        style={{ width }}
      >
        <div
          onClick={() => !disabled && setOpen((state) => !state)}
          aria-hidden
          data-testid="dropDownSelect-input"
          className={`dropdown-select-button overflow-hidden ${
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          } w-100 d-flex justify-content-between align-items-center ${
            listSelect ? 'px-2 py-1' : 'p-2'
          }`}
        >
          {searchable && !selected ? (
            <input
              type="text"
              placeholder={selectedPlaceholder && optionSelectedText}
              onChange={(e) => {
                if (!open) {
                  setOpen(true);
                }
                setFilteredOptions(
                  options.filter((item) =>
                    item?.name?.toLowerCase().includes(e.target.value.toLowerCase())
                  )
                );
              }}
              className="border-0 w-100 px-2 outline-none bg-transparent font-montserrat"
              data-testid="dropdown-select-input"
            />
          ) : (
            <span
              className={`dropdown-select-placeholder overflow-hidden font-montserrat ${
                disabled && 'text-muted'
              } ${placeholderClassname}`}
              data-testid="dropdown-select-placeholder-span"
            >
              {selectedPlaceholder && optionSelectedText}
            </span>
          )}
          {arrow &&
            (listSelect ? (
              <img
                src={whiteArrow}
                alt={t('ARROW_ALT')}
                className={`dropdown-select-arrow ${listSelect ? 'ml-2' : 'mx-2'} ${
                  open && 'dropdown-select-arrow-open'
                } ${arrowDisabled && 'disabled-arrow'}`}
                data-testid="dropdown-select-arrow-img"
              />
            ) : (
              <ArrowIcon
                className={`dropdown-select-arrow ${listSelect ? 'ml-2' : 'mx-2'} ${
                  open && 'dropdown-select-arrow-open'
                } ${arrowDisabled && 'disabled-arrow'}`}
                color={arrowColor}
              />
            ))}
          {arrowPurple && (
            <DropDownArrow
              classNames={`dropdown-select-arrow ${open && 'dropdown-select-arrow-open'}`}
            />
          )}
          {bigArrowLightBlue && (
            <DropDownArrow lightBlue classNames={`${open && 'dropdown-select-arrow-open'}`} />
          )}
          {arrowLightBlue && (
            <img
              src={arrowBlue}
              alt={t('ARROW_ALT')}
              className={`dropdown-select-arrow ${listSelect ? 'ml-2' : 'mx-2'} ${
                open && 'dropdown-select-arrow-open'
              }`}
              data-testid="dropdown-select-arrow-blue-img"
            />
          )}
        </div>
        <ul
          className={`dropdown-select-options-container bg-white overflow-auto custom-scrollbar m-0 position-absolute line-height-20 gray-subtitle ${
            open && 'dropdown-select-options-container-open py-2 border'
          } ${position === 'right' ? 'left-0' : 'right-0'} ${
            listSelect && 'text-dark'
          } ${menuClassnames}`}
          data-testid="dropDownSelect-list"
        >
          {allOption && (
            <li
              className={`ml-2 p-2 ${
                disabled ? 'cursor-not-allowed' : 'cursor-pointer'
              } dropdown-selec-option letter-spacing-05 text-sm font-montserrat`}
              onClick={() => handleClick({ name: t('ALL'), id: null })}
              onKeyPress={() => handleClick({ name: t('ALL'), id: null })}
              role="option"
              aria-selected
              data-testid="dropdown-select-option-all-li"
            >
              {t('ALL')}
            </li>
          )}
          {searchable
            ? filteredOptions.map((option) => (
                <div className="d-flex p-2" key={option.id}>
                  <li
                    data-testid={`dropDownSelect-option-${option.id || option.name}`}
                    className={`dropdown-selec-option ${
                      disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                    } font-weight-600 letter-spacing-05 text-xs font-montserrat ${
                      selected &&
                      (selected === option.value || selected === option.id) &&
                      'dropdown-selected-option'
                    } ${responsive && 'text-dark'}`}
                    onClick={() =>
                      handleClick({
                        id: option.value ?? option.id,
                        ...(option.label ? { name: option.label } : {}),
                        ...option,
                      })
                    }
                    onKeyPress={() =>
                      handleClick({
                        id: option.value ?? option.id,
                        ...(option.label ? { name: option.label } : {}),
                        ...option,
                      })
                    }
                    role="option"
                    aria-selected
                  >
                    {option.label ?? option.name}
                  </li>
                </div>
              ))
            : options.map((option, i) => (
                <div className="d-flex p-2" key={`${option.label}-${i}`}>
                  <li
                    data-testid={`dropDownSelect-option-${option.id || option.name}`}
                    className={`dropdown-selec-option ${
                      disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                    } font-weight-600 letter-spacing-05 text-xs font-montserrat ${
                      selected &&
                      (selected === option.value || selected === option.id) &&
                      'dropdown-selected-option'
                    } ${responsive && 'text-dark'}`}
                    onClick={() =>
                      handleClick({
                        id: option.value ?? option.id,
                        ...(option.label ? { name: option.label } : {}),
                        ...option,
                      })
                    }
                    onKeyPress={() =>
                      handleClick({
                        id: option.value ?? option.id,
                        ...(option.label ? { name: option.label } : {}),
                        ...option,
                      })
                    }
                    role="option"
                    aria-selected
                  >
                    {option.label ?? option.name}
                  </li>
                </div>
              ))}
        </ul>
      </div>
    </div>
  );
};

export default DropdownSelect;
