const Icon5 = ({ color = '#BCBCBC' }) => (
  <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0458 10.6287V0.735151C12.0458 0.336575 12.3559 0 12.7544 0C18.2326 0 22.6745 4.44191 22.6745 9.92011C22.6745 10.3187 22.338 10.6287 21.9394 10.6287H12.0458ZM0 12.0458C0 6.67393 3.99019 2.2276 9.16724 1.51902C9.57468 1.46144 9.92011 1.78916 9.92011 2.20102V12.7544L16.8509 19.6852C17.1476 19.9819 17.1255 20.4691 16.7845 20.7082C15.0485 21.9482 12.9227 22.6745 10.6287 22.6745C4.76077 22.6745 0 17.9182 0 12.0458ZM23.3123 12.7544C23.7241 12.7544 24.0474 13.0999 23.9943 13.5073C23.6533 15.9829 22.462 18.1839 20.7215 19.8092C20.4558 20.0572 20.0395 20.0395 19.7826 19.7782L12.7544 12.7544H23.3123Z"
      fill={color}
    />
  </svg>
);

export default Icon5;
