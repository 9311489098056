import React from 'react';

const CreatedCheckIcon = ({ className, testid }) => (
  <svg
    className={className}
    data-testid={testid}
    width="23"
    height="16"
    viewBox="0 0 23 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.9303 0.468677C22.5552 1.09358 22.5552 2.10842 21.9303 2.73332L9.13232 15.5313C8.50742 16.1562 7.49258 16.1562 6.86768 15.5313L0.468677 9.13232C-0.156226 8.50742 -0.156226 7.49258 0.468677 6.86768C1.09358 6.24277 2.10842 6.24277 2.73332 6.86768L8.0025 12.1319L19.6707 0.468677C20.2956 -0.156226 21.3104 -0.156226 21.9353 0.468677H21.9303Z"
      fill="#3D8C6B"
    />
  </svg>
);
export default CreatedCheckIcon;
