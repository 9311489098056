import { Orders } from '../../constants/enums';
import './OrderingTriangles.scss';

const OrderingTriangles = ({ order }) => (
  <div className="position-absolute d-flex flex-column justify-content-between align-items-center ordering-container-triangles">
    <span className="ordering-asc" data-active={order === Orders.ASC} />
    <span className="ordering-desc" data-active={order === Orders.DESC} />
  </div>
);

export default OrderingTriangles;
