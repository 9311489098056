import React, { useEffect, useState } from 'react';
import './GenericToggle.scss';

const GenericToggle = ({
  className = '',
  leftTextClassName,
  rightTextClassname,
  actionFunction,
  actionFunctionValue = (value) => {},
  rightText,
  leftText,
  defaultActive,
  isEditTG,
  loading = false,
  disabled,
  value = null,
}) => {
  const [active, setActive] = useState(defaultActive);

  const isActive = value ?? active;

  useEffect(() => {
    setActive(defaultActive);
  }, [defaultActive]);

  const handleToggle = () => {
    actionFunction();

    setActive((status) => {
      actionFunctionValue(!status);
      return !status;
    });
  };

  return (
    <div className={`align-items-center non-selectable switch-container ${className}`}>
      <span
        className={`font-montserrat font-weight-500 text-sm cursor-pointer ${leftTextClassName} ${
          !isActive ? 'toggle-selected-option' : 'toggle-unselected-option'
        }`}
        onClick={() => {
          if (!loading && !disabled) {
            handleToggle();
          }
        }}
        aria-hidden
      >
        {leftText}
      </span>
      <label
        className={`switch m-0 position-relative d-inline-block mx-1  ${
          loading ? 'cursor-loading' : 'cursor-pointer'
        } ${isActive ? 'switch-active' : 'switch-inactive'} ${isEditTG && 'cursor-not-allowed'}`}
      >
        <input
          type="checkbox"
          data-testid="generic-toggle"
          onChange={() => {
            if (!loading && !disabled) {
              handleToggle();
            }
          }}
          disabled={isEditTG}
          checked={!isActive}
        />
        <span className="font-montserrat slider position-absolute" />
      </label>
      <span
        className={`font-montserrat font-weight-500 text-sm cursor-pointer ${rightTextClassname} ${
          isActive ? 'toggle-selected-option' : 'toggle-unselected-option'
        }`}
        onClick={() => {
          if (!loading && !disabled) {
            handleToggle();
          }
        }}
        aria-hidden
      >
        {rightText}
      </span>
    </div>
  );
};

export default GenericToggle;
