export const verifyCompleteData = (stages, searchData) => {
  let complete = true;
  const requiredData = searchData?.stages?.map((item) =>
    Boolean(
      item?.levelId &&
        item?.startDate?.day &&
        item?.startDate?.hours &&
        item?.endDate?.day &&
        item?.endDate?.hours
    )
  );
  if (stages !== searchData?.stages?.length) complete = false;
  if (!requiredData?.every((item) => item === true)) complete = false;
  if (!searchData?.stagesQuantity) complete = false;
  if (!searchData?.name) complete = false;
  return complete;
};
