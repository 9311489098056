import axios from 'axios';
import { auth0Client } from '../auth0/auth0';
import { Window } from '../utils/window';
import { alkymindBaseURL } from './config';

const client = axios.create({ baseURL: alkymindBaseURL });
client.interceptors.request.use(async (config) => {
  const accessToken = localStorage.getItem('token');
  if (accessToken && !config.newAuthorization) {
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
      ...(config.headers ?? {}),
    };
  }
  if (config.newAuthorization) {
    config.headers = {
      Authorization: config.newAuthorization,
    };
  }
  return config;
});
client.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      const originalRequest = error.config;
      return new Promise((resolve, reject) => {
        auth0Client().checkSession(
          {
            audience: process.env.RAZZLE_RUNTIME_AUTH_ZERO_AUDIENCE,
            scope: 'read:current_user',
          },
          (err, result) => {
            if (err === null && result.accessToken) {
              originalRequest.newAuthorization = `Bearer ${result.accessToken}`;
              if (Window()) {
                localStorage.setItem('token', result.accessToken);
                resolve(client(originalRequest));
              }
            } else {
              reject(err);
            }
          }
        );
      });
    }
    throw error;
  }
);

export const alkymindClient = client;
