const OpenExternalLinkIcon = ({ className }) => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      id="Vector"
      d="M8.89258 0C8.40859 0 8.01758 0.391016 8.01758 0.875C8.01758 1.35898 8.40859 1.75 8.89258 1.75H11.1539L5.64961 7.25703C5.30781 7.59883 5.30781 8.15391 5.64961 8.4957C5.99141 8.8375 6.54648 8.8375 6.88828 8.4957L12.3926 2.98867V5.25C12.3926 5.73398 12.7836 6.125 13.2676 6.125C13.7516 6.125 14.1426 5.73398 14.1426 5.25V0.875C14.1426 0.391016 13.7516 0 13.2676 0H8.89258ZM2.33008 0.875C1.12148 0.875 0.142578 1.85391 0.142578 3.0625V11.8125C0.142578 13.0211 1.12148 14 2.33008 14H11.0801C12.2887 14 13.2676 13.0211 13.2676 11.8125V8.75C13.2676 8.26602 12.8766 7.875 12.3926 7.875C11.9086 7.875 11.5176 8.26602 11.5176 8.75V11.8125C11.5176 12.0531 11.3207 12.25 11.0801 12.25H2.33008C2.08945 12.25 1.89258 12.0531 1.89258 11.8125V3.0625C1.89258 2.82187 2.08945 2.625 2.33008 2.625H5.39258C5.87656 2.625 6.26758 2.23398 6.26758 1.75C6.26758 1.26602 5.87656 0.875 5.39258 0.875H2.33008Z"
      fill="#595959"
    />
  </svg>
);

export default OpenExternalLinkIcon;
