function Address(profile) {
  const city = profile?.address?.city?.displayName;
  const country = profile?.address?.country?.displayName;
  switch (true) {
    case !!city && !!country:
      return `${city}, ${country}`;
    case !!city && !country:
      return city;
    case !city && !!country:
      return country;
    default:
      return undefined;
  }
}

export default Address;
