const EditIcon = () => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="cursor-pointer"
  >
    <path
      d="M9.02297 0.251578C8.77809 0.376564 6.7933 2.21385 4.61519 4.33861L0.671386 8.18817L0.516727 8.93808C0.439397 9.35054 0.284738 10.038 0.181632 10.4754C-0.166351 12.0127 0.0011967 12.8501 0.774492 13.3001C0.980703 13.4126 1.34157 13.5001 1.58645 13.5001C2.21798 13.5001 5.09205 12.9126 5.50448 12.7002C6.136 12.3752 13.4952 5.11352 13.7272 4.60108C13.9721 4.01365 13.9721 2.87628 13.7272 2.30134C13.4436 1.62642 12.4255 0.551544 11.8068 0.264077C11.0851 -0.0858831 9.66738 -0.0858831 9.02297 0.251578ZM11.4975 2.33884C12.1935 3.00126 12.3481 3.45121 12.0646 3.96365C11.9615 4.15113 10.2345 5.90093 8.2239 7.83821L4.57652 11.3503L3.24903 11.5628C2.5144 11.6753 1.88288 11.7378 1.84422 11.7003C1.80555 11.6628 1.89577 11.0253 2.05043 10.2879L2.32108 8.93808L5.90401 5.46348C7.86303 3.5387 9.65449 1.90139 9.86071 1.8014C10.4149 1.55143 10.8015 1.68891 11.4975 2.33884Z"
      fill="#757575"
    />
    <path
      d="M0.28545 14.5749C0.0921267 14.6999 0.001909 14.8874 0.001909 15.1873C0.001909 16.0372 -0.307409 15.9997 6.94867 15.9997C14.1661 15.9997 13.831 16.0372 13.831 15.2248C13.831 14.9748 13.7279 14.7499 13.5475 14.5999C13.2639 14.3874 12.8773 14.3749 6.91001 14.3749C1.38095 14.3749 0.543215 14.3999 0.28545 14.5749Z"
      fill="#757575"
    />
  </svg>
);

export default EditIcon;
