import { useLottie } from 'lottie-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { HOME } from '../../constants/routes';
import { t } from '../../i18n/i18n';

const PaymentStatus = ({ options, title, subtitle }) => {
  const history = useHistory();
  const { View } = useLottie(options);

  return (
    <div data-testid="payment-status-container" className="w-100 mx-auto h-100">
      <div className="px-2">
        <div className="w-100  position-relative mx-auto p-4 rounded d-flex flex-column">
          <div className="d-flex flex-column align-items-center py-4">
            <div className="lottie-container">{View}</div>
            <div className="my-4 d-flex flex-column align-items-center">
              {title && (
                <span className="my-2 font-montserrat font-weight-bold text-xl">{title}</span>
              )}
              {subtitle && (
                <span className="my-2 font-montserrat text-muted text-md ">{subtitle}</span>
              )}
            </div>
            <div>
              <button
                onClick={() => {
                  history.push(HOME);
                }}
                className="align-self-end px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none bg-maya-blue"
                type="button"
              >
                {t('RESET_TESTGROUP_STEP_5_GO_HOME_BUTTON')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentStatus;
