import { t } from '../../../i18n/i18n';
import './ApplicationMethod.scss';

const ApplicationMethod = ({ index = 1, recruitSessionStage }) => (
  <div
    className="d-flex flex-row align-items-center position-relative w-full my-4 formation-border-increment"
    key={index}
  >
    <div className="d-flex flex-column w-full formation-collapsed-container-width mt-4">
      <div className="d-flex flex-row w-full">
        <div className="d-flex flex-column w-full">
          <span className="font-montserrat font-italic text-sm font-weight-300 dashboard-group-name">
            {`${t('CANDIDATE_APPLIED_METHOD')} ${
              recruitSessionStage?.mailSent
                ? t('APPLIED_METHOD_EMAIL_INVITATION')
                : recruitSessionStage?.jobId
                ? t('APPLIED_METHOD_JOBPOST')
                : t('APPLIED_METHOD_LINK')
            }`}
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default ApplicationMethod;
