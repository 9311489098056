export const Behaviors = {
  PROFILE_WATCH_CODE_MESSAGE: 'PROFILE_WATCH_CODE_MESSAGE',
  SWITCH_ENVIRONMENT: 'SWITCH_ENVIRONMENT',
  HIRED_STATUS_TICKET: 'HIRED_STATUS_TICKET',
  SAVE_AND_CONTACT_PROFILES: 'SAVE_AND_CONTACT_PROFILES',
  STATUS_FILTER: 'STATUS_FILTER',
  ADVANCE_STATUS_FILTER: 'ADVANCE_STATUS_FILTER',
  RELOCATION_FILTER: 'RELOCATION_FILTER',
  OPORTUNITY_CHART: 'OPORTUNITY_CHART',
  HOME_PAGE: 'HOME_PAGE',
  PROFILE_BUSINESS_UNIT: 'PROFILE_BUSINESS_UNIT',
  DEFAULT_AVAILABLE_FILTER: 'DEFAULT_AVAILABLE_FILTER',
};

export const spanFilterTypes = {
  academic_level: 'LEVEL',
  study_area: 'AREA',
};
