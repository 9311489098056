import React from 'react';
import CheckIcon from '../../assets/login/CheckIcon';
import { t } from '../../i18n/i18n';
import './TechButton.scss';

const TechButton = ({ tech, selected, disabled, onClick, hideImage }) => (
  <button
    type="button"
    onClick={onClick}
    disabled={!selected && disabled}
    className={`bg-transparent tech-button-border my-1 px-2 py-3 d-flex justify-content-between align-items-center ${
      selected ? 'selected-border' : 'unselected-border'
    }`}
  >
    <div>
      {!hideImage && (
        <img
          alt={t('TECH_BUTTON_IMAGE_ALT')}
          width="24"
          height="24"
          className="mr-2 object-fit-contain"
          src={tech.imageUrl}
        />
      )}
      <span className="font-montserrat font-weight-bold text-muted text-xs">
        {tech?.displayName}
      </span>
    </div>
    {selected && <CheckIcon className="mr-1" width="19" height="19" />}
  </button>
);
export default TechButton;
