import 'react-quill/dist/quill.snow.css';

const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;

export const GenericTextArea = ({
  richText = false,
  maxLength,
  minLength,
  placeholder,
  title,
  disabled,
  className,
  onChange,
  value,
  headers = [1, 2, 3, 4, false],
  fontsType = ['bold', 'italic', 'underline', 'strike'],
  listType = ['ordered', 'bullet'],
  alignType = ['', 'center', 'right'],
}) => {
  const handleChange = (e) => {
    onChange(e);
  };

  return (
    <div className={`align-items-center mt-2 my-2 ${className}`}>
      {richText ? (
        typeof window !== 'undefined' &&
        ReactQuill && (
          <ReactQuill
            theme="snow"
            value={value}
            className="p-2 w-100 font-montserrat rounded text-sm screening-name-input evaluation-name-input mr-2 false"
            modules={{
              toolbar: [
                [{ header: headers }],
                fontsType,
                listType.map((l) => ({ list: l })),
                alignType.map((a) => ({ align: a })),
              ],
            }}
            onChange={(e) => handleChange(e)}
            readOnly={disabled}
            placeholder={placeholder}
            title={title}
          />
        )
      ) : (
        <textarea
          minLength={minLength ?? 1}
          maxLength={maxLength ?? 750}
          placeholder={placeholder}
          title={title}
          value={value}
          disabled={disabled}
          className="p-2 w-100 font-montserrat rounded text-sm screening-name-input evaluation-name-input mr-2 false"
          onChange={handleChange}
        />
      )}
    </div>
  );
};
