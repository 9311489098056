import React from 'react';

const People = ({ className = '' }) => (
  <svg
    className={className}
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1817 0.0257878C12.9456 0.231796 11.8641 1.04646 11.3537 2.14673C11.1384 2.61961 11.0494 2.97076 11.0119 3.51387C10.9136 5.03084 11.7236 6.4448 13.0673 7.10028C13.7181 7.41865 14.0693 7.50293 14.7669 7.50293C15.3615 7.49825 15.6893 7.43738 16.2043 7.23137C17.7821 6.59462 18.756 4.86229 18.4844 3.17677C18.2363 1.62235 17.0517 0.376937 15.5301 0.0679259C15.2023 -0.00230408 14.4673 -0.0210319 14.1817 0.0257878ZM15.2913 1.60362C16.1481 1.83304 16.8083 2.55875 16.9534 3.4296C17.1875 4.85292 15.8484 6.18729 14.4345 5.94383C13.5496 5.78932 12.8379 5.13384 12.6132 4.26767C12.2059 2.70389 13.7275 1.18692 15.2913 1.60362Z"
      fill="#757575"
    />
    <path
      d="M6.00231 2.05295C4.60708 2.34791 3.44127 3.46691 3.11353 4.82469C2.68746 6.56639 3.49277 8.32213 5.07528 9.10403C5.66053 9.38963 6.11468 9.49731 6.75612 9.49731C8.49782 9.49731 9.92114 8.42514 10.3987 6.74898C10.5485 6.2246 10.5485 5.27884 10.3987 4.75446C10.0476 3.52777 9.17202 2.60074 7.96407 2.17936C7.59419 2.04826 7.49587 2.03422 6.87785 2.02485C6.50329 2.01549 6.11 2.02954 6.00231 2.05295ZM7.71124 3.76187C8.10921 3.94915 8.51654 4.33308 8.71319 4.70295C9.31248 5.82195 8.86301 7.17036 7.70188 7.74157C7.37414 7.90544 7.28986 7.92885 6.89658 7.94289C6.55011 7.96162 6.3956 7.94289 6.13809 7.8633C5.27192 7.60111 4.65858 6.86135 4.56026 5.95773C4.46194 5.07751 4.991 4.15048 5.81504 3.76187C6.42838 3.46691 7.09322 3.46691 7.71124 3.76187Z"
      fill="#757575"
    />
    <path
      d="M14.0179 7.98047C13.1611 8.06943 12.8193 8.11625 12.337 8.21457C10.909 8.51422 9.6917 9.08074 8.89107 9.81581L8.58674 10.092L8.28242 10.0452C7.75335 9.96095 5.66986 9.97968 5.05184 10.0733C2.63593 10.4479 0.912958 11.3702 0.280889 12.6344C0.0421074 13.1119 -0.0187585 13.4959 0.00465143 14.3386C0.0186974 14.9051 0.0374254 15.0269 0.126383 15.2141C0.276207 15.5185 0.575855 15.7994 0.875502 15.9118C1.12365 16.0054 1.21261 16.0054 6.84504 15.996L12.5664 15.982L12.8239 15.8369C13.3156 15.5606 13.5262 15.1346 13.5262 14.4042V13.9921H16.9816C20.8161 13.9921 20.685 14.0015 21.0549 13.6831C21.1626 13.5942 21.303 13.4022 21.3779 13.2524C21.509 12.9902 21.509 12.9715 21.509 12.1428C21.509 11.3843 21.4997 11.2672 21.4014 10.9863C20.7974 9.23992 18.2878 8.05538 15.0432 7.98047C14.5844 7.9711 14.1209 7.9711 14.0179 7.98047ZM15.6331 9.54425C16.71 9.64258 17.6839 9.86731 18.4189 10.1997C18.9012 10.4151 19.5005 10.8318 19.7065 11.094C19.9359 11.3843 19.9874 11.5669 19.9874 12.0819V12.4939H16.5836H13.1751L13.044 12.2832C12.8474 11.9742 12.234 11.4264 11.7892 11.1642C11.5739 11.0378 11.1899 10.8412 10.9371 10.7335L10.4736 10.5368L10.6749 10.4198C11.822 9.74558 13.8587 9.38975 15.6331 9.54425ZM8.02491 11.5809C9.66828 11.7729 10.998 12.3113 11.6675 13.0511C11.9156 13.332 11.9812 13.538 11.9812 14.0577V14.4603H6.76077H1.54035V14.0249C1.54035 13.7065 1.56376 13.5427 1.6293 13.4022C2.02259 12.5407 3.70343 11.7635 5.61836 11.5622C6.25979 11.4966 7.37411 11.506 8.02491 11.5809Z"
      fill="#757575"
    />
  </svg>
);

export default People;
