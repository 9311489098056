import React from 'react';

const MoveScreeningIcon = ({ className = '', width = '20', height = '21', color = 'grey' }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 65 75">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.2929 6.87868L32.4142 12H51C52.6569 12 54 13.3431 54 15H56.8349C58.7422 15 60.3651 16.657 59.932 18.6157L56.0776 37.1256C58.4875 39.3205 60 42.4836 60 46C60 52.6274 54.6274 58 48 58C42.7751 58 38.3302 54.6608 36.6828 50H7C5.34315 50 4 48.6569 4 47V9C4 7.34315 5.34315 6 7 6H25.1716C25.9672 6 26.7303 6.31607 27.2929 6.87868ZM57.9753 18.2014L54.3123 35.7924C54.2092 35.7286 54.1051 35.6662 54 35.6054V17H56.8349C57.6684 17 58.095 17.6665 57.9785 18.1867L57.9753 18.2014ZM52 15V34.6828C50.7489 34.2406 49.4025 34 48 34C41.3726 34 36 39.3726 36 46C36 46.6814 36.0568 47.3496 36.1659 48H7C6.44772 48 6 47.5523 6 47V9C6 8.44772 6.44772 8 7 8H25.1716C25.4368 8 25.6911 8.10536 25.8787 8.29289L29.5858 12L12 12C11.4477 12 11 12.4477 11 13C11 13.5523 11.4477 14 12 14L51 14C51.5523 14 52 14.4477 52 15ZM58 46C58 40.4772 53.5228 36 48 36C42.4772 36 38 40.4771 38 46C38 51.5229 42.4772 56 48 56C53.5228 56 58 51.5229 58 46Z"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M49.7071 40.2929C49.4211 40.0069 48.991 39.9213 48.6173 40.0761C48.2436 40.2309 48 40.5955 48 41V42.9166L42 42.9167C41.4477 42.9167 41 43.3644 41 43.9167V48.0833C41 48.3485 41.1054 48.6029 41.2929 48.7904C41.4804 48.978 41.7348 49.0833 42 49.0833L48 49.0833V51C48 51.4045 48.2436 51.7691 48.6173 51.9239C48.991 52.0787 49.4211 51.9931 49.7071 51.7071L54.7071 46.7071C54.8946 46.5196 55 46.2652 55 46C55 45.7348 54.8946 45.4804 54.7071 45.2929L49.7071 40.2929ZM50 43.9166V43.4142L52.5858 46L50 48.5858V48.0833C50 47.8181 49.8946 47.5637 49.7071 47.3762C49.5196 47.1887 49.2652 47.0833 49 47.0833L43 47.0833V44.9167L49 44.9166C49.5523 44.9166 50 44.4689 50 43.9166Z"
      stroke={color}
    />
  </svg>
);

export default MoveScreeningIcon;
