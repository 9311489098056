import { sort } from '../sort';

const firstCharacter = (word) => (word ? word.toLowerCase()[0] : null);

const group = (data, grouper) => {
  const grouped = {};
  data.forEach((item) => {
    const firstChar = firstCharacter(item[grouper]);
    if (firstChar) {
      if (grouped[firstChar]) {
        grouped[firstChar].push(item);
      } else {
        grouped[firstChar] = [item];
      }
    }
  });
  return grouped;
};

const modalGrouper = (data, grouper) => {
  const sorted = sort(data, grouper);
  const grouped = group(sorted, grouper);
  return grouped;
};

export default modalGrouper;
