import React from 'react';

const CameraEmptyState = () => (
  <svg width="80" height="78" viewBox="0 0 80 78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M77.3623 21.418C75.8384 20.4758 73.8787 20.39 72.2791 21.1909L59.0363 27.7848V20.6458C59.0363 17.7923 56.793 15.5459 53.9394 15.5459H22.9451L11.3757 1.8399C9.53392 -0.337421 6.27657 -0.629343 4.09417 1.21629C1.91685 3.05955 1.64438 6.31216 3.48866 8.49067L60.3277 75.6599C61.3495 76.8672 62.8054 77.4871 64.2738 77.4871C65.452 77.4871 66.638 77.0848 67.6094 76.2635C69.7867 74.4204 70.0253 71.1588 68.181 68.9802L59.0363 58.2127V54.8445L72.2593 61.4384C72.9885 61.8029 73.799 61.9833 74.5886 61.9833C75.5322 61.9833 76.5238 61.7247 77.3538 61.2113C78.8752 60.2702 79.8517 58.6075 79.8517 56.8162V25.8131C79.8517 24.0217 78.8837 22.3591 77.3623 21.418Z"
      fill="#5F5F5F"
    />
    <path
      d="M-0.000244141 19.1064V60.4439C-0.000244141 63.2974 2.39234 65.6218 5.24591 65.6218H49.3739L5.0562 13.9597C2.27472 14.0411 -0.000244141 16.3039 -0.000244141 19.1064Z"
      fill="#5F5F5F"
    />
  </svg>
);

export default CameraEmptyState;
