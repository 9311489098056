import React from 'react';

const Play = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0C5.37097 0 0 5.37097 0 12C0 18.629 5.37097 24 12 24C18.629 24 24 18.629 24 12C24 5.37097 18.629 0 12 0ZM16.6452 15.871C16.6452 16.2968 16.2968 16.6452 15.871 16.6452H8.12903C7.70323 16.6452 7.35484 16.2968 7.35484 15.871V8.12903C7.35484 7.70323 7.70323 7.35484 8.12903 7.35484H15.871C16.2968 7.35484 16.6452 7.70323 16.6452 8.12903V15.871Z"
      fill="#F44336"
    />
  </svg>
);

export default Play;
