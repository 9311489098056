import { t } from '../../i18n/i18n';

const setStatus = (statusFilter) => {
  const sexFilterMap = statusFilter
    .filter((filter) => filter.name)
    .map((filter) => ({
      ...filter,
      name: t(`PROFILES_FILTER_STATUS_${filter?.id?.toUpperCase()}`) ?? filter?.name,
    }));

  const sorted = [];
  sexFilterMap.forEach((filter) =>
    filter.id === 'AVAILABLE' ? sorted.unshift(filter) : sorted.push(filter)
  );

  return sorted;
};

export default setStatus;
