import { useState, useEffect } from 'react';
import Input from './Input';
import DeleteForever from '../../assets/screening/DeleteForever';
import { validateEmail, validateName } from '../../validations/screeningValidation';
import './FormRow.scss';
import { t } from '../../i18n/i18n';
import { DatePicker } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const FormRow = ({
  setError,
  allErrors,
  rowIndex,
  handleValidation,
  remove,
  duplicatedEmails = [],
  data = {},
  emailError,
  firstNameError,
  lastNameError,
  setData,
  duplicatedInvitations,
  setDuplicatedInvitations,
}) => {
  const [errors, setErrors] = useState({ firstName: false, lastName: false, email: false });
  const [validate, setValidate] = useState(0);
  const [duplicatedEmail, setDuplicatedEmail] = useState(false);
  const { selectedSearch } = useSelector((state) => state.screenings);

  useEffect(() => {
    if (handleValidation) setValidate((value) => value + 1);
  }, [handleValidation]);

  useEffect(() => {
    setDuplicatedEmail(duplicatedEmails.includes(data.email?.toLowerCase()));
  }, [duplicatedEmails]);

  useEffect(() => {
    if (validate && Object.keys(data).length) {
      setErrors({
        firstName: !validateName(data.firstName),
        lastName: !validateName(data.lastName),
        email: !validateEmail(data.email),
      });
    }
  }, [validate]);

  const baseHandler = (value, key, validationFn) => {
    if (Object.keys(allErrors).includes(String(rowIndex))) {
      setErrors((obj) => ({ ...obj, [key]: !validationFn(value) }));
    }
    setData((obj) => ({ ...obj, [rowIndex]: { ...data, [key]: value } }));
  };

  const handleFirstName = (value) => {
    baseHandler(value, 'firstName', validateName);
  };

  const handleLastName = (value) => {
    baseHandler(value, 'lastName', validateName);
  };

  const handleEmail = (value) => {
    baseHandler(value, 'email', validateEmail);
    setDuplicatedInvitations(false);
  };

  const handleInvitationLimitDate = (value) => {
    const date = moment(value._d, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    baseHandler(date, 'limitDate', () => true);
  };

  useEffect(() => {
    if (validate) {
      const hasErrors = Object.values(errors).some((value) => value);
      setError((value) => ({ ...value, [rowIndex]: hasErrors }));
    }
  }, [errors]);

  const hasFirstNameErrors =
    errors.firstName || (!validateName(data.firstName) && firstNameError)
      ? t('SCREENING_NAME_ERROR')
      : null;

  const hasLastNameErrors =
    errors.lastName || (!validateName(data.lastName) && lastNameError)
      ? t('SCREENING_NAME_ERROR')
      : null;

  const hasEmailErrors =
    errors.email || (!validateEmail(data.email) && emailError)
      ? t('SCREENING_EMAIL_ERROR')
      : duplicatedEmail
      ? t(`${duplicatedInvitations ? 'SCREENING_INVITATION_REPEATED' : 'SCREENING_EMAIL_REPEATED'}`)
      : null;

  return (
    <div data-testid="form-row-send-emails" className="d-flex my-3 flex-wrap">
      <Input
        placeholder={t('SCREENING_FIRSTNAME_PLACEHOLDER')}
        className="pr-2 pr-md-3 flex-grow-1 w-25"
        name="firstName"
        value={data.firstName}
        onChange={handleFirstName}
        error={hasFirstNameErrors}
      />
      <Input
        placeholder={t('SCREENING_LASTNAME_PLACEHOLDER')}
        className="pr-sm-2 pr-md-3 flex-grow-1 w-25"
        name="lastName"
        value={data.lastName}
        onChange={handleLastName}
        error={hasLastNameErrors}
      />
      <Input
        placeholder={t('SCREENING_EMAIL_PLACEHOLDER')}
        className="flex-grow-3 mt-2 mt-sm-0 w-75 w-sm-25"
        name="email"
        value={data.email}
        onChange={handleEmail}
        error={hasEmailErrors}
      />
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{t('DASHBOARD_SEARCH_HEADERS_LIMIT_DATE')}</Tooltip>}
      >
        <div className="px-2">
          <DatePicker
            showTime
            placeholder={t('DASHBOARD_SEARCH_HEADERS_LIMIT_DATE')}
            onOk={handleInvitationLimitDate}
            format="DD-MM-YYYY HH:mm"
            locale={'utc'}
            showSecond={false}
            defaultValue={data.limitDate ? moment(data.limitDate, 'YYYY-MM-DDTHH:mm:ss.SSS') : null}
            disabledDate={(current) => {
              return (
                current < moment().add(2, 'hours') ||
                current >= moment(selectedSearch.stages ? selectedSearch.stages[0]?.endDate : null)
              );
            }}
            className="rounded calendar-invitations h-100"
          />
        </div>
      </OverlayTrigger>
      <button
        type="button"
        className="screening-input-delete-icon px-2"
        tabIndex={-1}
        onClick={() => remove(rowIndex)}
        data-testid={`button-delete-row-form-${rowIndex}`}
      >
        <DeleteForever />
      </button>
    </div>
  );
};

export default FormRow;
