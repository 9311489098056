const CategoriesIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    transform="rotate(90)"
  >
    <path d="M3 4H17V6H3V4ZM3 9H17V11H3V9ZM3 14H17V16H3V14Z" fill="current-color" stroke="none" />
  </svg>
);

export default CategoriesIcon;
