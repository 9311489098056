import { AnswerTypeEnum } from '../../constants/enums';

export const validateIfContainsBasicData = (excelRow, testDifficulty) => {
  const difficulties = testDifficulty.map((item) => item.name);
  const invalidDifficulty = !difficulties.includes(excelRow.difficulty?.toUpperCase());
  const invalidType = ![
    AnswerTypeEnum.CHECK,
    AnswerTypeEnum.RADIO,
    AnswerTypeEnum.VIDEO,
    AnswerTypeEnum.WRITE,
    AnswerTypeEnum.FILE,
    AnswerTypeEnum.TEXT_AI,
    AnswerTypeEnum.CODE,
  ].includes(excelRow.answerType?.toUpperCase());
  return invalidDifficulty || invalidType ? 'EXCEL_QUESTION_VALIDATION_BASIC_DATA' : undefined;
};

export const validateTestOptions = (test) => {
  if (test.eachScore) {
    const invalidOptionWeights =
      test.options.some((option) => option.weight === undefined) ||
      test.options.filter(
        (option) => Number(option.weight) > test?.weight || Number(option.weight) < -test?.weight
      ).length;
    const atLeastOneQuestionWithDifficultyWeight = !test.options.some(
      (option) => Number(option.weight) === test?.weight
    );
    return invalidOptionWeights || atLeastOneQuestionWithDifficultyWeight
      ? 'EXCEL_QUESTION_VALIDATION_WEIGHT'
      : undefined;
  }
  const everyOptionWithText = !test.options.every((option) => option.text.trim().length);
  if (everyOptionWithText) {
    return everyOptionWithText ? 'EXCEL_QUESTION_TEXT_VALIDATION_CORRECT' : undefined;
  }

  if ([AnswerTypeEnum.RADIO, AnswerTypeEnum.CHECK].includes(test.answerType)) {
    const atLeastOneCorrectQuestion = !test?.options.some((option) => option.correct);
    return atLeastOneCorrectQuestion ? 'EXCEL_QUESTION_VALIDATION_CORRECT' : undefined;
  }

  if (test.answerType === AnswerTypeEnum.FILE) {
    const atLeastOneFileTypeCorrect = !test.options[0]?.text
      .split(';')
      .find((type) => ['IMAGE', 'PDF', 'VIDEO', 'CSV', 'TEXT'].includes(type));
    return atLeastOneFileTypeCorrect ? 'EXCEL_QUESTION_FILE_EXTENSION_VALIDATION' : undefined;
  }

  if (test.answerType === AnswerTypeEnum.VIDEO) {
    const oneCorrectTotalTime = !['30', '60', '120', '180', '240', '300'].includes(test?.totalTime);
    const oneCorrectPrepTime = !['5', '15', '30', '60'].includes(test?.preparationTime);
    const oneCorrectAttemptTry = !['1', '2', '3', '4', '5'].includes(test?.maxAttempts);
    return oneCorrectTotalTime
      ? 'EXCEL_QUESTION_TOTAL_TIME_VIDEO_VALIDATION'
      : oneCorrectPrepTime
      ? 'EXCEL_QUESTION_PREP_TIME_VIDEO_VALIDATION'
      : oneCorrectAttemptTry
      ? 'EXCEL_QUESTION_ATTEMPTS_VIDEO_VALIDATION'
      : undefined;
  }

  if (test.answerType === AnswerTypeEnum.TEXT_AI) {
    const atLeastOneCharacterLength = !test.options[0]?.weight > 1;
    return atLeastOneCharacterLength ? 'EXCEL_QUESTION_TEXT_CHARACTERS_VALIDATION' : undefined;
  }

  if (test.answerType === AnswerTypeEnum.CODE) {
    const atLeastOneTestIncomplete = test.expectedResult?.some(
      (option) => (!option.input && test.langCode !== 'sql') || !option.output
    );
    if (test.langDisplayName === 'NODEJS' || test.langCode === 'JAVASCRIPT') {
      test.langDisplayName = 'JAVASCRIPT';
      test.langCode = 'nodejs';
    }
    const invalidCodeLanguage =
      ![
        'SQL',
        'JAVASCRIPT',
        'JAVA',
        'RUBY',
        'PYTHON_3',
        'PYTHON_2',
        'CSHARP',
        'SWIFT',
        'KOTLIN',
      ].includes(test.langDisplayName) || !test.langCode;
    return atLeastOneTestIncomplete
      ? 'EXCEL_QUESTION_CODE_INCOMPLETE_TEST_CASE'
      : invalidCodeLanguage
      ? 'EXCEL_QUESTION_CODE_LANGUAGE_NOT_VALID'
      : undefined;
  }

  return undefined;
};

export const validBooleanConversion = (field) => {
  if (typeof field === 'boolean') {
    return field;
  }
  return field.toLowerCase() === 'verdadero' || field.toLowerCase() === 'true';
};
