const Group = () => (
  <svg
    width="204"
    height="128"
    viewBox="0 0 204 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M80.3613 102.274H0.819084C0.601923 102.274 0.393724 102.187 0.240169 102.034C0.0866131 101.88 0.00024082 101.672 0 101.455V23.9148C0.00024082 23.6976 0.0866131 23.4894 0.240169 23.3359C0.393724 23.1823 0.601923 23.0959 0.819084 23.0957H80.3613C80.5784 23.0959 80.7866 23.1823 80.9402 23.3359C81.0938 23.4894 81.1801 23.6976 81.1804 23.9148V101.455C81.1801 101.672 81.0938 101.88 80.9402 102.034C80.7866 102.187 80.5784 102.274 80.3613 102.274Z"
      fill="#F0F0F0"
    />
    <path
      d="M71.716 30.1943H9.46557C9.24844 30.1947 9.04029 30.2811 8.88675 30.4346C8.73321 30.5881 8.64681 30.7963 8.64648 31.0134V79.2484C8.64681 79.4655 8.73322 79.6737 8.88675 79.8272C9.04029 79.9808 9.24844 80.0672 9.46557 80.0675H71.716C71.9331 80.0672 72.1413 79.9808 72.2948 79.8272C72.4484 79.6737 72.5348 79.4655 72.5351 79.2484V31.0134C72.5348 30.7963 72.4484 30.5881 72.2948 30.4346C72.1413 30.2811 71.9331 30.1947 71.716 30.1943Z"
      fill="white"
    />
    <path
      d="M68.1653 89.5326H13.0136C12.893 89.5324 12.7773 89.4844 12.692 89.3991C12.6067 89.3138 12.5587 89.1982 12.5586 89.0775V86.5293C12.5587 86.4086 12.6067 86.293 12.692 86.2077C12.7773 86.1223 12.893 86.0744 13.0136 86.0742H68.1653C68.286 86.0744 68.4016 86.1223 68.4869 86.2077C68.5722 86.293 68.6202 86.4086 68.6204 86.5293V89.0775C68.6202 89.1982 68.5722 89.3138 68.4869 89.3991C68.4016 89.4844 68.286 89.5324 68.1653 89.5326Z"
      fill="white"
    />
    <path
      d="M49.0534 95.7215H13.0136C12.893 95.7214 12.7773 95.6734 12.692 95.5881C12.6067 95.5028 12.5587 95.3871 12.5586 95.2665V92.7182C12.5587 92.5976 12.6067 92.4819 12.692 92.3966C12.7773 92.3113 12.893 92.2633 13.0136 92.2632H49.0534C49.174 92.2633 49.2897 92.3113 49.375 92.3966C49.4603 92.4819 49.5083 92.5976 49.5084 92.7182V95.2665C49.5083 95.3871 49.4603 95.5028 49.375 95.5881C49.2897 95.6734 49.174 95.7214 49.0534 95.7215Z"
      fill="white"
    />
    <path
      d="M39.0535 62.8795C43.4248 62.8795 46.9684 59.3359 46.9684 54.9647C46.9684 50.5934 43.4248 47.0498 39.0535 47.0498C34.6823 47.0498 31.1387 50.5934 31.1387 54.9647C31.1387 59.3359 34.6823 62.8795 39.0535 62.8795Z"
      fill="#FFB8B8"
    />
    <path
      d="M50.6323 80.0675H33.084L33.6573 67.166L33.7939 67.0222L35.7633 64.9419H42.0301L44.3527 67.1916L44.5403 67.3736L49.4857 72.1135L50.6323 80.0675Z"
      fill="#4bafe1"
    />
    <path
      d="M36.1008 80.0676H22.8244C22.7917 77.1553 22.7734 75.4116 22.7734 75.4116L23.5415 74.9438C23.5425 74.9428 23.5438 74.9421 23.5452 74.942H23.547L32.0072 69.8054L33.7837 66.9204L33.7929 67.0223L34.3153 73.1036C34.3153 73.1036 35.178 75.8921 36.1008 80.0676Z"
      fill="#2F2E41"
    />
    <path
      d="M58.4151 80.0676H44.3523C44.5653 71.5746 43.4186 66.9204 44.0084 66.9204L44.3523 67.1916L48.4969 70.4643L53.9593 72.4683H53.963L57.729 73.8499L58.3897 74.0919V74.4032C58.3915 75.1495 58.3951 77.1899 58.4151 80.0676Z"
      fill="#2F2E41"
    />
    <path
      d="M31.5643 57.4408C31.5643 57.4408 32.2368 56.2598 32.0804 52.3146C31.9241 48.3694 37.9317 50.6007 42.9517 50.2244C47.9718 49.848 46.9091 56.811 46.9091 56.811C46.9091 56.811 47.2713 56.3971 48.0467 53.0366C48.822 49.676 47.0615 46.3271 47.0615 46.3271C46.543 42.7958 43.7486 43.9276 43.7486 43.9276C45.0944 44.4515 44.8359 45.5716 44.8359 45.5716C43.6968 43.5744 40.9024 44.7062 40.9024 44.7062C37.1755 42.3675 33.3983 45.4473 33.3983 45.4473C28.896 46.4691 31.5364 50.0496 31.5364 50.0496C27.0086 52.3378 31.5643 57.4408 31.5643 57.4408Z"
      fill="#2F2E41"
    />
    <path
      d="M24.597 80.0676H19.1418C19.1844 79.3954 19.1856 78.7213 19.1455 78.049C18.8197 76.7512 23.4029 74.9965 23.5412 74.9437C23.5422 74.9427 23.5435 74.942 23.5449 74.9419H23.5467L24.4204 75.4115L24.597 80.0676Z"
      fill="#2F2E41"
    />
    <path
      d="M59.4004 80.0679H53.6704L52.9551 73.0055L53.7577 72.4194C53.7577 72.4194 53.8306 72.4358 53.9598 72.4686H53.9635C54.606 72.6269 56.5936 73.1512 57.7294 73.8501C57.9821 73.9919 58.2062 74.1796 58.3901 74.4034C58.5028 74.5386 58.5589 74.7121 58.5466 74.8876C58.5175 75.1515 58.8525 77.0955 59.4004 80.0679Z"
      fill="#2F2E41"
    />
    <path
      d="M202.828 79.1782H123.286C123.069 79.1779 122.861 79.0915 122.707 78.938C122.553 78.7844 122.467 78.5762 122.467 78.3591V0.819084C122.467 0.601923 122.553 0.393729 122.707 0.240173C122.861 0.0866165 123.069 0.000241499 123.286 0H202.828C203.045 0.000237437 203.253 0.0866079 203.407 0.240165C203.561 0.393722 203.647 0.601922 203.647 0.819084V78.3591C203.647 78.5762 203.561 78.7844 203.407 78.938C203.253 79.0915 203.045 79.1779 202.828 79.1782Z"
      fill="#F0F0F0"
    />
    <path
      d="M194.183 7.09863H131.932C131.715 7.09896 131.507 7.18536 131.354 7.3389C131.2 7.49244 131.114 7.70058 131.113 7.91772V56.1527C131.114 56.3698 131.2 56.578 131.354 56.7315C131.507 56.885 131.715 56.9714 131.932 56.9718H194.183C194.4 56.9714 194.608 56.885 194.762 56.7315C194.915 56.578 195.002 56.3698 195.002 56.1527V7.91772C195.002 7.70058 194.915 7.49244 194.762 7.3389C194.608 7.18536 194.4 7.09896 194.183 7.09863Z"
      fill="white"
    />
    <path
      d="M190.632 66.4369H135.48C135.36 66.4367 135.244 66.3887 135.159 66.3034C135.074 66.2181 135.026 66.1025 135.025 65.9818V63.4336C135.026 63.3129 135.074 63.1973 135.159 63.1119C135.244 63.0266 135.36 62.9787 135.48 62.9785H190.632C190.753 62.9787 190.868 63.0266 190.954 63.1119C191.039 63.1973 191.087 63.3129 191.087 63.4336V65.9818C191.087 66.1025 191.039 66.2181 190.954 66.3034C190.868 66.3887 190.753 66.4367 190.632 66.4369Z"
      fill="white"
    />
    <path
      d="M171.52 72.6253H135.48C135.36 72.6252 135.244 72.5772 135.159 72.4919C135.074 72.4066 135.026 72.2909 135.025 72.1703V69.622C135.026 69.5014 135.074 69.3857 135.159 69.3004C135.244 69.2151 135.36 69.1671 135.48 69.167H171.52C171.641 69.1671 171.756 69.2151 171.842 69.3004C171.927 69.3857 171.975 69.5014 171.975 69.622V72.1703C171.975 72.2909 171.927 72.4066 171.842 72.4919C171.756 72.5772 171.641 72.6252 171.52 72.6253Z"
      fill="white"
    />
    <path
      d="M178.379 56.9723C178.73 55.7072 179.003 54.6169 179.144 53.8452C179.187 53.6072 179.191 53.3637 179.156 53.1244C178.632 49.3948 169.163 46.2422 167.949 45.8527L167.656 42.6055L155.738 41.3223L154.229 45.4759L149.951 47.0795C149.797 47.1367 149.649 47.2125 149.513 47.3052C149.154 47.5475 148.878 47.8939 148.722 48.2978C148.566 48.7016 148.538 49.1436 148.641 49.5641L150.463 56.9722L178.379 56.9723Z"
      fill="#4bafe1"
    />
    <path
      d="M153.672 56.9719H144.424C144.755 54.9078 145.052 53.0822 145.245 51.9209C145.951 47.6944 149.031 47.334 149.513 47.3049C149.551 47.3013 149.575 47.3013 149.577 47.3013H151.523L153.672 56.9719Z"
      fill="#4bafe1"
    />
    <path
      d="M178.381 56.9722H180.623C180.425 55.1502 179.795 53.9234 179.158 53.1243C178.733 52.5625 178.184 52.1061 177.555 51.7901L177.385 51.7173L175.414 56.9722H178.381Z"
      fill="#4bafe1"
    />
    <path
      d="M161.232 39.2667C165.971 39.2667 169.814 35.4246 169.814 30.6851C169.814 25.9456 165.971 22.1035 161.232 22.1035C156.492 22.1035 152.65 25.9456 152.65 30.6851C152.65 35.4246 156.492 39.2667 161.232 39.2667Z"
      fill="#A0616A"
    />
    <path
      d="M155.472 26.5043C156.361 27.5557 157.864 27.8522 159.251 27.9728C162.067 28.2199 166.162 27.8192 168.915 27.1838C169.113 29.0877 168.576 31.1406 169.697 32.7071C170.223 30.8109 170.528 28.8604 170.608 26.8943C170.642 26.0511 170.629 25.1788 170.286 24.4048C169.944 23.6307 169.196 22.9736 168.336 22.9668C168.975 22.4819 169.724 22.1619 170.516 22.0349L167.791 20.6656L168.491 19.9472L163.558 19.6454L164.987 18.7378C162.836 18.4423 160.659 18.3739 158.494 18.5336C157.488 18.6076 156.437 18.7491 155.623 19.3316C154.809 19.9142 154.338 21.0717 154.844 21.9268C154.211 22.0308 153.615 22.2946 153.112 22.6934C152.609 23.0921 152.217 23.6126 151.972 24.2054C151.609 25.126 151.642 26.1485 151.754 27.1296C151.94 28.6985 152.304 30.2411 152.841 31.7272"
      fill="#2F2E41"
    />
    <path
      d="M143.107 128H63.5652C63.348 128 63.1398 127.913 62.9863 127.76C62.8327 127.606 62.7463 127.398 62.7461 127.181V49.6409C62.7463 49.4237 62.8327 49.2155 62.9863 49.0619C63.1398 48.9084 63.348 48.822 63.5652 48.8218H143.107C143.325 48.822 143.533 48.9084 143.686 49.0619C143.84 49.2155 143.926 49.4237 143.926 49.6409V127.181C143.926 127.398 143.84 127.606 143.686 127.76C143.533 127.913 143.325 128 143.107 128Z"
      fill="#E4E4E4"
    />
    <path
      d="M134.462 55.9209H72.2117C71.9945 55.9212 71.7864 56.0076 71.6328 56.1612C71.4793 56.3147 71.3929 56.5228 71.3926 56.74V104.975C71.3929 105.192 71.4793 105.4 71.6328 105.554C71.7864 105.707 71.9945 105.794 72.2117 105.794H134.462C134.679 105.794 134.887 105.707 135.041 105.554C135.194 105.4 135.281 105.192 135.281 104.975V56.74C135.281 56.5228 135.194 56.3147 135.041 56.1612C134.887 56.0076 134.679 55.9212 134.462 55.9209Z"
      fill="white"
    />
    <path
      d="M130.913 115.259H75.7617C75.641 115.259 75.5254 115.211 75.4401 115.125C75.3548 115.04 75.3068 114.924 75.3066 114.804V112.255C75.3068 112.135 75.3548 112.019 75.4401 111.934C75.5254 111.848 75.641 111.8 75.7617 111.8H130.913C131.034 111.8 131.15 111.848 131.235 111.934C131.32 112.019 131.368 112.135 131.368 112.255V114.804C131.368 114.924 131.32 115.04 131.235 115.125C131.15 115.211 131.034 115.259 130.913 115.259Z"
      fill="white"
    />
    <path
      d="M111.801 121.447H75.7617C75.641 121.447 75.5254 121.399 75.4401 121.314C75.3548 121.228 75.3068 121.113 75.3066 120.992V118.444C75.3068 118.323 75.3548 118.208 75.4401 118.122C75.5254 118.037 75.641 117.989 75.7617 117.989H111.801C111.922 117.989 112.038 118.037 112.123 118.122C112.208 118.208 112.256 118.323 112.256 118.444V120.992C112.256 121.113 112.208 121.228 112.123 121.314C112.038 121.399 111.922 121.447 111.801 121.447Z"
      fill="white"
    />
    <path
      d="M109.462 105.794H96.9336L97.7144 94.0757L102.234 95.1187L106.63 93.4277L106.746 93.384L106.754 93.3804C106.755 93.3839 106.756 93.3876 106.757 93.3913C106.826 93.6698 108.206 99.194 109.462 105.794Z"
      fill="#4bafe1"
    />
    <path
      d="M117.012 95.9887L115.842 105.794H105.283L106.63 93.4278L106.643 93.3076L106.747 93.384L106.757 93.3912L109.362 95.2916L117.012 95.9887Z"
      fill="#2F2E41"
    />
    <path
      d="M98.7877 105.794H89.3318L89.1953 98.0746L96.1539 95.7666L97.7156 94.0757C97.7156 94.0757 98.2089 99.1631 98.7877 105.794Z"
      fill="#2F2E41"
    />
    <path
      d="M121.564 105.794H113.729L114.231 98.77L117.012 95.9888C117.012 95.9888 119.213 100.605 121.564 105.794Z"
      fill="#2F2E41"
    />
    <path
      d="M91.5614 105.794H87.4277C87.446 103.544 87.4569 101.989 87.4569 101.551C87.4361 100.873 87.5841 100.2 87.8875 99.5932C88.1909 98.9865 88.6403 98.4645 89.1952 98.0742L91.2829 98.7695L91.5614 105.794Z"
      fill="#2F2E41"
    />
    <path
      d="M90.9141 82.0129C90.9204 80.1067 91.4214 78.2349 92.3682 76.5805C93.3149 74.9262 94.6748 73.5458 96.315 72.5747C99.7995 70.5176 104.004 70.5548 107.849 72.6764C110.589 74.1882 112.818 77.9921 112.818 81.156V90.3878H90.9141V82.0129Z"
      fill="#2F2E41"
    />
    <path
      d="M105.229 89.1911C109.063 87.0953 110.472 82.2883 108.376 78.4544C106.28 74.6205 101.473 73.2114 97.6392 75.3072C93.8053 77.4031 92.3963 82.2101 94.4921 86.044C96.5879 89.8779 101.395 91.2869 105.229 89.1911Z"
      fill="#FFB8B8"
    />
    <path
      d="M93.184 82.3239C93.124 82.2547 92.9837 82.0926 93.9595 74.5836L93.9905 74.4609H94.0788C99.0253 72.0275 104.044 71.9663 108.996 74.2782C109.149 74.3505 109.28 74.4612 109.377 74.5994C109.473 74.7376 109.533 74.8986 109.549 75.0666C110.126 82.1215 109.987 82.2753 109.927 82.3411C109.836 82.4406 109.726 82.5201 109.603 82.5746C109.48 82.6291 109.347 82.6573 109.212 82.6574H107.018L106.972 82.5745C106.595 81.8744 106.182 81.1946 105.733 80.538C105.527 81.1916 105.358 81.8564 105.228 82.5292L105.201 82.6574H93.9143C93.7758 82.6574 93.6389 82.6277 93.5129 82.5701C93.3869 82.5126 93.2747 82.4286 93.184 82.3239Z"
      fill="#2F2E41"
    />
  </svg>
);

export default Group;
