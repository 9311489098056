const Person = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.8102 0.255863C15.0472 0.893541 13.8844 2.43147 13.7343 4.38202C13.6218 5.69488 13.8469 6.55762 14.5408 7.60792C14.8034 7.98302 14.9909 8.30186 14.9534 8.33937C14.9159 8.35813 14.522 8.58319 14.0719 8.82701C13.4155 9.18336 13.2279 9.22087 13.1529 9.01456C12.9841 8.50817 11.7275 7.27032 10.9773 6.89522C7.99522 5.37605 4.31919 7.40161 3.9816 10.7776C3.86906 11.9216 4.28168 13.422 4.95687 14.2098L5.38824 14.7349L4.43172 15.185C1.28084 16.6855 -0.894768 20.7741 0.361834 22.8184C1.03702 23.9437 1.31835 24 6.70111 24C11.8025 24 11.9901 23.9625 11.9901 23.1373C11.9901 22.1807 11.7463 22.1245 7.09497 22.1245C4.46923 22.1245 2.63122 22.0495 2.40616 21.9369C1.89976 21.6556 1.95603 20.8866 2.59371 19.7801C3.644 17.9796 5.36948 16.7417 7.48883 16.2916C8.91423 15.9915 10.3584 16.2166 11.9526 16.9855C13.4342 17.6982 13.9406 17.6982 14.2782 16.9668C14.5596 16.3479 14.0907 15.6539 13.0216 15.1475C12.1964 14.7349 12.1776 14.7349 12.534 14.3598C12.9841 13.8534 13.2842 13.2532 13.5655 12.3155C13.7343 11.7341 13.9969 11.4152 14.6908 10.9464C15.8912 10.1024 16.8477 9.83979 18.6482 9.83979C19.9611 9.83979 20.3174 9.91481 21.4427 10.44C23.2807 11.3215 23.9934 11.2277 23.9934 10.1024C23.9934 9.53971 23.4683 9.03332 22.5118 8.63946L21.9679 8.39564L22.5118 7.58916C23.487 6.14501 23.5996 4.00691 22.7931 2.45023C21.7616 0.518436 18.9858 -0.513102 16.8102 0.255863ZM19.661 2.3377C20.4299 2.7128 21.1426 3.65056 21.2927 4.40077C21.4802 5.45107 20.7488 6.72642 19.6235 7.28908C18.967 7.60792 18.5732 7.70169 18.0855 7.60792C17.3166 7.45788 16.3976 6.72642 16.0037 5.9387C15.6286 5.226 15.7411 3.89438 16.2475 3.20044C16.9977 2.1689 18.5732 1.77504 19.661 2.3377ZM10.0395 8.82701C11.5024 9.52095 12.0276 11.4715 11.0898 12.7281C9.83323 14.4161 7.77016 14.4161 6.51355 12.7281C5.57579 11.4903 6.10094 9.53971 7.54509 8.82701C8.53912 8.33937 9.04551 8.33937 10.0395 8.82701Z"
      fill={fill}
    />
    <path
      d="M18.9663 15.2976C18.5912 15.5414 18.5537 15.7477 18.5537 17.0793V18.561H17.0908C15.4966 18.561 14.9902 18.8236 14.9902 19.63C14.9902 20.3615 15.5716 20.6241 17.1096 20.6241H18.5537V22.087C18.5537 23.7374 18.7038 24 19.6228 24C20.4293 24 20.6168 23.6061 20.6168 21.9557V20.6241H21.9484C23.5989 20.6241 23.9928 20.4365 23.9928 19.63C23.9928 18.711 23.7302 18.561 22.0797 18.561H20.6168V17.1543C20.6168 15.9915 20.5418 15.6727 20.2417 15.3726C19.7916 14.9225 19.529 14.9037 18.9663 15.2976Z"
      fill={fill}
    />
  </svg>
);

export default Person;
