import { put, takeLatest, call } from 'redux-saga/effects';
import { GET_COHORTS, GET_TEAMS } from '../actionTypes';
import { setCohorts, setTeams } from '../actions/teamsActions';
import { handleError } from '../actions/errorsActions';
import { getCohorts, getTeams } from '../../API/services/teamsService';

function* getTeamsSaga({ payload: { params } }) {
  try {
    const teams = yield call(getTeams, params);
    yield put(setTeams(teams));
  } catch (err) {
    yield put(handleError(err));
  }
}

function* getCohortsSaga() {
  try {
    const cohorts = yield call(getCohorts);
    yield put(setCohorts(cohorts));
  } catch (err) {
    yield put(handleError(err));
  }
}

function* teamsSagas() {
  yield takeLatest(GET_TEAMS, getTeamsSaga);
  yield takeLatest(GET_COHORTS, getCohortsSaga);
}

export default teamsSagas();
