import {
  SET_USER,
  SET_USER_TOKEN,
  CHECK_TOKEN,
  GET_ME,
  SET_LOADING_ME,
  SET_MAINTENANCE,
  SET_WHITELABEL,
  SET_AUTHENTICATED,
  SET_LOGIN_LOADING,
  SET_VERIFY,
  SET_PLAN,
  SET_VERIFY_EMAIL_USER,
  SET_USER_EMAIL,
  SET_CURRENT_WORKSPACE,
  SET_WORKSPACES,
  SET_WORKSPACE_FAVORITE,
  SET_PLAN_FEATURES,
  SET_WORKSPACE_OWNERS,
  SET_CURRENT_ROLE,
} from '../actionTypes';

export const setMaintenance = ({ maintenance }) => ({
  type: SET_MAINTENANCE,
  payload: { maintenance },
});

export const setUser = (user) => ({
  type: SET_USER,
  payload: { user },
});

export const setUserToken = (token) => ({
  type: SET_USER_TOKEN,
  payload: { token },
});

export const setUserEmail = (email) => ({
  type: SET_USER_EMAIL,
  payload: { email },
});

export const checkToken = () => ({
  type: CHECK_TOKEN,
});

export const getMe = () => ({
  type: GET_ME,
});

export const setLoadingMe = (isLoadingMe) => ({
  type: SET_LOADING_ME,
  payload: { isLoadingMe },
});

export const setWhitelabel = (whitelabel) => ({
  type: SET_WHITELABEL,
  payload: { whitelabel },
});

export const SetAuthenticated = (authenticated) => ({
  type: SET_AUTHENTICATED,
  payload: authenticated,
});

export const setLoginLoading = (loginLoading) => ({
  type: SET_LOGIN_LOADING,
  payload: { loginLoading },
});

export const setVerify = (isVerify) => ({
  type: SET_VERIFY,
  payload: { isVerify },
});

export const setPlan = (plan) => ({
  type: SET_PLAN,
  payload: { plan },
});

export const setPlanFeatures = (planFeatures) => ({
  type: SET_PLAN_FEATURES,
  payload: { planFeatures },
});

export const setVerifyEmailUser = (emailUserVerify) => ({
  type: SET_VERIFY_EMAIL_USER,
  payload: { emailUserVerify },
});

export const setCurrentWorkspace = (currentWorkspace) => ({
  type: SET_CURRENT_WORKSPACE,
  payload: { currentWorkspace },
});

export const setCurrentRole = (currentRole) => ({
  type: SET_CURRENT_ROLE,
  payload: { currentRole },
});

export const setWorkspacesList = (workspacesList) => ({
  type: SET_WORKSPACES,
  payload: { workspacesList },
});

export const setWorkspaceFavorite = (workspaceFavorite) => ({
  type: SET_WORKSPACE_FAVORITE,
  payload: { workspaceFavorite },
});

export const setWorkspaceOwners = (workspaceOwners) => ({
  type: SET_WORKSPACE_OWNERS,
  payload: { workspaceOwners },
});
