import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification, Slider } from 'antd';
import { getSavedSearches, saveSearchRequest } from '../../API/services/companyService';
import FiltersList from '../FiltersList/FiltersList';
import {
  processProfilesQuery,
  setAgeQuery,
  setAppliedFilters,
  setEngagementQuery,
  setSavedSearches,
} from '../../redux/actions/profilesActions';
import { t } from '../../i18n/i18n';
import FiltersSkeleton from './FiltersSkeleton';
import SearchModal from '../SearchModal/SearchModal';
import setGenre from '../../utils/filters/setGenre';
import './ProfileSearch.scss';
import closeIcon from '../../assets/sidebar/icons/close-icon-big.svg';
import setStatus from '../../utils/filters/setStatus';
import setRelocation from '../../utils/filters/setRelocation';
import setAcademicStatus from '../../utils/filters/setAcademicStatus';
import setEnglishLevel from '../../utils/filters/setEnglishLevel';
import setAcademicLevels from '../../utils/filters/setAcademicLevels';
import setInstitutesFilter from '../../utils/filters/setInstitutesFilter';
import WhiteLabelShow from '../../whiteLabel/WhiteLabelShow';
import { Behaviors, spanFilterTypes } from '../../whiteLabel/enums';
import SavedFiltersList from '../SavedFiltersList/SavedFiltersList';
import { TrackEvent } from '../../utils/filters/segmentUtils';
import setStudyAreaFilters from '../../utils/filters/setStudyAreaFilters';
import useRoles from '../../hooks/useRoles';
import GenericToggle from '../GenericToggle/GenericToggle';

function ProfilesSearch({
  addFilter,
  removeAppliedFilter,
  showFilters,
  clearFilters,
  isLoading,
  showSaveBtn = true,
}) {
  const dispatch = useDispatch();
  const profilesState = useSelector((state) => state.profiles);
  const profilesLoading = useSelector((state) => state.profiles.loading);
  const { isRole, roles } = useRoles();
  const [saveSearch, setSaveSearch] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [ageRange, setAgeRange] = useState({
    min: profilesState.ageQuery.min,
    max: profilesState.ageQuery.max,
  });
  const [engagementRange, setEngagementRange] = useState({
    min: profilesState.engagementQuery.minEngagement,
    max: profilesState.engagementQuery.maxEngagement,
  });
  const appliedFilters = useSelector((state) => state.profiles.appliedFilters);
  const availableFilters = useSelector((state) => state.profiles.availableFilters);
  const { engagementQuery, ageQuery } = useSelector((state) => state.profiles);
  const [locationModal, setLocationModal] = useState(false);
  const [institutesModal, setInstitutesModal] = useState(false);
  const [countryIds, setCountryIds] = useState([]);
  const [secondaryTechModal, setSecondaryTechModal] = useState(false);
  const [studyAreaModal, setStudyAreaModal] = useState(false);
  const hasMarketplaceRole = isRole(roles.MARKETPLACEPARTNER);

  useEffect(() => {
    dispatch(processProfilesQuery());
  }, [appliedFilters]);

  useEffect(() => {
    setAgeRange({
      min: profilesState.ageQuery.min,
      max: profilesState.ageQuery.max,
    });
    setEngagementRange({
      min: profilesState.engagementQuery.minEngagement,
      max: profilesState.engagementQuery.maxEngagement,
    });
  }, [profilesState.ageQuery, profilesState.engagementQuery]);

  useEffect(() => {
    if (!appliedFilters.length) {
      setSaveSearch(false);
    }
    setCountryIds(
      appliedFilters?.filter((item) => item.type === 'country')?.map((item) => Number(item?.id))
    );
  }, [appliedFilters]);

  const addToggleFilter = (filter) => {
    const isApplied = appliedFilters.find((appliedfilter) => appliedfilter.name === filter.name);
    if (!isApplied) {
      dispatch(setAppliedFilters([...appliedFilters, filter]));
    }
  };

  const removeToggleFilter = (typeFilter) => {
    dispatch(setAppliedFilters(appliedFilters.filter((filter) => filter.name !== typeFilter)));
  };

  const query = useSelector((state) => state.profiles.query);
  const [filtersQuery, setFiltersQuery] = useState([]);

  const handleChange = (filterType) => {
    TrackEvent(`filter-${filterType}-${filtersQuery.includes(filterType) ? 'and' : 'or'}`);
    if (!filtersQuery.includes(filterType)) {
      addToggleFilter({ name: filterType, id: filterType, type: 'and' });
    } else {
      removeToggleFilter(filterType);
    }
  };

  useEffect(() => {
    setFiltersQuery(query.and?.split(',') ?? []);
  }, [query]);

  return (
    <>
      <div className="profile-search-container py-5 ml-3">
        {(profilesState.loading && !Object.keys(availableFilters).length) ||
        isLoading ||
        profilesLoading ? (
          <FiltersSkeleton />
        ) : (
          <div className="small" data-tour-id="profile-filters">
            <div>
              <button
                type="button"
                className="options-button bg-transparent border-0 font-montserrat w-100 d-lg-none d-flex justify-content-between font-weight-bold text-sm my-2"
                onClick={showFilters}
              >
                <h6 className="font-weight-bold text-base font-montserrat">
                  {t('PROFILES_FILTER_FILTER')}
                </h6>
                <img src={closeIcon} alt="close icon" />
              </button>
              {(!!appliedFilters?.filter((item) => item.type !== 'orderBy')?.length &&
                !!appliedFilters.filter((filter) => filter.type !== 'search').length) ||
              Boolean(appliedFilters.find((filter) => filter.type === 'search')?.name)
                ? showSaveBtn && (
                    <button
                      className="bg-transparent my-2 font-montserrat border-0 outline-0 font-weight-bold text-sm text-purple"
                      type="button"
                      onClick={() => setSaveSearch(true)}
                    >
                      {t('PROFILES_FILTER_SAVED_FILTERS')}
                    </button>
                  )
                : null}
              {saveSearch && (
                <form
                  className="pr-3 mb-3"
                  onSubmit={(e) => {
                    e.preventDefault();
                    setSaveSearch(false);
                    const dataToPost = appliedFilters.reduce(
                      (acum, el) => ({
                        ...acum,
                        name: searchName,
                        min_age: typeof ageRange.min === 'string' ? null : ageRange.min,
                        max_age: typeof ageRange.max === 'string' ? null : ageRange.max,
                        [el.type]: [...(acum[el.type] ?? []), el.id],
                      }),
                      []
                    );
                    saveSearchRequest({
                      ...dataToPost,
                      search: appliedFilters.find((el) => el.type === 'search')?.id ?? '',
                    })
                      .then(() => {
                        notification.open({
                          message: `${t('PROFILES_SAVED_SEARCH_NOTIFICATION')} ${searchName}. ${t(
                            'PROFILES_SEE_SAVED_SEARCHES_NOTIFICATION'
                          )}`,
                          icon: <i className="fas fa-check" style={{ color: '#58DDA6' }} />,
                        });
                        getSavedSearches().then((res) => dispatch(setSavedSearches(res)));
                        setSearchName('');
                      })
                      .catch(() => {
                        notification.open({
                          message: `${t('PROFILES_SAVE_ERROR_NOTIFICATION')} ${searchName}, ${t(
                            'PROFILES_TRY_LATER_NOTIFICATION'
                          )}`,
                          icon: <i className="fas fa-times" style={{ color: 'red' }} />,
                        });
                        setSearchName('');
                      });
                  }}
                >
                  <input
                    placeholder={t('PROFILES_SEARCH_NAME_PLACEHOLDER')}
                    onChange={(e) => setSearchName(e.target.value)}
                    maxLength={40}
                    className="border-0 outline-none font-montserrat bg-gray p-2 rounded w-100"
                    type="text"
                  />
                  <p className="font-montserrat text-xs text-right text-gray mb-0">{`${searchName.length}/40`}</p>
                </form>
              )}
              {(!!appliedFilters?.filter((item) => item.type !== 'orderBy')?.length &&
                !!appliedFilters.filter((filter) => filter.type !== 'search').length) ||
              Boolean(appliedFilters.find((filter) => filter.type === 'search')?.name) ||
              typeof engagementQuery.minEngagement === 'number' ||
              typeof ageQuery.min === 'number' ? (
                <div>
                  <button
                    className="bg-transparent my-2 font-montserrat border-0 outline-0 red-color font-weight-bold text-sm"
                    type="button"
                    onClick={() => clearFilters(true)}
                  >
                    {t('PROFILES_CLEAR_FILTERS')}
                  </button>
                </div>
              ) : null}
              {!!profilesState?.savedSearches?.filters?.length && (
                <SavedFiltersList
                  filters={profilesState?.savedSearches?.filters}
                  appliedFilters={appliedFilters}
                />
              )}
              <WhiteLabelShow objectKey={Behaviors.STATUS_FILTER}>
                {!!availableFilters?.status?.length && (
                  <div className={`${appliedFilters?.length ? 'my-4' : 'mb-4'}`}>
                    <div>
                      <h6 className="font-weight-bold text-base profile-search-filters-label font-montserrat font-weight-bold">
                        {t('PROFILES_FILTER_STATUS')}
                      </h6>
                    </div>
                    <FiltersList
                      removeAppliedFilter={removeAppliedFilter}
                      filters={setStatus(availableFilters.status)}
                      addFilter={addFilter}
                      type="status"
                    />
                  </div>
                )}
              </WhiteLabelShow>
              {!!availableFilters?.technologies?.length && (
                <div className="mb-4">
                  <div>
                    <h6 className="font-weight-bold text-base profile-search-filters-label font-montserrat font-weight-bold">
                      {t('PROFILES_FILTER_TECHNOLOGIES')}
                    </h6>
                    <GenericToggle
                      leftTextClassName="text-xs"
                      rightTextClassname="text-xs"
                      className="mb-1 d-flex"
                      defaultActive={filtersQuery.includes('technologies')}
                      actionFunction={() => handleChange('technologies')}
                      leftText={t('TOGGLE_FILTERS_MARKETPLACE_OR')}
                      rightText={t('TOGGLE_FILTERS_MARKETPLACE_AND')}
                    />
                  </div>
                  <FiltersList
                    removeAppliedFilter={removeAppliedFilter}
                    filters={availableFilters.technologies}
                    addFilter={addFilter}
                    type="technologies"
                  />
                </div>
              )}
              {!!Object.keys(availableFilters).length && (
                <div className="my-3">
                  <div>
                    <h6 className="font-weight-bold text-base profile-search-filters-label font-montserrat font-weight-bold">
                      {t('ENGAGEMENT_FILTER')}
                    </h6>
                  </div>
                  <div className="text-center pr-lg-4 mr-lg-4">
                    <span className="font-weight-bold font-montserrat">
                      {`${typeof engagementRange.min === 'string' ? 0 : engagementRange.min}
                       - 
                      ${
                        typeof engagementRange.max === 'string'
                          ? hasMarketplaceRole
                            ? 100
                            : 60
                          : engagementRange.max
                      }`}
                    </span>
                    <Slider
                      range
                      min={0}
                      max={hasMarketplaceRole ? 100 : 60}
                      defaultValue={[
                        typeof engagementRange.min === 'string' ? 0 : engagementRange.min,
                        typeof engagementRange.max === 'string'
                          ? hasMarketplaceRole
                            ? 100
                            : 60
                          : engagementRange.max,
                      ]}
                      onAfterChange={(e) => {
                        TrackEvent('filter-score');
                        dispatch(
                          setEngagementQuery({
                            minEngagement: e[0],
                            maxEngagement: e[1],
                          })
                        );
                        dispatch(processProfilesQuery());
                      }}
                    />
                  </div>
                </div>
              )}
              {!!availableFilters?.english_level?.length && (
                <div className="mb-4">
                  <div>
                    <h6 className="font-weight-bold text-base profile-search-filters-label d-flex align-items-center font-montserrat font-weight-bold">
                      {t('PROFILES_FILTER_ENGLISH_LEVEL')}
                    </h6>
                  </div>
                  <FiltersList
                    removeAppliedFilter={removeAppliedFilter}
                    filters={setEnglishLevel(availableFilters.english_level)}
                    addFilter={addFilter}
                    type="english_level"
                  />
                </div>
              )}
              {!!availableFilters?.secondary_technologies?.length && (
                <div className="mb-4">
                  <div>
                    <h6 className="font-weight-bold text-base d-flex align-items-center profile-search-filters-label font-montserrat">
                      {t('PROFILES_FILTER_SECONDARY_TECHNOLOGY')}{' '}
                    </h6>
                    <GenericToggle
                      leftTextClassName="text-xs"
                      rightTextClassname="text-xs"
                      className="mb-1 d-flex"
                      defaultActive={filtersQuery.includes('secondary-technologies')}
                      actionFunction={() => handleChange('secondary-technologies')}
                      leftText={t('TOGGLE_FILTERS_MARKETPLACE_OR')}
                      rightText={t('TOGGLE_FILTERS_MARKETPLACE_AND')}
                    />
                  </div>
                  <FiltersList
                    removeAppliedFilter={removeAppliedFilter}
                    filters={availableFilters.secondary_technologies?.sort(
                      (a, b) => b.count - a.count
                    )}
                    limit={5}
                    addFilter={addFilter}
                    type="secondary_technologies"
                  />
                  {availableFilters?.secondary_technologies?.length > 5 ? (
                    <div className="mb-2">
                      <span
                        role="button"
                        tabIndex={0}
                        className="text-primary profile-search-clear-filter outline-none"
                        onKeyPress={() => setSecondaryTechModal(true)}
                        onClick={() => setSecondaryTechModal(true)}
                      >
                        {t('SEE_ALL')}
                      </span>
                    </div>
                  ) : null}
                </div>
              )}
              {!!availableFilters?.academic_level?.length && (
                <div className="mb-4">
                  <div>
                    <h6 className="font-weight-bold text-base profile-search-filters-label font-montserrat font-weight-bold">
                      {t('PROFILES_FILTER_ACADEMIC_LEVEL')}
                    </h6>
                    <GenericToggle
                      leftTextClassName="text-xs"
                      rightTextClassname="text-xs"
                      className="mb-1 d-flex"
                      defaultActive={filtersQuery.includes('academic_level')}
                      actionFunction={() => handleChange('academic_level')}
                      rightText={t('TOGGLE_FILTERS_MARKETPLACE_PLUS').replace(
                        '{filter}',
                        t(spanFilterTypes.academic_level)
                      )}
                    />
                  </div>
                  <FiltersList
                    removeAppliedFilter={removeAppliedFilter}
                    filters={setAcademicLevels(availableFilters.academic_level)}
                    addFilter={addFilter}
                    type="academic_level"
                  />
                </div>
              )}
              {!!availableFilters?.study_area?.length && (
                <div className="mb-4">
                  <div>
                    <h6 className="font-weight-bold text-base profile-search-filters-label font-montserrat font-weight-bold">
                      {t('PROFILES_FILTER_STUDY_AREA')}
                    </h6>
                    <GenericToggle
                      leftTextClassName="text-xs"
                      rightTextClassname="text-xs"
                      className="mb-1 d-flex"
                      defaultActive={filtersQuery.includes('study_area')}
                      actionFunction={() => handleChange('study_area')}
                      rightText={t('TOGGLE_FILTERS_MARKETPLACE_PLUS').replace(
                        '{filter}',
                        t(spanFilterTypes.study_area)
                      )}
                    />
                  </div>
                  <FiltersList
                    removeAppliedFilter={removeAppliedFilter}
                    filters={setStudyAreaFilters(availableFilters.study_area)}
                    addFilter={addFilter}
                    type="study_area"
                    limit={5}
                  />
                  {availableFilters?.study_area?.length > 5 ? (
                    <div className="mb-2">
                      <span
                        role="button"
                        tabIndex={0}
                        className="text-primary profile-search-clear-filter outline-none"
                        onKeyPress={() => setStudyAreaModal(true)}
                        onClick={() => setStudyAreaModal(true)}
                      >
                        {t('SEE_ALL')}
                      </span>
                    </div>
                  ) : null}
                </div>
              )}
              {!!availableFilters?.academic_status?.length && (
                <div className="mb-4">
                  <div>
                    <h6 className="font-weight-bold text-base profile-search-filters-label font-montserrat font-weight-bold">
                      {t('PROFILES_FILTER_ACADEMIC_STATUS')}
                    </h6>
                  </div>
                  <FiltersList
                    removeAppliedFilter={removeAppliedFilter}
                    filters={setAcademicStatus(availableFilters.academic_status)}
                    addFilter={addFilter}
                    type="academic_status"
                  />
                </div>
              )}
              {!!availableFilters?.institutes?.length && (
                <div className="mb-4">
                  <div>
                    <h6 className="font-weight-bold text-base profile-search-filters-label font-montserrat font-weight-bold">
                      {t('PROFILES_FILTER_INSTITUTES')}
                    </h6>
                  </div>
                  <FiltersList
                    removeAppliedFilter={removeAppliedFilter}
                    filters={setInstitutesFilter(availableFilters?.institutes)}
                    limit={5}
                    addFilter={addFilter}
                    type="institutes"
                  />
                  {availableFilters?.institutes?.length > 5 ? (
                    <div className="mb-2">
                      <span
                        role="button"
                        tabIndex={0}
                        className="text-primary profile-search-clear-filter outline-none"
                        onKeyPress={() => setInstitutesModal(true)}
                        onClick={() => setInstitutesModal(true)}
                      >
                        {t('SEE_ALL')}
                      </span>
                    </div>
                  ) : null}
                </div>
              )}
              {!!availableFilters?.sex?.length && (
                <div className="mb-4">
                  <div>
                    <h6 className="font-weight-bold text-base profile-search-filters-label font-montserrat font-weight-bold">
                      {t('PROFILES_FILTER_GENRE')}
                    </h6>
                  </div>
                  <FiltersList
                    removeAppliedFilter={removeAppliedFilter}
                    filters={setGenre(availableFilters.sex)}
                    addFilter={addFilter}
                    type="sex"
                  />
                </div>
              )}
              {!!availableFilters?.locations?.length && (
                <div className="mb-4">
                  <div>
                    <h6 className="font-weight-bold text-base profile-search-filters-label font-montserrat font-weight-bold">
                      {t('PROFILES_FILTER_LOCATION')}
                    </h6>
                  </div>
                  <FiltersList
                    removeAppliedFilter={removeAppliedFilter}
                    filters={appliedFilters?.filter((item) => item.type === 'state')}
                    addFilter={addFilter}
                    type="state"
                  />
                  <FiltersList
                    removeAppliedFilter={removeAppliedFilter}
                    filters={availableFilters.locations}
                    addFilter={addFilter}
                    type="country"
                  />
                  {availableFilters?.states?.length > 1 ? (
                    <div className="mb-2">
                      <span
                        role="button"
                        tabIndex={0}
                        className="text-primary profile-search-clear-filter outline-none"
                        onKeyPress={() => setLocationModal(true)}
                        onClick={() => setLocationModal(true)}
                      >
                        {t('SEE_ALL')}
                      </span>
                    </div>
                  ) : null}
                </div>
              )}
              <WhiteLabelShow objectKey={Behaviors.RELOCATION_FILTER}>
                {!!availableFilters?.relocation?.length && (
                  <div className="mb-4">
                    <div>
                      <h6 className="font-weight-bold text-base profile-search-filters-label font-montserrat font-weight-bold">
                        {t('PROFILES_FILTER_AVAILABLE_FOR_RELOCATION')}
                      </h6>
                    </div>
                    <FiltersList
                      removeAppliedFilter={removeAppliedFilter}
                      filters={setRelocation(availableFilters.relocation)}
                      addFilter={addFilter}
                      type="relocation"
                    />
                  </div>
                )}
              </WhiteLabelShow>
              {!!Object.keys(availableFilters).length && (
                <div>
                  <div>
                    <h6 className="font-weight-bold text-base profile-search-filters-label font-montserrat font-weight-bold">
                      {t('PROFILES_FILTER_AGE')}
                    </h6>
                  </div>
                  <div className="text-center pr-lg-4 mr-lg-4">
                    <span className="font-weight-bold font-montserrat">
                      {typeof ageRange.min === 'string' ? 17 : ageRange.min}{' '}
                      {t('PROFILES_FILTER_YEARS')} -{' '}
                      {typeof ageRange.max === 'string' ? 99 : ageRange.max}{' '}
                      {t('PROFILES_FILTER_YEARS')}
                    </span>
                    <Slider
                      range
                      min={17}
                      max={99}
                      defaultValue={[
                        typeof ageRange.min === 'string' ? 17 : ageRange.min,
                        typeof ageRange.max === 'string' ? 99 : ageRange.max,
                      ]}
                      onAfterChange={(e) => {
                        TrackEvent('filter-age');
                        dispatch(setAgeQuery({ min: e[0], max: e[1] }));
                        dispatch(processProfilesQuery());
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {availableFilters?.states?.length > 1 ? (
        <SearchModal
          type="state"
          show={locationModal}
          setShow={setLocationModal}
          data={
            countryIds.length
              ? availableFilters.states.filter((item) => countryIds.includes(item.countryId))
              : availableFilters.states
          }
          removeAppliedFilter={removeAppliedFilter}
          addFilter={addFilter}
          hash="#location"
        />
      ) : null}
      {availableFilters?.institutes?.length > 1 ? (
        <SearchModal
          type="institutes"
          show={institutesModal}
          setShow={setInstitutesModal}
          data={availableFilters.institutes}
          removeAppliedFilter={removeAppliedFilter}
          addFilter={addFilter}
          hash="#institutes"
        />
      ) : null}
      {availableFilters?.secondary_technologies?.length > 1 ? (
        <SearchModal
          type="secondary_technologies"
          show={secondaryTechModal}
          setShow={setSecondaryTechModal}
          data={availableFilters.secondary_technologies}
          addFilter={addFilter}
          removeAppliedFilter={removeAppliedFilter}
          hash="#secondary_technologies"
        />
      ) : null}
      {availableFilters?.study_area?.length > 1 ? (
        <SearchModal
          type="study_area"
          show={studyAreaModal}
          setShow={setStudyAreaModal}
          data={availableFilters.study_area}
          addFilter={addFilter}
          removeAppliedFilter={removeAppliedFilter}
          hash="#study_area"
        />
      ) : null}
    </>
  );
}

export default ProfilesSearch;
