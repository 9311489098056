import querystring from 'query-string';

const processQueryString = (qs) => {
  try {
    qs = qs.split('?');
    qs.shift();
    return querystring.parse(qs.join('?'));
  } catch (err) {
    return {};
  }
};

export const processQueryStringAmpersand = (queryData) => {
  try {
    queryData = queryData.split('&');
    return querystring.parse(queryData.join('&'));
  } catch (err) {
    return {};
  }
};

export default processQueryString;
