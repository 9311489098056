import React from 'react';

const CreateEvaluationModalImage = ({ classNames }) => (
  <svg
    className={classNames}
    width="103"
    height="64"
    viewBox="0 0 103 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M52.363 63.8468C55.4468 63.8468 57.9466 61.347 57.9466 58.2633C57.9466 55.1795 55.4468 52.6797 52.363 52.6797C49.2793 52.6797 46.7795 55.1795 46.7795 58.2633C46.7795 61.347 49.2793 63.8468 52.363 63.8468Z"
      fill="#4bafe1"
    />
    <path
      d="M51.7648 0.000420485C43.0384 0.0620606 35.8933 7.15344 35.7694 15.8792C35.7682 15.9597 35.7677 16.5262 35.7678 17.2671C35.7678 18.3534 36.1994 19.3952 36.9675 20.1633C37.7356 20.9315 38.7774 21.3631 39.8637 21.3632C40.4022 21.3631 40.9354 21.2569 41.4328 21.0506C41.9302 20.8443 42.3821 20.5421 42.7627 20.1611C43.1433 19.7801 43.445 19.3279 43.6508 18.8302C43.8565 18.3326 43.9621 17.7993 43.9617 17.2608C43.9612 16.7568 43.961 16.408 43.961 16.3866C43.9607 15.038 44.3115 13.7126 44.9788 12.5407C45.6461 11.3689 46.607 10.3909 47.7669 9.70306C48.9269 9.01519 50.2459 8.64111 51.5942 8.61763C52.9425 8.59415 54.2738 8.92207 55.4569 9.56913C56.6401 10.2162 57.6345 11.1601 58.3422 12.308C59.0499 13.4559 59.4466 14.7683 59.4933 16.1161C59.54 17.4638 59.235 18.8005 58.6084 19.9946C57.9818 21.1887 57.0551 22.1992 55.9196 22.9265L55.921 22.9283C55.921 22.9283 50.0912 26.6813 48.3139 31.8299L48.3154 31.8302C48.0029 32.8849 47.8445 33.9793 47.8452 35.0794C47.8452 35.5316 47.8718 39.4881 47.9232 42.6994C47.9412 43.8075 48.394 44.864 49.184 45.6411C49.9741 46.4183 51.0379 46.8537 52.1461 46.8534C52.7029 46.8534 53.2543 46.7433 53.7684 46.5294C54.2825 46.3155 54.7492 46.002 55.1417 45.6071C55.5342 45.2121 55.8448 44.7434 56.0555 44.228C56.2662 43.7126 56.3729 43.1606 56.3694 42.6038C56.3516 39.6628 56.3419 36.2383 56.3419 35.9897C56.3419 32.8284 59.3917 29.6785 61.8968 27.6244C64.7816 25.259 66.8574 22.0356 67.6912 18.3995C67.8757 17.6509 67.9775 16.8843 67.9946 16.1134C67.9946 13.9876 67.5739 11.8828 66.7569 9.92022C65.9398 7.95767 64.7425 6.1762 63.2338 4.67844C61.7252 3.18068 59.9352 1.99624 57.9668 1.19337C55.9984 0.390501 53.8905 -0.0149365 51.7648 0.000420485Z"
      fill="#4bafe1"
    />
    <path
      d="M102.383 64H0.144109C0.105851 63.9999 0.0691981 63.9846 0.0421843 63.9575C0.0151706 63.9304 0 63.8937 0 63.8555C0 63.8172 0.0151706 63.7805 0.0421843 63.7534C0.0691981 63.7263 0.105851 63.711 0.144109 63.7109H102.383C102.422 63.7109 102.458 63.7262 102.486 63.7533C102.513 63.7804 102.528 63.8171 102.528 63.8555C102.528 63.8938 102.513 63.9306 102.486 63.9577C102.458 63.9848 102.422 64 102.383 64Z"
      fill="#4bafe1"
    />
    <path
      d="M76.2821 24.883C78.2932 24.883 79.9235 23.2527 79.9235 21.2416C79.9235 19.2304 78.2932 17.6001 76.2821 17.6001C74.271 17.6001 72.6406 19.2304 72.6406 21.2416C72.6406 23.2527 74.271 24.883 76.2821 24.883Z"
      fill="#2F2E41"
    />
    <path d="M69.5528 62.4059H68.0648L67.3568 56.666H69.5532L69.5528 62.4059Z" fill="#A0616A" />
    <path
      d="M67.002 61.9805H69.8719V63.7875H65.195C65.195 63.5502 65.2417 63.3152 65.3326 63.096C65.4234 62.8767 65.5565 62.6775 65.7243 62.5097C65.8921 62.3419 66.0913 62.2088 66.3105 62.118C66.5297 62.0272 66.7647 61.9805 67.002 61.9805Z"
      fill="#2F2E41"
    />
    <path d="M81.0842 62.4059H79.5962L78.8882 56.666H81.0846L81.0842 62.4059Z" fill="#A0616A" />
    <path
      d="M78.5332 61.9805H81.4032V63.7875H76.7263C76.7263 63.5502 76.773 63.3152 76.8638 63.096C76.9546 62.8767 77.0877 62.6775 77.2555 62.5097C77.4233 62.3419 77.6225 62.2088 77.8417 62.118C78.061 62.0272 78.296 61.9805 78.5332 61.9805Z"
      fill="#2F2E41"
    />
    <path
      d="M75.7286 24.9039C77.3751 24.9039 78.7099 23.5692 78.7099 21.9227C78.7099 20.2762 77.3751 18.9414 75.7286 18.9414C74.0821 18.9414 72.7474 20.2762 72.7474 21.9227C72.7474 23.5692 74.0821 24.9039 75.7286 24.9039Z"
      fill="#A0616A"
    />
    <path
      d="M77.5054 34.5218L78.8393 31.4569C79.1633 30.7379 79.2562 29.9361 79.1051 29.1621C78.954 28.3881 78.5664 27.6801 77.9958 27.1357C77.8786 27.0199 77.7541 26.9116 77.6231 26.8116C76.9649 26.2967 76.1537 26.0161 75.3181 26.0142C74.7562 26.0163 74.2014 26.1397 73.6916 26.3759C73.6478 26.3953 73.6054 26.4159 73.5616 26.4366C73.4779 26.4766 73.3954 26.5203 73.3153 26.5652C72.7927 26.8653 72.348 27.2839 72.017 27.7874C71.6859 28.2909 71.4779 28.8652 71.4095 29.4638L70.8208 34.3968C70.6728 34.784 65.3902 48.7672 67.2377 59.4427C67.2574 59.5567 67.3129 59.6615 67.3964 59.7416C67.4798 59.8218 67.5867 59.8731 67.7014 59.8882L69.5743 60.1431C69.7111 60.1618 69.8498 60.128 69.9626 60.0485C70.0755 59.969 70.154 59.8497 70.1824 59.7146L73.325 44.7349C73.3446 44.6418 73.3948 44.558 73.4676 44.4968C73.5404 44.4356 73.6317 44.4007 73.7267 44.3974C73.8218 44.3942 73.9152 44.423 73.992 44.4791C74.0688 44.5352 74.1245 44.6154 74.1504 44.707L78.5905 60.4599C78.6222 60.5744 78.6905 60.6753 78.785 60.7472C78.8795 60.8191 78.9949 60.8581 79.1137 60.858C79.1491 60.8578 79.1844 60.8546 79.2193 60.8483L81.5073 60.4077C81.6403 60.3829 81.7593 60.3092 81.8408 60.2012C81.9223 60.0932 81.9605 59.9586 81.948 59.8239C81.6117 56.0744 79.825 37.293 77.5054 34.5218Z"
      fill="#2F2E41"
    />
    <path
      d="M64.1425 24.4038C64.1218 24.5811 64.1402 24.7607 64.1965 24.9301C64.2527 25.0994 64.3454 25.2544 64.468 25.384C64.5906 25.5137 64.7402 25.6149 64.9061 25.6805C65.072 25.7461 65.2503 25.7745 65.4285 25.7637L67.0949 29.7684L68.3555 27.8998L66.5797 24.3834C66.5418 24.0854 66.3959 23.8116 66.1696 23.614C65.9434 23.4163 65.6525 23.3086 65.352 23.3111C65.0516 23.3136 64.7626 23.4262 64.5396 23.6276C64.3167 23.829 64.1754 24.1052 64.1425 24.4038Z"
      fill="#A0616A"
    />
    <path
      d="M75.563 38.5542C75.7402 38.533 75.9106 38.4732 76.0622 38.3791C76.2138 38.2849 76.3429 38.1587 76.4404 38.0092C76.5379 37.8598 76.6015 37.6908 76.6266 37.5141C76.6517 37.3375 76.6378 37.1574 76.5859 36.9867L80.092 34.433L77.9812 33.6426L74.9753 36.1888C74.6943 36.2951 74.462 36.5008 74.3226 36.7669C74.1831 37.033 74.1461 37.341 74.2185 37.6325C74.291 37.9241 74.4679 38.179 74.7157 38.3488C74.9635 38.5187 75.265 38.5917 75.563 38.5542Z"
      fill="#A0616A"
    />
    <path
      d="M69.8475 32.4383C69.7131 32.4381 69.579 32.4249 69.4472 32.3988C69.1283 32.3381 68.8292 32.1999 68.5763 31.9963C68.3234 31.7928 68.1244 31.5301 67.997 31.2316L65.6623 28.3848C65.6129 28.3246 65.577 28.2546 65.5569 28.1794C65.5368 28.1042 65.533 28.0255 65.5458 27.9487C65.5586 27.872 65.5876 27.7988 65.6309 27.7341C65.6743 27.6695 65.7309 27.6148 65.7971 27.5739L67.4582 26.5457C67.5195 26.5077 67.5877 26.4823 67.6589 26.4708C67.7302 26.4593 67.8029 26.4621 67.8731 26.4789C67.9432 26.4957 68.0093 26.5263 68.0676 26.5688C68.1259 26.6113 68.1751 26.6649 68.2126 26.7266L69.8581 29.4375L73.0093 27.1086C73.2602 26.9771 73.5501 26.9402 73.826 27.0047C74.1018 27.0692 74.3453 27.2308 74.5118 27.4599C74.6784 27.6891 74.7569 27.9706 74.7331 28.2529C74.7093 28.5352 74.5847 28.7995 74.3821 28.9975L71.2369 31.8666C70.8673 32.2329 70.3679 32.4384 69.8475 32.4383Z"
      fill="#2F2E41"
    />
    <path
      d="M78.3623 36.7197C78.3581 36.7197 78.3538 36.7197 78.3495 36.7196C78.2729 36.7177 78.1976 36.6998 78.1283 36.667C78.0591 36.6341 77.9975 36.5871 77.9476 36.529L76.4709 34.8062C76.4206 34.7475 76.3833 34.6788 76.3615 34.6047C76.3397 34.5305 76.3339 34.4526 76.3445 34.376C76.355 34.2995 76.3817 34.226 76.4228 34.1606C76.4638 34.0951 76.5183 34.0391 76.5826 33.9962L79.3479 32.1527L76.8427 29.1409C76.697 28.898 76.6435 28.6107 76.6921 28.3316C76.7407 28.0525 76.8881 27.8002 77.1073 27.6208C77.3266 27.4414 77.603 27.3468 77.8862 27.3544C78.1694 27.362 78.4405 27.4713 78.6498 27.6622L81.6944 30.6376C81.9341 30.8565 82.117 31.1303 82.2275 31.4354C82.3381 31.7406 82.3729 32.068 82.329 32.3896C82.2851 32.7112 82.1639 33.0173 81.9757 33.2817C81.7874 33.5462 81.5378 33.7609 81.2483 33.9077L78.7599 36.5482C78.7088 36.6023 78.6471 36.6454 78.5788 36.6749C78.5104 36.7044 78.4368 36.7197 78.3623 36.7197Z"
      fill="#2F2E41"
    />
    <path
      d="M74.9707 30.3713C74.947 30.3713 74.9233 30.3698 74.8998 30.3668C74.797 30.3543 74.6999 30.3126 74.6201 30.2466C74.5402 30.1805 74.481 30.093 74.4494 29.9943L73.3397 26.5921C73.3119 26.5061 73.3162 26.4129 73.3518 26.3298C73.3873 26.2467 73.4518 26.1792 73.5332 26.14L73.5692 26.123C73.6019 26.1074 73.6342 26.0921 73.6672 26.0774C74.1847 25.8374 74.748 25.7122 75.3184 25.7104C76.0589 25.7118 76.7829 25.9294 77.4015 26.3364C77.4844 26.3916 77.5423 26.4771 77.5628 26.5746C77.5833 26.6721 77.5646 26.7738 77.5109 26.8577L75.4292 30.1189C75.3803 30.1963 75.3127 30.2601 75.2325 30.3043C75.1523 30.3484 75.0622 30.3715 74.9707 30.3713Z"
      fill="#E6E6E6"
    />
    <path
      d="M79.1528 19.6156C79.8109 19.6156 80.3444 19.0821 80.3444 18.424C80.3444 17.7659 79.8109 17.2324 79.1528 17.2324C78.4947 17.2324 77.9612 17.7659 77.9612 18.424C77.9612 19.0821 78.4947 19.6156 79.1528 19.6156Z"
      fill="#2F2E41"
    />
    <path
      d="M75.0679 17.1143C74.2309 17.1143 73.4281 17.4468 72.8363 18.0386C72.2444 18.6305 71.9119 19.4332 71.9119 20.2702V21.6054H73.5541L74.3396 20.2702L74.5752 21.6054H79.559L78.2238 20.2702C78.2238 19.4332 77.8913 18.6305 77.2994 18.0386C76.7076 17.4468 75.9049 17.1143 75.0679 17.1143Z"
      fill="#2F2E41"
    />
    <path
      d="M79.7651 17.6079C79.8969 17.1958 80.1697 16.8435 80.5356 16.6127C80.9015 16.3819 81.337 16.2875 81.7656 16.346C82.525 16.473 83.1254 17.0834 83.4831 17.7651C83.8408 18.4469 84.006 19.2099 84.2465 19.9412C84.487 20.6726 84.8281 21.408 85.4352 21.8814C86.0423 22.3548 86.974 22.4833 87.5703 21.9962C87.5022 22.5038 87.2762 22.9771 86.9243 23.3492C86.5725 23.7213 86.1125 23.9734 85.6096 24.0698C85.1066 24.1661 84.5861 24.1019 84.1216 23.8863C83.6571 23.6706 83.2722 23.3143 83.0212 22.8679C82.7066 22.3081 82.6254 21.6521 82.5321 21.0167C82.4388 20.3814 82.3176 19.724 81.9419 19.2032C81.5663 18.6824 80.8631 18.3423 80.2664 18.5795L79.7651 17.6079Z"
      fill="#2F2E41"
    />
    <path
      d="M41.0204 19.4258C39.9341 19.4257 38.8923 18.9941 38.1242 18.2259C37.3561 17.4578 36.9245 16.416 36.9245 15.3297C36.9244 14.5888 36.9249 14.0223 36.9261 13.9418C36.9606 11.4041 37.5997 8.9112 38.7903 6.66992C36.8341 9.34796 35.7585 12.5678 35.7122 15.8839C35.7111 15.9644 35.7106 16.5309 35.7106 17.2718C35.7107 18.3581 36.1422 19.3999 36.9104 20.168C37.6785 20.9362 38.7203 21.3678 39.8066 21.3679C40.6992 21.3676 41.5673 21.0759 42.2791 20.5372C42.9908 19.9984 43.5073 19.2421 43.75 18.3831C43 19.0556 42.0278 19.427 41.0204 19.4258Z"
      fill="#4bafe1"
    />
    <path
      d="M52.0426 8.63122C53.2985 8.6912 54.521 9.05507 55.6052 9.6916C56.6895 10.3281 57.603 11.2183 58.2674 12.2857C58.9319 13.353 59.3273 14.5657 59.4198 15.8196C59.5124 17.0734 59.2992 18.331 58.7986 19.4843C59.5227 18.634 60.0548 17.6376 60.3589 16.5629C60.6629 15.4883 60.7316 14.3607 60.5604 13.2571C60.3891 12.1535 59.9819 11.0998 59.3665 10.1678C58.7511 9.23588 57.942 8.44757 56.9943 7.85665C56.0467 7.26573 54.9827 6.88609 53.875 6.74363C52.7673 6.60117 51.6419 6.69924 50.5756 7.03113C49.5092 7.36303 48.527 7.92096 47.6958 8.66688C46.8646 9.4128 46.204 10.3292 45.7591 11.3535C46.5281 10.4489 47.4946 9.73301 48.584 9.26101C49.6734 8.78902 50.8567 8.57354 52.0426 8.63122Z"
      fill="#4bafe1"
    />
    <path
      d="M53.3025 44.9158C52.1943 44.916 51.1305 44.4806 50.3404 43.7035C49.5504 42.9264 49.0976 41.8698 49.0796 40.7618C49.0282 37.5505 49.0016 33.5939 49.0016 33.1418C49.0009 32.0417 49.1593 30.9473 49.4718 29.8926L49.4703 29.8922C49.6548 29.3628 49.8777 28.8476 50.1372 28.3506C49.3407 29.412 48.7069 30.5861 48.2565 31.8344L48.258 31.8347C47.9454 32.8894 47.7871 33.9838 47.7878 35.0839C47.7878 35.536 47.8144 39.4926 47.8658 42.7039C47.8838 43.8119 48.3366 44.8685 49.1266 45.6456C49.9166 46.4227 50.9805 46.8581 52.0887 46.8579C53.0024 46.8577 53.8914 46.5613 54.6224 46.0131C55.3534 45.465 55.8869 44.6946 56.143 43.8175C55.3666 44.5255 54.3533 44.9173 53.3025 44.9158Z"
      fill="#4bafe1"
    />
    <path
      d="M15.3594 62.4612L16.8475 62.4611L17.5555 56.7212L15.3591 56.7213L15.3594 62.4612Z"
      fill="#FFB8B8"
    />
    <path
      d="M19.7175 63.8428L15.0405 63.843L15.0405 62.036L17.9104 62.0359C18.3896 62.0358 18.8493 62.2262 19.1882 62.5651C19.527 62.9039 19.7174 63.3635 19.7175 63.8428Z"
      fill="#2F2E41"
    />
    <path
      d="M10.0186 62.4612L11.5066 62.4611L12.2147 56.7212L10.0182 56.7213L10.0186 62.4612Z"
      fill="#FFB8B8"
    />
    <path
      d="M14.3765 63.8428L9.69954 63.843L9.69945 62.036L12.5694 62.0359C13.0486 62.0358 13.5083 62.2262 13.8471 62.5651C14.186 62.9039 14.3764 63.3635 14.3765 63.8428Z"
      fill="#2F2E41"
    />
    <path
      d="M11.319 60.696L9.86544 60.6195C9.79049 60.6156 9.71706 60.5968 9.64942 60.5643C9.58178 60.5318 9.52126 60.4862 9.47137 60.4301C9.42149 60.374 9.38323 60.3086 9.35881 60.2376C9.33439 60.1666 9.32429 60.0915 9.32911 60.0166L11.0664 40.7817L19.0668 42.9406L19.8376 42.7303L17.4272 59.7583C17.4066 59.8864 17.343 60.0037 17.2468 60.0907C17.1507 60.1778 17.0277 60.2294 16.8982 60.2371L15.3313 60.3101C15.2529 60.3145 15.1745 60.3025 15.101 60.275C15.0275 60.2476 14.9605 60.2051 14.9043 60.1504C14.848 60.0957 14.8037 60.0299 14.7742 59.9572C14.7446 59.8845 14.7305 59.8065 14.7327 59.728L15.0166 49.5081C15.0179 49.4609 15.0015 49.4148 14.9706 49.3791C14.9396 49.3433 14.8964 49.3205 14.8495 49.315C14.8025 49.3095 14.7552 49.3218 14.7169 49.3494C14.6785 49.3771 14.6519 49.4181 14.6423 49.4644L11.9043 60.2446C11.8785 60.3725 11.8091 60.4875 11.708 60.57C11.6068 60.6525 11.4802 60.6973 11.3497 60.6969C11.3395 60.6969 11.3293 60.6966 11.319 60.696Z"
      fill="#2F2E41"
    />
    <path
      d="M15.6268 25.0436C17.2733 25.0436 18.608 23.7088 18.608 22.0623C18.608 20.4158 17.2733 19.0811 15.6268 19.0811C13.9803 19.0811 12.6455 20.4158 12.6455 22.0623C12.6455 23.7088 13.9803 25.0436 15.6268 25.0436Z"
      fill="#FFB8B8"
    />
    <path
      d="M10.6631 30.821L10.9909 37.8936L11.1094 40.4668C11.1141 40.5788 11.1522 40.6868 11.2187 40.7771C11.2851 40.8674 11.377 40.9358 11.4826 40.9736L19.1704 43.7585C19.2325 43.7816 19.2983 43.7931 19.3646 43.7925C19.4403 43.7929 19.5153 43.778 19.5852 43.7487C19.655 43.7195 19.7182 43.6764 19.7711 43.6222C19.8239 43.5679 19.8653 43.5036 19.8927 43.433C19.9201 43.3624 19.933 43.287 19.9307 43.2113L19.6155 30.4945C19.5948 29.4512 19.2107 28.4477 18.5295 27.6572C17.8483 26.8667 16.9126 26.3386 15.8838 26.1639C15.8094 26.1526 15.7337 26.1413 15.6581 26.1312C15.0201 26.052 14.3726 26.1135 13.7609 26.3115C13.1493 26.5095 12.5885 26.8391 12.118 27.2772C11.6308 27.7253 11.2479 28.275 10.9965 28.8873C10.7451 29.4997 10.6313 30.1598 10.6631 30.821Z"
      fill="#CCCCCC"
    />
    <path
      d="M22.8424 42.3472C22.6606 42.2908 22.4944 42.1926 22.3573 42.0606C22.2201 41.9286 22.1157 41.7663 22.0524 41.5867C21.9891 41.4072 21.9686 41.2153 21.9926 41.0265C22.0167 40.8376 22.0845 40.657 22.1907 40.499L19.5837 37.0323L21.8334 36.8931L23.9642 40.2064C24.1923 40.3828 24.351 40.6338 24.4125 40.9155C24.4741 41.1972 24.4344 41.4916 24.3006 41.747C24.1668 42.0024 23.9473 42.2025 23.6806 42.3123C23.414 42.422 23.1172 42.4344 22.8424 42.3472Z"
      fill="#FFB8B8"
    />
    <path
      d="M21.0096 39.8718C20.9405 39.8438 20.8777 39.8023 20.8247 39.7499C20.7718 39.6974 20.7298 39.635 20.7011 39.5661L16.7734 30.2083C16.6964 30.0251 16.6562 29.8285 16.6551 29.6297C16.6541 29.4309 16.6922 29.2339 16.7673 29.0499C16.8425 28.8659 16.9531 28.6985 17.093 28.5572C17.2328 28.416 17.3992 28.3037 17.5824 28.2268C17.7657 28.1499 17.9624 28.1098 18.1611 28.1089C18.3599 28.108 18.5569 28.1463 18.7408 28.2216C18.9248 28.2968 19.0921 28.4076 19.2333 28.5476C19.3744 28.6876 19.4865 28.854 19.5633 29.0373L23.491 38.3953C23.5491 38.534 23.5497 38.6902 23.4928 38.8294C23.4359 38.9686 23.3261 39.0796 23.1875 39.1379L21.4438 39.8697C21.3752 39.8988 21.3014 39.9139 21.2269 39.9143C21.1524 39.9147 21.0785 39.9002 21.0096 39.8718Z"
      fill="#CCCCCC"
    />
    <path
      d="M14.668 39.5417L18.4405 32.3184C18.5132 32.1795 18.6381 32.0751 18.7877 32.0281C18.9374 31.9812 19.0995 31.9955 19.2386 32.0679L24.7143 34.9277C24.8532 35.0005 24.9576 35.1253 25.0045 35.275C25.0515 35.4246 25.0372 35.5867 24.9648 35.7258L21.1923 42.9491C21.1195 43.088 20.9947 43.1924 20.845 43.2393C20.6954 43.2863 20.5333 43.272 20.3942 43.1995L14.9185 40.3398C14.7796 40.267 14.6752 40.1421 14.6282 39.9925C14.5813 39.8429 14.5956 39.6808 14.668 39.5417Z"
      fill="#4bafe1"
    />
    <path
      d="M17.198 36.2414C17.131 36.0632 17.0232 35.9032 16.8833 35.7741C16.7434 35.645 16.5752 35.5503 16.3923 35.4977C16.2093 35.4451 16.0165 35.436 15.8294 35.4712C15.6423 35.5063 15.466 35.5847 15.3146 35.7L11.7 33.3022L11.6938 35.5563L15.1272 37.4877C15.3168 37.705 15.5767 37.8486 15.8616 37.8934C16.1464 37.9382 16.4379 37.8812 16.685 37.7325C16.932 37.5838 17.1189 37.3529 17.2127 37.0803C17.3065 36.8076 17.3013 36.5106 17.198 36.2414Z"
      fill="#FFB8B8"
    />
    <path
      d="M12.6278 36.7481L9.67377 34.4082C9.3542 34.3009 9.06544 34.1176 8.83228 33.8741C8.59912 33.6307 8.42853 33.3343 8.33514 33.0103C8.24175 32.6864 8.22836 32.3447 8.29612 32.0145C8.36387 31.6843 8.51075 31.3754 8.72414 31.1144L11.4063 27.5993C11.5927 27.3715 11.8549 27.2184 12.1448 27.168C12.4348 27.1176 12.7332 27.1732 12.9855 27.3247C13.2379 27.4762 13.4272 27.7135 13.519 27.9931C13.6109 28.2727 13.599 28.576 13.4856 28.8476L11.3633 32.3191L14.4821 33.7984C14.5546 33.8328 14.619 33.8822 14.6711 33.9433C14.7231 34.0044 14.7615 34.0759 14.7839 34.153C14.8062 34.2301 14.812 34.3111 14.8007 34.3905C14.7895 34.47 14.7615 34.5462 14.7186 34.614L13.4597 36.6064C13.4171 36.6736 13.3609 36.7312 13.2947 36.7753C13.2285 36.8195 13.1537 36.8492 13.0753 36.8626C13.0722 36.8632 13.069 36.8637 13.0659 36.8641C12.9888 36.8758 12.9102 36.8715 12.8348 36.8515C12.7595 36.8316 12.689 36.7964 12.6278 36.7481Z"
      fill="#CCCCCC"
    />
    <path
      d="M13.3739 24.0584C13.3611 24.021 13.3571 23.9811 13.3621 23.9419C13.367 23.9027 13.3809 23.8651 13.4027 23.8321C13.4244 23.799 13.4535 23.7714 13.4875 23.7514C13.5216 23.7313 13.5598 23.7193 13.5992 23.7162C13.68 23.7206 13.759 23.7413 13.8315 23.7771C13.904 23.8129 13.9686 23.863 14.0212 23.9244C14.1291 24.0488 14.2547 24.1566 14.394 24.2445C14.5382 24.3172 14.7338 24.3068 14.816 24.1682C14.8932 24.0381 14.8403 23.8638 14.7936 23.7126C14.6746 23.328 14.6081 22.9291 14.5957 22.5267C14.5822 22.0776 14.6456 21.6088 14.8943 21.2585C15.2152 20.8064 15.789 20.6348 16.3321 20.6472C16.8752 20.6596 17.4094 20.8273 17.9473 20.9333C18.133 20.9698 18.3513 20.9885 18.4755 20.8446C18.6076 20.6916 18.5591 20.4439 18.5029 20.2373C18.3572 19.7011 18.2025 19.1467 17.8638 18.7136C17.4976 18.2637 16.9745 17.9692 16.3999 17.8894C15.8482 17.8211 15.2881 17.8847 14.7658 18.075C13.9147 18.3432 13.1659 18.8649 12.6194 19.5702C12.056 20.3203 11.8126 21.263 11.9423 22.1921C12.069 23.0013 12.4978 23.7324 13.1423 24.2379L13.3739 24.0584Z"
      fill="#2F2E41"
    />
    <path
      d="M51.1326 62.4851C50.3395 62.1396 49.6374 61.6145 49.0819 60.9513C48.5264 60.288 48.1327 59.5048 47.9317 58.6633C47.7308 57.8218 47.7282 56.9451 47.9241 56.1024C48.12 55.2598 48.5091 54.4742 49.0606 53.8076C48.4212 54.2745 47.8883 54.8721 47.4975 55.5606C47.1066 56.2492 46.8667 57.013 46.7936 57.8014C46.7205 58.5897 46.8159 59.3846 47.0735 60.1333C47.3311 60.882 47.745 61.5673 48.2877 62.1438C48.8304 62.7203 49.4895 63.1748 50.2213 63.4771C50.9531 63.7794 51.7408 63.9226 52.5321 63.8972C53.3235 63.8718 54.1004 63.6783 54.8113 63.3297C55.5221 62.9811 56.1507 62.4853 56.6553 61.8751C55.8683 62.4504 54.9443 62.8094 53.9753 62.9164C53.0063 63.0234 52.0262 62.8747 51.1326 62.4851Z"
      fill="#4bafe1"
    />
  </svg>
);

export default CreateEvaluationModalImage;
