import './CheckboxButton.scss';

const CheckboxButton = ({ checked, className, name, option, onChange, editable, value }) => (
  <label className={`checkboxButton bounce mb-0 d-flex mt-1 ${className || ''}`}>
    <input
      type="checkbox"
      name={name}
      checked={checked}
      value={value}
      disabled={editable}
      className="bg-white"
      readOnly
      onChange={onChange}
    />
    <svg viewBox="0 0 21 21">
      <polyline points="5 10.75 8.5 14.25 16 6" />
    </svg>
    <span className="ml-1 radio-button-option">{option}</span>
  </label>
);
export default CheckboxButton;
