const RemoveInputIcon = () => (
  <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.33333 21.3333C1.33333 22.8 2.53333 24 4 24H14.6667C16.1333 24 17.3333 22.8 17.3333 21.3333V5.33333H1.33333V21.3333ZM4.61333 11.84L6.49333 9.96L9.33333 12.7867L12.16 9.96L14.04 11.84L11.2133 14.6667L14.04 17.4933L12.16 19.3733L9.33333 16.5467L6.50667 19.3733L4.62667 17.4933L7.45333 14.6667L4.61333 11.84ZM14 1.33333L12.6667 0H6L4.66667 1.33333H0V4H18.6667V1.33333H14Z"
      fill="#757575"
    />
  </svg>
);

export default RemoveInputIcon;
