import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import { auth0Client } from '../../auth0/auth0';
import Header from '../../components/Header/Header';
import { t } from '../../i18n/i18n';
import { Window } from '../../utils/window';
import { ErrorType } from '../../constants/enums';
import processQueryString from '../../utils/processQueryString';
import useRoles from '../../hooks/useRoles';
import { sendPartnerAccessEmail } from '../../API/services/authService';
import { isCompanyEmail } from '../../utils/emailsUtils';
import './Error.scss';

const Error = () => {
  const history = useHistory();
  const { type } = processQueryString(history.location.search);
  const { roles, userRoles } = useRoles();
  const { email } = useSelector((state) => state.auth);
  const [requestPartnerAccess, setRequestPartnerAccess] = useState(false);
  const [partnerAccessRequested, setPartnerAccessRequested] = useState(false);

  const [messagesToShow, setMessagesToShow] = useState({
    title: t('ERROR_TITLE'),
    subtitle: t('ERROR_SUBTITLE'),
  });

  const campusRoles = [roles.APPLICANT, roles.ALKYMER, roles.PREACCELERATION];

  const isCampusUser = () => userRoles.some((role) => campusRoles.includes(role));

  useEffect(() => {
    if (type === ErrorType.INVALID_ROLES && isCampusUser() && isCompanyEmail(email)) {
      setRequestPartnerAccess(true);
      setMessagesToShow({
        title: t('ERROR_TITLE_INVALID_ROLES_REQUEST_ACCESS', true),
        subtitle: t('ERROR_SUBTITLE_INVALID_ROLES_REQUEST_ACCESS', true),
      });
    } else if (ErrorType[type]) {
      setMessagesToShow({
        title: t(`ERROR_TITLE_${type}`, true),
        subtitle: t(`ERROR_SUBTITLE_${type}`, true),
      });
    }
  }, [type, email, userRoles]);

  const requestAccess = async () => {
    if (!partnerAccessRequested) {
      setPartnerAccessRequested(true);
      await sendPartnerAccessEmail();
    }
    notification.open({
      message: t('PARTNER_ACCESS_REQUESTED'),
      type: 'success',
    });
  };

  const campusURL = process.env.RAZZLE_RUNTIME_CAMPUS_BASE_URL;

  const redirectCampus = () => {
    Window().location.href = campusURL;
  };

  const buildRequestAccessMessage = () =>
    messagesToShow.subtitle.split('||').map((text, i) => {
      if (i === 1)
        return (
          <span
            className="font-montserrat font-weight-bold cursor-pointer"
            onClick={redirectCampus}
            aria-hidden
          >
            {text}
          </span>
        );
      if (i === 3)
        return (
          <span
            className="font-montserrat font-weight-bold cursor-pointer"
            onClick={requestAccess}
            aria-hidden
          >
            {text}
          </span>
        );
      return text;
    });

  return (
    <>
      <Header maintenance />
      <div className="error-container d-flex align-items-center justify-content-center">
        <div className="d-flex flex-column align-items-center">
          {Boolean(messagesToShow.title) && (
            <span className="error-title-color text-center font-montserrat font-weight-bold text-xl">
              {messagesToShow.title}
            </span>
          )}
          {Boolean(messagesToShow.subtitle) && (
            <span className="error-subtitle text-center mt-2 text-base font-montserrat mw-90">
              {requestPartnerAccess ? buildRequestAccessMessage() : messagesToShow.subtitle}
            </span>
          )}
          <button
            type="button"
            className="error-button border-0 mt-5"
            onClick={() => {
              if (Window()) {
                localStorage.clear();
                auth0Client().logout({ returnTo: window.location.origin });
              }
            }}
          >
            <span className="error-button-text font-roboto font-weight-bold">
              🤓 {t('MAINTENANCE_BUTTON')}
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Error;
