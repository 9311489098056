import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { t } from '../../i18n/i18n';
import imgCheck from '../../assets/profile/check.svg';
import closeIcon from '../../assets/sidebar/icons/close-icon.svg';
import './ticket.scss';

const Ticket = (props) => {
  const {
    className,
    text,
    check,
    width,
    close,
    accelerating,
    secondary,
    sprint,
    sprintFrom,
    sprintTo,
    tooltip = false,
  } = props;
  return tooltip ? (
    <OverlayTrigger placement="top" overlay={<Tooltip>{text}</Tooltip>}>
      <span style={width && { width: `${width}` }} className={className}>
        {accelerating && t('PROFILES_FILTER_CERTIFIED_TECHNOLOGY')}
        {secondary && t('PROFILES_FILTER_SECONDARY_TECHNOLOGY')}
        {sprint && sprintFrom}
        {sprint}
        {sprint && sprintTo}
        {text}
        {check && <img className="ml-2" src={imgCheck} alt="check" />}
        {close && <img className="ml-2" src={closeIcon} alt="close" />}
      </span>
    </OverlayTrigger>
  ) : (
    <span style={width && { width: `${width}` }} className={className}>
      {accelerating && t('PROFILES_FILTER_CERTIFIED_TECHNOLOGY')}
      {secondary && t('PROFILES_FILTER_SECONDARY_TECHNOLOGY')}
      {sprint && sprintFrom}
      {sprint}
      {sprint && sprintTo}
      {text}
      {check && <img className="ml-2" src={imgCheck} alt="check" />}
      {close && <img className="ml-2" src={closeIcon} alt="close" />}
    </span>
  );
};

export default Ticket;
