import { useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

const sizes = [0, 350, 400, 450, 500, 600, 700, 800, 900, 1000];

export const useElementSize = () => {
  const { ref, width, height } = useResizeDetector();
  const [size, setSize] = useState('');

  useEffect(() => {
    const stringSizes = sizes.filter((size) => size <= width).join(' ');
    setSize(stringSizes);
  }, [width]);

  return { ref, width, height, size };
};
