import { useEffect } from 'react';

function useClickOutside(ref, callback, ignoreRefs = []) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !ignoreRefs.some(
          (ignoreRef) => ignoreRef.current && ignoreRef.current.contains(event.target)
        )
      ) {
        callback();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback, ignoreRefs]);
}

export default useClickOutside;
