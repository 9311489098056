import React from 'react';
import './newStyleInput.scss';

const NewStyleInput = ({
  title,
  titleBold = false,
  required,
  disabled = false,
  onChange,
  errors,
  type,
  value,
  name,
  className,
  classNameInput,
}) => (
  <div className={`d-flex flex-column w-100 ${className}`}>
    <span
      className={`d-flex align-items-center mb-1 font-montserrat italic text-truncate custom-input-label-active text-sm ${
        titleBold ? 'font-weight-600' : ''
      }`}
    >
      {title}
      {required && <span className="text-danger ml-1">*</span>}
    </span>
    <input
      className={`text-xs w-100 py-1 bg-transparent px-2 input-custom-new-style font-montserrat text-truncate ${
        errors?.length ? 'border-red-500' : 'border-gray-300'
      } ${classNameInput}`}
      onChange={onChange}
      type={type}
      name={name}
      value={value}
      disabled={disabled}
    />
    {!!errors?.length && <span className="text-danger text-xs ml-1">{errors}</span>}
  </div>
);

export default NewStyleInput;
