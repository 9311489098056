const CrossLogo = () => (
  <svg
    data-testid="cross-logo"
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.06803 11.9926C0.677505 12.3832 0.677506 13.0163 1.06803 13.4069L1.83644 14.1753C2.22696 14.5658 2.86012 14.5658 3.25064 14.1753L7.99996 9.4259L12.7493 14.1753C13.1398 14.5658 13.773 14.5658 14.1635 14.1753L14.9319 13.4069C15.3224 13.0164 15.3224 12.3832 14.9319 11.9927L10.1826 7.24325L14.9321 2.49361C15.3227 2.10309 15.3227 1.46992 14.9321 1.07939L14.1637 0.310962C13.7732 -0.0795653 13.14 -0.0795656 12.7495 0.310962L7.99996 5.0606L3.25041 0.310972C2.85989 -0.0795556 2.22673 -0.0795551 1.83621 0.310972L1.06779 1.0794C0.677272 1.46993 0.677272 2.1031 1.06779 2.49362L5.81734 7.24325L1.06803 11.9926Z"
      fill="#DC251C"
    />
  </svg>
);

export default CrossLogo;
