const Check = () => (
  <svg
    data-testid="check-icon"
    width="23"
    height="23"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="23" height="22" rx="10" fill="#5EDCA7" />
    <path
      d="M7.5 10.5L10.5 13.5L15.5 8.5"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Check;
