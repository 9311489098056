import { useDispatch, useSelector } from 'react-redux';
import { setAppliedFilters, setTextQuery } from '../../redux/actions/profilesActions';
import MultipleSelect from '../MultipleSelect/MultipleSelect';
import setProctoringAmountOfEvents from '../../utils/filters/setProctoringAmountOfEvents';
import DropDownArrow from '../../assets/screening/DropDownArrow';
import { t } from '../../i18n/i18n';

function ProctoringRangeFilters({ setAmountOfEvents }) {
  const dispatch = useDispatch();
  const { appliedFilters } = useSelector((state) => state.profiles);

  const removeAppliedFilter = () => {
    dispatch(
      setAppliedFilters([
        ...appliedFilters.filter(
          (filter) => filter.type !== 'maxEvents' && filter.type !== 'minEvents'
        ),
      ])
    );
  };

  const addFilter = (filter) => {
    setAmountOfEvents({});
    const isApplied = appliedFilters.filter(
      (appliedFilter) => appliedFilter.key === filter.key
    ).length;

    if (!isApplied) {
      dispatch(setTextQuery(''));
      dispatch(
        setAppliedFilters([
          ...appliedFilters.filter(
            (filter) => filter.type !== 'maxEvents' && filter.type !== 'minEvents'
          ),
          ...[
            { ...filter, name: 'maxEvents', id: filter.max, type: 'maxEvents' },
            { ...filter, name: 'minEvents', id: filter.min, type: 'minEvents' },
          ],
        ])
      );
    } else {
      removeAppliedFilter();
    }
  };

  const events =
    appliedFilters
      .filter((filter) => filter.type === 'maxEvents' || filter.type === 'minEvents')
      .sort((a, b) => a.id - b.id)[0] ?? undefined;

  return (
    <>
      <MultipleSelect
        title={t('DASHBOARD_FILTERS_PROCTORING_EVENTS_PLACEHOLDER')}
        firstSelected
        classNames="filters-dropdown border-maya-blue"
        options={setProctoringAmountOfEvents()}
        onSelect={(e) => addFilter(e)}
        selectedList={events ? [`${events?.min}/${events?.max}`] : []}
        CustomArrow={DropDownArrow}
      />
    </>
  );
}

export default ProctoringRangeFilters;
