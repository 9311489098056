import { t } from '../../i18n/i18n';

const options = [
  { min: 0, max: 5 },
  { min: 6, max: 10 },
  { min: 11, max: 15 },
  { min: 16, max: 30 },
  { min: 30, max: undefined },
];

const setProctoringAmountOfEvents = () =>
  options.map((op) => ({
    ...op,
    name: op.max
      ? t('DASHBOARD_AMOUNT_OF_EVENTS_FROM_TO')
          .replace('{{min}}', op.min)
          .replace('{{max}}', op.max)
      : t('DASHBOARD_AMOUNT_OF_EVENTS_FROM_TO_INFINITE').replace('{{min}}', op.min),
    key: `from-${op.min}-to-${op.max}`,
    ids: { maxEvents: op.max, minEvents: op.min },
    id: `${op.min}/${op.max}`,
    displayName: op.max
      ? t('DASHBOARD_AMOUNT_OF_EVENTS_FROM_TO')
          .replace('{{min}}', op.min)
          .replace('{{max}}', op.max)
      : t('DASHBOARD_AMOUNT_OF_EVENTS_FROM_TO_INFINITE').replace('{{min}}', op.min),
  }));

export default setProctoringAmountOfEvents;
