import React from 'react';

const HeadsetAudioIcon = ({ className }) => (
  <svg
    className={className}
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.42857 1.39286C4.09442 1.39286 1.39286 4.09442 1.39286 7.42857V10.4464C1.39286 10.8324 1.08237 11.1429 0.696429 11.1429C0.310491 11.1429 0 10.8324 0 10.4464V7.42857C0 3.32545 3.32545 0 7.42857 0C11.5317 0 14.8571 3.32545 14.8571 7.42857V10.4464C14.8571 10.8324 14.5467 11.1429 14.1607 11.1429C13.7748 11.1429 13.4643 10.8324 13.4643 10.4464V7.42857C13.4643 4.09442 10.7627 1.39286 7.42857 1.39286ZM2.32143 9.28571C2.32143 8.26138 3.15424 7.42857 4.17857 7.42857H4.64286C5.15647 7.42857 5.57143 7.84353 5.57143 8.35714V12.0714C5.57143 12.585 5.15647 13 4.64286 13H4.17857C3.15424 13 2.32143 12.1672 2.32143 11.1429V9.28571ZM10.6786 7.42857C11.7029 7.42857 12.5357 8.26138 12.5357 9.28571V11.1429C12.5357 12.1672 11.7029 13 10.6786 13H10.2143C9.70067 13 9.28571 12.585 9.28571 12.0714V8.35714C9.28571 7.84353 9.70067 7.42857 10.2143 7.42857H10.6786Z"
      fill="#3D8C6B"
    />
  </svg>
);

export default HeadsetAudioIcon;
