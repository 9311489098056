import { useState } from 'react';
import { t } from '../../i18n/i18n';
import CopyCheck from '../../assets/search/Check';
import LinkIcon from '../../assets/search/Link';
import { TrackEvent } from '../../utils/filters/segmentUtils';
import DisabledFeature from '../DisabledFeature/DisabledFeature';
import { PlanFeaturesIds } from '../../constants/enums';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const SearchLink = ({ link, track }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const copyLink = () => {
    if (track) {
      TrackEvent(track);
    }
    navigator.clipboard.writeText(link);
    setCopySuccess(true);

    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  };

  return (
    <DisabledFeature
      className="d-flex flex-row"
      iconClassName="align-self-start mt-4 mr-3"
      childrenClassname="mt-2 pt-1"
      featureId={PlanFeaturesIds.SEARCH_LINKS}
    >
      <div className="d-flex flex-column mt-2">
        <h5 className="text-base font-montserrat font-weight-600 mt-2">
          {t('INVITATION_SEARCH_LINK_TITLE')}
        </h5>
        <p className="font-montserrat font-weight-500 text-sm mt-2 gray-subtitle">
          {t('INVITATION_SEARCH_LINK_SUBTITLE')}
        </p>
        <div className="w-75">
          <div className="d-flex">
            <input
              type="text"
              name="search_link"
              value={link}
              className="url-input-placeholder maya-blue-thin-border py-2 px-3 gray-subtitle font-montserrat text-xs font-weight-600 outline-none w-75"
              disabled
              data-testid="input-search-link"
            />
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip open={copySuccess} className="font-montserrat">
                  {t('COPIED_INVITATION_LINK')}
                </Tooltip>
              }
            >
              <button
                type="button"
                data-testid="search-link-button"
                className="px-3 py-1 copy-link-btn text-white bg-maya-blue font-montserrat text-xs font-weight-bold outline-none maya-blue-thin-border bg-transparent"
                onClick={copyLink}
              >
                {copySuccess ? <CopyCheck /> : <LinkIcon />}
              </button>
            </OverlayTrigger>
          </div>
        </div>
        <div className="d-flex mt-2">
          <p className="font-montserrat text-xs gray-subtitle">
            {t('INVITATION_SEARCH_LINK_ACLARATION')}
          </p>
          <span className="font-weight-500 font-montserrat text-xs ml-1 text-gray-2">
            {t('INVITATION_SEARCH_LINK_ACLARATION_PART_TWO')}
            <strong className="font-montserrat text-xs ml-1">
              {t('INVITATION_SEARCH_LINK_ACLARATION_PART_TREE')}
            </strong>
          </span>
        </div>
      </div>
    </DisabledFeature>
  );
};

export default SearchLink;
