export const RESET_STATE = 'RESET_STATE';
export const SET_BUTTON_LOADING = 'SET_BUTTON_LOADING';
export const GET_PROFILES = 'GET_PROFILES';
export const GET_PROFILE = 'GET_PROFILE';
export const SET_PROFILES = 'SET_PROFILES';
export const SET_PROFILE = 'SET_PROFILE';
export const GET_SOME_PROFILES = 'GET_SOME_PROFILES';
export const SET_SOME_PROFILES = 'SET_SOME_PROFILES';
export const GET_SOME_PROFILES_FILTERS = 'GET_SOME_PROFILES_FILTERS';
export const SET_SOME_PROFILES_FILTERS = 'SET_SOME_PROFILES_FILTERS';
export const SET_SOME_PROFILES_APPLIED_FILTERS = 'SET_SOME_PROFILES_APPLIED_FILTERS';
export const SET_SOME_PROFILES_NEXT_URL = 'SET_SOME_PROFILES_NEXT_URL';
export const SET_PROFILES_FILTERS = 'SET_PROFILES_FILTERS';
export const SET_APPLIED_FILTERS = 'SET_APPLIED_FILTERS';
export const SET_PROFILES_NEXT_URL = 'SET_PROFILES_NEXT_URL';
export const SET_PROFILES_QUERY = 'SET_PROFILES_QUERY';
export const PROCESS_PROFILES_QUERY = 'PROCESS_PROFILES_QUERY';
export const REFRESH_PROFILES = 'REFRESH_PROFILES';
export const SET_TEXT_QUERY = 'SET_TEXT_QUERY';
export const SET_AGE_QUERY = 'SET_AGE_QUERY';
export const SET_PROFILES_LOADING = 'SET_PROFILES_LOADING';
export const CLEAR_APPLIED_FILTERS = 'CLEAR_APPLIED_FILTERS';
export const GET_ME = 'GET_ME';
export const SET_LOADING_ME = 'SET_LOADING_ME';
export const SET_USER = 'SET_USER';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const CHECK_TOKEN = 'CHECK_TOKEN';
export const SET_CHECKING_TOKEN = 'SET_CHECKING_TOKEN';
export const GET_TECHNOLOGIES = 'GET_TECHNOLOGIES';
export const SET_TECHNOLOGIES = 'SET_TECHNOLOGIES';
export const SET_CERTIFIABLE_TECHNOLOGIES = 'SET_CERTIFIABLE_TECHNOLOGIES';
export const HANDLE_ERROR = 'HANDLE_ERROR';
export const GET_SCREENINGS = 'GET_SCREENINGS';
export const SET_SCREENINGS = 'SET_SCREENINGS';
export const GET_TEAMS = 'GET_TEAMS';
export const SET_TEAMS = 'SET_TEAMS';
export const SET_TOTAL_PROFILES = 'SET_TOTAL_PROFILES';
export const GET_ALKYMERS_STATS = 'GET_ALKYMERS_STATS';
export const SET_ALKYMERS_STATS = 'SET_ALKYMERS_STATS';
export const GET_ALKYMER_ADVANCE = 'GET_ALKYMER_ADVANCE';
export const SET_ALKYMER_ADVANCE = 'SET_ALKYMER_ADVANCE';
export const SET_SIDEBAR = 'SET_SIDEBAR';
export const SET_MAINTENANCE = 'SET_MAINTENANCE';
export const GET_TECHNOLOGY_ADVANCE = 'GET_TECHNOLOGY_ADVANCE';
export const GET_FAVORITE_PROFILES = 'GET_FAVORITE_PROFILES';
export const SET_FAVORITE_PROFILES = 'SET_FAVORITE_PROFILES';
export const GET_COHORTS = 'GET_COHORTS';
export const SET_COHORTS = 'SET_COHORTS';
export const SET_WHITELABEL = 'SET_WHITELABEL';
export const GET_NEWS = 'GET_NEWS';
export const SET_NEWS = 'SET_NEWS';
export const GET_TOP_BY_TECHNOLOGY = 'GET_TOP_BY_TECHNOLOGY';
export const SET_TOP_BY_TECHNOLOGY = 'SET_TOP_BY_TECHNOLOGY';
export const SET_TOP_LOADING = 'SET_TOP_LOADING';
export const SET_PROFILES_ORDERS = 'SET_PROFILES_ORDERS';
export const SET_SELECTED_VIDEO = 'SET_SELECTED_VIDEO';
export const GET_USEFUL_CONTENT = 'GET_USEFUL_CONTENT';
export const SET_USEFUL_CONTENT = 'SET_USEFUL_CONTENT';
export const GET_MOST_HIRES_COMPANIES = 'GET_MORE_HIRES_COMPANIES';
export const SET_MOST_HIRES_COMPANIES = 'SET_MORE_HIRES_COMPANIES';
export const SET_MOST_HIRES_COMPANIES_LOADING = 'SET_MOST_HIRES_COMPANIES_LOADING';
export const SET_ENGAGEMENT_QUERY = 'SET_ENGAGEMENT_QUERY';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_TOUR_RUNNING = 'SET_TOUR_RUNNING';
export const SET_TOUR_STEP = 'SET_TOUR_STEP';
export const SET_SAVED_SEARCHES = 'SET_SAVED_SEARCHES';
export const SET_ORDER = 'SET_ORDER';
export const SET_SCREENING_TECHNOLOGIES = 'SET_SCREENING_TECHNOLOGIES';
export const SET_SCREENING_INVITATIONS = 'SET_SCREENING_INVITATIONS';
export const SET_SCREENING_INVITATIONS_FILTERS = 'SET_SCREENING_INVITATIONS_FILTERS';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_LOGIN_LOADING = 'SET_LOGIN_LOADING';
export const SET_NEW_SEARCH_DATA = 'SET_NEW_SEARCH_DATA';
export const SET_SELECTED = 'SET_SELECTED';
export const SET_CONFIRMED = 'SET_CONFIRMED';
export const SET_SEARCHES = 'SET_SEARCHES';
export const SET_TEMPLATE = 'SET_TEMPLATE';
export const SET_SELECTED_SEARCH = 'SET_SELECTED_SEARCH';
export const SET_SELECTED_SEARCH_FILTERS = 'SET_SELECTED_SEARCH_FILTERS';
export const SET_STAGE_CANDIDATES = 'SET_STAGE_CANDIDATES';
export const SET_INVITED_PROFILE = 'SET_INVITED_PROFILE';
export const SET_EMAILS_DATA = 'SET_EMAILS_DATA';
export const SET_INVITED_PROFILE_TEST_GROUPS = 'SET_INVITED_PROFILE_TEST_GROUPS';
export const SET_NEW_SCREENING_DATA = 'SET_NEW_SCREENING_DATA';
export const SET_NEW_SCREENING_FILTERS = 'SET_NEW_SCREENING_FILTERS';
export const SET_TEST_GROUPS_DATA = 'SET_TEST_GROUPS_DATA';
export const SET_NEW_SCREENING_LEVELS_FILTERS_OPTIONS = 'SET_NEW_SCREENING_LEVELS_FILTERS_OPTIONS';
export const SET_NEW_SCREENING_CATEGORIES_FILTERS_OPTIONS =
  'SET_NEW_SCREENING_CATEGORIES_FILTERS_OPTIONS';
export const SET_NEW_SCREENING_ROLES_FILTERS_OPTIONS = 'SET_NEW_SCREENING_ROLES_FILTERS_OPTIONS';
export const SET_NEW_SCREENING_TAGS_FILTERS_OPTIONS = 'SET_NEW_SCREENING_TAGS_FILTERS_OPTIONS';
export const SET_ENDORSEMENT_CREATOR_OPTIONS = 'SET_ENDORSEMENT_CREATOR_OPTIONS';
export const SET_ENDORSEMENT_COMPANY_OPTIONS = 'SET_ENDORSEMENT_COMPANY_OPTIONS';
export const SET_NEW_SCREENING_QUICK_ROLES_FILTERS_OPTIONS =
  'SET_NEW_SCREENING_QUICK_ROLES_FILTERS_OPTIONS';
export const SET_SUBJECT_EMAIL = 'SET_SUBJECT_EMAIL';
export const SET_SUBMISSIONS_SELECTED = 'SET_SUBMISSIONS_SELECTED';
export const SET_SEARCH_WAS_ALREADY_CREATED = 'SET_SEARCH_WAS_ALREADY_CREATED';
export const SET_SEARCH_INVITATIONS_LEFT = 'SET_SEARCH_INVITATIONS_LEFT';
export const SET_VERIFY = 'SET_VERIFY';
export const SET_SEND_EMAIL_FROM = 'SET_SEND_EMAIL_FROM';
export const SET_SHOW_FIRST_FILTER_MODAL = 'SET_SHOW_FIRST_FILTER_MODAL';
export const SET_TECHNOLOGY_TYPES = 'SET_TECHNOLOGY_TYPES';
export const SET_PROGRESS = 'SET_PROGRESS';
export const SET_REFS = 'SET_REFS';
export const SET_CREATE_SCREENING_FROM_SEARCH = 'SET_CREATE_SCREENING_FROM_SEARCH';
export const SET_AMOUNT_OF_FILTERS = 'SET_AMOUNT_OF_FILTERS';
export const SET_KEEP_TESTS_FILTERS = 'SET_KEEP_TESTS_FILTERS';
export const SET_MODAL_DATA = 'SET_MODAL_DATA';
export const SET_KEEP_SEARCH_DATA = 'SET_KEEP_SEARCH_DATA';
export const SET_INVITATION_ID_TO_SCROLL = 'SET_INVITATION_ID_TO_SCROLL';
export const SET_SCREENING_ID_TO_KEEP = 'SET_SCREENING_ID_TO_KEEP';
export const SET_DISPLAY_TEST_GROUP = 'SET_DISPLAY_TEST_GROUP';
export const SET_PLAN = 'SET_PLAN';
export const SET_PLAN_FEATURES = 'SET_PLAN_FEATURES';
export const SET_EVALUATION_DATA = 'SET_EVALUATION_DATA';
export const SET_VERIFY_EMAIL_USER = 'SET_VERIFY_EMAIL_USER';
export const SET_CUSTOM_TESTGROUP_INCOMPLETE_QUESTION = 'SET_CUSTOM_TESTGROUP_INCOMPLETE_QUESTION';
export const SET_SEARCH_TABLE_PERSONALIZATION = 'SET_SEARCH_TABLE_PERSONALIZATION';
export const SET_STAGE_SCREENING = 'SET_STAGE_SCREENING';
export const SET_CURRENT_WORKSPACE = 'SET_CURRENT_WORKSPACE';
export const SET_CURRENT_ROLE = 'SET_CURRENT_ROLE';
export const SET_WORKSPACES = 'SET_WORKSPACES';
export const SET_WORKSPACE_FAVORITE = 'SET_WORKSPACE_FAVORITE';
export const SET_WORKSPACE_OWNERS = 'SET_WORKSPACE_OWNERS';
export const SET_JOBPOST_DATA = 'SET_JOBPOST_DATA';
export const RESET_JOBPOST_DATA = 'RESET_JOBPOST_DATA';
export const EDIT_JOBPOST_DATA = 'EDIT_JOBPOST_DATA';
export const SET_EVALUATION_SEARCH_TYPES = 'SET_EVALUATION_SEARCH_TYPES';
