import React from 'react';

const ArrowSmall = ({ color, classNames }) => (
  <svg
    className={classNames}
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.1 8.85779L14.7029 7.31294L9.06231 13.3331L9.1731 0.101059L7.17317 0.0843137L7.06238 13.3163L1.52337 7.20259L0.100583 8.72383L8.02747 17.4889L16.1 8.85779Z"
      fill={color}
    />
  </svg>
);

export default ArrowSmall;
