const PointLeftFinger = () => (
  <svg
    data-testid="check-icon"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 100 125"
    fill="#919191"
    xmlSpace="preserve"
  >
    <switch>
      <foreignObject
        requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/"
        x="0"
        y="0"
        width="1"
        height="1"
      />
      <path d="M2.5,38.4c0,3.7,3,6.7,6.7,6.7h11.3c-0.3,0.8-0.5,1.6-0.5,2.5c0,2.2,1.1,4.1,2.7,5.3c-0.8,1.1-1.2,2.4-1.2,3.8    c0,2.1,1,4,2.6,5.2c-0.8,1.1-1.3,2.4-1.3,3.9c0,3.7,3,6.7,6.7,6.7h7.1c0.7,0,1.4-0.1,2.1-0.4c7.1,1.6,15,1.9,20.5,1.9    c5.3-0.1,10.1-2.8,13-7.3l0.6-1h5.8v0.4c0,1.8,1.5,3.3,3.3,3.3h12.4c1.8,0,3.3-1.5,3.3-3.3V37.7c0-1.8-1.5-3.3-3.3-3.3H81.7    c-1.8,0-3.3,1.5-3.3,3.3v0.4h-5.7l-9-8c-3.6-3.2-8.5-4.7-13.3-4.1l-9,1.2c-3,0.4-5.4,2.1-6.8,4.5H9.2C5.5,31.8,2.5,34.8,2.5,38.4z     M84.8,60.8c0-1.8,1.4-3.2,3.2-3.2c1.8,0,3.2,1.4,3.2,3.2c0,1.8-1.4,3.2-3.2,3.2C86.2,64,84.8,62.6,84.8,60.8z M42.3,39.8l0.3,7.4    c0,0.7-0.2,1.3-0.7,1.8c-0.5,0.5-1.1,0.8-1.8,0.8c-1.3,0-2.4-1.1-2.4-2.4l-0.2-10.8c0-2.6,1.9-4.8,4.5-5.2l9-1.2    c3.6-0.5,7.2,0.6,10,3l9.6,8.5c0.4,0.3,0.9,0.5,1.4,0.5h6.5v19.4h-6.9c-0.7,0-1.4,0.4-1.8,1l-1.2,1.9c-2.1,3.3-5.6,5.3-9.5,5.3    c-4.4,0-10.6-0.2-16.5-1.2c0.4-0.9,0.7-1.8,0.7-2.8c0-1.5-0.5-2.8-1.3-3.9c1.6-1.2,2.6-3.1,2.6-5.2c0-1.4-0.4-2.6-1.1-3.7    c0.6-0.3,1.1-0.7,1.5-1.2c1-1.1,1.6-2.4,1.8-3.8c3.9,3.7,8.8,4.6,11.7,4.3c1.1-0.1,1.9-1,1.9-2.1v-0.2c-0.1-1.1-1.1-2-2.3-1.9    c-0.4,0-7.5,0.6-11-6.8l2.4-0.4c1.1-0.2,1.9-1.2,1.8-2.4c-0.2-1.1-1.2-1.9-2.4-1.8l-4.7,0.7C43,37.8,42.2,38.7,42.3,39.8z     M27,65.9c0-1.4,1.1-2.5,2.5-2.5h7.1c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5h-7.1C28.1,68.4,27,67.3,27,65.9z M25.7,56.7    c0-1.4,1.1-2.5,2.5-2.5h9.7c1.4,0,2.5,1.1,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5h-9.7C26.8,59.2,25.7,58.1,25.7,56.7z M24.2,47.6    c0-1.4,1.1-2.5,2.5-2.5h6.9l0,2.3c0,0.9,0.2,1.8,0.6,2.6h-7.5C25.3,50.1,24.2,49,24.2,47.6z M6.7,38.4c0-1.4,1.1-2.5,2.5-2.5h24.2    c0,0.2-0.1,0.5,0,0.7l0.1,4.3H9.2C7.8,40.9,6.7,39.8,6.7,38.4z" />
    </switch>
  </svg>
);

export default PointLeftFinger;
