import store from '../redux/store';

export const determineDisabledFeature = ({ featureId, validateAmount, usedAmount }) => {
  let disabledFeature = true;
  const { features } = store.getState().auth.planFeatures;

  if (!features) return true;

  const featureToValidate = features.find((feature) => feature.id === featureId);

  if (!featureToValidate) {
    disabledFeature = false;
    return disabledFeature;
  }

  if (featureToValidate.included && !validateAmount) {
    disabledFeature = false;
    return disabledFeature;
  }

  if (validateAmount && featureToValidate.amount > usedAmount) {
    disabledFeature = false;
    return disabledFeature;
  }
  return disabledFeature;
};
export const determineDisabledMinimumTier = (tier) => {
  if (tier == undefined || tier == null) return false;
  const plan = store.getState().auth.plan;
  if (!plan) return true;
  if (tier <= plan?.tier) return false;
  return true;
};
