export const questionsAmount = (test) => {
  if (test?.difficultyConfig?.length > 0) {
    return test?.difficultyConfig?.reduce(
      (accumulator, currentValue) => accumulator + currentValue?.quantity,
      0
    );
  }
  return test?.testsQuantityToEvaluate
    ? test?.testsQuantityToEvaluate
    : test?.tests?.length
    ? test?.tests?.length
    : test?.testsQuantity;
};
