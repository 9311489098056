import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'lottie-react';
import { saveSearchRequest } from '../../API/services/companyService';
import SearchAnimation from '../../assets/lottie/first-filter-modal-search.json';
import { t } from '../../i18n/i18n';
import {
  setAppliedFilters,
  setSavedSearches,
  setShowFirstFilterModal,
} from '../../redux/actions/profilesActions';
import DropdownSelect from '../DropdownSelect/DropdownSelect';
import SearchableSelect from '../SearchableSelect/SearchableSelect';
import HorizontalLoader from '../HorizontalLoader/HorizontalLoader';
import TechButton from '../TechButton/TechButton';
import './FirstFilterForm.scss';

const FirstFilterForm = ({ setShowModalFirstFilter, setShowModal }) => {
  const [step, setStep] = useState(1);
  const [disablePostButton, setDisablePostButton] = useState(false);
  const { availableFilters, technologyTypes, technologies, profiles, loading } = useSelector(
    (state) => state.profiles
  );
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState({
    selectedStack: undefined,
    selectedTechs: [],
    selectedStatus: [],
    selectedStudyArea: [],
  });
  const disableButtons = filterData.selectedTechs.length === filterData.selectedStack?.max;
  const disableFinishButton =
    !filterData.selectedStudyArea.length || !filterData.selectedStatus.length;

  const formattedTechs = technologies
    ?.map((tech) => ({
      ...tech,
      technologyTypes: tech?.technologyToTypes?.map((item) => item.technologyTypeId),
    }))
    ?.filter((tech) => tech?.technologyTypes?.includes(filterData.selectedStack?.id));

  const handleAddTech = (tech) => {
    const selectedTech = filterData.selectedTechs.find((item) => item.id === tech.id);
    if (selectedTech) {
      setFilterData({
        ...filterData,
        selectedTechs: filterData.selectedTechs.filter((item) => item.id !== tech.id),
      });
      return;
    }
    setFilterData({ ...filterData, selectedTechs: [...filterData.selectedTechs, tech] });
  };

  const handleSelectStudyArea = (studyArea) => {
    const selectedTech = filterData.selectedStudyArea.find((item) => item.id === studyArea.id);
    if (selectedTech) {
      setFilterData({
        ...filterData,
        selectedStudyArea: filterData.selectedStudyArea.filter((item) => item.id !== studyArea.id),
      });
      return;
    }
    setFilterData({
      ...filterData,
      selectedStudyArea: [...filterData.selectedStudyArea, studyArea],
    });
  };

  const handlePostData = () => {
    const dataToPost = {
      name: t('MY_FIRST_FILTER'),
      academic_level: filterData.selectedStatus.map((item) => item.id),
      technologies: filterData.selectedTechs.map((item) => item.id.toString()),
      study_area: filterData.selectedStudyArea.map((item) => item.id),
      // and: ['technologies'],
    };
    saveSearchRequest(dataToPost).then((res) => {
      dispatch(setShowFirstFilterModal(false));
      setDisablePostButton(false);
      dispatch(
        setSavedSearches({
          filters: [res],
        })
      );
    });
  };

  const handleSetFilters = () => {
    setDisablePostButton(true);
    dispatch(
      setAppliedFilters([
        ...filterData.selectedTechs.map((item) => ({
          id: item.id.toString(),
          name: item.name,
          type: 'technologies',
        })),
        ...filterData.selectedStatus.map((item) => ({ ...item, type: 'academic_level' })),
        ...filterData.selectedStudyArea.map((item) => ({ ...item, type: 'study_area' })),
        // { type: 'and', id: 'technologies' },
      ])
    );
  };

  useEffect(() => {
    if (step === 3 && !loading) {
      if (!profiles.length) {
        setStep(4);
        setDisablePostButton(false);
      } else {
        handlePostData();
      }
    }
  }, [profiles, loading]);

  return (
    <div
      className={`p-4 text-align-center first-filter-form-container ${
        [3, 4].includes(step) && 'last-steps'
      }`}
    >
      {[1, 2].includes(step) && (
        <div className="d-flex flex-column text-center mt-4">
          <span className="font-montserrat font-weight-500 text-xl">
            {t('FIRST_FILTER_MODAL_TITLE')}
          </span>
          <span className="font-montserrat font-weight-400 px-3 mt-2 text-muted text-base">
            {t('FIRST_FILTER_MODAL_SUBTITLE')}
          </span>
        </div>
      )}
      {step === 1 && (
        <>
          <div className="mx-auto mt-3 text-sm font-weight-500">
            <span className="font-montserrat">{t('FIRST_FILTER_STACK_TITLE')}</span>
            <DropdownSelect
              roundBorder={false}
              menuClassnames="first-filter-form-types-dropdown"
              setState={(tech) => {
                setFilterData({ ...filterData, selectedStack: tech, selectedTechs: [] });
                if (tech.id === 5) {
                  setFilterData({
                    ...filterData,
                    selectedTechs: [technologies.find((tech) => tech.id === 17)],
                  });
                  setStep(2);
                }
              }}
              maxHeight={false}
              options={technologyTypes.map((type) => ({
                ...type,
                max: ['fullstack', 'backend'].includes(type.name) ? 2 : 1,
                label: type.displayName,
              }))}
              placeholderClassname="text-muted text-xs"
              selected={filterData.selectedStack && filterData.selectedStack.id}
              placeholder=""
              className={`w-100 py-2 tech-button-border ${
                filterData.selectedStack ? 'border-maya-blue' : 'border-unselected-types'
              }`}
              selectedPlaceholder
              arrow={false}
              bigArrowLightBlue
            />
          </div>
          {filterData.selectedStack && (
            <div className="d-flex flex-column mt-4 pt-2">
              <div className="text-sm font-weight-500">
                <span className="font-montserrat">{t('FIRST_FILTER_TECHS_TITLE')}</span>
                {filterData.selectedStack && filterData.selectedStack?.max && (
                  <span className="font-montserrat ml-2">
                    {t('FIRST_FILTER_TECHS_SUBTITLE').replace(
                      '{techs}',
                      filterData.selectedStack.max
                    )}
                  </span>
                )}
              </div>
              <div className="d-flex flex-column">
                {formattedTechs
                  .filter((item) => item?.imageUrl)
                  .map((item) => (
                    <TechButton
                      disabled={disableButtons}
                      onClick={() => handleAddTech(item)}
                      selected={filterData.selectedTechs.find((tech) => tech.id === item.id)}
                      tech={item}
                    />
                  ))}
              </div>
              <button
                type="button"
                onClick={() => setStep(2)}
                disabled={!disableButtons}
                className={`align-self-end my-4 align-self-end px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none ${
                  disableButtons ? 'bg-maya-blue' : 'disabled-button'
                }`}
              >
                {t('FIRST_FILTER_STEP_1_CONTINUE')}
              </button>
            </div>
          )}
        </>
      )}
      {step === 2 && (
        <div className="mt-4">
          <div className="mb-1">
            <span className="font-montserrat">{t('FIRST_FILTER_LAST_STEP_TITLE')}</span>
          </div>
          <SearchableSelect
            allOption
            selected={filterData.selectedStatus.length}
            onChange={(e) => {
              if (e.value === 'all') {
                setFilterData({ ...filterData, selectedStatus: availableFilters?.academic_level });
              } else {
                setFilterData({
                  ...filterData,
                  selectedStatus: availableFilters?.academic_level?.filter(
                    (item) => item.id === e.value
                  ),
                });
              }
            }}
            options={availableFilters?.academic_level?.map((level) => ({
              value: level.id,
              label: t(`PROFILES_FILTER_ACADEMIC_LEVEL_${level?.name.toUpperCase()}`),
            }))}
          />
          <div className="mt-5 mb-1">
            <span className="font-montserrat">{t('FIRST_FILTER_LAST_STEP_STUDY_AREA')}</span>
          </div>
          <div className="d-flex flex-column">
            {availableFilters?.study_area.map((item) => (
              <TechButton
                className="w-100"
                disabled={filterData.selectedStudyArea.length}
                onClick={() => handleSelectStudyArea(item)}
                hideImage
                selected={filterData.selectedStudyArea.find((tech) => tech.id === item.id)}
                tech={{ ...item, displayName: item.name }}
              />
            ))}
          </div>
          <div className="w-100 d-flex justify-content-end">
            <button
              type="button"
              onClick={() => setStep(3)}
              disabled={disableFinishButton}
              className={`align-self-end my-4 px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none ${
                !disableFinishButton ? 'bg-maya-blue' : 'disabled-button'
              }`}
            >
              {t('FIRST_FILTER_LAST_STEP_FINISH')}
            </button>
          </div>
        </div>
      )}
      {step === 3 && (
        <>
          <div className="d-flex flex-column text-center mt-3">
            <span className="font-montserrat font-weight-500 text-xl">
              {t('FIRST_FILTER_MODAL_CONGRATS_TITLE')}
            </span>
            <span className="font-montserrat font-weight-400 px-3 mt-4 text-muted text-base">
              {t('FIRST_FILTER_MODAL_CONGRATS_SUBTITLE')}
            </span>
          </div>
          <div className="d-flex align-items-center flex-column">
            <Lottie animationData={SearchAnimation} loop />
            {disablePostButton && <HorizontalLoader />}
            <button
              disabled={disablePostButton}
              type="button"
              onClick={handleSetFilters}
              className={`my-1 px-5 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none ${
                disablePostButton ? 'disabled-button' : 'bg-maya-blue'
              }`}
            >
              {t('FIRST_FILTER_LAST_STEP_GO_TO_COMMUNITY')}
            </button>
          </div>
        </>
      )}
      {step === 4 && (
        <>
          <div className="d-flex flex-column text-center mt-3">
            <span className="font-montserrat font-weight-500 text-xl px-4">
              {t('FIRST_FILTER_MODAL_ERROR_TITLE')}
            </span>
            <span className="font-montserrat font-weight-400 px-3 mt-4 text-muted text-base">
              {t('FIRST_FILTER_MODAL_ERROR_SUBTITLE')}
            </span>
          </div>
          <div className="d-flex align-items-center justify-content-center mt-5 mb-3">
            <button
              type="button"
              onClick={() => {
                setFilterData({
                  selectedStack: undefined,
                  selectedTechs: [],
                  selectedStatus: [],
                  selectedStudyArea: [],
                });
                setStep(1);
                dispatch(setAppliedFilters([]));
              }}
              className="font-roboto text-sm font-weight-bold border-0 outline-none text-purple bg-white mr-4"
            >
              {t('FIRST_FILTER_LAST_STEP_CHANGE_FILTERS')}
            </button>
            <button
              type="button"
              className="my-1 px-5 mx-1 py-2 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none bg-maya-blue"
              onClick={() => {
                setShowModalFirstFilter();
                setShowModal(false);
                dispatch(setAppliedFilters([]));
              }}
            >
              {t('FIRST_MODAL_GO_TO_COMMUNITY')}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default FirstFilterForm;
