import React from 'react';

const RoleTestGroupCross = ({ classNames }) => (
  <svg
    className={classNames}
    width="11"
    height="10"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect width="11" height="10" fill="url(#pattern0)" />
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_11_4597" transform="scale(0.0078125)" />
      </pattern>
      <image
        id="image0_11_4597"
        width="128"
        height="128"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAEHUlEQVR4nO2cOW4UQRSGPxw54gCYC4EADRI4sbgMEhkWJ2EJMDKcA7FGzkAYB3YACNAQNCNsGA/T3dVVb/k/aRJrqvUvr8vTPQv84QKwDTwHjoCvwDtgF9hCeOMy8AB4D3wDPgP7wC26rs9wEXgGzM95nACzGqpFEW7SdXZen3t0nQOwweryF48fwE4tB2IwO3Rd/a/PPX7vBNtrPFlD4IN1y188bgO86LFgMQR3KhkS67MNfKdfl/vQveDrs0g7gT36nvmLxyF0r/b7LtQQ2GFo+XPgC8DbgYs1BO0ZU/4ceA3ddf7QA2gI2jG2/Dld92yx+ppRQ2CPEuUfA5cWB5wVOKCGoA4lyv/Bkht7Qy4jlh1Yl4jTMXlHGgK7VOtGQ2CP6p1oCOzQrAsNQXuad9BcQGLMZG9GSCLMZW5OUGDMZm1WWCDMZ2xeoGPcZOtGqCPcZepOsGHcZulWuCHcZ+jeQEPCZBfGSEXCZRbO0ISEzSqssYKEzyi8wRGkySaN0R6kyySd4RWkzSKt8VOkzyBzAJm9nyFjEBk9ryRTIJm89iJDMBk8jiJyQJG9FSViUBE9TUqkwCJ5qUqE4CJ4aIrnAD1rN4XHID1qNo2nQD1pdYWHYD1odI3lgC1rC4XFoC1qCo2lwC1pSYWF4C1oSE3LAlS+EVoUofKNUbMQlW+UGsWofONMWZDKd8IURal8Z5QsTOU7pURxPynzw8sqvxElhkDlO6fVEKh8Q9QeApVvkFpDoPINM/UQqHwHTDUEKt8RpYdA5Ttkm/HX+HO6ewVhy99oLUCIKdC/gMToRWBidBmYGN0ISoxuBSdGbwYlRm8HJ0YfCEmMPhKWGH0oNDH6WHhi9MWQxOirYYnRl0MTo6+HJ8ZCARY0pMRS8Ja0pMBi4BY1hcRy0Ja1hcBDwB40usRTsJ60usBjoB41m8RzkJ61myBCgBE8NCFScJG8VCFiYBE9TULkoCJ7K0KGgDJ4HESmYDJ5XYuMgWT0vJTMQWT2DigASJxBWuNLSJdFOsNrkCaTNEYHED6b8AYLEDajsMYmIFxW4QxVIExmYYw0wH127g0YwG2GboUbxF2W7gQ7wE2mboQ6xHy25gUGwGzGZoUFxFzW5gQlwEzmZoQkpHn2zQWIdh2ofDtU70Ll26NaJyrfLpN3o/LtM1lH1yjzo8k7pR2Lf9ihTFfXFge8DBwWOKDKr0eJITgEtgDujTyQtv02lPh3cBfg1YgD6Mxvy9id4CXA0cDFOvNtMGYn+ATwccBCnfm2GLoTfAB41HORznybDNkJHgLc6LFAZ75t+u4E1xcLn6zxZJXvg3WH4PHpRZu//3Dek4+BWRX5ogQz4ITVW//m34suAFeBp8AB8AV4DdwHLtVQLYqyBewCb+i6PKDr9gpd1wD8AvsRKC7GBZ8HAAAAAElFTkSuQmCC"
      />
    </defs>
  </svg>
);

export default RoleTestGroupCross;
