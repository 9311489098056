export const sort = (data, orderer, order = 'ASC') => {
  const ordered = data.sort((a, b) => (a[orderer] > b[orderer] ? 1 : -1));
  return order === 'DESC' ? ordered.reverse() : ordered;
};

export const multipleSort = (data, orderer1, orderer2, order = 'ASC') => {
  const ordered = data.sort((a, b) => a[orderer1] - b[orderer1] || a[orderer2] - b[orderer2]);
  return order === 'DESC' ? ordered.reverse() : ordered;
};

export const sortAlphabetic = (data, orderer, order = 'ASC') => {
  const ordered = data.sort((a, b) =>
    a[orderer].toLowerCase() > b[orderer].toLowerCase() ? 1 : -1
  );
  return order === 'DESC' ? ordered.reverse() : ordered;
};
