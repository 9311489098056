const Icon12 = ({ color = '#BCBCBC' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 4.9125C9 4.48125 8.72813 4.10156 8.38125 3.84375C7.8375 3.43594 7.5 2.87344 7.5 2.25C7.5 1.00781 8.84531 0 10.5 0C12.1547 0 13.5 1.00781 13.5 2.25C13.5 2.87344 13.1625 3.43594 12.6188 3.84375C12.2719 4.10156 12 4.48125 12 4.9125C12 5.5125 12.4875 6 13.0875 6H15.75C16.9922 6 18 7.00781 18 8.25V10.9125C18 11.5125 18.4875 12 19.0875 12C19.5188 12 19.8984 11.7281 20.1562 11.3813C20.5641 10.8375 21.1266 10.5 21.75 10.5C22.9922 10.5 24 11.8453 24 13.5C24 15.1547 22.9922 16.5 21.75 16.5C21.1266 16.5 20.5641 16.1625 20.1562 15.6188C19.8984 15.2719 19.5188 15 19.0875 15C18.4875 15 18 15.4875 18 16.0875V21.75C18 22.9922 16.9922 24 15.75 24H13.0875C12.4875 24 12 23.5125 12 22.9125C12 22.4812 12.2719 22.1016 12.6188 21.8438C13.1625 21.4359 13.5 20.8734 13.5 20.25C13.5 19.0078 12.1547 18 10.5 18C8.84531 18 7.5 19.0078 7.5 20.25C7.5 20.8734 7.8375 21.4359 8.38125 21.8438C8.72813 22.1016 9 22.4812 9 22.9125C9 23.5125 8.5125 24 7.9125 24H2.25C1.00781 24 0 22.9922 0 21.75V16.0875C0 15.4875 0.4875 15 1.0875 15C1.51875 15 1.89844 15.2719 2.15625 15.6188C2.56406 16.1625 3.12656 16.5 3.75 16.5C4.99219 16.5 6 15.1547 6 13.5C6 11.8453 4.99219 10.5 3.75 10.5C3.12656 10.5 2.56406 10.8375 2.15625 11.3813C1.89844 11.7281 1.51875 12 1.0875 12C0.4875 12 0 11.5125 0 10.9125V8.25C0 7.00781 1.00781 6 2.25 6H7.9125C8.5125 6 9 5.5125 9 4.9125Z"
      fill={color}
    />
  </svg>
);

export default Icon12;
