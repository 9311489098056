import { alkycheatClient } from '../alkycheatClient';

export const getProctoringSummary = async (screeningInstanceId) => {
  const { data } = await alkycheatClient.get(`/event/summary/${screeningInstanceId}`);
  return data;
};

export const getSubmissionWithEvents = async (testGroupId, screeningInstanceId) => {
  const { data } = await alkycheatClient.get(
    `/event/submissionWithEvents/${testGroupId}/${screeningInstanceId}`
  );
  return data;
};

export const getSubmissionEventsDetails = async (
  submissionId,
  userId,
  screeningInstanceId,
  offset = '0',
  limit = '15'
) => {
  const { data } = await alkycheatClient.get(`/event/submission/${submissionId}`, {
    params: {
      userId,
      screeningInstanceId,
      offset,
      limit,
    },
  });
  return data;
};

export const getUserCameraSnapshots = async (
  recruitSessionId,
  recruitSessionStageId,
  userId,
  screeningInstanceId
) => {
  const { data } = await alkycheatClient.get(
    `AWS/getUserInstanceSnapshots/${recruitSessionId}/${recruitSessionStageId}/${userId}/${screeningInstanceId}`
  );

  return data;
};
