import React from 'react';

const WeightsEmptyState = ({ classNames, testid }) => (
  <svg
    className={classNames}
    data-testid={testid}
    width="100"
    height="89"
    viewBox="0 0 100 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M61.9433 60.1727L14.4199 52.6934C12.8294 52.4433 11.5167 51.1455 11.0754 49.3875L0.160519 5.88792C-0.209326 4.41359 0.0667813 2.88695 0.918067 1.6991C1.76935 0.511238 3.02709 -0.102649 4.37014 0.0140646L52.2499 4.19102C53.9821 4.34209 55.4362 5.73104 55.8685 7.64697L66.4268 54.4489C66.7637 55.9424 66.4268 57.5211 65.5254 58.6718C64.7492 59.6629 63.6486 60.2164 62.5012 60.2164C62.3162 60.2164 62.1297 60.202 61.9433 60.1727Z"
      fill="#E6E6E6"
    />
    <path
      d="M43.4127 24.4312C44.2164 23.5556 45.3197 23.2605 45.8769 23.7721C46.434 24.2836 46.2341 25.408 45.43 26.2838C45.1136 26.6381 44.7169 26.9114 44.2732 27.0809L40.8211 30.748L39.1348 29.0913L42.717 25.6521C42.8481 25.1954 43.0866 24.7768 43.4127 24.4312Z"
      fill="#FFB6B6"
    />
    <path
      d="M23.7309 25.2471C23.7309 25.2471 25.7481 23.402 28.1621 25.013C30.5761 26.6239 36.1975 30.7445 36.1975 30.7445L40.1365 25.8592L43.2404 28.6726C43.2404 28.6726 37.7653 38.3164 34.228 36.194C30.6907 34.0715 22.7939 28.0125 23.1524 26.7589C23.511 25.5053 23.7309 25.2471 23.7309 25.2471Z"
      fill="#4bafe1"
    />
    <path
      d="M38.6678 70.2556L35.4538 76.2446L32.8066 73.0411L35.8517 68.9263L38.6678 70.2556Z"
      fill="#FFB6B6"
    />
    <path
      d="M36.5896 75.4169L35.2297 82.7647C35.2297 82.7647 34.3169 85.3131 32.8497 82.7246C31.3826 80.1362 28.7259 74.7808 30.2532 73.7993C31.7806 72.8179 33.6252 72.0249 33.6252 72.0249L36.5896 75.4169Z"
      fill="#2F2E41"
    />
    <path
      d="M40.5643 76.7778L43.9552 82.8815L39.7166 83.3901L37.8516 78.4733L40.5643 76.7778Z"
      fill="#FFB6B6"
    />
    <path
      d="M22.5912 21.5059C22.5912 21.5059 22.2521 24.8968 21.7434 25.2359C21.2348 25.575 27.6802 25.2359 27.6802 25.2359L25.8125 21.5059H22.5912Z"
      fill="#FFB6B6"
    />
    <path
      d="M29.5422 26.2559L27.1698 24.3882H21.3692L17.5057 27.6096L19.9641 39.0539L18.7773 44.6489L33.8669 45.1575L31.1541 40.0712C31.1541 40.0712 34.3755 36.6802 33.3582 34.4761C32.341 32.2721 29.5422 26.2559 29.5422 26.2559Z"
      fill="#4bafe1"
    />
    <path
      d="M32.3412 43.8008L34.5453 44.9876C34.5453 44.9876 46.4135 56.1776 45.3962 59.5685C44.379 62.9594 37.5971 72.6235 37.5971 72.6235L34.3758 70.9281L39.123 60.5858L30.0523 51.5151L34.9215 60.0984L41.8358 78.2185L37.7667 79.914L27.2548 58.7208C27.2548 58.7208 18.608 52.7867 18.0994 49.0567C17.5907 45.3267 18.7776 44.6485 18.7776 44.6485L32.3412 43.8008Z"
      fill="#2F2E41"
    />
    <path
      d="M43.87 81.4399L49.465 86.6959C49.465 86.6959 51.1605 88.9 48.1087 88.7304C45.0569 88.5609 38.9532 87.8827 38.9532 86.0177C38.9532 84.1527 39.2923 82.1181 39.2923 82.1181L43.87 81.4399Z"
      fill="#2F2E41"
    />
    <path
      d="M24.2882 22.3533C26.4419 22.3533 28.1878 20.6074 28.1878 18.4537C28.1878 16.3001 26.4419 14.5542 24.2882 14.5542C22.1346 14.5542 20.3887 16.3001 20.3887 18.4537C20.3887 20.6074 22.1346 22.3533 24.2882 22.3533Z"
      fill="#FFB6B6"
    />
    <path
      d="M41.2042 34.0139L37.9904 43.8012C37.9048 44.0621 37.6822 44.2545 37.4117 44.3015L31.2657 45.3704C30.7146 45.4662 30.2591 44.9418 30.4313 44.4095L33.6408 34.4892C33.7275 34.2215 33.9579 34.0256 34.2361 33.9833L40.3863 33.0474C40.934 32.964 41.377 33.4876 41.2042 34.0139Z"
      fill="white"
    />
    <path
      d="M31.135 45.5512C30.8734 45.5512 30.625 45.4394 30.4489 45.2366C30.2374 44.993 30.1704 44.6642 30.2697 44.3574L33.4792 34.437C33.5863 34.106 33.8665 33.8679 34.2104 33.8158L40.3605 32.8798C40.676 32.8316 40.9881 32.9487 41.1946 33.1926C41.4009 33.4365 41.4646 33.7633 41.365 34.0668L38.1512 43.8541C38.0458 44.1751 37.7735 44.4106 37.4406 44.4687L31.2945 45.5374C31.2411 45.5467 31.1878 45.5512 31.135 45.5512ZM40.4994 33.2083C40.4703 33.2083 40.4409 33.2104 40.4115 33.2149L34.2613 34.1509C34.0451 34.1837 33.8691 34.3334 33.8019 34.5413L30.6007 44.4359C30.542 44.6175 30.5612 44.8215 30.674 44.9754C30.8059 45.1554 31.0204 45.2402 31.2364 45.2033L33.7026 44.7744C36.1762 44.3443 38.211 42.5852 38.9942 40.1999L41.0367 33.9796C41.0882 33.8229 41.0818 33.6483 41.0005 33.5048C40.8936 33.316 40.703 33.2083 40.4994 33.2083Z"
      fill="#3F3D56"
    />
    <path
      d="M31.823 41.6383C33.0107 41.6823 33.9508 42.3308 33.9227 43.0867C33.8946 43.8426 32.909 44.4195 31.7209 44.3753C31.246 44.3642 30.7807 44.2395 30.3639 44.0117L25.3338 43.7618L25.5014 41.4038L30.4424 41.9005C30.8751 41.7041 31.3485 41.6142 31.823 41.6383Z"
      fill="#FFB6B6"
    />
    <path
      d="M18.439 27.1855C18.439 27.1855 21.1517 27.5246 21.4908 30.4069C21.8299 33.2892 22.3386 40.2406 22.3386 40.2406L28.6118 40.071L28.4829 44.2583C28.4829 44.2583 17.5913 46.3442 16.9131 42.2751C16.2349 38.206 15.7263 28.2656 16.9131 27.7256C18.0999 27.1855 18.439 27.1855 18.439 27.1855Z"
      fill="#4bafe1"
    />
    <path
      d="M40.0456 35.5267L34.2641 36.0138C34.1184 36.0261 33.9898 35.9175 33.9775 35.7718C33.9652 35.626 34.0738 35.4975 34.2196 35.4852L40.0011 34.998C40.1468 34.9857 40.2753 35.0943 40.2876 35.24C40.2999 35.3858 40.1914 35.5144 40.0456 35.5267Z"
      fill="#3F3D56"
    />
    <path
      d="M39.5358 37.2225L33.7544 37.7096C33.6086 37.7219 33.48 37.6133 33.4677 37.4676C33.4555 37.3218 33.5641 37.1933 33.7098 37.181L39.4913 36.6938C39.637 36.6815 39.7656 36.7901 39.7779 36.9358C39.7901 37.0816 39.6816 37.2102 39.5358 37.2225Z"
      fill="#3F3D56"
    />
    <path
      d="M39.0278 38.9178L33.2464 39.405C33.1006 39.4172 32.972 39.3086 32.9597 39.1629C32.9475 39.0171 33.0561 38.8886 33.2018 38.8763L38.9833 38.3891C39.129 38.3768 39.2576 38.4854 39.2699 38.6312C39.2821 38.7769 39.1736 38.9055 39.0278 38.9178Z"
      fill="#3F3D56"
    />
    <path
      d="M23.2445 19.8561L25.9726 16.7238L25.5178 15.4026L26.9823 16.7942L27.7382 16.5889C27.7382 16.5889 27.7317 13.9064 25.5898 13.7853C23.4479 13.6641 21.9114 13.8897 21.9114 13.8897C21.9114 13.8897 18.8488 14.8495 18.7291 15.585C18.6093 16.3205 16.8936 17.2978 16.8936 17.2978C16.8936 17.2978 15.405 18.4052 16.1009 20.2613C16.7968 22.1174 18.3847 24.4341 17.2855 25.5635C16.1863 26.6929 14.633 27.5622 15.3898 27.9957C16.1467 28.4292 17.5118 26.3968 18.7551 27.2094C19.9984 28.022 22.0993 31.2859 24.07 29.6003C26.0406 27.9146 26.405 25.9622 26.405 25.9622L23.26 22.8542C23.26 22.8542 21.3546 19.6041 23.2445 19.8561Z"
      fill="#2F2E41"
    />
    <path
      d="M92.1453 11.0923H51.3549C50.9735 11.0928 50.6079 11.2446 50.3382 11.5143C50.0685 11.7839 49.9167 12.1496 49.9162 12.531V28.2717C49.9167 28.653 50.0685 29.0187 50.3382 29.2883C50.6079 29.558 50.9735 29.7098 51.3549 29.7103H92.1453C92.5267 29.7098 92.8923 29.558 93.162 29.2883C93.4317 29.0187 93.5834 28.653 93.584 28.2717V12.531C93.5834 12.1496 93.4317 11.7839 93.162 11.5143C92.8923 11.2446 92.5267 11.0928 92.1453 11.0923ZM93.4147 28.2717C93.4143 28.6082 93.2804 28.9308 93.0424 29.1688C92.8045 29.4068 92.4818 29.5407 92.1453 29.5411H51.3549C51.0183 29.5407 50.6957 29.4068 50.4577 29.1688C50.2197 28.9308 50.0859 28.6082 50.0855 28.2717V12.531C50.0859 12.1944 50.2197 11.8718 50.4577 11.6338C50.6957 11.3958 51.0183 11.2619 51.3549 11.2615H92.1453C92.4818 11.2619 92.8045 11.3958 93.0424 11.6338C93.2804 11.8718 93.4143 12.1944 93.4147 12.531V28.2717Z"
      fill="#3F3D56"
    />
    <path
      d="M58.7601 25.3099C57.7894 25.3099 56.8404 25.0221 56.0332 24.4827C55.226 23.9434 54.5969 23.1768 54.2254 22.2799C53.8539 21.383 53.7567 20.3961 53.9461 19.444C54.1355 18.4918 54.6029 17.6173 55.2894 16.9308C55.9758 16.2443 56.8504 15.7769 57.8026 15.5875C58.7547 15.3981 59.7416 15.4953 60.6385 15.8668C61.5354 16.2383 62.302 16.8674 62.8413 17.6746C63.3807 18.4818 63.6685 19.4308 63.6685 20.4016C63.6671 21.7029 63.1495 22.9505 62.2293 23.8707C61.3091 24.7909 60.0615 25.3085 58.7601 25.3099ZM58.7601 15.6624C57.8228 15.6624 56.9066 15.9404 56.1272 16.4611C55.3479 16.9819 54.7404 17.722 54.3817 18.588C54.0231 19.4539 53.9292 20.4068 54.1121 21.3261C54.2949 22.2454 54.7463 23.0899 55.4091 23.7526C56.0718 24.4154 56.9163 24.8668 57.8356 25.0496C58.7549 25.2325 59.7078 25.1386 60.5737 24.7799C61.4397 24.4213 62.1798 23.8138 62.7006 23.0345C63.2213 22.2551 63.4993 21.3389 63.4993 20.4016C63.4979 19.1451 62.9981 17.9405 62.1096 17.0521C61.2212 16.1636 60.0166 15.6638 58.7601 15.6624Z"
      fill="#3F3D56"
    />
    <path
      d="M88.5055 17.27H68.9565C68.6833 17.2702 68.4207 17.3761 68.2238 17.5656C68.0269 17.755 67.911 18.0134 67.9004 18.2864C67.899 18.3002 67.8984 18.314 67.8987 18.3279C67.8991 18.6083 68.0107 18.8771 68.209 19.0754C68.4073 19.2737 68.6761 19.3853 68.9565 19.3857H88.5055C88.786 19.3857 89.0551 19.2743 89.2535 19.0759C89.4519 18.8775 89.5633 18.6084 89.5633 18.3279C89.5633 18.0473 89.4519 17.7782 89.2535 17.5799C89.0551 17.3815 88.786 17.27 88.5055 17.27Z"
      fill="#4bafe1"
    />
    <path
      d="M88.5055 21.417H68.9565C68.6833 21.4172 68.4207 21.5231 68.2238 21.7125C68.0269 21.902 67.911 22.1603 67.9004 22.4334C67.899 22.4471 67.8984 22.461 67.8987 22.4748C67.8991 22.7553 68.0107 23.0241 68.209 23.2224C68.4073 23.4207 68.6761 23.5323 68.9565 23.5327H88.5055C88.786 23.5327 89.0551 23.4212 89.2535 23.2228C89.4519 23.0245 89.5633 22.7554 89.5633 22.4748C89.5633 22.1943 89.4519 21.9252 89.2535 21.7268C89.0551 21.5284 88.786 21.417 88.5055 21.417Z"
      fill="#4bafe1"
    />
    <path
      d="M58.2129 22.5461C58.0759 22.5463 57.9426 22.502 57.833 22.4199L57.8262 22.4148L56.3957 21.3195C56.3294 21.2687 56.2738 21.2054 56.232 21.1332C56.1903 21.0609 56.1631 20.9812 56.1522 20.8984C56.1412 20.8157 56.1467 20.7316 56.1682 20.651C56.1898 20.5703 56.227 20.4947 56.2777 20.4285C56.3285 20.3622 56.3918 20.3066 56.4641 20.2648C56.5363 20.223 56.6161 20.1959 56.6988 20.185C56.7816 20.174 56.8657 20.1795 56.9463 20.201C57.0269 20.2225 57.1025 20.2598 57.1688 20.3105L58.0954 21.021L60.2851 18.1653C60.3358 18.0991 60.3992 18.0435 60.4714 18.0018C60.5437 17.96 60.6235 17.9329 60.7062 17.922C60.789 17.911 60.8731 17.9165 60.9537 17.9381C61.0343 17.9597 61.1099 17.9969 61.1761 18.0477L61.1625 18.0662L61.1765 18.048C61.3101 18.1507 61.3975 18.3021 61.4196 18.4692C61.4416 18.6363 61.3965 18.8052 61.2941 18.9391L58.7185 22.298C58.6589 22.3754 58.5823 22.4381 58.4947 22.4811C58.407 22.5241 58.3105 22.5463 58.2129 22.5461Z"
      fill="#4bafe1"
    />
    <path
      d="M98.5613 32.0903H57.7709C57.3895 32.0909 57.0239 32.2426 56.7542 32.5123C56.4845 32.782 56.3328 33.1476 56.3322 33.529V49.2697C56.3328 49.6511 56.4845 50.0167 56.7542 50.2864C57.0239 50.5561 57.3895 50.7078 57.7709 50.7084H98.5613C98.9427 50.7078 99.3083 50.5561 99.578 50.2864C99.8477 50.0167 99.9995 49.6511 100 49.2697V33.529C99.9995 33.1476 99.8477 32.782 99.578 32.5123C99.3083 32.2426 98.9427 32.0909 98.5613 32.0903ZM99.8307 49.2697C99.8303 49.6062 99.6965 49.9289 99.4585 50.1669C99.2205 50.4048 98.8979 50.5387 98.5613 50.5391H57.7709C57.4344 50.5387 57.1117 50.4048 56.8738 50.1669C56.6358 49.9289 56.5019 49.6062 56.5015 49.2697V33.529C56.5019 33.1925 56.6358 32.8698 56.8738 32.6318C57.1117 32.3939 57.4344 32.26 57.7709 32.2596H98.5613C98.8979 32.26 99.2205 32.3939 99.4585 32.6318C99.6965 32.8698 99.8303 33.1925 99.8307 33.529V49.2697Z"
      fill="#3F3D56"
    />
    <path
      d="M65.1762 46.308C64.2054 46.308 63.2564 46.0201 62.4492 45.4808C61.642 44.9414 61.0129 44.1749 60.6414 43.278C60.2699 42.3811 60.1727 41.3942 60.3621 40.442C60.5515 39.4899 61.019 38.6153 61.7054 37.9288C62.3919 37.2424 63.2665 36.7749 64.2186 36.5855C65.1707 36.3961 66.1577 36.4933 67.0545 36.8648C67.9514 37.2363 68.718 37.8655 69.2574 38.6726C69.7967 39.4798 70.0846 40.4288 70.0846 41.3996C70.0831 42.7009 69.5655 43.9486 68.6453 44.8687C67.7251 45.7889 66.4775 46.3065 65.1762 46.308ZM65.1762 36.6605C64.2389 36.6605 63.3226 36.9384 62.5433 37.4592C61.7639 37.9799 61.1565 38.7201 60.7978 39.586C60.4391 40.452 60.3452 41.4049 60.5281 42.3242C60.711 43.2435 61.1623 44.0879 61.8251 44.7507C62.4879 45.4135 63.3323 45.8648 64.2516 46.0477C65.1709 46.2305 66.1238 46.1367 66.9898 45.778C67.8557 45.4193 68.5959 44.8119 69.1166 44.0325C69.6374 43.2532 69.9153 42.3369 69.9153 41.3996C69.9139 40.1431 69.4141 38.9386 68.5257 38.0501C67.6372 37.1616 66.4326 36.6619 65.1762 36.6605Z"
      fill="#3F3D56"
    />
    <path
      d="M94.9215 38.2681H75.3726C75.0993 38.2682 74.8368 38.3741 74.6399 38.5636C74.443 38.7531 74.3271 39.0114 74.3164 39.2844C74.315 39.2982 74.3145 39.3121 74.3147 39.3259C74.3151 39.6063 74.4267 39.8752 74.625 40.0735C74.8233 40.2718 75.0921 40.3834 75.3726 40.3838H94.9215C95.2021 40.3838 95.4711 40.2723 95.6695 40.0739C95.8679 39.8755 95.9794 39.6065 95.9794 39.3259C95.9794 39.0454 95.8679 38.7763 95.6695 38.5779C95.4711 38.3795 95.2021 38.2681 94.9215 38.2681Z"
      fill="#4bafe1"
    />
    <path
      d="M94.9215 42.415H75.3726C75.0993 42.4152 74.8368 42.5211 74.6399 42.7106C74.443 42.9001 74.3271 43.1584 74.3164 43.4314C74.315 43.4452 74.3145 43.459 74.3147 43.4729C74.3151 43.7533 74.4267 44.0222 74.625 44.2205C74.8233 44.4188 75.0921 44.5303 75.3726 44.5307H94.9215C95.2021 44.5307 95.4711 44.4193 95.6695 44.2209C95.8679 44.0225 95.9794 43.7534 95.9794 43.4729C95.9794 43.1923 95.8679 42.9233 95.6695 42.7249C95.4711 42.5265 95.2021 42.415 94.9215 42.415Z"
      fill="#4bafe1"
    />
    <path
      d="M64.6289 43.5441C64.492 43.5444 64.3586 43.5001 64.2491 43.4179L64.2423 43.4128L62.8117 42.3176C62.7455 42.2668 62.6899 42.2035 62.6481 42.1312C62.6063 42.059 62.5792 41.9792 62.5682 41.8965C62.5573 41.8137 62.5627 41.7296 62.5843 41.649C62.6058 41.5684 62.643 41.4928 62.6938 41.4265C62.7445 41.3603 62.8079 41.3047 62.8801 41.2629C62.9524 41.2211 63.0321 41.194 63.1149 41.183C63.1976 41.172 63.2817 41.1775 63.3623 41.199C63.443 41.2206 63.5186 41.2578 63.5848 41.3086L64.5114 42.0191L66.7011 39.1634C66.7519 39.0972 66.8152 39.0416 66.8875 38.9998C66.9598 38.9581 67.0395 38.9309 67.1223 38.92C67.205 38.9091 67.2891 38.9146 67.3697 38.9362C67.4504 38.9577 67.526 38.995 67.5922 39.0458L67.5786 39.0642L67.5925 39.046C67.7261 39.1487 67.8136 39.3002 67.8356 39.4672C67.8577 39.6343 67.8125 39.8033 67.7102 39.9371L65.1346 43.2961C65.075 43.3735 64.9984 43.4361 64.9107 43.4791C64.823 43.5221 64.7266 43.5444 64.6289 43.5441Z"
      fill="#4bafe1"
    />
  </svg>
);

export default WeightsEmptyState;
