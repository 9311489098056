import React, { useState } from 'react';
import { t } from '../../i18n/i18n';
import ModalCustom from '../ModalCustom/ModalCustom';

export const ModalProctoringEnabled = ({
  showModal,
  setShowModal,
  data: { proctoringEnabled },
  submitAction,
  disabledButtonProps = false,
}) => {
  const [disabledButton, setDisabledButton] = useState(false);
  const onClickButton = () => {
    setDisabledButton(true);
    submitAction();
  };
  return (
    <>
      <ModalCustom
        onCancel={() => setShowModal(false)}
        onCancelText={t('CANCEL_OPERATION_LABEL')}
        onOk={disabledButtonProps && disabledButton ? () => {} : onClickButton}
        classModal="allow-certificate-download-modal-width"
        subtitleClassName="px-0 px-lg-0"
        onOkText={proctoringEnabled ? t('DEACTIVATE_LABEL') : t('ACTIVATE_LABEL')}
        setShow={setShowModal}
        show={showModal}
        title={proctoringEnabled ? t('WILL_DISABLE_PROCTORING') : t('WILL_ENABLE_PROCTORING')}
        subtitle={
          proctoringEnabled
            ? t('TO_DISABLE_PROCTORING_DESCRIPTION_MODAL')
            : t('TO_ENABLE_PROCTORING_DESCRIPTION_MODAL')
        }
      />
    </>
  );
};
