const Shortcut = () => (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 120">
    <path
      fill="#52C0F7"
      stroke="#52C0F7"
      stroke-width="2"
      d="M70.86,37.63H26.2c-0.55,0-1-0.45-1-1s0.45-1,1-1h44.65c0.55,0,1,0.45,1,1S71.41,37.63,70.86,37.63z"
    />
    <path
      fill="#52C0F7"
      stroke="#52C0F7"
      stroke-width="2"
      d="M59.2,51.1c-0.26,0-0.51-0.1-0.71-0.29c-0.39-0.39-0.39-1.02,0-1.41l10.49-10.51c1.24-1.25,1.24-3.27,0-4.52         L58.49,23.86c-0.39-0.39-0.39-1.02,0-1.41c0.39-0.39,1.02-0.39,1.41,0L70.4,32.95c2.02,2.02,2.02,5.32,0,7.35L59.9,50.8         C59.71,51,59.45,51.1,59.2,51.1z"
    />
    <path
      fill="#52C0F7"
      stroke="#52C0F7"
      stroke-width="2"
      d="M72.01,51.06c-0.55,0-1-0.45-1-1V23.31c0-0.55,0.45-1,1-1s1,0.45,1,1v26.75C73.01,50.61,72.56,51.06,72.01,51.06z"
    />
    <path
      fill="#52C0F7"
      stroke="#52C0F7"
      stroke-width="2"
      d="M73.8,64.38H29.14c-0.55,0-1-0.45-1-1s0.45-1,1-1H73.8c0.55,0,1,0.45,1,1S74.35,64.38,73.8,64.38z"
    />
    <path
      fill="#52C0F7"
      stroke="#52C0F7"
      stroke-width="2"
      d="M40.8,77.85c-0.26,0-0.51-0.1-0.71-0.29L29.42,66.87c-1.92-1.92-1.92-5.06,0-6.98L40.1,49.2         c0.39-0.39,1.02-0.39,1.41,0c0.39,0.39,0.39,1.02,0,1.41L30.83,61.3c-1.14,1.15-1.14,3.01,0,4.16l10.68,10.69         c0.39,0.39,0.39,1.02,0,1.41C41.31,77.75,41.06,77.85,40.8,77.85z"
    />
    <path
      fill="#52C0F7"
      stroke="#52C0F7"
      stroke-width="2"
      d="M27.99,77.69c-0.55,0-1-0.45-1-1V49.94c0-0.55,0.45-1,1-1s1,0.45,1,1v26.75C28.99,77.24,28.55,77.69,27.99,77.69z"
    />
    <path
      fill="#52C0F7"
      stroke="#52C0F7"
      stroke-width="4"
      d="M87.06,94.06H12.94c-3.86,0-7-3.14-7-7V12.94c0-3.86,3.14-7,7-7h74.12c3.86,0,7,3.14,7,7v74.12      C94.06,90.92,90.92,94.06,87.06,94.06z M12.94,9.94c-1.65,0-3,1.35-3,3v74.12c0,1.65,1.35,3,3,3h74.12c1.65,0,3-1.35,3-3V12.94      c0-1.65-1.35-3-3-3H12.94z"
    />
  </svg>
);

export default Shortcut;
