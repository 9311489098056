import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { updatePreApprovalReference } from '../../../API/services/alkymersService';
import MyInternalServerError from '../../../components/MyError/MyInternalServerError';
import PaymentFailure from '../../../components/Payments/PaymentFailure';
import PaymentPending from '../../../components/Payments/PaymentPending';
import PaymentSuccess from '../../../components/Payments/PaymentSuccess';
import Spinner from '../../../components/spinner/Spinner';
import { HOME } from '../../../constants/routes';
const PaymentValidation = () => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const preapproval_id = queryParams.get('preapproval_id') ?? null;

  if (!preapproval_id) history.push(HOME);

  const { isLoading, isError, error, data, isSuccess, reset, mutateAsync } = useMutation({
    mutationFn: (preapproval_id) => updatePreApprovalReference(preapproval_id),
  });

  useEffect(() => {
    if (preapproval_id) updatePaymentReference(preapproval_id);
  }, [preapproval_id]);

  const updatePaymentReference = async (preapproval_id) => {
    await mutateAsync(preapproval_id);
  };

  const SubscriptionStatus = ({ status }) => {
    // authorized: Preapproval with a valid payment method
    if (status == 'authorized') return <PaymentSuccess />;
    // pending: Preapproval without a payment method
    if (status == 'pending') return <PaymentPending />;
    // paused: Preapproval with temporally discontinued collection of payments
    if (status == 'paused') return <PaymentPending />;
    // cancelled: Preapproval terminated. This is an irreversible state
    if (status == 'cancelled') return <PaymentFailure />;
    return null;
  };

  return (
    <div data-testid="plans-configuration-page" className="container-fluid custom-column">
      {isLoading ? (
        <Spinner show={isLoading} />
      ) : (
        <div className="position-relative w-100 d-flex flex-column overflow-md-hidden">
          {isSuccess && <SubscriptionStatus status={data} />}
          {isError ? <MyInternalServerError /> : null}
        </div>
      )}
    </div>
  );
};

export default PaymentValidation;
