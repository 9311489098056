import { getLocalStorageWorkspace } from '../workspaceUtils';

const hasHttp = (url) => url.includes('https://') || url.includes('http://');

export const formatUrl = (url) => {
  if (!hasHttp(url)) {
    url = `https://${url}`;
  }
  return url;
};

export const generateTGDemoUrl = async (testGroup, userId) => {
  const redirectURL = new URL(
    `${process.env.RAZZLE_RUNTIME_SCREENINGS_FRONT}/trainning/${testGroup.id}`
  );
  const token = localStorage.getItem('token');
  const workspace = getLocalStorageWorkspace();
  redirectURL.search = new URLSearchParams({
    token,
    userId,
  });

  redirectURL.searchParams.append('workspaceId', workspace?.id);
  redirectURL.searchParams.append('companyId', workspace?.companyId);
  return redirectURL.toString();
};
