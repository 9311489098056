const ProctoringWarning = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 100 125"
    fill="#DC251C"
    width="23"
    height="23"
    transform="scale(1.7)"
  >
    <g transform="translate(0,-952.36218)">
      <path
        d="m 49.8125,960.37636 a 2.0002,2.0002 0 0 0 -0.625,0.15625 l -32.000001,13.9375 A 2.0002,2.0002 0 0 0 16,976.31386 c 0,21.55536 1.305349,33.95134 6.249999,43.40624 4.944651,9.4549 13.327191,15.4568 26.656251,24.3125 a 2.0002,2.0002 0 0 0 2.1875,0 c 13.32906,-8.8557 21.7116,-14.8576 26.65625,-24.3125 4.94465,-9.4549 6.25,-21.85088 6.25,-43.40624 a 2.0002,2.0002 0 0 0 -1.1875,-1.84375 l -32,-13.9375 a 2.0002,2.0002 0 0 0 -1,-0.15625 z m 0.1875,4.15625 29.96875,13.0625 c -0.0617,20.49131 -1.4443,32.04809 -5.75,40.28119 -4.26929,8.1635 -11.68355,13.7002 -24.21875,22.0625 -12.5352,-8.3623 -19.94946,-13.899 -24.218751,-22.0625 -4.3057,-8.2331 -5.68832,-19.78988 -5.75,-40.28119 L 50,964.53261 z m -7,14.84375 2,29.99994 10,0 2,-29.99994 -14,0 z m 2,33.99994 0,10 10,0 0,-10 -10,0 z"
        fill="#DC251C"
        fillOpacity="1"
        stroke="none"
        visibility="visible"
        display="inline"
        overflow="visible"
      />
    </g>
  </svg>
);

export default ProctoringWarning;
