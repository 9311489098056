import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSavedSearch } from '../../API/services/companyService';
import { setSavedSearches } from '../../redux/actions/profilesActions';
import { t } from '../../i18n/i18n';
import SearchItemFilters from '../SearchItemFilters/SearchItemFilters';
import { formatSearchData } from '../../utils/filters/formatSearchData';
import Accordion from '../Accordion/Accordion';

function SavedFiltersList({ filters, appliedFilters }) {
  const dispatch = useDispatch();
  const profilesState = useSelector((state) => state.profiles);

  const [displayMore, setDisplayMore] = useState(false);
  const [someSelected, setSomeSelected] = useState(false);
  const [sortedFilters, setSortedFilters] = useState([]);

  useEffect(() => {
    const selected = filters.find(
      (filter) => JSON.stringify(appliedFilters) === JSON.stringify(formatSearchData(filter, t))
    );
    const excludeFilters = filters.filter(
      (filter) => JSON.stringify(appliedFilters) !== JSON.stringify(formatSearchData(filter, t))
    );
    const sorted = [];
    if (selected) sorted.push(selected);
    if (excludeFilters.length) sorted.push(...excludeFilters);
    setSortedFilters(sorted);
  }, [filters]);

  const handleDeleteSearch = (id) => {
    deleteSavedSearch(id).then(() => {
      dispatch(
        setSavedSearches({
          ...profilesState?.savedSearches,
          filters: profilesState?.savedSearches.filters.filter((search) => search.id !== id),
        })
      );
    });
  };

  return (
    <div className="d-flex flex-column align-items-start my-2 mb-4">
      <div>
        <h6 className="font-weight-bold profile-search-filters-label font-montserrat font-weight-bold">
          {t('PROFILES_SAVE_FILTER')}
        </h6>
      </div>
      {sortedFilters?.slice(0, 3)?.map((item, index) => (
        <SearchItemFilters
          search={item}
          key={index}
          handleDelete={() => handleDeleteSearch(item.id)}
          setSomeSelected={setSomeSelected}
          someSelected={someSelected}
        />
      ))}
      <Accordion open={displayMore}>
        {sortedFilters?.slice(3)?.map((item, index) => (
          <SearchItemFilters
            search={item}
            key={index}
            handleDelete={() => handleDeleteSearch(item.id)}
            setSomeSelected={setSomeSelected}
            someSelected={someSelected}
          />
        ))}
      </Accordion>
      {filters?.length > 3 && (
        <button
          className="bg-transparent my-2 font-roboto border-0 outline-0 text-maya-blue"
          type="button"
          onClick={() => setDisplayMore((value) => !value)}
        >
          {t(`PROFILES_FILTER_SEE_${displayMore ? 'LESS' : 'MORE'}_SEARCHES`)}
        </button>
      )}
    </div>
  );
}

export default SavedFiltersList;
