function FiltersSkeleton({ dashboard }) {
  return (
    <div className="pt-2">
      {dashboard ? (
        <>
          <div className="ph-row ph-item">
            <div className="big w-20 mr-2" />
            <div className="big w-20 mr-2" />
            <div className="big w-20 mr-2" />
            <div className="big w-20" />
          </div>
          <div className="ph-row ph-item">
            <div className="big w-20 mr-2" />
            <div className="big w-20 mr-2" />
            <div className="big w-20 mr-2" />
            <div className="big w-20" />
          </div>
        </>
      ) : (
        <>
          <div className="ph-row ph-item">
            <div data-testid="skeleton-non-dashboard-column" className="ph-col-4 big" />
            <div data-testid="skeleton-non-dashboard-column" className="ph-col-2 med" />
            <div data-testid="skeleton-non-dashboard-column" className="ph-col-10 med empty" />
            <div data-testid="skeleton-non-dashboard-column" className="ph-col-6 med" />
            <div data-testid="skeleton-non-dashboard-column" className="ph-col-6 med empty" />
            <div data-testid="skeleton-non-dashboard-column" className="ph-col-4 med" />
          </div>
          <div className="ph-row ph-item">
            <div data-testid="skeleton-non-dashboard-column" className="ph-col-6 big" />
            <div data-testid="skeleton-non-dashboard-column" className="ph-col-6 big empty" />
            <div data-testid="skeleton-non-dashboard-column" className="ph-col-4 med" />
            <div data-testid="skeleton-non-dashboard-column" className="ph-col-8 med empty" />
            <div data-testid="skeleton-non-dashboard-column" className="ph-col-2 med" />
            <div data-testid="skeleton-non-dashboard-column" className="ph-col-10 med empty" />
            <div data-testid="skeleton-non-dashboard-column" className="ph-col-4 med" />
          </div>
          <div className="ph-row ph-item">
            <div data-testid="skeleton-non-dashboard-column" className="ph-col-8 big" />
            <div data-testid="skeleton-non-dashboard-column" className="ph-col-4 big empty" />
            <div data-testid="skeleton-non-dashboard-column" className="ph-col-4 med" />
            <div data-testid="skeleton-non-dashboard-column" className="ph-col-8 med empty" />
            <div data-testid="skeleton-non-dashboard-column" className="ph-col-6 med" />
            <div data-testid="skeleton-non-dashboard-column" className="ph-col-6 med empty" />
            <div data-testid="skeleton-non-dashboard-column" className="ph-col-2 med" />
          </div>
        </>
      )}
    </div>
  );
}

export default FiltersSkeleton;
