import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { t } from '../../i18n/i18n';
import Address from '../../utils/address';
import { passStageInBatch } from '../../API/services/screeningService';
import { setInvitedProfile } from '../../redux/actions/profilesActions';
import MarkIcon from '../../assets/profile/MarkIcon';
import profileImg from '../../assets/header/profile.svg';
import LinkedinIcon from '../../assets/invitedProfile/LinkedinIcon';
import EmailIcon from '../../assets/invitedProfile/EmailIcon';
import PhoneIcon from '../../assets/invitedProfile/PhoneIcon';
import CvIcon from '../../assets/profile/CvIcon';
import './InvitedProfileData.scss';
import {
  setEmailsData,
  setNewSearchData,
  setSearchWasAlreadyCreated,
  setSendEmailFrom,
  setSubjectEmail,
} from '../../redux/actions/screeningActions';
import { PartnerWorkspaceRole, ProcessStatusStage } from '../../constants/enums';
import DropdownSelectDot from '../DropdownSelectDot/DropdownSelectDot';
import ContactComponent from '../ContactComponent/ContactComponent';
import DeleteIcon from '../../assets/profile/Delete';
import { capitalizeString } from '../../utils/stringUtils';
import {
  getDuplicateInvitations,
  getRecruitSessionStageActive,
} from '../../API/services/alkymersService';
import { disableForWorkspaceRole } from '../../utils/workspaceRoleValidation';
import { CREATE_TEMPLATE } from '../../constants/routes';
import RecruitSessionDropdown from '../RecruitSessionDropdown/RecruitSessionDropdown';

const InvitedProfileData = ({
  profile,
  search,
  currentStage,
  nextStage,
  currentInvitation,
  setSelected,
  setEditMailVisible,
  updateStageSingleCandidate,
  isInvitedProfile,
  deleteCandidate,
  disabledWorkspaceFunctions,
  className = '',
}) => {
  const invitedProfile = useSelector((state) => state.profiles.invitedProfile);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = history.location.pathname.split('/')[1];
  const [confirmPassStageModal, setConfirmPassStageModal] = useState(false);
  const { currentWorkspace, currentRole } = useSelector((state) => state.auth);
  const [recruitSessionStages, setRecruitSessionStages] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const { newSearch } = useSelector((state) => state.screenings);

  useEffect(async () => {
    const data = await getRecruitSessionStageActive(currentWorkspace);
    setRecruitSessionStages(data.collection);
    dispatch(
      setNewSearchData({
        name: '',
        stagesQuantity: 1,
        stages: [],
        step: 1,
      })
    );
  }, [currentWorkspace]);

  const workspaceDisable = disableForWorkspaceRole(currentRole, [
    PartnerWorkspaceRole.OWNER,
    PartnerWorkspaceRole.ADMINISTRATOR,
  ]);

  const createAssessmentInvitationAction = async (body) => {
    let candidate = [];
    try {
      candidate = await getDuplicateInvitations(body?.id, [profile?.email]);
    } catch (e) {
      notification.open({
        message: t('ERROR_EVALUATION'),
        type: 'error',
      });
      return;
    }
    if (candidate?.length > 0) {
      notification.open({
        message: t('DUPLICATE_INVITATION_ERROR_NOTIFICATION_SINGULAR'),
        type: 'error',
      });
    } else {
      dispatch(setSendEmailFrom('edit'));
      const selectedSearch = {
        name: body?.name,
        stages: [
          {
            id: body?.id,
            levelId: body?.levelId,
            technologyId: body?.technologyId,
          },
        ],
      };

      const data = {
        v4_id: {
          firstName: profile?.firstName,
          lastName: profile?.lastName,
          email: profile?.email,
        },
      };
      dispatch(
        setNewSearchData({
          ...newSearch,
          ...selectedSearch,
          step: 4,
        })
      );
      dispatch(setSubjectEmail({ subjectEmail: '' }));
      dispatch(setSearchWasAlreadyCreated(true));
      history.push(CREATE_TEMPLATE);
      dispatch(setEmailsData(data));
    }
  };

  const handleSelectCandidate = () => {
    const id = invitedProfile?.stages[0].invitation?.id;
    setEditMailVisible(true);
    setSelected({ [id]: true });
    dispatch(setSubjectEmail({ subjectEmail: '' }));
    dispatch(setEmailsData({}));
    dispatch(setSearchWasAlreadyCreated(true));
  };

  const handlePassStage = async () => {
    if (isInvitedProfile) {
      const response = await passStageInBatch({
        invitationsIds: [currentInvitation?.id],
        screeningName: search.name,
        recruitSessionStageId: nextStage.id,
      });
      if (response?.length) {
        setEditMailVisible(false);
        notification.open({
          message: t('PASS_STAGE_BATCH_SUCCESSFUL'),
          type: 'success',
        });
        const invitation = response[0];
        const profile = invitedProfile;
        const stages = profile.stages.map((stage) => {
          if (stage.recruitSessionStage.id === invitation.recruitSessionStageId) {
            stage = { ...stage, invitation };
          }
          return stage;
        });
        dispatch(setInvitedProfile({ ...profile, stages }));
      } else {
        notification.open({
          message: t('PASS_STAGE_BATCH_FAIL'),
          type: 'error',
        });
      }
    }
    setConfirmPassStageModal(false);
  };

  const processStatusOptions = Object.values(ProcessStatusStage).map((status) => ({
    name: t(`SCREENING_INVITATIONS_PROCESS_STATUS_${status}`),
    value: status,
  }));

  return (
    <div className={`position-relative h-100 invited-profile-data-card-custom ${className}`}>
      <div className="card-profile description-container position-relative h-100 p-4 bg-white b-radius-8">
        <div className="d-flex flex-column">
          <div className="profile-list-data-item text-center position-relative ml-2 d-flex justify-content-center invited-list-status-dropdown-width align-items-center">
            {isInvitedProfile && (
              <DropdownSelectDot
                className="position-absolute top-0 right-0 ml-2 py-0 font-montserrat text-12-px border-maya-blue b-radius-8"
                placeholder={t('DASHBOARD_INVITATIONS_PROCESS_STATUS')}
                listSelect
                arrowLightBlue
                options={processStatusOptions}
                dot
                selectedPlaceholder
                selected={invitedProfile?.stages[0]?.invitation?.processStatus}
                setState={(e) =>
                  updateStageSingleCandidate(e.id, invitedProfile?.stages[0]?.invitation?.id)
                }
                padding={false}
              />
            )}
          </div>
          <div className="d-flex flex-row align-items-center mt-2">
            <img
              className="profile-invitation-img rounded-circle p-1"
              src={profile?.picture || profileImg}
              alt={t('PROFILE_MENU_LOGO_USER')}
            />
            <span className="font-montserrat font-weight-600 text-base mx-2 mr-3 text-capitalize">
              {profile?.firstName} {profile?.lastName}
            </span>
            {!!profile?.linkedinUrl && (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{t('CONTACT_BY_LINKEDIN')}</Tooltip>}
              >
                <a
                  href={profile?.linkedinUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="text-decoration-none mr-2"
                >
                  <LinkedinIcon />
                </a>
              </OverlayTrigger>
            )}
            {location === 'profiles' ? (
              <ContactComponent
                className="border-0 mr-2"
                alkymer={profile}
                Icon={EmailIcon}
                title={t('CONTACT_BY_EMAIL')}
              />
            ) : (
              !disabledWorkspaceFunctions && (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{t('CONTACT_BY_EMAIL')}</Tooltip>}
                >
                  <button
                    type="button"
                    onClick={() => handleSelectCandidate()}
                    className="ml-2 border-0 bg-transparent"
                  >
                    <EmailIcon />
                  </button>
                </OverlayTrigger>
              )
            )}
            {!workspaceDisable && (
              <div className="ml-2">
                <RecruitSessionDropdown
                  options={recruitSessionStages || []}
                  setOpenDropdown={setOpenDropdown}
                  openDropdown={openDropdown}
                  createAssessmentInvitationAction={createAssessmentInvitationAction}
                  disabled={workspaceDisable}
                />
              </div>
            )}
            {isInvitedProfile &&
              invitedProfile?.stages[0].invitation &&
              !disabledWorkspaceFunctions && (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{t('DELETE_CANDIDATE')}</Tooltip>}
                >
                  <button
                    data-testid="button-delete-candidate-from-profile"
                    type="button"
                    onClick={() => deleteCandidate([invitedProfile?.stages[0].invitation], false)}
                    disabled={Boolean(
                      invitedProfile?.stages[0].invitation?.startDate ||
                        invitedProfile?.stages[0].invitation?.endDate
                    )}
                    className={`my-2 ml-2 pr-2 border-0 bg-transparent ${
                      invitedProfile?.stages[0].invitation?.startDate ||
                      invitedProfile?.stages[0].invitation?.endDate
                        ? 'cursor-not-allowed'
                        : ''
                    }`}
                  >
                    <DeleteIcon
                      className="icon"
                      fill={
                        invitedProfile?.stages[0].invitation?.startDate ||
                        invitedProfile?.stages[0].invitation?.endDate
                          ? '#BCBCBC'
                          : '#4bafe1'
                      }
                    />
                  </button>
                </OverlayTrigger>
              )}
          </div>
          <div className="mt-3">
            {Boolean(nextStage) && isInvitedProfile && (
              <div className="d-flex align-items-center h-100 justify-content-between">
                <div className="profile-list-data-item text-center position-relative ml-2 d-flex justify-content-center invited-list-status-width align-items-center">
                  <button
                    onClick={() => setConfirmPassStageModal(true)}
                    type="button"
                    className="px-3 invited-profile-pass-stage-button text-white rounded-pill border-0 text-xs font-weight-bold"
                  >
                    {t('INVITED_PROFILE_PASS_STAGE')}
                  </button>
                </div>
              </div>
            )}
            <div className="mt-3 pl-2 d-flex flex-column">
              {Boolean(profile?.email) && (
                <div className="d-flex flex-row align-items-center mt-2 mb-1">
                  <EmailIcon color="#FD7779" />
                  <span className="font-montserrat font-weight-600 text-xs invited-profile-data-text text-break ml-2">
                    {profile?.email}
                  </span>
                </div>
              )}
              {Boolean(profile?.address) && (
                <div className="d-flex flex-row align-items-center mt-2 mb-1">
                  <MarkIcon className="ml-custom-mark-icon" />
                  <span className="font-montserrat font-weight-600 text-xs invited-profile-data-text text-break margin-left-country-custom">
                    {Address(profile)}
                  </span>
                </div>
              )}
              {Boolean(profile?.cvUrl) && (
                <div className="d-flex flex-row align-items-center mt-2 mb-1">
                  <CvIcon />
                  <a
                    href={profile?.cvUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="font-montserrat font-weight-600 text-xs download-cv-text text-break margin-left-cv-custom text-decoration-none"
                  >
                    {t('SEARCH_DOWNLOAD_CV')}
                  </a>
                </div>
              )}
              {Boolean(profile?.phone) && (
                <div className="d-flex flex-row align-items-center mt-2 mb-1">
                  <PhoneIcon />
                  <span className="font-montserrat font-weight-600 text-xs invited-profile-data-text text-break margin-left-phone-custom">
                    {profile?.phone}
                  </span>
                </div>
              )}
            </div>
            <button
              onClick={() =>
                isInvitedProfile
                  ? history.push(
                      `/search/${invitedProfile?.stages[0].recruitSessionStage.recruitSessionId}`
                    )
                  : history.push('/profiles')
              }
              className={`font-montserrat profile-buttons text-xs border-maya-blue ${
                !isInvitedProfile && 'go-back-button'
              } cursor-pointer rounded-pill d-flex align-items-center font-weight-700 text-maya-blue bg-white mb-3 mb-lg-0 mx-auto px-5 py-2 mt-4`}
              type="button"
            >
              {isInvitedProfile
                ? t('INVITED_PROFILE_GO_DASHBOARD')
                : t('INVITED_PROFILE_GO_COMMUNITY')}
            </button>
          </div>
        </div>
        <Modal
          visible={confirmPassStageModal && isInvitedProfile}
          onOk={handlePassStage}
          onCancel={() => setConfirmPassStageModal(false)}
          okText={t('SCREENING_PROCESS_STATUS_MODIFY_CONFIRM')}
          cancelText={t('SCREENING_PROCESS_STATUS_MODIFY_CANCEL')}
        >
          <div className="p-3 invitation-status-modal-container">
            <span className="font-montserrat">
              {t('PASS_STAGE_MODAL_TEXT')
                .replace('{name}', `${profile?.firstName} ${profile?.lastName}`)
                .replace('{currentStage}', currentStage?.index + 1)
                .replace('{nextStage}', nextStage?.index + 1)}
            </span>
          </div>
        </Modal>
      </div>
      {!!profile?.description && (
        <div className="card-profile description-container position-relative h-100 p-4 mt-3 bg-white b-radius-8">
          <span className="font-montserrat font-weight-600 text-base invited-profile-stage-text">
            {t('SEARCH_ABOUT_PROFILE')} {capitalizeString(profile?.firstName)}
          </span>
          <div className="container-about-invited-profile mt-3">
            <span className="font-montserrat font-weight-500 text-sm invited-profile-search b-radius-8 text-break">
              {profile?.description}
            </span>
          </div>
        </div>
      )}
      {!!profile?.videos?.filter((item) => item.videoType === 'VIDEO_10')?.length && (
        <div className="b-radius-8 card-profile description-container position-relative h-100 px-4 pt-4 pb-3 mt-3 bg-white">
          <span className="font-montserrat font-weight-600 text-base invited-profile-stage-text">
            {t('SEARCH_PRESENTATION_OF')} {capitalizeString(profile?.firstName)}
          </span>
          <ReactPlayer
            className="w-100 h-100 mt-3"
            url={profile?.videos?.find((item) => item.videoType === 'VIDEO_10')?.videoUrl}
            controls
            width={100}
          />
        </div>
      )}
    </div>
  );
};

export default InvitedProfileData;
