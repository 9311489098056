import React from 'react';

const ArchiveBoxIcon = ({ className = '' }) => (
  <svg
    className={className}
    width="19"
    height="16"
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.914285 0.205725C0.697142 0.308581 0.4 0.571439 0.262857 0.788582C0.0114286 1.1543 0 1.29144 0 3.04001C0 5.10858 0.0114286 5.14286 0.788571 5.14286H1.14286V10.1829C1.14286 13.4171 1.18857 15.2914 1.25714 15.44C1.56571 16 1.54286 16 9.15428 16H16.2514L16.64 15.6114L17.0286 15.2229V10.1829V5.14286H17.4171C18.1486 5.14286 18.1943 4.99429 18.1486 2.92572C18.1143 1.18858 18.1028 1.06287 17.84 0.72001C17.2914 -0.0114174 17.4857 1.04904e-05 8.99428 1.04904e-05C1.90857 1.04904e-05 1.28 0.0114393 0.914285 0.205725ZM16.5714 2.57144V3.42858H9.08571H1.6V2.57144V1.7143H9.08571H16.5714V2.57144ZM15.4286 9.71429V14.2857H9.08571H2.74286V9.71429V5.14286H9.08571H15.4286V9.71429Z"
      fill="#757575"
    />
    <path
      d="M7.00583 6.937C6.77726 7.02843 6.81155 8.41129 7.04012 8.50272C7.26869 8.59415 10.903 8.59415 11.1315 8.50272C11.3601 8.41129 11.3944 7.21129 11.1773 6.99415C11.0858 6.90272 10.4687 6.857 9.08583 6.86843C8.02297 6.86843 7.0744 6.90272 7.00583 6.937Z"
      fill="#757575"
    />
  </svg>
);

export default ArchiveBoxIcon;
