import { PartnerWorkspaceRole } from '../constants/enums';

export const getLocalStorageWorkspace = () => {
  try {
    return JSON.parse(localStorage?.getItem('workspaceInfo'));
  } catch (error) {
    return null;
  }
};

export const setLocalStorageWorkspace = (workspace) => {
  try {
    const strWorkspace = JSON.stringify(workspace);
    JSON.parse(strWorkspace);
    localStorage.setItem('workspaceInfo', strWorkspace);
  } catch (err) {}
};

export const eliminateAlkemyMembers = (response, currentWorkspace) => {
  if (response) {
    if (
      currentWorkspace !== 1 &&
      response?.collection?.length > 0 &&
      response?.pagination &&
      currentWorkspace !== 2
    ) {
      const collectionTemp = response.collection.filter(
        (row) => typeof row.email === 'string' && !row.email.includes('@alkemy.org')
      );
      const difTotalAndTotalWOFilter =
        response.pagination.totalWithoutFilters - response.pagination.total;
      response.pagination.total = collectionTemp.length;
      response.pagination.totalWithoutFilters =
        response.pagination.total + difTotalAndTotalWOFilter;
      response.collection = collectionTemp;
    }
  }
  return response;
};

export const hasCompanyRootAccess = (companyId, workspaces) => {
  const workspaceRootAccess = workspaces.find(
    (workspace) =>
      workspace?.companyId === companyId &&
      workspace?.role !== PartnerWorkspaceRole.VISUALIZATOR &&
      workspace.workspace.isRootGroup
  );
  return !!workspaceRootAccess;
};
