import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { t } from '../../i18n/i18n';
import './SearchInput.scss';

const SearchInput = ({ value, onSubmit, onInput, className = '' }) => {
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);

  return (
    <form onSubmit={onSubmit}>
      <div
        className={`input-container ${className} ${focus ? 'input-container-active' : ''} ${
          hover ? 'input-container-hover' : ''
        }`}
      >
        <input
          className="custom-input w-100 pr-5"
          placeholder={t('CUSTOM_SEARCH_PLACEHOLDER')}
          value={value}
          onInput={onInput}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        />
        <button type="submit" className="profile-search-input-search-btn">
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </div>
    </form>
  );
};

export default SearchInput;
