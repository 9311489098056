const ProctoringOK = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 52 65"
    fill="#199028"
    xmlSpace="preserve"
    width="23"
    height="23"
    transform="scale(1.7)"
  >
    <g>
      <g>
        <path d="M25.9882832,49.9997559c-0.1953125,0-0.3857422-0.0566406-0.5478516-0.1621094    C3.0380878,39.4616699,5.0439472,11.0437031,5.0664082,10.7565937C5.1074238,10.2429218,5.5341816,9.8444843,6.04883,9.8366718    c0.1103516-0.0019531,11.0517578-0.25,19.2998047-7.5839844c0.3789063-0.3378906,0.9492188-0.3378906,1.328125,0    c8.2685528,7.3525391,19.1640606,7.5830078,19.2734356,7.5839844c0.515625,0.0068359,0.9423828,0.4052734,0.9833984,0.9199219    c0.0224609,0.2871094,2.0283203,28.7050762-20.3749981,39.0810547c-0.1650391,0.1074219-0.3808594,0.171875-0.5585938,0.1621094    C25.9960957,49.9997559,25.9921894,49.9997559,25.9882832,49.9997559z M7.0214863,11.7897968    c-0.1269531,4.71875,0.2871094,27.2207012,18.9785156,36.1005859    c18.7148418-8.8896484,19.1103497-31.3837872,18.9785137-36.1005859C42.1103516,11.5808125,33.34375,10.448,26.0126972,4.3200703    C18.6787128,10.4489765,9.8955097,11.581789,7.0214863,11.7897968z" />
      </g>
      <g>
        <path d="M26.0000019,37.9997559c-6.6171875,0-12-5.3828125-12-11.9999981s5.3828125-12,12-12S38,19.3825703,38,25.9997578    S32.6171875,37.9997559,26.0000019,37.9997559z M26.0000019,15.9997578c-5.5136719,0-10,4.4863281-10,10    s4.4863281,9.9999981,10,9.9999981S36,31.5134296,36,25.9997578S31.5136738,15.9997578,26.0000019,15.9997578z" />
      </g>
      <g>
        <path d="M23.8574238,30.9997578c-0.2529297,0-0.4970703-0.0957031-0.6826172-0.2685547l-2.8574219-2.6660156    c-0.4033203-0.3769531-0.4257813-1.0097656-0.0488281-1.4140625c0.3779297-0.4033203,1.0097656-0.4248047,1.4140625-0.0488281    l2.109375,1.96875l6.4619141-7.2373047c0.3681641-0.4130859,1.0009766-0.4462891,1.4121094-0.0800781    c0.4121075,0.3681641,0.4482403,1,0.0800781,1.4121094l-7.1425781,8    c-0.1796875,0.2001953-0.4316406,0.3203125-0.7001953,0.3330078    C23.8876972,30.9997578,23.8730488,30.9997578,23.8574238,30.9997578z" />
      </g>
    </g>
  </svg>
);

export default ProctoringOK;
