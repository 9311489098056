import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AnswerTypeEnum, PlanFeaturesIds, TestFileType } from '../../constants/enums';
import useCreateScreening from '../../hooks/useCreateScreening';
import useCreateTestGroup from '../../hooks/useCreateTestGroup';
import { t } from '../../i18n/i18n';
import { TrackEvent } from '../../utils/filters/segmentUtils';
import DisabledFeature from '../DisabledFeature/DisabledFeature';
import DropDownSelect from '../DropdownSelect/DropdownSelect';
import QuestionMultipleChoice from '../QuestionMultipleChoice/QuestionMultipleChoice';
import QuestionText from '../QuestionText/QuestionText';
import QuestionWrite from '../QuestionWrite/QuestionWrite';
import QuestionWithFiles from '../QuestionWithFiles/QuestionWithFiles';
import QuestionWithVideoAnswer from '../QuestionWithVideoAnswer/QuestionWithVideoAnswer';
import './AddQuestion.scss';
import AnswerTypeButton from './AnswerTypeButton/AnswerTypeButton';
import QuestionCode from '../QuestionCode/QuestionCode';
import MultipleChoice from '../../assets/additionalQuestions/multipleChoice.jsx';
import WriteAnswer from '../../assets/additionalQuestions/writeAnswer.jsx';
import Ai from '../../assets/additionalQuestions/ai.jsx';
import UploadFile from '../../assets/additionalQuestions/uploadFile.jsx';
import Video from '../../assets/additionalQuestions/video.jsx';
import TextAnswer from '../../assets/additionalQuestions/textAnswer.jsx';
import CodeAnswer from '../../assets/additionalQuestions/codeAnswer.jsx';

const AddQuestions = ({
  testGroupABM,
  incompleteQuestion,
  testgroupWithThemes,
  setShowDropdown,
}) => {
  const {
    currentTypeQuestion,
    isEdit,
    currentQuestionData,
    disableAddCheckQuestion,
    disableAddFileQuestion,
    disableAddTextQuestion,
    disableAddTextIaQuestion,
    handleEditQuestion,
    handleAddQuestion,
    handleAddQuestionDifficulty,
    handleAddQuestionText,
    eachScore,
    disableAddCodeQuestion,
    setEachScore,
    disableAddWriteQuestion,
  } = useCreateScreening();
  const { testDifficulty } = useCreateTestGroup();
  const { pathname } = useLocation();
  const [testAnswer, setTestAnswer] = useState({
    text: '',
    score: undefined,
    explanation: undefined,
  });
  const track = pathname === '/createscreening';
  const disableEachScore =
    currentTypeQuestion === AnswerTypeEnum.CHECK &&
    (!currentQuestionData?.options?.length >= 2 ||
      !currentQuestionData?.options?.every((answer) => answer?.text.length));
  const AIDifficultyId = '64f5e4f13b7d7759631e6f09';
  const videoAnswerDifficultyId = '658068f6a6e9a25ee9245f83';

  const buttons = [
    {
      id: AnswerTypeEnum.CHECK,
      disableIds: [AnswerTypeEnum.CHECK],
      name: t('CREATE_SCREENING_YOUR_QUESTIONS_CHECK'),
      track: 'create-screening-multiple-question',
      show: true,
      img: <MultipleChoice />,
      isPremiumFeature: false,
    },
    {
      id: AnswerTypeEnum.WRITE,
      disableIds: [AnswerTypeEnum.WRITE],
      name: t('CREATE_SCREENING_YOUR_QUESTIONS_WRITE'),
      track: 'create-screening-write-question',
      show: true,
      img: <WriteAnswer />,
      isPremiumFeature: false,
    },
    {
      id: testGroupABM ? AnswerTypeEnum.TEXT_AI : AnswerTypeEnum.TEXT,
      disableIds: [AnswerTypeEnum.TEXT, AnswerTypeEnum.TEXT_AI],
      name: t('CREATE_SCREENING_YOUR_QUESTIONS_TEXT'),
      track: 'create-screening-text-question',
      show: true,
      img: <TextAnswer />,
      isPremiumFeature: false,
      img2: <Ai />,
    },
    {
      id: AnswerTypeEnum.FILE,
      disableIds: [AnswerTypeEnum.FILE],
      name: t('CREATE_SCREENING_YOUR_QUESTIONS_FILE'),
      track: 'create-screening-file-question',
      show: true,
      isPremiumFeature: false,
      img: <UploadFile />,
    },
    {
      id: AnswerTypeEnum.VIDEO,
      disableIds: [AnswerTypeEnum.VIDEO],
      name: t('CREATE_SCREENING_YOUR_QUESTIONS_VIDEO'),
      track: 'create-screening-video-question',
      show: true,
      isPremiumFeature: !testGroupABM,
      img: <Video />,
    },
    {
      id: AnswerTypeEnum.CODE,
      disableIds: [AnswerTypeEnum.CODE],
      name: t('CREATE_SCREENING_YOUR_QUESTIONS_CODE'),
      track: 'create-screening-code-question',
      show: testGroupABM,
      isPremiumFeature: true,
      img: <CodeAnswer />,
    },
  ];

  const disableQuestionButton = Boolean(
    !currentQuestionData?.text?.length ||
      disableAddTextQuestion ||
      disableAddTextIaQuestion ||
      (![AnswerTypeEnum.CODE].includes(currentTypeQuestion) &&
        currentQuestionData?.options?.some((option) => !option.text.trim().length)) ||
      disableAddFileQuestion ||
      (eachScore ? disableEachScore : disableAddCheckQuestion) ||
      (AnswerTypeEnum.VIDEO === currentTypeQuestion &&
        !(
          currentQuestionData?.preparationTime &&
          currentQuestionData?.totalTime &&
          currentQuestionData?.maxAttempts
        )) ||
      disableAddWriteQuestion
  );

  const disabledTestGroupABMButton =
    disableQuestionButton ||
    disableAddCodeQuestion ||
    (AnswerTypeEnum.VIDEO !== currentTypeQuestion && !currentQuestionData?.difficultyId) ||
    (testgroupWithThemes && !currentQuestionData?.theme) ||
    ([AnswerTypeEnum.CHECK, AnswerTypeEnum.RADIO].includes(currentTypeQuestion) &&
      !currentQuestionData?.options?.some((option) => option.correct === true) &&
      !eachScore) ||
    currentQuestionData?.testFiles?.some(
      (file) =>
        file.fileType === TestFileType.AUDIO && !file?.infinitePlays && !Number(file?.numberOfPlays)
    );

  const handleCustomQuestion = (callback) => {
    if (testGroupABM && eachScore) {
      if (
        currentQuestionData.options.some((option) => option.weight === '') ||
        currentQuestionData.options.filter(
          (option) =>
            (testGroupABM && Number(option.weight) > currentQuestionData?.weight) ||
            (testGroupABM && Number(option.weight) < -currentQuestionData?.weight)
        ).length
      ) {
        notification.open({
          message: testGroupABM
            ? t('CREATE_TEST_GROUP_MESSAGE_ERROR').replaceAll(
                '{score}',
                currentQuestionData?.weight
              )
            : t('CREATE_TEST_INVALID_CUSTOM_WEIGHT'),
          type: 'error',
        });
        return;
      }
      if (
        !currentQuestionData.options.some(
          (option) => Number(option.weight) === currentQuestionData?.weight
        )
      ) {
        notification.open({
          message: t('CREATE_TEST_GROUP_INVALID_OPTION'),
          type: 'error',
        });
        return;
      }
      callback();
      return;
    }
    setTestAnswer({
      text: '',
      score: undefined,
      explanation: undefined,
    });
    callback();
  };

  useEffect(() => {
    if (testGroupABM && currentTypeQuestion === AnswerTypeEnum.TEXT_AI) {
      const IADifficulty = testDifficulty?.find((test) => test?.id === AIDifficultyId);
      handleAddQuestionDifficulty({
        id: IADifficulty?.id,
        name: t(`CREATE_TEST_GROUP_QUESTION_${IADifficulty?.name}`),
        weight: IADifficulty?.score,
      });
    } else if (
      testGroupABM &&
      (currentTypeQuestion === AnswerTypeEnum.VIDEO || currentTypeQuestion === AnswerTypeEnum.TEXT)
    ) {
      const videoDifficulty = testDifficulty?.find((test) => test?.id === videoAnswerDifficultyId);
      handleAddQuestionDifficulty({
        id: videoDifficulty?.id,
        name: t(`CREATE_TEST_GROUP_QUESTION_${videoDifficulty?.name}`),
        weight: videoDifficulty?.score,
      });
    }
  }, [currentTypeQuestion]);

  return (
    <div
      className={`w-100 row justify-content-center my-3 d-flex flex-column align-items-center ${
        incompleteQuestion && 'border-danger'
      }`}
    >
      {testGroupABM && (
        <div
          className={`${
            testgroupWithThemes ? 'w-92-5 align-self-end' : 'w-85'
          } d-flex align-items-center`}
        >
          <input
            placeholder={t('ABM_NAME_INPUT_PLACEHOLDER')}
            value={currentQuestionData?.name ?? ''}
            onChange={handleAddQuestionText}
            name="name"
            className="mx-2 rounded py-2 text-sm font-weight-bold theme-input-border outline-none font-montserrat py-1 px-2 outline-none h-10"
          />
          <DropDownSelect
            selectedPlaceholder
            className="ml-0 mr-2"
            placeholder={t('MULTIPLE_SELECT_DIFFICULTY_PLACEHOLDER')}
            setState={(e) => handleAddQuestionDifficulty(e)}
            selected={currentQuestionData?.difficultyId}
            options={testDifficulty
              ?.filter((test) =>
                [AnswerTypeEnum.CHECK, AnswerTypeEnum.RADIO].includes(currentTypeQuestion)
                  ? test.id !== AIDifficultyId && test.id !== videoAnswerDifficultyId
                  : true
              )
              ?.sort((a, b) => a.order - b.order)
              ?.map((item) => ({
                id: item.id,
                name: t(`CREATE_TEST_GROUP_QUESTION_${item.name}`),
                weight: item.score,
              }))}
            disabled={[AnswerTypeEnum.TEXT, AnswerTypeEnum.TEXT_AI, AnswerTypeEnum.VIDEO].includes(
              currentTypeQuestion
            )}
          />
          {testgroupWithThemes && (
            <input
              placeholder={t('ABM_THEME_INPUT_PLACEHOLDER')}
              value={currentQuestionData?.theme ?? ''}
              onChange={handleAddQuestionText}
              name="theme"
              className="mx-2 rounded py-2 text-sm font-weight-bold theme-input-border outline-none font-montserrat py-1 px-2 outline-none h-25"
              data-testid="add-questions-abm-theme-input"
            />
          )}
          <div
            className={
              (!eachScore || currentQuestionData?.difficultyId) &&
              'b-radius-8 bg-yellow-warning py-1 w-100 h-50 gray-subtitle'
            }
          >
            {!eachScore ? (
              <p
                className="font-montserrat text-sm fit-content mb-0 font-weight-500 px-2"
                data-testid="add-questions-not-each-score-p"
              >
                {currentQuestionData?.difficultyId
                  ? t('CREATE_TEST_GROUP_VALUE_TO_TEST_TEXT').replace(
                      '{score}',
                      testDifficulty.find((d) => d.id === currentQuestionData?.difficultyId).score
                    )
                  : t('CREATE_TEST_GROUP_SELECT_DIFICULT_TEXT')}
              </p>
            ) : (
              currentQuestionData?.difficultyId && (
                <p
                  className="font-montserrat text-sm fit-content mb-0 font-weight-500 px-2"
                  data-testid="add-questions-each-score-p"
                >
                  {t('CREATE_TEST_GROUP_TEXT_BETWEEN_SCORE').replaceAll(
                    '{score}',
                    testDifficulty.find((d) => d.id === currentQuestionData?.difficultyId).score
                  )}
                </p>
              )
            )}
          </div>
        </div>
      )}
      <div className="d-flex w-100 h-100">
        <div className="w-25 column justify-content-center column align-items-end">
          <div className="empty-buttons" />
          {buttons.map((button, index) =>
            button.show ? (
              button.isPremiumFeature ? (
                <DisabledFeature
                  className="position-relative flex-1 w-auto py-0 d-flex flex-column align-items-end "
                  childrenClassname="position-relative w-100"
                  iconClassName="disabled-icon-add-questions"
                  featureId={PlanFeaturesIds.VIDEO_ANSWERS}
                  validateAmount={false}
                  usedAmount={0}
                >
                  <AnswerTypeButton button={button} index={index} />
                </DisabledFeature>
              ) : (
                <AnswerTypeButton button={button} index={index} />
              )
            ) : null
          )}
          <div className="empty-buttons" />
        </div>
        <div className="w-75 ml-2 pt-4 question-to-add-container d-flex flex-column justify-content-between">
          {[AnswerTypeEnum.WRITE].includes(currentTypeQuestion) && (
            <QuestionWrite
              testGroupABM={testGroupABM}
              disabledTestGroupABMButton={disabledTestGroupABMButton}
              disableQuestionButton={disableQuestionButton}
              handleCustomQuestion={handleCustomQuestion}
            />
          )}
          {[AnswerTypeEnum.TEXT, AnswerTypeEnum.TEXT_AI].includes(currentTypeQuestion) && (
            <QuestionText
              testGroupABM={testGroupABM}
              testAnswer={testAnswer}
              setTestAnswer={setTestAnswer}
              aiImg={<Ai />}
            />
          )}
          {[AnswerTypeEnum.CHECK, AnswerTypeEnum.RADIO].includes(currentTypeQuestion) && (
            <QuestionMultipleChoice
              testGroupABM={testGroupABM}
              eachScore={eachScore}
              setEachScore={setEachScore}
            />
          )}
          {[AnswerTypeEnum.VIDEO].includes(currentTypeQuestion) && (
            <QuestionWithVideoAnswer testGroupABM={testGroupABM} />
          )}
          {[AnswerTypeEnum.FILE].includes(currentTypeQuestion) && (
            <QuestionWithFiles testGroupABM={testGroupABM} />
          )}
          {[AnswerTypeEnum.CODE].includes(currentTypeQuestion) && <QuestionCode testGroupABM />}
          <div className="d-flex justify-content-end">
            {isEdit ? (
              <button
                disabled={testGroupABM ? disabledTestGroupABMButton : disableQuestionButton}
                className={`mt-4 align-self-end px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none ${
                  (testGroupABM ? disabledTestGroupABMButton : disableQuestionButton)
                    ? 'disabled-button'
                    : 'bg-maya-blue'
                }`}
                onClick={() => {
                  handleCustomQuestion(handleEditQuestion);
                }}
                type="button"
                data-testid="add-questions-edit-question-btn"
              >
                {t('CREATE_SCREENING_YOUR_QUESTIONS_EDIT_QUESTION')}
              </button>
            ) : (
              <button
                disabled={testGroupABM ? disabledTestGroupABMButton : disableQuestionButton}
                className={`mt-4 align-self-end  justify-content-end px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none ${
                  (testGroupABM ? disabledTestGroupABMButton : disableQuestionButton)
                    ? 'disabled-button'
                    : 'bg-maya-blue'
                }`}
                onClick={() => {
                  if (track) {
                    TrackEvent(
                      `create-screening-add-${currentTypeQuestion.toLowerCase()}-question`
                    );
                  }
                  handleCustomQuestion(handleAddQuestion);
                  setShowDropdown(true);
                }}
                type="button"
                data-testid="add-questions-create-question-btn"
              >
                {t('CREATE_SCREENING_YOUR_QUESTIONS_SAVE_QUESTION')}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddQuestions;
