import { t } from '../../i18n/i18n';
import { getPriceByValue } from './price';
/**
 * Class representing a feature.
 */
class Feature {
  /**
   * Create a feature.
   * @param {number} id - The unique identifier of the feature.
   * @param {string} name - The name of the feature.
   * @param {string} whitelabelName - The whitelabel name of the feature.
   * @param {number} order - The order of the feature.
   * @param {boolean} hidden - Whether the feature is hidden or not.
   * @param {string} description - The description of the feature.
   */
  constructor(
    name,
    whitelabelName,
    id = 0,
    order,
    hidden = true,
    description = ' ',
    whitelabelDescription = ''
  ) {
    this.name = name;
    this.whitelabelName = whitelabelName;
    this.id = id;
    this.order = order;
    this.hidden = hidden;
    this.description = description;
    this.whitelabelDescription = whitelabelDescription;
  }
}
/**
 * Process and structure features for plans, ensuring uniqueness and filling missing features.
 * @param {Array} plans - The array of plans to process and structure features for.
 * @returns {Array} updatedPlans - The plans array with processed and structured features.
 */
export const processAndStructureFeatures = (plans) => {
  // Step 0: Deep copy the plans array to avoid mutation
  const plansCopy = plans.map((plan) => ({
    ...plan,
    features: plan.features.map((feature) => ({ ...feature })),
  }));

  // Step 1: Extract unique features from plansCopy
  const uniqueFeatures = plansCopy.reduce((acc, plan) => {
    plan.features.forEach((f) => {
      const { feature } = f;
      if (!feature.id) return;
      const existingFeature = acc.find((item) => item.id === feature.id);
      if (!existingFeature) {
        acc.push(feature);
      }
    });
    return acc.sort((a, b) => a.order - b.order);
  }, []);

  // Step 2: Structure features for each plan in plansCopy
  plansCopy.forEach((x) => {
    // Step 2.1: Map features and add empty features for missing ones
    const structuredFeatures = uniqueFeatures.map((u) => {
      const includedFeature = x.features.find((x) => x.featureId === u.id);
      // Step 2.2: Add empty feature with default values for missing features
      const emptyFeature = {
        feature: new Feature(
          u.name,
          u.whitelabelName,
          u.id,
          u.order,
          true, // 'hidden' property set to true
          ' ', // 'description' property set to a space
          ' ' // 'whitelabelDescription' property set to a space
        ),
      };
      if (!includedFeature) return emptyFeature;
      if (u.id === includedFeature.featureId) return includedFeature;
    });

    // Step 2.3: Update plan features in plansCopy with structured features
    x.features = structuredFeatures;
  });

  // Step 3: Return the updated plansCopy array
  return plansCopy;
};

/**
 * Groups features by ID and includes price information for each plan.
 * @param {Array} plans - The array of plans to extract feature values from.
 * @param {Array} features - The array of features to group and structure.
 * @returns {Object} obj - The structured object containing feature information grouped by ID and price.
 */
export const getFeaturesGroupedByIdAndPrice = (plans, features, currency) => {
  // Step 0: Initialize an empty object
  const obj = {};

  // Step 1: Check if features array is empty or undefined, return empty object if true
  if (!features) return obj;

  // Step 2: Iterate through each feature in the features array
  features
    .filter((f) => f?.visible !== 0)
    .forEach((f) => {
      // Step 2.1: Check if the feature ID is not present in the object
      if (!obj[f.order]) {
        // Step 2.2: Create an array of values for the feature (name, and values for each plan)
        obj[f.order] = [
          { value: t(f.whitelabelName), hasAmount: true, isIncludedFeature: true },
          // Step 2.3: Map through plans array to get values for each plan
          ...plans.map((p) => {
            const featureByKey = p.features.find((x) => x.feature.id == f.id);
            // Step 2.4: Check if feature is not found for the plan, return default values
            if (!featureByKey) return { value: null, hasAmount: false, isIncludedFeature: false };
            // Step 2.5: Return values for the feature for the plan
            return {
              hasAmount: featureByKey.feature.hasAmount,
              value: featureByKey.amount,
              isIncludedFeature: true,
            };
          }),
        ];
      }
    });

  // Step 3: Check if the object is not empty
  if (obj) {
    // Step 3.1: Add a 'prices' key to the object with price information for each plan
    obj.prices = [
      { value: t('PRICE_PER_MONTH_LABEL'), hasAmount: true, isIncludedFeature: true },
      // Step 3.2: Map through plans array to get price information for each plan
      ...plans.map((p) => ({
        hasAmount: true,
        value: getPriceByValue(p, currency),
        isIncludedFeature: true,
      })),
    ];
  }

  // Step 4: Return the structured object
  return obj;
};
