import { useEffect, useRef, useState } from 'react';
import CheckIcon from '../../assets/login/CheckIcon';
import SearchIcon from '../../assets/screening/SearchIcon';
import useOnScreen from '../../hooks/useOnScreen';
import { t } from '../../i18n/i18n';
import Spinner from '../spinner/Spinner';
import EmptyComponent from '../EmptyComponent/EmptyComponent';
import GenericToggle from '../GenericToggle/GenericToggle';

const ResetTestgroupStep3 = ({
  handlePreviousStep,
  handleNextStep,
  loading,
  loadingInvitations,
  invitations,
  selectedInstances,
  selectedPrefilterInstances,
  handleSelectInstance,
  fetchSearchInvitations,
  clearSelectedInvitations,
  fetchNextPage,
  restartPrefilters,
  setRestartPrefilter,
}) => {
  const [search, setSearch] = useState('');
  const [fetched, setFetched] = useState(false);
  const fetchNextUrlRef = useRef();
  const fetchNextUrl = useOnScreen(fetchNextUrlRef, '0px', true);

  useEffect(() => {
    fetchSearchInvitations({ search });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetchSearchInvitations({ search });
  };

  useEffect(() => {
    if (!fetchNextUrl) setFetched(false);
    if (
      fetchNextUrl &&
      !fetched &&
      invitations?.collection?.length &&
      invitations?.pagination.nextPage &&
      !loading &&
      !loading
    ) {
      fetchNextPage();
      setFetched(true);
    }
  }, [fetchNextUrl]);

  return (
    <>
      <div className="d-flex flex-column">
        <span className="font-montserrat font-weight-bold text-base mt-2 mb-3">
          {t('RESET_TESTGROUP_STEP_3_TITLE')}
        </span>
        <div className="toggle-reset-testgroup mt-2">
          <GenericToggle
            rightTextClassname="font-roboto"
            leftTextClassName="font-roboto"
            defaultActive={!restartPrefilters}
            actionFunction={() => {
              clearSelectedInvitations();
              setRestartPrefilter(!restartPrefilters);
            }}
            leftText={t('RESER_PREFILTER_TESTS')}
            rightText={t('MENU_ITEM_RESET_TEST_GROUP')}
            disabled={!invitations?.collection?.some((inv) => inv?.prefilterInstanceId)}
          />
        </div>
        <form
          onSubmit={handleSubmit}
          className={`my-1 my-sm-0 rounded transition-2-ms p-2 d-flex align-items-center ml-auto
    ${search?.length ? 'tests-search-input-full bg-white' : 'tests-search-input-empty bg-gray'}`}
        >
          <SearchIcon color={search?.length ? '#1aacf0' : '#424242'} />
          <input
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            className="font-montserrat text-xs bg-transparent border-0 outline-none ml-2"
            placeholder={t('RESET_TESTGROUP_STEP_3_SEARCH_PLACEHOLDER')}
            type="text"
          />
        </form>
        <div className="w-100 d-flex flex-column mt-2 reset-testgroup-lists-container overflow-auto">
          {loading ? (
            <Spinner show />
          ) : !restartPrefilters ? (
            invitations?.collection
              ?.filter((inv) => inv?.screeningInstanceId)
              ?.map((invitation) => (
                <button
                  type="button"
                  key={invitation?.email}
                  onClick={() => handleSelectInstance(invitation)}
                  className={`bg-transparent tech-button-border my-1 px-2 py-3 d-flex justify-content-between align-items-center ${
                    selectedInstances.includes(invitation.screeningInstanceId)
                      ? 'selected-border'
                      : 'unselected-border'
                  }`}
                >
                  <span className="font-montserrat font-weight-bold text-muted text-xs">
                    {invitation.email}
                  </span>
                  {selectedInstances.includes(invitation.screeningInstanceId) && (
                    <CheckIcon className="mr-1" width="19" height="19" />
                  )}
                </button>
              ))
          ) : (
            invitations?.collection
              ?.filter((inv) => inv?.prefilterInstanceId)
              ?.map((invitation) => (
                <button
                  type="button"
                  key={invitation?.email}
                  onClick={() => handleSelectInstance(invitation)}
                  className={`bg-transparent tech-button-border my-1 px-2 py-3 d-flex justify-content-between align-items-center ${
                    selectedPrefilterInstances.includes(invitation.prefilterInstanceId)
                      ? 'selected-border'
                      : 'unselected-border'
                  }`}
                >
                  <span className="font-montserrat font-weight-bold text-muted text-xs">
                    {invitation.email}
                  </span>
                  {selectedPrefilterInstances.includes(invitation.prefilterInstanceId) && (
                    <CheckIcon className="mr-1" width="19" height="19" />
                  )}
                </button>
              ))
          )}
          {!loading && !invitations?.collection?.length && (
            <EmptyComponent
              title={t('RESET_TESTGROUP_STEP_3_EMPTY_STATE_TITLE')}
              className="my-3 align-items-center py-3"
            />
          )}
          {loadingInvitations && <Spinner show />}
          <div ref={fetchNextUrlRef} />
        </div>
      </div>
      <div className="d-flex flex-grow-1 justify-content-between">
        <button
          onClick={() => {
            clearSelectedInvitations();
            handlePreviousStep();
          }}
          className="align-self-end mt-4 px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none bg-maya-blue"
          type="button"
        >
          {t('RESET_TESTGROUP_BACK_BUTTON')}
        </button>
        <button
          onClick={handleNextStep}
          disabled={!selectedInstances.length && !selectedPrefilterInstances.length}
          className={`align-self-end mt-4 px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none ${
            !selectedInstances.length && !selectedPrefilterInstances.length
              ? 'bg-disabled'
              : 'bg-maya-blue'
          }`}
          type="button"
        >
          {t('RESET_TESTGROUP_NEXT_BUTTON')}
        </button>
      </div>
    </>
  );
};

export default ResetTestgroupStep3;
