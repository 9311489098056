const EnvelopeOutlinedIcon = ({ className }) => (
  <svg
    className={className}
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.66667 2C2.3 2 2 2.3 2 2.66667V3.5875L9.1875 9.4875C10.05 10.1958 11.2875 10.1958 12.15 9.4875L19.3333 3.5875V2.66667C19.3333 2.3 19.0333 2 18.6667 2H2.66667ZM2 6.175V13.3333C2 13.7 2.3 14 2.66667 14H18.6667C19.0333 14 19.3333 13.7 19.3333 13.3333V6.175L13.4167 11.0333C11.8167 12.3458 9.5125 12.3458 7.91667 11.0333L2 6.175ZM0 2.66667C0 1.19583 1.19583 0 2.66667 0H18.6667C20.1375 0 21.3333 1.19583 21.3333 2.66667V13.3333C21.3333 14.8042 20.1375 16 18.6667 16H2.66667C1.19583 16 0 14.8042 0 13.3333V2.66667Z" />
  </svg>
);

export default EnvelopeOutlinedIcon;
