import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { Window } from '../../utils/window';
import UserIcon from '../../assets/login/UserIcon';
import MailIcon from '../../assets/login/MailIcon';
import PasswordIcon from '../../assets/login/PasswordIcon';
import gmailIcon from '../../assets/login/gmail-icon.svg';
import LoginInput from '../LoginInput/LoginInput';
import { auth0Client } from '../../auth0/auth0';
import loginValidations from '../../hooks/validations/login/loginValidations';
import { t } from '../../i18n/i18n';
import './LoginForm.scss';
import { setLoginLoading } from '../../redux/actions/authActions';
import Modal from '../Modal/Modal';
import Spinner from '../spinner/Spinner';
import useTermsAndConditions from '../../hooks/useTermsAndConditions';
import PDFViewer from '../PDFViewer/PDFViewer';
import { FORGOTPASSWORD, SIGNIN } from '../../constants/routes';
import { getGoogleValidAccount } from '../../API/services/alkymersService';

const LoginForm = ({ haveAccount, recoverPassword }) => {
  const history = useHistory();
  const [loginErrors, setLoginErrors] = useState({});
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    email: '',
    password: '',
    name: '',
    lastName: '',
    verifypassword: '',
  });
  const [error, setError] = useState('');
  const { validationsFn } = loginValidations(t);
  const validatePasswordValidation = Boolean(
    user.password.length && user.verifypassword.length && user.password !== user.verifypassword
  );

  useEffect(() => {
    setError('');
    setLoginErrors({});
    setUser({ email: '', password: '', name: '', lastName: '', verifypassword: '' });
  }, [haveAccount, recoverPassword]);

  const onChange = (e) => {
    setError('');

    if (validationsFn(e.target.name, e.target.value)?.length > 0) {
      setLoginErrors({
        ...loginErrors,
        [e.target.name]: validationsFn(e.target.name, e.target.value),
      });
    } else {
      delete loginErrors[e.target.name];
    }
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const login = async () => {
    if (Window()) {
      dispatch(setLoginLoading(true));
      auth0Client().login(
        {
          realm: 'Username-Password-Authentication',
          username: user.email,
          password: user.password,
        },
        (e) => {
          dispatch(setLoginLoading(false));
          setError(e?.description || '');
        }
      );
    }
  };

  const loginWithGoogle = () => {
    if (Window()) {
      auth0Client().authorize({
        connection: 'google-oauth2',
      });
    }
  };

  const handleRecover = async () => {
    if (Window()) {
      getGoogleValidAccount(user.email)
        .then((res) => {
          if (!res.data) {
            notification.open({
              message: t('ERROR_GOOGLE_ACCOUNT'),
              type: 'error',
            });
          } else {
            auth0Client().changePassword(
              {
                connection: 'Username-Password-Authentication',
                email: user.email,
              },
              (err) => {
                if (err) {
                  notification.open({
                    message: t('RECOVER_PASSWORD_ERROR'),
                    type: 'error',
                  });
                } else {
                  notification.open({
                    message: t('RECOVER_PASSWORD_SUCCESS'),
                    type: 'success',
                  });
                }
              }
            );
          }
        })
        .catch(() => {
          notification.open({
            message: t('RECOVER_PASSWORD_ERROR'),
            type: 'error',
          });
        });
    }
  };

  const register = () => {
    if (Window()) {
      dispatch(setLoginLoading(true));
      auth0Client().redirect.signupAndLogin(
        {
          connection: 'Username-Password-Authentication',
          username: user?.email,
          email: user?.email,
          password: user?.password,
          given_name: user?.name,
          family_name: user?.lastName,
        },
        () => {
          dispatch(setLoginLoading(false));
          notification.open({
            message: t('REGISTER_ERROR'),
            type: 'error',
            description: t('REGISTER_ERROR_TEXT'),
          });
        }
      );
    }
  };

  const {
    showModal,
    setShowModal,
    termsAndConditionsLoading,
    termsAndConditionsData,
    acceptedTermsAndConditions,
    setAcceptedTermsAndConditions,
    showTermsAndConditionsModal,
    closeModal,
  } = useTermsAndConditions({ platform: 'PARTNERS' });

  const resetForm = () => {
    setAcceptedTermsAndConditions(false);
    setUser({
      email: '',
      password: '',
      name: '',
      lastName: '',
      verifypassword: '',
    });
  };

  return haveAccount ? (
    recoverPassword ? (
      <form
        onSubmit={(e) => e.preventDefault()}
        className="d-flex login-form-container mt-2 mx-auto flex-column justify-content-start align-items-center"
      >
        <span
          className="text-center mb-4 font-montserrat text-sm"
          data-testid="recover-password-disclaimer-span"
        >
          {t('RECOVER_PASSWORD_DISCLAIMER')}
        </span>
        <LoginInput
          placeholder={t('EMAIL_PLACEHOLDER')}
          name="email"
          value={user?.email}
          onChange={onChange}
          error={loginErrors?.email && loginErrors?.email[0]}
          Icon={MailIcon}
        />
        <div className="d-flex justify-content-around w-100">
          <button
            data-testid="RECOVER_PASSWORD_CANCEL"
            onClick={() => {
              resetForm();
              history.push(SIGNIN);
            }}
            type="button"
            className="bg-transparent font-weight-500 font-montserrat text-sm mt-3 mt-md-0 text-maya-blue border-0"
          >
            {t('CANCEL')}
          </button>
          <button
            data-testid="RECOVER_PASSWORD_SEND_EMAIL"
            onClick={() => {
              handleRecover();
            }}
            type="submit"
            disabled={!user?.email || !!loginErrors?.email}
            className={`rounded-pill border-0 text-white text-sm font-weight-bold font-montserrat login-form-button ${
              !user?.email || !!loginErrors?.email ? 'bg-maya-blue disabled' : 'bg-maya-blue'
            }`}
          >
            {t('RECOVER_PASSWORD_SEND_EMAIL')}
          </button>
        </div>
      </form>
    ) : (
      <form
        onSubmit={(e) => e.preventDefault()}
        className="d-flex login-form-container mt-1 mx-auto flex-column justify-content-start align-items-center"
      >
        <LoginInput
          placeholder={t('EMAIL_PLACEHOLDER')}
          name="email"
          value={user?.email}
          onChange={onChange}
          error={loginErrors?.email && loginErrors?.email[0]}
          Icon={MailIcon}
        />
        <LoginInput
          placeholder={t('PASSWORD_PLACEHOLDER')}
          type="password"
          name="password"
          value={user?.password}
          Icon={PasswordIcon}
          onChange={onChange}
          isPassword
        />
        <div className="w-100">
          <button
            data-testid="FORGOT_MY_PASSWORD_BUTTON"
            onClick={() => {
              history.push(FORGOTPASSWORD);
              resetForm();
            }}
            type="button"
            className="bg-transparent font-weight-500 font-montserrat text-sm text-maya-blue border-0"
          >
            {t('FORGOT_MY_PASSWORD')}
          </button>
        </div>
        {error && (
          <span
            className="danger-text font-montserrat m-0 mt-3"
            data-testid="login-error-wrong-fields-span"
          >
            ⚠️ {t('WRONG_EMAIL_OR_PASSWORD')}
          </span>
        )}
        <div className="d-flex flex-column-reverse mt-4 w-100">
          <button
            type="button"
            onClick={loginWithGoogle}
            className="font-weight-500 d-flex justify-content-center align-items-center mt-3 w-100 register-with-google-btn rounded-pill text-sm font-montserrat login-form-button"
            data-testid="ly-register-with-google-btn"
          >
            <img
              src={gmailIcon}
              alt={t('GMAIL_ICON')}
              width="18"
              height="18"
              className="mr-2"
              data-testid="ly-register-with-google-img"
            />
            {t('REGISTER_WITH_GOOGLE')}
          </button>
          <button
            data-testid="LOGIN_BUTTON"
            onClick={() => {
              login();
            }}
            type="submit"
            disabled={
              !user?.email ||
              !user?.password ||
              (loginErrors?.email && loginErrors?.email[0]) ||
              error
            }
            className={`rounded-pill border-0 text-white text-sm font-weight-bold font-montserrat login-form-button ${
              !user?.email ||
              !user?.password ||
              (loginErrors?.email && loginErrors?.email[0]) ||
              error
                ? 'bg-maya-blue disabled'
                : 'bg-maya-blue '
            }`}
          >
            {t('LOGIN_IN_TITLE')}
          </button>
        </div>
      </form>
    )
  ) : (
    <>
      <Modal
        className="h-100 pt-3 pb-2 px-4 modal-tac-container b-radius-8"
        show={showModal}
        setShow={setShowModal}
        closeBtnClassname="text-maya-blue modal-close-icon"
      >
        <>
          {termsAndConditionsLoading ? (
            <Spinner show />
          ) : termsAndConditionsData ? (
            <div className="d-flex flex-column h-100 py-4">
              <span
                className="font-montserrat font-weight-600 text-md mb-2"
                data-testid="terms-and-conditions-title"
              >
                {t('TERMS_AND_CONDITIONS_MODAL_TITLE')}
              </span>
              <PDFViewer pdf={termsAndConditionsData?.link} />
              <button
                type="button"
                onClick={() => closeModal()}
                className="rounded-pill border-0 text-white px-4 text-sm font-weight-500 font-montserrat login-form-button bg-maya-blue w-fit close-modal-button mt-3"
                data-testid="terms-and-conditions-back-btn"
              >
                {t('TERMS_AND_CONDITIONS_GO_BACK')}
              </button>
            </div>
          ) : (
            <span data-testid="terms-and-conditions-cannot-loading-span">
              {t('CANNOT_LOAD_TERMS_AND_CONDITIONS')}
            </span>
          )}
        </>
      </Modal>
      <form
        onSubmit={(e) => e.preventDefault()}
        className="d-flex login-form-container mt-2 mx-auto flex-column justify-content-start align-items-center "
      >
        <div className="d-flex">
          <LoginInput
            className="mr-1"
            placeholder={t('PROFILE_NAME')}
            value={user?.name}
            name="name"
            onChange={onChange}
            error={loginErrors?.name && loginErrors?.name[0]}
            Icon={UserIcon}
          />
          <LoginInput
            className="ml-1"
            placeholder={t('PROFILE_LASTNAME')}
            value={user?.lastName}
            name="lastName"
            onChange={onChange}
            error={loginErrors?.lastName && loginErrors?.lastName[0]}
            Icon={UserIcon}
          />
        </div>
        <LoginInput
          placeholder={t('EMAIL_PLACEHOLDER')}
          value={user?.email}
          name="email"
          onChange={onChange}
          error={loginErrors?.email && loginErrors?.email[0]}
          Icon={MailIcon}
        />
        <LoginInput
          placeholder={t('PASSWORD_PLACEHOLDER')}
          name="password"
          value={user?.password}
          type="password"
          error={
            (loginErrors?.password &&
              loginErrors?.password?.length > 1 &&
              loginErrors?.password[0]) ||
            (Boolean(user?.password?.length && user?.password?.length < 8) &&
              t('PASSWORD_VALIDATION_LENGTH'))
          }
          onChange={onChange}
          Icon={PasswordIcon}
          isPassword
        />
        {/* TOOD: Refactor after checking Hubspot Docs in detail */}
        <label hidden htmlFor="verifypassword">
          password
        </label>
        <LoginInput
          placeholder={t('VERIFY_PASSWORD_PLACEHOLDER')}
          name="verifypassword"
          value={user?.verifypassword}
          type="password"
          error={
            (loginErrors?.verifypassword && loginErrors?.verifypassword[0]) ||
            (validatePasswordValidation && t('VERIFY_PASSWORD_DOES_NOT_MATCH'))
          }
          onChange={onChange}
          Icon={PasswordIcon}
          isPassword
        />
        <div className="d-flex mt-1 w-100 p-0">
          <input
            data-testid="TERMS_AND_CONDITIONS_CHECKBOX"
            className="cursor-pointer"
            type="checkbox"
            onChange={() => setAcceptedTermsAndConditions((state) => !state)}
            value={acceptedTermsAndConditions}
            defaultChecked={acceptedTermsAndConditions}
          />
          <span className="ml-2 font-montserrat text-sm text-gray">
            {t('HAVE_READ_AND_ACCEPTED')}
            <button
              data-testid="TERMS_AND_CONDITIONS_BUTTON"
              type="button"
              onClick={() => showTermsAndConditionsModal()}
              className="ml-1 border-0 cursor-pointer text-purple bg-transparent"
            >
              {t('TERMS_AND_CONDITIONS')}
            </button>
          </span>
        </div>
        <div className="d-flex flex-column-reverse mt-3 w-100">
          <div className="text-center">
            <button
              type="button"
              onClick={loginWithGoogle}
              className="font-weight-500 d-flex justify-content-center align-items-center mt-3 w-100 register-with-google-btn rounded-pill text-sm font-montserrat login-form-button"
              data-testid="ly-register-with-google-btn"
            >
              <img
                src={gmailIcon}
                alt={t('GMAIL_ICON')}
                width="18"
                height="18"
                className="mr-2"
                data-testid="ly-register-with-google-img"
              />
              {t('REGISTER_WITH_GOOGLE')}
            </button>
          </div>
          <button
            data-testid="REGISTER_BUTTON"
            onClick={() => {
              register();
            }}
            type="submit"
            disabled={
              !user?.email ||
              !user?.password ||
              !user?.verifypassword ||
              user?.password !== user?.verifypassword ||
              !user?.name ||
              !user.lastName ||
              !!loginErrors?.email ||
              loginErrors?.password?.length > 1 ||
              user?.password?.length < 8 ||
              !!loginErrors?.name ||
              !!loginErrors?.lastName ||
              !acceptedTermsAndConditions
            }
            className={`rounded-pill border-0 text-white text-sm font-weight-bold font-montserrat login-form-button ${
              !user?.email ||
              !user?.password ||
              !user?.verifypassword ||
              user?.password !== user?.verifypassword ||
              !user?.name ||
              !user.lastName ||
              !!loginErrors?.email ||
              loginErrors?.password?.length > 1 ||
              user?.password?.length < 8 ||
              !!loginErrors?.name ||
              !!loginErrors?.lastName ||
              !acceptedTermsAndConditions
                ? 'bg-maya-blue disabled'
                : 'bg-maya-blue'
            }`}
          >
            {t('CREATE_ACCOUNT')}
          </button>
        </div>
      </form>
    </>
  );
};

export default LoginForm;
