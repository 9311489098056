import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import sidebarRoutes from '../constants/sidebarRoutes';

const useWhiteLabelSidebar = () => {
  const { whitelabel } = useSelector((state) => state.auth);
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    if (whitelabel?.routes) {
      const filteredRoutes = sidebarRoutes.filter((route) =>
        whitelabel?.routes?.includes(route.key)
      );
      setRoutes(filteredRoutes);
    }
  }, [whitelabel]);

  return {
    routes,
  };
};

export default useWhiteLabelSidebar;
