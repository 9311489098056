import ARE from './ARE.svg';
import ARG from './ARG.svg';
import ARM from './ARM.svg';
import AUS from './AUS.svg';
import AUT from './AUT.svg';
import BEL from './BEL.svg';
import BGD from './BGD.svg';
import BGR from './BGR.svg';
import BOL from './BOL.svg';
import BRA from './BRA.svg';
import CAN from './CAN.svg';
import CHE from './CHE.svg';
import CHL from './CHL.svg';
import CHN from './CHN.svg';
import COG from './COG.svg';
import COL from './COL.svg';
import CRI from './CRI.svg';
import CUB from './CUB.svg';
import CZE from './CZE.svg';
import DEU from './DEU.svg';
import ECU from './ECU.svg';
import EGY from './EGY.svg';
import ENG from './ENG.svg';
import ESP from './ESP.svg';
import EST from './EST.svg';
import EU from './EU.svg';
import FIN from './FIN.svg';
import FRA from './FRA.svg';
import GAB from './GAB.svg';
import GBR from './GBR.svg';
import GIN from './GIN.svg';
import GRC from './GRC.svg';
import HUN from './HUN.svg';
import IDN from './IDN.svg';
import IND from './IND.svg';
import IRL from './IRL.svg';
import ISR from './ISR.svg';
import ITA from './ITA.svg';
import JPN from './JPN.svg';
import KOR from './KOR.svg';
import LTU from './LTU.svg';
import LUX from './LUX.svg';
import LVA from './LVA.svg';
import MDG from './MDG.svg';
import MEX from './MEX.svg';
import MLI from './MLI.svg';
import NGA from './NGA.svg';
import NLD from './NLD.svg';
import NOR from './NOR.svg';
import PAK from './PAK.svg';
import PER from './PER.svg';
import PHL from './PHL.svg';
import POL from './POL.svg';
import PRI from './PRI.svg';
import PRY from './PRY.svg';
import PRT from './PRT.svg';
import ROU from './ROU.svg';
import RUS from './RUS.svg';
import SWE from './SWE.svg';
import THA from './THA.svg';
import TUR from './TUR.svg';
import UKR from './UKR.svg';
import URY from './URY.svg';
import USA from './USA.svg';
import VEN from './VEN.svg';
import VNM from './VNM.svg';
import YEM from './YEM.svg';
import BRB from './BRB.svg';
import DMA from './DMA.svg';
import DOM from './DOM.svg';
import GRD from './GRD.svg';
import GTM from './GTM.svg';
import GUY from './GUY.svg';
import HND from './HND.svg';
import HTI from './HTI.svg';
import JAM from './JAM.svg';
import LCA from './LCA.svg';
import NIC from './NIC.svg';
import PAN from './PAN.svg';
import SLV from './SLV.svg';
import SUR from './SUR.svg';
import TCA from './TCA.svg';
import TTO from './TTO.svg';
import ABW from './ABW.svg';
import ATG from './ATG.svg';
import BHS from './BHS.svg';
import BLZ from './BLZ.svg';

import UNKNOWN from './UNKNOWN.svg';

export default {
  ARE,
  ARG,
  ARM,
  AUS,
  AUT,
  BEL,
  BGD,
  BGR,
  BOL,
  BRA,
  CAN,
  CHE,
  CHL,
  CHN,
  COG,
  COL,
  CUB,
  CZE,
  DEU,
  ECU,
  EGY,
  ENG,
  ESP,
  EST,
  EU,
  FIN,
  FRA,
  GAB,
  GBR,
  GIN,
  GRC,
  HUN,
  IDN,
  IND,
  IRL,
  ISR,
  ITA,
  JPN,
  KOR,
  LTU,
  LUX,
  LVA,
  MDG,
  MEX,
  MLI,
  NGA,
  NLD,
  NOR,
  PAK,
  PER,
  PHL,
  POL,
  PRI,
  PRY,
  PRT,
  ROU,
  RUS,
  SWE,
  THA,
  TUR,
  UKR,
  URY,
  USA,
  VEN,
  VNM,
  YEM,
  BRB,
  CRI,
  DMA,
  DOM,
  GRD,
  GTM,
  GUY,
  HND,
  HTI,
  JAM,
  LCA,
  NIC,
  PAN,
  SLV,
  SUR,
  TCA,
  TTO,
  ABW,
  ATG,
  BHS,
  BLZ,
  UNKNOWN,
};
