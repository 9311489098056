const EnvelopeIcon = ({ fill }) => (
  <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.9742 3.9625C16.0961 3.86563 16.2773 3.95625 16.2773 4.10938V10.5C16.2773 11.3281 15.6055 12 14.7773 12H1.77734C0.949219 12 0.277344 11.3281 0.277344 10.5V4.1125C0.277344 3.95625 0.455469 3.86875 0.580469 3.96562C1.28047 4.50937 2.20859 5.2 5.39609 7.51562C6.05547 7.99687 7.16797 9.00938 8.27734 9.00313C9.39297 9.0125 10.5273 7.97813 11.1617 7.51562C14.3492 5.2 15.2742 4.50625 15.9742 3.9625ZM8.27734 8C9.00234 8.0125 10.0461 7.0875 10.5711 6.70625C14.718 3.69688 15.0336 3.43437 15.9898 2.68437C16.1711 2.54375 16.2773 2.325 16.2773 2.09375V1.5C16.2773 0.671875 15.6055 0 14.7773 0H1.77734C0.949219 0 0.277344 0.671875 0.277344 1.5V2.09375C0.277344 2.325 0.383594 2.54062 0.564844 2.68437C1.52109 3.43125 1.83672 3.69688 5.98359 6.70625C6.50859 7.0875 7.55234 8.0125 8.27734 8Z"
      fill={fill}
    />
  </svg>
);

export default EnvelopeIcon;
