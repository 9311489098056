const hexToRgb = (hex) =>
  hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
    .substring(1)
    .match(/.{2}/g)
    ?.map((x) => parseInt(x, 16))
    .join(',') ?? '#000';

export const getTextColor = (hexColor) => {
  const rgba = hexToRgb(hexColor);
  const rgbaArr = rgba.match(/\d+/g);

  if (rgbaArr) {
    return Number(rgbaArr[0]) * 0.299 + Number(rgbaArr[1]) * 0.587 + Number(rgbaArr[2]) * 0.114 >
      186
      ? '#000000'
      : '#ffffff';
  }
  return '#ffffff';
};
