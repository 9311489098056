import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { t } from '../../i18n/i18n';
import { capitalizeString } from '../../utils/stringUtils';
import codeIcon from '../../assets/savedSearch/code-icon.svg';
import trashIcon from '../../assets/savedSearch/trash-icon.svg';
import { processQueryStringAmpersand } from '../../utils/processQueryString';
import { setAgeQuery, setAppliedFilters } from '../../redux/actions/profilesActions';
import './SearchItem.css';

const SearchItem = ({ search, handleDelete }) => {
  const dispatch = useDispatch();
  const searchData = Object.entries(processQueryStringAmpersand(search?.queryString))
    .map((item) => ({
      type: item[0],
      id: item[1],
      name:
        item[0] === 'country' ||
        item[0] === 'institutes' ||
        item[0] === 'secondary_technologies' ||
        item[0] === 'technologies'
          ? capitalizeString(search[item[0]]?.toString())
          : item[0] === 'english_level'
          ? t(`LANGUAGES_LEVELS_${search[item[0]]?.toString()?.toUpperCase()}`)
          : t(
              `PROFILES_FILTER_${item[0]?.toUpperCase()}_${search[item[0]]
                ?.toString()
                ?.toUpperCase()}`
            ),
    }))
    .filter((item) => item.type !== 'max_age' && item.type !== 'min_age');
  const history = useHistory();
  const handleSearch = () => {
    if (search.min_age && search.max_age) {
      dispatch(setAgeQuery({ min: search.min_age, max: search.max_age }));
    }
    dispatch(setAppliedFilters(searchData));
    history.push('/profiles');
  };

  return (
    <div className="px-3 py-3 bg-white position-relative d-flex flex-column search-container rounded mb-4 w-100">
      <button
        className="position-absolute right-0 mr-2 bg-transparent border-0 outline-none"
        type="button"
        onClick={() => handleDelete(search.id)}
      >
        <img src={trashIcon} alt={t('ICON_DELETE_SEARCH')} />
      </button>
      <span className="font-montserrat font-weight-bold text-base mt-2">
        {search.name ?? 'titulo'}
      </span>
      <ul className="list-unstyled saved-filters-list ml-4">
        {search.min_age && search.max_age && (
          <li className="font-weight-bold font-montserrat text-sm">
            {`${t('SAVED_SEARCH_AGE')}: `}
            <span className="font-weight-normal font-montserrat">
              {search.min_age} a {search.max_age}
            </span>
          </li>
        )}
        {!!search?.status?.length && (
          <li className="font-weight-bold font-montserrat">
            {`${t('SAVED_SEARCH_STATUS')}: `}
            <span className="font-weight-normal font-montserrat">
              {t(`PROFILES_FILTER_STATUS_${search?.status[0]}`)}
            </span>
          </li>
        )}
        {!!search?.academic_level?.length && (
          <li className="font-weight-bold font-montserrat">
            {`${t('SAVED_SEARCH_ACADEMIC_LEVEL')}: `}
            <span className="font-weight-normal font-montserrat">
              {t(`PROFILES_FILTER_ACADEMIC_LEVEL_${search?.academic_level[0]?.toUpperCase()}`)}
            </span>
          </li>
        )}
        {!!search?.advance_status?.length && (
          <li className="font-weight-bold font-montserrat">
            {`${t('SAVED_SEARCH_ADVANCE_STATUS')}: `}
            <span className="font-weight-normal font-montserrat">
              {t(`PROFILES_FILTER_ADVANCE_STATUS_${search?.advance_status[0]}`)}
            </span>
          </li>
        )}
        {!!search?.institutes?.length && (
          <li className="font-weight-bold font-montserrat">
            {`${t('SAVED_SEARCH_INSTITUTE')}: `}
            <span className="font-weight-normal font-montserrat">
              {capitalizeString(search?.institutes[0])}
            </span>
          </li>
        )}
        {!!search?.technologies?.length && (
          <li className="font-weight-bold font-montserrat">
            {`${t('SAVED_SEARCH_TECHNOLOGY')}: `}
            <span className="font-weight-normal font-montserrat">
              {capitalizeString(search?.technologies[0])}
            </span>
          </li>
        )}
        {!!search?.secondary_technologies?.length && (
          <li className="font-weight-bold font-montserrat">
            {`${t('SAVED_SEARCH_SECONDARY_TECHNOLOGY')}: `}
            <span className="font-weight-normal font-montserrat">
              {capitalizeString(search?.secondary_technologies[0])}
            </span>
          </li>
        )}
        {!!search?.country?.length && (
          <li className="font-weight-bold font-montserrat">
            {`${t('SAVED_SEARCH_LOCATION')}: `}
            <span className="font-weight-normal font-montserrat">
              {capitalizeString(search?.country[0])}
            </span>
          </li>
        )}
        {!!search?.english_level?.length && (
          <li className="font-weight-bold font-montserrat">
            {`${t('SAVED_SEARCH_ENGLISH_LEVEL')}: `}
            <span className="font-weight-normal font-montserrat">
              {t(`${search?.english_level[0]?.toUpperCase()}`)}
            </span>
          </li>
        )}
        {!!search?.sex?.length && (
          <li className="font-weight-bold font-montserrat">
            {`${t('SAVED_SEARCH_GENRE')}: `}
            <span className="font-weight-normal font-montserrat">
              {t(`PROFILES_FILTER_SEX_${search?.sex[0]?.toUpperCase()}`)}
            </span>
          </li>
        )}
      </ul>
      <div className="w-100 h-100 d-flex align-items-end justify-content-end">
        <button 
          className="mt-4 align-self-end px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none bg-maya-blue"
          onClick={handleSearch}
          type="button"
        >
          <img src={codeIcon} alt={t('ICON_CODE')} className="mr-1" />
          {t('SAVED_SEARCH_GO_TO_SEARCH')}
        </button>
      </div>
    </div>
  );
};
export default SearchItem;
