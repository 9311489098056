import { useEffect } from 'react';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import { t } from '../../i18n/i18n';
import CreateEvaluationSecondStep from '../../components/CreateEvaluationSecondStep/CreateEvaluationSecondStep';
import useCreateEvaluation from '../../hooks/useCreateEvaluation';

const Tests = () => {
  const { loadingTests, fetchTests, handleClearData } = useCreateEvaluation();
  const isTestsPage = true;
  useEffect(
    () => () => {
      handleClearData(false);
    },
    []
  );
  return (
    <div
      data-testid="tests-page-container"
      className="w-100 mx-auto pl-3 pr-3 create-evaluation-container h-100`"
    >
      <div>
        <BackgroundTop
          height="3rem"
          title={t('TEST_CATALOG')}
          routes={[
            {
              text: 'MENU_ITEM_SCREENING',
              clickable: false,
            },
          ]}
          className="position-relative"
          titleClassname="text-xl"
        />
      </div>
      <div
        className={`position-relative new-search-container px-3 py-2 bg-white d-flex w-100 flex-column rounded mx-auto
          }`}
      >
        <div className="mt-2 flex-grow-1 d-flex flex-column">
          <CreateEvaluationSecondStep
            loadingTests={loadingTests}
            fetchTests={fetchTests}
            isTestsPage={isTestsPage}
          />
        </div>
      </div>
    </div>
  );
};

export default Tests;
