import React, { useEffect, useRef } from 'react';

export const AutoScroll = ({ isDragging, scrollSpeed = 10, scrollAreaHeight = 200 }) => {
  const scrollRef = useRef();

  useEffect(() => {
    if (isDragging) {
      const handleMouseMove = (event) => {
        const { clientY } = event;
        const { innerHeight } = window;

        if (clientY < scrollAreaHeight) {
          // Si el mouse está en la parte superior de la pantalla
          window.scrollBy(0, -scrollSpeed);
        } else if (clientY > innerHeight - scrollAreaHeight) {
          // Si el mouse está en la parte inferior de la pantalla
          window.scrollBy(0, scrollSpeed);
        }
      };

      document.addEventListener('mousemove', handleMouseMove);

      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, [isDragging, scrollSpeed, scrollAreaHeight]);

  return <div ref={scrollRef} />;
};
