import { useEffect } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { Window } from '../../utils/window';

const BlockRedirection = ({
  children,
  route,
  message,
  conditionToBlock,
  updateConditionToBlock,
}) => {
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname !== route) {
      history.replace(route);
    }

    return () => {
      if (Window()) {
        window.onbeforeunload = () => {};
      }
    };
  }, [updateConditionToBlock && conditionToBlock]);

  useEffect(() => {
    if (Window() && conditionToBlock) {
      window.onbeforeunload = (event) => {
        event.returnValue = message;
      };
    }
  }, [conditionToBlock]);

  return (
    <>
      <Prompt message={message} when={conditionToBlock} />
      {children}
    </>
  );
};

export default BlockRedirection;
