import { useState } from 'react';
import { notification } from 'antd';
import buttonEditImage from '../../assets/profile/button-edit-image.svg';
import profileImageNull from '../../assets/profile/profile.png';
import { t } from '../../i18n/i18n';

export const UploadFile = ({ onUpload, defaultImg }) => {
  const [newFile, setNewFile] = useState(null);

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    onUpload(file);
    const fmData = new FormData();
    fmData.append('file', e.target.files[0]);
    if (['image/png', 'image/jpeg', 'image/jpg'].includes(e.target.files[0]?.type)) {
      setNewFile(URL.createObjectURL(file));
    } else {
      notification.open({
        message: t('IMAGE_FORMAT_INVALID'),
        type: 'error',
      });
    }
  };
  return (
    <div className="d-flex flex-column position-relative mt-4" style={{ width: 'fit-content' }}>
      <img
        className="user-heading-img rounded-circle p-1"
        src={newFile || defaultImg || profileImageNull}
        alt={t('PROFILE_MENU_LOGO_USER')}
      />
      <div className="image-upload position-absolute bottom-0 right-0">
        <label htmlFor="file-input-company" className="m-0 p-0">
          <img
            src={buttonEditImage}
            className="button-edit-image-profile cursor-pointer"
            alt={t('EDIT_ALT')}
          />
        </label>
        <input
          id="file-input-company"
          type="file"
          accept="image/x-png,image/jpeg"
          onChange={uploadImage}
        />
      </div>
    </div>
  );
};
