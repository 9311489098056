import React from 'react';

const ImageFileIcon = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 12H1.5C0.671562 12 0 11.3284 0 10.5V1.5C0 0.671562 0.671562 0 1.5 0H14.5C15.3284 0 16 0.671562 16 1.5V10.5C16 11.3284 15.3284 12 14.5 12ZM3.5 1.75C2.5335 1.75 1.75 2.5335 1.75 3.5C1.75 4.4665 2.5335 5.25 3.5 5.25C4.4665 5.25 5.25 4.4665 5.25 3.5C5.25 2.5335 4.4665 1.75 3.5 1.75ZM2 10H14V6.5L11.2652 3.76516C11.1187 3.61872 10.8813 3.61872 10.7348 3.76516L6.5 8L4.76516 6.26516C4.61872 6.11872 4.38128 6.11872 4.23481 6.26516L2 8.5V10Z"
      fill="#41479B"
    />
  </svg>
);

export default ImageFileIcon;
