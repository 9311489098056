const Bug = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.9727 8.65234C15 8.16016 14.5898 7.75 14.125 7.75H12.5938V6.16406L13.8516 4.87891C14.207 4.55078 14.207 3.97656 13.8516 3.64844C13.5234 3.29297 12.9492 3.29297 12.6211 3.64844L11.1172 5.125H4.85547L3.35156 3.64844C3.02344 3.29297 2.44922 3.29297 2.12109 3.64844C1.76562 3.97656 1.76562 4.55078 2.12109 4.87891L3.40625 6.16406V7.75H1.875C1.38281 7.75 0.972656 8.16016 1 8.65234C1 9.14453 1.41016 9.5 1.875 9.5H3.40625V9.9375C3.40625 10.5391 3.51562 11.1133 3.76172 11.6328L2.12109 13.2734C1.76562 13.6016 1.76562 14.1758 2.12109 14.5039C2.44922 14.8594 3.02344 14.8594 3.35156 14.5039L4.85547 13C5.53906 13.5469 6.38672 13.875 7.34375 13.875V7.20312C7.34375 7.03906 7.48047 6.875 7.67188 6.875H8.32812C8.49219 6.875 8.65625 7.03906 8.65625 7.20312V13.875C9.58594 13.875 10.4336 13.5469 11.1172 13L12.6211 14.5039C12.9492 14.8594 13.5234 14.8594 13.8516 14.5039C14.207 14.1758 14.207 13.6016 13.8516 13.2734L12.2109 11.6328C12.457 11.1133 12.5938 10.5391 12.5938 9.9375V9.5H14.0977C14.5625 9.5 14.9727 9.14453 14.9727 8.65234ZM8.02734 0.75C6.33203 0.75 4.96484 2.14453 4.96484 3.8125H11.0898C11.0898 2.14453 9.69531 0.75 8.02734 0.75Z"
      fill="#828282"
    />
  </svg>
);

export default Bug;
