const MembersIcon = () => (
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.15 0C3.61413 0 4.05925 0.184374 4.38744 0.512563C4.71563 0.840752 4.9 1.28587 4.9 1.75C4.9 2.21413 4.71563 2.65925 4.38744 2.98744C4.05925 3.31563 3.61413 3.5 3.15 3.5C2.68587 3.5 2.24075 3.31563 1.91256 2.98744C1.58437 2.65925 1.4 2.21413 1.4 1.75C1.4 1.28587 1.58437 0.840752 1.91256 0.512563C2.24075 0.184374 2.68587 0 3.15 0ZM11.2 0C11.6641 0 12.1092 0.184374 12.4374 0.512563C12.7656 0.840752 12.95 1.28587 12.95 1.75C12.95 2.21413 12.7656 2.65925 12.4374 2.98744C12.1092 3.31563 11.6641 3.5 11.2 3.5C10.7359 3.5 10.2908 3.31563 9.96256 2.98744C9.63437 2.65925 9.45 2.21413 9.45 1.75C9.45 1.28587 9.63437 0.840752 9.96256 0.512563C10.2908 0.184374 10.7359 0 11.2 0ZM0 6.53406C0 5.24563 1.04562 4.2 2.33406 4.2H3.26812C3.61594 4.2 3.94625 4.27656 4.24375 4.41219C4.21531 4.56969 4.20219 4.73375 4.20219 4.9C4.20219 5.73562 4.56969 6.48594 5.14937 7C5.145 7 5.14062 7 5.13406 7H0.465937C0.21 7 0 6.79 0 6.53406ZM8.86594 7C8.86156 7 8.85719 7 8.85063 7C9.4325 6.48594 9.79781 5.73562 9.79781 4.9C9.79781 4.73375 9.7825 4.57188 9.75625 4.41219C10.0538 4.27437 10.3841 4.2 10.7319 4.2H11.6659C12.9544 4.2 14 5.24563 14 6.53406C14 6.79219 13.79 7 13.5341 7H8.86594ZM4.9 4.9C4.9 4.34305 5.12125 3.8089 5.51508 3.41508C5.9089 3.02125 6.44305 2.8 7 2.8C7.55695 2.8 8.0911 3.02125 8.48492 3.41508C8.87875 3.8089 9.1 4.34305 9.1 4.9C9.1 5.45695 8.87875 5.9911 8.48492 6.38492C8.0911 6.77875 7.55695 7 7 7C6.44305 7 5.9089 6.77875 5.51508 6.38492C5.12125 5.9911 4.9 5.45695 4.9 4.9ZM2.8 10.6159C2.8 9.00594 4.10594 7.7 5.71594 7.7H8.28406C9.89406 7.7 11.2 9.00594 11.2 10.6159C11.2 10.9375 10.9397 11.2 10.6159 11.2H3.38406C3.0625 11.2 2.8 10.9397 2.8 10.6159Z"
      fill="#BCBCBC"
    />
  </svg>
);

export default MembersIcon;
