const Link = ({ width = '23', height = '16', fill = '#828282' }) => (
  <div>
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 128.000000 128.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>Created by potrace 1.16, written by Peter Selinger 2001-2019</metadata>
      <g
        transform="translate(0.000000, 128.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M800 1259 c-45 -18 -81 -48 -192 -157 -119 -117 -138 -140 -138 -167
    0 -39 36 -75 75 -75 23 0 52 23 149 119 67 65 137 127 156 136 87 44 205 7
    256 -81 32 -55 36 -131 9 -184 -9 -19 -71 -89 -136 -156 -98 -100 -119 -126
    -119 -151 0 -38 36 -73 76 -73 26 0 51 21 166 138 163 166 182 202 176 342 -4
    102 -29 162 -98 230 -69 70 -127 94 -235 97 -73 3 -100 -1 -145 -18z"
        />
        <path
          d="M588 692 c-237 -237 -244 -247 -193 -297 50 -51 60 -44 297 193 237
    237 244 247 193 297 -50 51 -60 44 -297 -193z"
        />
        <path
          d="M178 673 c-163 -167 -182 -203 -176 -343 4 -102 29 -162 98 -230 67
    -68 127 -93 230 -98 139 -6 176 12 342 176 119 117 138 140 138 167 0 39 -36
    75 -75 75 -23 0 -52 -23 -149 -119 -67 -65 -137 -127 -156 -136 -53 -27 -129
    -23 -184 9 -88 51 -125 169 -81 256 9 19 71 89 136 156 98 100 119 126 119
    151 0 38 -36 73 -76 73 -26 0 -51 -21 -166 -137z"
        />
      </g>
    </svg>
  </div>
);

export default Link;
