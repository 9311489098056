import { Currencies, Regions } from '../../constants/enums';
import { t } from '../../i18n/i18n';

/**
 * Formats the price from a price object into a currency string based on the specified region.
 *
 * This function converts the price from cents to dollars and formats it according to the currency
 * and regional settings. It uses the `Intl.NumberFormat` object to handle currency formatting,
 * adjusting the number of decimal places based on the region.
 *
 * @param {Object} priceObject - The price object containing the price and currency details.
 * @param {number} priceObject.priceInCents - The price amount in cents.
 * @param {string} priceObject.currency - The currency code (e.g., 'USD', 'ARS').
 * @param {string} [region=Regions.USA] - The region to format the price for. Defaults to `Regions.USA`.
 * @returns {string} - The formatted currency string.
 *
 * @example
 * const price = { priceInCents: 123456, currency: 'USD' };
 * const formattedPrice = getPlanPrice(price);
 * // Returns: '$1,234.56' (in the US region)
 *
 * @example
 * const price = { priceInCents: 123456, currency: 'ARS' };
 * const formattedPrice = getPlanPrice(price, Regions.ARGENTINA);
 * // Returns: '$1,234' (in Argentina, with no decimal places)
 */
export function getPlanPrice(priceObject, region = Regions.USA) {
  // Return an empty string if the priceObject is not provided
  if (!priceObject) return '';

  // Extract price in cents and currency code from the priceObject
  const { priceInCents, currency } = priceObject;

  // Convert price from cents to dollars
  const priceInDollars = priceInCents / 100;

  // Set the minimum and maximum number of fraction digits based on the region
  const minimumFractionDigits = region === Regions.ARGENTINA ? 0 : 0;
  const maximumFractionDigits = region === Regions.ARGENTINA ? 0 : 0;

  // Format the price using the Intl.NumberFormat object with currency style
  const formattedCurrency = new Intl.NumberFormat(region, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  }).format(priceInDollars);

  // Return the formatted currency string
  return `${formattedCurrency}`;
}

export const getPriceByValue = (plan, currency) => {
  if (!plan.prices || !plan.prices.length) return t('NEGOTIABLE_PRICE_MESSAGE');
  const priceToShow = plan.prices?.find((x) => x.currency == currency);
  return getPlanPrice(priceToShow, currency == Currencies.USD ? Regions.USA : Regions.ARGENTINA);
};

/**
 * Formats a price object into a currency string based on the region.
 *
 * This function formats the price based on the amount in cents. It converts large amounts into a
 * more readable format using 'M' for millions and 'K' for thousands. For smaller amounts, it uses
 * the `getPlanPrice` function to format the currency based on the specified region.
 *
 * @param {Object} priceObject - The price object to format.
 * @param {number} priceObject.priceInCents - The price amount in cents.
 * @param {string} [region=Regions.USA] - The region to format the price for. Defaults to `Regions.USA`.
 * @returns {string} - The formatted currency string.
 *
 * @example
 * const price = { priceInCents: 1500000 };
 * const formattedPrice = formatCurrency(price);
 * // Returns: '$15K'
 *
 * @example
 * const price = { priceInCents: 500000000 };
 * const formattedPrice = formatCurrency(price);
 * // Returns: '$5M'
 */
export function formatCurrency(priceObject, region = Regions.USA) {
  // Return an empty string if the priceObject is not provided
  if (!priceObject) return '';

  // Extract the price in cents from the priceObject
  const { priceInCents } = priceObject;

  // Format the price for amounts 100 million or more
  if (priceInCents >= 100_000_000) {
    return '$' + (priceInCents / 100_000_000).toFixed(0).replace(/\.0$/, '') + 'M';
  }
  // Format the price for amounts 100 thousand or more
  else if (priceInCents >= 100_000) {
    return '$' + (priceInCents / 100_000).toFixed(0).replace(/\.0$/, '') + 'K';
  }
  // For smaller amounts, use the getPlanPrice function to format the price
  else {
    return getPlanPrice(priceObject, region);
  }
}
