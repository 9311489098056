const SmallCrossIcon = () => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.76324 1.36623C8.07559 1.05388 8.07559 0.546619 7.76324 0.234265C7.45088 -0.0780884 6.94362 -0.0780884 6.63127 0.234265L4 2.86803L1.36623 0.236764C1.05388 -0.0755896 0.546619 -0.0755896 0.234265 0.236764C-0.0780884 0.549118 -0.0780884 1.05638 0.234265 1.36873L2.86803 4L0.236764 6.63376C-0.0755895 6.94612 -0.0755895 7.45338 0.236764 7.76573C0.549118 8.07809 1.05638 8.07809 1.36873 7.76573L4 5.13197L6.63376 7.76324C6.94612 8.07559 7.45338 8.07559 7.76573 7.76324C8.07809 7.45088 8.07809 6.94362 7.76573 6.63127L5.13197 4L7.76324 1.36623Z"
      fill="#1A1A1A"
    />
  </svg>
);

export default SmallCrossIcon;
