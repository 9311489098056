import { GET_COHORTS, GET_TEAMS, SET_COHORTS, SET_TEAMS } from '../actionTypes';

export const getTeams = (params) => ({
  type: GET_TEAMS,
  payload: { params },
});

export const setTeams = (teams) => ({
  type: SET_TEAMS,
  payload: { teams },
});

export const getCohorts = (params) => ({
  type: GET_COHORTS,
  payload: { params },
});

export const setCohorts = (cohorts) => ({
  type: SET_COHORTS,
  payload: { cohorts },
});
