import React from 'react';

const Video = ({ classNames }) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    viewBox="0 0 100 125"
    enableBackground="new 0 0 100 100"
    xmlSpace="preserve"
    fill="none"
  >
    <g>
      <path d="M81.1,23.1H18.9c-2.5,0-4.5,2-4.5,4.5v44.9c0,2.5,2,4.5,4.5,4.5h62.3c2.5,0,4.5-2,4.5-4.5v-45C85.7,25,83.6,23.1,81.1,23.1   z M51.3,27.9c0-0.3,0.3-0.7,0.7-0.7h5.7c0.3,0,0.7,0.3,0.7,0.7V31c0,0.3-0.3,0.7-0.7,0.7H52c-0.3,0-0.7-0.3-0.7-0.7V27.9z    M40.4,27.9c0.1-0.3,0.3-0.7,0.8-0.7h5.6c0.3,0,0.7,0.3,0.7,0.7V31c0,0.3-0.3,0.7-0.7,0.7h-5.7c-0.3,0-0.7-0.3-0.7-0.7V27.9z    M29.7,27.9c0-0.3,0.3-0.7,0.7-0.7h5.7c0.3,0,0.7,0.3,0.7,0.7V31c0,0.3-0.3,0.7-0.7,0.7h-5.7c-0.3,0-0.7-0.3-0.7-0.7V27.9z    M26,71.6c-0.1,0.3-0.3,0.7-0.8,0.7h-5.6c-0.3,0-0.7-0.3-0.7-0.7v-3.1c0-0.3,0.3-0.7,0.7-0.7h5.7c0.3,0,0.7,0.3,0.7,0.7V71.6z    M26,30.9c-0.1,0.3-0.3,0.7-0.8,0.7h-5.6c-0.3,0-0.7-0.3-0.7-0.7v-3.1c0-0.3,0.3-0.7,0.7-0.7h5.7c0.3,0,0.7,0.3,0.7,0.7V30.9z    M36.8,71.6c0,0.3-0.3,0.7-0.7,0.7h-5.7c-0.3,0-0.7-0.3-0.7-0.7v-3.1c0-0.3,0.3-0.7,0.7-0.7h5.7c0.3,0,0.7,0.3,0.7,0.7V71.6z    M47.6,71.6c-0.1,0.3-0.3,0.7-0.8,0.7h-5.7c-0.3,0-0.7-0.3-0.7-0.7v-3.1c0-0.3,0.3-0.7,0.7-0.7h5.8c0.3,0,0.7,0.3,0.7,0.7V71.6z    M58.4,71.6c-0.1,0.3-0.3,0.7-0.8,0.7h-5.6c-0.3,0-0.7-0.3-0.7-0.7v-3.1c0-0.3,0.3-0.7,0.7-0.7h5.7c0.3,0,0.7,0.3,0.7,0.7V71.6z    M49.3,62.7c-7,0-12.7-5.7-12.7-12.7c0-7,5.7-12.7,12.7-12.7c7,0,12.7,5.7,12.7,12.7C61.9,57,56.3,62.7,49.3,62.7z M69.2,71.6   c0,0.3-0.3,0.7-0.7,0.7h-5.7c-0.3,0-0.7-0.3-0.7-0.7v-3.1c0-0.3,0.3-0.7,0.7-0.7h5.7c0.3,0,0.7,0.3,0.7,0.7V71.6z M69.2,30.9   c0,0.3-0.3,0.7-0.7,0.7h-5.7c-0.3,0-0.7-0.3-0.7-0.7v-3.1c0-0.3,0.3-0.7,0.7-0.7h5.7c0.3,0,0.7,0.3,0.7,0.7V30.9z M80.1,71.6   c0,0.3-0.3,0.7-0.7,0.7h-5.7c-0.3,0-0.7-0.3-0.7-0.7v-3.1c0-0.3,0.3-0.7,0.7-0.7h5.7c0.3,0,0.7,0.3,0.7,0.7V71.6z M80.1,30.9   c0,0.3-0.3,0.7-0.7,0.7h-5.7c-0.3,0-0.7-0.3-0.7-0.7v-3.1c0-0.3,0.3-0.7,0.7-0.7h5.7c0.3,0,0.7,0.3,0.7,0.7V30.9z" />
    </g>
    <path d="M57,49L52,45.9l-5.1-3c-0.7-0.4-1.7,0.1-1.7,1l-0.1,5.9L45,55.8c0,0.9,1,1.5,1.7,1l5.2-3l5.2-3C57.8,50.6,57.8,49.4,57,49z" />
  </svg>
);

export default Video;
