import React from 'react';

const InviteMembers = ({ className }) => (
  <svg
    className={className}
    width="65"
    height="65"
    viewBox="0 0 65 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.0044 55.4978C30.0044 57.3771 29.4471 59.2143 28.403 60.7769C27.3589 62.3395 25.8748 63.5575 24.1385 64.2767C22.4022 64.9959 20.4917 65.184 18.6484 64.8174C16.8052 64.4507 15.112 63.5458 13.7831 62.2168C12.4542 60.8879 11.5492 59.1948 11.1826 57.3516C10.8159 55.5083 11.0041 53.5977 11.7233 51.8614C12.4425 50.1251 13.6604 48.6411 15.2231 47.597C16.7857 46.5529 18.6228 45.9956 20.5022 45.9956C21.7516 45.99 22.9897 46.232 24.1451 46.7076C25.3005 47.1831 26.3502 47.8828 27.2337 48.7663C28.1171 49.6498 28.8169 50.6995 29.2924 51.8549C29.768 53.0102 30.01 54.2484 30.0044 55.4978Z"
      fill="#F2F2F2"
    />
    <path
      d="M24.3886 53.2241L24.3416 53.3089C24.7601 52.2775 24.3795 51.0066 23.633 50.229C22.8865 49.4513 21.8487 49.0958 20.8267 48.9547C20.0681 48.85 19.2807 48.853 18.5593 49.1309C17.5148 49.5333 16.7167 50.4649 16.0504 51.4339C15.7456 51.8771 15.4459 52.3825 15.4762 52.9365C15.5044 53.4506 15.8096 53.8868 16.0595 54.323C16.3094 54.7592 16.5181 55.3003 16.3388 55.7764C16.2319 56.0601 16.006 56.2644 15.8227 56.4965C15.6394 56.7286 15.4886 57.0422 15.5772 57.3334C15.6165 57.4448 15.684 57.5441 15.7732 57.6216C15.8624 57.6991 15.9701 57.7521 16.0859 57.7755C16.3075 57.8272 16.5373 57.7934 16.7618 57.7595L22.5867 56.8794C22.9825 56.8196 23.358 56.665 23.6811 56.4287C24.0042 56.1924 24.2654 55.8815 24.4424 55.5225C24.6194 55.1634 24.707 54.7669 24.6976 54.3667C24.6882 53.9665 24.5822 53.5745 24.3886 53.2241Z"
      fill="#2F2E41"
    />
    <path
      d="M22.867 54.4471C23.119 52.8946 22.0646 51.4317 20.5121 51.1798C18.9595 50.9278 17.4967 51.9822 17.2448 53.5347C16.9928 55.0873 18.0472 56.5501 19.5997 56.8021C21.1523 57.054 22.6151 55.9997 22.867 54.4471Z"
      fill="#FFB9B9"
    />
    <path
      d="M27.6375 61.7717C25.9912 63.6448 23.6744 64.7955 21.1872 64.9752C18.6999 65.155 16.2417 64.3494 14.3433 62.7324L15.1583 60.4582C15.3425 60.0543 15.6331 59.7081 15.9991 59.4568C16.365 59.2055 16.7925 59.0586 17.2356 59.0317L17.7541 58.9999L18.9905 57.9336C18.9905 57.9336 22.3218 57.8147 22.2724 57.7577L24.2902 58.9714L24.2886 58.9631L25.0818 59.0116C25.525 59.0384 25.9525 59.1853 26.3185 59.4366C26.6844 59.6879 26.9751 60.0341 27.1592 60.4381L27.6375 61.7717Z"
      fill="#4bafe1"
    />
    <path
      d="M22.7571 51.3478C22.6836 50.9588 22.287 50.781 21.9471 50.6948C20.9614 50.4433 19.9359 50.387 18.9286 50.529C18.1942 50.6337 17.4424 50.8647 16.9096 51.4379C16.48 51.9205 16.2365 52.5406 16.223 53.1866C16.2013 53.5729 16.2876 53.9576 16.4723 54.2975C16.664 54.6221 16.9908 54.8575 17.3282 54.8492L17.3624 54.9681C18.7923 54.7689 20.1205 53.8792 20.957 52.5604C20.8 53.121 20.4875 53.6257 20.0558 54.0162C21.0211 53.6299 21.8602 52.9832 22.4797 52.1481C22.6488 51.9236 22.8131 51.6436 22.7571 51.3478Z"
      fill="#2F2E41"
    />
    <path
      d="M32.0505 41.8275C37.2985 41.8275 41.5527 37.5732 41.5527 32.3253C41.5527 27.0774 37.2985 22.8231 32.0505 22.8231C26.8026 22.8231 22.5483 27.0774 22.5483 32.3253C22.5483 37.5732 26.8026 41.8275 32.0505 41.8275Z"
      fill="#F2F2F2"
    />
    <path
      d="M29.4412 28.7598C30.1821 28.7598 30.7827 28.1591 30.7827 27.4182C30.7827 26.6773 30.1821 26.0767 29.4412 26.0767C28.7002 26.0767 28.0996 26.6773 28.0996 27.4182C28.0996 28.1591 28.7002 28.7598 29.4412 28.7598Z"
      fill="#2F2E41"
    />
    <path
      d="M31.5876 33.8577C33.4645 33.8577 34.9862 32.3361 34.9862 30.4591C34.9862 28.5821 33.4645 27.0605 31.5876 27.0605C29.7106 27.0605 28.189 28.5821 28.189 30.4591C28.189 32.3361 29.7106 33.8577 31.5876 33.8577Z"
      fill="#2F2E41"
    />
    <path
      d="M31.8692 33.8568C33.3944 33.8568 34.6309 32.6203 34.6309 31.0951C34.6309 29.5698 33.3944 28.3333 31.8692 28.3333C30.3439 28.3333 29.1074 29.5698 29.1074 31.0951C29.1074 32.6203 30.3439 33.8568 31.8692 33.8568Z"
      fill="#9E616A"
    />
    <path
      d="M33.7448 28.2799C33.2608 27.9848 32.7065 27.8253 32.1398 27.8178C31.573 27.8103 31.0147 27.9552 30.5231 28.2374C30.0316 28.5196 29.6249 28.9286 29.3455 29.4218C29.0662 29.9149 28.9244 30.4741 28.9351 31.0408C30.3424 31.3941 31.8208 31.6645 33.3215 31.1253L33.6759 30.2579L33.8849 31.1259C34.3419 31.3127 34.8001 31.4708 35.2583 31.1241C35.277 30.559 35.1471 29.9989 34.8815 29.4998C34.6159 29.0007 34.2239 28.58 33.7448 28.2799Z"
      fill="#2F2E41"
    />
    <path
      d="M28.1922 23.7764C27.2646 23.351 26.1006 23.5506 25.3281 24.2176C24.5557 24.8846 24.1885 25.9701 24.3376 26.9797C24.4897 28.0096 25.1159 28.8965 25.693 29.763C26.2702 30.6295 26.834 31.579 26.8334 32.6201C26.8329 33.3699 26.5351 34.089 26.0052 34.6196C25.4754 35.1502 24.7567 35.449 24.0068 35.4505C24.8869 35.8294 25.8055 36.2157 26.7626 36.1708C27.7197 36.1259 28.7229 35.5106 28.8791 34.5652C28.9916 33.884 28.6574 33.2198 28.3786 32.5881C27.9246 31.5589 27.5965 30.4787 27.4013 29.3708C27.3082 28.8421 27.2476 28.2862 27.42 27.7778C27.5572 27.401 27.8119 27.0783 28.1465 26.8573C28.4811 26.6362 28.8779 26.5286 29.2783 26.5502L29.5946 26.2656C29.7353 25.2548 29.1199 24.2017 28.1922 23.7764Z"
      fill="#2F2E41"
    />
    <path
      d="M39.2004 38.683C37.5542 40.5561 35.2374 41.7068 32.7502 41.8865C30.2629 42.0663 27.8047 41.2607 25.9062 39.6438L26.7213 37.3696C26.9055 36.9656 27.1961 36.6195 27.5621 36.3682C27.928 36.1169 28.3555 35.9699 28.7986 35.9431L29.3171 35.9112L30.5535 34.8449C30.5535 34.8449 33.8848 34.726 33.8354 34.669L35.8532 35.8827L35.8516 35.8744L36.6448 35.923C37.088 35.9497 37.5155 36.0966 37.8815 36.3479C38.2474 36.5992 38.5381 36.9455 38.7222 37.3495L39.2004 38.683Z"
      fill="#4bafe1"
    />
    <path
      d="M30.0044 9.51905C30.0011 11.3984 29.4405 13.2346 28.3936 14.7953C27.3467 16.3561 25.8605 17.5714 24.123 18.2875C22.3854 19.0036 20.4745 19.1884 18.6319 18.8185C16.7893 18.4486 15.0978 17.5406 13.7712 16.2093C12.4447 14.8781 11.5427 13.1833 11.1793 11.3395C10.8159 9.49556 11.0075 7.58532 11.7298 5.8503C12.4521 4.11528 13.6726 2.6334 15.2371 1.59206C16.8016 0.550718 18.6397 -0.00331703 20.519 1.49409e-05C21.7678 -0.00101157 23.0045 0.244751 24.1579 0.723179C25.3114 1.20161 26.359 1.90327 27.2404 2.78784C28.1219 3.67241 28.8198 4.72245 29.2941 5.87762C29.7685 7.03278 30.0098 8.2703 30.0044 9.51905Z"
      fill="#F2F2F2"
    />
    <path
      d="M16.0981 6.41111C16.491 5.15892 17.3845 4.01045 18.6201 3.58714C19.8559 3.16383 21.6654 3.80867 22.5944 4.73454C24.2937 6.42814 24.7084 12.2463 23.5409 14.0849C23.3087 14.0726 22.5074 14.0635 22.2723 14.0547L21.9398 12.9465V14.0437C21.5654 14.0322 21.188 14.0242 20.8077 14.0198C19.5326 14.004 18.3133 13.4944 17.4061 12.5982C16.4989 11.702 15.9746 10.489 15.9432 9.21424C15.9128 7.99469 15.9432 6.90458 16.0981 6.41111Z"
      fill="#2F2E41"
    />
    <path
      d="M20.3614 10.5612C21.857 10.5612 23.0694 9.34876 23.0694 7.85314C23.0694 6.35752 21.857 5.14508 20.3614 5.14508C18.8658 5.14508 17.6533 6.35752 17.6533 7.85314C17.6533 9.34876 18.8658 10.5612 20.3614 10.5612Z"
      fill="#9E616A"
    />
    <path
      d="M18.0244 5.47481L21.1425 3.91351C21.78 4.20077 22.329 4.65334 22.7326 5.22429C23.1362 5.79523 23.3797 6.46376 23.4379 7.16054L23.5156 8.09148L22.2099 7.75911L22.0669 6.56872L21.8451 7.66625L21.2426 7.51294L21.2483 5.66487L20.6454 7.51162L18.4932 7.01717L18.0244 5.47481Z"
      fill="#2F2E41"
    />
    <path
      d="M27.5535 15.7903C25.9072 17.6635 23.5904 18.8141 21.1032 18.9939C18.6159 19.1737 16.1577 18.3681 14.2593 16.7511L15.0743 14.4769C15.2585 14.073 15.5491 13.7268 15.9151 13.4755C16.2811 13.2242 16.7085 13.0773 17.1517 13.0504L17.6702 13.0186L18.9065 11.9523C18.9065 11.9523 22.2378 11.8333 22.1884 11.7764L24.2063 12.9901L24.2046 12.9817L24.9978 13.0303C25.441 13.057 25.8685 13.2039 26.2345 13.4553C26.6005 13.7066 26.8911 14.0528 27.0752 14.4568L27.5535 15.7903Z"
      fill="#4bafe1"
    />
    <path
      d="M54.5022 42.0044C59.7501 42.0044 64.0044 37.7501 64.0044 32.5022C64.0044 27.2543 59.7501 23 54.5022 23C49.2543 23 45 27.2543 45 32.5022C45 37.7501 49.2543 42.0044 54.5022 42.0044Z"
      fill="#F2F2F2"
    />
    <path
      d="M50.6246 28.7854C50.4942 29.4861 50.2803 30.1687 49.9876 30.8185C48.3114 34.3645 53.0849 33.2087 54.7908 33.2087C56.6559 33.2087 58.4405 34.1894 58.1679 29.8315C58.0515 27.97 56.6559 26.4544 54.7908 26.4544C53.1976 26.4544 50.9577 27.1625 50.6246 28.7854Z"
      fill="#2F2E41"
    />
    <path
      d="M54.5114 33.2078C56.027 33.2078 57.2557 31.9792 57.2557 30.4635C57.2557 28.9479 56.027 27.7192 54.5114 27.7192C52.9958 27.7192 51.7671 28.9479 51.7671 30.4635C51.7671 31.9792 52.9958 33.2078 54.5114 33.2078Z"
      fill="#FFB6B6"
    />
    <path
      d="M52.6474 27.6661C53.1283 27.373 53.6791 27.2144 54.2422 27.2069C54.8054 27.1995 55.3602 27.3435 55.8486 27.6239C56.3371 27.9043 56.7412 28.3108 57.0188 28.8008C57.2964 29.2908 57.4373 29.8465 57.4266 30.4096C56.0282 30.7607 54.5592 31.0293 53.0679 30.4935L52.7158 29.6316L52.5081 30.4942C52.054 30.6798 51.5987 30.8369 51.1434 30.4924C51.1248 29.9309 51.2539 29.3743 51.5178 28.8783C51.7818 28.3824 52.1712 27.9644 52.6474 27.6661Z"
      fill="#2F2E41"
    />
    <path
      d="M61.8909 38.8206C60.2447 40.6937 57.9278 41.8444 55.4406 42.0241C52.9533 42.2039 50.4951 41.3983 48.5967 39.7813L49.4117 37.5071C49.5959 37.1032 49.8865 36.757 50.2525 36.5057C50.6185 36.2544 51.0459 36.1075 51.4891 36.0806L52.0076 36.0488L53.2439 34.9825C53.2439 34.9825 56.5752 34.8635 56.5258 34.8066L58.5437 36.0203L58.542 36.0119L59.3352 36.0605C59.7784 36.0873 60.2059 36.2342 60.5719 36.4855C60.9379 36.7368 61.2285 37.083 61.4126 37.487L61.8909 38.8206Z"
      fill="#4bafe1"
    />
    <path
      d="M43.5022 19.0044C48.7501 19.0044 53.0044 14.7501 53.0044 9.5022C53.0044 4.25428 48.7501 0 43.5022 0C38.2543 0 34 4.25428 34 9.5022C34 14.7501 38.2543 19.0044 43.5022 19.0044Z"
      fill="#F2F2F2"
    />
    <path
      d="M43.2397 10.6793C44.9149 10.6793 46.2729 9.32131 46.2729 7.64612C46.2729 5.97092 44.9149 4.61292 43.2397 4.61292C41.5646 4.61292 40.2065 5.97092 40.2065 7.64612C40.2065 9.32131 41.5646 10.6793 43.2397 10.6793Z"
      fill="#FFB6B6"
    />
    <path
      d="M50.6306 15.8599C48.9844 17.733 46.6676 18.8837 44.1803 19.0634C41.6931 19.2432 39.2349 18.4376 37.3364 16.8206L38.1514 14.5464C38.3356 14.1425 38.6263 13.7963 38.9922 13.545C39.3582 13.2937 39.7857 13.1468 40.2288 13.1199L40.7473 13.0881L41.9837 12.0218C41.9837 12.0218 45.315 11.9028 45.2655 11.8459L47.2834 13.0596L47.2817 13.0512L48.075 13.0998C48.5181 13.1266 48.9457 13.2735 49.3116 13.5248C49.6776 13.7761 49.9682 14.1223 50.1523 14.5263L50.6306 15.8599Z"
      fill="#4bafe1"
    />
    <path
      d="M46.4389 5.76494C46.1825 4.84752 45.6002 4.0552 44.8011 3.5367C44.1643 3.1294 43.3697 2.92146 42.6482 3.13685C41.9268 3.35225 41.3295 4.06025 41.3767 4.82521C41.1163 4.7366 40.8203 4.85449 40.6297 5.05592C40.439 5.25736 40.3365 5.52832 40.2631 5.79804C40.0316 6.6557 40.047 7.56129 40.3074 8.41062L40.293 8.53253C40.645 8.4743 40.912 8.17231 41.078 7.85055C41.244 7.5288 41.3395 7.17184 41.5195 6.85796C41.6994 6.54408 41.9982 6.26408 42.3546 6.25428L42.3232 6.79913C42.6299 6.64113 42.9366 6.48313 43.2432 6.32514C43.1703 6.49963 43.0973 6.67412 43.0244 6.84861C43.2922 6.73667 43.56 6.62473 43.8278 6.5128C43.7979 6.68059 43.768 6.84838 43.738 7.01616C44.0036 6.41161 44.825 6.14603 45.3838 6.48404C45.7083 6.68035 45.9212 7.03019 46.0332 7.39758C46.1452 7.76497 46.1674 8.15362 46.1887 8.53773C46.6026 7.67354 46.6914 6.68923 46.4389 5.76494Z"
      fill="#2F2E41"
    />
    <path
      d="M43.5823 65C48.8302 65 53.0845 60.7457 53.0845 55.4978C53.0845 50.2499 48.8302 45.9956 43.5823 45.9956C38.3344 45.9956 34.0801 50.2499 34.0801 55.4978C34.0801 60.7457 38.3344 65 43.5823 65Z"
      fill="#F2F2F2"
    />
    <path
      d="M43.373 56.4834C44.9651 56.4834 46.2557 55.1928 46.2557 53.6007C46.2557 52.0086 44.9651 50.718 43.373 50.718C41.7809 50.718 40.4902 52.0086 40.4902 53.6007C40.4902 55.1928 41.7809 56.4834 43.373 56.4834Z"
      fill="#9E616A"
    />
    <path
      d="M50.5886 61.7717C48.9424 63.6448 46.6256 64.7955 44.1383 64.9752C41.6511 65.155 39.1929 64.3494 37.2944 62.7324L38.1095 60.4582C38.2936 60.0543 38.5843 59.7081 38.9503 59.4568C39.3162 59.2055 39.7437 59.0586 40.1868 59.0317L40.7053 58.9999L41.9417 57.9336C41.9417 57.9336 45.273 57.8147 45.2235 57.7577L47.2414 58.9714L47.2397 58.9631L48.033 59.0116C48.4761 59.0384 48.9037 59.1853 49.2696 59.4366C49.6356 59.6879 49.9263 60.0341 50.1103 60.4381L50.5886 61.7717Z"
      fill="#4bafe1"
    />
    <path
      d="M40.7538 54.9334C40.7481 54.9177 40.743 54.9017 40.7374 54.8859C40.7263 54.8957 40.7156 54.9058 40.7036 54.9149L40.7538 54.9334Z"
      fill="#2F2E41"
    />
    <path
      d="M46.8262 52.5752C46.8114 52.2827 46.7348 51.9966 46.6014 51.7359C46.468 51.4752 46.2808 51.2456 46.0523 51.0625C45.8843 50.9296 45.6899 50.8174 45.5787 50.6344C45.5153 50.5048 45.4615 50.3707 45.4178 50.2332C45.1965 49.7065 44.5658 49.4265 44.0001 49.5063C43.4344 49.586 42.9447 49.9666 42.615 50.4331C42.4525 50.3553 42.2711 50.3256 42.0923 50.3477C41.9134 50.3697 41.7447 50.4425 41.606 50.5575C41.4672 50.6725 41.3644 50.8248 41.3095 50.9964C41.2547 51.1681 41.2502 51.3518 41.2966 51.5259C41.0036 51.2899 40.5297 51.4787 40.3468 51.8075C40.1639 52.1362 40.193 52.5385 40.2473 52.9108C40.3458 53.5836 40.5099 54.2451 40.7372 54.886C41.0225 54.6348 41.005 54.1773 40.9701 53.7906C40.9339 53.3888 40.9556 52.8983 41.3069 52.7001C41.6463 52.5086 42.0583 52.7188 42.4325 52.8276C42.7284 52.9114 43.0389 52.9301 43.3427 52.8823C43.6465 52.8345 43.9364 52.7214 44.1922 52.5508C44.3872 52.4189 44.5607 52.2537 44.7709 52.1477C44.9811 52.0417 45.2485 52.0048 45.4442 52.1357C45.6228 52.2552 45.6955 52.4781 45.7563 52.6842C45.9004 53.1729 46.0445 53.6615 46.1886 54.1502C46.4001 53.9476 46.5663 53.7025 46.6762 53.431C46.7861 53.1596 46.8372 52.8679 46.8262 52.5752Z"
      fill="#2F2E41"
    />
    <path
      d="M9.5022 41.8275C14.7501 41.8275 19.0044 37.5732 19.0044 32.3253C19.0044 27.0774 14.7501 22.8231 9.5022 22.8231C4.25428 22.8231 0 27.0774 0 32.3253C0 37.5732 4.25428 41.8275 9.5022 41.8275Z"
      fill="#F2F2F2"
    />
    <path
      d="M9.58702 32.7215C11.6797 32.7215 13.3762 31.025 13.3762 28.9323C13.3762 26.8396 11.6797 25.1431 9.58702 25.1431C7.49432 25.1431 5.79785 26.8396 5.79785 28.9323C5.79785 31.025 7.49432 32.7215 9.58702 32.7215Z"
      fill="#2F2E41"
    />
    <path
      d="M9.58864 33.2192C11.4581 33.2192 12.9737 31.7037 12.9737 29.8342C12.9737 27.9647 11.4581 26.4492 9.58864 26.4492C7.71914 26.4492 6.20361 27.9647 6.20361 29.8342C6.20361 31.7037 7.71914 33.2192 9.58864 33.2192Z"
      fill="#FFB8B8"
    />
    <path
      d="M16.6047 38.569C14.9585 40.4422 12.6417 41.5928 10.1544 41.7726C7.66719 41.9523 5.209 41.1468 3.31055 39.5298L4.12557 37.2556C4.30975 36.8517 4.60041 36.5055 4.96637 36.2542C5.33233 36.0029 5.75979 35.8559 6.20292 35.8291L6.72142 35.7973L7.95778 34.731C7.95778 34.731 11.2891 34.612 11.2397 34.5551L13.2575 35.7688L13.2559 35.7604L14.0491 35.809C14.4923 35.8357 14.9198 35.9826 15.2858 36.234C15.6517 36.4853 15.9424 36.8315 16.1265 37.2355L16.6047 38.569Z"
      fill="#4bafe1"
    />
    <path
      d="M13.1598 28.6471C13.1836 28.3943 13.1945 28.1406 13.1924 27.8867C13.2063 27.3847 13.1143 26.8854 12.9225 26.4213C12.6866 25.9074 12.266 25.501 11.7442 25.283C11.2271 25.0744 10.6491 25.0749 10.1324 25.2844C9.66582 24.9896 9.14742 24.756 8.60148 24.727C8.05554 24.6981 7.47832 24.9006 7.14188 25.3465C7.06676 25.4641 6.98143 25.575 6.88688 25.6777C6.61644 25.9282 6.20545 25.8921 5.84599 25.8409C6.00721 25.979 6.16848 26.1172 6.32979 26.2554C6.13463 26.3064 5.93945 26.3574 5.74426 26.4084C5.97202 26.5465 6.19976 26.6846 6.42749 26.8228C5.76631 27.2196 5.46579 28.0858 5.54518 28.8725C5.62028 29.6173 5.97986 30.2983 6.40971 30.9037C6.58118 30.5418 6.54423 30.1089 6.5414 29.7013C6.53983 29.5691 6.5479 29.437 6.56553 29.306C8.01822 29.6542 9.5361 29.8777 11.0754 29.3246L11.3843 28.5686C11.4277 28.573 11.4711 28.5772 11.5144 28.5818L11.6933 29.3253C12.0991 29.4912 12.5057 29.6361 12.9125 29.4838C12.9323 29.5986 12.9442 29.7158 12.9533 29.8285C12.9882 29.6937 13.0185 29.558 13.0458 29.4217C13.0996 29.3929 13.1511 29.36 13.1998 29.3233C13.2097 29.0971 13.1963 28.8705 13.1598 28.6471Z"
      fill="#2F2E41"
    />
  </svg>
);

export default InviteMembers;
