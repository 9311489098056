import React from 'react';
import tooltip from '../../assets/profile/tooltip.svg';
import timerIcon from '../../assets/profile/timer.svg';
import { t } from '../../i18n/i18n';
import './trainingSkills.scss';
import SingleAccordion from '../SingleAccordion/SingleAccordion';

const TrainingSkills = ({ className, skills = [], waiting }) => (
  <div
    className={`card-profile position-relative non-selectable ${className}`}
    data-tour-id="profile-skills"
  >
    <div className="pb-2">
      <span className="technical-skills-card-title font-montserrat font-weight-bold">
        {t('PROFILE_SKILLS')}
      </span>
      <span className="technical-skills-card-subtitle text-nowrap mr-2 font-montserrat font-weight-500">
        {' - '}
        {t('PROFILE_TRAINING_SKILL')}
      </span>
      {false && <img src={tooltip} alt={t('PROFILE_TOOLTIP')} />}
    </div>
    {waiting && (
      <div className="bg-gray d-flex align-items-center w-100 py-3 px-2 rounded font-montserrat font-weight-bold text-sm my-3">
        <img src={timerIcon} alt={t('ICON_TIMER')} className="mr-2" />{' '}
        {t('SKILLS_WAITING_ACCELERATION')}
      </div>
    )}
    <div className="position-relative">
      <div className="d-flex flex-column">
        <div className="progress-title d-flex my-3 w-100 h-100 position-absolute align-items-stretch">
          {false && (
            <div className="w-100 position-relative">
              <div className="barras d-none d-sm-block h-100 position-absolute" />
            </div>
          )}
          <div className="progress-dropdown" />
        </div>
      </div>
      {skills.map((skill, index) => (
        <SingleAccordion skill={skill} index={index} waiting={waiting} />
      ))}
    </div>
    <div className="d-flex">
      {false && (
        <div className="w-25 flex-grow-1 d-flex py-3">
          <div className="progress-title-pre font-roboto text-center">{t('PRE_TRAINING')}</div>
          <div className="progress-title-ace font-roboto text-center">{t('ACCELERATION')}</div>
        </div>
      )}
      <div className="progress-dropdown" />
    </div>
  </div>
);
export default TrainingSkills;
