import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import EditIcon from '../../../assets/settings/workspace/editIcon';
import {
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Icon7,
  Icon8,
  Icon9,
  Icon10,
  Icon11,
  Icon12,
  Icon13,
} from '../../../assets/settings/workspace';
import { t } from '../../../i18n/i18n';
import {
  modifyWorkspaceAsync,
  uploadWorkspaceIconAsync,
} from '../../../API/services/alkymersService';
import ModalCustom from '../../../components/ModalCustom/ModalCustom';
import './Workspace.scss';
import { getLocalStorageWorkspace, setLocalStorageWorkspace } from '../../../utils/workspaceUtils';
import { setWorkspacesList } from '../../../redux/actions/authActions';
import { CONFIGURATION_MEMBERS } from '../../../constants/routes';
import { PartnerWorkspaceRole } from '../../../constants/enums';
import TransferOwnership from '../../../assets/search/TransferOwnership';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const iconsMaps = [
  { id: 1, icon: Icon1 },
  { id: 2, icon: Icon2 },
  { id: 3, icon: Icon3 },
  { id: 4, icon: Icon4 },
  { id: 5, icon: Icon5 },
  { id: 6, icon: Icon6 },
  { id: 7, icon: Icon7 },
  { id: 8, icon: Icon8 },
  { id: 9, icon: Icon9 },
  { id: 10, icon: Icon10 },
  { id: 11, icon: Icon11 },
  { id: 12, icon: Icon12 },
  { id: 13, icon: Icon13 },
];

const CustomIcon = ({ children: Children, color }) => (
  <>
    <Children color={color} />
  </>
);

const ColorPicker = ({ workspaceData, setWorkspaceData }) => {
  const { color: colorSelected } = workspaceData;
  return (
    <>
      <span className="font-montserrat font-weight-600 text-base">
        {t('PERSONALIZE_WORKSPACE_SELECT_COLOR')}
      </span>
      <div
        data-testid="color-picker-container"
        className="d-flex flex-row flex-wrap mt-2 mb-4"
        style={{ minHeight: '32px', gap: '8px' }}
      >
        {[
          '#0e9cff',
          '#66cbfb',
          '#0000ff',
          '#FF6384',
          '#ff0000',
          '#ffa500',
          '#f7cb0b',
          '#32cd32',
          '#0b8245',
          '#9966ff',
          '#800080',
          '#a81b4b',
          '#bcbcbc',
          '#757575',
          '#1a1a1a',
        ].map((color) => (
          <div
            style={{
              borderColor: color,
            }}
            key={color}
            className={`d-flex justify-content-center align-items-center  ${
              color.toUpperCase() === colorSelected.toUpperCase()
                ? 'color-picker-selected'
                : 'd-none'
            }`}
          >
            <div
              style={{
                backgroundColor: color,
              }}
              className="cursor-pointer mx-auto color-picker-circle"
              aria-hidden="true"
              data-testid={`color-picker-circle-${color}`}
              onClick={() => setWorkspaceData({ ...workspaceData, color })}
              key={color}
            />
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-center align-items-center mt-2 px-2 py-1 input-type-color-profile">
        <input
          type="color"
          onChange={(e) => setWorkspaceData({ ...workspaceData, color: e.target.value })}
          className="input-picker-color-custom cursor-pointer"
          data-testid="color-picker-input"
          style={{ borderColor: colorSelected }}
          name="color1"
          value={colorSelected}
        />
        <span className="font-montserrat font-weight-600 text-base ml-2 text-uppercase">
          {colorSelected}
        </span>
      </div>
    </>
  );
};

const IconPicker = ({
  color,
  workspaceData,
  setWorkspaceData,
  newCustomIcon,
  setNewCustomIcon,
  previewImage,
}) => {
  const importRef = useRef(null);

  return (
    <>
      <span className="font-montserrat font-weight-600 text-base">
        {t('PERSONALIZE_WORKSPACE_SELECT_ICON')}
      </span>
      <div
        data-testid="icon-picker-container"
        className="d-flex flex-row flex-wrap mt-2 mb-4"
        style={{ minHeight: '32px', gap: '8px' }}
      >
        {iconsMaps.map((icon) => (
          <div
            className={`d-flex justify-content-center align-items-center icon-workspace ${
              workspaceData.iconId === icon.id ? 'icon-workspace-selected' : 'border-0'
            }`}
            key={icon.id}
            style={{
              borderColor: color,
            }}
            data-testid={`icon-${icon.id}`}
            onClick={() =>
              setWorkspaceData({
                ...workspaceData,
                icon: null,
                iconId: icon.id,
              })
            }
            aria-hidden="true"
          >
            <CustomIcon {...(workspaceData.iconId === icon.id ? { color } : {})}>
              {icon.icon}
            </CustomIcon>
          </div>
        ))}
      </div>
      <div className="d-flex flex-row align-items-center justify-content-between mt-auto">
        <div className="d-flex align-items-center">
          <input
            type="file"
            name="file"
            id="customIconFile"
            data-testid="custom-icon-file"
            hidden
            ref={importRef}
            onChange={(e) => {
              if (e.target?.files[0]) {
                setNewCustomIcon(e.target.files[0]);
                setWorkspaceData({
                  ...workspaceData,
                  iconId: null,
                  icon: null,
                });
              }
            }}
            accept="image/svg+xml, image/png, image/jpeg"
          />
          {newCustomIcon && (
            <div
              className="d-flex align-items-center justify-content-center custom-icon-preview icon-workspace-selected"
              style={{
                borderColor: color,
              }}
            >
              <img src={previewImage} alt="workspace-custom-icon" />
            </div>
          )}
          {newCustomIcon && (
            <>
              <span
                className="pl-2 font-montserrat text-sm font-weight-500 color-text-icon-picker cursor-pointer"
                aria-hidden="true"
                onClick={() => {
                  setWorkspaceData({
                    ...workspaceData,
                    icon: null,
                    iconId: null,
                  });
                  setNewCustomIcon(null);
                }}
              >
                {t('PERSONALIZE_WORKSPACE_REMOVE_ICON')}
              </span>
              <span className="pl-2 font-montserrat text-sm font-weight-500">|</span>
            </>
          )}
          <label htmlFor="customIconFile" className="mb-0">
            {newCustomIcon ? (
              <span className="pl-2 font-montserrat text-sm font-weight-500 color-text-icon-picker cursor-pointer">
                {t('PERSONALIZE_WORKSPACE_CHANGE_ICON')}
              </span>
            ) : (
              <span className="font-montserrat text-sm font-weight-500 color-text-icon-picker cursor-pointer">
                {t('PERSONALIZE_WORKSPACE_UPLOAD_ICON')}
              </span>
            )}
          </label>
        </div>
        <span
          className="font-montserrat text-sm font-weight-500 color-text-icon-picker cursor-pointer"
          aria-hidden="true"
          data-testid="initial-as-icon-btn"
          onClick={() => {
            setWorkspaceData({ ...workspaceData, icon: null, iconId: null });
            setNewCustomIcon(null);
          }}
        >
          {t('PERSONALIZE_WORKSPACE_USE_INITIAL')}
        </span>
      </div>
    </>
  );
};

const Workspace = () => {
  const { user, workspacesList } = useSelector((state) => state?.auth);
  const workspaceId = getLocalStorageWorkspace()?.id;
  const history = useHistory();
  const [showModalEditWorkspaceName, setShowModalEditWorkspaceName] = useState(false);
  const activeWorkspace =
    workspacesList.find((item) => item.id === workspaceId) ?? user?.workspaces[0];
  const [newCustomIcon, setNewCustomIcon] = useState(null);
  const [savedWorkspace, setSavedWorkspace] = useState({
    name: activeWorkspace?.name,
    color: activeWorkspace?.color || '#0E9CE2',
    icon: activeWorkspace?.icon,
    iconId: activeWorkspace?.iconId,
  });
  const [workspaceData, setWorkspaceData] = useState({
    name: activeWorkspace?.name,
    color: activeWorkspace?.color || '#0E9CE2',
    icon: activeWorkspace?.icon,
    iconId: activeWorkspace?.iconId,
  });
  const workspaceRole = user?.workspaces?.find(
    (item) => item.workspaceId === activeWorkspace.id
  )?.role;
  const { color: colorSelected, icon: iconUrl, iconId: iconNumber, name } = workspaceData;
  const [newWorkspaceName, setNewWorkspaceName] = useState(name);
  const [previewImage, setPreviewImage] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (newCustomIcon && newCustomIcon !== '') {
      setPreviewImage(URL.createObjectURL(newCustomIcon));
    } else {
      setPreviewImage(null);
    }
  }, [newCustomIcon]);

  const save = async () => {
    let newIconUrl = workspaceData.icon || null;
    if (newCustomIcon) {
      const fmData = new FormData();
      fmData.append('file', newCustomIcon);
      const response = await uploadWorkspaceIconAsync(activeWorkspace.id, fmData);
      newIconUrl = response.picture;
    }
    await modifyWorkspaceAsync(activeWorkspace.id, { ...workspaceData, icon: newIconUrl })
      .then(() => {
        setSavedWorkspace({
          ...workspaceData,
        });

        const userWorkspaces = workspacesList.map((workspace) => {
          if (workspace.id === activeWorkspace.id) {
            return {
              ...workspace,
              ...workspaceData,
              icon: newIconUrl,
            };
          }
          return workspace;
        });

        dispatch(setWorkspacesList(userWorkspaces));

        setLocalStorageWorkspace({
          id: workspaceId,
          favorite: activeWorkspace.favorite,
          name: workspaceData.name,
          icon: workspaceData.icon,
          color: workspaceData.color,
          iconId: workspaceData.iconId,
        });
        notification.open({
          message: t('PERSONALIZE_WORKSPACE_SUCCESS'),
          icon: <i className="fas fa-check" style={{ color: '#58DDA6' }} />,
        });
      })
      .catch(() => {
        notification.open({
          message: t('PERSONALIZE_WORKSPACE_ERROR'),
          type: 'error',
        });
      });
  };

  const cancelChanges = () => {
    setWorkspaceData({ ...savedWorkspace });
  };

  const changeWorkspaceName = () => {
    setNewWorkspaceName(name);
    setShowModalEditWorkspaceName(true);
  };

  const changeWorkspaceNameOk = () => {
    setShowModalEditWorkspaceName(false);
    setWorkspaceData({
      ...workspaceData,
      name: newWorkspaceName,
    });
  };

  return (
    <div data-testid="workspace-tab">
      <div className="d-flex flex-column w-100">
        <span className="font-montserrat font-weight-600 text-base mt-2">
          {t('PERSONALIZE_WORKSPACE_TITLE')}
        </span>
        <div className="bar-custom-color w-100 mt-2" style={{ backgroundColor: colorSelected }} />
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-row align-items-center mt-2">
            <div
              className="d-flex justify-content-center align-items-center text-white logo-brand-squared text-xl"
              style={{
                backgroundColor: colorSelected,
              }}
            >
              {iconUrl || previewImage ? (
                <img
                  src={previewImage ?? iconUrl}
                  alt="icon-brand"
                  className="custom-icon-preview"
                />
              ) : null}

              {!iconNumber && !iconUrl && !previewImage && name?.slice(0, 1).toUpperCase()}

              {!iconUrl && iconNumber && (
                <CustomIcon color="white">
                  {iconsMaps.find((icon) => icon.id === iconNumber)?.icon}
                </CustomIcon>
              )}
            </div>
            <span className="font-montserrat font-weight-600 text-base ml-3 mr-2 text-capitalize">
              {name}
            </span>
            <button
              data-testid="modal-edit-workspace-name-button"
              type="button"
              className="border-0"
              onClick={changeWorkspaceName}
            >
              <EditIcon />
            </button>
          </div>
          {workspaceRole === PartnerWorkspaceRole.OWNER && (
            <OverlayTrigger placement="top" overlay={<Tooltip>{t('TRANSFER_OWNERSHIP')}</Tooltip>}>
              <div className="mt-3">
                <button
                  className="px-3 bg-transparent border-0 font-montserrat font-weight-500 text-sm text-gray-4 text-underline"
                  type="button"
                  onClick={() => {
                    history.push(CONFIGURATION_MEMBERS);
                  }}
                >
                  <TransferOwnership />
                </button>
              </div>
            </OverlayTrigger>
          )}
        </div>
        <div className="d-flex flex-row justify-content-center mt-4">
          {[
            <ColorPicker
              key={1}
              workspaceData={workspaceData}
              setWorkspaceData={setWorkspaceData}
            />,
            <IconPicker
              key={2}
              color={colorSelected}
              workspaceData={workspaceData}
              setWorkspaceData={setWorkspaceData}
              previewImage={previewImage}
              newCustomIcon={newCustomIcon}
              setNewCustomIcon={setNewCustomIcon}
            />,
          ].map((component, index) => (
            <div
              className={`d-flex flex-column w-50 p-3 container-picker-customize ${
                index ? 'ml-3' : ''
              }`}
              key={component.key}
            >
              {component}
            </div>
          ))}
        </div>
      </div>
      {showModalEditWorkspaceName && (
        <ModalCustom
          datatestid="modal-edit-workspace-name"
          datatestidbuttonok="button-ok"
          datatestidbuttoncancel="button-cancel"
          title={t('PERSONALIZE_WORKSPACE_CHANGE_NAME')}
          htmlSubtitle
          show={showModalEditWorkspaceName}
          setShow={setShowModalEditWorkspaceName}
          onOkText={t('SAVE')}
          onCancelText={t('CANCEL')}
          onCancel={() => setShowModalEditWorkspaceName(false)}
          onOk={() => changeWorkspaceNameOk()}
          classModal="modal-edit-workspace"
          showCross={false}
          input={
            <input
              ref={(ref) => ref && ref.focus()}
              className="input-name-workspace input outline-none px-3 py-2 font-montserrat input-search text-base mx-auto mt-3 mb-2"
              type="text"
              value={newWorkspaceName}
              onChange={(e) => {
                setNewWorkspaceName(e.target.value);
              }}
              data-testid="workspace-personalize-input-name"
              placeholder={t('CREATE_WORKSPACE_INPUT_NAME')}
            />
          }
        />
      )}
      <div className="d-flex flex-row justify-content-between mt-5 mb-3">
        <button
          onClick={cancelChanges}
          type="submit"
          data-testid="workspace-personalize-cancel-button"
          className="float-right mt-3 border-0 px-4 py-1 bg-transparent"
          style={{ borderRadius: '50px' }}
        >
          <span className="font-montserrat text-xs font-weight-700 text-tab-selected">
            {t('CANCEL')}
          </span>
        </button>
        <button
          onClick={save}
          type="submit"
          data-testid="workspace-personalize-save-button"
          className="float-right mt-3 button-save-profile border-0 px-4 py-1"
        >
          <span className="font-montserrat text-xs font-weight-700 text-white">{t('SAVE')}</span>
        </button>
      </div>
    </div>
  );
};

export default Workspace;
