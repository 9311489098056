import React from 'react';

const PinIcon = ({ fill, classNames }) => (
  <svg
    className={classNames}
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.81463 5.70386V1.98263H9.55887C9.96821 1.98263 10.3031 1.64772 10.3031 1.23839C10.3031 0.829051 9.96821 0.494141 9.55887 0.494141L2.11642 0.494141C1.70709 0.494141 1.37217 0.829051 1.37217 1.23839C1.37217 1.64772 1.70709 1.98263 2.11642 1.98263L2.86067 1.98263L2.86067 5.70386C2.86067 6.9393 1.86338 7.93659 0.62793 7.93659V9.42508H5.07107V14.6348L5.81532 15.379L6.55956 14.6348V9.42508H11.0474V7.93659C9.81192 7.93659 8.81463 6.9393 8.81463 5.70386Z"
      fill={fill}
    />
  </svg>
);

export default PinIcon;
