const HomeSidebarIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="30" viewBox="0 0 24 21">
    <path
      fill="black"
      stroke="current-color"
      d="M5.3 19.7V9.65L12 4.6l6.7 5.05V19.7h-4.9v-6.25h-3.6v6.25ZM6 19h3.5v-6.25h5V19H18v-9l-6-4.5L6 10Zm6-6.75Z"
    />
  </svg>
);

export default HomeSidebarIcon;
