const OtherCheck = () => {
  return (
    <svg width="9" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.69922 6.58516L5.89922 10.7852L14.2992 1.78516"
        stroke="#BCBCBC"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default OtherCheck;
