import { Window } from '../../utils/window';
import {
  SET_LANGUAGE,
  SET_REFS,
  SET_SIDEBAR,
  SET_TOUR_RUNNING,
  SET_TOUR_STEP,
  SET_KEEP_TESTS_FILTERS,
  SET_MODAL_DATA,
} from '../actionTypes';

const initialState = {
  sidebar: {
    collapsed: true,
  },
  refs: {
    dropdown: undefined,
  },
  language: Window()?.localStorage?.getItem('language') ?? 'es',
  /* tour: !Window()?.localStorage.getItem('partners-tour'), */
  tour: false,
  tourStep: 0,
  keepTestsFilters: {},
  modalData: undefined,
};

function uxReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SIDEBAR: {
      return { ...state, ...action.payload };
    }
    case SET_LANGUAGE: {
      return { ...state, ...action.payload };
    }
    case SET_TOUR_RUNNING: {
      return { ...state, ...action.payload };
    }
    case SET_TOUR_STEP: {
      return { ...state, ...action.payload };
    }
    case SET_REFS: {
      return { ...state, ...action.payload };
    }
    case SET_KEEP_TESTS_FILTERS: {
      return { ...state, ...action.payload };
    }
    case SET_MODAL_DATA: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}

export default uxReducer;
