import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useRouteSelected = (routes = []) => {
  const [selected, setSelected] = useState('');
  const location = useLocation();

  useEffect(() => {
    routes.forEach((route) => {
      if (route?.path === location.pathname) setSelected(route.key);
      if (route?.subRoutes)
        route?.subRoutes?.forEach((subRoute) => {
          if (subRoute.path === location.pathname) setSelected(route.key);
          if (subRoute.partial && location.pathname.includes(subRoute.path)) setSelected(route.key);
        });
    });
  }, [location]);

  return { selected };
};

export default useRouteSelected;
