const Icon1 = ({ color = '#BCBCBC' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.4976 1.50245C22.4976 0.897824 22.132 0.349447 21.5695 0.115097C21.0071 -0.119253 20.365 0.0119832 19.9338 0.438499L17.8902 2.48671C15.6405 4.73647 12.5893 6.00196 9.40679 6.00196H8.99902H7.49918H2.99967C1.34517 6.00196 0 7.34712 0 9.00163V13.5011C0 15.1556 1.34517 16.5008 2.99967 16.5008V22.5002C2.99967 23.3298 3.66991 24 4.49951 24H7.49918C8.32878 24 8.99902 23.3298 8.99902 22.5002V16.5008H9.40679C12.5893 16.5008 15.6405 17.7663 17.8902 20.0161L19.9338 22.0596C20.365 22.4908 21.0071 22.6173 21.5695 22.383C22.132 22.1486 22.4976 21.6049 22.4976 20.9956V14.0823C23.3693 13.6699 23.9974 12.5591 23.9974 11.2514C23.9974 9.94372 23.3693 8.8329 22.4976 8.42044V1.50245ZM19.4979 5.09737V11.2514V17.4054C16.7419 14.8979 13.147 13.5011 9.40679 13.5011H8.99902V9.00163H9.40679C13.147 9.00163 16.7419 7.60491 19.4979 5.09737Z"
      fill={color}
    />
  </svg>
);

export default Icon1;
