const Icon8 = ({ color = '#BCBCBC' }) => (
  <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.8795 2.09098C15.1561 1.44881 14.9499 0.698837 14.3827 0.286351C13.8155 -0.126134 13.0421 -0.0886353 12.5124 0.370724L0.512863 10.8704C0.0441291 11.2828 -0.124615 11.9438 0.0956897 12.525C0.315994 13.1062 0.878474 13.4999 1.50189 13.4999H6.72827L3.12371 21.909C2.84715 22.5512 3.0534 23.3012 3.62057 23.7136C4.18773 24.1261 4.96114 24.0886 5.49081 23.6293L17.4904 13.1296C17.9591 12.7172 18.1279 12.0562 17.9076 11.475C17.6873 10.8938 17.1295 10.5047 16.5014 10.5047H11.275L14.8795 2.09098Z"
      fill={color}
    />
  </svg>
);

export default Icon8;
