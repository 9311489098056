import * as Yup from 'yup';

const schema = Yup.object().shape({
  firstName: Yup.string()
    .required('NAME_REQUIRED_ERROR')
    .min(3, 'NAME_LENGTH_ERROR')
    .max(100, 'NAME_LENGTH_ERROR'),
  lastName: Yup.string()
    .required('LAST_NAME_REQUIRED_ERROR')
    .min(3, 'LAST_NAME_LENGTH_ERROR')
    .max(100, 'LAST_NAME_LENGTH_ERROR'),
  companyRequest: Yup.string()
    .required('COMPANY_REQUIRED_ERROR')
    .min(2, 'COMPANY_LENGTH_ERROR')
    .max(100, 'COMPANY_LENGTH_ERROR'),
});

export default schema;
