const Icon7 = ({ color = '#BCBCBC' }) => (
  <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.8 0C0.80625 0 0 0.80625 0 1.8V13.8C0 14.7938 0.80625 15.6 1.8 15.6H2.4C2.4 17.5875 4.0125 19.2 6 19.2C7.9875 19.2 9.6 17.5875 9.6 15.6H14.4C14.4 17.5875 16.0125 19.2 18 19.2C19.9875 19.2 21.6 17.5875 21.6 15.6H22.8C23.4638 15.6 24 15.0638 24 14.4C24 13.7363 23.4638 13.2 22.8 13.2V10.8V9.6V8.89875C22.8 8.26125 22.5487 7.65 22.0988 7.2L19.2 4.30125C18.75 3.85125 18.1388 3.6 17.5013 3.6H15.6V1.8C15.6 0.80625 14.7938 0 13.8 0H1.8ZM15.6 6H17.5013L20.4 8.89875V9.6H15.6V6ZM4.2 15.6C4.2 15.1226 4.38964 14.6648 4.72721 14.3272C5.06477 13.9896 5.52261 13.8 6 13.8C6.47739 13.8 6.93523 13.9896 7.27279 14.3272C7.61036 14.6648 7.8 15.1226 7.8 15.6C7.8 16.0774 7.61036 16.5352 7.27279 16.8728C6.93523 17.2104 6.47739 17.4 6 17.4C5.52261 17.4 5.06477 17.2104 4.72721 16.8728C4.38964 16.5352 4.2 16.0774 4.2 15.6ZM18 13.8C18.4774 13.8 18.9352 13.9896 19.2728 14.3272C19.6104 14.6648 19.8 15.1226 19.8 15.6C19.8 16.0774 19.6104 16.5352 19.2728 16.8728C18.9352 17.2104 18.4774 17.4 18 17.4C17.5226 17.4 17.0648 17.2104 16.7272 16.8728C16.3896 16.5352 16.2 16.0774 16.2 15.6C16.2 15.1226 16.3896 14.6648 16.7272 14.3272C17.0648 13.9896 17.5226 13.8 18 13.8Z"
      fill={color}
    />
  </svg>
);

export default Icon7;
