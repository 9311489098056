const EvaluationIcon = () => (
  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 1.55583C0 0.697691 0.697691 0 1.55583 0H5.44539V3.11165C5.44539 3.54193 5.79302 3.88956 6.2233 3.88956H9.33495V6.9453L7.22487 9.05539C6.97447 9.30578 6.79944 9.61695 6.71436 9.95971L6.25977 11.7805C6.20385 12.0042 6.21601 12.2375 6.29137 12.449H1.55583C0.697691 12.449 0 11.7513 0 10.8932V1.55583ZM9.33495 3.11165H6.2233V0L9.33495 3.11165ZM13.3655 5.72981L13.7156 6.07987C14.0948 6.45911 14.0948 7.07414 13.7156 7.45581L13.0009 8.17052L11.2749 6.44452L11.9896 5.72981C12.3688 5.35058 12.9839 5.35058 13.3655 5.72981ZM7.58222 10.1372L10.723 6.99635L12.449 8.72235L9.30821 11.8607C9.20854 11.9604 9.08456 12.0309 8.946 12.0649L7.48498 12.4296C7.35128 12.4636 7.21271 12.4247 7.11547 12.3275C7.01823 12.2302 6.97934 12.0917 7.01337 11.958L7.37802 10.497C7.41205 10.3608 7.48255 10.2344 7.58222 10.1347V10.1372Z"
      fill="#BCBCBC"
    />
  </svg>
);

export default EvaluationIcon;
