import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import store from './redux/store';
import { setWhitelabel } from './redux/actions/authActions';

if (!['dev', 'localhost'].includes(process.env.RAZZLE_RUNTIME_ENV)) {
  Sentry.init({
    dsn: 'https://f62092cb7e474b45ba0a892397d0570d@o966663.ingest.sentry.io/5939705',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.RAZZLE_RUNTIME_ENV,
    tracesSampleRate: 1,
  });
}

if (window) {
  store.dispatch(setWhitelabel(window.whitelabel));
  hydrate(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  );
}

if (module.hot) {
  module.hot.accept();
}
