import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { TrackEvent } from '../../utils/filters/segmentUtils';
import { t } from '../../i18n/i18n';
import './SideBarDropDown.css';

const SideBarDropDown = ({
  options,
  icon,
  collapsed,
  buttonText,
  imgClassName,
  imgWidth = '25',
  imgPadding = 'pr-1',
  selected,
  onClick,
  responsive,
  isMobile = false,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (collapsed) {
      setShow(false);
    }
  }, [collapsed]);

  return (
    <div
      onKeyDown={() => {}}
      className={`position-relative cursor-pointer ${selected && 'sidebar-active'}`}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      role="menuitem"
      tabIndex="0"
      onClick={onClick}
    >
      <div className="nav-links__item ml-1 position-relative d-flex align-items-center text-white">
        <div
          height="25"
          width={imgWidth}
          className={`mr-3 ${imgPadding} ${imgClassName || 'ml-3'} ${
            selected ? 'icon-selected' : 'icon-normal'
          }`}
        >
          {icon}
        </div>
        <div
          className={`list-button border-0 py-2 position-relative d-flex justify-content-between align-items-center bg-transparent text-nowrap w-100 ${
            collapsed ? 'd-none ml-3' : 'pr-3'
          }`}
        >
          <span
            className={`link_name font-roboto ml-0 ${collapsed && 'collapsed overflow-hidden'}`}
          >
            {buttonText}
          </span>
        </div>
        {collapsed && (
          <ul
            className={`link-list collapsed-list position-absolute overflow-hidden top-0 list-unstyled py-2 
            ${show && 'show-list px-3'}
            `}
          >
            <span
              className={`text-white text-nowrap text-base ml-0 font-weight-bold d-block ${
                options && 'pb-1'
              }`}
            >
              {buttonText}
            </span>
            {options &&
              options.map(
                (option, index) =>
                  !!option.label && (
                    <li
                      onClick={() => {
                        if (option?.track) {
                          TrackEvent(option.track);
                        }
                      }}
                      key={index}
                      id={`${isMobile ? `${option?.label}-mobile` : option?.label}`}
                      onKeyPress={() => {}}
                      role="button"
                      className="m-0 py-2 list-item text-white font-weight-bold"
                    >
                      <Link className="font-roboto text-sm" to={option.path}>
                        {t(option.label)}
                      </Link>
                    </li>
                  )
              )}
          </ul>
        )}
      </div>
      {!collapsed && (
        <ul
          className={`link-list overflow-hidden list-unstyled pl-4 w-100 ${collapsed && 'd-none'} ${
            responsive && 'bg-transparent'
          }`}
        >
          {options &&
            options.map(
              (option, index) =>
                !!option.label && (
                  <li
                    key={index}
                    className={`m-0 py-2 pl-4 ml-1 list-item text-white font-weight-bold ${
                      collapsed && 'd-none'
                    }`}
                    id={`${isMobile ? `${option?.label}-mobile` : option?.label}`}
                  >
                    <Link className="font-roboto pl-2 ml-1 text-sm" to={option.path}>
                      {t(option.label)}
                    </Link>
                  </li>
                )
            )}
        </ul>
      )}
    </div>
  );
};

export default SideBarDropDown;
