import {
  GET_PROFILES,
  GET_PROFILE,
  SET_PROFILES,
  SET_PROFILE,
  SET_PROFILES_FILTERS,
  SET_APPLIED_FILTERS,
  SET_PROFILES_NEXT_URL,
  SET_PROFILES_QUERY,
  PROCESS_PROFILES_QUERY,
  REFRESH_PROFILES,
  SET_TEXT_QUERY,
  SET_AGE_QUERY,
  SET_PROFILES_LOADING,
  CLEAR_APPLIED_FILTERS,
  GET_TECHNOLOGIES,
  SET_TECHNOLOGIES,
  SET_CERTIFIABLE_TECHNOLOGIES,
  SET_TOTAL_PROFILES,
  GET_SOME_PROFILES,
  SET_SOME_PROFILES,
  GET_ALKYMERS_STATS,
  SET_ALKYMERS_STATS,
  GET_ALKYMER_ADVANCE,
  SET_ALKYMER_ADVANCE,
  GET_TECHNOLOGY_ADVANCE,
  GET_SOME_PROFILES_FILTERS,
  SET_SOME_PROFILES_FILTERS,
  SET_SOME_PROFILES_APPLIED_FILTERS,
  SET_SOME_PROFILES_NEXT_URL,
  GET_FAVORITE_PROFILES,
  SET_FAVORITE_PROFILES,
  GET_NEWS,
  SET_NEWS,
  GET_TOP_BY_TECHNOLOGY,
  SET_TOP_BY_TECHNOLOGY,
  SET_TOP_LOADING,
  SET_PROFILES_ORDERS,
  SET_SELECTED_VIDEO,
  GET_USEFUL_CONTENT,
  SET_USEFUL_CONTENT,
  GET_MOST_HIRES_COMPANIES,
  SET_MOST_HIRES_COMPANIES,
  SET_MOST_HIRES_COMPANIES_LOADING,
  SET_ENGAGEMENT_QUERY,
  SET_SAVED_SEARCHES,
  SET_ORDER,
  SET_INVITED_PROFILE,
  SET_INVITED_PROFILE_TEST_GROUPS,
  SET_SHOW_FIRST_FILTER_MODAL,
  SET_TECHNOLOGY_TYPES,
  SET_PROGRESS,
} from '../actionTypes';

export const getProfiles = (nextUrl = '') => ({
  type: GET_PROFILES,
  payload: { nextUrl },
});

export const getProfile = (id) => ({
  type: GET_PROFILE,
  payload: { id },
});

export const setProgress = (id) => ({
  type: SET_PROGRESS,
  payload: { id },
});

export const setProfiles = (profiles) => ({
  type: SET_PROFILES,
  payload: { profiles },
});

export const setProfile = (profile) => ({
  type: SET_PROFILE,
  payload: { profile },
});

export const setTotalProfiles = (total) => ({
  type: SET_TOTAL_PROFILES,
  payload: { total },
});

export const setProfilesFilters = (availableFilters) => ({
  type: SET_PROFILES_FILTERS,
  payload: { availableFilters },
});

export const setProfilesOrders = (availableOrder) => ({
  type: SET_PROFILES_ORDERS,
  payload: availableOrder,
});

export const setAppliedFilters = (appliedFilters) => ({
  type: SET_APPLIED_FILTERS,
  payload: { appliedFilters },
});

export const setProfilesNextUrl = (nextUrl) => ({
  type: SET_PROFILES_NEXT_URL,
  payload: { nextUrl },
});

export const setProfilesQuery = (query) => ({
  type: SET_PROFILES_QUERY,
  payload: { query },
});

export const processProfilesQuery = () => ({
  type: PROCESS_PROFILES_QUERY,
});

export const refreshProfiles = (refresh) => ({
  type: REFRESH_PROFILES,
  payload: { refresh },
});

export const setAgeQuery = ({ min, max }) => ({
  type: SET_AGE_QUERY,
  payload: { ageQuery: { min, max } },
});

export const setEngagementQuery = ({ minEngagement, maxEngagement }) => ({
  type: SET_ENGAGEMENT_QUERY,
  payload: { engagementQuery: { minEngagement, maxEngagement } },
});

export const setTextQuery = (textQuery) => ({
  type: SET_TEXT_QUERY,
  payload: { textQuery },
});

export const setProfilesLoading = (loading) => ({
  type: SET_PROFILES_LOADING,
  payload: { loading },
});

export const clearAppliedFilters = () => ({
  type: CLEAR_APPLIED_FILTERS,
});

export const getTechnologies = (certifiable) => ({
  type: GET_TECHNOLOGIES,
  payload: { certifiable },
});

export const setTechnologies = (technologies) => ({
  type: SET_TECHNOLOGIES,
  payload: { technologies },
});

export const setTechnologyTpes = (technologyTypes) => ({
  type: SET_TECHNOLOGY_TYPES,
  payload: { technologyTypes },
});

export const setCertifiableTechnologies = (certifiableTechnologies) => ({
  type: SET_CERTIFIABLE_TECHNOLOGIES,
  payload: { certifiableTechnologies },
});

export const getSomeProfiles = (params) => ({
  type: GET_SOME_PROFILES,
  payload: { params },
});

export const setSomeProfiles = (someProfiles) => ({
  type: SET_SOME_PROFILES,
  payload: { someProfiles },
});

export const getSomeProfilesFilters = () => ({
  type: GET_SOME_PROFILES_FILTERS,
});

export const setSomeProfilesFilters = (available) => ({
  type: SET_SOME_PROFILES_FILTERS,
  payload: { available },
});

export const setSomeProfilesAppliedFilters = (applied) => ({
  type: SET_SOME_PROFILES_APPLIED_FILTERS,
  payload: { applied },
});

export const setSomeProfilesNextUrl = (someProfilesNextUrl) => ({
  type: SET_SOME_PROFILES_NEXT_URL,
  payload: { someProfilesNextUrl },
});

export const getAlkymersStats = (params, stopLoading) => ({
  type: GET_ALKYMERS_STATS,
  payload: { params, stopLoading },
});

export const setAlkymersStats = (stats) => ({
  type: SET_ALKYMERS_STATS,
  payload: { stats },
});

export const getAlkymerAdvance = (id, jobreadyId) => ({
  type: GET_ALKYMER_ADVANCE,
  payload: { id, jobreadyId },
});

export const getTechnologyAdvance = (id) => ({
  type: GET_TECHNOLOGY_ADVANCE,
  payload: { id },
});

export const setAlkymerAdvance = (alkymerAdvance) => ({
  type: SET_ALKYMER_ADVANCE,
  payload: { alkymerAdvance },
});

export const getFavoritesProfiles = () => ({
  type: GET_FAVORITE_PROFILES,
});

export const setFavoriteProfiles = (profiles) => ({
  type: SET_FAVORITE_PROFILES,
  payload: profiles,
});

export const getNews = (limitRecomendation) => ({
  type: GET_NEWS,
  payload: { limitRecomendation },
});

export const setNews = (news) => ({
  type: SET_NEWS,
  payload: news,
});

export const getTopByTech = (id) => ({
  type: GET_TOP_BY_TECHNOLOGY,
  payload: { id },
});

export const setTopByTech = (profiles) => ({
  type: SET_TOP_BY_TECHNOLOGY,
  payload: profiles,
});

export const setTopLoading = (topLoading) => ({
  type: SET_TOP_LOADING,
  payload: { topLoading },
});

export const setSelectedVideo = (videoUrl) => ({
  type: SET_SELECTED_VIDEO,
  payload: videoUrl,
});

export const getUsefulContent = () => ({
  type: GET_USEFUL_CONTENT,
});

export const setUsefulContent = (videos) => ({
  type: SET_USEFUL_CONTENT,
  payload: videos,
});

export const getMostHireCompanies = () => ({
  type: GET_MOST_HIRES_COMPANIES,
});

export const setMostHireCompanies = (companies) => ({
  type: SET_MOST_HIRES_COMPANIES,
  payload: companies,
});

export const setMostHireCompaniesLoading = (loading) => ({
  type: SET_MOST_HIRES_COMPANIES_LOADING,
  payload: loading,
});

export const setSavedSearches = (searches) => ({
  type: SET_SAVED_SEARCHES,
  payload: searches,
});

export const setOrder = (order) => ({
  type: SET_ORDER,
  payload: order,
});

export const setInvitedProfile = (invitedProfile) => ({
  type: SET_INVITED_PROFILE,
  payload: { invitedProfile },
});

export const setInvitedProfileTestGroups = (invitedProfileTestGroups) => ({
  type: SET_INVITED_PROFILE_TEST_GROUPS,
  payload: { invitedProfileTestGroups },
});

export const setShowFirstFilterModal = (showFirstFilterModal) => ({
  type: SET_SHOW_FIRST_FILTER_MODAL,
  payload: { showFirstFilterModal },
});
