import React, { useEffect } from 'react';
import useCreateScreening from '../../hooks/useCreateScreening';
import { t } from '../../i18n/i18n';
import MultipleSelect from '../MultipleSelect/MultipleSelect';
import { file } from '@babel/types';
import { Checkbox } from 'antd';

const QuestionWithFiles = ({ testGroupABM }) => {
  const { fileTypes, currentQuestionData, handleAddFileTypes, handleAddQuestionText } =
    useCreateScreening();
  return (
    <div className="d-flex flex-column align-items-start gap-2">
      <textarea
        type="text"
        onChange={handleAddQuestionText}
        name="text"
        value={currentQuestionData?.text ?? ''}
        className={`w-100 mb-1 py-2 px-3 bottom-border outline-none text-sm text-center ${
          currentQuestionData?.text?.length ? 'selected-border' : 'unselected-border'
        }`}
        placeholder={t('CREATE_SCREENING_YOUR_QUESTIONS_WRITE_YOU_QUESTION_PLACEHOLDER')}
        rows="1"
        data-testid="question-wf-textarea"
      />
      {testGroupABM && (
        <textarea
          type="text"
          onChange={handleAddQuestionText}
          name="formula"
          value={currentQuestionData?.formula ?? ''}
          className={`w-100 mt-3 py-2 px-3 font-montserrat maya-blue-thin-border outline-none text-xs min-height-add-question ${
            currentQuestionData?.formula?.length ? 'selected-border' : 'unselected-border'
          }`}
          rows="1"
          maxLength={500}
          placeholder={t('CREATE_SCREENING_YOUR_FORMULAS_PLACEHOLDER')}
          data-testid="question-mc-add-formula-textarea"
        />
      )}
      <p>{t('CREATE_SCREENING_YOUR_QUESTIONS_FILE_MULTIPLE_SELECT_PLACEHOLDER')}</p>
      <div className="d-flex flex-wrap gap-2">
        {Boolean(fileTypes.length) &&
          fileTypes.map((fileType, index) => (
            <div
              className="d-flex rounded-lg bg-blue-light py-2 px-4 gap-1 align-items-center justify-content-center text-sm font-weight-500 color-grey-1 cursor-pointer"
              onClick={() => handleAddFileTypes(fileType.id)}
            >
              <Checkbox
                //  onChange={()=> handleAddFileTypes(fileType.id)}
                className="checkbox-files-item"
                checked={currentQuestionData?.fileTypes?.includes(fileType.id)}
              />
              <div>
                {fileType?.displayName?.split('/').map((item, index) => (
                  <p
                    key={item}
                    className={`mb-0 ${index == 1 && 'font-weight-400'}`}
                    data-testid="question-wf-your-files-span"
                  >
                    {item}
                  </p>
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default QuestionWithFiles;
