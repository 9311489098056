const Star = ({ selected = false, color = '#303030', width = 26, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.7908 8.03866L16.9432 7.04035L13.8827 0.834866C13.3343 -0.271247 11.7454 -0.285308 11.1924 0.834866L8.13183 7.04035L1.28424 8.03866C0.0562652 8.21677 -0.435861 9.73064 0.454654 10.5977L5.40873 15.4253L4.237 22.2447C4.02609 23.4774 5.32436 24.4007 6.41173 23.8242L12.5375 20.6043L18.6633 23.8242C19.7507 24.396 21.049 23.4774 20.8381 22.2447L19.6663 15.4253L24.6204 10.5977C25.5109 9.73064 25.0188 8.21677 23.7908 8.03866ZM17.2526 14.6378L18.3634 21.1245L12.5375 18.064L6.71169 21.1245L7.82249 14.6378L3.10745 10.0447L9.62227 9.09791L12.5375 3.19239L15.4528 9.09791L21.9676 10.0447L17.2526 14.6378Z"
      fill={color}
      stroke={color}
      strokeWidth="0.5"
    />
    <path
      d="M17.2526 14.6378L18.3634 21.1245L12.5375 18.064L6.71169 21.1245L7.82249 14.6378L3.10745 10.0447L9.62227 9.09791L12.5375 3.19239L15.4528 9.09791L21.9676 10.0447L17.2526 14.6378Z"
      fill={selected ? (color !== '#303030' ? color : '#FFD018') : 'none'}
      stroke={selected && color !== '#303030' ? color : 'none'}
      strokeWidth="0.5"
    />
  </svg>
);

export default Star;
