import Arrow from '../../assets/modal/Arrow';
import Accordion from '../Accordion/Accordion';

const Collapsed = ({ collapsedSelected, setCollapsedSelected, index, options }) => {
  const handleShow = (selectedIndex) => {
    if (collapsedSelected.includes(selectedIndex)) {
      setCollapsedSelected(collapsedSelected?.filter((selected) => selected !== selectedIndex));
    } else {
      setCollapsedSelected([...collapsedSelected, selectedIndex]);
    }
  };
  return (
    <div className="position-relative b-radius-8 description-container mt-3 px-4 py-3 bg-white mx-auto">
      <div
        className="d-flex flex-row justify-content-between cursor-pointer"
        aria-hidden="true"
        onClick={() => handleShow(index)}
      >
        <span className="font-montserrat font-weight-600 text-base text-black">
          {options?.title}
        </span>
        <Arrow
          className={`mt-1 ${
            collapsedSelected.includes(index)
              ? 'arrow-dropdown-modal-collapsed'
              : 'arrow-dropdown-modal-uncollapsed'
          }`}
        />
      </div>
      <Accordion open={collapsedSelected.includes(index)} maxHeight="100">
        {options?.content}
      </Accordion>
    </div>
  );
};

export default Collapsed;
