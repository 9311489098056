import React, { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import BackgroundTop from '../components/BackgroundTop/BackgroundTop';
import ProfileListItem from '../components/ProfileListItem/ProfileListItem';
import EmptyComponent from '../components/EmptyComponent/EmptyComponent';
import { useElementSize } from '../hooks/useElementSize';
import { t } from '../i18n/i18n';
import { getFavoritesProfiles, setFavoriteProfiles } from '../redux/actions/profilesActions';
import { spaceSeparatedIncludes } from '../utils/spaceSeparatedIncludes';
import './Favorites.scss';
import Spinner from '../components/spinner/Spinner';
import { breadcrumbAlkemyCommunity } from '../constants/breadcrumb';

const Favorites = () => {
  const { favoriteProfiles, loading } = useSelector((state) => state.profiles);
  const [profiles, setProfiles] = useState([]);
  const dispatch = useDispatch();
  const fetchNextUrlRef = useRef();
  const { ref: containerRef, size } = useElementSize();

  useEffect(() => {
    dispatch(getFavoritesProfiles());
  }, []);

  useEffect(() => {
    setProfiles(favoriteProfiles.filter((profile) => profile.favorite !== false));
  }, [favoriteProfiles]);

  const indicators = [
    {
      name: 'PROFILES_LIST_SCORE',
      className: 'favorites-list-score-width ml-2',
      orderKey: 'TECHNICAL_SCORE',
    },
    { name: 'PROFILES_LIST_TECHNOLOGY', className: 'favorites-list-tech-width ml-2' },
    {
      name: 'PROFILES_LIST_ENGLISH',
      className: 'favorites-list-english-width ml-2',
      orderKey: 'ENGLISH_LEVEL',
    },
    { name: 'PROFILES_LIST_COUNTRY', className: 'favorites-list-country-width ml-2' },
    { size: '500', className: 'favorites-list-actions-width' },
  ];

  return (
    <div data-testid="favorite-profiles-container" className="position-relative w-100">
      <BackgroundTop
        title={t('SAVED_PROFILES_TITLE')}
        routes={breadcrumbAlkemyCommunity}
        className="position-relative"
        titleClassname="text-xl"
      />
      <div className="w-100 min-vh-100 px-2 px-sm-3 px-md-4 px-lg-5">
        <div className="d-flex flex-column w-100 mx-auto mb-5 favorites-page-container">
          <div ref={containerRef} className="w-100" />
          <div
            className="non-selectable mx-auto bg-white px-2 py-3 px-md-3 rounded position-relative flex-grow-1 w-100 profiles-list-container"
            data-size={size}
          >
            {profiles?.length ? (
              <div className="min-width-100">
                <div className="d-flex flex-row justify-content-between text-uppercase align-items-center px-3">
                  {spaceSeparatedIncludes(size, '450') && (
                    <div className="py-1 d-flex favorites-list-name-width pr-1" />
                  )}
                  <div className="favorites-list-right-titles flex-grow-1">
                    <div className="d-flex justify-content-between align-items-center favorites-list-titles">
                      {indicators.map(
                        (indicator, i) =>
                          spaceSeparatedIncludes(size, indicator.size ?? '0') && (
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>{t(indicator.name)}</Tooltip>}
                              key={i}
                            >
                              <div className={`text-center ${indicator.className}`}>
                                <span className="position-relative">
                                  <span className="font-roboto font-weight-bold overflow-hidden text-truncate mw-100 d-inline-block">
                                    {t(indicator.name)}
                                  </span>
                                </span>
                              </div>
                            </OverlayTrigger>
                          )
                      )}
                    </div>
                  </div>
                </div>
                {profiles.map(
                  (profile, i) =>
                    (profile.favorite ?? true) && (
                      <div className="favorites-list-item-container my-2" key={profile?.id}>
                        <ProfileListItem
                          key={i}
                          name={`${profile?.firstName} ${profile?.lastName}`}
                          percent={
                            profile?.profileScore?.totalEngagement
                              ? Math.round(profile.profileScore.totalEngagement)
                              : 0
                          }
                          profileId={profile?.id}
                          english={profile?.languages ?? []}
                          country={profile?.address}
                          technology={profile?.profileScore?.technology}
                          alkymer={{ ...profile, favorite: profile.favorite ?? true }}
                          containerSize={size}
                          dispatchFn={setFavoriteProfiles}
                          stateKey="favoriteProfiles"
                          confirmDelete
                        />
                      </div>
                    )
                )}
                <div ref={fetchNextUrlRef} />
              </div>
            ) : loading ? (
              <Spinner show />
            ) : (
              <div className="bg-white position-relative description-container mx-auto py-3 px-3 rounded mx-5">
                <EmptyComponent
                  className="p-4"
                  title={t('FAVORITES_EMPTY_TITLE')}
                  subtitle={t('FAVORITES_EMPTY_SUBTITLE')}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Favorites;
