import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/header/logo.svg';
import { HOME } from '../../../constants/routes';

import { t } from '../../../i18n/i18n';

const Header = () => (
  <div className="header d-flex align-items-center justify-content-between flex-column flex-md-row  h-auto py-2">
    <div className="d-flex align-items-center flex-wrap py-2">
      <Link to={HOME}>
        <img
          src={logo}
          className="logo mr-4"
          alt={t('HEADER_LOGO')}
          style={{ height: 'auto', width: '10em' }}
        />
      </Link>
    </div>
    <div className="d-flex align-items-center flex-column flex-sm-row">
      <a
        href={process.env.RAZZLE_RUNTIME_ALKEMY_URL + '/talento/'}
        target="_blank"
        rel="noopener noreferrer"
        className="font-montserrat text-underline link cursor-pointer m-2"
      >
        {t('CERTIFICATES_LABEL_TALENT_APPLY_HERE')}
      </a>

      <a
        href={process.env.RAZZLE_RUNTIME_ALKEMY_URL + '/evaluaciones-en-linea/'}
        target="_blank"
        rel="noopener noreferrer"
        data-testid="create-evaluation-open-search-btn"
        className="align-self-end px-4 py-2 rounded-pill font-montserrat text-md text-white font-weight-bold border-0 outline-none bg-maya-blue m-1"
      >
        {t('CERTIFICATES_LABEL_COMPANY_SOLUTIONS')}
      </a>
    </div>
  </div>
);

export default Header;
