const ArrowInvite = () => (
  <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.53595 0.301866C6.77492 1.1386 9.55504 3.29791 11.2015 6.22648C11.7683 7.25215 12.4431 9.23602 12.5511 10.2212C12.5916 10.6261 12.659 11.1659 12.6995 11.4223L12.767 11.8812L14.0086 10.6531C14.6969 9.96479 15.3582 9.41146 15.4796 9.41146C15.925 9.41146 16.1274 9.57341 16.1274 9.93779C16.1274 10.4371 12.5781 14 12.0787 14C11.8223 14 11.2825 13.5411 9.87893 12.1376C8.05701 10.3157 7.81409 9.95129 8.19197 9.57341C8.54286 9.22252 8.92074 9.42496 10.2163 10.7205L11.5389 12.0296L11.4579 11.3009C11.3365 10.1402 10.9046 8.49375 10.4727 7.57605C8.90724 4.22911 5.6008 1.73241 2.17289 1.30055C-0.0539017 1.01714 8.01086e-05 1.03063 8.01086e-05 0.504301V0.0319519L1.14721 0.00496101C1.99744 -0.0220308 2.61825 0.0589437 3.53595 0.301866Z"
      fill="#1A1A1A"
    />
  </svg>
);

export default ArrowInvite;
