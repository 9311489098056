const CopyPasteLogo = () => (
  <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8504 0H1.83578C0.826099 0 0 0.826099 0 1.83578V14.6862H1.83578V1.83578H12.8504V0ZM15.6041 3.67155H5.50733C4.49765 3.67155 3.67155 4.49765 3.67155 5.50733V18.3578C3.67155 19.3674 4.49765 20.1935 5.50733 20.1935H15.6041C16.6138 20.1935 17.4399 19.3674 17.4399 18.3578V5.50733C17.4399 4.49765 16.6138 3.67155 15.6041 3.67155ZM15.6041 18.3578H5.50733V5.50733H15.6041V18.3578Z"
      fill="#52C0F7"
    />
  </svg>
);

export default CopyPasteLogo;
