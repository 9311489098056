const Clock = ({ className, size = '14' }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 14 14" fill="none">
    <path
      d="M0.769531 6.99902C0.769531 7.81709 0.930662 8.62715 1.24372 9.38295C1.55678 10.1387 2.01565 10.8255 2.59411 11.4039C3.17257 11.9824 3.8593 12.4413 4.6151 12.7543C5.3709 13.0674 6.18095 13.2285 6.99902 13.2285C7.81709 13.2285 8.62715 13.0674 9.38295 12.7543C10.1387 12.4413 10.8255 11.9824 11.4039 11.4039C11.9824 10.8255 12.4413 10.1387 12.7543 9.38295C13.0674 8.62715 13.2285 7.81709 13.2285 6.99902C13.2285 5.34686 12.5722 3.76236 11.4039 2.59411C10.2357 1.42585 8.65119 0.769531 6.99902 0.769531C5.34686 0.769531 3.76236 1.42585 2.59411 2.59411C1.42585 3.76236 0.769531 5.34686 0.769531 6.99902Z"
      stroke="#BCBCBC"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7 3.53711V6.99794L9.0765 9.07444"
      stroke="#BCBCBC"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Clock;
