import Cross from '../../assets/common/Cross';
import SearchIcon from '../../assets/form/SearchIcon';
import './FormInputSearch.scss';

const FormInputSearch = ({
  searchText,
  setSearchText,
  placeholder,
  className,
  onSubmit,
  onClear,
}) => (
  <form
    className={`form-container-input d-flex align-items-center py-2 px-3 mx-auto mb-1 ${
      searchText?.length ? 'selected-border' : ''
    } ${className}`}
    onSubmit={(e) => {
      e.preventDefault();
      onSubmit?.(searchText);
    }}
    data-testid="form-search"
  >
    <SearchIcon color={searchText?.length ? '#1aacf0' : '#424242'} />
    <input
      data-testid="form-input-search"
      className="border-0 input pl-2 font-montserrat input-search text-xs"
      onChange={(e) => {
        setSearchText(e.target.value);
      }}
      type="text"
      value={searchText}
      placeholder={placeholder}
    />
    {Boolean(searchText?.length) && (
      <button
        data-testid="cross-button-clear-filter"
        type="button"
        className="bg-transparent border-0 ml-auto d-flex"
        onClick={() => {
          setSearchText('');
          onSubmit?.('');
          onClear?.();
        }}
      >
        <Cross />
      </button>
    )}
  </form>
);

export default FormInputSearch;
