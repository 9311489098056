const CvIcon = ({ color = '#FD7779', className }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M16.4309 6.94447H10.417V5.55558H15.8198C15.6235 4.85672 15.5771 4.12426 15.6836 3.40621C15.7901 2.68816 16.0471 2.00071 16.4378 1.38892H5.55588C5.18752 1.38892 4.83426 1.53524 4.57379 1.79571C4.31332 2.05618 4.16699 2.40945 4.16699 2.7778V22.2222C4.16699 22.5906 4.31332 22.9439 4.57379 23.2043C4.83426 23.4648 5.18752 23.6111 5.55588 23.6111H19.4448C19.8131 23.6111 20.1664 23.4648 20.4269 23.2043C20.6873 22.9439 20.8337 22.5906 20.8337 22.2222V9.37503C19.9541 9.37517 19.089 9.15229 18.319 8.72723C17.549 8.30217 16.8994 7.6888 16.4309 6.94447ZM9.0281 18.0556H7.63921V16.6667H9.0281V18.0556ZM9.0281 15.2778H7.63921V13.8889H9.0281V15.2778ZM9.0281 12.5H7.63921V11.1111H9.0281V12.5ZM9.0281 9.72225H7.63921V8.33336H9.0281V9.72225ZM9.0281 6.94447H7.63921V5.55558H9.0281V6.94447ZM17.3614 18.0556H10.417V16.6667H17.3614V18.0556ZM17.3614 15.2778H10.417V13.8889H17.3614V15.2778ZM17.3614 12.5H10.417V11.1111H17.3614V12.5ZM17.3614 9.72225H10.417V8.33336H17.3614V9.72225Z"
      fill={color}
    />
    <path
      d="M20.8336 7.6389C22.7512 7.6389 24.3058 6.08434 24.3058 4.16668C24.3058 2.24902 22.7512 0.694458 20.8336 0.694458C18.9159 0.694458 17.3613 2.24902 17.3613 4.16668C17.3613 6.08434 18.9159 7.6389 20.8336 7.6389Z"
      fill="#FD7779"
    />
  </svg>
);

export default CvIcon;
