import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import BackgroundTop from '../components/BackgroundTop/BackgroundTop';
import timerIcon from '../assets/profile/timer.svg';
import TrainingSkills from '../components/TrainingSkills/TrainingSkills';
import Spinner from '../components/spinner/Spinner';
import {
  getAlkymerAdvance,
  getProfile,
  setAlkymerAdvance,
  setProfile,
} from '../redux/actions/profilesActions';
import './Results.scss';
import { t } from '../i18n/i18n';

const Results = () => {
  const dispatch = useDispatch();
  const { profile, alkymerAdvance, loading } = useSelector((state) => state.profiles);
  const { id } = useParams();
  const oneHundredPercent = `${process.env.RAZZLE_RUNTIME_ONE_HUNDRED_PERCENT}`;

  useEffect(() => {
    dispatch(getProfile(id));
    return () => {
      dispatch(setProfile(null));
      dispatch(setAlkymerAdvance(null));
    };
  }, [id]);
  useEffect(() => {
    if (profile) {
      const groups = profile?.groups;
      const jobreadyId = groups && groups[groups.length - 1]?.group?.jobReadyProfileId;
      dispatch(getAlkymerAdvance(id, jobreadyId));
    }
  }, [profile]);

  return (
    <div className="w-100 h-100">
      {loading || !profile ? (
        <Spinner show />
      ) : (
        <>
          <BackgroundTop
            title={t('RESULTS_SECTION_TITLE')}
            className="position-relative"
            titleClassname="text-xl"
          />
          <div className="px-2">
            <div className="bg-white description-container rounded mx-auto position-relative mt-4 p-4">
              <div className="border-bottom mb-4">
                <h3 className="font-montserrat font-weight-bold text-xl">
                  {profile?.firstName} {profile?.lastName}
                </h3>
              </div>
              {alkymerAdvance?.error ? (
                <div className="bg-gray d-flex align-items-center w-100 py-3 px-2 rounded font-montserrat font-weight-bold text-sm my-3">
                  <img src={timerIcon} alt={t('ICON_TIMER')} className="mr-2" />
                  {t('SKILLS_WAITING_ACCELERATION')}
                </div>
              ) : (
                <div className="w-100 d-flex flex-column flex-md-row">
                  <div className="results-left-column">
                    <TrainingSkills
                      className="px-4 pb-4"
                      skills={alkymerAdvance?.skills ?? []}
                      waiting={false}
                    />
                  </div>
                  <div className="results-right-column d-flex flex-column pl-3">
                    <div className="d-flex flex-column my-2">
                      <span className="text-sm text-muted font-weight-bold font-montserrat">
                        {t('RESULTS_PAGE_RESULT')}
                      </span>
                      <span className="text-muted font-montserrat">
                        <span className="font-weight-bold text-dark percent-result-text font-montserrat name-text mr-2">
                          {Math.round(alkymerAdvance?.totalEngagement)}
                        </span>
                        {t('RESULTS_PAGE_PERCENT_OF')} {oneHundredPercent}
                      </span>
                    </div>
                    <div className="d-flex flex-column my-2">
                      <span className="text-sm text-muted font-weight-bold font-montserrat">
                        {t('RESULTS_PAGE_TECHNOLOGY')}
                      </span>
                      <span className="font-weight-bold text-dark font-montserrat name-text">
                        {profile?.profileScore?.technology?.displayName}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Results;
