import { AnswerTypeEnum } from '../constants/enums';
/**
 * Calculates the minimum time required for a custom screening based on video answers.
 *
 * The minimum time is calculated as follows:
 *   minTimeInSeconds = Σ(maxAttempts * (preparationTime + totalTime)) + 15 (uploadTime)
 *
 * @param {Object} newScreening - The screening object containing custom test group information.
 * @returns {number} - The minimum time in seconds required for the custom screening.
 */
export const getMinimunScreeningTimeInSeconds = (newScreening) => {
  const videoAnswers = newScreening?.customTestGroup?.filter(
    (x) => x?.answerType == AnswerTypeEnum.VIDEO
  );

  // Tiempo minimo (s) = (intentos * (preparacion + grabacion)) + subida archivo
  const minCustomScreeningsTimeInSeconds = videoAnswers?.reduce((accumulator, currentValue) => {
    const { maxAttempts, preparationTime, totalTime } = currentValue;
    const maxTotalTime =
      Number(maxAttempts ?? 0) * (Number(preparationTime ?? 0) + Number(totalTime ?? 0)) + 15;
    return accumulator + maxTotalTime;
  }, 0);

  return minCustomScreeningsTimeInSeconds;
};

/**
 * Converts an object representing hours, minutes, and seconds to total seconds.
 *
 * @param {Object} timeObject - The object containing hours, minutes, and seconds.
 * @returns {number} - The total time in seconds.
 */
export const convertTimeObjectToSeconds = (timeObject) => {
  const { hours, minutes, seconds } = timeObject;

  // Convert hours, minutes, and seconds to total seconds
  const totalSeconds =
    parseInt(hours ?? 0, 10) * 60 * 60 +
    parseInt(minutes ?? 0, 10) * 60 +
    parseInt(seconds ?? 0, 10);

  return totalSeconds;
};

/**
 * Converts a string representing seconds to a formatted time string (hh:mm:ss).
 * @param {string} secondsString - The string representing seconds.
 * @returns {string} - The formatted time string (hh:mm:ss).
 */
export function convertSecondsToTimeFormat(secondsString) {
  if (!secondsString) return;
  // Convert the string to a number
  const totalSeconds = parseFloat(secondsString);
  // Validate if the input is a valid number
  if (isNaN(totalSeconds)) {
    throw new Error('Invalid input. Please provide a valid string representing seconds.');
  }

  // Calculate hours, minutes, and remaining seconds
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  // Format the time string (hh:mm:ss)
  const formattedTime = `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;

  return formattedTime;
}

/**
 * Pads a number with a leading zero if it's a single-digit number.
 * @param {number} num - The number to pad.
 * @returns {string} - The padded number as a string.
 */
function padZero(num) {
  return num < 10 ? `0${num}` : num.toString();
}
