import { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import ProfilesSearch from '../components/ProfileSearch/ProfilesSearch';
import { submitContactSalesMail } from '../API/services/alkymersService';
import Spinner from '../components/spinner/Spinner';
import ProfilesSearchProvider from '../components/ProfilesSearchProvider/ProfilesSearchProvider';
import arrowSmall from '../assets/profile/arrow-small.svg';
import {
  getProfiles,
  refreshProfiles,
  setProfiles,
  setAppliedFilters,
  setAgeQuery,
  setEngagementQuery,
} from '../redux/actions/profilesActions';
import { t } from '../i18n/i18n';
import {
  formatAppliedFilters,
  formatMinMaxFilters,
  formatMinMaxUrlFilters,
  formatUrlFilters,
} from '../utils/profilesFiltersUtils';
import ProfilesNavbar from '../components/ProfilesNavbar/ProfilesNavbar';
import useWindowSize from '../hooks/useWindowSize';
import useEnvironment from '../hooks/useEnvironment';
import ProfilesList from '../components/ProfilesList/ProfilesList';
import './ProfilesIndex.scss';
import Modal from '../components/Modal/Modal';
import FirstFilterForm from '../components/FirstFilterForm/FirstFilterForm';
import { Window } from '../utils/window';
import useRoles from '../hooks/useRoles';
import ConfirmSendEmailModal from '../components/ExploreOurCommunity/ConfirmSendEmailModal/ConfirmSendEmailModal';
import '../components/ExploreOurCommunity/NotApprovedModal/NotApprovedModal.scss';
import Astronaut from '../assets/search/Astronaut.json';
import BackgroundTop from '../components/BackgroundTop/BackgroundTop';
import { breadcrumbAlkemyCommunity } from '../constants/breadcrumb';

function ProfilesIndex() {
  const dispatch = useDispatch();
  const profilesState = useSelector((state) => state.profiles);
  const appliedFilters = useSelector((state) => state.profiles.appliedFilters);
  const size = useWindowSize();
  const location = useLocation();
  const history = useHistory();
  const { isRole, roles } = useRoles();
  const [first, setFirst] = useState(true);
  const [listStyle, setListStyle] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [submitEmailLoading, setSubmitEmailLoading] = useState(false);
  const [submitEmailSuccess, setSubmitEmailSuccess] = useState(false);
  const [showModal, setShowModal] = useState();
  const { environment } = useEnvironment();
  const filtersUrl = formatAppliedFilters(profilesState.appliedFilters);
  const formattedFiltersUrl = formatUrlFilters(location.search);
  const ageFiltersUrl = formatMinMaxFilters(profilesState.ageQuery);
  const formattedAgeUrl = formatMinMaxUrlFilters(location.search, 'min', 'max');
  const engagementFiltersUrl = formatMinMaxFilters(profilesState.engagementQuery);
  const formattedEngagementUrl = formatMinMaxUrlFilters(
    location.search,
    'minEngagement',
    'maxEngagement'
  );

  const fetchProfiles = (nextUrl) => {
    if (!first) {
      dispatch(getProfiles(nextUrl));
    }
  };

  const contactSales = (filters) => {
    setSubmitEmailLoading(true);
    submitContactSalesMail(filters).then((res) => {
      if (res.status === 201) {
        setSubmitEmailLoading(false);
        setSubmitEmailSuccess(true);
      }
    });
  };

  useEffect(
    () => () => {
      if (!history.location.pathname.includes('/profiles')) {
        dispatch(setAppliedFilters([]));
      }
    },
    []
  );

  useEffect(() => {
    if (location.search) {
      dispatch(setAppliedFilters(formattedFiltersUrl));
      dispatch(
        setAgeQuery({
          max: Number(formattedAgeUrl[0]?.value) === 0 ? '' : Number(formattedAgeUrl[0]?.value),
          min: Number(formattedAgeUrl[1]?.value) === 0 ? '' : Number(formattedAgeUrl[1]?.value),
        })
      );
      dispatch(
        setEngagementQuery({
          maxEngagement:
            Number(formattedEngagementUrl[0]?.value) === 0
              ? ''
              : Number(formattedEngagementUrl[0]?.value),
          minEngagement:
            Number(formattedEngagementUrl[1]?.value) === 0
              ? ''
              : Number(formattedEngagementUrl[1]?.value),
        })
      );
    }

    return () => {
      dispatch(setProfiles([]));
    };
  }, []);

  useEffect(() => {
    if (!profilesState.appliedFilters.length) {
      history.replace({
        search: '',
      });
    }
    if (
      profilesState.appliedFilters.length ||
      typeof profilesState.ageQuery.min !== 'string' ||
      typeof profilesState.engagementQuery.minEngagement !== 'string'
    ) {
      history.replace({
        search: `${ageFiltersUrl}${
          (ageFiltersUrl?.length && filtersUrl?.length) || engagementFiltersUrl.length ? '&' : ''
        }${escape(filtersUrl)}${
          filtersUrl?.length && engagementFiltersUrl.length ? '&' : ''
        }${engagementFiltersUrl}`,
      });
    }
  }, [
    profilesState.appliedFilters,
    profilesState.ageQuery,
    location.search,
    profilesState.engagementQuery,
  ]);

  useEffect(() => {
    if (size.width <= 768) {
      setListStyle(size.width <= 768);
    }
    if (size.width >= 992) {
      setShowFilters(false);
    }
  }, [size]);

  useEffect(() => {
    dispatch(setProfiles([]));
    if (!first) {
      dispatch(refreshProfiles(true));
    }
  }, [location.pathname, environment, profilesState.ordering]);

  useEffect(() => {
    if (first) {
      setFirst(false);
    }
    if (!first) {
      fetchProfiles();
    }
  }, [profilesState.query, location.pathname, environment, profilesState.ordering]);

  useEffect(() => {
    if (Window()) {
      if (profilesState.showFirstFilterModal && showModal) {
        window.document.body.classList.add('body-modal-open');
      } else {
        window.document.body.classList.remove('body-modal-open');
      }

      return () => {
        window.document.body.classList.remove('body-modal-open');
      };
    }
  }, [profilesState.showFirstFilterModal, showModal]);

  useEffect(() => {
    if (!localStorage.getItem('showModal')) {
      setShowModal(true);
    }
  }, []);

  const setShowModalFirstFilter = () => {
    localStorage.setItem('showModal', false);
  };

  return (
    <Container fluid className="content-min-height">
      <Modal
        show={profilesState.showFirstFilterModal && showModal}
        minWidth={false}
        className="overflow-auto first-modal-close-btn"
        showCloseButton
        setShow={setShowModal}
        onClose={setShowModalFirstFilter}
        isFirstFilterModal
      >
        <FirstFilterForm
          setShowModalFirstFilter={setShowModalFirstFilter}
          setShowModal={setShowModal}
        />
      </Modal>
      <Row className="justify-content-center content-min-height">
        <div className={`position-absolute ${showFilters && 'black-filter z-index-1'}`} />
        <Col
          xs="9"
          sm="6"
          md="4"
          lg="3"
          className={`bg-white d-none d-lg-block ${
            showFilters &&
            'right-0 d-block position-fixed z-index-1 border-left overflow-auto filter-list-height'
          }`}
        >
          <div className="profiles-filters-tour-box-container">
            <div className="profiles-filters-tour-box" data-tour-id="profile-filters" />
          </div>
          <ProfilesSearchProvider
            showFilters={() => setShowFilters(!showFilters)}
            component={ProfilesSearch}
          />
        </Col>
        <Col md="12" lg="9" className="mb-5 pr-lg-4">
          <div className="position-relative profiles-breadcrumb-container">
            <BackgroundTop title={t('TALENTS')} routes={breadcrumbAlkemyCommunity} />
          </div>
          {submitEmailSuccess && (
            <div className="card modal-container d-flex flex-column justify-content-around align-items-center">
              <ConfirmSendEmailModal
                setShow={() => setSubmitEmailSuccess(false)}
                textButton={t('BACK_TO_COMMUNITY_BUTTON_MODAL')}
              />
            </div>
          )}
          <ListGroup
            className={`mb-2 position-relative ${!listStyle && 'flex-wrap'}`}
            horizontal={!listStyle}
          >
            <ProfilesSearchProvider
              showFilters={() => setShowFilters(!showFilters)}
              component={ProfilesNavbar}
              alkymers={profilesState.total}
              toggleSelect={() => setListStyle(!listStyle)}
              style={listStyle}
              searchInput
            />
            {!isRole(roles.MARKETPLACEPARTNER) && (
              <div className="d-flex flex-column mx-auto header-not-approve-account w-100 mb-3 py-3 b-radius-8">
                {submitEmailLoading ? (
                  <Spinner show />
                ) : (
                  <>
                    <p className="font-montserrat text-large text-center font-weight-500">
                      {t('HEADER_PROFILE_UNAPPROVE_ACCOUNT')}
                    </p>
                    <button
                      type="button"
                      onClick={() => contactSales(appliedFilters)}
                      className="mt-1 align-self-center px-3 py-1 rounded-pill font-roboto text-white text-xs font-weight-bold border-0 outline-none bg-maya-blue"
                    >
                      {t('HEADER_PROFILE_CONTACT_BUTTON')}
                    </button>
                  </>
                )}
              </div>
            )}
            <ProfilesList
              className="flex-grow-1"
              profiles={profilesState.profiles}
              fetchProfiles={fetchProfiles}
              nextUrl={profilesState?.nextUrl}
            />
          </ListGroup>
          <Spinner show={profilesState.loading} />
          {profilesState.nextUrl && !profilesState.refresh && !profilesState.loading && (
            <div className="mb-2 mr-0 pr-0 mr-xl-5 pr-xl-5 mb-4 d-flex justify-content-end">
              <button
                onClick={() => {
                  fetchProfiles(profilesState.nextUrl);
                }}
                type="button"
                className={`results-button py-1 px-2 font-roboto text-sm border-0 mt-5 ${
                  listStyle ? 'position-relative align-self-end' : 'bottom-0 right-0'
                }`}
              >
                {t('MORE_ALKYMERS')}
                <img src={arrowSmall} alt={t('ICON_ARROW')} className="pl-2" />
              </button>
            </div>
          )}
          {!profilesState.profiles.length && !profilesState.refresh ? (
            <div className="d-flex flex-column align-items-center justify-content-center empty-candidates-container bg-white">
              <span className="font-montserrat font-weight-600 text-md">
                {t('DASHBOARD_NOT_CANDIDATES_MATCHING')}
              </span>
              <div className="mt-3">
                <Lottie animationData={Astronaut} loop />
              </div>
            </div>
          ) : null}
        </Col>
      </Row>
      {submitEmailSuccess && (
        <div
          className="modal-background"
          onClick={() => setSubmitEmailSuccess(false)}
          aria-hidden="true"
        />
      )}
    </Container>
  );
}

export default ProfilesIndex;
