import React, { useState } from 'react';
import CheckIcon from '../../assets/login/CheckIcon';
import PasswordEye from '../../assets/login/password-eye.svg';
import PasswordEyeOff from '../../assets/login/password-eye-off.svg';
import './LoginInput.css';

const LoginInput = ({
  placeholder,
  Icon,
  onChange,
  name,
  value,
  error,
  type = 'text',
  isPassword,
  className = '',
}) => {
  const [focus, setFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={`${className} my-1 w-100`}>
      <div
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        className={`login-input-container position-relative rounded d-flex w-100 ${
          focus || value?.length ? 'login-input-focused bg-white' : 'login-input-blur'
        } ${error && 'border-danger'}`}
      >
        {Icon && (
          <Icon
            selected={focus || value?.length}
            className="input-login-image align-self-center ml-3"
          />
        )}
        <input
          type={showPassword ? 'text' : type}
          onChange={onChange}
          name={name}
          role={name}
          value={value}
          className="login-input outline-none font-montserrat text-sm bg-transparent w-100 border-0 position-relative d-inline-block"
          placeholder={placeholder}
          data-testid={`login-input-${name}-input`}
        />
        {isPassword && (
          <img
            src={!showPassword ? PasswordEyeOff : PasswordEye}
            onClick={() => {
              setShowPassword(!showPassword);
            }}
            className="input-login-image mx-2"
            alt="asd"
            role="button"
            onKeyPress={() => {}}
          />
        )}
        {Boolean(value?.length && !error) && (
          <div className="input-login-image align-self-center mr-2">
            <CheckIcon />
          </div>
        )}
      </div>
      {error && (
        <p
          className="danger-text font-montserrat text-xs m-0 mt-1 p-0"
          data-testid="login-input-error-p"
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default LoginInput;