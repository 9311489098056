import { SCREENING_SEARCHES } from './routes';

export const breadcrumbAlkymetricsEvaluation = [
  {
    text: 'MENU_ITEM_SCREENING',
    clickable: false,
  },
  {
    text: 'MENU_ITEM_SEARCHES',
    clickable: true,
    route: SCREENING_SEARCHES,
  },
];

export const breadcrumbAlkemyCommunity = [{ clickable: false, text: 'COMMUNITY_ALKEMY' }];
