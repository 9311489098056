import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  NEW_SEARCH_SEND,
  PROFILE,
  PROFILES,
  TESTS,
  SAVED_SEARCHES,
  SAVED_PROFILES,
  HOME,
  CREATE_ABM_TESTGROUP_SCREENING,
  CREATE_TEST_GROUP,
  SCREENING_SEARCHES,
} from '../constants/routes';
import { t } from '../i18n/i18n';
import store from '../redux/store';

const useWindowName = () => {
  const history = useHistory();
  const ALKEMY_PARTNERS = 'Alkemy Partners';
  const [windowName, setWindowName] = useState(ALKEMY_PARTNERS);

  const languageKey = useSelector((state) => state.ux.language);
  const { texts } = store.getState().auth.whitelabel;
  const isAuthenticated = useSelector((state) => state.auth.authenticated);

  const invitedProfile = useSelector((state) => state.profiles.invitedProfile);
  const profile = useSelector((state) => state.profiles.profile);

  function capitalizeFirstLetter(string = '') {
    return string.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  }

  const pipedTitle = (text) => `${text} | ${ALKEMY_PARTNERS}`;

  useEffect(() => {
    const conditions = [
      {
        condition: history.location.pathname === PROFILES,
        windowName: pipedTitle(t('WINDOW_NAME_PROFILES')),
      },
      {
        condition: history.location.pathname.includes(PROFILES) && invitedProfile,
        windowName: pipedTitle(
          `${capitalizeFirstLetter(invitedProfile?.profile?.firstName)} ${capitalizeFirstLetter(
            invitedProfile?.profile?.lastName
          )}`
        ),
      },
      {
        condition: history.location.pathname.includes(PROFILES) && profile,
        windowName: pipedTitle(
          `${capitalizeFirstLetter(profile?.firstName)} ${capitalizeFirstLetter(profile?.lastName)}`
        ),
      },
      {
        condition: history.location.pathname === NEW_SEARCH_SEND,
        windowName: pipedTitle(t('WINDOW_NAME_SUBMIT_INVITATIONS')),
      },
      {
        condition: history.location.pathname.includes(TESTS),
        windowName: pipedTitle(t('WINDOW_NAME_TESTS')),
      },
      {
        condition: history.location.pathname.includes('/search/'),
        windowName: pipedTitle(t('WINDOW_NAME_SEARCH_DASHBOARD')),
      },
      {
        condition: history.location.pathname === PROFILE,
        windowName: pipedTitle(t('WINDOW_NAME_PROFILE')),
      },
      {
        condition: history.location.pathname === SAVED_SEARCHES,
        windowName: pipedTitle(t('WINDOW_NAME_MY_FILTERS')),
      },
      {
        condition: history.location.pathname === SAVED_PROFILES,
        windowName: pipedTitle(t('WINDOW_NAME_MY_FAVOURITES')),
      },
      {
        condition: history.location.pathname === HOME && isAuthenticated,
        windowName: pipedTitle(t('WINDOW_NAME_HOME')),
      },
      {
        condition:
          history.location.pathname === CREATE_ABM_TESTGROUP_SCREENING ||
          history.location.pathname.toLowerCase() === CREATE_TEST_GROUP ||
          history.location.pathname.includes('/abm/edit-testgroup'),
        windowName: pipedTitle(t('WINDOW_NAME_ABM_TESTGROUPS')),
      },
      {
        condition: history.location.pathname === SCREENING_SEARCHES,
        windowName: pipedTitle(t('WINDOW_NAME_SEARCHES')),
      },
    ];

    const conditionToExecute = conditions.find((item) => item.condition);
    if (conditionToExecute) {
      setWindowName(conditionToExecute.windowName);
    } else {
      setWindowName(t('WINDOW_NAME_ALKEMY_PARTNERS'));
    }
  }, [history.location.pathname, invitedProfile, profile, languageKey, texts, isAuthenticated]);

  return { windowName };
};

export default useWindowName;
