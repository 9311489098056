import { forwardRef, useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { enGB, pt, es } from 'date-fns/locale';
import { notification } from 'antd';
import { t } from '../../i18n/i18n';
import 'react-datepicker/dist/react-datepicker.css';
import './CalendarDatePicker.scss';
import useTranslations from '../../hooks/useTranslations';

registerLocale('en', enGB);
registerLocale('pt', pt);
registerLocale('es', es);

const CustomInput = forwardRef(
  ({ value, onClick, buttonClassNames, placeholder, showIconAlways, iconClassNames }, ref) => (
    <button
      data-testid="CalendarDatePicker"
      type="button"
      onClick={onClick}
      ref={ref}
      className={`m-2 text-sm outline-none font-montserrat px-3 py-2 custom-date-button font-weight-bold ${buttonClassNames}`}
    >
      {value !== '' ? value : t(placeholder)}
      <i
        className={`far fa-calendar-alt ml-3 ${
          value !== '' && !showIconAlways && 'd-none'
        } ${iconClassNames}`}
      />
    </button>
  )
);

const CalendarDatePicker = ({
  dateType,
  stageData,
  setStageData,
  buttonClassNames = '',
  showIconAlways = false,
  iconClassNames,
  placeholder = 'CREATE_SEARCH_STAGE_DATE_PLACEHOLDER',
  dateState = null,
  evaluationLimitDate,
}) => {
  const { key } = useTranslations();
  const startDate =
    stageData?.startDate?.day !== undefined ? new Date(stageData?.startDate?.day) : null;
  const endDate = stageData?.endDate?.day !== undefined ? new Date(stageData?.endDate?.day) : null;
  const formattedMinDate = startDate >= new Date() ? startDate : new Date();
  const initialDate = dateType === 'startDate' ? startDate : endDate;
  const [date, setDate] = useState(initialDate);
  if (dateState == null) dateState = { date: date, setDate: setDate };
  const [changeArrow, setChangeArrow] = useState(false);
  const minDate = dateType === 'endDate' ? formattedMinDate : null;
  const maxDate = evaluationLimitDate
    ? new Date(evaluationLimitDate)
    : dateType === 'startDate'
    ? endDate
    : null;
  const setData = () => {
    if (dateType === 'startDate') {
      setStageData({
        ...stageData,
        startDate: {
          ...stageData?.startDate,
          day: dateState.date?.toISOString(),
        },
      });
    }
    if (dateType === 'endDate') {
      if (dateState.date && dateState.date < startDate) {
        notification.open({
          message: t('CREATE_SEARCH_STAGE_END_DATE_BEFORE_START_DATE'),
          icon: <i className="fas fa-times text-danger" />,
        });
        dateState.setDate(null);
      }
      setStageData({
        ...stageData,
        endDate: {
          ...stageData?.endDate,
          day: dateState.date?.toISOString(),
        },
      });
    }
    if (dateType == 'limitDate') {
      setStageData(dateState.date?.toISOString());
    }
  };

  useEffect(() => {
    const startDate =
      stageData?.startDate?.day !== undefined ? new Date(stageData?.startDate?.day) : null;
    const endDate =
      stageData?.endDate?.day !== undefined ? new Date(stageData?.endDate?.day) : null;
    const initialDate = dateType === 'startDate' ? startDate : endDate;
    dateState.setDate(initialDate);
  }, []);

  useEffect(() => {
    setData();
  }, [dateState.date]);

  const handleChangeArrow = () => setChangeArrow(!changeArrow);

  return (
    <DatePicker
      selected={dateState.date}
      onChange={(selectedDate) => dateState.setDate(selectedDate)}
      calendarClassName="container-picker"
      locale={key}
      customInput={
        <CustomInput
          buttonClassNames={buttonClassNames}
          placeholder={placeholder}
          showIconAlways={showIconAlways}
          iconClassNames={iconClassNames}
        />
      }
      dateFormat="dd/MM/yyyy"
      minDate={minDate}
      maxDate={maxDate}
      onCalendarClose={handleChangeArrow}
      onCalendarOpen={handleChangeArrow}
      fixedHeight
    />
  );
};

export default CalendarDatePicker;
