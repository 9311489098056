import ModalTestImage from './ModalTestImage/ModalTestImage';
import ModalTestAudio from './ModalTestAudio/ModalTestAudio';
import { TestFileType } from '../../constants/enums';
import './ModalTestFile.scss';

const ModalTestFile = ({ file, open }) => (
  <>
    {file.fileType === TestFileType.IMAGE && <ModalTestImage file={file} />}
    {file.fileType === TestFileType.AUDIO && <ModalTestAudio file={file} open={open} />}
  </>
);

export default ModalTestFile;
