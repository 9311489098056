const Bookmark = ({ on, className }) => (
  <div className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V2C24 0.895431 23.1046 0 22 0H2ZM6.85714 6.85714V18.4286C6.85708 18.503 6.88027 18.5761 6.92444 18.6408C6.96861 18.7055 7.03223 18.7595 7.10904 18.7975C7.18585 18.8354 7.2732 18.8561 7.3625 18.8574C7.45179 18.8587 7.53995 18.8406 7.6183 18.8049L11.9999 16.3449L16.3817 18.8049C16.46 18.8406 16.5482 18.8587 16.6375 18.8575C16.7268 18.8562 16.8142 18.8355 16.891 18.7975C16.9678 18.7596 17.0314 18.7056 17.0756 18.6409C17.1197 18.5762 17.1429 18.5031 17.1429 18.4286V6.85721C17.1429 6.40255 16.9261 5.96652 16.5403 5.64503C16.1545 5.32353 15.6313 5.14292 15.0857 5.14292L8.91433 5.14286C8.36873 5.14286 7.84547 5.32347 7.45968 5.64496C7.07388 5.96645 6.85714 6.40249 6.85714 6.85714Z"
        fill={on ? ' #5EDCA7' : ' #C9C9C9'}
      />
    </svg>
  </div>
);

export default Bookmark;
