export const maxRecordTimes = [
  {
    name: '30 seg',
    id: 30,
  },
  {
    name: '1 min',
    id: 60,
  },
  {
    name: '2 min',
    id: 120,
  },
  {
    name: '3 min',
    id: 180,
  },
  {
    name: '4 min',
    id: 240,
  },
  {
    name: '5 min',
    id: 300,
  },
];
export const maxPreparationTimes = [
  {
    name: '5 seg',
    id: 5,
  },
  {
    name: '15 seg',
    id: 15,
  },
  {
    name: '30 seg',
    id: 30,
  },
  {
    name: '1 min',
    id: 60,
  },
];
export const maxAttemptsOptions = [
  {
    name: '0',
    id: 0,
  },
  {
    name: '1',
    id: 1,
  },
  {
    name: '2',
    id: 2,
  },
  {
    name: '3',
    id: 3,
  },
  {
    name: '4',
    id: 4,
  },
];
