const TrashIcon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.24935 13.4583C4.24935 14.2375 4.88685 14.875 5.66602 14.875H11.3327C12.1118 14.875 12.7494 14.2375 12.7494 13.4583V4.95833H4.24935V13.4583ZM13.4577 2.83333H10.9785L10.2702 2.125H6.72852L6.02018 2.83333H3.54102V4.25H13.4577V2.83333Z"
      fill={fill}
    />
  </svg>
);

export default TrashIcon;
