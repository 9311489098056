import { useEffect, useRef, useState } from 'react';
import { formatMinutesToHours } from '../../utils/formatMinutesToHours';
import { t } from '../../i18n/i18n';
import './TestGroupsListItem.scss';
import useCreateScreening from '../../hooks/useCreateScreening';
import { QuestionEnableModal } from './QuestionEnableModal';
import { questionsAmount } from '../../utils/tests';
import useTranslations from '../../hooks/useTranslations';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { HiDotsVertical } from 'react-icons/hi';
import { CheckOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { normalizedDescription } from '../../utils/stringUtils.js';
import GenericToggle from '../GenericToggle/GenericToggle.js';
import useClickOutside from '../../hooks/useClickOutside.js';
import MoveTestgroup from '../../assets/screening/MoveTestgroup.jsx';
import DeleteForever from '../../assets/screening/DeleteForever.js';
import { TestGroupTypes } from '../../constants/enums.js';
import ArrowSmall from '../../assets/screening/ArrowSmall.js';

const TestGroupsListItem = ({
  testGroup,
  disableOrderUp,
  disableOrderDown,
  disableQuestionIcon = false,
  disableCustomWeight,
  hideOrderArrows,
  hideDeleteIcon,
  handleOrder,
  handleDelete,
  handleQuestionButton,
  index,
  className,
}) => {
  const { key } = useTranslations();
  const { modifyTestWeight } = useCreateScreening();
  const [flipped, setFlipped] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const menuRef = useRef(null);
  const iconRef = useRef(null);
  useClickOutside(menuRef, () => setShowMenu(false), [iconRef]);

  const handleActiveSkipQuestion = ({ hasSkipQuestion }) => {
    handleQuestionButton({ newItem: { ...testGroup, hasSkipQuestion } });
    setShowModal(false);
  };
  return (
    <div className={`row d-flex flex-column w-100 gap-1 ${className}`}>
      <div className="col-1">
        <span className="tg-number-text">{index >= 0 ? index + 1 : ' '}</span>
      </div>
      <div className="col-11">
        <div className={`row testgroup-listitem-container list-item-card ${flipped && 'flipped'}`}>
          <div className="list-item-card-front">
            <div className="col-9 d-flex flex-column flex-md-row pt-1">
              <div className="d-flex flex-column w-100 h-100 p-1">
                <span className="text-sm font-weight-600 mb-1">
                  {testGroup?.type === TestGroupTypes.PRE_FILTER
                    ? t('TESTGROUP_PRE_FILTER_TITLE_NAME')
                    : testGroup.name}
                </span>
                <div className="d-flex flex-grow-1 gap-1">
                  <div className="d-flex flex-column w-50 gap-1">
                    <div className="d-flex align-items-center">
                      <CheckOutlined className="mr-1 testformat-icon" />
                      <span className="text-xs font-weight-400">
                        {`${questionsAmount(testGroup)} ${t(
                          'CREATE_TEST_GROUP_QUESTIONS'
                        ).toLowerCase()}`}
                      </span>
                    </div>
                    <div className="d-flex align-items-center">
                      <ClockCircleOutlined className="mr-1 testformat-icon" />
                      <span className="text-xs font-weight-400">
                        {testGroup?.type !== TestGroupTypes.PRE_FILTER
                          ? formatMinutesToHours(testGroup?.duration ?? 0)
                          : t('NO_TIME_RESTRICTION')}
                      </span>
                    </div>
                    <div className="d-flex flex-wrap gap-1">
                      {testGroup?.testFormats?.map((format, index) => (
                        <OverlayTrigger
                          key={`test-format-list-item-${index}`}
                          placement="top"
                          overlay={<Tooltip id="tooltip-top">{format.name}</Tooltip>}
                        >
                          <img
                            src={format?.iconUrl}
                            alt={format.name}
                            className="testformat-icon"
                          />
                        </OverlayTrigger>
                      ))}
                    </div>
                  </div>
                  <div className="d-flex align-items-end justify-content-end w-50 pb-2">
                    <button className="details-button-testgroup" onClick={() => setFlipped(true)}>
                      {t('TEST_LIST_ITEM_DETAILS')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 bg-grey-3 d-flex flex-column border-grey-rounded pt-2 position-relative">
              <div ref={iconRef} className="d-flex w-100 justify-content-end position-relative">
                <HiDotsVertical
                  className="p-0 m-0 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowMenu((prev) => !prev);
                  }}
                />
                <div
                  className={`position-absolute ${
                    showMenu && 'show-menu'
                  } testgroup-list-item-menu`}
                  ref={menuRef}
                >
                  {!hideOrderArrows && (
                    <>
                      <button
                        disabled={disableOrderUp}
                        className="w-100 bg-transparent border-0 outline-none d-flex justify-content-between align-items-center gap-1"
                        type="button"
                        onClick={() =>
                          handleOrder(testGroup.order, testGroup.order - 1, 'allTests')
                        }
                        data-testid="tg-list-item-disabled-order-up-btn"
                      >
                        <span>{t('TESTLIST_ITEM_UP_POSITION')}</span>
                        <MoveTestgroup />
                      </button>
                      <button
                        disabled={disableOrderDown}
                        className="w-100 bg-transparent border-0 outline-none d-flex justify-content-between align-items-center  gap-1"
                        type="button"
                        onClick={() =>
                          handleOrder(testGroup.order, testGroup.order + 1, 'allTests')
                        }
                        data-testid="tg-list-item-disabled-order-down-btn"
                      >
                        <span>{t('TESTLIST_ITEM_DOWN_POSITION')}</span>
                        <MoveTestgroup rotate />
                      </button>
                    </>
                  )}
                  <div
                    onClick={() => !disableQuestionIcon && setShowModal(true)}
                    className="d-flex w-100 justify-content-between align-items-center  gap-1"
                  >
                    <span>{t('LISTITEM_MENU_SKIP_QUESTIONS')}</span>
                    <GenericToggle
                      data-testid="toggle"
                      defaultActive={testGroup?.hasSkipQuestion ?? true}
                      value={testGroup?.hasSkipQuestion ?? true}
                      actionFunction={() => !disableQuestionIcon && setShowModal(true)}
                    />
                  </div>
                  {!hideDeleteIcon && (
                    <button
                      onClick={handleDelete}
                      type="button"
                      className="bg-transparent border-0 outline-none w-100 d-flex justify-content-between align-items-center  gap-1"
                      data-testid="tg-list-item-delete-btn"
                    >
                      <span>{t('LISTITEM_MENU_SKIP_DELETE')}</span>
                      <DeleteForever />
                    </button>
                  )}
                </div>
              </div>
              {[TestGroupTypes.QUALITATIVE, TestGroupTypes.PRE_FILTER].includes(testGroup?.type) ||
              (disableCustomWeight && !testGroup?.weight) ? (
                <div className="d-flex justify-content-center">
                  <span className="font-italic text-xs font-weight-500 mt-4 word-wrap">
                    {t(`CREATE_SCREENING_${testGroup.type.toUpperCase()}_LABEL`)}
                  </span>
                </div>
              ) : (
                <div
                  data-testid="tg-list-item-weight-input"
                  className={`d-flex bottom-border justify-content-center align-items-center mt-4
                  ${(testGroup?.weight === '0' || !testGroup.weight) && 'wrong-weight'}`}
                >
                  <input
                    disabled={disableCustomWeight}
                    className="outline-none bg-transparent border-0 text-center w-100"
                    min="0"
                    max="100"
                    value={testGroup?.weight ?? ''}
                    onKeyDown={(e) => {
                      const excludedKeys = ['-', '+', 'e'];
                      if (excludedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => modifyTestWeight(testGroup?.id, e.target.value)}
                    type="number"
                    data-testid="tg-list-item-custom-weight-input"
                  />
                  <span className=" " data-testid="tg-list-item-percent-span">
                    %
                  </span>
                </div>
              )}
            </div>
          </div>
          <div
            className="list-item-card-back d-flex flex-column py-2 px-3"
            onClick={() => setFlipped(false)}
          >
            <span>{t('TESTGROUP_OBJECTIVES_OF_THE_EVALUATION')}</span>
            <div className="d-flex flex-wrap test-objectives-content">
              {testGroup.objetives?.[key]?.length
                ? testGroup.objetives?.[key].map((item, index) => (
                    <OverlayTrigger
                      key={`${testGroup?.id}-obj-${index}`}
                      placement="top"
                      overlay={<Tooltip id="tooltip-top">{item}</Tooltip>}
                    >
                      <p className="objective-pill texts">{normalizedDescription(item, 16)}</p>
                    </OverlayTrigger>
                  ))
                : t('MODAL_SUBMISSION_DEFAULT_OBJECTIVES')
                    ?.split(',')
                    ?.map((item, index) => (
                      <OverlayTrigger
                        key={`${testGroup?.id}-obj-${index}`}
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">{item}</Tooltip>}
                      >
                        <p className="objective-pill texts">{normalizedDescription(item, 16)}</p>
                      </OverlayTrigger>
                    ))}
            </div>
            <span>{t('TESTGROUP_TARGET_ROLES')}</span>
            <div className="test-roles-content">
              {testGroup.roles?.length
                ? testGroup.roles?.slice(0, 5).map((role, index) => (
                    <OverlayTrigger
                      key={`${testGroup?.id}-role-${index}`}
                      placement="top"
                      overlay={<Tooltip id="tooltip-top">{role?.displayName}</Tooltip>}
                    >
                      <p className="role-pill texts">
                        {normalizedDescription(role?.displayName, 16)}
                      </p>
                    </OverlayTrigger>
                  ))
                : t('MODAL_SUBMISSION_DEFAULT_TARGET_ROLES')
                    ?.split(',')
                    ?.map((item, index) => (
                      <p key={`${testGroup?.id}-role-${index}`} className="role-pill texts">
                        {normalizedDescription(item, 16)}
                      </p>
                    ))}
            </div>
          </div>
          {testGroup?.tests && disableQuestionIcon && (
            <div className="d-flex flex-column w-full  mt-1 mb-1 mx-2">
              <div className="d-flex flex-column w-full  my-1 bg-white">
                <span className="d-block font-montserrat font-weight-800 text-md text-color-item-test-group text-truncate media-body my-2">
                  {t('QUESTIONS')}
                </span>
                {testGroup?.tests?.map((item) => (
                  <div
                    key={item.testId}
                    className="mb-2 justify-content-between d-flex flex-row justify-content-between align-items-center border-item-test-group p-3 position-relative"
                    aria-hidden="true"
                  >
                    <span className="d-block font-montserrat font-weight-600 text-sm text-color-item-test-group text-truncate media-body">
                      {item?.test?.text}
                    </span>
                    <span className="ticket-technology-frontend ticket-padding font-weight-bold font-roboto text-uppercase px-3 mr-auto mr-md-0 mt-2 mt-md-0 ml-2">
                      {item?.test?.type === TestGroupTypes.PRE_FILTER
                        ? t(`CREATE_PREFILTER_QUESTIONS_${item.test?.answerType}`)
                        : t(`CREATE_SCREENING_YOUR_QUESTIONS_${item.test?.answerType}`)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {!disableQuestionIcon && testGroup?.type !== TestGroupTypes.PRE_FILTER && (
        <QuestionEnableModal
          data={{ x: '' }}
          setShowModal={setShowModal}
          showModal={showModal}
          submitAction={() => {
            handleActiveSkipQuestion({
              hasSkipQuestion:
                testGroup?.hasSkipQuestion === undefined ? false : !testGroup?.hasSkipQuestion,
            });
          }}
          isQuestionEnabled={testGroup?.hasSkipQuestion ?? true}
        />
      )}
    </div>
  );
};

export default TestGroupsListItem;
