import { t } from '../../i18n/i18n';
import './QuestionText.scss';
import useCreateScreening from '../../hooks/useCreateScreening';
import GenericToggle from '../GenericToggle/GenericToggle';
import HorizontalLoader from '../HorizontalLoader/HorizontalLoader';
import { AnswerTypeEnum, PlanFeaturesIds } from '../../constants/enums';
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import DisabledFeature from '../DisabledFeature/DisabledFeature';

const QuestionText = ({ testGroupABM, testAnswer, setTestAnswer, aiImg }) => {
  const {
    handleAddQuestionText,
    handleCurrentTypeQuestion,
    handleGenerateAIAnswer,
    loadingAIAnswer,
    currentQuestionData,
    currentTypeQuestion,
  } = useCreateScreening();
  return (
    <div data-testid="question-text">
      <div className="row ">
        <textarea
          name="text"
          className={`col-5 border-grey-2 outline-none p-2 px-3 text-sm font-weight-500  ${
            currentQuestionData?.text?.length ? 'selected-border' : 'unselected-border'
          }`}
          rows="5"
          placeholder={t('CREATE_SCREENING_YOUR_QUESTIONS_WRITE_YOU_QUESTION_PLACEHOLDER')}
          onChange={(e) => handleAddQuestionText(e)}
          value={currentQuestionData?.text ?? ''}
          maxLength={5000}
          data-testid="question-text-textarea"
        />
        {testGroupABM && (
          <textarea
            type="text"
            onChange={handleAddQuestionText}
            name="formula"
            value={currentQuestionData?.formula ?? ''}
            className={`w-100 mt-3 py-2 px-3 font-montserrat maya-blue-thin-border outline-none text-xs min-height-add-question ${
              currentQuestionData?.formula?.length ? 'selected-border' : 'unselected-border'
            }`}
            rows="1"
            maxLength={500}
            placeholder={t('CREATE_SCREENING_YOUR_FORMULAS_PLACEHOLDER')}
            data-testid="question-mc-add-formula-textarea"
          />
        )}
        <div className="col-7 gap-1 ">
          {testGroupABM ? (
            <div className="d-flex align-items-center gap-1">
              <span className="ai-image-container">
                {aiImg}
                {t('QUESTION_TEXT_TOGGLE_AI_CORRECTION')}
              </span>
              <GenericToggle
                disabled
                actionFunction={() => {
                  if (currentTypeQuestion !== AnswerTypeEnum.TEXT_AI) {
                    handleCurrentTypeQuestion(AnswerTypeEnum.TEXT_AI);
                  } else {
                    handleCurrentTypeQuestion(AnswerTypeEnum.TEXT);
                  }
                }}
                defaultActive={currentTypeQuestion === AnswerTypeEnum.TEXT_AI}
              />
            </div>
          ) : (
            <DisabledFeature
              className="d-flex flex-row"
              iconClassName="align-self-start"
              childrenClassname="mt-2 pt-1"
              featureId={PlanFeaturesIds.PROCTORING}
            >
              <div className="d-flex align-items-center gap-1 ai-image-container">
                {aiImg}
                <span className=" text-sm font-weight-500">
                  {t('QUESTION_TEXT_TOGGLE_AI_CORRECTION')}
                </span>
                <GenericToggle
                  className="svgs-text-ai"
                  disabled={testGroupABM}
                  actionFunction={() => {
                    if (currentTypeQuestion !== AnswerTypeEnum.TEXT_AI) {
                      handleCurrentTypeQuestion(AnswerTypeEnum.TEXT_AI);
                    } else {
                      handleCurrentTypeQuestion(AnswerTypeEnum.TEXT);
                    }
                  }}
                  defaultActive={currentTypeQuestion === AnswerTypeEnum.TEXT_AI}
                />
              </div>
            </DisabledFeature>
          )}
          {currentTypeQuestion === AnswerTypeEnum.TEXT_AI && (
            <div className="d-flex flex-column align-items-start gap-1 mt-3">
              <div className="d-flex flex-column w-100">
                <span className=" text-sm font-weight-400 min-height-ia-text d-flex align-items-start">
                  {t('QUESTION_TEXT_TOGGLE_AI_ADD_CONCEPTS')}
                  <InfoTooltip
                    className="ml-2 info-tooltip-ai"
                    placement="top"
                    content={
                      <div className="d-flex flex-column py-2">
                        <span className=" text-sm mb-2">
                          {t('QUESTION_TEXT_TOGGLE_AI_ADD_CONCEPTS_FIRST_DISCLAIMER')}
                        </span>
                        <span className=" text-sm">
                          {t('QUESTION_TEXT_TOGGLE_AI_ADD_CONCEPTS_SECOND_DISCLAIMER')}
                        </span>
                      </div>
                    }
                  />
                </span>
                <textarea
                  name="concepts"
                  className={`bottom-border outline-none w-100 mt-3 p-2 px-3 text-sm font-weight-400  ${
                    currentQuestionData?.concepts?.length ? 'selected-border' : 'unselected-border'
                  }`}
                  rows="3"
                  onChange={(e) => handleAddQuestionText(e)}
                  value={currentQuestionData?.concepts ?? ''}
                  maxLength={1000}
                  data-testid="question-text-concepts-textarea"
                />
              </div>
              <div className="d-flex flex-column w-100">
                <span className=" text-sm font-weight-400 min-height-ia-text d-flex align-items-start">
                  {t('QUESTION_TEXT_TOGGLE_AI_ADD_TEST_ANSWER')}
                  <InfoTooltip
                    className="ml-2 info-tooltip-ai"
                    placement="top"
                    content={
                      <div className="d-flex flex-column py-2">
                        <span className=" text-sm mb-2">
                          {t('QUESTION_TEXT_TOGGLE_AI_ADD_TEST_ANSWER_DISCLAIMER')}
                        </span>
                      </div>
                    }
                  />
                </span>
                <textarea
                  name="testAnswer"
                  className={`bottom-border outline-none w-100 mt-3 p-2 px-3 text-sm font-weight-400  ${
                    testAnswer?.text?.length ? 'selected-border' : 'unselected-border'
                  }`}
                  rows="3"
                  onChange={(e) => setTestAnswer({ text: e.target.value })}
                  value={testAnswer?.text ?? ''}
                  maxLength={5000}
                  data-testid="question-text-test-answer-textarea"
                />
                <div className="d-flex mt-2 align-items-center justify-content-between text-sm font-weight-400">
                  <div className="d-flex flex-column gap-2">
                    <span className="bg-grey-3 p-2">
                      {t('QUESTION_TEXT_TOGGLE_AI_ADD_TEST_ANSWER_SCORE')}
                      {testAnswer?.score && `${testAnswer?.score * 10} %`}
                    </span>
                    <span className="bg-grey-3 p-2">
                      {t('QUESTION_TEXT_TOGGLE_AI_ADD_TEST_ANSWER_EXPLANATION')}
                      {testAnswer?.explanation}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <button
                      data-testid="question-text-add-test-answer-button"
                      onClick={async () => {
                        setTestAnswer({
                          ...testAnswer,
                          score: undefined,
                          explanation: undefined,
                        });
                        const data = await handleGenerateAIAnswer(testAnswer.text);
                        setTestAnswer({
                          ...testAnswer,
                          score: data.score,
                          explanation: data.explanation,
                        });
                      }}
                      type="button"
                      disabled={
                        !testAnswer?.text?.length ||
                        !currentQuestionData?.concepts?.length ||
                        !currentQuestionData?.text?.length ||
                        loadingAIAnswer
                      }
                      className={`members-invite-submit-button  text-sm align-self-start font-weight-bold py-2 px-4 rounded-pill text-white
                  ${
                    !testAnswer?.text?.length ||
                    !currentQuestionData?.concepts?.length ||
                    !currentQuestionData?.text?.length ||
                    loadingAIAnswer
                      ? 'disabled'
                      : ''
                  }
                  `}
                    >
                      {t('QUESTION_TEXT_TOGGLE_AI_ADD_TEST_ANSWER_BUTTON')}
                    </button>
                    <InfoTooltip
                      className="ml-2"
                      placement="top"
                      content={
                        <div className="d-flex flex-column py-2">
                          <span className=" text-sm mb-2">
                            {t('QUESTION_TEXT_TOGGLE_AI_ADD_TEST_ANSWER_BUTTON_FIRST_DISCLAIMER')}
                          </span>
                          <span className=" text-sm">
                            {t('QUESTION_TEXT_TOGGLE_AI_ADD_TEST_ANSWER_BUTTON_SECOND_DISCLAIMER')}
                          </span>
                        </div>
                      }
                    />
                  </div>
                </div>
                {loadingAIAnswer && (
                  <div className="w-100 d-flex justify-content-center mt-3">
                    <HorizontalLoader />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row mt-3">
        <input
          type="number"
          name="maxChar"
          value={currentQuestionData?.maxChar ?? ''}
          onKeyDown={(e) => {
            const excludedKeys = ['e', '+', '-', '.'];
            if (excludedKeys.includes(e.key)) {
              e.preventDefault();
            }
          }}
          onChange={(e) => handleAddQuestionText(e)}
          className="pb-3 mt-4 bottom-border div-input w-content h-100 b-radius-8 max-char-input text-sm font-weight-400  text-center col-5"
          min={0}
          max={5000}
          data-testid="question-text-max-char-input"
          placeholder={t('CREATE_SCREENING_MAX_CHAR_ANSWER')}
        />
      </div>
    </div>
  );
};

export default QuestionText;
