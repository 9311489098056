import { t } from '../../../../i18n/i18n';
import { formatSecondsToHHMMSS } from '../../../../utils/formatSecondsToHHMMSS';

const eventTypes = {
  EXIT_FULLSCREEN: 'PROCTORING_DETAIL_EXIT_FULLSCREEN',
  ENTER_FULLSCREEN: 'PROCTORING_DETAIL_ENTER_FULLSCREEN',
  MOUSE_LEAVE: 'PROCTORING_DETAIL_MOUSE_LEAVE',
  MOUSE_ENTER: 'PROCTORING_DETAIL_MOUSE_ENTER',
  SNAPSHOT: 'PROCTORING_DETAIL_SNAPSHOT',
  CAMERA_OFF: 'PROCTORING_DETAIL_CAMERA_OFF',
  CAMERA_ON: 'PROCTORING_DETAIL_CAMERA_ON',
  CAMERA_UNAUTHORIZED: 'PROCTORING_DETAIL_CAMERA_UNAUTHORIZED',
  COPY_TEXT: 'PROCTORING_DETAIL_COPY_TEXT',
  PASTE_TEXT: 'PROCTORING_DETAIL_PASTE_TEXT',
  CUT_TEXT: 'PROCTORING_DETAIL_CUT_TEXT',
  SNAPSHOT_CAMERA_OFF: 'PROCTORING_DETAIL_SNAPSHOT_CAMERA_OFF',
  SHORTCUT_EXECUTED: 'PROCTORING_DETAIL_SHORTCUTS',
};

const Content = ({ timeSubmitted, type }) => (
  <div className="d-flex w-100 mb-2">
    <span className="timestamp-container font-weight-500 font-montserrat item-detail-title">
      {formatSecondsToHHMMSS(timeSubmitted)}
    </span>
    <span className="notification-container font-weight-500 font-montserrat item-detail-title">
      {t(eventTypes[type])}
    </span>
  </div>
);

export default Content;
