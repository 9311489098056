import './Modal.scss';

const Modal = ({
  show,
  setShow,
  children,
  className,
  minWidth = true,
  onClose,
  showCloseButton = true,
  closeBtnClassname,
  relativeCloseButton,
  isFirstFilterModal = false,
}) => {
  const closeModal = () => {
    setShow(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      {show ? (
        <>
          <div
            id="custom-modal"
            data-testid="custom-modal"
            className={`h-auto card modal-card ${className} ${minWidth && 'min-width'}`}
          >
            {showCloseButton && (
              <button
                type="button"
                data-testid="close-button"
                className={`${
                  relativeCloseButton ? 'modal-close-button-relative' : 'modal-close-button'
                } ${closeBtnClassname}`}
                onClick={closeModal}
              >
                &times;
              </button>
            )}
            {children}
          </div>
          <div
            className="modal-background-custom"
            onClick={!isFirstFilterModal ? closeModal : undefined}
            aria-hidden="true"
          />
        </>
      ) : null}
    </>
  );
};

export default Modal;
