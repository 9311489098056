/**
 * Groups an array of objects by a specified property and formats the results.
 *
 * @param {Array<Object>} array - The array of objects to be grouped.
 * @param {string} property - The property name by which to group the objects.
 * @returns {Array<Object>} - An array of objects, each representing a group.
 *   Each group object contains the following properties:
 *   - `name`: The value of the grouping property for the group.
 *   - `color`: The `color` value from the first object in the group.
 *   - `data`: An array of objects that belong to the group.
 */
export function groupBy(array, property) {
  // Reduce the array into an object where the keys are the unique values of the specified property
  // and the values are arrays of objects that have that property value.
  const groups = array.reduce((acc, obj) => {
    const key = obj[property]; // Extract the value of the specified property to use as the key
    if (!acc[key]) {
      acc[key] = []; // Initialize an empty array if the key does not exist in the accumulator
    }
    acc[key].push(obj); // Add the object to the appropriate array based on its property value
    return acc; // Return the updated accumulator for the next iteration
  }, {}); // Start with an empty object

  // Transform the groups object into an array of group objects
  return Object.keys(groups).map((key) => {
    const firstItem = groups[key][0]; // Get the first item in the group to use for additional properties
    return {
      ...firstItem, // Spread the properties of the first item
      name: key, // Set the 'name' property to the key value
      color: firstItem.color, // Set the 'color' property from the first item in the group
      data: groups[key], // Include the array of objects that belong to this group
    };
  });
}

/**
 * Groups an array of objects by multiple properties and formats the results.
 *
 * @param {Array<Object>} array - The array of objects to be grouped.
 * @param {Array<string>} properties - An array of property names by which to group the objects.
 * @returns {Array<Object>} - An array of objects, each representing a group.
 *   Each group object contains the following properties:
 *   - The properties specified in the `properties` array, set to the value from the first object in the group.
 *   - `data`: An array of objects that belong to the group.
 *
 * @example
 * const data = [
 *   { color: 'red', size: 'M', value: 10 },
 *   { color: 'red', size: 'L', value: 20 },
 *   { color: 'blue', size: 'M', value: 30 }
 * ];
 *
 * const result = groupByMultipleProperties(data, ['color', 'size']);
 * // result will be:
 * // [
 * //   { color: 'red', size: 'M', data: [ { color: 'red', size: 'M', value: 10 } ] },
 * //   { color: 'red', size: 'L', data: [ { color: 'red', size: 'L', value: 20 } ] },
 * //   { color: 'blue', size: 'M', data: [ { color: 'blue', size: 'M', value: 30 } ] }
 * // ]
 */
export function groupByMultipleProperties(array, properties) {
  // Reduce the array into an object where the keys are concatenated values of the specified properties
  // and the values are arrays of objects that have those property values.
  const groups = array.reduce((acc, obj) => {
    // Create a unique key by concatenating the values of the specified properties with a separator ('|')
    const key = properties.map((prop) => obj[prop]).join('|');
    // Initialize an empty array if the key does not exist in the accumulator
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add the object to the appropriate array based on its concatenated property values
    acc[key].push(obj);
    return acc; // Return the updated accumulator for the next iteration
  }, {}); // Start with an empty object

  // Transform the groups object into an array of group objects
  return Object.keys(groups).map((key) => {
    // Get the first item in the group to use for additional properties
    const firstItem = groups[key][0];
    // Create a group object with the 'data' property and other properties from the first item
    const groupObject = { data: groups[key] };
    // Add each property from the 'properties' array to the group object
    properties.forEach((prop) => {
      groupObject[prop] = firstItem[prop];
    });
    // Return a new object that combines the group object with the first item
    return {
      ...groupObject,
      ...firstItem,
    };
  });
}
