import { useLottie } from 'lottie-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import rocketWithSuccess from '../../assets/lottie/rocket-with-success-animation.json';
import { HOME } from '../../constants/routes';
import { t } from '../../i18n/i18n';

const ResetTestgroupStep5 = () => {
  const history = useHistory();
  const options = {
    animationData: rocketWithSuccess,
    loop: true,
  };

  const { View } = useLottie(options);

  return (
    <div className="d-flex flex-column align-items-center py-4">
      <div className="lottie-container">{View}</div>
      <div className="my-3 d-flex flex-column align-items-center">
        <span className="my-1 font-montserrat font-weight-bold text-xl">
          {t('RESET_TESTGROUP_STEP_5_TITLE')}
        </span>
        <span className="my-1 font-montserrat text-muted text-sm font-italic">
          {t('RESET_TESTGROUP_STEP_5_SUBTITLE')}
        </span>
      </div>
      <div>
        <button
          onClick={() => {
            history.push(HOME);
          }}
          className="mt-4 align-self-end px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none bg-maya-blue"
          type="button"
        >
          {t('RESET_TESTGROUP_STEP_5_GO_HOME_BUTTON')}
        </button>
      </div>
    </div>
  );
};

export default ResetTestgroupStep5;
