import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Select, notification } from 'antd';
import GenericInput from '../GenericInput/GenericInput';
import './JobPostForm.css';
import { t } from '../../i18n/i18n';
import profileImageNull from '../../assets/profile/profile.png';
import DropdownSelect from '../DropdownSelect/DropdownSelect';
import useCreateJobPost from '../../hooks/useCreateJobPost';
import GenericToggle from '../GenericToggle/GenericToggle';
import { GenericTextArea } from '../GenericTextArea/GenericTextArea';
import MultipleSelect from '../MultipleSelect/MultipleSelect';
import fileJobDescription from '../../assets/jobPostIcons/fileJobDescription.svg';
import ArrowIcon from '../../assets/common/ArrowIcon';
import trashIcon from '../../assets/savedSearch/trash-icon.svg';
import CountryFlag from '../CountryFlag/CountryFlag';
import HorizontalLoader from '../HorizontalLoader/HorizontalLoader';
import BlockRedirection from '../BlockRedirection/BlockRedirection';
import Spinner from '../spinner/Spinner';
import { determineDisabledFeature } from '../../utils/determineDisabled';
import { PlanFeaturesIds } from '../../constants/enums';
import DiamondIcon from '../../assets/disabledFeature/DiamondIcon';
import DisabledFeature from '../DisabledFeature/DisabledFeature';
import { CSSTransition } from 'react-transition-group';
import { resetJobPostData } from '../../redux/actions/jobActions';
import { capitalizeString } from '../../utils/stringUtils';

const JobPostForm = ({ isEdit, jobPostHashId, Route, editDisabled = false }) => {
  const { newJobPost } = useSelector((state) => state.jobs);
  const { Option } = Select;
  const {
    studyAreaOptions,
    roleOptions,
    countriesOptions,
    WorkModalityOptions,
    ModalityOptions,
    preventReload,
    SeniorityLevelOptions,
    technologiesOptions,
    currenciesOptions,
    salaryFrequencyOptions,
    isLoading,
    screeningOptions,
    workspace,
    searchApplicattionsAmount,
    fetchRolesOptions,
    fetchStudyAreas,
    fetchCountryOptions,
    fetchTechnologiesOptions,
    uploadJobDescriptionFile,
    handleCreateJobPost,
    getJobPostById,
    validateJobPostFields,
    getIsoCode,
    handleEditJobPost,
    setPreventReload,
    validateSalaryInput,
    handleAddJobOffer,
    handleAddJobOfferDetail,
    setIsLoading,
    fetchRecruitSessionsOpen,
  } = useCreateJobPost();
  const [countryCodeSelected, setCountryCodeSelected] = useState([]);
  const [stateCodeSelected, setStateCodeSelected] = useState([]);
  const [cityValue, setCityValue] = useState('');
  const [jobOfferFileName, setJobOfferFileName] = useState(null);
  const [isoCodeCurrency, setIsoCodeCurrency] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const isDisabledFeature = determineDisabledFeature({
    featureId: PlanFeaturesIds.JOB_POSTING,
    validateAmount: false,
    usedAmount: 0,
  });

  const handleSelectTagsTechnologies = (e) => {
    const techs = [];
    const newTechs = [];
    e.forEach((t) => {
      if (!Number(t)) {
        const newId = t.toLowerCase();
        const name = capitalizeString(t);
        const techFound = technologiesOptions.find((t) => t.displayName.toLowerCase() == newId);
        if (!techFound) {
          newTechs.push({ id: newId, displayName: name });
        }
        techs.push(techFound?.id ?? name);
      } else {
        techs.push(Number(t));
      }
    });
    handleAddJobOfferDetail('technologyIds', techs);
    handleAddJobOfferDetail('newTechnologyData', newTechs);
  };

  const handleUploadFile = (e) => {
    if (e.target.files[0].type !== 'application/pdf') {
      notification.open({
        message: t('LABEL_JOBPOST_FILE_PDF_FORMAT'),
        icon: <i className="fas fa-times" style={{ color: '#F11010' }} />,
      });
      return;
    }
    uploadJobDescriptionFile(e)
      .then((response) => {
        handleAddJobOfferDetail('fileUrl', response.assetUrl);
        notification.open({
          message: t('LABEL_JOBPOST_FILE_PDF_UPLOADED'),
          icon: <i className="fas fa-check" style={{ color: '#52C0F7' }} />,
        });
      })
      .catch(() => {
        notification.open({
          message: t('LABEL_JOBPOST_FILE_FAIL_UPLOADED'),
          icon: <i className="fas fa-times" style={{ color: '#F11010' }} />,
        });
        setJobOfferFileName(null);
      });
  };

  const handleDeleteFile = () => {
    handleAddJobOfferDetail('fileUrl', null);
    setJobOfferFileName(null);
  };

  const handleSelectCountry = (e) => {
    const locationsToAdd = [];
    if (!countryCodeSelected.includes(e.id)) {
      setCountryCodeSelected([...countryCodeSelected, e.id]);
      locationsToAdd.push(...newJobPost?.locationsToOffer, { cityId: null, countryId: e.id });
    } else {
      setCountryCodeSelected(countryCodeSelected.filter((t) => t !== e.id));
      const countryToDelete = countriesOptions.find((p) => p.id === e.id)?.cities || [];
      const stateCodesToDelete = countryToDelete?.map((c) => c.id);
      setStateCodeSelected(stateCodeSelected.filter((t) => !stateCodesToDelete?.includes(t)));
      locationsToAdd.push(...newJobPost?.locationsToOffer?.filter((c) => c.countryId !== e.id));
      setCityValue('');
    }
    handleAddJobOfferDetail('locationsToOffer', locationsToAdd);
  };

  const handleSelectState = (e) => {
    const statesToAdd = [];
    if (!stateCodeSelected.includes(e.id)) {
      setStateCodeSelected([...stateCodeSelected, e.id]);
      const filterOutLocations = newJobPost?.locationsToOffer?.filter((item) => {
        if (item.countryId === e.countryId && item.cityId === null) {
          return false;
        }
        return true;
      });
      statesToAdd.push(...filterOutLocations, { cityId: e.id, countryId: e.countryId });
    } else {
      setStateCodeSelected(stateCodeSelected.filter((t) => t !== e.id));
      const statesAmountSelected = newJobPost?.locationsToOffer?.filter(
        (a) => a.countryId === e.countryId
      )?.length;
      if (statesAmountSelected === 1) {
        statesToAdd.push(...statesToAdd, { cityId: null, countryId: e.countryId });
      }
      statesToAdd.push(...newJobPost?.locationsToOffer?.filter((c) => c.cityId !== e.id));
      setCityValue('');
    }
    handleAddJobOfferDetail('locationsToOffer', statesToAdd);
  };

  const handleChangeCity = (e) => {
    handleAddJobOfferDetail('location', e);
  };

  const handleSetMinSalaryRange = (e) => {
    if (validateSalaryInput(e)) {
      handleAddJobOfferDetail('salaryInCentsMin', e);
    }
  };

  const handleSetMaxSalaryRange = (e) => {
    if (validateSalaryInput(e)) {
      handleAddJobOfferDetail('salaryInCentsMax', e);
    }
  };

  const handleSubmitJobpost = () => {
    if (validateJobPostFields()) {
      setIsLoading(true);
      handleCreateJobPost()
        .then(() => {
          notification.open({
            message: t('LABEL_JOBPOST_OFFER_CREATED'),
            icon: <i className="fas fa-check" style={{ color: '#52C0F7' }} />,
          });
          setPreventReload(false);
          setIsLoading(false);
          history.push('/jobs');
        })
        .catch(() => {
          notification.open({
            message: t('LABEL_JOBPOST_OFFER_FAILED_ON_CREATE'),
            icon: <i className="fas fa-times" style={{ color: '#F11010' }} />,
          });
          setIsLoading(false);
        });
    }
  };

  const handleSubmitEdit = () => {
    if (validateJobPostFields()) {
      setIsLoading(true);
      handleEditJobPost()
        .then(() => {
          setPreventReload(false);
          notification.open({
            message: t('LABEL_JOBPOST_OFFER_EDITED'),
            icon: <i className="fas fa-check" style={{ color: '#52C0F7' }} />,
          });
          history.push('/jobs');
          setIsLoading(false);
        })
        .catch(() => {
          notification.open({
            message: t('LABEL_JOBPOST_OFFER_FAILED_ON_EDIT'),
            icon: <i className="fas fa-times" style={{ color: '#F11010' }} />,
          });
          setIsLoading(false);
          setPreventReload(false);
        });
    }
  };

  useEffect(() => {
    setLoadingData(true);
    fetchStudyAreas();
    fetchRecruitSessionsOpen();
    fetchRolesOptions();
    fetchCountryOptions();
    fetchTechnologiesOptions();
    setLoadingData(false);
  }, []);

  useEffect(() => {
    if (jobPostHashId) {
      setPreventReload(false);
      setLoadingData(true);
      getJobPostById(jobPostHashId)
        .then((jobPostOffer) => {
          const countryRecovered = [];
          const statesRecovered = [];
          jobPostOffer?.address?.forEach((a) => {
            if (a?.countryId !== null && !countryRecovered.includes(a?.countryId)) {
              countryRecovered.push(a?.countryId);
            }
            if (a?.cityId !== null) {
              statesRecovered.push(a?.cityId);
            }
            if (a?.location !== null && a?.location !== '') {
              setCityValue(a?.location);
            }
          });
          setCountryCodeSelected(countryRecovered);
          setStateCodeSelected(statesRecovered);
          setIsoCodeCurrency(getIsoCode(jobPostOffer?.currency));
          if (jobPostOffer?.fileUrl?.length) {
            setJobOfferFileName(jobPostOffer.fileUrl);
          } else {
            delete jobPostOffer.fileUrl;
          }
          handleAddJobOffer({
            ...jobPostOffer,
            locationsToOffer: jobPostOffer?.address,
            roleId: jobPostOffer?.rol?.id,
            technologyIds: jobPostOffer?.technologies?.map((t) => t?.id),
            salaryInCentsMin: jobPostOffer.salaryInCentsMin / 100,
            salaryInCentsMax: jobPostOffer.salaryInCentsMax / 100,
          });
          setLoadingData(false);
        })
        .catch((e) => {
          notification.open({
            message: `${t('LABEL_JOBPOST_EDIT_FAILED_LOAD')}${e}`,
            icon: <i className="fas fa-times" style={{ color: '#F11010' }} />,
          });
          history.push('/jobs');
        });
    } else {
      setIsoCodeCurrency(false);
      dispatch(resetJobPostData());
    }

    return () => {
      dispatch(resetJobPostData());
    };
  }, [jobPostHashId]);

  return (
    <BlockRedirection
      conditionToBlock={preventReload}
      route={Route}
      updateConditionToBlock
      message={t('CREATE_SCREENING_LEAVE_SECTION_ALERT')}
    >
      <Spinner show={loadingData} />
      <div
        className={`bg-white rounded p-2 row align-items-start z-0 ${loadingData ? 'd-none' : ''}`}
        data-testid="jobpost-form"
      >
        <div className="container-lg align-items-start mt-2 col-12 col-sm-9 col-md-6">
          <span className="font-weight-500 font-montserrat text-light-black mb-3">
            {t('CREATE_JOBPOST_JOB_OFFER_TITLE')}
          </span>
          <span className="text-xs font-thin align-top mx-1">(*)</span>
          <GenericInput
            placeholder={t('CREATE_JOBPOST_TITLE_INPUT_PLACEHOLDER')}
            onChange={(e) => handleAddJobOfferDetail('title', e)}
            defaultValue={newJobPost?.title}
            disabled={editDisabled}
            className="mb-4"
            required
          />
          <span className="font-weight-500 font-montserrat text-light-black mb-3">
            {t('CREATE_JOBPOST_INDUSTRY_TITLE')}{' '}
          </span>
          <span className="text-xs font-thin align-top mx-0.5">(*)</span>
          <div className="container-dropdown-jopbpost">
            <DropdownSelect
              options={(studyAreaOptions ?? []).map((item) => ({
                id: item.id,
                name: item?.displayName,
              }))}
              className="w-100 position-relative font-montserrat fw-light"
              selected={newJobPost?.studyAreaId}
              selectedPlaceholder
              placeholderClassname="font-montserrat mr-2 text-sm font-weight-500 text-muted"
              axHeight={false}
              bigArrowLightBlue
              arrow={false}
              placeholder={t('CREATE_JOBPOST_INDUSTRY_INPUT_PLACEHOLDER')}
              searchable
              setState={(e) => {
                handleAddJobOfferDetail('studyAreaId', e.id);
              }}
              alignCenter={false}
              disabled={editDisabled}
            />
          </div>
          <span className="font-weight-500 font-montserrat text-light-black mb-3">
            {t('CREATE_JOBPOST_SEARCHED_ROL_TITLE')}
          </span>
          <span className="text-xs font-thin align-top mx-1">(*)</span>
          <div className="container-dropdown-jopbpost">
            <DropdownSelect
              options={(roleOptions ?? []).map((item) => ({
                id: item.id,
                name: item?.displayName,
              }))}
              className="w-100"
              selected={newJobPost?.roleId}
              placeholderClassname="font-montserrat mr-2 text-sm font-weight-500 text-muted"
              selectedPlaceholder
              maxHeight={false}
              bigArrowLightBlue
              arrow={false}
              placeholder={t('CREATE_JOBPOST_ROLE_INPUT_PLACEHOLDER')}
              searchable
              setState={(e) => handleAddJobOfferDetail('roleId', e.id)}
              alignCenter={false}
              disabled={editDisabled}
            />
          </div>
          <span className="font-weight-500 font-montserrat text-light-black mb-3">
            {t('CREATE_JOBPOST_SEARCHED_SENIORITY_TITLE')}
          </span>
          <div className="container-dropdown-jopbpost">
            <DropdownSelect
              options={(SeniorityLevelOptions ?? []).map((item) => ({
                id: item.id,
                name: item?.name,
              }))}
              className="w-100 position-relative"
              selected={newJobPost?.seniority}
              selectedPlaceholder
              placeholderClassname="font-montserrat mr-2 text-sm font-weight-500 text-muted"
              maxHeight={false}
              bigArrowLightBlue
              arrow={false}
              placeholder={t('CREATE_JOBPOST_SENIORITY_INPUT_PLACEHOLDER')}
              searchable
              setState={(e) => {
                handleAddJobOfferDetail('seniority', e.id);
              }}
              alignCenter={false}
              disabled={editDisabled}
            />
          </div>
          <span className="font-weight-500 font-montserrat text-light-black ">
            {t('CREATE_JOBPOST_TECHNOLOGIES_TITLE')}
          </span>
          <div className="container-dropdown-techs-jopbpost mb-3 mt-1">
            <Select
              mode="tags"
              style={{
                width: '100%',
              }}
              placeholder={t('CREATE_JOBPOST_TECHNOLOGIES_PLACHEHOLDER')}
              onChange={(value) => {
                if (!value || value == '') return handleSelectTagsTechnologies([]);
                const techs = String(value).split(',');
                handleSelectTagsTechnologies(techs);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) == 0
              }
              value={newJobPost?.technologyIds}
              disabled={editDisabled}
              showArrow
              suffixIcon={<ArrowIcon />}
              allowClear
              onFocus={false}
              clearBg="#58C1F5"
            >
              {technologiesOptions?.map((tech) => (
                <Option value={tech?.id} key={tech?.id}>
                  {tech?.displayName}
                </Option>
              ))}
            </Select>
          </div>
          <span className="font-weight-500 font-montserrat text-light-black mb-3">
            {t('CREATE_JOBPOST_JOB_OFFER_DESCRIPTION_TITLE')}
          </span>
          <div className="mb-4">
            <GenericTextArea
              placeholder={t('CREATE_JOBPOST_DESCRIPTION_PLACEHOLDER')}
              onChange={(e) => handleAddJobOfferDetail('description', e)}
              value={newJobPost?.description}
              disabled={editDisabled}
              maxLength={5000}
              richText
            />
          </div>
          <div className="jobpost-file-ofert mb-4">
            <span className="font-weight-500 font-montserrat d-flex text-light-black mb-3 mt-3">
              {t('JOBPOST_TITLE_UPLOAD_FILE_DESCRIPTION')}
            </span>
            <label
              htmlFor="pdf-upload"
              className="bg-white cursor-pointer p-2 border rounded-lg dark:bg-dark-bold w-full"
            >
              <input
                id="pdf-upload"
                name="pdf-upload"
                type="file"
                accept="application/pdf"
                className="d-none"
                value=""
                disabled={editDisabled}
                onChange={(e) => {
                  handleUploadFile(e);
                  setJobOfferFileName(e.target.files[0].name);
                }}
              />
              <div className={`justify-between ${editDisabled ? 'jobpost-file-disabled' : ''}`}>
                <label
                  onClick={handleDeleteFile}
                  title={t('LABEL_JOBPOST_ICON_DELETE_FILE')}
                  className="jobpost-delete-file-icon"
                >
                  {jobOfferFileName && !editDisabled ? (
                    <img
                      src={trashIcon}
                      className="mb-2"
                      alt={t('LABEL_JOBPOST_ICON_DELETE_FILE')}
                    />
                  ) : (
                    <></>
                  )}
                </label>
                <span className="text-alkemy-black font-bold dark:text-white bg-white dark:bg-dark-normal text-xs truncate">
                  {jobOfferFileName ?? t('JOBPOST_OFFER_UPLOAD_FILE')}
                </span>
                <img
                  className="job-post-upload-icon"
                  src={
                    jobOfferFileName
                      ? fileJobDescription
                      : 'https://alkemy-screenings-prod-assets.s3.amazonaws.com/typeAnswer/fileAnswer.svg'
                  }
                />
              </div>
            </label>
          </div>
        </div>

        <div className="container-lg align-items-start mt-2 col-12 col-sm-9 col-md-6">
          <div className="d-block container-flex w-100 justify-content-center py-1 my-1 mb-2 border border-light-dark  ">
            <div className="row my-0 justify-content-center align-items-center">
              <div className="col justify-content-center text-center">
                <span className="text-light-black font-weight-400 my-3 font-montserrat">
                  {workspace?.name}
                </span>
              </div>
              <div className="col justify-content-center text-center">
                <img
                  className="create-jobpost-img rounded-circle p-1  mx-5 my-1"
                  src={workspace?.company?.logo || profileImageNull}
                  alt={t('PROFILE_MENU_LOGO_USER')}
                />
              </div>
              <div className="col justify-content-center text-center">
                <span className="text-light-black font-weight-400 my-3 font-montserrat">
                  {workspace?.company?.name}
                </span>
              </div>
            </div>
            <div
              className={`row jobpost-total-applied align-items-center mt-2 ${
                jobPostHashId ? '' : 'd-none'
              }`}
            >
              <div className="col justify-content-center text-center">
                <span className="font-weight-500 font-montserrat text-light-black p-1">
                  {t('RECRUIT_SESSION_TOTAL_APPLIED')}:
                  <b className="text-maya-blue font-montserrat ml-1">
                    {searchApplicattionsAmount(newJobPost?.totalApplied)}
                  </b>
                </span>
              </div>
            </div>
          </div>
          <span className="font-weight-500 font-montserrat text-light-black mb-3">
            {t('CREATE_JOBPOST_JOB_LOCATION_TITLE')}
          </span>
          <span className="text-xs font-thin align-top mx-1">(*)</span>
          <div className="justify-content-start row">
            <div className="row-lg-4 row-md-8 row-sm-10 mb-2">
              <div className="col mb-3 mt-2">
                <MultipleSelect
                  options={(countriesOptions ?? []).map((item) => ({
                    id: item.id,
                    displayName: item.displayName,
                  }))}
                  classNames="justify-content-between"
                  showSelectedAmount
                  firstSelected
                  isCreateTestGroup
                  title={t('CREATE_JOBPOST_COUNTRY_INPUT_PLACEHOLDER')}
                  searchable
                  selectedList={countryCodeSelected}
                  onSelect={(e) => {
                    handleSelectCountry(e);
                  }}
                  CustomArrow={ArrowIcon}
                  disabled={editDisabled}
                />
              </div>
            </div>
            <div className="row-lg-4 row-md-8 row-sm-10 mb-2">
              <div className="col mb-3 mt-2">
                <MultipleSelect
                  options={countriesOptions
                    ?.filter((country) => countryCodeSelected.includes(country?.id))
                    ?.map((country) => country.cities)
                    .reduce((accumulator, currentValue) => accumulator.concat(currentValue), [])
                    .map((city) => ({
                      id: city.id,
                      displayName: city.displayName,
                      countryId: city.countryId,
                    }))}
                  classNames="justify-content-between"
                  showSelectedAmount
                  firstSelected
                  isCreateTestGroup
                  title={t('CREATE_JOBPOST_STATE_INPUT_PLACEHOLDER')}
                  searchable
                  selectedList={stateCodeSelected}
                  onSelect={(e) => {
                    handleSelectState(e);
                  }}
                  CustomArrow={ArrowIcon}
                  disabled={editDisabled}
                />
              </div>
            </div>
            <div className="row-lg-4 row-md-8 row-sm-10 mb-2">
              <div className="col">
                <GenericInput
                  placeholder={t('CREATE_JOBPOST_CITY_INPUT_PLACEHOLDER')}
                  onChange={(e) => {
                    handleChangeCity(e);
                  }}
                  defaultValue={cityValue}
                  disabled={editDisabled}
                />
              </div>
            </div>
          </div>
          <span className="font-weight-500 font-montserrat text-light-black ">
            {t('CREATE_JOBPOST_WORK_MODALITY_TITLE')}
          </span>
          <span className="text-xs font-thin align-top mx-1">(*)</span>
          <div className="container-dropdown-jopbpost mb-3 mt-1">
            <DropdownSelect
              options={(ModalityOptions ?? []).map((item) => ({
                id: item.id,
                name: item?.name,
              }))}
              className="w-100"
              selected={newJobPost?.modality}
              selectedPlaceholder
              placeholderClassname="font-montserrat mr-2 text-sm font-weight-500 text-muted"
              maxHeight={false}
              searchable
              bigArrowLightBlue
              arrow={false}
              placeholder={t('CREATE_JOBPOST_WORK_MODALITY_INPUT_PLACEHOLDER')}
              setState={async (e) => {
                handleAddJobOfferDetail('modality', e.id);
              }}
              alignCenter={false}
              disabled={editDisabled}
            />
          </div>
          <span className="font-weight-500 font-montserrat text-light-black mb-3">
            {t('CREATE_JOBPOST_WORK_MODE_TITLE')}
          </span>
          <span className="text-xs font-thin align-top mx-1">(*)</span>
          <div className="container-dropdown-jopbpost mb-2">
            <DropdownSelect
              options={(WorkModalityOptions ?? []).map((item) => ({
                id: item.id,
                name: item?.name,
              }))}
              className="w-100"
              selected={newJobPost?.workModality}
              selectedPlaceholder
              placeholderClassname="font-montserrat mr-2 text-sm font-weight-500 text-muted"
              maxHeight={false}
              searchable
              bigArrowLightBlue
              arrow={false}
              placeholder={t('CREATE_JOBPOST_WORK_MODE_INPUT_PLACEHOLDER')}
              setState={(e) => {
                handleAddJobOfferDetail('workModality', e.id);
              }}
              disabled={editDisabled}
            />
          </div>
          <div>
            <span className="font-weight-500 font-montserrat d-flex text-light-black">
              {t('CREATE_JOBPOST_SCREENING_LINK_TITLE')}
              <span className="text-xs align-top ml-1 ">(*)</span>
            </span>
            <div className="container-dropdown-jopbpost mb-2">
              <DropdownSelect
                options={screeningOptions.map((item) => ({
                  id: item.id,
                  name: item.displayName,
                }))}
                className="w-100"
                selected={newJobPost?.screeningUrl}
                selectedPlaceholder
                placeholderClassname="font-montserrat mr-2 text-sm font-weight-500 text-muted"
                maxHeight={false}
                searchable
                bigArrowLightBlue
                arrow={false}
                placeholder={t('JOBPOST_LABEL_SCREENING_PLACEHOLDER')}
                setState={(e) => {
                  handleAddJobOfferDetail('screeningUrl', e.id);
                }}
                disabled={editDisabled}
              />
            </div>
          </div>
          <div className="mt-3 mb-4">
            <span
              className="font-weight-500 font-montserrat text-light-black mb-3"
              title="LABEL_JOBPOST_HIDE_SHOW_COMPANY_NAME"
            >
              {t('CREATE_JOBPOST_SELECT_SALARY_TITLE')}
            </span>
            <div className="row justfiy-content-start mt-2">
              <div className="col ">
                <GenericInput
                  placeholder={t('CREATE_JOBPOST_MIN_SALARY_INPUT_PLACEHOLDER')}
                  type="number"
                  onChange={(e) => {
                    handleSetMinSalaryRange(e);
                  }}
                  defaultValue={newJobPost?.salaryInCentsMin}
                  disabled={editDisabled}
                />
              </div>
              <div className="col ">
                <GenericInput
                  placeholder={t('CREATE_JOBPOST_MAX_SALARY_INPUT_PLACEHOLDER')}
                  type="number"
                  onChange={(e) => {
                    handleSetMaxSalaryRange(e);
                  }}
                  defaultValue={newJobPost?.salaryInCentsMax}
                  disabled={editDisabled}
                />
              </div>
              <div className="col mr-3">
                <div className="row align-items-center">
                  <DropdownSelect
                    options={(currenciesOptions ?? []).map((item) => ({
                      id: item.id,
                      name: item?.displayName,
                      isoCode: item?.isoCode,
                    }))}
                    className="w-100 position-relative"
                    selected={newJobPost?.currency}
                    selectedPlaceholder
                    placeholderClassname="font-montserrat mr-2 text-sm font-weight-500 text-muted"
                    maxHeight={false}
                    searchable
                    bigArrowLightBlue
                    arrow={false}
                    placeholder={t('CREATE_JOBPOST_CURRENCY_SELECT_PLACEHOLDER')}
                    setState={async (e) => {
                      handleAddJobOfferDetail('currency', e.id);
                      setIsoCodeCurrency(e.isoCode);
                    }}
                    disabled={editDisabled}
                  />
                  {isoCodeCurrency && (
                    <div className="px-2">
                      <CountryFlag countryIso={isoCodeCurrency} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <DropdownSelect
              options={(salaryFrequencyOptions ?? []).map((item) => ({
                id: item.id,
                name: item?.displayName,
              }))}
              className="w-100 position-relative"
              selected={newJobPost?.salaryFrequency}
              selectedPlaceholder
              placeholderClassname="font-montserrat mr-2 text-sm font-weight-500 text-muted"
              maxHeight={false}
              searchable
              bigArrowLightBlue
              arrow={false}
              placeholder={t('CREATE_JOBPOST_SALARY_FREQUENCY_SELECT_PLACEHOLDER')}
              setState={(e) => {
                handleAddJobOfferDetail('salaryFrequency', e.id);
              }}
              disabled={editDisabled}
            />
          </div>
          <div className="mb-5">
            <span
              className="font-weight-500 font-montserrat text-light-black mb-3"
              title="LABEL_JOBPOST_HIDE_SHOW_COMPANY_NAME"
            >
              {t('CREATE_JOBPOST_COMPANY_CONFIDENTIALITY_TITLE')}
            </span>
            <div className="toggle-jobpost-confidentiality mt-2">
              <GenericToggle
                rightTextClassname="font-roboto"
                leftTextClassName="font-roboto"
                defaultActive={newJobPost?.confidential}
                actionFunction={() => {
                  handleAddJobOfferDetail('confidential', !newJobPost?.confidential);
                }}
                leftText={t('LABEL_JOBPOST_TOGGLE_NONCONFIDENTIALITY')}
                rightText={t('LABEL_JOBPOST_TOGGLE_CONFIDENTIALITY')}
                disabled={editDisabled}
              />
            </div>
          </div>

          <DisabledFeature
            className="position-relative flex-1 w-auto py-0 d-flex flex-column align-items-end bg-gray p-2 rounded"
            childrenClassname="position-relative w-100"
            iconClassName="w-min-content absolute-top-right position-absolute m-3"
            featureId={PlanFeaturesIds.JOB_POSTING}
            validateAmount={false}
            usedAmount={0}
          >
            {' '}
            <div className="mb-3">
              <span
                className="font-weight-500 font-montserrat text-light-black mb-3"
                title={t('LABEL_JOBPOST_HIDE_SHOW_COMPANY_NAME')}
              >
                {t('CREATE_JOBPOST_PLAN_TITLE')}
              </span>
              <div className="toggle-jobpost-plantype my-2">
                <GenericToggle
                  rightTextClassname="font-roboto"
                  leftTextClassName="font-roboto"
                  defaultActive={newJobPost?.isPremium}
                  actionFunction={() => {
                    handleAddJobOfferDetail('isPremium', !newJobPost?.isPremium);
                  }}
                  leftText={t('LABEL_JOBPOST_TOGGLE_PLAN_FREE')}
                  rightText={
                    <>
                      {t('LABEL_JOBPOST_TOGGLE_PLAN_PREMIUM')}
                      <CSSTransition
                        in={newJobPost?.isPremium}
                        timeout={200}
                        classNames="alert"
                        unmountOnExit
                      >
                        <DiamondIcon className={'diamond-icon-xs mx-1 '} />
                      </CSSTransition>
                    </>
                  }
                />
              </div>
              <span className="font-weight-400 font-montserrat text-light-black text-sm">
                {newJobPost?.isPremium
                  ? t('CREATE_JOBPOST_PLAN_PREMIUM_DESCRIPTION')
                  : t('CREATE_JOBPOST_PLAN_FREE_DESCRIPTION')}
              </span>
            </div>
          </DisabledFeature>

          {/* {!isDisabledFeature && (
            <div className="mb-3">
              <span
                className="font-weight-500 font-montserrat text-light-black mb-3"
                title={t('LABEL_JOBPOST_HIDE_SHOW_COMPANY_NAME')}
              >
                {t('CREATE_JOBPOST_PLAN_TITLE')}
              </span>
              <div className="toggle-jobpost-plantype my-2">
                <GenericToggle
                  rightTextClassname="font-roboto"
                  leftTextClassName="font-roboto"
                  defaultActive={jobPostPlan}
                  actionFunction={toggleJobPostPlan}
                  leftText={t('LABEL_JOBPOST_TOGGLE_PLAN_FREE')}
                  rightText={
                    <>
                      {t('LABEL_JOBPOST_TOGGLE_PLAN_PREMIUM')}
                      {jobPostPlan && <DiamondIcon className={'diamond-icon-xs mx-1'} />}
                    </>
                  }
                />
              </div>
              <span className="font-weight-400 font-montserrat text-light-black">
                {jobPostPlan
                  ? t('CREATE_JOBPOST_PLAN_PREMIUM_DESCRIPTION')
                  : t('CREATE_JOBPOST_PLAN_FREE_DESCRIPTION')}
              </span>
            </div>
          )} */}

          <div
            className={`container justify-content-end mt-4 py-3 ${editDisabled ? 'hidden' : ''}`}
          >
            {isLoading ? (
              <HorizontalLoader />
            ) : isEdit ? (
              <button
                className="mt-3 align-self-end px-3 py-2 rounded-pill font-montserrat text-xs text-white font-weight-bold border-0 outline-none bg-maya-blue"
                onClick={handleSubmitEdit}
                type="submit"
              >
                {t('JOBPOST_SAVE_EDIT_BTN_TITLE')}
              </button>
            ) : (
              <button
                className="mt-3 align-self-end px-3 py-2 rounded-pill font-montserrat text-xs text-white font-weight-bold border-0 outline-none bg-maya-blue"
                onClick={handleSubmitJobpost}
                type="submit"
              >
                {t('CREATE_JOBPOST_SAVE_BTN_TITLE')}
              </button>
            )}
          </div>
        </div>
      </div>
    </BlockRedirection>
  );
};

export default JobPostForm;
