import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { capitalizeString } from '../../utils/stringUtils';
import useClickOutside from '../../hooks/useClickOutside';
import HeaderArrowIcon from '../../assets/header/HeaderArrowIcon';
import profileImg from '../../assets/header/profile.svg';
import './Dropdown.scss';

const Dropdown = ({ options, className, profile, open, setOpen }) => {
  const ref = useRef(null);
  useClickOutside(ref, () => setOpen(false));

  return (
    <div
      data-testid="header-dropdown-container"
      ref={ref}
      className={`rounded font-roboto font-weight-bold  non-selectable dropdown-select-container ${className}`}
    >
      <div
        onClick={() => setOpen((state) => !state)}
        aria-hidden
        className="dropdown-select-button mx-2 cursor-pointer d-flex align-items-center"
      >
        <div
          className="dropdown-logo rounded-circle"
          style={{
            backgroundImage: `url('${profile?.picture}'), url('${profileImg}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <div className="d-flex flex-column justify-content-start">
          {profile?.firstName || profile?.lastName ? (
            <span className="dropdown-name font-roboto font-weight-bold">
              {`${capitalizeString(profile?.firstName)} ${capitalizeString(profile?.lastName)}`}
            </span>
          ) : null}
          {profile?.email ? (
            <span className="dropdown-name font-roboto font-weight-500 text-muted text-sm">
              {profile?.email}
            </span>
          ) : null}
        </div>
        <HeaderArrowIcon
          color="#737373"
          className={`dropdown-select-arrow ml-2 ${open && 'dropdown-select-arrow-open'}`}
        />
      </div>
      <ul
        className={`dropdown-select-options-container bg-white overflow-auto m-0 ${
          open && 'dropdown-select-options-container-open py-2'
        }`}
      >
        {options.map((option, i) =>
          option.link ? (
            <li
              className="p-1 px-3 cursor-pointer dropdown-selec-option"
              key={i}
              id={option?.id}
              ref={option?.ref}
            >
              <Link to={option.link} className="dropdown-link d-flex align-items-center">
                <img src={option.icon} width="20px" height="20px" className="mr-3" alt="icon" />
                <span className={option.className}>{option.title}</span>
              </Link>
            </li>
          ) : (
            <li className="p-1 px-3 cursor-pointer dropdown-selec-option" key={i}>
              <button
                className="dropdown-link d-flex align-items-center bg-transparent border-0"
                onClick={option.action}
                type="button"
              >
                <img src={option.icon} className="mr-3 dropdown-option-img" alt="icon" />
                <span className={option.className}>{option.title}</span>
              </button>
            </li>
          )
        )}
      </ul>
    </div>
  );
};

export default Dropdown;
