import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { languagesList } from '../DropdownLanguage/DropdownLanguage';
import CountryFlag from '../CountryFlag/CountryFlag';
import { t } from '../../i18n/i18n';
const { Option } = Select;

export const buildOptionLanguage = (item) => ({
  ...item,
  label: (
    <>
      <CountryFlag countryIso={item.iso} size="sm" className="mr-2" />
      {item.label}
    </>
  ),
});

export const SelectLanguage = ({ onChange = () => {}, defaultSelect = [] }) => {
  const [langSelected, setLangSelected] = useState(defaultSelect);

  const onHandleSelect = (langsSelected) => {
    const languagesSel = languagesList
      ?.filter((item) => langsSelected?.includes(item?.value))
      ?.map((item) => buildOptionLanguage(item));
    setLangSelected(languagesSel);
    onChange(languagesSel);
  };

  useEffect(() => {
    setLangSelected(defaultSelect);
  }, []);

  return (
    <Select
      mode="multiple"
      style={{
        width: '100%',
        fontFamily: 'Montserrat',
      }}
      value={langSelected}
      className="font-montserrat"
      placeholder={t('CREATE_TEST_GROUP_LANGUAGES_PLACEHOLDER')}
      onChange={(value) => {
        if (!value || value === '') return onHandleSelect([]);
        const langs = String(value).split(',');
        onHandleSelect(langs);
      }}
      popupClassName="font-montserrat"
      dropdownStyle={{ fontFamily: 'Montserrat' }}
    >
      {languagesList.map((x) => (
        <Option
          value={x.value}
          style={{
            fontFamily: 'Montserrat',
          }}
        >
          <CountryFlag countryIso={x.iso} size="sm" className="mr-2" />
          {x.label}
        </Option>
      ))}
    </Select>
  );
};
