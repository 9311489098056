const ArrowRight = () => (
  <svg width="12" height="23" viewBox="0 0 12 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.33203 1.89648L10.346 11.5791L1.33203 21.2428"
      stroke="#41479B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ArrowRight;
