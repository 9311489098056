import {
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Icon7,
  Icon8,
  Icon9,
  Icon10,
  Icon11,
  Icon12,
  Icon13,
} from '../../assets/settings/workspace';

const determineIcon = (customData) => {
  switch (customData.iconId) {
    case 1:
      return <Icon1 color={customData.color} />;
    case 2:
      return <Icon2 color={customData.color} />;
    case 3:
      return <Icon3 color={customData.color} />;
    case 4:
      return <Icon4 color={customData.color} />;
    case 5:
      return <Icon5 color={customData.color} />;
    case 6:
      return <Icon6 color={customData.color} />;
    case 7:
      return <Icon7 color={customData.color} />;
    case 8:
      return <Icon8 color={customData.color} />;
    case 9:
      return <Icon9 color={customData.color} />;
    case 10:
      return <Icon10 color={customData.color} />;
    case 11:
      return <Icon11 color={customData.color} />;
    case 12:
      return <Icon12 color={customData.color} />;
    case 13:
      return <Icon13 color={customData.color} />;
    default:
      return <Icon1 color={customData.color} />;
  }
};

export const determineIconType = (workspace, isCard) => {
  if (workspace.iconId) {
    return determineIcon({ ...workspace, color: isCard ? '#FFFFFF' : workspace.color });
  }
  if (workspace.icon) {
    return (
      <div
        style={{
          backgroundColor: workspace.color || '#0E9CE2',
        }}
        className="d-flex justify-content-center align-items-center box-icon"
      >
        <img className="img-icon-dropdown m-auto" src={workspace.icon} alt={workspace.icon} />
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: workspace.color || '#0E9CE2',
      }}
      className="d-flex justify-content-center align-items-center box-icon"
    >
      <span className="text-white">
        {(workspace?.name || workspace?.placeholder)?.toLocaleUpperCase()?.slice(0, 1)}
      </span>
    </div>
  );
};
