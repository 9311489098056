import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import Spinner from '../spinner/Spinner';
import EmptyComponent from '../EmptyComponent/EmptyComponent';
import { t } from '../../i18n/i18n';
import { getCommunicationsAssociated } from '../../API/services/alkymersService';
import CheckIcon from '../../assets/modal/Check';
import FailedIcon from '../../assets/modal/Failed';
import './InvitedProfileCommunications.scss';

const InvitedProfileCommunications = ({ isInvitedProfile }) => {
  const [communications, setCommunications] = useState([]);
  const [loading, setLoading] = useState(true);
  const invitationId = useSelector(
    (state) => state?.profiles?.invitedProfile?.stages[0]?.invitation?.id
  );

  useEffect(() => {
    if (isInvitedProfile) {
      setLoading(true);

      const getCommunicationsFn = () => getCommunicationsAssociated(invitationId);

      getCommunicationsFn().then((res) => {
        setCommunications(res);
        setLoading(false);
      });
    }
  }, []);

  const conditionIcon = (condition) => (condition ? <CheckIcon /> : <FailedIcon />);

  return loading ? (
    <Spinner show />
  ) : (
    <div className="mt-4">
      {!communications?.length ? (
        <EmptyComponent
          className="p-4"
          title={t('INVITED_PROFILE_COMMUNICATIONS_EMPTY_STATE_TITLE')}
          subtitleClassname="text-center"
        />
      ) : (
        <>
          <div className="d-none d-sm-flex row no-gutters justify-content-center align-items-center text-sm px-2">
            <div className="col-12 col-sm-8">
              <div className="row no-gutters justify-content-center align-items-center text-sm px-1">
                <div className="col-12 col-sm-5 font-weight-600 font-montserrat text-sm">
                  {t('INVITED_PROFILE_COMMUNICATIONS_DATE')}
                </div>
                <div className="col-12 col-sm-7 font-weight-600 font-montserrat text-sm">
                  {t('INVITED_PROFILE_COMMUNICATIONS_SUBJECT')}
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-4">
              <div className="row no-gutters justify-content-center align-items-center text-sm text-center">
                <div className="col col-sm-5 font-weight-600 font-montserrat text-sm">
                  {t('INVITED_PROFILE_COMMUNICATIONS_RECEIVED')}
                </div>
                <div className="col col-sm-4 font-weight-600 font-montserrat text-sm">
                  {t('INVITED_PROFILE_COMMUNICATIONS_OPENED')}
                </div>
                <div className="col col-sm-3 font-weight-600 font-montserrat text-sm">
                  {t('INVITED_PROFILE_COMMUNICATIONS_CLICKED')}
                </div>
              </div>
            </div>
          </div>
          {communications?.map((communication, i) => (
            <div
              className="row no-gutters justify-content-center align-items-center font-weight-600 mt-2 p-2 font-montserrat text-sm communication-container"
              key={i}
            >
              <div className="col-12 col-sm-8">
                <div className="row no-gutters justify-content-center align-items-center ">
                  <div className="col-12 col-sm-5">
                    <span className="d-flex font-weight-600 font-montserrat text-xs">
                      <span className="d-sm-none d-flex pr-2 ">
                        {t('INVITED_PROFILE_COMMUNICATIONS_DATE')}:
                      </span>
                      {format(new Date(communication.sendDate), 'yyyy/MM/dd HH:mm').concat(' hs')}
                    </span>
                  </div>
                  <div className="col-12 col-sm-7">
                    <span className="d-flex font-weight-600 font-montserrat text-xs">
                      <span className="d-sm-none d-flex pr-2">
                        {t('INVITED_PROFILE_COMMUNICATIONS_SUBJECT')}:
                      </span>
                      {communication.subjectEmail}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-4 mt-2 mt-sm-0">
                <div className="row no-gutters justify-content-center align-items-center text-sm text-center">
                  <div className="col-5">
                    <span className="d-sm-none d-block mb-1 ">
                      {t('INVITED_PROFILE_COMMUNICATIONS_RECEIVED')}
                    </span>
                    {conditionIcon(communication.received)}
                  </div>
                  <div className="col-4">
                    <span className="d-sm-none d-block mb-1">
                      {t('INVITED_PROFILE_COMMUNICATIONS_OPENED')}
                    </span>
                    {conditionIcon(communication.opened)}
                  </div>
                  <div className="col-3">
                    <span className="d-sm-none d-block mb-1">
                      {t('INVITED_PROFILE_COMMUNICATIONS_CLICKED')}
                    </span>
                    {conditionIcon(communication.clicked)}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default InvitedProfileCommunications;
