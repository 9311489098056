import { alkymetricsClient } from '../alkymetricsClient';

export const getTeams = async (params) => {
  try {
    const { data } = await alkymetricsClient.get('/partner/groups', {
      params: { limit: -1, ...params },
    });
    return data?.collection ?? [];
  } catch (err) {
    return [];
  }
};

export const getOneGroup = async (id) => {
  try {
    const { data } = await alkymetricsClient.get(`/groups/${id}`, { params: { limit: -1 } });
    return data;
  } catch (err) {
    return null;
  }
};

export const getCohorts = async () => {
  try {
    const { data } = await alkymetricsClient.get('/cohort/me');
    return data ?? [];
  } catch (err) {
    return [];
  }
};
