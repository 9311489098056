import { t } from '../../i18n/i18n';

const setRelocation = (filters) => {
  const sexFilterMap = filters
    .filter((filter) => filter.name)
    .map((filter) => ({
      ...filter,
      name:
        filter.id === 'AVAILABLE_FOR_RELOCATION'
          ? t('AVAILABLE_FOR_RELOCATION_YES')
          : t('AVAILABLE_FOR_RELOCATION_NO'),
      tag: t(filter.name),
    }));
  return sexFilterMap;
};

export default setRelocation;
