import { SET_JOBPOST_DATA, RESET_JOBPOST_DATA, EDIT_JOBPOST_DATA } from '../actionTypes';

export const setNewJobPostData = (newJobPost) => ({
  type: SET_JOBPOST_DATA,
  payload: { newJobPost },
});

export const resetJobPostData = (initalState = {}) => ({
  type: RESET_JOBPOST_DATA,
  payload: { initalState },
});

export const editJobPostData = (newJobPost) => ({
  type: EDIT_JOBPOST_DATA,
  payload: { newJobPost },
});
