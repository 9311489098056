import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { submitContactPlansSalesMail } from '../../../API/services/alkymersService.js';
import ConfirmSendEmailModal from '../../ExploreOurCommunity/ConfirmSendEmailModal/ConfirmSendEmailModal.js';
import { t } from '../../../i18n/i18n.js';
import PaymentModal from '../PaymentModal/PaymentModal.jsx';
import Plan from '../Plan/Plan.jsx';
import { useQuery } from '@tanstack/react-query';
import { getPlan } from '../../../API/services/alkymersService.js';
import { TimeFrequency } from '../../../constants/enums.js';
const CardsView = ({ plans, isInternationalPrice, setSelectedPlan, companySize }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [submitEmailSuccess, setSubmitEmailSuccess] = useState(false);

  const [loading, setLoading] = useState(false);
  const [modality, setModality] = useState(TimeFrequency.ANNUAL);

  const [showModal, setShowModal] = useState({
    show: false,
    id: null,
    loading: false,
    modality: TimeFrequency.ANNUAL,
  });

  const plansWithStructuredFeatures = plans;

  const { status, data, error, isLoading, isError, refetch, isSuccess } = useQuery({
    queryKey: ['plan', showModal?.id],
    queryFn: () => getPlan(showModal?.id),
    retry: 0,
    enabled: !!showModal?.id,
  });

  useEffect(() => {
    if (showModal?.id) refetch();
  }, [showModal]);

  const contactSales = async (planId) => {
    try {
      await submitContactPlansSalesMail(planId);
      setSubmitEmailSuccess(true);
    } catch (error) {
      notification.open({
        message: t('CONTACT_SALES_ERROR'),
        type: 'error',
      });
    }
  };
  return (
    <>
      <div
        data-testid="plans-card-view-container"
        className="row mb-5 w-100"
        style={{ justifyContent: 'center', gap: '1.5em' }}
      >
        {plansWithStructuredFeatures && plansWithStructuredFeatures.length
          ? plansWithStructuredFeatures.map((p, i) => {
              if (!p) return <></>;
              return (
                <div
                  id="plan"
                  key={`plan-${p.id}`}
                  //style={{ minWidth: '25%' }}
                  className="d-flex flex-wrap align-items-start justify-content-center col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 mw-100 p-0"
                >
                  <Plan
                    plan={p}
                    contactSalesAction={contactSales}
                    accordion={{ isOpen, setIsOpen }}
                    onClickBuy={(id, modality) => {
                      setLoading(true);
                      setShowModal({ show: true, id, loading: true, modality });
                    }}
                    showBuyButton={p.id != 1}
                    loading={showModal.id == p.id && showModal.loading}
                    isInternationalPrice={isInternationalPrice}
                    setModalModality={setModality}
                    setSelectedPlan={setSelectedPlan}
                    companySize={companySize}
                  />
                </div>
              );
            })
          : null}
      </div>
      {data && (
        <PaymentModal
          showDeleteModal={showModal}
          setShowDeleteModal={setShowModal}
          contactSalesAction={contactSales}
          data={data}
          refetch={refetch}
          setLoadingModal={setLoading}
          isInternationalPrice={isInternationalPrice}
        />
      )}

      {submitEmailSuccess && (
        <>
          <div className="card modal-container d-flex flex-column justify-content-around align-items-center">
            <ConfirmSendEmailModal
              setShow={() => setSubmitEmailSuccess(false)}
              textButton={t('BACK_TO_COMMUNITY_BUTTON_MODAL')}
            />
          </div>
          <div
            className="modal-background"
            onClick={() => setSubmitEmailSuccess(false)}
            aria-hidden="true"
          />
        </>
      )}
    </>
  );
};

export default CardsView;
