import { AnswerTypeEnum } from '../../constants/enums';
import { t } from '../../i18n/i18n';

const parseAnswerType = (answerType) => {
  switch (answerType) {
    case AnswerTypeEnum.RADIO:
      return t('ANSWER_TYPE_RADIO');
    case AnswerTypeEnum.CHECK:
      return t('ANSWER_TYPE_CHECK');
    case AnswerTypeEnum.TEXT:
      return t('ANSWER_TYPE_TEXT');
    case AnswerTypeEnum.TEXT_AI:
      return t('ANSWER_TYPE_TEXT_AI');
    case AnswerTypeEnum.CODE_AI:
      return t('ANSWER_TYPE_CODE_AI');
    case AnswerTypeEnum.FILE:
      return t('ANSWER_TYPE_FILE');
    case AnswerTypeEnum.WRITE:
      return t('ANSWER_TYPE_WRITE');
    default:
      return answerType;
  }
};

export default parseAnswerType;
