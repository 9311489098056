import React from 'react';

const PauseButton = () => (
  <svg
    data-testid="pause-button"
    width="11"
    height="14"
    viewBox="0 0 11 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 56H16V0H0V56ZM32 0V56H11V0H32Z" fill="black" />
  </svg>
);

export default PauseButton;
