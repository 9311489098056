const CheckWithCircle = () => (
  <svg width="50" height="50" viewBox="0 0 39 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.334 18.4706L18.0483 24.7101L28.334 12.2311M19.334 35.9412C16.9702 35.9412 14.6295 35.4893 12.4457 34.6113C10.2618 33.7333 8.27752 32.4465 6.60606 30.8242C4.93461 29.2019 3.60874 27.2759 2.70415 25.1563C1.79957 23.0367 1.33398 20.7649 1.33398 18.4706C1.33398 16.1763 1.79957 13.9045 2.70415 11.7849C3.60874 9.66525 4.93461 7.73931 6.60606 6.11702C8.27752 4.49472 10.2618 3.20785 12.4457 2.32987C14.6295 1.45189 16.9702 1 19.334 1C24.1079 1 28.6863 2.84065 32.0619 6.11702C35.4376 9.39339 37.334 13.8371 37.334 18.4706C37.334 23.1041 35.4376 27.5478 32.0619 30.8242C28.6863 34.1005 24.1079 35.9412 19.334 35.9412Z"
      stroke="#686868"
      stroke-width="2"
    />
  </svg>
);

export default CheckWithCircle;
