import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import {
  setAppliedFilters,
  setTextQuery,
  clearAppliedFilters,
} from '../../redux/actions/profilesActions';

function ProfilesSearchProvider({
  component: Component,
  showFilters,
  isLoading,
  showSaveBtn,
  setAllSelected = () => {},
  ...rest
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { appliedFilters, textQuery } = useSelector((state) => state.profiles);

  const [score, setScore] = useState({
    min: appliedFilters.find((f) => f.name === 'minScore')?.id || 0,
    max: appliedFilters.find((f) => f.name === 'maxScore')?.id || 100,
  });
  const [sliderKey, setSliderKey] = useState(v4());
  const handleSearch = (event) => {
    event.preventDefault();
    const filterList = appliedFilters.filter((filter) => filter.type !== 'search');

    dispatch(
      setAppliedFilters([...filterList, ...[{ name: textQuery, id: textQuery, type: 'search' }]])
    );
  };

  const [amountOfEvents, setAmountOfEvents] = useState({});

  const addFilter = (e) => {
    let filterName;
    let filterId;
    let filterType;

    if (e.target) {
      filterName = e.target.getAttribute('data-name');
      filterId = e.target.getAttribute('data-value');
      filterType = e.target.getAttribute('data-type');
    } else {
      filterName = e.name;
      filterId = e.id;
      filterType = e.type;
    }

    const isApplied = appliedFilters.find(
      (filter) => filter.id === filterId && filter.type === filterType && filter.type !== 'search'
    );
    if (!isApplied) {
      dispatch(
        setAppliedFilters([
          ...appliedFilters.filter((filter) => filter.type !== 'search'),
          ...[{ name: filterName, id: filterId, type: filterType }],
          ...[{ name: textQuery, id: textQuery, type: 'search' }],
        ])
      );
    }
    setAllSelected(false);
  };

  const addFilterObject = ({ name, id, type }) => {
    const isApplied = appliedFilters.find((filter) => filter.id === id && filter.type === type);
    if (!isApplied) {
      dispatch(setAppliedFilters([...appliedFilters, { name, id, type }]));
    }
    setAllSelected(false);
  };

  const addScoreFilter = (filter) => {
    const filterSet = [];
    appliedFilters.concat(filter).forEach((obj) => {
      if (!filterSet.find((filter) => filter.name === obj.name)) {
        filterSet.push(obj);
      }
    });
    dispatch(
      setAppliedFilters(filterSet.map((obj) => filter.find((o) => o.type === obj.type) || obj))
    );
  };

  const removeAppliedFilter = (e) => {
    let filterId;
    let filterType;

    if (e.currentTarget && e.target) {
      filterId = e.currentTarget.getAttribute('data-id');
      filterType = e.target.getAttribute('data-type');
    } else {
      filterId = e.id;
      filterType = e.type;
    }

    if (
      appliedFilters.filter((filter) => filter.type !== 'search').length - 1 === 0 &&
      appliedFilters.find((filter) => filter.type === 'search')?.name === ''
    ) {
      dispatch(setAppliedFilters([]));
      history.replace({ search: '' });
    }

    dispatch(
      setAppliedFilters(
        appliedFilters.filter((filter) => {
          if (filter.type === 'search' && filter.id === filterId) {
            dispatch(setTextQuery(''));
          }
          return `${filter.id}` !== `${filterId}` || filter.type !== filterType;
        })
      )
    );
  };

  const removeFilter = (id) => {
    dispatch(setAppliedFilters(appliedFilters.filter((item) => item.id !== id)));
  };

  const removeFilters = (ids) => {
    dispatch(setAppliedFilters(appliedFilters.filter((item) => !ids.includes(item.id))));
  };

  const clearFilters = () => {
    dispatch(clearAppliedFilters());
    setAllSelected(false);
    setSliderKey(v4());
    setScore({
      min: 0,
      max: 100,
    });
  };

  return (
    <Component
      amountOfEvents={amountOfEvents}
      setAmountOfEvents={setAmountOfEvents}
      handleSearch={handleSearch}
      removeAppliedFilter={removeAppliedFilter}
      addFilter={addFilter}
      addFilterObject={addFilterObject}
      clearFilters={clearFilters}
      removeFilter={removeFilter}
      removeFilters={removeFilters}
      showFilters={showFilters}
      isLoading={isLoading}
      showSaveBtn={showSaveBtn}
      score={score}
      setScore={setScore}
      sliderKey={sliderKey}
      setSliderKey={setSliderKey}
      appliedFilters={appliedFilters.length}
      addScoreFilter={addScoreFilter}
      {...rest}
    />
  );
}

export default ProfilesSearchProvider;
