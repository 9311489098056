import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { determineDisabledFeature } from '../../utils/determineDisabled';
import DiamondIcon from '../../assets/disabledFeature/DiamondIcon';
import './DisabledFeature.scss';
import { t } from '../../i18n/i18n';

const DisabledFeature = ({
  children,
  featureId,
  validateAmount,
  usedAmount,
  className,
  iconClassName,
  childrenClassname,
  iconSize = 'small',
  hideOnDisabled = false,
}) =>
  determineDisabledFeature({ featureId, validateAmount, usedAmount }) ? (
    <div
      className={`border-1 ${hideOnDisabled ? 'd-none' : className ?? 'd-flex flex-column'} ${
        className && !className.includes('py-0') ? 'py-2' : ''
      }`}
    >
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            <span className="font-montserrat font-weight-bold text-sm mb-2">
              {validateAmount
                ? t('NO_MORE_AVAILABLE_USAGE_FOR_THIS_FUNCTION_CONTACT_SALES_TEAM')
                : t('AVAILABLE_IN_PREMIUM_CONTACT_SALES_TEAM')}
            </span>
          </Tooltip>
        }
      >
        <a
          className={`mx-1 mr-3 font-montserrat text-sm diamond-background d-flex align-items-center justify-content-center rounded-circle ${iconClassName}`}
          href={process.env.RAZZLE_RUNTIME_SALES_CALENDAR_URL}
        >
          <DiamondIcon className={`diamond-icon-${iconSize}`} />
        </a>
      </OverlayTrigger>
      <div
        style={{ pointerEvents: 'none', filter: 'grayscale(1)' }}
        role="button"
        className={`cursor-disabled ${childrenClassname}`}
        tabIndex={0}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  ) : (
    children
  );

export default DisabledFeature;
