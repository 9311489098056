import Modal from '../../Modal/Modal';
import { t } from '../../../i18n/i18n';
import Input from '../../ScreeningForm/Input';
import { notification } from 'antd';
import { languagesList } from '../../DropdownLanguage/DropdownLanguage';
import CountryFlag from '../../CountryFlag/CountryFlag';
import { useState } from 'react';
import { SelectLanguage, buildOptionLanguage } from '../../SelectLanguages/SelectLanguage';
import { UploadFile } from '../../UploadFileCircle/UploadFile';

const NewCategoryModal = ({ showModal, setShowModal = () => {}, onSubmit = () => {} }) => {
  const [languagesSelected, setLanguagesSelected] = useState([
    buildOptionLanguage(languagesList[0]),
  ]);
  const [newFile, setNewFile] = useState(null);
  const [name, setName] = useState({ es: '' });

  const onChangeSelect = (languages = []) => {
    setLanguagesSelected(languages);
    const nameExistent = Object.keys(name);
    const langValue = languages?.map((item) => item?.value);
    const attr = Object.values(langValue).map((value) => ({
      [value]: nameExistent.includes(value) ? name[value] : '',
    }));
    const newAttr = attr?.reduce((a, b) => ({ ...a, ...b }), {});
    setName(newAttr);
  };

  const onUpload = (file) => {
    setNewFile(file);
  };

  const onSubmitModal = () => {
    if (languagesSelected?.length === 0) {
      notification.error({
        message: t('MUST_HAVE_SOME_LANGUAGE').replace('{{amount}}', 1),
      });
      return null;
    }
    const names = Object.values(languagesSelected)?.filter((item) => name[item?.value] === '');
    if (names?.length > 0) {
      notification.error({ message: t('MUST_HAVE_ALL_INPUT') });
      return null;
    }
    const newCategory = { name: name?.es, languages: name };
    onSubmit(newCategory, newFile);
    return null;
  };

  return (
    <Modal
      data-testid="card-screening-search-modal"
      className="modal-buttons-border d-flex justify-content-end"
      showCloseButton={false}
      show={showModal}
      minWidth={false}
      setShow={(e) => {
        setShowModal(e);
      }}
      onClose={() => {
        setShowModal(false);
      }}
    >
      <div className="px-5 mx-5" style={{ overflowY: 'scroll' }}>
        <div className="py-5 d-flex flex-column align-items-center">
          <span className="font-weight-600 font-montserrat text-base mb-2">
            {t('NEW_CATEGORY_MODAL_TITLE')}
          </span>
          <div>
            <UploadFile onUpload={onUpload} />
          </div>
          <div className="my-2 w-100">
            <span className="font-montserrat font-weight-bold text-base my-2">
              {t('CREATE_TEST_GROUP_LANGUAGES_TITLE')}
            </span>
            <SelectLanguage
              onChange={onChangeSelect}
              defaultSelect={[buildOptionLanguage(languagesList[0])]}
            />
          </div>
          <div>
            {languagesSelected?.map((item) => (
              <div className="my-2">
                {' '}
                <span className="font-weight-500 font-montserrat text-light-black mb-3">
                  {t('NAME')} <CountryFlag countryIso={item.iso} size="sm" className="mr-2" />
                </span>
                <Input
                  placeholder={t('NEW_CATEGORY_MODAL_EXAMPLE')}
                  className="flex-grow-1"
                  name="name"
                  value={name[item.value]}
                  onChange={(value) => setName({ ...name, [item.value]: value })}
                />
              </div>
            ))}
          </div>
          <div>
            <button
              type="button"
              className="px-5 py-3 modal-buttons-border bg-transparent mt-5 dont-delete-draft-button-border border-red-danger text-red-danger font-roboto outline-none text-sm font-weight-700 mr-2"
              onClick={() => {
                setShowModal(false);
              }}
            >
              {t('CANCEL')}
            </button>
            <button
              type="button"
              className="px-5 py-3 modal-buttons-border  mt-5 border-0 text-white font-roboto outline-none text-sm font-weight-700 ml-2"
              style={{ backgroundColor: '#449cc8' }}
              onClick={onSubmitModal}
            >
              {t('CREATE')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NewCategoryModal;
