import { useEffect, useState } from 'react';
import './NotApprovedLayout.scss';

const NotApprovedLayout = ({ children }) => {
  const [randomImg, setRandomImg] = useState('');
  const imgList = [
    `${process.env.RAZZLE_RUNTIME_ASSETS}/login/1b3d8251-dac9-46ef-9ef4-ae7889585b9a.webp`,
    `${process.env.RAZZLE_RUNTIME_ASSETS}/login/04bd3c20-fe06-4fed-a7b4-fbdadbc3ceb4.webp`,
    `${process.env.RAZZLE_RUNTIME_ASSETS}/login/17b12564-457d-4907-9474-db51e4e22e29.webp`,
    `${process.env.RAZZLE_RUNTIME_ASSETS}/login/91e8b9ee-18bd-4bf3-889b-5f7eb809e42d.webp`,
    `${process.env.RAZZLE_RUNTIME_ASSETS}/login/531cb089-f554-48f5-bbc3-530615faff98.webp`,
    `${process.env.RAZZLE_RUNTIME_ASSETS}/login/6958c3b2-0d8d-45b9-a1cb-ddb89c5aca94.webp`,
    `${process.env.RAZZLE_RUNTIME_ASSETS}/login/b142e60b-e037-40e7-911d-e22f98696ee1.webp`,
    `${process.env.RAZZLE_RUNTIME_ASSETS}/login/ff321e54-afdc-4500-a3a3-5eca2362b57a.webp`,
  ];

  useEffect(() => {
    setRandomImg(imgList[Math.floor(Math.random() * imgList.length)]);
  }, []);

  return (
    <div className="container-fluid max-vh-100 bg-white">
      <div className="row no-gutter logged-out-container">
        <div className="container col-md-5 col-lg-5 bg-white">
          <div className="children-container steps-children-container w-100 vh-100 ">
            {children}
          </div>
        </div>
        <div
          className="col-md-7 col-lg-7 d-none d-md-flex logged-out-bg-image"
          style={{
            backgroundImage: `url(${randomImg})`,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="position-absolute bottom-0 left-0"
            viewBox="0 0 1440 320"
          >
            <path
              fill="#52C0F7"
              fillOpacity="1"
              d="M0,288L48,277.3C96,267,192,245,288,229.3C384,213,480,203,576,197.3C672,192,768,192,864,202.7C960,213,1056,235,1152,234.7C1248,235,1344,213,1392,202.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default NotApprovedLayout;
