import React from 'react';

const PersonalizeExperience = ({ className }) => (
  <svg
    className={className}
    width="69"
    height="64"
    viewBox="0 0 69 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.6835 37.2702H26.5657C26.3518 37.2705 26.1468 37.3558 25.9958 37.5072C25.8447 37.6586 25.76 37.8638 25.7603 38.0777V61.9595H42.491V38.0777C42.4911 37.9716 42.4702 37.8666 42.4297 37.7686C42.3891 37.6706 42.3296 37.5816 42.2546 37.5066C42.1796 37.4316 42.0906 37.3721 41.9926 37.3316C41.8946 37.291 41.7896 37.2701 41.6835 37.2702ZM34.1755 50.8395C33.7027 50.8373 33.2499 50.6486 32.9156 50.3142C32.5813 49.9799 32.3925 49.5272 32.3903 49.0544V46.2998C32.3903 45.8264 32.5784 45.3723 32.9131 45.0375C33.2479 44.7027 33.702 44.5147 34.1755 44.5147C34.6489 44.5147 35.103 44.7027 35.4378 45.0375C35.7725 45.3723 35.9606 45.8264 35.9606 46.2998V49.0544C35.9584 49.5272 35.7696 49.9799 35.4353 50.3142C35.101 50.6486 34.6482 50.8373 34.1755 50.8395Z"
      fill="#E6E6E6"
    />
    <path
      d="M25.7085 61.1956V63.4997C25.7087 63.6191 25.7563 63.7336 25.8407 63.818C25.9251 63.9024 26.0396 63.9499 26.159 63.9502H42.0926C42.2119 63.9496 42.3261 63.9019 42.4105 63.8176C42.4948 63.7332 42.5424 63.619 42.543 63.4997V61.1956H25.7085Z"
      fill="#CCCCCC"
    />
    <path
      d="M66.7175 0H2.09447C1.53925 0.000883281 1.00703 0.221832 0.614436 0.614426C0.221839 1.00702 0.00088675 1.53924 0 2.09445V45.7023C0.000533979 46.2577 0.221372 46.7901 0.614044 47.1827C1.00672 47.5754 1.53914 47.7963 2.09447 47.7968H66.7175C67.2728 47.7963 67.8052 47.5754 68.1979 47.1827C68.5906 46.7901 68.8114 46.2577 68.8119 45.7023V2.09445C68.8111 1.53924 68.5901 1.00702 68.1975 0.614426C67.8049 0.221832 67.2727 0.000883281 66.7175 0Z"
      fill="#E6E6E6"
    />
    <path
      d="M66.1777 1.51221H2.63449C2.33712 1.51291 2.05217 1.63153 1.84215 1.84205C1.63212 2.05256 1.51417 2.33778 1.51416 2.63515V45.1618C1.5145 45.4588 1.63264 45.7436 1.84266 45.9536C2.05269 46.1636 2.33746 46.2818 2.63449 46.2821H66.1777C66.4747 46.2818 66.7595 46.1636 66.9695 45.9536C67.1796 45.7436 67.2977 45.4588 67.298 45.1618V2.63515C67.298 2.33778 67.1801 2.05256 66.9701 1.84205C66.76 1.63153 66.4751 1.51291 66.1777 1.51221Z"
      fill="white"
    />
    <path
      d="M67.6805 2.47432V39.4896C62.7912 40.9148 57.5708 40.7081 52.8095 38.901C48.0482 37.0939 44.0054 33.7846 41.293 29.4743C38.5807 25.164 37.3465 20.0874 37.7774 15.0129C38.2083 9.93849 40.2808 5.14261 43.6811 1.35138H66.5601C66.8575 1.35209 67.1424 1.4707 67.3525 1.68122C67.5625 1.89174 67.6804 2.17696 67.6805 2.47432Z"
      fill="#4bafe1"
    />
    <path
      d="M62.984 7.37206H3.52638C3.46454 7.37074 3.40569 7.34525 3.36242 7.30105C3.31915 7.25686 3.29492 7.19746 3.29492 7.13561C3.29492 7.07376 3.31915 7.01437 3.36242 6.97017C3.40569 6.92597 3.46454 6.90049 3.52638 6.89917H62.984C63.0458 6.90049 63.1046 6.92597 63.1479 6.97017C63.1912 7.01437 63.2154 7.07376 63.2154 7.13561C63.2154 7.19746 63.1912 7.25686 63.1479 7.30105C63.1046 7.34525 63.0458 7.37074 62.984 7.37206Z"
      fill="#CACACA"
    />
    <path
      d="M7.57367 5.69196C8.33897 5.69196 8.95936 5.05774 8.95936 4.27539C8.95936 3.49304 8.33897 2.85883 7.57367 2.85883C6.80838 2.85883 6.18799 3.49304 6.18799 4.27539C6.18799 5.05774 6.80838 5.69196 7.57367 5.69196Z"
      fill="#E6E6E6"
    />
    <path
      d="M12.3608 5.69196C13.1261 5.69196 13.7465 5.05774 13.7465 4.27539C13.7465 3.49304 13.1261 2.85883 12.3608 2.85883C11.5955 2.85883 10.9751 3.49304 10.9751 4.27539C10.9751 5.05774 11.5955 5.69196 12.3608 5.69196Z"
      fill="#E6E6E6"
    />
    <path
      d="M17.1474 5.69196C17.9127 5.69196 18.5331 5.05774 18.5331 4.27539C18.5331 3.49304 17.9127 2.85883 17.1474 2.85883C16.3821 2.85883 15.7617 3.49304 15.7617 4.27539C15.7617 5.05774 16.3821 5.69196 17.1474 5.69196Z"
      fill="#3F3D56"
    />
    <path
      d="M60.1015 3.2099H56.7011C56.6337 3.21124 56.5695 3.23896 56.5224 3.2871C56.4752 3.33524 56.4487 3.39996 56.4487 3.46737C56.4487 3.53478 56.4752 3.5995 56.5224 3.64764C56.5695 3.69578 56.6337 3.7235 56.7011 3.72484H60.1015C60.1689 3.7235 60.2331 3.69578 60.2803 3.64764C60.3275 3.5995 60.3539 3.53478 60.3539 3.46737C60.3539 3.39996 60.3275 3.33524 60.2803 3.2871C60.2331 3.23896 60.1689 3.21124 60.1015 3.2099Z"
      fill="white"
    />
    <path
      d="M60.1015 4.17639H56.7011C56.6337 4.17773 56.5695 4.20545 56.5224 4.25359C56.4752 4.30173 56.4487 4.36645 56.4487 4.43386C56.4487 4.50127 56.4752 4.56599 56.5224 4.61413C56.5695 4.66227 56.6337 4.68999 56.7011 4.69133H60.1015C60.1689 4.68999 60.2331 4.66227 60.2803 4.61413C60.3275 4.56599 60.3539 4.50127 60.3539 4.43386C60.3539 4.36645 60.3275 4.30173 60.2803 4.25359C60.2331 4.20545 60.1689 4.17773 60.1015 4.17639Z"
      fill="white"
    />
    <path
      d="M60.1015 5.14154H56.7011C56.6337 5.14288 56.5695 5.1706 56.5224 5.21874C56.4752 5.26688 56.4487 5.3316 56.4487 5.39901C56.4487 5.46642 56.4752 5.53114 56.5224 5.57928C56.5695 5.62742 56.6337 5.65514 56.7011 5.65648H60.1015C60.1689 5.65514 60.2331 5.62742 60.2803 5.57928C60.3275 5.53114 60.3539 5.46642 60.3539 5.39901C60.3539 5.3316 60.3275 5.26688 60.2803 5.21874C60.2331 5.1706 60.1689 5.14288 60.1015 5.14154Z"
      fill="white"
    />
    <path
      d="M18.5264 33.2804C18.6139 33.1851 18.6795 33.0719 18.7185 32.9486C18.7575 32.8253 18.769 32.6949 18.7522 32.5666C18.7354 32.4384 18.6906 32.3154 18.6211 32.2063C18.5516 32.0972 18.4591 32.0047 18.3499 31.9353L19.075 28.876L17.5404 29.4366L17.0871 32.2558C16.9826 32.4469 16.9519 32.6696 17.0006 32.8819C17.0494 33.0941 17.1743 33.2811 17.3517 33.4074C17.5291 33.5337 17.7467 33.5905 17.9632 33.5671C18.1797 33.5437 18.3801 33.4417 18.5264 33.2804Z"
      fill="#9E616A"
    />
    <path
      d="M22.2165 20.4603L21.6006 22.044C21.6006 22.044 21.8646 23.6277 21.4246 23.9796C20.9847 24.3316 21.1607 24.3316 21.1607 24.5955C21.1455 24.7849 21.0907 24.9689 20.9998 25.1356C20.9088 25.3024 20.7838 25.4481 20.6328 25.5633C20.2809 25.8273 18.6972 31.1942 18.6972 31.1942H17.1135C17.1647 30.7852 17.1647 30.3714 17.1135 29.9625C17.0255 29.3466 17.3774 27.7629 17.5534 27.4989C17.7294 27.235 17.5534 26.8831 17.5534 26.3552C17.5534 25.8273 17.9053 25.5633 17.9053 24.6835C17.9053 23.8037 18.9611 16.941 18.8732 16.1492C18.7852 15.3574 19.753 14.6535 19.753 14.6535H20.6328L22.2165 20.4603Z"
      fill="#E6E6E6"
    />
    <path d="M27.9302 61.7149H29.1493L29.7295 57.0125H27.9302V61.7149Z" fill="#9E616A" />
    <path
      d="M27.5327 60.8799L29.4804 60.7637V61.5982L31.3322 62.8771C31.4237 62.9402 31.4925 63.031 31.5288 63.136C31.565 63.2411 31.5668 63.355 31.5337 63.4611C31.5006 63.5672 31.4345 63.66 31.3449 63.7259C31.2554 63.7917 31.1472 63.8273 31.036 63.8273H28.7172L28.3175 63.0018L28.1614 63.8273H27.2871L27.5327 60.8799Z"
      fill="#2F2E41"
    />
    <path
      d="M12.6504 60.6792L13.7832 61.1301L16.0614 56.9757L14.3896 56.3102L12.6504 60.6792Z"
      fill="#9E616A"
    />
    <path
      d="M12.5897 59.7563L14.4423 60.3686L14.1337 61.144L15.3812 63.017C15.4428 63.1095 15.4732 63.2193 15.4681 63.3303C15.4629 63.4414 15.4223 63.5478 15.3524 63.6342C15.2824 63.7206 15.1866 63.7823 15.0791 63.8104C14.9715 63.8385 14.8578 63.8314 14.7546 63.7903L12.6001 62.9327L12.5341 62.018L12.0838 62.7272L11.2715 62.4038L12.5897 59.7563Z"
      fill="#2F2E41"
    />
    <path
      d="M25.4729 47.7223L23.4399 38.2351L20.2654 48.7762L15.2387 58.7718L15.212 58.8249L13.6198 57.9676C13.395 53.9089 13.9287 50.1056 15.8808 46.7799L16.8051 37.6516C16.8334 37.3397 17.523 30.0172 19.7062 28.7186L20.3456 27.2607L26.705 25.6901L26.7282 25.7161C27.3397 26.4035 27.7436 27.2502 27.8931 28.1579L29.7933 39.6697L29.7944 39.6761L29.2849 47.7068L29.9819 59.5548H27.46C25.9 56.6193 25.2376 52.6752 25.4729 47.7223Z"
      fill="#2F2E41"
    />
    <path
      d="M31.486 16.1269L35.3413 14.7782C35.4608 14.7368 35.5918 14.7445 35.7057 14.7996C35.8195 14.8547 35.9069 14.9526 35.9486 15.072C35.9904 15.1914 35.9832 15.3225 35.9285 15.4365C35.8738 15.5505 35.7761 15.6383 35.6569 15.6804L31.8017 17.0292C31.6822 17.0705 31.5511 17.0628 31.4373 17.0077C31.3234 16.9526 31.2361 16.8547 31.1943 16.7353C31.1525 16.6159 31.1598 16.4849 31.2145 16.3708C31.2691 16.2568 31.3668 16.1691 31.486 16.1269Z"
      fill="#2F2E41"
    />
    <path
      d="M35.6284 15.5576C35.7038 15.8187 36.0292 15.9455 36.2919 15.8759C36.5518 15.7869 36.7679 15.602 36.8962 15.359C37.0373 15.1268 37.1343 14.8696 37.2795 14.6399C37.5582 14.2034 37.9941 13.8908 38.497 13.7669C38.0489 13.7079 37.5937 13.7941 37.1982 14.0129C37.0591 14.1084 36.9055 14.1809 36.7432 14.2274C36.5702 14.2366 36.3967 14.2343 36.224 14.2206C36.0714 14.2284 35.9242 14.2796 35.7998 14.3683C35.6753 14.4569 35.5788 14.5793 35.5216 14.721C35.4645 14.8626 35.449 15.0177 35.477 15.1679C35.505 15.3181 35.5753 15.4571 35.6798 15.5687"
      fill="#4bafe1"
    />
    <path
      d="M35.1241 15.9729C35.1079 16.1012 35.0637 16.2244 34.9947 16.3338C34.9258 16.4432 34.8336 16.5362 34.7248 16.6061C34.616 16.6761 34.4932 16.7213 34.3651 16.7387C34.2369 16.7561 34.1065 16.7451 33.983 16.7066L32.1149 19.2354L31.5522 17.7016L33.4125 15.5353C33.4909 15.3321 33.6411 15.1648 33.8347 15.065C34.0283 14.9652 34.2517 14.9399 34.4627 14.9938C34.6737 15.0478 34.8575 15.1772 34.9794 15.3577C35.1013 15.5381 35.1528 15.757 35.1241 15.9729Z"
      fill="#9E616A"
    />
    <path
      d="M18.4714 30.3494C18.3002 30.3494 18.2029 30.3158 18.1652 30.2438C18.1099 30.1381 18.2059 30 18.3169 29.84C18.3944 29.7286 18.6206 29.507 18.5093 29.4934C16.8223 29.2873 17.6321 18.8864 18.3632 17.5116C18.5118 17.2322 18.3546 16.8036 18.5219 16.3296C18.9789 15.035 19.6026 14.6371 20.8451 13.8445C20.946 13.7801 21.051 13.7129 21.1602 13.6429C21.3015 13.5521 21.2936 13.2511 21.2853 12.9324C21.2761 12.5782 21.2665 12.2119 21.44 11.9829L21.4513 11.968L21.4695 11.9638C21.8892 11.8667 23.3009 11.6866 24.137 11.9651L24.1485 11.969L24.1571 11.9776C24.3557 12.1762 24.3738 12.5608 24.3913 12.9328C24.4057 13.2382 24.4192 13.5266 24.5316 13.6519C25.3906 14.6093 26.3648 14.5907 26.3747 14.5905L26.4229 14.589L26.4277 14.6371C27.0299 15.7833 26.5584 20.0404 26.977 22.6847C27.4023 25.3712 31.0343 27.6581 27.9243 28.0642C27.931 28.0745 27.9347 28.0865 27.9351 28.0988C27.9355 28.1111 27.9325 28.1232 27.9264 28.134C27.7385 28.4851 19.1169 30.3494 18.4714 30.3494Z"
      fill="#E6E6E6"
    />
    <path
      d="M28.3161 22.5254L26.6368 21.5807C23.7083 19.5509 22.9869 17.9389 22.8967 16.9437C22.8005 15.8817 23.3663 15.3008 23.3905 15.2767L23.4035 15.2672L24.9174 14.4937C25.1097 14.3954 25.3207 14.3392 25.5363 14.3287C25.752 14.3181 25.9675 14.3536 26.1684 14.4326C26.3694 14.5117 26.5512 14.6326 26.7019 14.7872C26.8526 14.9419 26.9687 15.1269 27.0425 15.3298L28.4428 19.1809L29.9953 18.0359L30.6574 17.7749L31.1916 17.0605L32.6589 16.2047L33.749 17.1858L32.3781 19.9274L31.6165 20.1214L31.455 20.5374L31.4406 20.5466L28.3161 22.5254Z"
      fill="#E6E6E6"
    />
    <path
      d="M25.3103 10.2039C24.9986 10.7738 24.5021 11.2207 23.9025 11.4707C23.3029 11.7208 22.6361 11.7593 22.0118 11.5797C21.3874 11.4002 20.8429 11.0134 20.4677 10.483C20.0926 9.95266 19.9093 9.31034 19.9479 8.66187C19.9866 8.01339 20.245 7.39742 20.6805 6.9154C21.116 6.43337 21.7027 6.11404 22.344 6.01C22.9852 5.90595 23.6428 6.02339 24.2084 6.34297C24.7739 6.66255 25.2138 7.16523 25.4556 7.76821C25.4687 7.80092 25.4804 7.83365 25.4922 7.86767C25.6289 8.24779 25.6833 8.65258 25.652 9.05529C25.6206 9.45801 25.5041 9.84949 25.3103 10.2039Z"
      fill="#9E616A"
    />
    <path
      d="M25.7211 7.86771C25.5981 7.5235 25.6517 7.70281 25.5274 7.35991C25.5674 7.52884 25.5551 7.70595 25.4921 7.86771H22.0879V5.88882C23.0852 5.49357 24.0602 5.15721 24.6492 5.88882C25.174 5.88884 25.6774 6.09734 26.0485 6.46845C26.4196 6.83956 26.6281 7.34289 26.6281 7.86771H25.7211Z"
      fill="#2F2E41"
    />
    <path
      d="M24.5143 13.3686C24.5117 13.3319 24.5078 13.294 24.5025 13.2573C24.4568 12.9885 24.3827 12.7252 24.2814 12.472C24.1832 12.1959 24.0837 11.9184 23.9843 11.6423C24.1123 12.1436 24.0733 12.6729 23.873 13.15C23.2985 12.7024 23.661 11.6789 22.9097 11.6789C21.1036 11.6789 19.2451 10.2144 19.2451 8.40693C19.2451 6.60079 20.0199 5.25012 21.8261 5.25012C23.6335 5.25012 25.0994 6.07727 25.1347 7.88342C22.9097 8.40694 24.9344 13.1853 24.5143 13.3686Z"
      fill="#2F2E41"
    />
    <path
      d="M19.471 8.13009C20.6275 8.13009 21.5651 7.19255 21.5651 6.03603C21.5651 4.8795 20.6275 3.94196 19.471 3.94196C18.3145 3.94196 17.377 4.8795 17.377 6.03603C17.377 7.19255 18.3145 8.13009 19.471 8.13009Z"
      fill="#2F2E41"
    />
    <path
      d="M20.1732 6.1174C19.8145 6.5349 19.2531 6.69719 18.7466 6.91445C17.8527 7.30661 17.0679 7.9109 16.4603 8.67492C15.8527 9.43895 15.4407 10.3396 15.2599 11.2989C15.1199 12.0803 15.1382 12.8891 14.917 13.6508C14.6972 14.4125 14.1527 15.1599 13.3714 15.292C12.8426 15.3823 12.3125 15.1782 11.8152 14.9766C11.5417 14.8667 11.2681 14.7563 10.9946 14.6455C11.2799 13.9296 11.5648 13.2137 11.8492 12.4978C11.3921 13.1734 10.8604 13.7955 10.2643 14.3523C9.28399 13.9571 8.30283 13.5618 7.3208 13.1666C9.73683 13.1757 9.32456 10.8304 9.8651 8.47715C10.1622 7.18406 12.6921 6.30848 13.1881 5.07822C13.4562 4.41093 13.9097 3.83439 14.4952 3.41684C15.0807 2.9993 15.7735 2.75822 16.4917 2.72215C17.2099 2.68607 17.9234 2.85651 18.5478 3.21328C19.1722 3.57006 19.6812 4.09824 20.0148 4.73531C20.5108 4.93294 20.5252 5.70513 20.1732 6.1174Z"
      fill="#2F2E41"
    />
    <path
      d="M60.3255 63.9701L5.13903 64C5.11575 64 5.09343 63.9878 5.07697 63.966C5.06052 63.9443 5.05127 63.9148 5.05127 63.8841C5.05127 63.8533 5.06052 63.8238 5.07697 63.8021C5.09343 63.7804 5.11575 63.7681 5.13903 63.7681L60.3255 63.7382C60.3487 63.7382 60.3711 63.7504 60.3875 63.7722C60.404 63.7939 60.4132 63.8234 60.4132 63.8541C60.4132 63.8849 60.404 63.9144 60.3875 63.9361C60.3711 63.9578 60.3487 63.9701 60.3255 63.9701Z"
      fill="#CACACA"
    />
  </svg>
);

export default PersonalizeExperience;
