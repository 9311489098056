import { useHistory } from 'react-router-dom';
import { t } from '../../i18n/i18n';
import CreateEvaluationIcon from '../../assets/createdWorkspace/CreateEvaluationIcon';
import InviteMembers from '../../assets/createdWorkspace/InviteMembers';
import PersonalizeExperience from '../../assets/createdWorkspace/PersonalizeExperience';
import CustomizeWorkspace from '../../assets/createdWorkspace/CustomizeWorkspace';
import { CONFIGURATION, CONFIGURATION_MEMBERS, CREATE_EVALUATION } from '../../constants/routes';
import './WorkspaceOptions.css';
import { PartnerWorkspaceRole } from '../../constants/enums';

const WorkspaceOptions = ({
  hideFirst,
  hideSecond,
  hideThird,
  hideFourth,
  className,
  currentRole,
  fromHome = false,
}) => {
  const history = useHistory();

  const options = [
    {
      title: 'WORKSPACE_OWNER_FIRST_TITLE',
      subtitle: 'WORKSPACE_OWNER_FIRST_SUBTITLE',
      Icon: CreateEvaluationIcon,
      path: CREATE_EVALUATION,
      buttonText: 'MENU_ITEM_CREATE_EVALUATION',
      workspaceRoles: [PartnerWorkspaceRole.OWNER, PartnerWorkspaceRole.ADMINISTRATOR],
      hide: hideFirst,
    },
    {
      title: 'WORKSPACE_OWNER_SECOND_TITLE',
      subtitle: 'WORKSPACE_OWNER_SECOND_SUBTITLE',
      Icon: InviteMembers,
      path: CONFIGURATION_MEMBERS,
      buttonText: 'WORKSPACE_OWNER_SECOND_BUTTON',
      workspaceRoles: [PartnerWorkspaceRole.OWNER, PartnerWorkspaceRole.ADMINISTRATOR],
      hide: hideSecond,
    },
    {
      title: 'WORKSPACE_OWNER_THIRD_TITLE',
      subtitle: 'WORKSPACE_OWNER_THIRD_SUBTITLE',
      Icon: PersonalizeExperience,
      path: `${CONFIGURATION}?tab=evaluation`,
      buttonText: 'WORKSPACE_OWNER_THIRD_BUTTON',
      workspaceRoles: [PartnerWorkspaceRole.OWNER, PartnerWorkspaceRole.ADMINISTRATOR],
      hide: hideThird,
    },
    {
      title: 'WORKSPACE_OWNER_FOURTH_TITLE',
      subtitle: 'WORKSPACE_OWNER_FOURTH_SUBTITLE',
      Icon: CustomizeWorkspace,
      path: CONFIGURATION,
      buttonText: 'WORKSPACE_OWNER_FOURTH_BUTTON',
      workspaceRoles: [PartnerWorkspaceRole.OWNER, PartnerWorkspaceRole.ADMINISTRATOR],
      hide: hideFourth,
    },
  ];

  return (
    <div
      data-testid="workspace-options-container"
      className={`${className} workspace-options-container`}
    >
      <div
        className={` ${
          fromHome ? ' w-100 rounded-bottom row' : ' mx-auto mt-4 created-workspace-grid-container'
        }`}
        style={{ background: 'black' }}
      >
        {options
          .filter((item) => !item.hide)
          .map(({ title, subtitle, Icon, path, buttonText, workspaceRoles }, index) => {
            return fromHome ? (
              <button
                key={index}
                style={{ color: '#FFFFFF' }}
                disabled={!workspaceRoles.includes(currentRole)}
                onClick={() => {
                  history.push(path);
                }}
                className={`font-weight-500 text-md custom-border-end justify-content-center align-items-center d-flex flex-column py-1 bg-transparent outline-none cursor-pointer ${
                  hideFourth ? 'col-12  col-lg-4' : 'col-12 col-md-6 col-lg-3'
                }`}
              >
                {t(title)}
              </button>
            ) : (
              <div
                data-testid="workspace-option"
                className="w-100 bg-white d-flex flex-column text-center align-items-center b-radius-8 p-2"
              >
                <Icon className="mt-4 mb-2" />
                <span className=" font-weight-700 text-base mb-2">{t(title)}</span>
                <span className=" font-weight-500 text-sm mb-2 px-5">{t(subtitle)}</span>
                <button
                  className="font-weight-700 mt-4 mb-4  text-xs font-bold complete-profile-button mx-auto bg-white"
                  type="button"
                  disabled={!workspaceRoles.includes(currentRole)}
                  onClick={() => {
                    history.push(path);
                  }}
                >
                  {t(buttonText)}
                </button>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default WorkspaceOptions;
