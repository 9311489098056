import {
  checkPassword,
  isEmail,
  regexDigits,
  regexLowerCase,
  regexSpecialCharName,
  regexSpecialCharacters,
  regexUpperCase,
} from '../../../constants/regexValidations';

export const isEmpty = (value) => value?.length === 0;

export const isMin = (value, min) => value?.length < min;

export const isMax = (value, max) => value?.length > max;

export const validations = (t) => ({
  name: (value) => {
    const errors = [];
    if (isEmpty(value)) {
      errors.push(t('PROFILE_NAME_REQUIRED_ERROR'));
    }
    if (isMin(value, 3)) {
      errors.push(t('PROFILE_MINIMUM_CHARACTERS'));
    }
    if (isMax(value, 20)) {
      errors.push(t('PROFILE_MAXIMUM_CHARACTERS'));
    }
    if (value.match(/[0-9]/)) {
      errors.push(t('PROFILE_NAME_NO_NUMBERS_ERROR'));
    }
    if (regexSpecialCharName.test(value)) {
      errors.push(t('PROFILE_NO_SPECIAL_CHAR_ERROR'));
    }
    return errors;
  },
  lastName: (value) => {
    const errors = [];
    if (isEmpty(value)) {
      errors.push(t('PROFILE_LASTNAME_REQUIRED_ERROR'));
    }
    if (isMin(value, 3)) {
      errors.push(t('PROFILE_MINIMUM_CHARACTERS'));
    }
    if (isMax(value, 20)) {
      errors.push(t('PROFILE_MAXIMUM_CHARACTERS'));
    }
    if (value.match(/[0-9]/)) {
      errors.push(t('PROFILE_LASTNAME_NO_NUMBERS_ERROR'));
    }
    if (regexSpecialCharName.test(value)) {
      errors.push(t('PROFILE_NO_SPECIAL_CHAR_ERROR'));
    }
    return errors;
  },
  email: (value) => {
    const errors = [];
    const blacklist = ['gmail', 'hotmail', 'outlook', 'live', 'yahoo'];
    const emailSplit = value?.split('@');
    const domain = emailSplit[emailSplit.length - 1];
    const domainSplit = domain?.split('.');
    const domainToValidate = domainSplit[0];
    const validCompanyEmail = !blacklist.includes(domainToValidate.toLowerCase());
    if (isEmpty(value)) {
      errors.push(t('EMAIL_REQUIRED_ERROR'));
    }
    if (!validCompanyEmail) {
      errors.push(t('EMAIL_COMPANY_MAIL_REQUIRED'));
    }
    if (!value.match(isEmail)) {
      errors.push(t('PROFILE_EMAIL_INVALID'));
    }
    return errors;
  },
  password: (value) => {
    const errors = [];
    if (isEmpty(value)) {
      errors.push(t('PASSWORD_REQUIRED_ERROR'));
    }
    if (isMin(value, 8)) {
      errors.push(t('PASSWORD_VALIDATION_LENGTH'));
    }
    if (!checkPassword(regexLowerCase, value)) {
      errors.push(t('PASSWORD_VALIDATION_MINUSCULE'));
    }
    if (!checkPassword(regexUpperCase, value)) {
      errors.push(t('PASSWORD_VALIDATION_CAPITAL_LETTER'));
    }
    if (!checkPassword(regexDigits, value)) {
      errors.push(t('PASSWORD_VALIDATION_NUMBER'));
    }
    if (!checkPassword(regexSpecialCharacters, value)) {
      errors.push(t('PASSWORD_VALIDATION_SPECIAL_CHARACTER'));
    }
    return errors;
  },
  verifypassword: (value) => {
    const errors = [];
    if (isEmpty(value)) {
      errors.push(t('VERIFY_PASSWORD_REQUIRED_ERROR'));
    }
    return errors;
  },
});

export const validationsFn = (t) => (key, value) => validations(t)[key](value);
