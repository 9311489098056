const Video = () => {
  return (
    <svg width="31" height="27" viewBox="0 0 36 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.625 0C4.13316 0 2.70242 0.592632 1.64752 1.64752C0.592632 2.70242 0 4.13316 0 5.625V21.375C0 22.8668 0.592632 24.2976 1.64752 25.3525C2.70242 26.4074 4.13316 27 5.625 27H21.375C22.8668 27 24.2976 26.4074 25.3525 25.3525C26.4074 24.2976 27 22.8668 27 21.375V19.125L32.4 23.175C32.7343 23.4257 33.1318 23.5784 33.5479 23.6159C33.9641 23.6534 34.3825 23.5743 34.7562 23.3875C35.13 23.2006 35.4443 22.9133 35.664 22.5579C35.8836 22.2024 36 21.7929 36 21.375V5.625C36 5.20715 35.8836 4.79755 35.664 4.44211C35.4443 4.08666 35.13 3.79941 34.7562 3.61254C34.3825 3.42567 33.9641 3.34657 33.5479 3.38409C33.1318 3.42162 32.7343 3.57429 32.4 3.825L27 7.875V5.625C27 4.13316 26.4074 2.70242 25.3525 1.64752C24.2976 0.592632 22.8668 0 21.375 0H5.625ZM27 10.6875L33.75 5.625V21.375L27 16.3125V10.6875ZM24.75 5.625V21.375C24.75 22.2701 24.3944 23.1286 23.7615 23.7615C23.1286 24.3944 22.2701 24.75 21.375 24.75H5.625C4.72989 24.75 3.87145 24.3944 3.23851 23.7615C2.60558 23.1286 2.25 22.2701 2.25 21.375V5.625C2.25 4.72989 2.60558 3.87145 3.23851 3.23851C3.87145 2.60558 4.72989 2.25 5.625 2.25H21.375C22.2701 2.25 23.1286 2.60558 23.7615 3.23851C24.3944 3.87145 24.75 4.72989 24.75 5.625Z"
        fill="#686868"
      />
    </svg>
  );
};

export default Video;
