import axios from 'axios';
import { alkymetricsClient } from '../alkymetricsClient';

export const getMe = async () => {
  const { data } = await alkymetricsClient.get(`/partner/me`);
  return data;
};

export const getMaintenance = async () => {
  const { data } = await alkymetricsClient.get('/maintenance');
  return data;
};

export const editMe = async ({ toEdit }) => {
  const { data } = await alkymetricsClient.post(`/partner/me`, toEdit);
  return data;
};

export const postRequestMainWorkspace = async ({ body }) => {
  const { data } = await alkymetricsClient.post('/workspace/request/workspaceInvitation', body);
  return data;
};

export const changeEnvironment = async (environment) => {
  const { data } = await alkymetricsClient.post('/partner/me', { environment });
  return data;
};

export const getWhiteLabel = async (language = 'es') => {
  const { data } = await alkymetricsClient.get('/whitelabel/me', {
    headers: {
      ...(process.env.RAZZLE_RUNTIME_ORIGIN_DEV
        ? { OriginDev: process.env.RAZZLE_RUNTIME_ORIGIN_DEV }
        : {}),
      ...{ language },
    },
  });
  return data;
};

export const getWhiteLabelByCompanyId = async (companyId, language = 'es') => {
  const { data } = await alkymetricsClient.get(`/whitelabel/${companyId}`, {
    headers: {
      ...(process.env.RAZZLE_RUNTIME_ORIGIN_DEV
        ? { OriginDev: process.env.RAZZLE_RUNTIME_ORIGIN_DEV }
        : {}),
      language,
    },
  });
  return data;
};

export const resendEmailVerifyAccount = async () => {
  const { data } = await alkymetricsClient.get('/resend/email?platform=PARTNERS');
  return data;
};

export const sendPartnerAccessEmail = async () => {
  try {
    await alkymetricsClient.post('/email/profile/partnerAccess');
  } catch {}
};

export const getPublicToken = async () => {
  const data = await alkymetricsClient.get(`/users/public/token`, {
    headers: {
      secret: process.env.RAZZLE_RUNTIME_PUBLIC_TOKEN_SECRET,
    },
  });
  return data?.data;
};

export const getVideosForCompany = async (companyId) => {
  const { data } = await alkymetricsClient.get(
    `/recruitSessionStage/previousWelcomeVideos/${companyId}`
  );
  return data;
};

export const uploadWelcomeVideoAsync = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  const { data } = await alkymetricsClient.post('/partner/upload/welcomeVideo', formData, {
    headers: {
      'Content-Type': 'video/mp4',
    },
  });
  return data;
};

export const deleteVideoById = async (videoId) => {
  const { data } = await alkymetricsClient.delete(`/partner/welcomeVideo/${videoId}`);
  return data;
};
