import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import EditPencilIcon from '../../assets/common/EditPencilIcon';
import DeleteIcon from '../../assets/profile/Delete';
import profileImageNull from '../../assets/profile/profile.png';
import { BiHide, BiShow, BiBookOpen } from 'react-icons/bi';

import { RiDraftFill } from 'react-icons/ri';
import VerticalDotsDropdown from '../../components/VerticalDotsDropdown/VerticalDotsDropdown';
import { t } from '../../i18n/i18n';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Button } from 'antd';
import DiamondIcon from '../../assets/disabledFeature/DiamondIcon';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const Job = ({ job, user, deleteDraft, editJob, shouldDisableOptions }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const history = useHistory();

  const editJobVisibility = () => {
    editJob({
      showModalData: {
        title: job?.confidential ? t('JOB_POST_EDIT_SHOW_TITLE') : t('JOB_POST_EDIT_HIDE_TITLE'),
        subtitle: job?.confidential
          ? t('JOB_POST_EDIT_SHOW_SUBTITLE')
          : t('JOB_POST_EDIT_HIDE_SUBTITLE'),
        onOkText: job?.confidential
          ? t('JOB_POST_EDIT_SHOW_OK_TITLE')
          : t('JOB_POST_EDIT_HIDE_OK_TITLE'),
      },
      jobPostData: {
        hashId: job?.hashId,
        confidential: !job?.confidential,
        companyId: job?.company?.id,
      },
    });
  };

  const dropdownOptions = [
    {
      icon: job.isDraft ? <BiBookOpen /> : <RiDraftFill />,
      text: job.isDraft ? t('JOB_POST_OPEN') : t('JOB_POST_DRAFT'),
      disabled: false,
      action: () => {
        if (job?.hashId)
          editJob({
            showModalData: {
              title: job.isDraft ? t('JOB_POST_EDIT_OPEN_TITLE') : t('JOB_POST_EDIT_DRAFT_TITLE'),
              subtitle: job.isDraft
                ? t('JOB_POST_EDIT_OPEN_SUBTITLE')
                : t('JOB_POST_EDIT_DRAFT_SUBTITLE'),
              onOkText: job.isDraft
                ? t('JOB_POST_EDIT_OPEN_OK_TITLE')
                : t('JOB_POST_EDIT_DRAFT_OK_TITLE'),
            },
            jobPostData: {
              hashId: job?.hashId,
              isDraft: !job.isDraft,
              isOpen: job.isDraft,
              companyId: job?.company?.id,
            },
          });
      },
      itemClass: `dropdown-option`,
      testid: 'draft-button',
    },
    {
      icon: job?.confidential ? <BiShow /> : <BiHide />,
      text: job?.confidential ? t('JOB_POST_SHOW') : t('JOB_POST_HIDE'),
      disabled: false,
      action: () => {
        if (job?.hashId) editJobVisibility();
      },
      itemClass: `dropdown-option`,
      testid: 'hide-button',
    },
    {
      icon: <EditPencilIcon className="icon" fill="#757575" width="14" height="14" />,
      text: t('EDIT'),
      disabled: false,
      action: () => {
        if (job?.hashId) goToEditJob(job?.hashId);
      },
      itemClass: `dropdown-option`,
      testid: 'edit-button',
    },
    {
      icon: <DeleteIcon className="icon" />,
      text: t('DELETE_TOOLTIP'),
      disabled: false,
      action: () => {
        deleteDraft(job?.id);
        setOpenDropdown(false);
      },
      itemClass: `dropdown-delete-option`,
      testid: 'delete-button',
    },
  ];
  const searchStatus = (job) => {
    if (job.isDraft) return t('SCREENING_SEARCH_DRAFT');
    if (!job.isActive) return t('SEARCH_INACTIVE');
    return t('SEARCH_ACTIVE');
  };
  const goToEditJob = (jobId) => {
    history.push(`/jobs/${jobId}/edit`);
  };
  const goToJob = (jobId) => {
    history.push(`/jobs/${jobId}`);
  };

  const searchApplicattionsAmount = (jobTotalApplied) => {
    if (!jobTotalApplied) return 0;
    if (jobTotalApplied <= 20 && jobTotalApplied >= 0) return `${jobTotalApplied}`;
    if (jobTotalApplied > 20 && jobTotalApplied <= 50) return '+20';
    if (jobTotalApplied > 50 && jobTotalApplied <= 100) return '+50';
    if (jobTotalApplied > 100 && jobTotalApplied <= 500) return '+100';
    if (jobTotalApplied > 500 && jobTotalApplied <= 1000) return '+500';
    return '+1000';
  };
  return (
    <div className="pt-3 my-4 bg-white b-radius-8 d-flex h-100 flex-column w-100 bg-white shadow-sm">
      <section className="px-4 d-flex justify-content-between">
        <div className="d-flex">
          <img
            className="rounded-circle profile-personalization-img"
            src={job?.company?.picture || profileImageNull}
            alt={t('PROFILE_MENU_LOGO_USER')}
          />
          <section className="d-flex flex-column mb-4 ml-2">
            <span className="font-montserrat text-sm">{job?.createdAt}</span>
            <Link
              data-testid="name-button"
              rel="noreferrer noopener"
              to={job?.hashId ? `/jobs/${job?.hashId}` : null}
              style={{ color: '#4bafe1' }}
              className="font-montserrat text-break text-lg font-weight-700"
            >
              {job?.title}
            </Link>
            <span style={{ color: '#4bafe1' }} className="font-montserrat text-md font-weight-500">
              {t(job?.seniority)}
            </span>
            <span className="font-montserrat text-md font-weight-600">
              {job?.company?.displayName}
            </span>
          </section>
        </div>
        <VerticalDotsDropdown
          options={dropdownOptions}
          className="mr-2 width-selectable"
          setOpenDropdown={setOpenDropdown}
          openDropdown={openDropdown}
          disabled={shouldDisableOptions}
        />
      </section>

      {/* <section className="d-flex w-100 my-2">
        <div className="d-flex flex-wrap">
          {job?.tags?.map((tag) => (
            <span className="badge badge-pill badge-light font-montserrat text-sm font-weight-500 px-2">
              {tag}
            </span>
          ))}
        </div>
      </section> */}

      {job?.technologies && job?.technologies?.length ? (
        <section className="px-4 d-flex w-100 my-4">
          <div className="d-flex flex-wrap justify-content-center">
            {job?.technologies?.map((technology) => (
              <span className="badge badge-pill font-montserrat text-sm font-weight-500 px-3 py-2">
                <div className="d-flex justify-content-center my-1">
                  <img
                    className="rounded-circle border-radius-100"
                    src={technology?.imageUrl || profileImageNull}
                    alt={t('PROFILE_MENU_LOGO_USER')}
                    style={{ height: '2em', borderRadius: '100%' }}
                  />
                </div>

                {technology?.displayName}
              </span>
            ))}
          </div>
        </section>
      ) : null}
      {/* <section className="px-4 d-flex w-100 my-4 flex-wrap justify-content-start">
        <span className="badge badge-pill badge-light font-montserrat text-sm font-weight-500 px-2">
          {job?.contractType}
        </span>
        <span className="badge badge-pill badge-light font-montserrat text-sm  font-weight-500 px-2">
          {job?.industry}
        </span>
        <span className="badge badge-pill badge-light font-montserrat text-sm font-weight-500 px-2">
          {job?.modality}
        </span>
        <span className="badge badge-pill badge-light font-montserrat text-sm  font-weight-500 px-2">
          {job?.searchRole}
        </span>
      </section> */}
      <section
        data-testid="footer-card"
        className={`px-4 mt-auto footer-card ${
          job.isDraft
            ? 'gray-footer-card'
            : !job.isActive
            ? 'closed-footer-card-dark-grey'
            : 'open-footer-card'
        } d-flex justify-content-between`}
      >
        <div className="d-flex">
          <div
            className={`d-flex align-items-center ticket-status-search text-white ${
              job.isDraft ? 'ticket-gray' : !job.isActive ? 'ticker-dark-grey' : 'ticket-open'
            }`}
          >
            <span className="text-uppercase font-roboto">{searchStatus(job)}</span>
          </div>
        </div>
        <div className="d-flex position-relative w-100 justify-content-between ml-2">
          {job?.partner?.firstName && (
            <span
              data-testid="span-created-by"
              className="font-montserrat text-muted text-sm font-weight-500 overflow-hidden text-truncate d-flex align-items-center"
            >
              {t('RECRUIT_SESSION_DRAFT_CREATED_BY')}
              <b className="font-montserrat text-muted text-sm ml-1">
                {` ${job?.partner?.firstName} ${job?.partner?.lastName}`}
              </b>
            </span>
          )}
        </div>

        <div className="d-flex column align-items-center justify-content-center">
          <div className="mx-1">
            <OverlayTrigger
              placement={'top'}
              overlay={
                <Tooltip className="font-montserrat">
                  {job?.confidential ? t('JOB_POST_HIDDEN_LABEL') : t('JOB_POST_VISIBLE_LABEL')}
                </Tooltip>
              }
            >
              <Button
                disabled={shouldDisableOptions}
                type="text"
                shape="circle"
                icon={job?.confidential ? <BiHide /> : <BiShow />}
                size="middle"
                ghost
                className="shadow-sm"
                onClick={() => {
                  if (job?.hashId) editJobVisibility();
                }}
              />
            </OverlayTrigger>
          </div>

          {job?.isPremium && (
            <div className="mx-1">
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip className="font-montserrat">
                    {t('LABEL_JOBPOST_TOGGLE_PLAN_PREMIUM')}
                  </Tooltip>
                }
              >
                <Button
                  disabled={shouldDisableOptions}
                  type="text"
                  shape="circle"
                  icon={<DiamondIcon className={``} />}
                  size="middle"
                  ghost
                  className="shadow-sm p-1 cursor-normal"
                />
              </OverlayTrigger>
            </div>
          )}
        </div>
        <div className="d-flex mx-3 mb-1">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="font-montserrat">{t('RECRUIT_SESSION_TOTAL_APPLIED')}</Tooltip>
            }
          >
            {job?.totalApplied >= 0 && (
              <span
                data-testid="span-created-by"
                className="font-montserrat text-muted text-sm font-weight-500 overflow-hidden text-truncate d-flex align-items-center"
              >
                <b className="font-montserrat text-muted text-sm mt-1">
                  {searchApplicattionsAmount(job?.totalApplied)}
                </b>
              </span>
            )}
          </OverlayTrigger>
        </div>
      </section>
    </div>
  );
};

Job.propTypes = {};

export default Job;
