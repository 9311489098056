import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { Modal, notification } from 'antd';
import { useSelector } from 'react-redux';
import Spinner from '../spinner/Spinner';
import EmptyComponent from '../EmptyComponent/EmptyComponent';
import { t } from '../../i18n/i18n';
import {
  createComment,
  deleteComment,
  getInvitationComments,
} from '../../API/services/screeningService';
import {
  getUserCompanyComments,
  createUserCompanyComment,
  deleteUserCompanyComment,
} from '../../API/services/companyService';
import closeIcon from '../../assets/createSearch/close-icon.svg';
import './InvitedProfileComments.scss';
import DeleteIcon from '../../assets/profile/Delete';

const InvitedProfileComments = ({ className, isInvitedProfile }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedComment, setSelectedComment] = useState(0);
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((state) => state.auth);
  const [comments, setComments] = useState([]);
  const { id, profileId, recruitSessionId } = useParams();

  useEffect(() => {
    setLoading(true);

    const getCommentsFn = () => {
      if (isInvitedProfile) {
        return getInvitationComments(profileId, recruitSessionId, {
          order: 'DESC',
          orderBy: 'DATE',
        });
      }
      return getUserCompanyComments(id);
    };

    getCommentsFn().then((res) => {
      setComments(res?.collection || res);
      setLoading(false);
    });
  }, []);

  const handlePostComment = async () => {
    if (text.length > 100) {
      notification.open({
        message: t('NOTES_LIMIT_OF_CHARACTERS'),
        type: 'error',
      });
      return null;
    }

    const createCommentFn = () => {
      if (isInvitedProfile) {
        return createComment({ text, profileId, recruitSessionId });
      }
      return createUserCompanyComment(text, id);
    };

    await createCommentFn().then((res) => {
      if (!Object.keys(res).length) {
        notification.open({
          message: t('CREATE_COMMENT_ERROR'),
          type: 'error',
        });
      } else {
        notification.open({
          message: t('CREATE_COMMENT_SUCCESS'),
          type: 'success',
        });
        setComments([
          {
            createdAt: res.createdAt,
            text,
            id: res.id,
            partnerName: `${user?.firstName} ${user?.lastName}`,
            partnerId: user.id,
            authorEmail: user.email,
          },
          ...comments,
        ]);
        setText('');
      }
      setShowModal(false);
    });
  };

  const handleDeleteComment = async () => {
    const deleteCommentFn = isInvitedProfile ? deleteComment : deleteUserCompanyComment;

    await deleteCommentFn(selectedComment).then(() => {
      notification.open({
        message: t('DELETE_COMMENT_SUCCESS'),
        type: 'success',
      });
      setComments(comments?.filter((item) => item.id !== selectedComment));
      setSelectedComment(0);
    });
  };

  return (
    <div
      className={`position-relative description-container px-4 py-3 bg-white b-radius-8 mx-auto notes-section ${className}`}
    >
      <Modal
        className="screening-profile-comment-modal"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        closeIcon={<img src={closeIcon} alt={t('CLOSE_ALT')} />}
        footer={
          <button
            onClick={handlePostComment}
            className="rounded-pill font-weight-bold d-flex align-items-center border-0 outline-none px-4 py-2 font-montserrat text-white bg-maya-blue"
            type="button"
          >
            {t('SAVE_COMMENT_BUTTON')}
          </button>
        }
      >
        <textarea
          className="rounded p-2 outline-none w-100 mt-4 border comment-input-height font-montserrat text-sm"
          placeholder={t('ADD_COMMENT_PLACEHOLDER')}
          value={text}
          onChange={(e) => {
            if (e.target.value.length < 255) setText(e.target.value);
          }}
          type="text"
          maxLength={255}
        />
      </Modal>
      <Modal
        visible={Boolean(selectedComment)}
        onOk={handleDeleteComment}
        onCancel={() => setSelectedComment(0)}
        okText={t('DELETE_COMMENT_MODAL_TEXT_CONFIRM')}
        cancelText={t('DELETE_COMMENT_MODAL_TEXT_CANCEL')}
      >
        <div className="p-3 invitation-status-modal-container">
          <span className="font-montserrat">{t('DELETE_COMMENT_MODAL_TEXT')}</span>
        </div>
      </Modal>
      <div className="d-flex justify-content-between">
        <span className="font-tall font-weight-600 font-montserrat notes-title">
          {t('INVITED_PROFILE_COMMENTS_TITLE')}
        </span>
      </div>
      {loading ? (
        <Spinner show />
      ) : (
        <div>
          {!comments?.length ? (
            <EmptyComponent
              className="p-4"
              title={t('PROFILE_COMMENTS_EMPTY_STATE_TITLE')}
              subtitle={
                <button
                  onClick={() => setShowModal(true)}
                  type="button"
                  className="text-xs border-maya-blue cursor-pointer rounded-pill d-flex align-items-center font-weight-700 text-maya-blue bg-white notes-empty-state-button border-button"
                >
                  {t('ADD_PROFILE_NOTES')}
                </button>
              }
              subtitleClassname="text-center"
            />
          ) : (
            <>
              {comments?.map((comment, i) => (
                <div className={`border-bottom d-flex flex-column ${i !== 0 && 'mt-4'}`}>
                  <div className="d-flex">
                    <span className="font-montserrat font-weight-bold text-sm invited-profile-comment-title-data mr-2 text-xs">{`${
                      comment.partnerName
                        ? comment.partnerName
                        : `${comment.authorName} ${comment.authorLastname}`
                    } (${format(new Date(comment.createdAt), 'dd/MM/yyyy')})`}</span>
                    {user?.id === comment.partnerId || user?.id === comment.authorId ? (
                      <button
                        type="button"
                        onClick={() => setSelectedComment(comment?.id)}
                        className="invited-profile-delete-comment-button d-flex align-items-center outline-none"
                      >
                        <DeleteIcon fill="#4bafe1" />
                      </button>
                    ) : null}
                  </div>
                  <p className="font-montserrat text-sm mt-2 invited-profile-comment-text text-xs font-weight-500">
                    {comment.text || comment.comment}
                  </p>
                </div>
              ))}
              <button
                onClick={() => setShowModal(true)}
                type="button"
                className="bg-transparent border-0 outline-none mt-1"
              >
                <span className="font-montserrat font-weight-600 text-xs purple-color">
                  {t('ADD_PROFILE_NOTES')}
                </span>
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default InvitedProfileComments;
