const Icon6 = ({ color = '#BCBCBC' }) => (
  <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0003 0C11.6965 0 11.3966 0.0524987 11.1116 0.153746L0.593063 3.9524C0.236822 4.08365 0.000577196 4.42114 0.000577196 4.79988C0.000577196 5.17863 0.236822 5.51612 0.593063 5.64736L2.76426 6.4311C2.14928 7.39857 1.80053 8.54229 1.80053 9.74602V10.7997C1.80053 11.8647 1.39554 12.9634 0.964304 13.8297C0.72056 14.3172 0.443067 14.7971 0.120574 15.2396C0.000577198 15.4009 -0.033172 15.6109 0.0343264 15.8021C0.101825 15.9934 0.259321 16.1359 0.454316 16.1846L2.85426 16.7846C3.01175 16.8258 3.1805 16.7958 3.31925 16.7096C3.45799 16.6234 3.55549 16.4809 3.58549 16.3196C3.90798 14.7146 3.74674 13.2747 3.50674 12.2435C3.38675 11.711 3.2255 11.1672 3.0005 10.6685V9.74602C3.0005 8.61354 3.383 7.54482 4.04673 6.68984C4.53047 6.1086 5.1567 5.63986 5.89169 5.35112L11.779 3.03743C12.0865 2.91743 12.4353 3.06743 12.5553 3.37492C12.6753 3.68241 12.5253 4.03115 12.2178 4.15115L6.33043 6.46484C5.86544 6.64859 5.4567 6.92983 5.12295 7.27483L11.1078 9.43477C11.3928 9.53602 11.6928 9.58852 11.9965 9.58852C12.3003 9.58852 12.6003 9.53602 12.8853 9.43477L23.4075 5.64736C23.7638 5.51987 24 5.17863 24 4.79988C24 4.42114 23.7638 4.08365 23.4075 3.9524L12.889 0.153746C12.604 0.0524987 12.304 0 12.0003 0ZM4.80046 14.0997C4.80046 15.4234 8.02538 16.7996 12.0003 16.7996C15.9752 16.7996 19.2001 15.4234 19.2001 14.0997L18.6264 8.64729L13.294 10.5747C12.8778 10.7247 12.439 10.7997 12.0003 10.7997C11.5615 10.7997 11.1191 10.7247 10.7066 10.5747L5.3742 8.64729L4.80046 14.0997Z"
      fill={color}
    />
  </svg>
);

export default Icon6;
