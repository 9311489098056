import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FilterItem from '../FilterItem/FilterItem';

function FiltersList({ filters, addFilter, className, type, removeAppliedFilter, limit }) {
  const query = useSelector((state) => state.profiles.query);
  const [filtersQuery, setFiltersQuery] = useState([]);
  const [sortedFilters, setSortedFilters] = useState([]);

  useEffect(() => {
    setFiltersQuery(query[type]?.split(',') ?? []);
  }, [query]);

  useEffect(() => {
    if (limit) {
      const sorted = filters
        ?.sort((a, b) => Number(filtersQuery.includes(b.id)) - Number(filtersQuery.includes(a.id)))
        ?.slice(0, limit);
      setSortedFilters(sorted);
    } else {
      setSortedFilters(filters);
    }
  }, [filters, filtersQuery]);

  return (
    <>
      {sortedFilters.map((filter, i) => (
        <FilterItem
          key={i}
          type={type}
          filter={filter}
          addFilter={addFilter}
          removeAppliedFilter={removeAppliedFilter}
          className={className}
          selected={filtersQuery.includes(String(filter.id))}
        />
      ))}
    </>
  );
}

export default FiltersList;
