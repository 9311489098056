import { t } from 'i18n-js';
import { Orders } from '../../constants/enums';
import arrow from '../../assets/dashboard/arrow-small.svg';
import './OrderingAngles.scss';

const OrderingAngles = ({ order }) => (
  <div data-testid="indicator-order-angles">
    {order === Orders.ASC ? (
      <span data-active={order === Orders.ASC}>
        <img
          src={arrow}
          alt={t('ARROW_ALT')}
          className="dropdown-select-arrow-open ordering-angle"
        />
      </span>
    ) : (
      <span data-active={order === Orders.DESC}>
        <img src={arrow} alt={t('ARROW_ALT')} className="ordering-angle" />
      </span>
    )}
  </div>
);

export default OrderingAngles;
