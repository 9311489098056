const Icon3 = ({ color = '#BCBCBC' }) => (
  <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.8 0C3.47625 0 2.4 1.07625 2.4 2.4V12H4.8V2.4H19.2V12H21.6V2.4C21.6 1.07625 20.5238 0 19.2 0H4.8ZM0.72 13.2C0.3225 13.2 0 13.5225 0 13.92C0 15.51 1.29 16.8 2.88 16.8H21.12C22.71 16.8 24 15.51 24 13.92C24 13.5225 23.6775 13.2 23.28 13.2H0.72Z"
      fill={color}
    />
  </svg>
);

export default Icon3;
