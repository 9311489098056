import { alkymetricsClient } from '../alkymetricsClient';

export const getAlkymersStats = async (params) => {
  try {
    const { data } = await alkymetricsClient.get('/partner/stats', { params });
    return data;
  } catch (err) {
    return undefined;
  }
};
