const MinusWarning = () => (
  <svg
    data-testid="minus-warning"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11" cy="11" r="11" fill="#FFD329" />
    <path
      d="M15.7143 10.0834H6.28571C5.85185 10.0834 5.5 10.4352 5.5 10.8691V11.6548C5.5 12.0887 5.85185 12.4405 6.28571 12.4405H15.7143C16.1481 12.4405 16.5 12.0887 16.5 11.6548V10.8691C16.5 10.4352 16.1481 10.0834 15.7143 10.0834Z"
      fill="#806600"
    />
  </svg>
);

export default MinusWarning;
