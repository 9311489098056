import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { t } from '../i18n/i18n';
import { verifyCompleteData } from '../utils/verifyCompleteData';
import {
  setNewSearchData,
  setSearchInvitationsData,
  setSendEmailFrom,
} from '../redux/actions/screeningActions';
import { createCustomSearch } from '../API/services/screeningService';
import { TrackEvent } from '../utils/filters/segmentUtils';

const useCreateSearch = () => {
  const dispatch = useDispatch();
  const { newSearch, screenings } = useSelector((state) => state.screenings);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [currentScreening, setCurrentScreening] = useState(undefined);
  const [selectedStage, setSelectedStage] = useState(1);
  const stageData = newSearch?.stages?.find((item) => item.stage === selectedStage);
  const [fullData, setFullData] = useState(false);
  const [displayProctoringMeasuresScreen, setDisplayProctoringMeasuresScreen] = useState(false);

  const handleChange = (stage) => {
    if (stage) {
      setSelectedStage(1);
      dispatch(
        setNewSearchData({
          ...newSearch,
          stagesQuantity: stage.id,
          stages: newSearch?.stages?.slice(0, stage.id),
        })
      );
    }
  };
  const formatDate = (day, hour, iso = false) => {
    const date = moment(day).add(hour, 'hours');
    if (iso) return date.toISOString();
    return date;
  };

  const formatDateWithObject = (day, hour, iso = false) => {
    const date = moment(day).add(hour);
    if (iso) return date.toISOString();
    return date;
  };

  const setStageData = (stageNumber, data) => {
    dispatch(
      setNewSearchData({
        ...newSearch,
        stages: [...newSearch.stages.filter((item) => item.stage !== stageNumber), data],
      })
    );
  };

  useEffect(() => {
    setFullData(verifyCompleteData(newSearch.stagesQuantity, newSearch));
  }, [newSearch]);

  useEffect(() => {
    setCurrentScreening(screenings?.find((item) => item?.levelId === stageData?.levelId));
  }, [selectedStage, screenings, stageData]);

  const handleNextStage = () => {
    const currentStage = newSearch?.stages?.find((item) => item.stage === selectedStage);
    if (
      formatDateWithObject(currentStage?.startDate?.day, currentStage?.startDate?.hours) <
      formatDateWithObject(currentStage?.endDate?.day, currentStage?.endDate?.hours)
    ) {
      if (selectedStage === newSearch.stagesQuantity && fullData) {
        dispatch(
          setNewSearchData({
            ...newSearch,
            step: newSearch.step + 1,
          })
        );
        history.push('/createsearch/resume');
      } else {
        setSelectedStage(selectedStage + 1);
      }
    } else {
      notification.open({
        message: t('CREATE_SEARCH_STAGE_END_DATE_BEFORE_START_DATE'),
        type: 'error',
      });
    }
  };

  const handleBackStage = () => {
    setSelectedStage(selectedStage - 1);
  };

  const handleBackStep = () => {
    TrackEvent('create-search-back-p2');
    dispatch(
      setNewSearchData({
        ...newSearch,
        step: newSearch.step - 1,
      })
    );
    history.goBack();
  };

  const postData = async () => {
    TrackEvent('create-search-next-p2');
    setLoading(true);
    const newSearchData = {
      name: newSearch?.name,
      description: '',
      stages: newSearch?.stages?.map((item) => ({
        ...(item.levelId ? { levelId: item.levelId } : {}),
        companyId: item.companyId,
        startDate: formatDateWithObject(item?.startDate?.day, item?.startDate?.hours).toISOString(),
        endDate: formatDateWithObject(item?.endDate?.day, item?.endDate?.hours).toISOString(),
        name: newSearch?.name,
      })),
    };
    await createCustomSearch(newSearchData).then((res) => {
      if (Object.keys(res)) {
        notification.open({
          message: t('SEARCH_CREATED_SUCCESSFULLY'),
          type: 'success',
        });
        dispatch(
          setNewSearchData({
            ...newSearch,
            step: newSearch.step + 1,
            stages: res.stages,
          })
        );
        dispatch(setSearchInvitationsData({}));
        setLoading(false);
        setSelectedStage(1);
        dispatch(setSendEmailFrom('new'));
        history.push('/createsearch/success');
      } else {
        notification.open({
          message: t('SEARCH_CREATE_ERROR'),
          type: 'error',
        });
      }
    });
  };

  return {
    customSearchData: newSearch,
    fullData,
    selectedStage,
    stageData,
    currentScreening,
    formattedScreenings: screenings?.map((item) => ({
      id: item?.levelId,
      name: item.companyId ? item?.screeningsName : `[Alkemy] ${item?.screeningsName}`,
      companyId: item.companyId,
      technologyId: item?.technologyId,
      recruitSessionId: item?.id,
      duration: item?.averageDuration,
      screeningId: item?.screeningId,
    })),
    step: newSearch.step,
    loading,
    displayProctoringMeasuresScreen,
    formatDate,
    formatDateWithObject,
    handleBackStage,
    handleChange,
    handleBackStep,
    handleNextStage,
    setStageData,
    postData,
    setSelectedStage,
    setDisplayProctoringMeasuresScreen,
  };
};

export default useCreateSearch;
