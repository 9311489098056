import React from 'react';

const Arrow = ({ className, modal = false }) => (
  <svg
    width="28"
    height="28"
    className={className}
    viewBox={`0 0 28 ${modal ? '28' : '12'}`}
    fill={'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    {modal ? (
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28 14C28 6.26802 21.732 6.67946e-06 14 7.01744e-06C6.26801 7.35541e-06 -9.49935e-07 6.26802 -6.11959e-07 14C-2.73983e-07 21.732 6.26801 28 14 28C21.732 28 28 21.732 28 14ZM6.76802 12.0412C6.83493 11.8799 6.93299 11.7334 7.05659 11.61C7.56326 11.1034 8.41659 11.1034 8.93659 11.61L14.1099 16.7834L19.2966 11.61C19.42 11.4866 19.5666 11.3887 19.7279 11.3219C19.8892 11.2551 20.062 11.2207 20.2366 11.2207C20.4112 11.2207 20.584 11.2551 20.7453 11.3219C20.9066 11.3887 21.0532 11.4866 21.1766 11.61C21.3 11.7335 21.398 11.88 21.4648 12.0413C21.5316 12.2026 21.566 12.3755 21.566 12.55C21.566 12.7246 21.5316 12.8975 21.4648 13.0588C21.398 13.2201 21.3 13.3666 21.1766 13.49L15.0566 19.61C14.9332 19.7336 14.7867 19.8317 14.6254 19.8986C14.4641 19.9655 14.2912 20 14.1166 20C13.942 20 13.7691 19.9655 13.6078 19.8986C13.4465 19.8317 13.2999 19.7336 13.1766 19.61L7.05659 13.49C6.93299 13.3667 6.83493 13.2202 6.76802 13.0589C6.70111 12.8976 6.66667 12.7247 6.66667 12.55C6.66667 12.3754 6.70111 12.2025 6.76802 12.0412Z"
        fill={'#367B98'}
      />
    ) : (
      <path
        d="M1 1.13428L10.8299 10.0809L20.5146 1.00018"
        stroke={!modal ? '#41479B' : 'transparent'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    )}
  </svg>
);

export default Arrow;
