const WorkspaceIcon = (
  <svg width="22" height="19" viewBox="0 0 19 17" stroke="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5714 0.5H17.4286C17.902 0.5 18.2857 0.88375 18.2857 1.35714V7.07143C18.2857 7.54482 17.902 7.92857 17.4286 7.92857H10.5714C10.098 7.92857 9.71429 7.54482 9.71429 7.07143V1.35714C9.71429 0.88375 10.098 0.5 10.5714 0.5ZM7.71429 0.5H0.857143C0.38375 0.5 0 0.88375 0 1.35714V7.07143C0 7.54482 0.38375 7.92857 0.857143 7.92857H7.71429C8.18768 7.92857 8.57143 7.54482 8.57143 7.07143V1.35714C8.57143 0.88375 8.18768 0.5 7.71429 0.5ZM0 9.92857V15.6429C0 16.1163 0.38375 16.5 0.857143 16.5H7.71429C8.18768 16.5 8.57143 16.1163 8.57143 15.6429V9.92857C8.57143 9.45518 8.18768 9.07143 7.71429 9.07143H0.857143C0.38375 9.07143 0 9.45518 0 9.92857ZM10.5714 16.5H17.4286C17.902 16.5 18.2857 16.1163 18.2857 15.6429V9.92857C18.2857 9.45518 17.902 9.07143 17.4286 9.07143H10.5714C10.098 9.07143 9.71429 9.45518 9.71429 9.92857V15.6429C9.71429 16.1163 10.098 16.5 10.5714 16.5Z"
      fill="current-color"
    />
  </svg>
);

export default WorkspaceIcon;
