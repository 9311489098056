import { put, takeLatest, call, select, all } from 'redux-saga/effects';
import {
  GET_PROFILES,
  GET_PROFILE,
  PROCESS_PROFILES_QUERY,
  CLEAR_APPLIED_FILTERS,
  GET_SOME_PROFILES,
  GET_ALKYMERS_STATS,
  GET_ALKYMER_ADVANCE,
  GET_TECHNOLOGY_ADVANCE,
  GET_SOME_PROFILES_FILTERS,
  GET_FAVORITE_PROFILES,
  GET_NEWS,
  GET_TOP_BY_TECHNOLOGY,
  GET_USEFUL_CONTENT,
  GET_MOST_HIRES_COMPANIES,
} from '../actionTypes';
import {
  setProfiles,
  setProfilesFilters,
  setProfilesNextUrl,
  refreshProfiles,
  setProfilesQuery,
  setProfilesLoading,
  setAppliedFilters,
  setAgeQuery,
  setTextQuery,
  setProfile,
  setTotalProfiles,
  setSomeProfiles,
  setAlkymerAdvance,
  setAlkymersStats,
  setSomeProfilesFilters,
  setSomeProfilesNextUrl,
  setFavoriteProfiles,
  setNews,
  setTopByTech,
  setTopLoading,
  setProfilesOrders,
  setUsefulContent,
  setMostHireCompanies,
  setMostHireCompaniesLoading,
  setEngagementQuery,
  setSavedSearches,
  setShowFirstFilterModal,
  setTechnologies,
  setTechnologyTpes,
} from '../actions/profilesActions';
import { handleError } from '../actions/errorsActions';
import {
  getProfile,
  getProfiles,
  getSomeProfiles,
  getAlkymerAdvance,
  getAdvanceTechnology,
  getSomeProfilesFilter,
  getFavoriteProfiles,
  getNews,
  getTopByTechnology,
  getProfilesFilters,
  getAllTechnologies,
  getAllTechnologyTypes,
} from '../../API/services/alkymersService';
import processQueryString from '../../utils/processQueryString';
import { PartnerEnvironment } from '../../constants/enums';
import { getAlkymersStats } from '../../API/services/summariesService';
import {
  getVideos,
  getMostHiresCompanies,
  getSavedSearches,
} from '../../API/services/companyService';

function* getProfilesSaga({ payload: { nextUrl } }) {
  try {
    yield put(setProfilesLoading(true));
    const state = yield select((state) => state.profiles);
    const user = yield select((state) => state.auth.user);
    const filtersState = yield select((state) => state.profiles.availableFilters);
    if (state.refresh) {
      yield put(setProfiles([]));
      if (
        (!state.savedSearches.length && typeof state.savedSearches !== 'object') ||
        !Object.keys(state.savedSearches).length
      ) {
        const searches = yield call(getSavedSearches);
        yield put(setSavedSearches(searches));
      }
    }
    if (nextUrl) {
      nextUrl = processQueryString(nextUrl);
    }
    const qs = {
      ...(nextUrl || {}),
      ...state.query,
      min_age: state.ageQuery.min,
      max_age: state.ageQuery.max,
      min_engagement: state.engagementQuery.minEngagement,
      max_engagement: state.engagementQuery.maxEngagement,
      ...(state.ordering.order && state.ordering.orderBy
        ? { order: state.ordering.order, orderBy: state.ordering.orderBy }
        : {}),
    };
    const onlyAvailable = user.environment === PartnerEnvironment.GENERAL;
    const savedSearches = yield select((state) => state.profiles.savedSearches);
    const technologies = yield select((state) => state.profiles.technologies);
    const technologyTypes = yield select((state) => state.profiles.technologyTypes);
    const showFirstFilterModal = !savedSearches.filters.length && !savedSearches.previousFilters;

    const callRequests = [];
    callRequests.push(call(getProfiles, qs, onlyAvailable));
    if (!Object.keys(filtersState).length) callRequests.push(call(getProfilesFilters));

    const [response, filters] = yield all(callRequests);

    if (filters) yield put(setProfilesFilters(filters));

    if (showFirstFilterModal) {
      if (!technologies.length) {
        const technologies = yield call(getAllTechnologies);
        yield put(setTechnologies(technologies));
      }
      if (!technologyTypes.length) {
        const technologyTypes = yield call(getAllTechnologyTypes);
        yield put(setTechnologyTpes(technologyTypes));
      }
    }
    yield put(setShowFirstFilterModal(showFirstFilterModal));
    yield put(setProfilesOrders(response.orders));
    yield put(setProfiles([...(state.refresh ? [] : state.profiles), ...response.collection]));
    yield put(setTotalProfiles(response.pagination.total));
    yield put(setProfilesNextUrl(response.pagination.nextPage));
    yield put(refreshProfiles(false));
  } catch (err) {
    yield put(handleError(err));
  } finally {
    yield put(setProfilesLoading(false));
  }
}

function* getProfileSaga({ payload: { id } }) {
  try {
    yield put(setProfilesLoading(true));
    const profile = yield call(getProfile, id);
    if (profile) {
      yield put(setProfile(profile));
    }
  } catch (err) {
    yield put(handleError(err));
  } finally {
    yield put(setProfilesLoading(false));
  }
}

function* processProfilesQuerySaga() {
  const state = yield select((state) => state.profiles);
  const search = {};
  state.appliedFilters.forEach((filter) => {
    if (search[filter.type]) {
      search[filter.type] += `,${filter.id}`;
    } else {
      search[filter.type] = filter.id;
    }
  });
  yield put(refreshProfiles(true));
  yield put(setProfilesQuery(search));
}

function* clearAppliedFiltersSaga() {
  yield put(setAppliedFilters([]));
  yield put(setAgeQuery({ min: '', max: '' }));
  yield put(setProfilesQuery({ search: '' }));
  yield put(setEngagementQuery({ minEngagement: '', maxEngagement: '' }));
  yield put(setTextQuery(''));
}

function* getFavoriteProfilesSaga() {
  try {
    yield put(setProfilesLoading(true));
    const profiles = yield call(getFavoriteProfiles);
    yield put(setFavoriteProfiles(profiles));
  } catch (err) {
    yield put(handleError(err));
  } finally {
    yield put(setProfilesLoading(false));
  }
}

function* getSomeProfilesSaga({ payload: { params } }) {
  try {
    const state = yield select((state) => state.profiles.someProfiles);
    const profiles = yield call(getSomeProfiles, params);
    if (!profiles?.collection?.length) {
      yield put(setSomeProfiles([]));
      return;
    }
    if (params?.offset) {
      yield put(setSomeProfiles([...state, ...profiles?.collection]));
    } else {
      yield put(setSomeProfiles(profiles?.collection));
    }

    const offset = profiles?.pagination?.nextPage
      ? profiles?.pagination?.offset + profiles?.pagination?.limit
      : 0;
    yield put(setSomeProfilesNextUrl(offset));
  } catch (err) {
    yield put(handleError(err));
  }
}

function* getSomeProfilesFiltersSaga() {
  try {
    yield put(setProfilesLoading(true));
    const filters = yield call(getSomeProfilesFilter);
    yield put(setSomeProfilesFilters(filters));
  } catch (err) {
    yield put(handleError(err));
  } finally {
    yield put(setProfilesLoading(false));
  }
}

function* getAlkymersStatsSaga({ payload: { params, stopLoading } }) {
  try {
    yield put(setProfilesLoading(true));
    const data = yield call(getAlkymersStats, params);
    if (data) yield put(setAlkymersStats(data));
  } catch (err) {
    yield put(handleError(err));
  } finally {
    if (!stopLoading) {
      yield put(setProfilesLoading(false));
    }
  }
}

function* getAlkymerAdvanceSaga({ payload: { id, jobreadyId } }) {
  try {
    yield put(setProfilesLoading(true));
    const profile = yield call(getAlkymerAdvance, id, jobreadyId);
    yield put(setAlkymerAdvance(profile));
  } catch (err) {
    yield put(handleError(err));
  } finally {
    yield put(setProfilesLoading(false));
  }
}

function* getTechnologyAdvanceSaga({ payload: { id } }) {
  try {
    yield put(setProfilesLoading(true));
    const profile = yield call(getAdvanceTechnology, id);
    yield put(setAlkymerAdvance(profile));
  } catch (err) {
    yield put(handleError(err));
  } finally {
    yield put(setProfilesLoading(false));
  }
}

function* getNewsSaga({ payload: { limitRecomendation } }) {
  try {
    yield put(setProfilesLoading(true));
    const news = yield call(getNews, limitRecomendation);
    yield put(setNews(news));
  } catch (err) {
    yield put(handleError(err));
  } finally {
    yield put(setProfilesLoading(false));
  }
}

function* getTopByTechnologySaga({ payload: { id } }) {
  try {
    yield put(setTopLoading(true));
    const profiles = yield call(getTopByTechnology, id);
    yield put(setTopByTech(profiles?.collection));
  } catch (err) {
    yield put(handleError(err));
  } finally {
    yield put(setTopLoading(false));
  }
}

function* getUsefulContent() {
  try {
    yield put(setProfilesLoading(true));
    const videos = yield call(getVideos);
    yield put(setUsefulContent(videos));
  } catch (err) {
    yield put(handleError(err));
  } finally {
    yield put(setProfilesLoading(false));
  }
}

function* getMostHireCompaniesSaga() {
  try {
    yield put(setMostHireCompaniesLoading(true));
    const companies = yield call(getMostHiresCompanies);
    yield put(setMostHireCompanies(companies));
  } catch (err) {
    yield put(handleError(err));
  } finally {
    yield put(setMostHireCompaniesLoading(false));
  }
}

function* profilesSagas() {
  yield takeLatest(GET_PROFILES, getProfilesSaga);
  yield takeLatest(PROCESS_PROFILES_QUERY, processProfilesQuerySaga);
  yield takeLatest(GET_FAVORITE_PROFILES, getFavoriteProfilesSaga);
  yield takeLatest(CLEAR_APPLIED_FILTERS, clearAppliedFiltersSaga);
  yield takeLatest(GET_TOP_BY_TECHNOLOGY, getTopByTechnologySaga);
  yield takeLatest(GET_USEFUL_CONTENT, getUsefulContent);
  yield takeLatest(GET_MOST_HIRES_COMPANIES, getMostHireCompaniesSaga);
  yield takeLatest(GET_PROFILE, getProfileSaga);
  yield takeLatest(GET_NEWS, getNewsSaga);
  yield takeLatest(GET_SOME_PROFILES, getSomeProfilesSaga);
  yield takeLatest(GET_SOME_PROFILES_FILTERS, getSomeProfilesFiltersSaga);
  yield takeLatest(GET_ALKYMERS_STATS, getAlkymersStatsSaga);
  yield takeLatest(GET_ALKYMER_ADVANCE, getAlkymerAdvanceSaga);
  yield takeLatest(GET_TECHNOLOGY_ADVANCE, getTechnologyAdvanceSaga);
}

export default profilesSagas();
