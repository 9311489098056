import Star from '../assets/tests/Star';

const stars = (starsStatus = [false, false, false], size = '24', boldStars = false) => {
  return (
    <span>
      {starsStatus.map((isSelected, index) => (
        <Star
          selected={isSelected}
          color={boldStars ? '#454545' : '#686868'}
          width={size}
          height={size}
        />
      ))}
    </span>
  );
};

export default stars;
