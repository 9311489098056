const ChartSidebarIcon = (
  <svg width="20" height="18" viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 14H5V7H7V14ZM11 14H9V4H11V14ZM15 14H13V10H15V14ZM17.5 16.1H2.5V2H17.5V16.1ZM17.5 0H2.5C1.4 0 0.5 0.9 0.5 2V16C0.5 17.1 1.4 18 2.5 18H17.5C18.6 18 19.5 17.1 19.5 16V2C19.5 0.9 18.6 0 17.5 0Z"
      fill="current-color"
      stroke="none"
    />
  </svg>
);

export default ChartSidebarIcon;
