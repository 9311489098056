import { useSelector } from 'react-redux';
import { v4 } from 'uuid';

const useRefillRows = () => {
  const { emailsData } = useSelector((state) => state.screenings);

  const refillRows = () => {
    let emailsList;
    if (Object.keys(emailsData).length >= 3) {
      emailsList = Object.keys(emailsData);
    } else if (Object.keys(emailsData).length === 2) {
      emailsList = [...Object.keys(emailsData), v4()];
    } else if (Object.keys(emailsData).length === 1) {
      emailsList = [...Object.keys(emailsData), v4(), v4()];
    } else {
      emailsList = [v4(), v4(), v4()];
    }
    return emailsList;
  };

  return { emailsList: refillRows() };
};

export default useRefillRows;
