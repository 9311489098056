const setInstitutesFilter = (statusFilter) => {
  const institutesFormat = statusFilter
    .filter((filter) => filter.name)
    .map((filter) => ({
      ...filter,
      name: filter?.name,
    }))
    .sort((a, b) => b.count - a.count)
    .filter((item) => item.id !== 'OTHER_INSTITUTES');

  return institutesFormat;
};

export default setInstitutesFilter;
