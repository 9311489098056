import { t } from '../../i18n/i18n';

const setGenre = (sexFilter) => {
  const sexFilterMap = sexFilter.map((filter) => ({
    ...filter,
    name: t(`PROFILES_FILTER_SEX_${filter.name.toUpperCase()}`),
  }));
  return sexFilterMap;
};

export default setGenre;
