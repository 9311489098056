import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLottie } from 'lottie-react';
import { useDispatch, useSelector } from 'react-redux';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import rocketWithSucccess from '../../assets/lottie/rocket-with-success-animation.json';
import { SCREENING_SEARCHES } from '../../constants/routes';
import { t } from '../../i18n/i18n';
import './screeningSent.scss';
import { setEmailsData, setSearchWasAlreadyCreated } from '../../redux/actions/screeningActions';
import useTableBatchAction from '../../hooks/useTableBatchAction';
import { TrackEvent } from '../../utils/filters/segmentUtils';

const CreateSearch = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { searchWasAlreadyCreated } = useTableBatchAction();
  const { emailsData } = useSelector((state) => state.screenings);

  const options = {
    animationData: rocketWithSucccess,
    loop: true,
  };

  const { View } = useLottie(options);
  useEffect(
    () => () => {
      dispatch(setEmailsData({}));
      dispatch(setSearchWasAlreadyCreated(false));
    },
    []
  );
  return (
    <div className="w-100 mx-auto h-100">
      <div>
        <BackgroundTop height="5rem" className="position-relative" />
      </div>
      <div className="px-2">
        <div className="description-container create-screening-container w-100 bg-white position-relative mx-auto p-4 rounded d-flex flex-column">
          <div className="d-flex flex-column align-items-center py-4">
            <div className="lottie-container">{View}</div>
            <div className="my-3 d-flex flex-column align-items-center">
              {searchWasAlreadyCreated ? (
                <>
                  <span className="my-1 font-montserrat font-weight-bold text-xl">
                    {Object.keys(emailsData).length <= 1
                      ? t('INVITATION_WAS_CREATED_SUCCESSFULLY')
                      : t('INVITATIONS_WERE_CREATED_SUCCESSFULLY')}
                  </span>
                  <span className="my-1 font-montserrat font-weight-600 text-muted text-sm">
                    {history?.location?.state?.from
                      ? t('REMINDER_SEND_INVITATIONS')
                      : t('REMEMBER_YOU_CAN_ADD_INVITATIONS')}
                  </span>
                </>
              ) : (
                <>
                  <span className="my-1 font-montserrat font-weight-bold text-xl">
                    {Object.keys(emailsData).length === 1
                      ? t('YOUR_SEARCH_CREATED_SUCCESSFULL_SINGLE')
                      : t('YOUR_SEARCH_CREATED_SUCCESSFULL')}
                  </span>
                  <span className="my-1 font-montserrat font-weight-600 text-muted text-sm">
                    {history?.location?.state?.from
                      ? t('REMINDER_SEND_INVITATIONS')
                      : t('REMINDER_BACK_TO_SEARCHES')}
                  </span>
                </>
              )}
            </div>
            <div>
              <button
                onClick={() => {
                  TrackEvent('search-to-search-dashboard');
                  history.push(SCREENING_SEARCHES);
                }}
                className="mt-4 align-self-end px-3 py-1 rounded-pill font-montserrat text-white text-sm font-weight-bold border-0 outline-none bg-maya-blue"
                type="button"
              >
                {searchWasAlreadyCreated
                  ? t('CREATE_SCREENING_SUCCESS_STEP_GO_TO_DASHBOARD')
                  : t('CREATE_SCREENING_SUCCESS_STEP_GO_TO_SEARCHS')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSearch;
