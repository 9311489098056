const Eye = ({ className }) => (
  <svg
    className={className}
    width="30"
    height="22"
    viewBox="0 0 30 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.8188 10.2359C26.9943 4.70038 21.4026 0.955078 15 0.955078C8.59738 0.955078 3.00414 4.70299 0.181222 10.2365C0.0620768 10.4732 0 10.7347 0 11C0 11.2653 0.0620768 11.5268 0.181222 11.7636C3.00571 17.2991 8.59738 21.0444 15 21.0444C21.4026 21.0444 26.9959 17.2965 29.8188 11.763C29.9379 11.5263 30 11.2647 30 10.9995C30 10.7342 29.9379 10.4727 29.8188 10.2359ZM15 18.5332C13.5166 18.5332 12.0666 18.0914 10.8332 17.2636C9.59984 16.4358 8.63855 15.2593 8.07089 13.8827C7.50323 12.5061 7.35471 10.9914 7.6441 9.53003C7.93349 8.06867 8.64779 6.72633 9.69669 5.67275C10.7456 4.61917 12.082 3.90168 13.5368 3.611C14.9917 3.32032 16.4997 3.4695 17.8701 4.0397C19.2406 4.60989 20.4119 5.57548 21.236 6.81435C22.0601 8.05323 22.5 9.50976 22.5 10.9997C22.5005 11.9892 22.3068 12.969 21.9301 13.8833C21.5533 14.7975 21.0009 15.6282 20.3044 16.3278C19.6078 17.0274 18.7809 17.5823 17.8707 17.9608C16.9605 18.3392 15.9851 18.5337 15 18.5332ZM15 5.97741C14.5537 5.98367 14.1103 6.05037 13.6818 6.17569C14.035 6.65787 14.2045 7.25124 14.1596 7.8482C14.1146 8.44515 13.8582 9.00616 13.4367 9.42948C13.0153 9.85279 12.4568 10.1104 11.8625 10.1555C11.2682 10.2007 10.6774 10.0304 10.1974 9.67562C9.92404 10.6872 9.97338 11.7594 10.3385 12.7413C10.7036 13.7233 11.366 14.5655 12.2326 15.1494C13.0992 15.7334 14.1263 16.0296 15.1693 15.9965C16.2123 15.9634 17.2187 15.6026 18.0468 14.9649C18.875 14.3272 19.4832 13.4447 19.7859 12.4416C20.0885 11.4384 20.0704 10.3652 19.734 9.37301C19.3976 8.38079 18.76 7.51949 17.9108 6.91036C17.0615 6.30124 16.0435 5.97494 15 5.97741Z"
      fill="white"
    />
  </svg>
);

export default Eye;
