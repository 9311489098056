const PencilDraft = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.1241 2.21197L2.71676 10.6202L2.61435 10.7322C2.45147 10.9262 2.33261 11.1531 2.26597 11.3975L1.29788 14.9469L1.28475 15.0169C1.27735 15.0877 1.28736 15.1593 1.3139 15.2254C1.34044 15.2915 1.38272 15.3501 1.43705 15.3962C1.49139 15.4423 1.55615 15.4744 1.62571 15.4897C1.69527 15.5051 1.76752 15.5032 1.8362 15.4843L5.3847 14.5162L5.52913 14.4698C5.76634 14.3823 5.98254 14.2449 6.16198 14.0654L14.5702 5.6572L14.684 5.53465C15.0982 5.05838 15.3115 4.43991 15.2787 3.80954C15.2459 3.17917 14.9697 2.58614 14.5084 2.15538C14.047 1.72462 13.4364 1.48978 12.8052 1.50034C12.1741 1.5109 11.5717 1.76603 11.125 2.21197M3.33473 11.239L10.3713 4.20331L12.578 6.40996L5.54226 13.4457L5.45648 13.521L5.36282 13.584C5.29718 13.6221 5.22686 13.6516 5.15362 13.6715L2.34213 14.4383L3.10977 11.6277L3.14654 11.52C3.19041 11.415 3.25433 11.3196 3.33473 11.239ZM13.9505 2.83082L14.0468 2.93586C14.558 3.54857 14.5256 4.4624 13.9505 5.03835L13.1968 5.79112L10.9902 3.58446L11.743 2.83169L11.848 2.73541C12.1473 2.48626 12.5289 2.35799 12.9179 2.37581C13.3069 2.39362 13.6752 2.55623 13.9505 2.83169M9.72624 2.37216H1.71891C1.60283 2.37216 1.49151 2.41827 1.40944 2.50034C1.32736 2.58242 1.28125 2.69374 1.28125 2.80981C1.28125 2.92588 1.32736 3.0372 1.40944 3.11928C1.49151 3.20136 1.60283 3.24747 1.71891 3.24747H8.85093L9.72624 2.37216ZM7.10031 4.99809H1.71891C1.60283 4.99809 1.49151 5.0442 1.40944 5.12627C1.32736 5.20835 1.28125 5.31967 1.28125 5.43574C1.28125 5.55182 1.32736 5.66313 1.40944 5.74521C1.49151 5.82729 1.60283 5.8734 1.71891 5.8734H6.225L7.10031 4.99809ZM3.59907 8.49933L4.47438 7.62402H1.71891C1.60283 7.62402 1.49151 7.67013 1.40944 7.7522C1.32736 7.83428 1.28125 7.9456 1.28125 8.06167C1.28125 8.17775 1.32736 8.28907 1.40944 8.37114C1.49151 8.45322 1.60283 8.49933 1.71891 8.49933H3.59907Z"
        fill="#686868"
      />
      <path
        d="M11.1241 2.21197L2.71676 10.6202L2.61435 10.7322C2.45147 10.9262 2.33261 11.1531 2.26597 11.3975L1.29788 14.9469L1.28475 15.0169C1.27735 15.0877 1.28736 15.1593 1.3139 15.2254C1.34044 15.2915 1.38272 15.3501 1.43705 15.3962C1.49139 15.4423 1.55615 15.4744 1.62571 15.4897C1.69527 15.5051 1.76752 15.5032 1.8362 15.4843L5.3847 14.5162L5.52913 14.4698C5.76634 14.3823 5.98254 14.2449 6.16198 14.0654L14.5702 5.6572L14.684 5.53465C15.0982 5.05838 15.3115 4.43991 15.2787 3.80954C15.2459 3.17917 14.9697 2.58614 14.5084 2.15538C14.047 1.72462 13.4364 1.48978 12.8052 1.50034C12.1741 1.5109 11.5717 1.76603 11.125 2.21197M13.9505 2.83082L14.0468 2.93586C14.558 3.54857 14.5256 4.4624 13.9505 5.03835L13.1968 5.79112L10.9902 3.58446L11.743 2.83169L11.848 2.73541C12.1473 2.48626 12.5289 2.35799 12.9179 2.37581C13.3069 2.39362 13.6752 2.55623 13.9505 2.83169M3.33473 11.239L10.3713 4.20331L12.578 6.40996L5.54226 13.4457L5.45648 13.521L5.36282 13.584C5.29718 13.6221 5.22686 13.6516 5.15362 13.6715L2.34213 14.4383L3.10977 11.6277L3.14654 11.52C3.19041 11.415 3.25433 11.3196 3.33473 11.239ZM9.72624 2.37216H1.71891C1.60283 2.37216 1.49151 2.41827 1.40944 2.50034C1.32736 2.58242 1.28125 2.69374 1.28125 2.80981C1.28125 2.92588 1.32736 3.0372 1.40944 3.11928C1.49151 3.20136 1.60283 3.24747 1.71891 3.24747H8.85093L9.72624 2.37216ZM7.10031 4.99809H1.71891C1.60283 4.99809 1.49151 5.0442 1.40944 5.12627C1.32736 5.20835 1.28125 5.31967 1.28125 5.43574C1.28125 5.55182 1.32736 5.66313 1.40944 5.74521C1.49151 5.82729 1.60283 5.8734 1.71891 5.8734H6.225L7.10031 4.99809ZM3.59907 8.49933L4.47438 7.62402H1.71891C1.60283 7.62402 1.49151 7.67013 1.40944 7.7522C1.32736 7.83428 1.28125 7.9456 1.28125 8.06167C1.28125 8.17775 1.32736 8.28907 1.40944 8.37114C1.49151 8.45322 1.60283 8.49933 1.71891 8.49933H3.59907Z"
        stroke="#686868"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default PencilDraft;
