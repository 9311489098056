import React from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import { t } from '../../../../i18n/i18n';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatNumberGermanLocale } from '../../../../utils/numbersUtils';
const Feature = (props) => {
  let { plan, planToFeature } = props;
  let { feature } = planToFeature;
  return (
    <div className="w-100">
      <div
        className="d-flex flex-column align-items-center text-center row m-0 py-4 px-4 w-100 font-montserrat text-xs font-weight-700 "
        style={{ borderTop: `1px solid #${plan.color}` }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <span
            className={`font-montserrat text-sm font-weight-700 ${
              feature.hidden ? 'text-white user-select-none' : ''
            }`}
          >
            {feature.whitelabelDescription && feature.whitelabelDescription?.length && (
              <div className="mx-1">
                {feature.hidden ? (
                  <AiFillInfoCircle color={feature.hidden ? 'white' : 'lightgray'} size={'1em'} />
                ) : (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip align={'center'} placement="top">
                        <span className="font-montserrat text-xs font-weight-700 text-center w-100">
                          {t(feature.whitelabelDescription)}
                        </span>
                      </Tooltip>
                    }
                  >
                    <AiFillInfoCircle color={feature.hidden ? 'white' : 'lightgray'} size={'1em'} />
                  </OverlayTrigger>
                )}
              </div>
            )}
            <span
              className={`font-montserrat text-sm font-weight-700 ${
                feature.hidden ? 'text-white user-select-none' : 'text-secondary'
              }`}
            >
              {t(feature.whitelabelName)}
            </span>

            <div className="font-montserrat text-xs font-weight-700">
              {feature.hasAmount
                ? formatNumberGermanLocale(planToFeature.amount) ?? t('NEGOTIABLE_PRICE_MESSAGE')
                : null}
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Feature;
