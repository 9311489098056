import { useLocation } from 'react-router-dom';
import bookmark from '../../../assets/additionalQuestions/bookmark.svg';
import { AnswerTypeEnum } from '../../../constants/enums';
import useCreateScreening from '../../../hooks/useCreateScreening';
import { t } from '../../../i18n/i18n';
import { TrackEvent } from '../../../utils/filters/segmentUtils';
import '../AddQuestion.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const AnswerTypeButton = ({ button, index }) => {
  const { pathname } = useLocation();
  const track = pathname === '/createscreening';
  const { currentTypeQuestion, handleCurrentTypeQuestion } = useCreateScreening();
  return (
    <button
      type="button"
      key={button.id}
      onClick={() => {
        if (track) {
          TrackEvent(button.track);
        }
        handleCurrentTypeQuestion(button.id);
      }}
      className={`w-100 gap-1 border-none text-buttons text-xl font-weight-500 d-flex justify-content-start align-items-center position-relative ${
        currentTypeQuestion === button.id ||
        (currentTypeQuestion === AnswerTypeEnum.TEXT_AI && button.id === AnswerTypeEnum.TEXT)
          ? 'bg-blue-principal color-white svg-selected'
          : 'bg-blue-light color-grey-1'
      }
      ${index === 0 && 'mt-0'}
      `}
      data-testid={`add-questions-current-type-${index}-btn`}
    >
      {button?.img}
      {button.name}
      {button?.img2 && (
        <div className="additional-image">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="font-montserrat font-size-tiny">
                {t('CORRECTION_POWERED_BY_AI')}
              </Tooltip>
            }
          >
            <div>{button?.img2}</div>
          </OverlayTrigger>
        </div>
      )}
    </button>
  );
};

export default AnswerTypeButton;
