import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeEnvironment as changeEnvironmentService } from '../API/services/authService';
import history from '../history';
import { setUser } from '../redux/actions/authActions';

const useEnvironment = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [environment, setEnvironment] = useState(user?.environment ?? '');
  const [error, setError] = useState(false);
  const [environmentLoading, setEnvironmentLoading] = useState(false);

  const environments = {
    GENERAL: 'GENERAL',
    MY_COMPANY: 'MY_COMPANY',
  };

  useEffect(() => {
    setEnvironment(user?.environment);
  }, [user?.environment]);

  const changeEnvironment = async (environment) => {
    try {
      await changeEnvironmentService(environment);
      dispatch(setUser({ ...user, ...{ environment } }));
    } catch (err) {
      setError(true);
    }
  };

  const toggleEnvironment = async () => {
    setEnvironmentLoading(true);
    try {
      if (user) {
        const newEnvironment =
          user?.environment === environments.GENERAL
            ? environments.MY_COMPANY
            : environments.GENERAL;
        await changeEnvironmentService(newEnvironment);
        setEnvironmentLoading(false);
        dispatch(setUser({ ...user, ...{ environment: newEnvironment } }));
        history.push({ search: '' });
      }
    } catch (err) {
      setError(true);
    }
  };

  return {
    error,
    environments,
    toggleEnvironment,
    changeEnvironment,
    environment,
    environmentLoading,
  };
};

export default useEnvironment;
