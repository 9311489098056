import { useEffect } from 'react';
import ArrowSmall from '../../assets/screening/ArrowSmall';
import './ScrollToTop.scss';

const ScrollToTop = () => {
  const listener = () => {
    const button = document.querySelector('#scroll-to-top-button');
    if (button) {
      if (window.scrollY > 100) {
        button.disabled = false;
      } else {
        button.disabled = true;
      }
    }
  };

  const initialize = () => {
    window.addEventListener('scroll', listener);
  };

  const cleanup = () => {
    window.removeEventListener('scroll', listener);
  };

  const backToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    document.querySelector('#scroll-to-top-button').disabled = true;
    initialize();
    return () => {
      cleanup();
    };
  }, []);

  return (
    <button
      onClick={() => backToTop()}
      id="scroll-to-top-button"
      data-testid="scroll-to-top-button"
      type="button"
      className="scroll-to-top-button"
    >
      <ArrowSmall classNames="rotate-180" color="#FFF" />
    </button>
  );
};

export default ScrollToTop;
