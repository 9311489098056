import React, { useEffect, useState } from 'react';
import CheckIcon from '../../assets/login/CheckIcon';
import SearchIcon from '../../assets/screening/SearchIcon';
import { t } from '../../i18n/i18n';
import Spinner from '../spinner/Spinner';
import EmptyComponent from '../EmptyComponent/EmptyComponent';

const ResetTestgroupStep2 = ({
  fetchCompanySearches,
  selectedCompany,
  selectedSearch,
  companySearches,
  loading,
  handleSelectSearch,
  handleNextStep,
  handlePreviousStep,
}) => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    fetchCompanySearches({ companyId: selectedCompany, search });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetchCompanySearches({ companyId: selectedCompany, search });
  };

  return (
    <>
      <div className="d-flex flex-column">
        <span className="font-montserrat font-weight-bold text-base mt-2 mb-3">
          {t('RESET_TESTGROUP_STEP_2_TITLE')}
        </span>
        <form
          onSubmit={handleSubmit}
          className={`my-1 my-sm-0 rounded transition-2-ms p-2 d-flex align-items-center ml-auto
        ${
          search?.length ? 'tests-search-input-full bg-white' : 'tests-search-input-empty bg-gray'
        }`}
        >
          <SearchIcon color={search?.length ? '#1aacf0' : '#424242'} />
          <input
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            className="font-montserrat text-xs bg-transparent border-0 outline-none ml-2"
            placeholder={t('RESET_TESTGROUP_STEP_2_SEARCH_PLACEHOLDER')}
            type="text"
          />
        </form>
        <div className="w-100 d-flex flex-column mt-2 reset-testgroup-lists-container overflow-auto">
          {loading ? (
            <Spinner show />
          ) : (
            companySearches?.collection?.map((search) => (
              <button
                type="button"
                key={search?.id}
                onClick={() => handleSelectSearch(search)}
                className={`bg-transparent tech-button-border my-1 px-2 py-3 d-flex justify-content-between align-items-center ${
                  selectedSearch?.id === search.id ? 'selected-border' : 'unselected-border'
                }`}
              >
                <span className="font-montserrat font-weight-bold text-muted text-xs">
                  {search.name}
                </span>
                {selectedSearch?.id === search.id && (
                  <CheckIcon className="mr-1" width="19" height="19" />
                )}
              </button>
            ))
          )}
          {!loading && !companySearches?.collection?.length && (
            <EmptyComponent
              title={t('RESET_TESTGROUP_STEP_2_EMPTY_STATE_TITLE')}
              className="my-3 align-items-center py-3"
            />
          )}
        </div>
      </div>
      <div className="d-flex flex-grow-1 justify-content-between">
        <button
          onClick={handlePreviousStep}
          className="align-self-end mt-4 px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none bg-maya-blue"
          type="button"
        >
          {t('RESET_TESTGROUP_BACK_BUTTON')}
        </button>
        <button
          onClick={handleNextStep}
          disabled={!selectedSearch}
          className={`align-self-end mt-4 px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none ${
            !selectedSearch ? 'bg-disabled' : 'bg-maya-blue'
          }`}
          type="button"
        >
          {t('RESET_TESTGROUP_NEXT_BUTTON')}
        </button>
      </div>
    </>
  );
};

export default ResetTestgroupStep2;
