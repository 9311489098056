import { useState, useEffect } from 'react';

const GenericInput = ({
  placeholder,
  id,
  accept,
  defaultValue,
  type,
  title,
  disabled,
  className = '',
  minLength = 1,
  maxLength = 50,
  onChange,
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className={`align-items-center mt-2 my-2 ${className}`}>
      <input
        id={id}
        minLength={minLength}
        maxLength={maxLength}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        type={type ?? 'text'}
        title={title}
        accept={accept}
        className="p-2 w-100 font-montserrat rounded text-sm screening-name-input evaluation-name-input mr-2"
        onChange={(e) => {
          handleChange(e);
        }}
      />
    </div>
  );
};

export default GenericInput;
