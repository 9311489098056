import { SET_BUTTON_LOADING } from '../actionTypes';

const initialState = {
  isLoading: false,
};

function buttonReducer(state = initialState, action) {
  switch (action.type) {
    case SET_BUTTON_LOADING: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}

export default buttonReducer;
