import './ChartLegendItem.scss';

export default function ChartLegendItem({ value, text, bgClassName }) {
  return (
    <div className="d-flex align-items-center my-3">
      <div className="chart-legend-item-box" style={{ backgroundColor: bgClassName }} />
      <p className="my-0 ml-2 font-montserrat item-text text-sm font-weight-500">
        <span className="font-montserrat mr-1 font-weight-700">{value}</span>
        {text}
      </p>
    </div>
  );
}
