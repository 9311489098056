const PaperPlane = () => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="#828282" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0156 0.859375L1.32812 8.16016C0.835938 8.43359 0.890625 9.14453 1.38281 9.33594L4.30859 10.5664L12.1562 3.64844C12.293 3.51172 12.5117 3.70312 12.375 3.86719L5.8125 11.8789V14.0938C5.8125 14.75 6.57812 14.9961 6.96094 14.5312L8.71094 12.3984L12.1016 13.8477C12.4844 14.0117 12.9492 13.7656 13.0039 13.3281L14.9727 1.51562C15.082 0.96875 14.4805 0.585938 14.0156 0.859375Z"
      fill="#828282"
    />
  </svg>
);

export default PaperPlane;
