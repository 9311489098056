const TransferOwnership = ({ width = '24', height = '24' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 100 100"
    x="0px"
    y="0px"
    width={width}
    height={height}
  >
    <defs>
      <style />
    </defs>
    <path
      className="cls-1"
      d="m37.04,46c-2.21,0-4,1.79-4,4v19.34l-7.17-7.17c-1.56-1.56-4.1-1.56-5.66,0-1.56,1.56-1.56,4.09,0,5.66l8.88,8.88c2.12,2.12,4.95,3.29,7.95,3.29s5.83-1.17,7.95-3.29l8.88-8.88c1.56-1.56,1.56-4.09,0-5.66-1.56-1.56-4.1-1.56-5.66,0l-7.17,7.17v-19.34c0-2.21-1.79-4-4-4Z"
      fill="#828282"
    />
    <path
      className="cls-1"
      d="m51.87,37.83l7.17-7.17v19.34c0,2.21,1.79,4,4,4s4-1.79,4-4v-19.34l7.17,7.17c.78.78,1.8,1.17,2.83,1.17s2.05-.39,2.83-1.17c1.56-1.56,1.56-4.09,0-5.66l-8.88-8.88c-2.12-2.12-4.95-3.29-7.95-3.29s-5.83,1.17-7.95,3.29l-8.88,8.88c-1.56,1.56-1.56,4.09,0,5.66,1.56,1.56,4.1,1.56,5.66,0Z"
      fill="#828282"
    />
    <path
      className="cls-1"
      d="m22,43c2.21,0,4-1.79,4-4,0-3.16.02-3.75.29-5.76.32-2.29.93-3.76,2.06-4.89,1.11-1.11,2.61-1.75,4.88-2.05,2.01-.27,2.6-.29,5.77-.29,2.21,0,4-1.79,4-4s-1.79-4-4-4c-3.24,0-4.28.02-6.84.37-2.56.34-6.32,1.16-9.48,4.33-3.14,3.15-3.97,6.9-4.32,9.45-.35,2.58-.37,3.62-.37,6.85,0,2.21,1.79,4,4,4Z"
      fill="#828282"
    />
    <path
      className="cls-1"
      d="m81.63,67.85c.35-2.57.37-3.62.37-6.85,0-2.21-1.79-4-4-4s-4,1.79-4,4c0,3.17-.02,3.76-.29,5.76-.32,2.29-.93,3.76-2.06,4.89-1.11,1.11-2.61,1.75-4.88,2.05-2.02.27-2.61.29-5.77.29-2.21,0-4,1.79-4,4s1.79,4,4,4c3.23,0,4.28-.02,6.84-.37,2.56-.34,6.32-1.16,9.48-4.33,3.14-3.15,3.97-6.9,4.32-9.45Z"
      fill="#828282"
    />
  </svg>
);
export default TransferOwnership;
