import { SET_COHORTS, SET_TEAMS } from '../actionTypes';

const initialState = {
  teams: [],
  cohorts: [],
};

function teamsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TEAMS: {
      return { ...state, ...action.payload };
    }
    case SET_COHORTS: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}

export default teamsReducer;
