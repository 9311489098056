import Spinner from './Spinner';

const SpinnerFullScreen = ({ show }) =>
  !show ? null : (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <Spinner show />
    </div>
  );

export default SpinnerFullScreen;
