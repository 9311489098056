const LeaveIcon = ({ width = '19', height = '16' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4964 2.63929L17.8821 7.025C18.1393 7.28214 18.2857 7.63571 18.2857 8C18.2857 8.36429 18.1393 8.71786 17.8821 8.975L13.4964 13.3607C13.2679 13.5893 12.9607 13.7143 12.6393 13.7143C11.9714 13.7143 11.4286 13.1714 11.4286 12.5036V10.2857H6.85714C6.225 10.2857 5.71429 9.775 5.71429 9.14286V6.85714C5.71429 6.225 6.225 5.71429 6.85714 5.71429H11.4286V3.49643C11.4286 2.82857 11.9714 2.28571 12.6393 2.28571C12.9607 2.28571 13.2679 2.41429 13.4964 2.63929ZM5.71429 2.28571H3.42857C2.79643 2.28571 2.28571 2.79643 2.28571 3.42857V12.5714C2.28571 13.2036 2.79643 13.7143 3.42857 13.7143H5.71429C6.34643 13.7143 6.85714 14.225 6.85714 14.8571C6.85714 15.4893 6.34643 16 5.71429 16H3.42857C1.53571 16 0 14.4643 0 12.5714V3.42857C0 1.53571 1.53571 0 3.42857 0H5.71429C6.34643 0 6.85714 0.510714 6.85714 1.14286C6.85714 1.775 6.34643 2.28571 5.71429 2.28571Z"
      fill="#757575"
    />
  </svg>
);

export default LeaveIcon;
