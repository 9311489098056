import { notification } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Lottie from 'lottie-react';
import { t } from '../../i18n/i18n';
import FormInputSearch from '../FormInputSearch/FormInputSearch';
import {
  getWorkspaceMembers,
  transferWorkspaceProperty,
  deleteWorkspaceMember,
  acceptRequestAccessWorkspace,
} from '../../API/services/companyService';
import useOnScreen from '../../hooks/useOnScreen';
import Spinner from '../spinner/Spinner';
import MemberRow from '../MemberRow/MemberRow';
import EmptyStateIcon from '../../assets/screening/EmptyState.json';
import ModalCustom from '../ModalCustom/ModalCustom';
import './MembersList.scss';
import { PartnerWorkspaceRole } from '../../constants/enums';
import { getWorkspaceOwners } from '../../API/services/screeningService';
import { setWorkspaceOwners } from '../../redux/actions/authActions';
import { eliminateAlkemyMembers } from '../../utils/workspaceUtils';

const MembersList = ({
  className = '',
  rerender,
  members,
  setMembers,
  pagination,
  setPagination,
  setLoadingPage,
}) => {
  const [searchText, setSearchText] = useState('');
  const [selectedMember, setSelectedMember] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [ownerModification, setOwnerModification] = useState(false);
  const { user, workspaceOwners, currentWorkspace, currentRole } = useSelector(
    (state) => state.auth
  );
  const fetchNextUrlRef = useRef(null);
  const dispatch = useDispatch();
  const fetchNextUrl = useOnScreen(
    fetchNextUrlRef,
    '20px',
    true,
    !loading && !loadingNextPage && Boolean(members.length) && Boolean(pagination?.nextPage?.length)
  );

  useEffect(() => {
    fetchMembers();
  }, [rerender]);

  useEffect(() => {
    if (fetchNextUrl) {
      fetchNextPage();
    }
  }, [fetchNextUrl]);

  useEffect(async () => {
    if (!workspaceOwners?.length) {
      const owners = await getWorkspaceOwners();
      dispatch(setWorkspaceOwners(owners));
    }
  }, []);

  useEffect(() => {
    if (
      currentRole === PartnerWorkspaceRole.OWNER ||
      currentRole === PartnerWorkspaceRole.ADMINISTRATOR
    ) {
      setOwnerModification(true);
    }
  }, [currentRole]);

  const fetchMembers = async (search) => {
    setLoading(true);
    const response = eliminateAlkemyMembers(
      await getWorkspaceMembers({ limit: 150, offset: 0, search }),
      currentWorkspace
    );

    setMembers(response.collection);
    setPagination(response.pagination);
    setLoading(false);
    setLoadingPage(false);
  };

  const fetchNextPage = async () => {
    setLoadingNextPage(true);
    const response = await getWorkspaceMembers({
      limit: pagination?.limit,
      offset: pagination?.offset + pagination?.limit,
      search: searchText,
    });
    if (response) {
      setMembers([...members, ...response.collection]);
      setPagination(response.pagination);
    }
    setLoadingNextPage(false);
  };

  const handleTransferWorkspaceProperty = async (memberId) => {
    const currentOwnerId = members.find((member) => member?.role === PartnerWorkspaceRole.OWNER).id;
    setLoading(true);
    const response = await transferWorkspaceProperty({
      newOwnerId: memberId,
      currentOwnerId,
    });
    if (response) {
      setMembers(response.collection);
      setPagination(response.pagination);
    }
    setLoading(false);
  };

  const handleAcceptRequestAccessWorkspace = async (member) => {
    setLoading(true);
    try {
      const response = await acceptRequestAccessWorkspace({
        role: member?.role,
        email: member?.email,
        workspaceId: currentWorkspace,
      });
      if (response) {
        fetchMembers();
      }
      notification.open({
        message: t('MEMBER_APPROVED'),
        type: 'success',
      });
    } catch (error) {
      notification.open({
        message: t('ERROR_MEMBER_APPROVED'),
        type: 'error',
      });
    }
    setLoading(false);
  };

  const handleDeleteMember = async () => {
    try {
      await deleteWorkspaceMember(
        selectedMember?.email,
        selectedMember?.partner?.id,
        Number(currentWorkspace)
      );
      notification.open({
        message: t('MEMBER_DELETED'),
        type: 'success',
      });
      setMembers(
        members.filter((member) => {
          if (member?.partner?.id) {
            return member?.partner?.id !== selectedMember?.partner?.id;
          }
          return member?.email !== selectedMember?.email;
        })
      );
    } catch (error) {
      notification.open({
        message: t('ERROR_MEMBER_DELETED', { error: error.message }),
        type: 'error',
      });
    }
  };

  const closeDeletePostConfirmation = async () => {
    await handleDeleteMember();
    setShowConfirmationModal(false);
  };

  const handleOpenModal = (member) => {
    setShowConfirmationModal(true);
    setSelectedMember(member);
  };

  return (
    <div className={`mh-100 d-flex flex-column ${className}`}>
      <div className="mb-3">
        <span className="font-montserrat font-weight-600">
          {pagination?.totalWithoutFilters ?? 0}
        </span>
        <span className="font-montserrat font-weight-500 ml-1">{t('MEMBERS_TITLE')}</span>
      </div>
      <FormInputSearch
        searchText={searchText}
        setSearchText={setSearchText}
        placeholder={t('MEMBERS_SEARCH_PLACEHOLDER')}
        onSubmit={fetchMembers}
        className="w-100 mb-3"
      />
      <div className="d-flex flex-column flex-grow-1 overflow-auto pb-4 pr-2">
        {!loading &&
          (members.length ? (
            <div className="d-flex flex-column w-100">
              <ModalCustom
                datatestid="modal-delete-member"
                datatestidbuttonok="button-ok"
                title={t('DELETE_MEMBER_QUESTION')}
                htmlSubtitle
                show={showConfirmationModal}
                setShow={setShowConfirmationModal}
                onOkText={t('DELETE')}
                onCancelText={t('CANCEL')}
                onCancel={() => setShowConfirmationModal(false)}
                onOk={closeDeletePostConfirmation}
                classModal="modal-delete-member"
                okBtnClassname="button-cancel-right-modal"
                noOkBtnClassname="button-cancel-left-modal"
                noOkTxtClassname="text-button-cancel-left-modal"
                input=""
              />
              {members.map((member) => (
                <MemberRow
                  member={member}
                  key={member.id}
                  handleOpenModal={handleOpenModal}
                  ownerModification={ownerModification}
                  isOwner={
                    members.length &&
                    members.find((member) => member?.role === PartnerWorkspaceRole.OWNER)?.partner
                      ?.id === user?.id
                  }
                  transferProperty={handleTransferWorkspaceProperty}
                  approveRequest={handleAcceptRequestAccessWorkspace}
                />
              ))}
            </div>
          ) : (
            <div
              className="d-flex flex-column align-items-center justify-content-center mt-5"
              data-testid="members-list-empty-state-container"
            >
              <div className="members-empty-state-icon">
                <Lottie animationData={EmptyStateIcon} loop />
              </div>
              <span className="font-montserrat font-weight-600 mt-4">
                {t('MEMBERS_EMPTY_STATE_TITLE')}
              </span>
              <span className="font-montserrat mt-2">{t('MEMBERS_EMPTY_STATE_SUBTITLE')}</span>
            </div>
          ))}
        {(loading || loadingNextPage) && <Spinner show />}
        {!loading &&
          !loadingNextPage &&
          Boolean(members.length) &&
          Boolean(pagination?.nextPage?.length) && <div ref={fetchNextUrlRef} />}
      </div>
    </div>
  );
};

export default MembersList;
