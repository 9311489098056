const UserCardOutlinedIcon = ({ className }) => (
  <svg
    className={className}
    width="21"
    height="16"
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.2857 1.71429C18.6 1.71429 18.8571 1.97143 18.8571 2.28571V13.7143C18.8571 14.0286 18.6 14.2857 18.2857 14.2857H2.28571C1.97143 14.2857 1.71429 14.0286 1.71429 13.7143V2.28571C1.71429 1.97143 1.97143 1.71429 2.28571 1.71429H18.2857ZM2.28571 0C1.025 0 0 1.025 0 2.28571V13.7143C0 14.975 1.025 16 2.28571 16H18.2857C19.5464 16 20.5714 14.975 20.5714 13.7143V2.28571C20.5714 1.025 19.5464 0 18.2857 0H2.28571ZM7.42857 8C8.03478 8 8.61616 7.75918 9.04482 7.33053C9.47347 6.90188 9.71428 6.3205 9.71428 5.71429C9.71428 5.10808 9.47347 4.5267 9.04482 4.09804C8.61616 3.66939 8.03478 3.42857 7.42857 3.42857C6.82236 3.42857 6.24098 3.66939 5.81233 4.09804C5.38367 4.5267 5.14286 5.10808 5.14286 5.71429C5.14286 6.3205 5.38367 6.90188 5.81233 7.33053C6.24098 7.75918 6.82236 8 7.42857 8ZM6.28571 9.14286C4.70714 9.14286 3.42857 10.4214 3.42857 12C3.42857 12.3143 3.68571 12.5714 4 12.5714H10.8571C11.1714 12.5714 11.4286 12.3143 11.4286 12C11.4286 10.4214 10.15 9.14286 8.57143 9.14286H6.28571ZM13.4286 4C12.9536 4 12.5714 4.38214 12.5714 4.85714C12.5714 5.33214 12.9536 5.71429 13.4286 5.71429H16.2857C16.7607 5.71429 17.1429 5.33214 17.1429 4.85714C17.1429 4.38214 16.7607 4 16.2857 4H13.4286ZM13.4286 7.42857C12.9536 7.42857 12.5714 7.81071 12.5714 8.28572C12.5714 8.76071 12.9536 9.14286 13.4286 9.14286H16.2857C16.7607 9.14286 17.1429 8.76071 17.1429 8.28572C17.1429 7.81071 16.7607 7.42857 16.2857 7.42857H13.4286Z" />
  </svg>
);

export default UserCardOutlinedIcon;
