import moment from 'moment';
import { t } from '../i18n/i18n';

const padTo2Digits = (num) => num.toString().padStart(2, '0');

export const formatSecondsToSSMM = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
};

export const formatAudioTimeSeconds = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  if (minutes && seconds) {
    return `${minutes}m ${seconds}s`;
  }

  if (minutes && !seconds) {
    return `${minutes}m`;
  }

  return `${t('TESTGROUP_ABM_QUESTION_WITH_AUDIO_TIME_IN_SECONDS').replace('{seconds}', seconds)}`;
};

export const formatSecondsToHHSSMM = (totalSeconds) =>
  moment.utc(totalSeconds * 1000).format('HH:mm:ss');
