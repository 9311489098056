const InvertIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.2381 3.53613C9.35567 2.7099 8.21055 2.25699 6.99571 2.25806C4.8094 2.25998 2.92194 3.75906 2.4008 5.83851C2.36287 5.98988 2.228 6.09677 2.07194 6.09677H0.45452C0.242883 6.09677 0.0821089 5.90464 0.121258 5.69665C0.73198 2.4535 3.5794 0 7 0C8.87555 0 10.5788 0.73771 11.8355 1.93869L12.8436 0.930605C13.2703 0.503859 14 0.806101 14 1.40963V5.19355C14 5.56768 13.6967 5.87097 13.3226 5.87097H9.53866C8.93513 5.87097 8.63289 5.1413 9.05964 4.71453L10.2381 3.53613ZM0.677419 8.12903H4.46134C5.06487 8.12903 5.36711 8.8587 4.94036 9.28547L3.76194 10.4639C4.64433 11.2902 5.78954 11.7431 7.0044 11.742C9.18959 11.74 11.0778 10.2419 11.5992 8.16155C11.6371 8.01017 11.772 7.90328 11.9281 7.90328H13.5455C13.7571 7.90328 13.9179 8.09542 13.8788 8.30341C13.268 11.5465 10.4206 14 7 14C5.12445 14 3.42122 13.2623 2.1645 12.0613L1.15641 13.0694C0.729665 13.4961 0 13.1939 0 12.5904V8.80645C0 8.43232 0.303286 8.12903 0.677419 8.12903Z"
      fill="#828282"
    />
  </svg>
);

export default InvertIcon;
