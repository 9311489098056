const CalendarIcon = () => (
  <svg width="60" height="60" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.0511 0.0661716C10.885 0.118055 10.6049 0.284081 10.4388 0.439728C9.93038 0.927427 9.85775 1.23873 9.85775 3.17914V4.94316H7.23247C5.79013 4.94316 4.32703 4.99504 3.99498 5.0573C2.19983 5.38935 0.747113 6.68643 0.207531 8.45044C0.0103766 9.06266 0 9.41546 0 13.1303C0 16.9489 0.0103766 17.1564 0.207531 17.4781C0.518829 17.9761 0.985774 18.2252 1.66025 18.2252C2.33473 18.2252 2.80167 17.9761 3.11297 17.4781C3.279 17.1979 3.3205 16.9489 3.3205 16.0668V15.0084H24.7481H46.1758V16.6894C46.1758 17.6233 46.2276 18.5468 46.2795 18.7648C46.4144 19.2421 47.0785 19.8543 47.5662 19.9477C48.3548 20.093 49.2057 19.6675 49.4859 18.9723C49.5793 18.7544 49.6 17.3224 49.5793 13.701C49.5481 8.76174 49.5481 8.73061 49.3095 8.12877C48.7803 6.82132 47.7011 5.78366 46.3314 5.24408C45.7399 5.0158 45.5532 5.00542 42.7307 4.96391L39.7423 4.92241V3.25178C39.7423 2.32826 39.6904 1.40475 39.6385 1.18684C39.5036 0.70952 38.8499 0.118055 38.3622 0.0246658C37.9056 -0.0583477 37.2727 0.0973015 36.9198 0.37747C36.3906 0.782156 36.318 1.13496 36.318 3.11688V4.94316H31.3891H26.4603V3.03387C26.4603 1.23873 26.4499 1.11421 26.2216 0.792532C25.5367 -0.234749 24.0633 -0.234749 23.3784 0.792532C23.1501 1.11421 23.1398 1.23873 23.1398 3.03387V4.94316H18.2109H13.282V3.12726C13.282 1.51889 13.2613 1.25948 13.0745 0.906675C12.7321 0.221821 11.7982 -0.162113 11.0511 0.0661716ZM9.85775 9.9758V11.5842H6.58912H3.3205V10.8267C3.33088 9.49848 3.56954 8.89664 4.26477 8.55421C4.57607 8.38818 4.94963 8.36743 7.24285 8.36743H9.85775V9.9758ZM36.318 9.9758V11.5842H24.8H13.282V9.9758V8.36743H24.8H36.318V9.9758ZM45.3456 8.56459C45.5428 8.66835 45.8126 8.90701 45.9475 9.09379C46.1446 9.39471 46.1758 9.57111 46.1758 10.505V11.5842H42.959H39.7423V9.9758V8.36743H42.3675C44.7127 8.36743 45.024 8.38818 45.3456 8.56459Z"
      fill="#0E9CE2"
    />
    <path
      d="M33.5682 18.2458C27.6639 18.5986 22.3718 22.7181 20.5041 28.4148C19.4975 31.4967 19.5702 35.3049 20.7012 38.3037C23.5236 45.806 31.7108 49.7387 39.3272 47.2587C40.6242 46.8332 42.2741 46.0031 43.3948 45.2041C44.4739 44.4362 46.238 42.6411 46.9643 41.5723C48.085 39.9328 48.9462 37.8679 49.3509 35.8133C49.5896 34.6408 49.6103 31.7249 49.4028 30.5731C48.4689 25.416 45.0031 21.1408 40.1988 19.2316C38.2065 18.4326 35.9652 18.1005 33.5682 18.2458ZM36.9198 21.8153C41.0497 22.6662 44.3702 25.6443 45.6361 29.6393C46.0719 31.0193 46.2172 32.1296 46.1446 33.6239C46.0719 35.2634 45.7191 36.6019 44.9928 38.0962C43.7995 40.5139 42.1185 42.1949 39.6903 43.3986C37.0339 44.7164 34.0559 44.9343 31.2334 44.0419C27.2281 42.7656 24.3019 39.5074 23.3991 35.2945C23.1501 34.1531 23.1501 32.0466 23.3887 30.8844C24.3019 26.5782 27.4252 23.2058 31.6174 22.0125C33.3087 21.5248 35.1973 21.4625 36.9198 21.8153Z"
      fill="#0E9CE2"
    />
    <path
      d="M33.9836 24.9077C33.6204 25.0634 33.1328 25.603 33.0497 25.935C33.0186 26.0595 32.9979 27.8651 33.0186 29.9507L33.0497 33.7382L33.2988 34.0806C33.6412 34.5683 39.3276 37.4219 39.9502 37.4219C40.8841 37.4219 41.6312 36.7059 41.6312 35.8135C41.6312 34.724 41.4133 34.5268 38.6842 33.1571L36.4325 32.0261L36.4014 28.9961C36.3702 26.1425 36.3599 25.935 36.1523 25.5926C35.7373 24.887 34.7411 24.5757 33.9836 24.9077Z"
      fill="#0E9CE2"
    />
    <path
      d="M0.933892 21.6704C0.674477 21.8053 0.36318 22.0647 0.238661 22.2618L0 22.6146V31.912C0 39.3417 0.0311297 41.3132 0.145272 41.7283C0.601841 43.4404 1.94042 44.779 3.62142 45.2252C4.23364 45.3808 5.04302 45.4016 11.4039 45.4016C18.325 45.412 18.5222 45.4016 18.9269 45.2044C19.5287 44.9035 19.757 44.5507 19.7985 43.8036C19.8296 43.2848 19.7985 43.1187 19.591 42.8282C19.4665 42.631 19.2174 42.3716 19.0618 42.2575C18.7712 42.0396 18.6675 42.0396 11.5699 41.9877C4.45155 41.9358 4.36854 41.9358 4.07799 41.7179C3.92235 41.5934 3.69406 41.3443 3.57992 41.1472C3.38276 40.8048 3.37239 40.5557 3.3205 31.6423C3.26862 22.6146 3.26862 22.4901 3.05071 22.1996C2.80168 21.8571 2.04419 21.4421 1.66025 21.4421C1.51498 21.4421 1.18293 21.5458 0.933892 21.6704Z"
      fill="#0E9CE2"
    />
    <path
      d="M8.60187 21.5454C8.12455 21.6907 7.7925 21.9709 7.56422 22.4482C7.18028 23.2368 7.38781 24.0462 8.13493 24.5961C8.40472 24.8037 8.58112 24.814 11.2894 24.8452C12.8666 24.8659 14.309 24.8452 14.4958 24.814C15.2533 24.6791 15.8655 23.6726 15.7098 22.8217C15.6164 22.334 15.025 21.6803 14.5476 21.5454C14.1015 21.4209 9.00656 21.4209 8.60187 21.5454Z"
      fill="#0E9CE2"
    />
    <path
      d="M8.24963 26.6611C7.07708 27.3252 7.09783 29.0062 8.30152 29.608C8.74771 29.8363 8.86185 29.8467 11.5598 29.8467C14.1747 29.8467 14.3822 29.8363 14.7973 29.6288C16.0321 29.0373 16.0321 27.2318 14.7973 26.6403C14.3822 26.4328 14.185 26.4224 11.5079 26.4224C8.74771 26.4224 8.65432 26.4328 8.24963 26.6611Z"
      fill="#0E9CE2"
    />
    <path
      d="M8.35296 31.5489C7.7615 31.8083 7.36719 32.4309 7.36719 33.0847C7.36719 33.5931 7.75112 34.2676 8.17656 34.5374C8.55012 34.7657 8.65388 34.776 11.331 34.8072C13.0743 34.8279 14.2469 34.7968 14.5063 34.7242C15.0251 34.5893 15.6166 33.9563 15.71 33.4478C15.793 32.9913 15.6373 32.3583 15.3571 32.0055C14.9213 31.4348 14.693 31.4036 11.5489 31.4036C9.38024 31.414 8.59162 31.4452 8.35296 31.5489Z"
      fill="#0E9CE2"
    />
  </svg>
);

export default CalendarIcon;
