import React, { useEffect } from 'react';
import PaymentSuccess from '../../components/Payments/PaymentSuccess';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getPaymentStatus } from '../../API/services/alkymersService';
import PaymentFailure from '../../components/Payments/PaymentFailure';
import PaymentPending from '../../components/Payments/PaymentPending';
import Spinner from '../../components/spinner/Spinner';
import { useHistory } from 'react-router-dom';
import { HOME } from '../../constants/routes';
import MyInternalServerError from '../../components/MyError/MyInternalServerError';
const Status = () => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const payment_id = queryParams.get('payment_id') ?? null;
  if (!payment_id) history.push(HOME);

  const { status, data, error, isLoading, isError, refetch, isSuccess } = useQuery({
    queryKey: ['paymentStatus', payment_id],
    queryFn: () => getPaymentStatus(payment_id),
    retry: 0,
  });

  useEffect(() => {
    if (payment_id) refetch();
  }, [payment_id]);

  const PaymentStatus = ({ status }) => {
    if (status == 'approved') return <PaymentSuccess />;
    if (status == 'pending') return <PaymentPending />;
    if (status == 'rejected') return <PaymentFailure />;
    return null;
  };

  return isLoading ? (
    <Spinner show={isLoading} />
  ) : (
    <>
      {isSuccess && <PaymentStatus status={data} />}
      {isError && <MyInternalServerError />}
    </>
  );
};

export default Status;
