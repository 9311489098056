import { oneDecimal } from '../../../utils/numbersUtils';
import './NumberPill.scss';

const NumberPill = ({ number, className = '' }) => (
  <div className={`d-flex justify-content-center ${className}`}>
    <span
      className={`number-pill-container font-montserrat d-flex justify-content-center align-items-center font-weight-bold number-pill-${
        !number ? 'no-score-bg' : 'green-bg'
      }`}
      data-testid="number"
    >
      {number ? oneDecimal(number) : '-'}
    </span>
  </div>
);

export default NumberPill;
