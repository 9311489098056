import CreatedCheckIcon from '../../assets/createdWorkspace/CreatedCheckIcon';
import './CreatedWorkspace.scss';
import WorkspaceOptions from '../../components/WorkspaceOptions/WorkspaceOptions';
import { t } from '../../i18n/i18n';
import { getLocalStorageWorkspace } from '../../utils/workspaceUtils';

const CreatedWorkspace = () => {
  const currentRole = getLocalStorageWorkspace()?.role;
  return (
    <div className="w-100 mx-auto h-100">
      <div className="d-flex flex-column align-items-center mt-4">
        <CreatedCheckIcon testid="created-check-icon" className="mb-2" />
        <span className="created-workspace-title font-weight-500 font-montserrat">
          {t('CREATED_WORKSPACE_SECTION_TITLE')}
        </span>
      </div>
      <WorkspaceOptions showTitle currentRole={currentRole} />
    </div>
  );
};

export default CreatedWorkspace;
