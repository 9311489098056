const QuestionIcon = () => (
  <svg
    data-testid="question-icon"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 11C22 17.0765 17.0747 22 11 22C4.92529 22 0 17.0765 0 11C0 4.92707 4.92529 0 11 0C17.0747 0 22 4.92707 22 11ZM11.2952 3.6371C8.87798 3.6371 7.33629 4.65535 6.12567 6.46507C5.96883 6.69953 6.0213 7.01574 6.24609 7.18619L7.78516 8.35317C8.01603 8.52824 8.34496 8.48659 8.52433 8.25905C9.31669 7.25406 9.85999 6.67128 11.066 6.67128C11.9721 6.67128 13.0929 7.25446 13.0929 8.13313C13.0929 8.79738 12.5446 9.13852 11.6499 9.64013C10.6065 10.225 9.22581 10.953 9.22581 12.7742V12.9516C9.22581 13.2456 9.46412 13.4839 9.75806 13.4839H12.2419C12.5359 13.4839 12.7742 13.2456 12.7742 12.9516V12.8925C12.7742 11.6301 16.4639 11.5775 16.4639 8.16129C16.4639 5.58862 13.7953 3.6371 11.2952 3.6371ZM11 14.6371C9.87494 14.6371 8.95968 15.5524 8.95968 16.6774C8.95968 17.8024 9.87494 18.7177 11 18.7177C12.1251 18.7177 13.0403 17.8024 13.0403 16.6774C13.0403 15.5524 12.1251 14.6371 11 14.6371Z"
      fill="#607D8B"
    />
  </svg>
);

export default QuestionIcon;
