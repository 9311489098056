import { Document, Page } from 'react-pdf';
import React, { useState } from 'react';
import Spinner from '../spinner/Spinner';

const PDFViewer = ({ pdf }) => {
  const [numPages, setNumPages] = useState(null);
  const [loading, setLoading] = useState(true);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setLoading(false);
  }

  return (
    <>
      <Spinner show={loading} />
      <div
        className="w-100 f-100"
        style={{ overflowY: 'scroll', display: loading ? 'none' : 'block' }}
      >
        <Document
          file={pdf}
          options={{ workerSrc: '/pdf.worker.js' }}
          onLoadSuccess={(pdf) => onDocumentLoadSuccess(pdf)}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              customTextRenderer={false}
            />
          ))}
        </Document>
      </div>
    </>
  );
};

export default PDFViewer;
