const ExternalLink = ({ to, children, event, disabled = false, ...rest }) => {
  const handleClick = () => {
    if (!disabled) {
      window.open(to);
      if (event) {
        event();
      }
    }
  };

  return (
    <a
      {...rest}
      data-testid="external-link"
      role="link"
      tabIndex={0}
      onClick={handleClick}
      style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      onKeyPress={handleClick}
    >
      {children}
    </a>
  );
};

export default ExternalLink;
