import { useRef } from 'react';
import useOnScreen from './useOnScreen';

function useAnimation({ margin = '-30px' }) {
  const ref = useRef();
  const intersecting = useOnScreen(ref, margin);

  return { ref, intersecting };
}

export default useAnimation;
