import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTermsAndConditions } from '../API/services/alkymersService';
import { editMe } from '../API/services/authService';
import { setUser } from '../redux/actions/authActions';

const useTermsAndConditions = ({ platform = 'PARTNERS' }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [termsAndConditionsLoading, setTermsAndConditionsLoading] = useState(false);
  const [termsAndConditionsData, setTermsAndConditionsData] = useState(null);
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState(false);
  const [modalContentLoading, setModalContentLoading] = useState(false);

  const showTermsAndConditionsModal = async () => {
    setShowModal(true);
    if (!termsAndConditionsData) {
      setTermsAndConditionsLoading(true);
      await getTermsAndConditions(platform).then((r) => {
        setTermsAndConditionsData(r);
        setTermsAndConditionsLoading(false);
      });
    }
  };

  const acceptTermsAndConditions = async (id) => {
    setModalContentLoading(true);
    editMe({
      id,
      toEdit: {
        termsAndConditionsAcceptance: new Date().toISOString(),
      },
    }).then((r) => {
      dispatch(setUser({ ...user, termsAndConditionsAcceptance: r.termsAndConditionsAcceptance }));
    });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return {
    showModal,
    setShowModal,
    termsAndConditionsLoading,
    termsAndConditionsData,
    acceptedTermsAndConditions,
    modalContentLoading,
    showTermsAndConditionsModal,
    acceptTermsAndConditions,
    setAcceptedTermsAndConditions,
    closeModal,
  };
};

export default useTermsAndConditions;
