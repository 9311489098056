const Icon9 = ({ color = '#BCBCBC' }) => (
  <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 0C12.6638 0 13.2 0.53625 13.2 1.2V3.6H17.7C19.1925 3.6 20.4 4.8075 20.4 6.3V16.5C20.4 17.9925 19.1925 19.2 17.7 19.2H6.3C4.8075 19.2 3.6 17.9925 3.6 16.5V6.3C3.6 4.8075 4.8075 3.6 6.3 3.6H10.8V1.2C10.8 0.53625 11.3363 0 12 0ZM7.8 14.4C7.47 14.4 7.2 14.67 7.2 15C7.2 15.33 7.47 15.6 7.8 15.6H9C9.33 15.6 9.6 15.33 9.6 15C9.6 14.67 9.33 14.4 9 14.4H7.8ZM11.4 14.4C11.07 14.4 10.8 14.67 10.8 15C10.8 15.33 11.07 15.6 11.4 15.6H12.6C12.93 15.6 13.2 15.33 13.2 15C13.2 14.67 12.93 14.4 12.6 14.4H11.4ZM15 14.4C14.67 14.4 14.4 14.67 14.4 15C14.4 15.33 14.67 15.6 15 15.6H16.2C16.53 15.6 16.8 15.33 16.8 15C16.8 14.67 16.53 14.4 16.2 14.4H15ZM9.9 9.6C9.9 9.20218 9.74197 8.82064 9.46066 8.53934C9.17936 8.25804 8.79782 8.1 8.4 8.1C8.00218 8.1 7.62064 8.25804 7.33934 8.53934C7.05804 8.82064 6.9 9.20218 6.9 9.6C6.9 9.99782 7.05804 10.3794 7.33934 10.6607C7.62064 10.942 8.00218 11.1 8.4 11.1C8.79782 11.1 9.17936 10.942 9.46066 10.6607C9.74197 10.3794 9.9 9.99782 9.9 9.6ZM15.6 11.1C15.9978 11.1 16.3794 10.942 16.6607 10.6607C16.942 10.3794 17.1 9.99782 17.1 9.6C17.1 9.20218 16.942 8.82064 16.6607 8.53934C16.3794 8.25804 15.9978 8.1 15.6 8.1C15.2022 8.1 14.8206 8.25804 14.5393 8.53934C14.258 8.82064 14.1 9.20218 14.1 9.6C14.1 9.99782 14.258 10.3794 14.5393 10.6607C14.8206 10.942 15.2022 11.1 15.6 11.1ZM1.8 8.4H2.4V15.6H1.8C0.80625 15.6 0 14.7938 0 13.8V10.2C0 9.20625 0.80625 8.4 1.8 8.4ZM22.2 8.4C23.1938 8.4 24 9.20625 24 10.2V13.8C24 14.7938 23.1938 15.6 22.2 15.6H21.6V8.4H22.2Z"
      fill={color}
    />
  </svg>
);

export default Icon9;
