const ScienceIcon = ({ width = '16', height = '17', color = 'currentColor' }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7992 15.3209L9.9992 7.5909V3.4209L11.3492 1.7309C11.6092 1.4009 11.3792 0.920898 10.9592 0.920898H5.0392C4.6192 0.920898 4.3892 1.4009 4.6492 1.7309L5.9992 3.4209V7.5909L0.199199 15.3209C-0.290801 15.9809 0.179199 16.9209 0.999199 16.9209H14.9992C15.8192 16.9209 16.2892 15.9809 15.7992 15.3209Z"
      fill={color}
      stroke="none"
    />
  </svg>
);

export default ScienceIcon;
