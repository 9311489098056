import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import {
  setConfirmed,
  setNewSearchData,
  setSubjectEmail,
  setTemplate,
} from '../../redux/actions/screeningActions';
import { saveTemplateEmail } from '../../API/services/alkymersService';
import EditEmailTemplate from '../../components/EditEmailTemplate/EditEmailTemplate';
import { createScreeningInvitations } from '../../API/services/screeningService';
import { t } from '../../i18n/i18n';

const CreateSearch = () => {
  const { newSearch, emailsData, template, subjectEmail } = useSelector(
    (state) => state.screenings
  );
  const { language } = useSelector((state) => state.ux);
  const { currentWorkspace } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const saveTemplate = async (value) =>
    saveTemplateEmail(value).then((res) => {
      dispatch(setTemplate({ template: res.html, id: res.id }));
      history.goBack();
    });

  const handleSendRequest = async (error, setLoading) => {
    const requestData = {
      levelId: String(newSearch?.stages[0]?.levelId),
      technologyId: Number(newSearch?.stages[0]?.technologyId),
      screeningName: newSearch?.name,
      recruitSessionStageId: newSearch?.stages[0]?.id,
      emailsParams: {
        subject: subjectEmail.subjectEmail,
      },
      emailTemplate: {
        ...(template.id
          ? { customEmailId: template.id }
          : {
              default: true,
              language,
            }),
      },
      workspaceId: currentWorkspace,
    };
    setLoading(true);
    const emails = [];
    for (
      let i = 0;
      Object.values(emailsData).length > i;
      i += Number(process.env.RAZZLE_RUNTIME_EMAIL_BATCH_SIZE)
    ) {
      emails.push(
        Object.values(emailsData).slice(i, i + Number(process.env.RAZZLE_RUNTIME_EMAIL_BATCH_SIZE))
      );
    }
    Promise.all(
      emails.map(async (items) => {
        if (!error) {
          try {
            await createScreeningInvitations({
              ...requestData,
              guests: items,
            });
            dispatch(
              setNewSearchData({
                name: '',
                stagesQuantity: 1,
                stages: [],
                step: 1,
              })
            );
            dispatch(setConfirmed(false));
            dispatch(setSubjectEmail({ subjectEmail: '' }));
          } catch (error) {
            notification.open({
              message: t('INVITATION_ERROR'),
              icon: <i className="fas fa-times" style={{ color: 'red' }} />,
            });
          }
        } else {
          notification.open({
            message: t('EMAILS_LIST_ERROR_NOTIFICATION'),
            type: 'error',
          });
        }
      })
    ).then(() => {
      if (Object.values(emailsData).length === 1) {
        notification.open({
          message: t('INVITED_SINGLE_PROFILE_CORRECTLY'),
          icon: <i className="fas fa-check" style={{ color: '#58DDA6' }} />,
        });
      } else {
        notification.open({
          message: t('INVITED_MULTIPLE_PROFILES_CORRECTLY'),
          icon: <i className="fas fa-check" style={{ color: '#58DDA6' }} />,
        });
      }
      setLoading(false);
      history.push('/screening-success');
    });
  };

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message = t('CREATE_SCREENING_LEAVE_SECTION_ALERT');
    e.returnValue = message;
    return message;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    if (!newSearch?.stages?.length) {
      history.replace('/screening/searches');
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return <EditEmailTemplate handleSave={saveTemplate} handleSendRequest={handleSendRequest} />;
};

export default CreateSearch;
