import React from 'react';

const DiamondIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 35 29" fill="none">
    <path
      d="M0 8.92308H8.75L15.3125 28.1077L0 8.92308ZM35 8.92308H26.25L19.6875 28.1077L35 8.92308ZM17.5 29L10.9375 8.92308H24.0625L17.5 29ZM8.75 6.69231H0L4.375 0L8.75 6.69231ZM35 6.69231H26.25L30.625 0L35 6.69231ZM21.875 6.69231H13.125L17.5 0L21.875 6.69231ZM7.30625 0H15.3125L10.9375 6.69231L7.30625 0ZM19.6875 0H28.4375L24.0625 6.69231L19.6875 0Z"
      fill="#E5C87E"
    />
  </svg>
);

export default DiamondIcon;
