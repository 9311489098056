const EmailIcon = ({ active }) => (
  <svg width="30" height="30" viewBox="15 15 65 78" fill="none" style={{ marginLeft: '5px' }}>
    <path
      d="M82.3,41.035a1.075,1.075,0,0,0-.372-.813L70.082,30.012V16.692a1.074,1.074,0,0,0-1.074-1.074H53.375L48.2,11.152a1.076,1.076,0,0,0-1.4,0l-5.181,4.466H30.284a1.074,1.074,0,0,0-1.074,1.074v3.449H25.993a1.073,1.073,0,0,0-1.074,1.074v8.8L13.076,40.222a1.075,1.075,0,0,0-.372.813v42c0,.007,0,.013,0,.019a1.038,1.038,0,0,0,.063.355c0,.009.01.016.014.025a.894.894,0,0,0,.044.08,1.066,1.066,0,0,0,.16.236,1.191,1.191,0,0,0,.089.085,1.058,1.058,0,0,0,.217.146.947.947,0,0,0,.106.052,1.043,1.043,0,0,0,.377.076H81.222a1.043,1.043,0,0,0,.377-.076.947.947,0,0,0,.106-.052,1.058,1.058,0,0,0,.217-.146,1.191,1.191,0,0,0,.089-.085,1.066,1.066,0,0,0,.16-.236.894.894,0,0,0,.044-.08c0-.009.011-.016.014-.025a1.038,1.038,0,0,0,.063-.355c0-.006,0-.012,0-.019ZM62.256,30.167H56.5v-6.21ZM80.147,43.38V80.69L58.507,62.038Zm-.571-2.345-9.494,8.186V32.85ZM47.493,13.385l2.591,2.233H44.9ZM31.358,17.767H67.934V51.073L65.79,52.921V31.241c0-.022-.011-.042-.012-.064a1.049,1.049,0,0,0-.049-.259c-.01-.033-.018-.066-.031-.1a1.06,1.06,0,0,0-.194-.308L56.218,20.484a1.074,1.074,0,0,0-.322-.228.879.879,0,0,0-.1-.039,1.043,1.043,0,0,0-.3-.063c-.022,0-.041-.013-.063-.013H31.358Zm23,4.522v8.952a1.074,1.074,0,0,0,1.075,1.074h8.212V54.774L56.861,60.62l-8.667-7.471a1.078,1.078,0,0,0-1.4,0l-8.657,7.465L27.067,51.074V22.289ZM36.488,62.034,14.853,80.689V43.38ZM24.919,49.222l-9.5-8.187,9.5-8.186ZM16.671,81.96,47.493,55.383,78.329,81.96ZM31.219,34.5a1.073,1.073,0,0,1,1.074-1.074H43.087a1.074,1.074,0,0,1,0,2.148H32.293A1.073,1.073,0,0,1,31.219,34.5Zm0,4.494a1.073,1.073,0,0,1,1.074-1.074H57.626a1.074,1.074,0,1,1,0,2.148H32.293A1.073,1.073,0,0,1,31.219,38.991Zm0,4.494a1.074,1.074,0,0,1,1.074-1.074H57.626a1.075,1.075,0,0,1,0,2.149H32.293A1.074,1.074,0,0,1,31.219,43.485Zm0,4.494a1.074,1.074,0,0,1,1.074-1.074H57.626a1.075,1.075,0,0,1,0,2.149H32.293A1.074,1.074,0,0,1,31.219,47.979Z"
      fill={active ? '#41479B' : '#828282'}
      stroke="#828282"
      stroke-width="1.7"
    />
  </svg>
);
export default EmailIcon;
