import React from 'react';
import ReactSelect, { components } from 'react-select';
import DropDownArrow from '../../assets/screening/DropDownArrow';
import { t } from '../../i18n/i18n';

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <DropDownArrow lightBlue />
  </components.DropdownIndicator>
);

const SearchableSelect = ({ options = [], allOption, onChange, selected }) => {
  const customStyles = {
    container: (provided) => ({
      ...provided,
      height: '3.125rem',
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: '0px',
      padding: '.25rem 0',
      border: '#58c1f5 1.5px solid',
      borderRadius: '8px',
    }),
    option: (provided) => ({
      ...provided,
      fontWeight: '600',
      fontSize: '0.75rem',
      fontFamily: 'Montserrat',
      padding: '.5rem .75rem',
      backgroundColor: 'transparent',
      color: '#5E5E5E',
      opacity: '.5',
      '&:hover': {
        backgroundColor: '#fafafa',
        cursor: 'pointer',
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    valueContainer: (provided) => ({
      ...provided,
      color: '#6c757d',
      opacity: '1',
      padding: '0px .5rem',
      fontFamily: 'Montserrat',
      fontSize: '0.75rem',
      fontWeight: '600',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '0.75rem',
      color: '#6c757d',
      fontFamily: 'Montserrat',
      fontWeight: '600',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    control: (provided) => ({
      ...provided,
      minHeight: '0px',
      background: 'transparent',
      display: 'flex',
      borderRadius: '.5rem',
      alignItems: 'center',
      height: '100%',
      boxShadow: 'none',
      border: `1.5px solid ${selected ? '#58C1F5' : '#A0A0A0'}`,
      '&:hover': {
        border: `1.5px solid ${selected ? '#58C1F5' : '#A0A0A0'}`,
      },
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      padding: '0px 0.313rem 0.25rem',
      color: '#58c1f5',
    }),
  };

  return (
    <ReactSelect
      components={{ DropdownIndicator }}
      onChange={onChange}
      styles={customStyles}
      placeholder=""
      isSearchable
      options={
        allOption
          ? [...options, { value: 'all', label: t('SEARCHABLE_SELECT_ALL_OPTION') }]
          : options
      }
    />
  );
};

export default SearchableSelect;
