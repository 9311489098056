const CheckLogo = () => (
  <svg
    data-testid="check-logo"
    width="19"
    height="16"
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.03809 12.0776L1.53383 7.41319L0 8.99037L6.03809 15.2432L19 1.82035L17.477 0.243164L6.03809 12.0776Z"
      fill="#2B9F5A"
    />
  </svg>
);

export default CheckLogo;
