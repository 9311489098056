import { t } from '../i18n/i18n';
import {
  PROFILES,
  SAVED_SEARCHES,
  SAVED_PROFILES,
  RoutesKeys,
  SCREENING_SEARCHES,
  CREATE_TEST_GROUP,
  HOME,
  TESTS,
  RESET_TEST_GROUP,
  CREATE_ABM_TESTGROUP_SCREENING,
  CREATE_EVALUATION,
  WORKSPACE,
  CONFIGURATION,
  CONFIGURATION_MEMBERS,
  PLANS_CONSUMPTIONS,
  CREATE_JOB_POST,
  VIEW_ALL_JOB_POST,
  JOB_POST,
  NOTIFICATIONS,
} from './routes';
import profilesIcon from '../assets/sidebar/icons/ProfilesIcon';
import chartIcon from '../assets/sidebar/icons/Chart';
import homeIcon from '../assets/sidebar/icons/Home';
import WorkspaceIcon from '../assets/sidebar/icons/WorkspaceIcon';
import ConfigIcon from '../assets/cardWorkspace/ConfigIcon';
import JobBagIcon from '../assets/sidebar/icons/JobBag';
import { PartnerWorkspaceRole } from './enums';
import ScienceIcon from '../assets/sidebar/icons/ScienceIcon';

const sidebarRoutes = [
  // REFACTORIZAR ESTO
  {
    key: RoutesKeys.WORKSPACE,
    path: WORKSPACE,
    label: 'WORKSPACE',
    hide: true,
    icon: WorkspaceIcon,
  },
  {
    key: RoutesKeys.HOME,
    path: HOME,
    label: 'HOME',
    hide: true,
    icon: homeIcon,
  },
  {
    key: RoutesKeys.SCREENING_INVITATIONS,
    label: 'MENU_ITEM_SCREENING',
    hide: false,
    icon: <ScienceIcon />,
    subRoutes: [
      {
        label: 'MENU_ITEM_CREATE_EVALUATION',
        path: CREATE_EVALUATION,
        workspaceRoles: ['OWNER', 'ADMINISTRATOR'],
        track: 'create-evaluation-sidebar',
      },
      {
        label: 'MENU_ITEM_SEARCHES',
        path: SCREENING_SEARCHES,
        track: 'searches-dashboard',
      },
      {
        label: 'MENU_ITEM_TESTS',
        path: TESTS,
        track: 'tests',
      },
    ],
  },
  {
    key: RoutesKeys.PROFILES,
    label: t('COMMUNITY_ALKEMY'),
    icon: profilesIcon,
    subRoutes: [
      {
        label: t('TALENTS'),
        path: PROFILES,
        track: 'talent-marketplace',
      },
      {
        label: t('MY_FILTERS'),
        path: SAVED_SEARCHES,
        track: 'my-filters',
      },
      { label: t('FAVORITES'), path: SAVED_PROFILES, track: 'my-favorites' },
    ],
  },
  {
    key: RoutesKeys.CREATE_ABM_TESTGROUP_SCREENING,
    label: 'ABM_TESTGROUP_SCREENING',
    path: CREATE_ABM_TESTGROUP_SCREENING,
    icon: chartIcon,
  },
  {
    key: RoutesKeys.CREATE_TEST_GROUP,
    path: CREATE_TEST_GROUP,
    label: 'MENU_ITEM_CREATE_TEST_GROUP',
    icon: chartIcon,
  },
  {
    key: RoutesKeys.RESET_TEST_GROUP,
    path: RESET_TEST_GROUP,
    label: 'MENU_ITEM_RESET_TEST_GROUP',
    icon: chartIcon,
  },
  {
    key: RoutesKeys.JOB_POST,
    path: JOB_POST,
    label: 'JOB_POST',
    icon: JobBagIcon,
    subRoutes: [
      {
        label: 'CREATE_JOB_POST',
        path: CREATE_JOB_POST,
        workspaceRoles: [PartnerWorkspaceRole.OWNER, PartnerWorkspaceRole.ADMINISTRATOR],
      },
      {
        label: 'VIEW_ALL_JOB_POST',
        path: VIEW_ALL_JOB_POST,
      },
    ],
  },
  {
    key: RoutesKeys.CONFIGURATION,
    label: 'CONFIGURATION_PAGE_BANNER',
    hide: false,
    icon: <ConfigIcon width="20" height="20" />,
    workspaceRoles: ['OWNER', 'ADMINISTRATOR'],
    subRoutes: [
      {
        label: 'MENU_ITEM_MEMBERS',
        path: CONFIGURATION_MEMBERS,
      },
      {
        label: 'MENU_ITEM_PERSONALIZATION',
        path: CONFIGURATION,
      },
      {
        label: 'MENU_ITEM_PLANS_CONSUMPTION',
        path: PLANS_CONSUMPTIONS,
      },
      {
        label: 'NOTIFICATIONS',
        path: NOTIFICATIONS,
      },
    ],
  },
];

export default sidebarRoutes;
