const MultipleChoice = () => {
  return (
    <svg width="31" height="27" viewBox="0 0 34 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.77778 0C2.77585 0 1.81496 0.31607 1.10649 0.87868C0.398015 1.44129 0 2.20435 0 3V24C0 24.7956 0.398015 25.5587 1.10649 26.1213C1.81496 26.6839 2.77585 27 3.77778 27H22.6667C23.6686 27 24.6295 26.6839 25.338 26.1213C26.0464 25.5587 26.4444 24.7956 26.4444 24V3C26.4444 2.20435 26.0464 1.44129 25.338 0.87868C24.6295 0.31607 23.6686 0 22.6667 0H3.77778ZM1.88889 3C1.88889 2.60218 2.0879 2.22064 2.44213 1.93934C2.79637 1.65804 3.27681 1.5 3.77778 1.5H22.6667C23.1676 1.5 23.6481 1.65804 24.0023 1.93934C24.3565 2.22064 24.5556 2.60218 24.5556 3V24C24.5556 24.3978 24.3565 24.7794 24.0023 25.0607C23.6481 25.342 23.1676 25.5 22.6667 25.5H3.77778C3.27681 25.5 2.79637 25.342 2.44213 25.0607C2.0879 24.7794 1.88889 24.3978 1.88889 24V3ZM28.3333 7.5C28.3333 6.90326 28.6318 6.33097 29.1632 5.90901C29.6946 5.48705 30.4152 5.25 31.1667 5.25C31.9181 5.25 32.6388 5.48705 33.1701 5.90901C33.7015 6.33097 34 6.90326 34 7.5V22.7273L31.1667 26.1023L28.3333 22.7273V7.5ZM31.1667 6.75C30.9162 6.75 30.676 6.82902 30.4988 6.96967C30.3217 7.11032 30.2222 7.30109 30.2222 7.5V9H32.1111V7.5C32.1111 7.30109 32.0116 7.11032 31.8345 6.96967C31.6574 6.82902 31.4171 6.75 31.1667 6.75ZM31.1667 23.3977L30.2222 22.2727V10.5H32.1111V22.2727L31.1667 23.3977Z"
        fill="#686868"
      />
      <path
        d="M13.2227 6.75C13.2227 6.55109 13.3222 6.36032 13.4993 6.21967C13.6764 6.07902 13.9166 6 14.1671 6H21.7227C21.9731 6 22.2134 6.07902 22.3905 6.21967C22.5676 6.36032 22.6671 6.55109 22.6671 6.75C22.6671 6.94891 22.5676 7.13968 22.3905 7.28033C22.2134 7.42098 21.9731 7.5 21.7227 7.5H14.1671C13.9166 7.5 13.6764 7.42098 13.4993 7.28033C13.3222 7.13968 13.2227 6.94891 13.2227 6.75ZM14.1671 9C13.9166 9 13.6764 9.07902 13.4993 9.21967C13.3222 9.36032 13.2227 9.55109 13.2227 9.75C13.2227 9.94891 13.3222 10.1397 13.4993 10.2803C13.6764 10.421 13.9166 10.5 14.1671 10.5H21.7227C21.9731 10.5 22.2134 10.421 22.3905 10.2803C22.5676 10.1397 22.6671 9.94891 22.6671 9.75C22.6671 9.55109 22.5676 9.36032 22.3905 9.21967C22.2134 9.07902 21.9731 9 21.7227 9H14.1671ZM13.2227 16.5C13.2227 16.3011 13.3222 16.1103 13.4993 15.9697C13.6764 15.829 13.9166 15.75 14.1671 15.75H21.7227C21.9731 15.75 22.2134 15.829 22.3905 15.9697C22.5676 16.1103 22.6671 16.3011 22.6671 16.5C22.6671 16.6989 22.5676 16.8897 22.3905 17.0303C22.2134 17.171 21.9731 17.25 21.7227 17.25H14.1671C13.9166 17.25 13.6764 17.171 13.4993 17.0303C13.3222 16.8897 13.2227 16.6989 13.2227 16.5ZM14.1671 18.75C13.9166 18.75 13.6764 18.829 13.4993 18.9697C13.3222 19.1103 13.2227 19.3011 13.2227 19.5C13.2227 19.6989 13.3222 19.8897 13.4993 20.0303C13.6764 20.171 13.9166 20.25 14.1671 20.25H21.7227C21.9731 20.25 22.2134 20.171 22.3905 20.0303C22.5676 19.8897 22.6671 19.6989 22.6671 19.5C22.6671 19.3011 22.5676 19.1103 22.3905 18.9697C22.2134 18.829 21.9731 18.75 21.7227 18.75H14.1671Z"
        fill="#686868"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.77734 15.75C3.77734 15.5511 3.87685 15.3603 4.05397 15.2197C4.23108 15.079 4.47131 15 4.72179 15H9.44401C9.69449 15 9.93472 15.079 10.1118 15.2197C10.289 15.3603 10.3885 15.5511 10.3885 15.75V19.5C10.3885 19.6989 10.289 19.8897 10.1118 20.0303C9.93472 20.171 9.69449 20.25 9.44401 20.25H4.72179C4.47131 20.25 4.23108 20.171 4.05397 20.0303C3.87685 19.8897 3.77734 19.6989 3.77734 19.5V15.75ZM5.66623 16.5V18.75H8.49957V16.5H5.66623Z"
        fill="#686868"
      />
      <path
        d="M11.0563 7.27885C11.2283 7.13739 11.3235 6.94794 11.3214 6.7513C11.3192 6.55465 11.2199 6.36654 11.0448 6.22748C10.8697 6.08843 10.6328 6.00955 10.3852 6.00784C10.1375 6.00613 9.89897 6.08173 9.72084 6.21835L6.61079 8.6881L5.38962 7.71835C5.2115 7.58173 4.97293 7.50613 4.7253 7.50784C4.47767 7.50955 4.24079 7.58843 4.06568 7.72748C3.89058 7.86654 3.79125 8.05465 3.7891 8.2513C3.78695 8.44794 3.88214 8.6374 4.05418 8.77885L6.61079 10.8091L11.0563 7.27885Z"
        fill="#686868"
      />
    </svg>
  );
};

export default MultipleChoice;
