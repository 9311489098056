import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../redux/actions/uxActions';
import store from '../redux/store';
import { Window } from '../utils/window';

function useTranslations() {
  const dispatch = useDispatch();
  const { texts } = store.getState().auth.whitelabel;
  const languageKey = useSelector((store) => store.ux.language);

  const changeLanguageKey = (key) => {
    dispatch(setLanguage(key));
    Window()?.localStorage.setItem('language', key);
  };

  useEffect(() => {
    changeLanguageKey(languageKey);
  }, []);

  return {
    key: languageKey,
    texts: texts ? texts[languageKey] : null,
    changeLanguageKey,
  };
}

export default useTranslations;
