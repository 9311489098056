import { useEffect } from 'react';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import { t } from '../../i18n/i18n';
import useCreateEvaluation from '../../hooks/useCreateEvaluation';
import RolesTestGroup from '../../components/RolesTestGroup/RolesTestGroup';

const RolesTestGroupPage = () => {
  const { loadingTests, fetchTests, handleClearData } = useCreateEvaluation();
  useEffect(
    () => () => {
      handleClearData();
    },
    []
  );
  return (
    <div data-testid="tests-page-container" className="w-100 mx-auto h-100 bg-gray">
      <div>
        <BackgroundTop
          height="3rem"
          title={t('CATEGORIES')}
          routes={[
            {
              text: 'MENU_ITEM_SCREENING',
              clickable: false,
            },
          ]}
          className="position-relative"
          titleClassname="text-xl"
        />
      </div>
      <div className="px-3 bg-gray d-flex flex-column h-100">
        <div
          className="create-screening-container w-100 bg-white position-relative mx-auto p-4 rounded d-flex flex-column"
          style={{ flex: '1' }}
        >
          <RolesTestGroup loadingTests={loadingTests} fetchTests={fetchTests} isTestsPage />
        </div>
      </div>
    </div>
  );
};

export default RolesTestGroupPage;
