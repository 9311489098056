import { useRef } from 'react';
import ArrowLeft from '../../../assets/proctoring/ArrowLeft';
import ArrowRight from '../../../assets/proctoring/ArrowRight';
import useOnScreen from '../../../hooks/useOnScreen';
import './SliderCaroussel.scss';

const Slider = ({ children, leftArrowDisabled, rightArrowDisabled }) => {
  const sliderContainerRef = useRef();

  return (
    <div className="d-flex align-items-center justify-content-center">
      <button
        data-testid="button-arrow-left"
        type="button"
        className={`btn ${leftArrowDisabled && 'nav-button-disabled'}`}
        onClick={() => {
          sliderContainerRef.current.scrollLeft -= sliderContainerRef.current.offsetWidth / 2;
        }}
      >
        <ArrowLeft />
      </button>
      <div className="slider-container" ref={sliderContainerRef}>
        <div className="slider-wrapper">{children}</div>
      </div>
      <button
        data-testid="button-arrow-right"
        type="button"
        className={`btn ${rightArrowDisabled && 'nav-button-disabled'}`}
        onClick={() => {
          sliderContainerRef.current.scrollLeft += sliderContainerRef.current.offsetWidth / 2;
        }}
      >
        <ArrowRight />
      </button>
    </div>
  );
};

const SliderParent = ({ data, selectFilter, selectedFilter }) => {
  const leftRef = useRef();
  const rightRef = useRef();

  const leftRefOnScreen = useOnScreen(leftRef, '0px', true);
  const rightRefOnScreen = useOnScreen(rightRef, '10px', true);

  return (
    <div className="parent">
      <Slider leftArrowDisabled={leftRefOnScreen} rightArrowDisabled={rightRefOnScreen}>
        <div ref={leftRef} />
        {data.map((value) => (
          <button
            type="button"
            key={value.id}
            onClick={() => selectFilter(value.id, value.name)}
            className={`font-weight-700 font-montserrat bg-white child ${
              selectedFilter === value.id ? 'child-selected' : 'child-disabled'
            }`}
          >
            {value.name}
          </button>
        ))}
        <div className="text-white" ref={rightRef}>
          .
        </div>
      </Slider>
    </div>
  );
};

export default SliderParent;
