import React from 'react';
import PropTypes from 'prop-types';

const Price = (props) => {
  const { price, currency } = props;
  return (
    <div>
      <span
        className="py-5 font-montserrat font-weight-700 text-center text-nowrap"
        style={{ fontSize: '1.35rem' }}
      >
        {price}
      </span>
      <span className="ml-1 font-montserrat text-xs font-weight-600">{currency}</span>
    </div>
  );
};

Price.propTypes = {
  price: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
};

export default Price;
