export const capitalizeWord = (word) =>
  word && word?.trim && word?.trim() && word[0]
    ? (word?.charAt(0)?.toUpperCase() ?? '') + (word?.slice(1)?.toLowerCase() ?? '')
    : '';

export const capitalizeString = (words) =>
  words && words?.trim && words?.trim()
    ? words
        .trim()
        .split(' ')
        .filter((word) => word?.length)
        .map((word) => (word?.length ? word[0].toUpperCase() + word.substring(1) : ''))
        .join(' ')
    : '';

export const toUpperCase = (word) => (word && word?.trim && word?.trim() ? word.toUpperCase() : '');

export function removeAccents(value) {
  if (!value?.length) return '';
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function compareStrings(value1, value2) {
  if (!value1 || !value2) return false;
  return removeAccents(value1).toLowerCase() === removeAccents(value2).toLowerCase();
}

export function escapeStrings(text) {
  try {
    // This one is required for documents exported from sheets in csv
    return decodeURIComponent(escape(text));
  } catch (error) {
    return text;
  }
}

export function normalizedDescription(description = '', maxValue = 185) {
  if (description?.length <= maxValue) {
    return description;
  }
  return `${description?.substring(0, maxValue)}...`;
}
