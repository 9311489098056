import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const useHubspotIdentify = () => {
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (_hsq && user && process.env.RAZZLE_RUNTIME_ENV === 'production') {
      _hsq.push([
        'identify',
        {
          email: user.email,
          id: user.id,
          created_at: user.created_at,
        },
      ]);
    }
  }, [user]);
};

export default useHubspotIdentify;
