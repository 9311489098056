import React from 'react';
import Error from '../../assets/lottie/error.json';
import { t } from '../../i18n/i18n';
import PaymentStatus from './PaymentStatus';

const PaymentFailure = () => {
  const options = {
    animationData: Error,
    loop: false,
  };

  return (
    <PaymentStatus
      options={options}
      title={t('PAYMENT_FAILURE_TITLE')}
      subtitle={t('PAYMENT_FAILURE_SUBTITLE')}
    />
  );
};

export default PaymentFailure;
