import React from 'react';

const AudioFileIcon = ({ className }) => (
  <svg
    className={className}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0252 0.0346954L3.52544 2.24943C3.3732 2.29726 3.24018 2.39238 3.14572 2.52099C3.05126 2.6496 3.00027 2.80498 3.00017 2.96455V9.09171C2.75457 9.03209 2.50286 9.00126 2.25013 8.99983C1.00787 8.99983 0 9.67158 0 10.4999C0 11.3282 1.00787 12 2.25013 12C3.49239 12 4.50026 11.3282 4.50026 10.4999V5.02272L10.5006 3.2648V7.59185C10.255 7.53203 10.0033 7.50112 9.75057 7.49974C8.50831 7.49974 7.50044 8.1715 7.50044 8.99983C7.50044 9.82815 8.50831 10.4999 9.75057 10.4999C10.9928 10.4999 12.0007 9.82839 12.0007 8.99983V0.749346C12.0006 0.631599 11.9728 0.515531 11.9195 0.410537C11.8662 0.305543 11.7889 0.214573 11.6939 0.144988C11.5989 0.0754026 11.4889 0.0291583 11.3727 0.00999749C11.2565 -0.00916336 11.1375 -0.000702478 11.0252 0.0346954Z"
      fill="#41479B"
    />
  </svg>
);

export default AudioFileIcon;
