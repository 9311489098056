import React from 'react';
import './HorizontalLoader.scss';

const HorizontalLoader = ({ className, white }) => (
  <div className={`lds-ellipsis ${className}`}>
    <div className={`${white ? 'white' : ''}`} />
    <div className={`${white ? 'white' : ''}`} />
    <div className={`${white ? 'white' : ''}`} />
    <div className={`${white ? 'white' : ''}`} />
  </div>
);

export default HorizontalLoader;
