import { useEffect } from 'react';
import { Button, Steps } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';

import { t } from '../../i18n/i18n';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import Spinner from '../../components/spinner/Spinner';
import './CreateEvaluation.scss';
import useCreateEvaluation from '../../hooks/useCreateEvaluation';
import CreateEvaluationFirstStep from '../../components/CreateEvaluationFirstStep/CreateEvaluationFirstStep';
import CreateEvaluationSecondStep from '../../components/CreateEvaluationSecondStep/CreateEvaluationSecondStep';
import CreateEvaluationThirdStep from '../../components/CreateEvaluationThirdStep/CreateEvaluationThirdStep';
import { CREATE_EVALUATION } from '../../constants/routes';
import BlockRedirection from '../../components/BlockRedirection/BlockRedirection';
import CreateEvaluationFourthStep from '../../components/CreateEvaluationFourthStep/CreateEvaluationFourthStep';
import CreateEvaluationSummaryNav from '../../components/CreateEvaluationSummaryNav/createEvaluationSummaryNav';

const CreateEvaluation = () => {
  const {
    step,
    draftId,
    enableCreateButton,
    existingChanges,
    evaluation,
    loadingCreate,
    loadingTests,
    disableCustomTestGroupWeight,
    missingTestgroupWeight,
    preventReload,
    incompleteQuestionValidation,
    fetchTests,
    saveChangesModal,
    setShowExitModal,
    handleEvaluationName,
    handleClearData,
    handleSetEvaluationData,
    handleCreateDraft,
    handleOpenEvaluation,
    handleEvaluationQuickRole,
    handleChangeStep,
    loadingCreateDraft,
    newScreeningFilters,
    handleEditDraft,
    disableOpenEvaluation,
  } = useCreateEvaluation();
  const history = useHistory();
  const location = useLocation();

  useEffect(
    () => () => {
      if (location?.pathname === CREATE_EVALUATION) handleClearData();
    },
    [location?.pathname]
  );

  return (
    <BlockRedirection
      conditionToBlock={preventReload}
      route={CREATE_EVALUATION}
      updateConditionToBlock
      message={t('CREATE_SCREENING_LEAVE_SECTION_ALERT')}
    >
      <div className="w-100 mx-auto pl-3 pr-3 create-evaluation-container h-100">
        {saveChangesModal()}
        <div>
          <BackgroundTop
            title={t('CREATE_EVALUATION_SECTION_TITLE')}
            routes={[
              { text: 'MENU_ITEM_SCREENING', clickable: false },
              ...(history.location.state?.from
                ? [
                    {
                      text: history.location.state?.fromName,
                      clickable: true,
                      route: history.location.state?.from,
                    },
                  ]
                : []),
            ]}
            className="position-relative"
            titleClassname="text-xl"
          />
        </div>
        <div
          className={`position-relative new-search-container px-3 py-2 bg-white d-flex w-100 flex-column rounded mx-auto ${
            step === 2 ? 'window-height-step3' : ''
          }`}
        >
          {loadingCreate ? (
            <Spinner show />
          ) : (
            <>
              <div>
                <CreateEvaluationSummaryNav
                  evaluation={evaluation}
                  setShowExitModal={setShowExitModal}
                  existingChanges={existingChanges}
                  incompleteQuestionValidation={incompleteQuestionValidation}
                  step={step}
                  handleChangeStep={handleChangeStep}
                  handleOpenEvaluation={handleOpenEvaluation}
                  disableOpenEvaluation={disableOpenEvaluation}
                />
                <Steps
                  data-testid="create-evaluation-steps-container"
                  current={step}
                  onChange={handleChangeStep}
                  items={[
                    {
                      title: t('CREATE_EVALUATION_FIRST_STEP_TITLE'),
                      'data-testid': 'CREATE_EVALUATION_FIRST_STEP_TITLE',
                    },
                    {
                      title: t('CREATE_EVALUATION_SECOND_STEP_TITLE'),
                      disabled: !draftId,
                      'data-testid': 'CREATE_EVALUATION_SECOND_STEP_TITLE',
                    },
                    {
                      title: t('CREATE_EVALUATION_THIRD_STEP_TITLE'),
                      disabled: !draftId,
                      'data-testid': 'CREATE_EVALUATION_THIRD_STEP_TITLE',
                    },
                    {
                      title: t('CREATE_EVALUATION_FOURTH_STEP_TITLE'),
                      disabled: !draftId,
                      'data-testid': 'CREATE_EVALUATION_FOURTH_STEP_TITLE',
                    },
                  ]}
                />
              </div>
              <div className="mt-2 flex-grow-1 d-flex flex-column">
                {step === 0 && (
                  <CreateEvaluationFirstStep
                    evaluation={evaluation}
                    handleEvaluationName={handleEvaluationName}
                    handleEvaluationQuickRole={handleEvaluationQuickRole}
                    handleSetEvaluationData={handleSetEvaluationData}
                    newScreeningFilters={newScreeningFilters}
                  />
                )}
                {step === 1 && (
                  <CreateEvaluationSecondStep
                    loadingTests={loadingTests}
                    fetchTests={fetchTests}
                    evaluation={evaluation}
                  />
                )}
                {step === 2 && <CreateEvaluationThirdStep />}
                {step === 3 && (
                  <CreateEvaluationFourthStep
                    handleChangeStep={handleChangeStep}
                    evaluation={evaluation}
                    handleSetEvaluationData={handleSetEvaluationData}
                    missingTestgroupWeight={missingTestgroupWeight}
                    disableCustomTestGroupWeight={disableCustomTestGroupWeight}
                  />
                )}
              </div>
              <div className="mt-auto d-flex justify-content-end align-items-center px-5">
                {draftId ? (
                  <div>
                    {step === 0 && (
                      <button
                        onClick={handleEditDraft}
                        className={`mt-4 align-self-end px-3 py-2 rounded-pill  text-xs text-white font-weight-bold border-0 outline-none ${
                          !enableCreateButton || loadingCreateDraft
                            ? 'disabled-button'
                            : 'bg-maya-blue'
                        }`}
                        type="button"
                        data-testid="create-evaluation-create-draft-btn"
                      >
                        {t('CREATE_EVALUATION_CREATE_DRAFT_BUTTON')}
                      </button>
                    )}
                  </div>
                ) : (
                  <Button
                    onClick={handleCreateDraft}
                    className={`px-3 font-weight-600 text-sm  text-white ${
                      !enableCreateButton || loadingCreateDraft
                        ? 'disabled-button'
                        : 'bg-blue-principal'
                    }`}
                    type="button"
                    data-testid="create-evaluation-create-draft-btn"
                  >
                    {t('CREATE_EVALUATION_CREATE_DRAFT_BUTTON')}
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </BlockRedirection>
  );
};

export default CreateEvaluation;
