import {
  SET_USER,
  SET_USER_TOKEN,
  SET_CHECKING_TOKEN,
  SET_LOADING_ME,
  SET_MAINTENANCE,
  SET_WHITELABEL,
  SET_AUTHENTICATED,
  SET_LOGIN_LOADING,
  SET_VERIFY,
  SET_PLAN,
  SET_VERIFY_EMAIL_USER,
  SET_USER_EMAIL,
  SET_CURRENT_WORKSPACE,
  SET_CURRENT_ROLE,
  SET_WORKSPACES,
  SET_WORKSPACE_FAVORITE,
  SET_PLAN_FEATURES,
  SET_WORKSPACE_OWNERS,
} from '../actionTypes';

const initialState = {
  user: null,
  token: null,
  email: null,
  isChecking: false,
  isLoadingMe: true,
  isVerify: false,
  currentWorkspace: null,
  currentRole: '',
  emailUserVerify: '',
  maintenance: false,
  authenticated: false,
  loginLoading: false,
  whitelabel: {},
  template: '',
  plan: {},
  planFeatures: {},
  workspacesList: [],
  workspaceFavorite: null,
  workspaceOwners: [],
};

function messagesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER: {
      return { ...state, ...action.payload };
    }
    case SET_USER_TOKEN: {
      return { ...state, ...action.payload };
    }
    case SET_USER_EMAIL: {
      return { ...state, ...action.payload };
    }
    case SET_CHECKING_TOKEN: {
      return { ...state, ...action.payload };
    }
    case SET_LOADING_ME: {
      return { ...state, ...action.payload };
    }
    case SET_VERIFY: {
      return { ...state, ...action.payload };
    }
    case SET_VERIFY_EMAIL_USER: {
      return { ...state, ...action.payload };
    }
    case SET_MAINTENANCE: {
      return { ...state, ...action.payload };
    }
    case SET_WHITELABEL: {
      return { ...state, ...action.payload };
    }
    case SET_AUTHENTICATED: {
      return { ...state, authenticated: action.payload };
    }
    case SET_LOGIN_LOADING: {
      return { ...state, ...action.payload };
    }
    case SET_PLAN: {
      return { ...state, ...action.payload };
    }
    case SET_PLAN_FEATURES: {
      return { ...state, ...action.payload };
    }
    case SET_CURRENT_WORKSPACE: {
      return { ...state, ...action.payload };
    }
    case SET_CURRENT_ROLE: {
      return { ...state, ...action.payload };
    }
    case SET_WORKSPACES: {
      return { ...state, ...action.payload };
    }
    case SET_WORKSPACE_FAVORITE: {
      return { ...state, ...action.payload };
    }
    case SET_WORKSPACE_OWNERS: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}

export default messagesReducer;
