import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { percent } from '../../utils/numbersUtils';
import checkgreen from '../../assets/profile/checkgreen.svg';
import { t } from '../../i18n/i18n';

const SubSkillItem = ({ index, subSkill }) => (
  <OverlayTrigger
    placement="top"
    overlay={<Tooltip>{t(subSkill?.key ? `${subSkill?.key}-DES` : subSkill?.description)}</Tooltip>}
  >
    <li
      key={index}
      className="list-group-item border-0 px-0 py-2 mr-1 mr-md-3 item-collapse d-flex align-items-center"
    >
      <div className="d-flex">
        <span
          className={`align-content-lg-startticket-training-job-ready text-uppercase d-flex align-items-center ${
            subSkill?.approved
              ? 'skill-completed'
              : 'skill-incompleted skill-custom-advance-background'
          }`}
          data-percent={percent(subSkill?.currentScore, subSkill?.acceptanceScore)}
        >
          <span
            className={`font-weight-bold font-roboto ${
              !subSkill?.approved ? 'skill-custom-advance' : ''
            }`}
            data-percent={percent(subSkill?.currentScore, subSkill?.acceptanceScore)}
          >
            {t(subSkill?.key ?? subSkill?.displayName ?? subSkill?.name)}
            {subSkill?.approved && <img src={checkgreen} className="ml-2" alt={t('ICON_CHECK')} />}
          </span>
        </span>
      </div>
    </li>
  </OverlayTrigger>
);
export default SubSkillItem;
