const Chart = ({ classname }) => (
  <svg
    className={classname}
    width="27"
    height="25"
    viewBox="0 0 27 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.25"
      d="M7.00456 23.8203H2.67122C2.38391 23.8203 2.10836 23.715 1.90519 23.5274C1.70203 23.3399 1.58789 23.0855 1.58789 22.8203V14.8203C1.58789 14.5551 1.70203 14.3007 1.90519 14.1132C2.10836 13.9257 2.38391 13.8203 2.67122 13.8203H7.00456C7.29188 13.8203 7.56743 13.9257 7.77059 14.1132C7.97375 14.3007 8.08789 14.5551 8.08789 14.8203V22.8203C8.08789 23.0855 7.97375 23.3399 7.77059 23.5274C7.56743 23.715 7.29188 23.8203 7.00456 23.8203Z"
      fill="white"
    />
    <path
      d="M15.5163 23.3207H11.1829C10.8956 23.3207 10.6201 23.2173 10.4169 23.0331C10.2137 22.849 10.0996 22.5993 10.0996 22.3389V2.70252C10.0996 2.44213 10.2137 2.1924 10.4169 2.00827C10.6201 1.82414 10.8956 1.7207 11.1829 1.7207H15.5163C15.8036 1.7207 16.0791 1.82414 16.2823 2.00827C16.4855 2.1924 16.5996 2.44213 16.5996 2.70252V22.3389C16.5996 22.5993 16.4855 22.849 16.2823 23.0331C16.0791 23.2173 15.8036 23.3207 15.5163 23.3207Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M24.3366 23.8203H20.0033C19.7159 23.8203 19.4404 23.715 19.2372 23.5274C19.0341 23.3399 18.9199 23.0855 18.9199 22.8203V10.8203C18.9199 10.5551 19.0341 10.3007 19.2372 10.1132C19.4404 9.92567 19.7159 9.82031 20.0033 9.82031H24.3366C24.6239 9.82031 24.8995 9.92567 25.1026 10.1132C25.3058 10.3007 25.4199 10.5551 25.4199 10.8203V22.8203C25.4199 23.0855 25.3058 23.3399 25.1026 23.5274C24.8995 23.715 24.6239 23.8203 24.3366 23.8203Z"
      fill="white"
    />
  </svg>
);

export default Chart;
