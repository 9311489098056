import React from 'react';

const OnboardingIcon = ({ classNames }) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 625"
    x="0px"
    y="0px"
    width={28}
  >
    <path d="m379.1,152.35c-19.41,0-35.2,15.79-35.2,35.2s15.79,35.2,35.2,35.2,35.2-15.79,35.2-35.2-15.79-35.2-35.2-35.2Z" />
    <path d="m424.96,55.97H66.01c-8.35,0-15.15,6.8-15.15,15.15v224.96h32.81v-38.67h85.48v38.67h155.36v-7.37h-28.96l-50.5-41.9,27.79-33.49,38.4,31.87h125.05l3.81,2.59V71.12c0-8.35-6.8-15.15-15.15-15.15Zm-45.86,177.24c-25.18,0-45.66-20.48-45.66-45.66s20.48-45.66,45.66-45.66,45.66,20.48,45.66,45.66-20.48,45.66-45.66,45.66Z" />
    <rect x="94.14" y="267.87" width="64.57" height="26.79" />
    <path d="m48.58,344.05c-6.13,0-11.11-4.99-11.11-11.11v-26.4h287.05v37.51H48.58Z" />
    <polygon points="462.53 336.77 439.92 336.77 439.92 292.06 429.46 292.06 429.46 366.21 429.46 444.03 387.86 444.03 387.86 360.65 377.4 360.65 377.4 444.03 334.98 444.03 334.98 354.51 334.98 296.08 334.98 278.25 299.33 278.25 259.79 245.43 274.22 228.04 307.48 255.64 433.08 255.64 440.11 260.42 440.11 260.56 440.3 260.56 462.53 275.69 462.53 336.77" />
    <path d="m48.58,344.05c-6.13,0-11.11-4.99-11.11-11.11v-26.4h287.05v37.51H48.58Z" />
    <rect x="94.14" y="267.87" width="64.57" height="26.79" />
  </svg>
);

export default OnboardingIcon;
