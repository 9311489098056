const SaveIcon = ({ fill = '#52C0F7', ...props }) => (
  <svg
    width="24"
    height="24"
    {...props}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.875 0.625H2.375C1.40375 0.625 0.625 1.4125 0.625 2.375V14.625C0.625 15.5875 1.40375 16.375 2.375 16.375H14.625C15.5875 16.375 16.375 15.5875 16.375 14.625V4.125L12.875 0.625ZM8.5 14.625C7.0475 14.625 5.875 13.4525 5.875 12C5.875 10.5475 7.0475 9.375 8.5 9.375C9.9525 9.375 11.125 10.5475 11.125 12C11.125 13.4525 9.9525 14.625 8.5 14.625ZM11.125 5.875H2.375V2.375H11.125V5.875Z"
      fill={fill}
    />
  </svg>
);

export default SaveIcon;
