import React from 'react';

const CameraProctoringEnabled = ({ width = '20', height = '18' }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 102.5 98.97500000000001"
    x="0px"
    y="0px"
  >
    <g data-name="Layer 2">
      <g data-name="10 Camera">
        <path d="M93.52,79.18H9a9,9,0,0,1-9-9V18.58a9,9,0,0,1,9-9H28.86a2.47,2.47,0,0,0,2.08-1.13L34.44,3A6.57,6.57,0,0,1,40,0H93.52a9,9,0,0,1,9,9V70.2A9,9,0,0,1,93.52,79.18ZM9,12.1A6.49,6.49,0,0,0,2.5,18.58V70.2A6.49,6.49,0,0,0,9,76.68H93.52A6.49,6.49,0,0,0,100,70.2V9A6.49,6.49,0,0,0,93.52,2.5H40a4.08,4.08,0,0,0-3.44,1.88L33,9.82a4.94,4.94,0,0,1-4.18,2.28Z" />
        <path d="M32.64,27.94H1.25a1.25,1.25,0,0,1,0-2.5H32.64a1.25,1.25,0,0,1,0,2.5Z" />
        <path d="M32.64,59.26H1.25a1.25,1.25,0,0,1,0-2.5H32.64a1.25,1.25,0,0,1,0,2.5Z" />
        <path d="M101.25,59.26H73.94a1.25,1.25,0,0,1,0-2.5h27.31a1.25,1.25,0,0,1,0,2.5Z" />
        <path d="M101.25,27.94H73.94a1.25,1.25,0,0,1,0-2.5h27.31a1.25,1.25,0,0,1,0,2.5Z" />
        <path d="M23.09,12.1a1.25,1.25,0,0,1-1.25-1.25V6.31H14.2v4.54a1.25,1.25,0,0,1-2.5,0V6a2.17,2.17,0,0,1,2.17-2.17h8.3A2.17,2.17,0,0,1,24.34,6v4.87A1.25,1.25,0,0,1,23.09,12.1Z" />
        <path d="M53.29,69.52A27.17,27.17,0,1,1,80.46,42.35,27.2,27.2,0,0,1,53.29,69.52Zm0-51.84A24.67,24.67,0,1,0,78,42.35,24.7,24.7,0,0,0,53.29,17.68Z" />
        <path d="M53.29,62.58A20.23,20.23,0,1,1,73.52,42.35,20.26,20.26,0,0,1,53.29,62.58Zm0-38A17.73,17.73,0,1,0,71,42.35,17.76,17.76,0,0,0,53.29,24.62Z" />
        <path d="M65.09,43.6a1.25,1.25,0,0,1-1.25-1.25A10.56,10.56,0,0,0,53.29,31.8a1.25,1.25,0,0,1,0-2.5A13.06,13.06,0,0,1,66.34,42.35,1.25,1.25,0,0,1,65.09,43.6Z" />
        <path d="M92.05,18.68H78.82A2.88,2.88,0,0,1,76,15.81V11a2.88,2.88,0,0,1,2.87-2.87H92.05A2.88,2.88,0,0,1,94.92,11v4.77A2.88,2.88,0,0,1,92.05,18.68Zm-13.23-8a.38.38,0,0,0-.37.37v4.77a.38.38,0,0,0,.37.37H92.05a.38.38,0,0,0,.37-.37V11a.38.38,0,0,0-.37-.37Z" />
      </g>
    </g>
  </svg>
);

export default CameraProctoringEnabled;
