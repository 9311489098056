import { useRef } from 'react';
import useClickOutside from '../../hooks/useClickOutside';
import { t } from '../../i18n/i18n';
import closeIcon from '../../assets/screening/close-big-icon.svg';
import './modalCustom.css';
import HorizontalLoader from '../HorizontalLoader/HorizontalLoader';

const ModalCustom = ({
  title,
  subtitle,
  show,
  setShow,
  onOk,
  onCancel,
  onOkDisabled,
  onCancelText,
  onOkText,
  classModal,
  input,
  showCross = true,
  datatestid,
  datatestidbuttonok,
  datatestidbuttoncancel,
  showOkButton = true,
  okBtnClassname,
  noOkBtnClassname,
  htmlSubtitle,
  subtitleClassName,
  isLoading,
  noOkTxtClassname,
  onOkLeftIcon = null,
  disableClickOutside = false,
  onlyLoading = false,
}) => {
  const modalRef = useRef(null);

  useClickOutside(modalRef, () => {
    if (!disableClickOutside) setShow(false);
  });

  return (
    <div
      className={`modal d-flex justify-content-center position-fixed top-0 left-0 my-0 mx-auto ${
        show ? 'visible' : ''
      }`}
      data-testid={datatestid}
    >
      <div
        className={`modal__wrap d-flex flex-column container-modal-screening w-100 py-4 text-center ${classModal}`}
        ref={modalRef}
      >
        {showCross && (
          <span
            className="position-absolute top-0 right-0 mt-3 mr-3 cursor-pointer"
            aria-hidden="true"
            onClick={() => setShow(false)}
          >
            <img src={closeIcon} alt={t('CLOSE')} />
          </span>
        )}
        {onlyLoading ? (
          <HorizontalLoader />
        ) : (
          <div className="d-flex flex-column pt-4 pb-3 container-components-modal">
            {Boolean(title) && (
              <span className="h3 font-weight-bold font-montserrat text-base">{title}</span>
            )}
            {Boolean(subtitle) && !htmlSubtitle && (
              <span
                className={`my-3 h5 font-montserrat font-weight-500 gray-subtitle text-sm line-jump ${
                  subtitleClassName ?? 'px-2 px-lg-5'
                }`}
              >
                {subtitle}
              </span>
            )}
            {Boolean(subtitle) && htmlSubtitle && (
              <span
                dangerouslySetInnerHTML={{ __html: subtitle }}
                className={`my-3 h5 font-montserrat font-weight-500 gray-subtitle text-sm line-jump ${
                  subtitleClassName ?? 'px-2 px-lg-5'
                }`}
              />
            )}
            {input}
            <div className="d-flex flex-row justify-content-center mt-4">
              {onCancelText && (
                <button
                  type="button"
                  onClick={onCancel}
                  data-testid={datatestidbuttoncancel}
                  className={`button-left-modal px-1 px-lg-3 ${
                    showOkButton ? 'mr-3 mr-lg-5' : ''
                  } ${noOkBtnClassname || ''}`}
                >
                  <span
                    className={`px-3 py-1 text-button-left-modal font-roboto d-flex justify-content-center align-items-center ${
                      noOkTxtClassname || ''
                    }`}
                  >
                    {onCancelText}
                  </span>
                </button>
              )}
              {isLoading ? (
                <HorizontalLoader className="h-25 w-25 mt-3" />
              ) : (
                <>
                  {showOkButton && (
                    <button
                      type="button"
                      onClick={onOk}
                      className={`button-right-modal px-1 px-lg-4 ${okBtnClassname || ''}`}
                      disabled={onOkDisabled}
                      data-testid={datatestidbuttonok}
                    >
                      <div className="d-flex">
                        {onOkLeftIcon && <div className="mx-1">{onOkLeftIcon}</div>}
                        <span className="px-3 py-1 text-button-right-modal font-roboto d-flex justify-content-center align-items-center">
                          {onOkText}
                        </span>
                      </div>
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalCustom;
