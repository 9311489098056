import { ProcessStatusStage } from '../../constants/enums';
import { t } from '../../i18n/i18n';

const setStateCandidate = () => {
  const stateFilterMap = Object.values(ProcessStatusStage).map((filter) => ({
    ...filter,
    type: 'processStatus',
    name: t(`SCREENING_INVITATIONS_PROCESS_STATUS_${filter}`),
    id: filter,
    displayName: t(`SCREENING_INVITATIONS_PROCESS_STATUS_${filter}`),
  }));
  return stateFilterMap;
};

export default setStateCandidate;
