import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const useHubspotRoute = () => {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (_hsq && user && process.env.RAZZLE_RUNTIME_ENV === 'production') {
      _hsq.push(['setPath', location.pathname]);
      _hsq.push(['trackPageView']);
    }
  }, [location.pathname, user]);
};

export default useHubspotRoute;
