import './Categories.scss';

import { useEffect, useMemo, useState } from 'react';
import useCreateScreening from '../../hooks/useCreateScreening';
import { t } from '../../i18n/i18n';
import { inHtmlTag } from '../../utils/inHtmlTag';
import ModalSubmission from '../ModalSubmission/ModalSubmission';
import { TrackEvent } from '../../utils/filters/segmentUtils';
import SearchIcon from '../../assets/screening/SearchIcon';
import MultipleSelect from '../MultipleSelect/MultipleSelect';
import profileImageNull from '../../assets/profile/profile.png';

import {
  createCategory,
  deleteCategory,
  getCategories,
  getCategoriesAll,
  updateCategories,
} from '../../API/services/screeningService';
import { notification } from 'antd';
import { CategoryRow } from './CategoryRow';
import { DeleteCategoryModal } from './modal/DeleteCategoryModal';
import NewCategoryModal from './modal/NewCategoryModal';
import UpdateCategoryModal from './modal/UpdateCategoryModal';
import { ConfirmModal } from './modal/ConfirmModal';
import useTranslations from '../../hooks/useTranslations';
import { AutoScroll } from '../AutoScroll/AutoScroll';

const RowSelected = ({ category, onClickCancel = () => {} }) => (
  <div
    className="test-button-filters-selected d-flex justify-content-between align-items-center"
    style={{ backgroundColor: '#3A6EA5', color: 'white' }}
  >
    <div className="ml-2">
      <img
        className="user-heading-img rounded-circle p-"
        style={{ width: '50px', height: '50px', filter: 'invert(1)' }}
        src={category?.iconUrl || profileImageNull}
        alt={t('PROFILE_MENU_LOGO_USER')}
      />
    </div>
    <div className="m-2">
      <span>{category?.name}</span>
    </div>
    <div>
      <button
        type="button"
        data-testid="create-evaluation-second-step-button"
        className={`bg-transparent p-2 d-flex m-2 justify-content-center align-items-center  ${'test-button-filters'}`}
        style={{ borderColor: '#FFFF' }}
        onClick={onClickCancel}
      >
        <span
          className={`font-montserrat test-text-filters ${'test-text-filters-selected'}`}
          data-testid="create-evaluation-ss-filters-selected-span"
          style={{ color: 'white' }}
        >
          {t('SELECTED')}
        </span>
      </button>
    </div>
  </div>
);

const RowSwap = ({
  currentCategory,
  onClickSetCategorySelected = () => {},
  categorySelected,
  canSelect = true,
  onClickCancel = () => {},
  onAddCategory = () => {},
  onRemoveCategory = () => {},
  setModalDelete = () => {},
  onSelectCategoryToUpdate = () => {},
  onDragStart = () => {},
  onDragOver = () => {},
  onDrop = () => {},
  onDragEnd = () => {},
  overThis = false,
  draggable = false,
  buttonPlus = false,
}) => (
  <div
    draggable={draggable}
    onDrop={onDrop}
    onDragOver={onDragOver}
    onDragStart={onDragStart}
    onDragEnd={onDragEnd}
    style={{ outline: overThis ? '2px solid green' : '', cursor: draggable ? 'grab' : '' }}
  >
    {currentCategory?.id === categorySelected?.id ? (
      <RowSelected category={currentCategory} onClickCancel={onClickCancel} />
    ) : (
      <CategoryRow
        category={currentCategory}
        onClickSetCategorySelected={onClickSetCategorySelected}
        categorySelected={categorySelected}
        canSelect={canSelect}
        onAddCategory={onAddCategory}
        onRemoveCategory={onRemoveCategory}
        setModalDelete={setModalDelete}
        onSelectCategoryToUpdate={onSelectCategoryToUpdate}
        buttonPlus={buttonPlus}
      />
    )}
  </div>
);

const Categories = () => {
  const [categorySelected, setCategorySelected] = useState(null);
  const [itemDragging, setItemDragging] = useState(null);
  const [itemOver, setItemOver] = useState(null);
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategories2, setSubCategories2] = useState([]);
  const [categoryToUpdate, setCategoryToUpdate] = useState(null);
  const [showModalDelete, setShowModalDelete] = useState(null);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [showModalToCreate, setShowModalToCreate] = useState(null);
  const [filter, setFilter] = useState({ name: '' });
  const { key } = useTranslations();
  const [modalConfirm, setModalConfirm] = useState({
    title: '',
    description: '',
    show: false,
    category: {},
    onSubmit: () => {},
  });

  const updateModalConfirm = (data) => {
    setModalConfirm({ ...modalConfirm, ...data });
  };
  const sortParents = (array = []) =>
    array.sort((a, b) => {
      if (a.parentId === null) return -1;
      if (b.parentId === null) return 1;
      return Number(a.parentId) - Number(b.parentId);
    });

  const loadCategoriesColumns = (responseCategories, nameFilter = '') => {
    let categoriesCopy = responseCategories?.filter((item) => !item?.parent);

    let subCategoriesCopy = responseCategories?.filter(
      (item) => item?.parent && !item?.parent?.parentId
    );
    sortParents(subCategoriesCopy);

    let subCategories2Copy = responseCategories?.filter(
      (item) => item?.parent && item?.parent?.parentId
    );

    sortParents(subCategories2Copy);

    if (nameFilter) {
      const funcFilter = (item) =>
        item?.id === categorySelected?.id ||
        item?.name?.toLowerCase()?.includes(nameFilter?.toLowerCase()) ||
        (item?.languages && item?.languages[key]?.includes(nameFilter?.toLowerCase()));
      categoriesCopy = categoriesCopy?.filter(funcFilter);
      subCategoriesCopy = subCategoriesCopy?.filter(funcFilter);
      subCategories2Copy = subCategories2Copy?.filter(funcFilter);
    }

    setCategories(categoriesCopy);
    setSubCategories(subCategoriesCopy);
    setSubCategories2(subCategories2Copy);
    setCategoryToUpdate(null);
    if (categorySelected) {
      const findSelected = responseCategories?.find((item) => item?.id === categorySelected?.id);
      setCategorySelected(findSelected);
    }
  };

  const init = async () => {
    const responseCategories = await getCategoriesAll();
    setAllCategories(responseCategories);
    loadCategoriesColumns(responseCategories, filter?.name);
  };

  const onSelectCategoryToUpdate = (category) => {
    setCategoryToUpdate(category);
  };

  useEffect(() => {
    init();
  }, []);

  const onClickSetCategorySelected = (category) => {
    setCategorySelected(category);
  };

  const onClickCancel = () => {
    setCategorySelected(null);
  };
  const alertSuccesss = (message = t('CATEGORY_UPDATED_MESSAGE')) => {
    notification.success({
      message,
      type: 'success',
    });
  };
  const alertError = (messaje = t('ACTION_CANCELLED')) => {
    notification.error({
      message: messaje,
      type: 'error',
    });
  };
  const onAddCategory = async ({ parent, categoryToAdd }) => {
    if (categoryToAdd?.children?.length > 0) {
      alertError(
        t('CATEGORY_ASSOCIATES').replace(
          '{{name}}',
          categoryToAdd?.languages
            ? categoryToAdd?.languages[key] ?? categoryToAdd?.name
            : categoryToAdd?.name
        )
      );
      return;
    }
    if (categoryToAdd?.id === parent?.id) {
      alertError();
      return;
    }

    try {
      const response = await updateCategories(categoryToAdd?.id, { parentId: parent?.id });
      await init();
      alertSuccesss();
    } catch (error) {
      alertError('NO_UPDATED');
    }
  };
  const confirmOnSubmit = ({ category, parentId }) => {
    updateCategories(category?.id, { parentId })
      .then((response) => {
        init();
        alertSuccesss();
      })
      .catch(() => alertError(t('NO_UPDATED')))
      .finally(() => updateModalConfirm({ show: false }));
  };

  const onRemoveCategory = ({ categoryToRemove }) => {
    const parentId = categoryToRemove?.parent?.parentId ?? null;

    if (categoryToRemove?.children?.length > 0) {
      updateModalConfirm({
        show: true,
        onSubmit: () => confirmOnSubmit({ category: categoryToRemove, parentId }),
        category: categoryToRemove,
        title: t('DO_YOU_WANT_CONTINUE'),
        description: t('CATEGORY_ASSOCIATES')?.replace('{{name}}', categoryToRemove?.name),
      });
    } else {
      confirmOnSubmit({ category: categoryToRemove, parentId });
    }
  };

  const createNewCategory = (_newCategory, newFile) => {
    createCategory({ ..._newCategory, parentId: null }, newFile)
      .then((response) => {
        if (!response) {
          alertError();
          return;
        }
        init();
        alertSuccesss();
      })
      .catch(() => alertError())
      .finally(() => setShowModalToCreate(false));
  };
  const updateCategory = (_updateCategory, updateFile) => {
    updateCategories(categoryToUpdate?.id, { ..._updateCategory }, updateFile)
      .then((response) => {
        if (!response) {
          alertError();
          return;
        }
        init();
        alertSuccesss();
      })
      .catch(() => alertError())
      .finally(() => setShowModalToCreate(false));
  };

  const onDeleteCategory = () => {
    deleteCategory(categoryToDelete?.id)
      .then((response) => {
        if (!response) {
          alertError(t('CATEGORY_CANCELLED_MESSAGE'));
          return;
        }
        alertSuccesss(t('DELETED_SUCCESS'));
        setShowModalDelete(false);
        init();
      })
      .catch(() => {
        alertError(t('CATEGORY_CANCELLED_MESSAGE'));
        setShowModalDelete(false);
      });
  };
  const onClickDeleteCategory = (_categoryToDelete) => {
    setShowModalDelete(true);
    setCategoryToDelete(_categoryToDelete);
  };

  const onClickShowModal = (category, show) => {
    setShowModalDelete(show);
    setCategoryToDelete(category);
  };

  const handleInput = (e) => {
    loadCategoriesColumns(allCategories, e?.target?.value);
    setFilter({ ...filter, name: e?.target?.value });
  };
  return (
    <div data-testid="create-evaluation-second-step" className="d-flex flex-column h-100">
      <AutoScroll isDragging={itemDragging} scrollAreaHeight={500} />
      <span
        className="font-weight-600 font-montserrat create-evaluation-step-title text-light-black mb-3"
        data-testid="create-evaluation-ss-title-span"
      >
        {t('CATEGORY_PAGE_TITLE')}
      </span>
      <div className="d-flex">
        <span
          className="font-montserrat font-weight-600 text-base text-light-black justify-content-start"
          data-testid="create-evaluation-ss-test-title-span"
        >
          {t('CATEGORY_QUESTION')}
        </span>
        <div className="flex-fill" />
      </div>

      <span className="text-light-black font-weight-400 text-base my-2 font-montserrat">
        {t('CATEGORY_DESCRIPTION_PAGE')}
        <p className="text-light-black font-weight-400 text-base my-2 font-montserrat">
          {t('CATEGORY_DESCRIPTION_OPTION1_PAGE')}
        </p>
        <p className="text-light-black font-weight-400 text-base my-2 font-montserrat">
          {t('CATEGORY_DESCRIPTION_OPTION2_PAGE')}
        </p>
        <p className="text-light-black font-weight-400 text-base my-2 font-montserrat">
          {t('CATEGORY_DESCRIPTION_OPTION3_PAGE')}
        </p>
      </span>

      <div className="test-button-filters-selected d-flex justify-content-between align-items-center my-3 w-100">
        <div className="d-flex justify-content-start">
          <div className="w-100">
            {' '}
            <button
              type="button"
              data-testid="create-evaluation-second-step-button"
              className={` p-2 d-flex m-2 justify-content-center align-items-center  ${'test-button-filters'}`}
              style={{ backgroundColor: '#449cc8' }}
              onClick={() => setShowModalToCreate(true)}
            >
              <span
                className={`font-montserrat test-text-filters ${'test-text-filters-selected'} text-white`}
                data-testid="create-evaluation-ss-filters-selected-span"
              >
                {t('NEW_CATEGORY')}
              </span>
            </button>
          </div>
          <div
            className="d-flex align-items-center justify-content-start pr-4 w-100"
            style={{ minWidth: 300 }}
          >
            <div
              className={`my-1 my-sm-0 rounded transition-2-ms p-2 d-flex align-items-center w-100 tests-search-input-full bg-white `}
            >
              <SearchIcon testid="alkemy-tests-search-icon" />
              <input
                onChange={handleInput}
                value={filter?.name}
                className="font-montserrat text-xs w-100 bg-transparent border-0 outline-none ml-2"
                style={{ flex: '1' }}
                placeholder={t('CATEGORY_ABM_SEARCH_PLACEHOLDER')}
                type="text"
                data-testid="alkemy-tests-search-input"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="w-100 mx-auto h-100 bg-gray p-4 d-flex justify-content-around"
        style={{ gap: 10 }}
      >
        <div className="w-100 ">
          <h2 className="text-center mb-2 ">{t('COLUMN_CATEGORY')}</h2>
          <div
            className="border border-1 border-grey w-100 d-flex flex-column p-2"
            style={{ gap: 10 }}
          >
            {categories?.map((item, index) => (
              <RowSwap
                key={index}
                currentCategory={item}
                categorySelected={categorySelected}
                onClickSetCategorySelected={onClickSetCategorySelected}
                onClickCancel={onClickCancel}
                onAddCategory={onAddCategory}
                setModalDelete={onClickShowModal}
                onSelectCategoryToUpdate={onSelectCategoryToUpdate}
                onDragStart={(e) => {
                  setItemDragging(item);
                  setCategorySelected(item);
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  setItemOver(item);
                }}
                onDragEnd={(e) => {
                  e.preventDefault();
                  setItemOver(null);
                  setItemDragging(null);
                }}
                onDrop={(e) => {
                  onAddCategory({ parent: item, categoryToAdd: itemDragging });
                }}
                overThis={itemOver?.id === item?.id}
                draggable={categorySelected && categorySelected?.id === item?.id}
              />
            ))}
          </div>
        </div>
        <div className="w-100 ">
          <h2 className="text-center">{t('COLUMN_SUBCATEGORY')}</h2>
          <div
            className="border border-1 border-grey w-100 d-flex flex-column p-2"
            style={{ gap: 10 }}
          >
            {subCategories?.map((item, index) => (
              <RowSwap
                key={index}
                currentCategory={item}
                categorySelected={categorySelected}
                onClickSetCategorySelected={onClickSetCategorySelected}
                onClickCancel={onClickCancel}
                onAddCategory={onAddCategory}
                onRemoveCategory={onRemoveCategory}
                setModalDelete={onClickShowModal}
                onSelectCategoryToUpdate={onSelectCategoryToUpdate}
                onDragStart={(e) => {
                  setItemDragging(item);
                  setCategorySelected(item);
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  setItemOver(item);
                }}
                onDragEnd={(e) => {
                  e.preventDefault();
                  setItemOver(null);
                  setItemDragging(null);
                }}
                onDrop={(e) => {
                  onAddCategory({ parent: item, categoryToAdd: itemDragging });
                }}
                overThis={itemOver?.id === item?.id}
                draggable={categorySelected && categorySelected?.id === item?.id}
              />
            ))}
          </div>
        </div>
        <div className="w-100 ">
          <h2 className="text-center">{t('COLUMN_SUBCATEGORY_2')}</h2>
          <div
            className="border border-1 border-grey w-100 d-flex flex-column p-2"
            style={{ gap: 10 }}
          >
            {subCategories2?.map((item, index) => (
              <RowSwap
                key={index}
                currentCategory={item}
                categorySelected={categorySelected}
                onClickSetCategorySelected={onClickSetCategorySelected}
                onClickCancel={onClickCancel}
                onAddCategory={onAddCategory}
                onRemoveCategory={onRemoveCategory}
                setModalDelete={onClickShowModal}
                onSelectCategoryToUpdate={onSelectCategoryToUpdate}
                onDragStart={(e) => {
                  setItemDragging(item);
                  setCategorySelected(item);
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  setItemOver(item);
                }}
                onDragEnd={(e) => {
                  e.preventDefault();
                  setItemOver(null);
                  setItemDragging(null);
                }}
                onDrop={(e) => {
                  alertError();
                }}
                draggable={categorySelected && categorySelected?.id === item?.id}
              />
            ))}
          </div>
        </div>
      </div>
      {showModalDelete && (
        <DeleteCategoryModal
          showModal={showModalDelete}
          setShowModal={setShowModalDelete}
          submitAction={onDeleteCategory}
          categoryToDelete={categoryToDelete}
        />
      )}
      {showModalToCreate && (
        <NewCategoryModal
          showModal={showModalToCreate}
          setShowModal={setShowModalToCreate}
          onSubmit={createNewCategory}
        />
      )}
      {categoryToUpdate && (
        <UpdateCategoryModal
          showModal={categoryToUpdate}
          setShowModal={(value) => !value && setCategoryToUpdate(null)}
          onSubmit={updateCategory}
          onClickDeleteCategory={onClickDeleteCategory}
          categoryToUpdate={categoryToUpdate}
        />
      )}
      <ConfirmModal
        showModal={modalConfirm.show}
        category={modalConfirm?.category}
        setShowModal={(value) => updateModalConfirm({ show: value })}
        submitAction={modalConfirm?.onSubmit}
        title={modalConfirm.title}
        description={modalConfirm.description}
      />
    </div>
  );
};

export default Categories;
