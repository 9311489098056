import React from 'react';
import rocketWithSuccess from '../../assets/lottie/rocket-with-success-animation.json';
import { t } from '../../i18n/i18n';
import PaymentStatus from './PaymentStatus';

const PaymentSuccess = () => {
  const options = {
    animationData: rocketWithSuccess,
    loop: true,
  };

  return (
    <PaymentStatus
      options={options}
      title={t('PAYMENT_SUCCESS_TITLE')}
      subtitle={t('PAYMENT_SUCCESS_SUBTITLE')}
    />
  );
};

export default PaymentSuccess;
