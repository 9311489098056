import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { capitalizeString } from '../../utils/stringUtils';
import { t } from '../../i18n/i18n';
import { CREATE_TEMPLATE, PROFILES } from '../../constants/routes';
import Bookmark from '../../assets/profileItem/Bookmark';
import ContactComponent from '../ContactComponent/ContactComponent';
import {
  deleteFavoriteProfile,
  getDuplicateInvitations,
  saveFavoriteProfile,
} from '../../API/services/alkymersService';
import CountryFlag from '../CountryFlag/CountryFlag';
import { spaceSeparatedIncludes } from '../../utils/spaceSeparatedIncludes';
import { setProfiles } from '../../redux/actions/profilesActions';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import './ProfileListItem.scss';
import RecruitSessionDropdown from '../RecruitSessionDropdown/RecruitSessionDropdown';
import {
  setEmailsData,
  setNewSearchData,
  setSearchWasAlreadyCreated,
  setSendEmailFrom,
  setSubjectEmail,
} from '../../redux/actions/screeningActions';
import EmailIcon from '../../assets/invitedProfile/EmailIcon';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ProfileListItem = ({
  className = '',
  name,
  firstName,
  lastName,
  percent = 0,
  profileId,
  email,
  technology,
  country,
  english,
  alkymer,
  containerSize,
  dispatchFn = setProfiles,
  stateKey = 'profiles',
  confirmDelete,
  recruitSessionStages,
  disabled,
}) => {
  const dispatch = useDispatch();
  const profilesState = useSelector((state) => state.profiles);
  const history = useHistory();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const { newSearch } = useSelector((state) => state.screenings);

  const deleteFavorite = () =>
    deleteFavoriteProfile(alkymer.id).then(() => {
      const updatedProfiles = profilesState[stateKey]?.map((profile) =>
        profile.id === alkymer.id ? { ...profile, favorite: false } : profile
      );
      dispatch(dispatchFn(updatedProfiles));
    });

  const handleSaveProfile = () => {
    if (alkymer.favorite) {
      if (!showConfirmDelete && confirmDelete) setShowConfirmDelete(true);
      if (!confirmDelete) deleteFavorite();
    } else {
      saveFavoriteProfile(alkymer.id).then(() => {
        const updatedProfiles = profilesState[stateKey]?.map((profile) =>
          profile.id === alkymer.id ? { ...profile, favorite: true } : profile
        );
        dispatch(dispatchFn(updatedProfiles));
        notification.open({
          message: t('SAVED_PROFILE_SUCCESS').replace('{name}', alkymer?.firstName),
          icon: <i className="fas fa-check" style={{ color: '#58DDA6' }} />,
        });
      });
    }
  };

  const createAssessmentInvitationAction = async (body) => {
    let candidate = [];
    try {
      candidate = await getDuplicateInvitations(body?.id, [email]);
    } catch (e) {
      notification.open({
        message: t('ERROR_EVALUATION'),
        type: 'error',
      });
      return;
    }
    if (candidate?.length > 0) {
      notification.open({
        message: t('DUPLICATE_INVITATION_ERROR_NOTIFICATION_SINGULAR'),
        type: 'error',
      });
    } else {
      dispatch(setSendEmailFrom('edit'));
      const selectedSearch = {
        name: body?.name,
        stages: [
          {
            id: body?.id,
            levelId: body?.levelId,
            technologyId: body?.technologyId,
          },
        ],
      };

      const data = {
        v4_id: {
          firstName,
          lastName,
          email,
        },
      };
      dispatch(
        setNewSearchData({
          ...newSearch,
          ...selectedSearch,
          step: 4,
        })
      );
      dispatch(setSubjectEmail({ subjectEmail: '' }));
      dispatch(setSearchWasAlreadyCreated(true));
      history.push(CREATE_TEMPLATE);
      dispatch(setEmailsData(data));
    }
  };

  return (
    <div
      onClick={() => history.push(`${PROFILES}/${profileId}`)}
      onKeyPress={() => {}}
      role="button"
      tabIndex={0}
      className={`profiles-list-item-container font-montserrat py-2 px-3 pr-2 font-roboto font-weight-bold d-flex align-items-center position-relative ${className} ${
        !spaceSeparatedIncludes(containerSize, '450') && 'flex-column w-100'
      }`}
    >
      <span
        className={`profile-list-item-name overflow-hidden profiles-list-name-width ${
          !spaceSeparatedIncludes(containerSize, '450') && 'py-2 mb-2 border-bottom'
        }`}
      >
        <Link className="dashboard-name-link" to={`${PROFILES}/${profileId}`}>
          {capitalizeString(name)}
        </Link>
      </span>
      <div
        className={`d-flex align-items-center flex-grow-1 justify-content-between ${
          !spaceSeparatedIncludes(containerSize, '450') && 'w-100 py-2'
        }`}
      >
        <div className="profile-list-data-item ml-2 d-flex justify-content-center profiles-list-score-width">
          <span
            className="profiles-list-item-percent position-relative font-roboto d-flex justify-content-center align-items-center"
            data-color="yellow"
          >
            <span
              className={`profiles-list-item-percent-subitem 
              ${
                percent < 75
                  ? `mix-yellow-${Math.floor(percent)}`
                  : `mix-green-${Math.floor(percent) - 75}`
              }`}
            />
            {percent}
          </span>
        </div>
        <div className="profile-list-data-item text-center ml-2 d-flex justify-content-center profiles-list-tech-width">
          <span className="d-flex align-items-center justify-content-center">
            {technology?.displayName}
          </span>
        </div>
        <div className="profile-list-data-item text-nowrap ml-2 text-truncate justify-content-center align-items-center profiles-list-english-width text-center">
          {english[0]?.level?.displayName
            ? spaceSeparatedIncludes(containerSize, '800')
              ? t(english[0]?.level?.displayName)
              : english[0]?.level?.displayName
            : '-'}
        </div>
        <div className="profile-list-data-item text-center ml-2 d-flex justify-content-center profiles-list-country-width align-items-center">
          {spaceSeparatedIncludes(containerSize, '700') && (
            <span className="d-flex align-items-center justify-content-center mr-2">
              {country?.country?.isoCode}
            </span>
          )}
          <CountryFlag countryIso={country?.country?.isoCode} size="sm" />
        </div>
        {spaceSeparatedIncludes(containerSize, '500') && (
          <div
            className="profile-list-data-item text-center d-flex justify-content-center profiles-list-actions-width"
            onClick={(e) => e.stopPropagation()}
            onKeyPress={() => {}}
            role="button"
            tabIndex={0}
          >
            <span className="d-flex align-items-center justify-content-center">
              <RecruitSessionDropdown
                options={recruitSessionStages || []}
                setOpenDropdown={setOpenDropdown}
                openDropdown={openDropdown}
                createAssessmentInvitationAction={createAssessmentInvitationAction}
                disabled={disabled}
              />
              <ContactComponent
                className="border-0"
                alkymer={alkymer}
                title={t('CONTACT_BY_EMAIL')}
              />
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{t('SAVE_PROFILE_CANDIDATE')}</Tooltip>}
              >
                <div
                  onClick={() => handleSaveProfile()}
                  type="button"
                  className="bg-transparent border-0 outline-none bg-danger"
                  data-tour-id="profile-save"
                  aria-hidden="true"
                >
                  <Bookmark className="m-2" on={alkymer.favorite} />
                  <ConfirmModal
                    show={showConfirmDelete}
                    setShow={setShowConfirmDelete}
                    callback={deleteFavorite}
                    text={t('FAVORITES_CONFIRM_MODAL_TEXT')}
                    confirmButtonText={t('FAVORITES_CONFIRM_MODAL_BUTTON')}
                    cancelButtonText={t('FAVORITES_CANCEL_MODAL_BUTTON')}
                  />
                </div>
              </OverlayTrigger>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileListItem;
