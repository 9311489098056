import { put, takeLatest } from 'redux-saga/effects';
import { checkToken } from '../actions/authActions';
import { HANDLE_ERROR } from '../actionTypes';
import history from '../../history';
import { HOME, ERROR } from '../../constants/routes';

function* handleErrorSaga({ payload: { error } }) {
  const { data, status } = error.response;
  switch (status) {
    case 500:
      yield history.replace(`${ERROR}/500`);
      break;
    case 404:
      yield history.replace(`${ERROR}/404`);
      break;
    case 401:
      yield put(checkToken());
      yield history.push(HOME);
      break;
    default:
  }
  return { data, status };
}

function* errorsSagas() {
  yield takeLatest(HANDLE_ERROR, handleErrorSaga);
}

export default errorsSagas();
