import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FilterItem from '../FilterItem/FilterItem';

const SearchModalList = ({ keys, grouped, addFilter, closeModal, type, removeAppliedFilter }) => {
  const query = useSelector((state) => state.profiles.query);
  const [filtersQuery, setFiltersQuery] = useState([]);

  useEffect(() => {
    setFiltersQuery(query[type]?.split(',') ?? []);
  }, [query]);

  return (
    <div>
      {keys.length
        ? keys.map((key, i) => (
            <div key={i}>
              <h5 className="mr-3">{key.toUpperCase()}</h5>
              {grouped[key]
                ? grouped[key].map((filter, i) => (
                    <FilterItem
                      className="search-modal-filter-item mr-3 small"
                      key={i}
                      filter={filter}
                      addFilter={addFilter}
                      type={type}
                      count="state_count"
                      tag="state_name"
                      value="state_id"
                      closeModal={closeModal}
                      removeAppliedFilter={removeAppliedFilter}
                      selected={filtersQuery.includes(String(filter.id))}
                    />
                  ))
                : null}
              <hr />
            </div>
          ))
        : null}
    </div>
  );
};

export default SearchModalList;
