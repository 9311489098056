import React from 'react';

const CustomizeWorkspace = ({ className }) => (
  <svg
    className={className}
    width="65"
    height="64"
    viewBox="0 0 65 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3716 22.9014C21.6957 22.9014 26.8223 17.7748 26.8223 11.4507C26.8223 5.12666 21.6957 0 15.3716 0C9.04756 0 3.9209 5.12666 3.9209 11.4507C3.9209 17.7748 9.04756 22.9014 15.3716 22.9014Z"
      fill="#6C63FF"
    />
    <path d="M64.0879 63.821H17.9272V64H64.0879V63.821Z" fill="#CACACA" />
    <path
      d="M56.5165 27.9616C56.5933 27.7126 56.6145 27.4497 56.5788 27.1916C56.543 26.9335 56.4511 26.6863 56.3095 26.4675C56.1679 26.2488 55.9801 26.0637 55.7592 25.9254C55.5384 25.787 55.2899 25.6988 55.0313 25.6669L46.5123 15.7123L44.5254 17.7985L53.0109 27.3429C52.9861 27.781 53.1228 28.2131 53.3952 28.5571C53.6676 28.9012 54.0568 29.1334 54.4889 29.2096C54.9211 29.2859 55.3663 29.201 55.74 28.971C56.1137 28.7409 56.3901 28.3818 56.5167 27.9616H56.5165Z"
      fill="#A0616A"
    />
    <path
      d="M49.2838 17.2665C49.2925 17.1596 49.278 17.052 49.2413 16.9511C49.2047 16.8503 49.1467 16.7585 49.0713 16.6822L46.4395 14.0135C46.2794 13.7931 46.0775 13.6065 45.8453 13.4641C45.6131 13.3218 45.3551 13.2266 45.0861 13.184C44.8171 13.1413 44.5424 13.1521 44.2775 13.2156C44.0127 13.2792 43.763 13.3943 43.5426 13.5543C43.3223 13.7144 43.1356 13.9163 42.9932 14.1485C42.8509 14.3807 42.7557 14.6387 42.7131 14.9077C42.6704 15.1767 42.6812 15.4514 42.7447 15.7163C42.8083 15.9811 42.9234 16.2308 43.0835 16.4512L44.8337 19.7794C44.8836 19.8744 44.9535 19.9575 45.0384 20.023C45.1234 20.0886 45.2215 20.1351 45.326 20.1593C45.4305 20.1836 45.5391 20.185 45.6442 20.1635C45.7494 20.142 45.8486 20.0981 45.9353 20.0348L48.9808 17.8092C49.0674 17.7459 49.1392 17.6646 49.1915 17.5709C49.2438 17.4773 49.2753 17.3734 49.2838 17.2665Z"
      fill="#E6E6E6"
    />
    <path
      d="M43.1038 11.1586C45.3549 11.1586 47.1797 9.33375 47.1797 7.08267C47.1797 4.83158 45.3549 3.00671 43.1038 3.00671C40.8527 3.00671 39.0278 4.83158 39.0278 7.08267C39.0278 9.33375 40.8527 11.1586 43.1038 11.1586Z"
      fill="#A0616A"
    />
    <path
      d="M45.3204 25.3223C45.4014 25.2621 45.4692 25.1859 45.5196 25.0984C45.57 25.0109 45.6019 24.9141 45.6134 24.8138C45.8953 22.4582 46.3055 20.1198 46.8422 17.8089C47.0798 16.7705 46.9322 15.6811 46.427 14.7433C45.9218 13.8055 45.0933 13.083 44.0955 12.7101C43.0976 12.3372 41.9983 12.3392 41.0019 12.7158C40.0055 13.0925 39.1796 13.8181 38.6779 14.7577C37.075 17.7662 36.9525 21.8965 36.9742 23.5326C36.9767 23.7073 37.0404 23.8755 37.1542 24.008C37.2681 24.1404 37.4248 24.2287 37.5971 24.2574L44.7488 25.4612C44.7905 25.4683 44.8327 25.4718 44.875 25.4718C45.0358 25.4718 45.1923 25.4193 45.3207 25.3224L45.3204 25.3223Z"
      fill="#E6E6E6"
    />
    <path
      d="M29.8798 12.0508C29.9002 12.1403 29.9134 12.2313 29.9195 12.323L37.0484 16.4356L38.7812 15.4381L40.6284 17.8565L37.7325 19.9204C37.4985 20.0872 37.2168 20.1736 36.9296 20.1666C36.6423 20.1596 36.3651 20.0596 36.1396 19.8816L28.7886 14.0823C28.4569 14.2051 28.0956 14.224 27.7529 14.1365C27.4101 14.0489 27.1021 13.8591 26.8699 13.5923C26.6377 13.3255 26.4922 12.9943 26.4527 12.6427C26.4133 12.2912 26.4819 11.936 26.6493 11.6243C26.8167 11.3127 27.075 11.0594 27.3898 10.8982C27.7047 10.7369 28.0611 10.6754 28.4118 10.7216C28.7626 10.7679 29.0908 10.9199 29.3531 11.1573C29.6153 11.3948 29.799 11.7064 29.8798 12.0508Z"
      fill="#A0616A"
    />
    <path
      d="M37.2869 16.6305L39.5909 19.6164C39.6565 19.7013 39.7396 19.7712 39.8346 19.8211C39.9295 19.8711 40.0342 19.8999 40.1413 19.9058C40.2484 19.9117 40.3556 19.8944 40.4555 19.8551C40.5553 19.8159 40.6456 19.7555 40.72 19.6782L43.3284 16.9697C43.5435 16.8027 43.7237 16.595 43.8585 16.3583C43.9934 16.1217 44.0803 15.8608 44.1144 15.5906C44.1484 15.3204 44.1289 15.0461 44.0569 14.7834C43.985 14.5208 43.862 14.2748 43.695 14.0597C43.5279 13.8445 43.3202 13.6644 43.0836 13.5295C42.847 13.3947 42.5861 13.3077 42.3159 13.2737C42.0456 13.2397 41.7714 13.2592 41.5087 13.3311C41.246 13.4031 41.0001 13.5261 40.7849 13.6931L37.5135 15.5225C37.4198 15.5749 37.3386 15.6469 37.2753 15.7336C37.2119 15.8202 37.168 15.9195 37.1465 16.0246C37.125 16.1298 37.1264 16.2383 37.1506 16.3429C37.1749 16.4474 37.2213 16.5455 37.2869 16.6305Z"
      fill="#E6E6E6"
    />
    <path
      d="M33.6952 62.0277H31.6606L31.1816 54.2055L33.6955 54.1802L33.6952 62.0277Z"
      fill="#A0616A"
    />
    <path
      d="M34.2139 61.3633H30.2072C29.8719 61.3633 29.5398 61.4294 29.23 61.5577C28.9202 61.686 28.6387 61.8741 28.4016 62.1112C28.1645 62.3484 27.9765 62.6299 27.8481 62.9397C27.7198 63.2495 27.6538 63.5815 27.6538 63.9168V63.9997H34.214L34.2139 61.3633Z"
      fill="#2F2E41"
    />
    <path d="M53.1118 62.0277H51.0772L50.1094 54.1801H53.1126L53.1118 62.0277Z" fill="#A0616A" />
    <path
      d="M53.6304 61.3633H49.6237C49.2884 61.3633 48.9563 61.4294 48.6465 61.5577C48.3367 61.686 48.0553 61.8741 47.8181 62.1112C47.581 62.3484 47.393 62.6299 47.2646 62.9397C47.1363 63.2495 47.0703 63.5815 47.0703 63.9168V63.9997H53.6305L53.6304 61.3633Z"
      fill="#2F2E41"
    />
    <path
      d="M50.8941 57.9521L53.4189 57.6229C53.5244 57.6091 53.6258 57.573 53.7162 57.5168C53.8066 57.4607 53.8839 57.3859 53.9431 57.2975C54.0022 57.209 54.0418 57.1089 54.0591 57.0039C54.0764 56.8989 54.0711 56.7915 54.0434 56.6887L50.3605 38.5896C49.9943 36.8325 49.741 35.0538 49.602 33.2643C49.4945 31.8084 49.0683 29.7495 47.6484 28.2096C47.071 27.5779 46.532 26.9121 46.0345 26.2157L44.9196 24.6718L37.4932 23.4908L37.4882 23.5827C37.1673 29.4841 35.6345 35.2563 32.9851 40.5394C31.7934 42.9155 31.1093 45.5136 30.9765 48.1685L30.5556 56.3752C30.5504 56.4791 30.5669 56.5831 30.6042 56.6803C30.6414 56.7775 30.6985 56.8659 30.7719 56.9397C30.845 57.014 30.933 57.072 31.0302 57.1098C31.1273 57.1476 31.2313 57.1645 31.3354 57.1592L33.7492 57.0494C33.9128 57.0418 34.0694 56.9806 34.1949 56.8754C34.3204 56.7701 34.4079 56.6266 34.444 56.4668L36.4593 47.4756C36.4774 47.3952 36.5122 47.3196 36.5614 47.2535L42.886 38.8209C42.9311 38.7607 42.9917 38.714 43.0613 38.6856C43.131 38.6573 43.207 38.6484 43.2813 38.6598C43.3556 38.6713 43.4254 38.7028 43.4832 38.7509C43.541 38.7989 43.5846 38.8618 43.6095 38.9327L50.0932 57.4575C50.1449 57.6034 50.2405 57.7298 50.3669 57.8194C50.4932 57.9089 50.6441 57.9572 50.799 57.9576C50.8308 57.9576 50.8626 57.9555 50.8942 57.9514L50.8941 57.9521Z"
      fill="#2F2E41"
    />
    <path
      d="M41.948 5.93385C41.733 6.65209 40.9105 7.09507 40.1707 6.97356C39.4309 6.85206 38.8225 6.24284 38.5977 5.52756C38.3945 4.79704 38.4777 4.01651 38.8303 3.34524C39.0246 2.94292 39.2962 2.58285 39.6298 2.28562C39.9633 1.9884 40.3522 1.75987 40.7742 1.61309C41.1986 1.47243 41.6507 1.43584 42.0922 1.5064C42.5338 1.57695 42.9519 1.7526 43.3114 2.01852C43.3497 1.85716 43.4303 1.70892 43.5449 1.58905C43.6595 1.46919 43.804 1.38203 43.9634 1.33656C44.2846 1.25001 44.6236 1.25503 44.942 1.35104C45.8597 1.61255 46.6548 2.19101 47.1861 2.98358C47.7106 3.7737 48.0576 4.66814 48.2032 5.60529C48.4536 7.01068 48.3612 8.54635 47.5719 9.7358C46.7826 10.9253 45.168 11.6276 43.858 11.0606C43.7763 10.643 43.9698 10.2225 44.1938 9.8607C44.4177 9.49893 44.6829 9.14637 44.769 8.72919C44.8551 8.31202 44.6901 7.80273 44.287 7.66642C43.9431 7.5501 43.5765 7.73854 43.2142 7.7616C43.0085 7.77452 42.8029 7.73311 42.6182 7.64152C42.4335 7.54993 42.2762 7.41138 42.1619 7.23977C42.0477 7.06816 41.9806 6.86952 41.9673 6.66378C41.9541 6.45805 41.9952 6.25245 42.0865 6.06762L41.948 5.93385Z"
      fill="#2F2E41"
    />
    <rect y="31.5844" width="22.9019" height="22.9019" rx="11.4509" fill="#E6E6E6" />
    <path
      d="M15.7715 37.6876H16.7383V38.6544H15.7715V37.6876ZM15.2881 36.2373C14.7533 36.2373 14.3212 36.6694 14.3212 37.2041H8.52019C8.52019 36.6694 8.08813 36.2373 7.55335 36.2373H5.61967C5.08489 36.2373 4.65283 36.6694 4.65283 37.2041V39.1378C4.65283 39.6726 5.08489 40.1047 5.61967 40.1047V45.9057C5.08489 45.9057 4.65283 46.3378 4.65283 46.8725V48.8062C4.65283 49.341 5.08489 49.7731 5.61967 49.7731H7.55335C8.08813 49.7731 8.52019 49.341 8.52019 48.8062H14.3212C14.3212 49.341 14.7533 49.7731 15.2881 49.7731H17.2217C17.7565 49.7731 18.1886 49.341 18.1886 48.8062V46.8725C18.1886 46.3378 17.7565 45.9057 17.2217 45.9057V40.1047C17.7565 40.1047 18.1886 39.6726 18.1886 39.1378V37.2041C18.1886 36.6694 17.7565 36.2373 17.2217 36.2373H15.2881ZM7.55335 40.1047C8.08813 40.1047 8.52019 39.6726 8.52019 39.1378H14.3212C14.3212 39.6726 14.7533 40.1047 15.2881 40.1047V45.9057C14.7533 45.9057 14.3212 46.3378 14.3212 46.8725H8.52019C8.52019 46.3378 8.08813 45.9057 7.55335 45.9057V40.1047ZM6.10309 47.356H7.06993V48.3228H6.10309V47.356ZM15.7715 48.3228V47.356H16.7383V48.3228H15.7715ZM6.10309 38.6544V37.6876H7.06993V38.6544H6.10309Z"
      fill="#6C63FF"
    />
  </svg>
);

export default CustomizeWorkspace;
