import { useHistory } from 'react-router-dom';
import ConfigIcon from '../../assets/cardWorkspace/ConfigIcon';
import EvaluationIcon from '../../assets/cardWorkspace/EvaluationIcon';
import LeaveIcon from '../../assets/cardWorkspace/LeaveIcon';
import MembersIcon from '../../assets/cardWorkspace/MembersIcon';
import AddInvitations from '../../assets/search/AddInvitations';
import StarComplete from '../../assets/tests/StarComplete';
import StarEmpty from '../../assets/tests/StarEmpty';
import { PartnerWorkspaceRole } from '../../constants/enums';
import { t } from '../../i18n/i18n';
import { determineIconType } from '../../utils/determineIconWorkspace/determineIconWorkspace';
import './CardWorkspace.scss';
import { CONFIGURATION, CONFIGURATION_MEMBERS } from '../../constants/routes';
import { addTruncate } from '../../utils/addTruncate';
import TransferOwnership from '../../assets/search/TransferOwnership';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const CardWorkspace = ({
  workspace,
  addFavorite,
  removeFavorite,
  setActiveWorkspace,
  handleLeaveWorkspace,
}) => {
  const isCard = true;
  const history = useHistory();
  const dataWorkspace = [
    { id: '1', icon: <EvaluationIcon />, name: t('EVALUATION'), quantity: workspace.evaluations },
    { id: '2', icon: <MembersIcon />, name: t('MEMBERS'), quantity: workspace.members },
  ];

  return (
    <div
      data-testid="card-workspace"
      key={workspace.id}
      className="b-radius-8 bg-white size-card mr-5 mt-2 mb-3"
    >
      <div
        className="header-color"
        style={{
          backgroundColor: workspace.color || '#0E9CE2',
        }}
      />
      <div className="d-flex justify-content-between pt-1 px-3 mb-3 mt-2">
        <div
          style={{
            backgroundColor: workspace.color || '#0E9CE2',
          }}
          className="d-flex justify-content-center align-items-center box-icon p-1"
        >
          {determineIconType(workspace, isCard)}
        </div>
        <button
          data-testid={`button-star-${workspace.id}`}
          type="button"
          className="bg-transparent border-0 ml-auto"
          onClick={() =>
            !workspace.favorite ? addFavorite(workspace?.id) : removeFavorite(workspace?.id)
          }
        >
          {workspace.favorite ? (
            <StarComplete testid={`star-complete-${workspace.id}`} />
          ) : (
            <StarEmpty />
          )}
        </button>
      </div>
      <div className="overflow-hidden">
        <span className="px-3 font-montserrat font-weight-600 text-md w-90">
          {addTruncate(workspace.name, 25)}
        </span>
      </div>
      <div className="mt-4">
        {dataWorkspace.map((option) => (
          <div key={option.id} className="px-3 d-flex mt-2 align-items-center">
            {option.icon}
            <span className="ml-2 font-weight-500 font-montserrat text-sm">{option.name}</span>
            <span className="mx-2 font-weight-600 font-montserrat text-sm">{option.quantity}</span>
          </div>
        ))}
      </div>
      <div className="mt-6 d-flex justify-content-between">
        {workspace.workspaceRole === PartnerWorkspaceRole.OWNER ? (
          // TODO : Show button when exist the transfer ownership workspace function
          <div className="d-flex align-items-center">
            <OverlayTrigger placement="top" overlay={<Tooltip>{t('TRANSFER_OWNERSHIP')}</Tooltip>}>
              <button
                className="pl-3 pr-2 bg-transparent border-0 align-items-center"
                type="button"
                onClick={() => {
                  setActiveWorkspace(workspace.id);
                  history.push(CONFIGURATION_MEMBERS);
                }}
              >
                <TransferOwnership width="25" height="25" />
              </button>
            </OverlayTrigger>
            <div className="badge-role d-flex align-items-center">
              <span className="px-1 py-1 font-montserrat font-weight-bold text-xs text-badge-color-owner">
                {t(workspace.workspaceRole)}
              </span>
            </div>
          </div>
        ) : (
          <div className="ml-3 badge-role d-flex align-items-center">
            <span className="px-2 py-1 font-montserrat font-weight-bold text-xs text-badge-color">
              {t(workspace.workspaceRole)}
            </span>
          </div>
        )}
        <div
          className={`${
            workspace.workspaceRole === PartnerWorkspaceRole.OWNER ? '' : 'd-flex flex-row-reverse'
          }`}
        >
          {(workspace.workspaceRole === PartnerWorkspaceRole.OWNER ||
            workspace.workspaceRole === PartnerWorkspaceRole.ADMINISTRATOR) && (
            <OverlayTrigger placement="top" overlay={<Tooltip>{t('ADD_INVITATION')}</Tooltip>}>
              <button
                data-testid="add-invitation-button"
                type="button"
                className="border-0 bg-transparent mr-3"
                onClick={() => {
                  setActiveWorkspace(workspace.id);
                  history.push(CONFIGURATION_MEMBERS);
                }}
              >
                <AddInvitations width="17" height="17" />
              </button>
            </OverlayTrigger>
          )}
          {workspace.workspaceRole === PartnerWorkspaceRole.OWNER ? (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{t('CONFIGURATE_WORKSPACE')}</Tooltip>}
            >
              <button
                data-testid="config-workspace-button"
                type="button"
                className="border-0 bg-transparent mr-3"
                onClick={() => {
                  setActiveWorkspace(workspace.id);
                  history.push(CONFIGURATION);
                }}
              >
                <ConfigIcon width="17" height="17" fill="#757575" />
              </button>
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{t('CONFIGURATE_WORKSPACE')}</Tooltip>}
            >
              <button
                data-testid="leave-workspace-button"
                type="button"
                className="border-0 bg-transparent mr-3"
                onClick={() => handleLeaveWorkspace(workspace)}
              >
                <LeaveIcon width="17" height="17" />
              </button>
            </OverlayTrigger>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardWorkspace;
