import { useState, useEffect } from 'react';
import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';
import { getInvitationsFilters } from '../API/services/alkymersService';
import { setProfilesFilters } from '../redux/actions/profilesActions';
import {
  exportCsvInvitations,
  getScreeningInvitationsStatsCount,
  getScreeningsInvitations,
} from '../API/services/screeningService';
import { setSelectedSearch, setStageCandidates } from '../redux/actions/screeningActions';
import { t } from '../i18n/i18n';

const useFiltersDashboardSearch = (recruitSessionStageId) => {
  const [isLoading, setIsLoading] = useState(false);
  const [previousFilters, setPreviousFilters] = useState(undefined);
  const [loaderTable, setLoaderTable] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const { query } = useSelector((state) => state.profiles);
  const { keepSearchData, selectedSearch } = useSelector((state) => state.screenings);
  const languageKey = useSelector((store) => store.ux.language);
  const [isFirst, setIsFirst] = useState(true);
  const dispatch = useDispatch();

  const fetchAvailableFilters = async () => {
    setIsLoading(true);
    dispatch(setProfilesFilters({}));
    const filters = await getInvitationsFilters(recruitSessionStageId);
    const stats = await getScreeningInvitationsStatsCount(recruitSessionStageId);
    dispatch(setProfilesFilters(filters));
    dispatch(setSelectedSearch({ ...selectedSearch, stats }));
    setIsLoading(false);
  };

  const fetchStageCandidates = async () => {
    if (!keepSearchData) {
      if (query?.minScore === 0 && query?.maxScore === 100) {
        delete query.minScore;
        delete query.maxScore;
      }
      setLoaderTable(true);
      await getScreeningsInvitations({
        stage: recruitSessionStageId,
        ...(query.orderBy ? {} : { order: 'DESC', orderBy: 'SCORE' }),
        ...query,
      })
        .then((response) => {
          dispatch(setStageCandidates(response));
        })
        .finally(() => {
          setLoaderTable(false);
        });
    }
  };

  const exportCSV = async () => {
    setLoadingDownload(true);
    const permanentToast = notification.open({
      message: t('EXPORT_CSV_PLEASE_WAIT_DISCLAIMER'),
      type: 'info',
      duration: 0,
    });
    const response = await exportCsvInvitations(recruitSessionStageId, query, languageKey);
    if (response.error) {
      setLoadingDownload(false);
      notification.destroy(permanentToast);
      notification.open({
        message: t(`${response.error}`),
        type: 'error',
      });
    } else {
      const fileURL = window.URL.createObjectURL(new Blob([response]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${t('DOWNLOAD_INVITATIONS')}.xlsx`);
      fileLink.setAttribute('target', '_blank');
      document.body.appendChild(fileLink);
      fileLink.click();
      setLoadingDownload(false);
      notification.destroy(permanentToast);
      notification.open({
        message: t('EXPORT_CSV_SUCCESSFULLY_DOWNLOADED'),
        type: 'success',
      });
    }
  };

  useEffect(async () => {
    if (recruitSessionStageId) {
      setIsLoading(true);
      if (!keepSearchData) {
        await fetchAvailableFilters();
      }
      setIsLoading(false);
    }
  }, [recruitSessionStageId]);

  useEffect(() => {
    if (recruitSessionStageId) {
      if (!isFirst && !keepSearchData && !isEqual(previousFilters, query)) {
        fetchStageCandidates();
        setPreviousFilters(query);
      } else {
        setIsFirst(false);
      }
    }
  }, [recruitSessionStageId, query]);
  return {
    isLoading,
    loaderTable,
    exportCSV,
    loadingDownload,
  };
};

export default useFiltersDashboardSearch;
