import React from 'react';

import './NotificationPage.scss';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import { t } from '../../i18n/i18n';
import { Tabs } from '../../components/Tabs/Tabs';
import Notification from '../../components/Notification/Notification';

const NotificationPage = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <div className="w-100 h-100 bg-notification">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('NOTIFICATIONS_TITLE_PAGE')}</title>
      </Helmet>
      <BackgroundTop
        title={t('NOTIFICATIONS_TITLE_PAGE')}
        className="position-relative"
        titleClassname="text-xl"
      />
      <div className="d-flex w-100  h-75  ">
        <div className="card-notification d-flex flex-column  description-container mx-auto w-100 position-relative p-5  ">
          <Tabs
            tabs={[
              { text: t('NOTIFICATIONS_TITLE_PAGE'), component: <Notification user={user} /> },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationPage;
