import { t } from '../../i18n/i18n';

const SetEnglishLevel = (statusFilter) => {
  const englishFilterMap = statusFilter
    .filter((filter) => filter.name)
    .map((filter) => ({
      ...filter,
      name: t(`LANGUAGES_LEVELS_${filter?.name?.toUpperCase()}`),
    }));

  return englishFilterMap;
};

export default SetEnglishLevel;
