import { auth0Client } from '../../auth0/auth0';
import { t } from '../../i18n/i18n';
import './LoginButton.scss';

const LoginButton = () => (
  <button
    className="login-button border-0"
    onClick={() =>
      auth0Client.login({
        appState: { targetUrl: '/' },
        redirectUri: `${window.location.origin}/login`,
      })
    }
    type="button"
  >
    <span className="login-button-text text-white font-roboto font-weight-bold">
      {t('BUTTON_LOGIN')}
    </span>
  </button>
);

export default LoginButton;
