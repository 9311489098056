import { useInfiniteQuery } from '@tanstack/react-query';
import { notification } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  deleteJob,
  patchEditJobPost,
  getJobs,
  getStudyAreas,
  getJobsFiltersData,
} from '../../API/services/alkymersService';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import Job from '../../components/Job/Job';
import ModalCustom from '../../components/ModalCustom/ModalCustom';
import Spinner from '../../components/spinner/Spinner';
import useClickOutside from '../../hooks/useClickOutside';
import { t } from '../../i18n/i18n';
import { disableForWorkspaceRole } from '../../utils/workspaceRoleValidation';
import { PartnerWorkspaceRole } from '../../constants/enums';
import EmptyComponent from '../../components/EmptyComponent/EmptyComponent';
import Lottie from 'lottie-react';
import EmptyState from '../../assets/screening/EmptyState.json';
import MultipleSelect from '../../components/MultipleSelect/MultipleSelect';
import CalendarPicker from '../../components/CalendarDatePicker/CalendarDatePicker';
import ButtonFilters from '../../components/ButtonFilters/ButtonFilters';
import FiltersSkeleton from '../../components/ProfileSearch/FiltersSkeleton';
import { BiTrash } from 'react-icons/bi';

export const EditJob = ({ showModal, setShowModal, refetch }) => {
  const [loadingEdit, setLoadingEdit] = useState(false);

  const onClickEdit = async () => {
    try {
      setLoadingEdit(true);
      await patchEditJobPost(showModal?.jobPostData);
      refetch();
      setShowModal({ ...showModal, show: false, jobPostData: null });
      notification.open({
        message: t('LABEL_JOBPOST_OFFER_EDITED'),
        icon: <i className="fas fa-check" style={{ color: '#58DDA6' }} />,
      });
    } catch (error) {
      notification.open({
        message: t('LABEL_JOBPOST_OFFER_FAILED_ON_EDIT'),
        type: 'error',
      });
    } finally {
      setLoadingEdit(false);
    }
  };

  return (
    <ModalCustom
      datatestid="modal-edit-job"
      datatestidbuttonok="edit-job-button-ok"
      title={showModal?.title}
      subtitle={showModal?.subtitle}
      show={showModal?.show}
      setShow={(show) => setShowModal({ ...showModal, show, id: null })}
      onOkText={showModal?.onOkText}
      onCancelText={showModal?.onCancelText}
      onCancel={() => {
        setShowModal({ ...showModal, show: false, id: null });
      }}
      onOk={() => {
        onClickEdit();
      }}
      classModal="modal-create-workspace"
      showCross={true}
      isLoading={loadingEdit}
      onOkDisabled={loadingEdit}
    />
  );
};

export const DeleteJob = ({ showDeleteModal, setShowDeleteModal, refetch }) => {
  const [loadingDelete, setLoadingDelete] = useState(false);

  const onClickDelete = async () => {
    try {
      setLoadingDelete(true);
      await deleteJob(showDeleteModal.id);
      refetch();
      setShowDeleteModal({ show: false, id: null });
      notification.open({
        message: t('MODAL_JOBS_DELETE_SUCCESSFULLY'),
        icon: <i className="fas fa-check" style={{ color: '#58DDA6' }} />,
      });
    } catch (error) {
      notification.open({
        message: t('MODAL_JOBS_DELETE_ERROR'),
        type: 'error',
      });
    } finally {
      setLoadingDelete(false);
    }
  };

  return (
    <>
      <ModalCustom
        datatestid="modal-delete-job"
        datatestidbuttonok="delete-job-button-ok"
        title={t('JOB_POST_DELETE_TITLE')}
        subtitle={t('JOB_POST_DELETE_SUBTITLE')}
        show={showDeleteModal?.show}
        setShow={(show) => setShowDeleteModal({ show, id: null })}
        onOkText={t('DELETE')}
        onCancelText={t('CANCEL')}
        onCancel={() => {
          setShowDeleteModal({ show: false, id: null });
        }}
        onOk={() => {
          onClickDelete();
        }}
        classModal="modal-create-workspace"
        showCross={true}
        isLoading={loadingDelete}
        onOkDisabled={loadingDelete}
      />
    </>
  );
};

const fetchData = async ({ data, filters }) => {
  return await getJobs({ ...filters, limit: 6, offset: data.pageParam, isPremiumOrder: false });
};

const Jobs = (props) => {
  const { user, currentWorkspace } = useSelector((state) => state.auth);
  const cardRef = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState({
    show: false,
    id: null,
  });
  const [showEditModal, setShowEditModal] = useState({
    show: false,
    jobPostData: null,
    title: t('JOB_POST_EDIT_TITLE'),
    subtitle: t('JOB_POST_EDIT_SUBTITLE'),
    onOkText: t('EDIT'),
    onCancelText: t('CANCEL'),
  });

  const [currentWorkspaceRole, setCurrentWorkspaceRole] = useState('');

  const [filtersData, setFiltersData] = useState(null);
  const [loadingFiltersData, setLoadingFiltersData] = useState(true);

  const initialDates = {
    startDate: {
      day: new Date('01/01/2024'),
    },
    endDate: {
      day: new Date(),
    },
  };

  const [stageData, setStageData] = useState(initialDates);

  const [startDate, setStartDate] = useState(initialDates.startDate.day);
  const [endDate, setEndDate] = useState(initialDates.endDate.day);

  const initialFilter = {
    studyAreasId: [],
    seniorities: [],
    technologiesId: [],
    workModalities: [],
    modalities: [],
    rolesId: [],
    startDate: initialDates?.startDate?.day?.toISOString(),
    endDate: initialDates?.endDate?.day?.toISOString(),
    isDraft: true,
    isOpen: true,
    confidential: [true, false],
    partnerId: user?.id,
  };

  const [filters, setFilters] = useState(initialFilter);

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['jobs'],
    queryFn: (data) =>
      fetchData({
        data,
        filters,
      }),
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage?.collection?.length) return undefined;
      if (lastPage?.collection?.length < lastPage?.pagination?.limit) return undefined;
      return pages?.length * lastPage?.collection?.length;
    },
  });

  useEffect(() => {
    fetchJobsFiltersData();
    fetchNextPage();
  }, []);

  const fetchJobsFiltersData = async () => {
    const data = await getJobsFiltersData();
    for (const key in data) {
      data[key] = data[key]?.map((x) => {
        return {
          id: x?.id,
          displayName: t(x?.whitelabelName) ?? t(x?.displayName) ?? x?.displayName,
          whitelabelName: x?.whitelabelName,
        };
      });
    }
    setFiltersData(data);
    setLoadingFiltersData(false);
  };
  const onSelectFilters = (filterName, e) => {
    const { id, displayName } = e;
    const filter = filters[filterName];
    const selectedOption = filter?.includes(id);
    if (selectedOption) {
      setFilters({ ...filters, [filterName]: filter?.filter((x) => x !== id) });
    } else {
      setFilters({ ...filters, [filterName]: [...filter, id] });
    }
  };

  const onChangeDate = (date, value) => {
    setFilters({ ...filters, [date]: value });
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  const handleScroll = () => {
    const { clientHeight, scrollHeight, scrollTop } = document.documentElement;
    const bottomReached = scrollTop + clientHeight === scrollHeight;
    if (bottomReached && hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage();
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const currentWorkspaceItem = user?.workspaces?.find((w) => w.workspaceId === currentWorkspace);
    setCurrentWorkspaceRole(currentWorkspaceItem?.role);
  }, [currentWorkspace]);

  const shouldDisableOptions = disableForWorkspaceRole(currentWorkspaceRole, [
    PartnerWorkspaceRole.OWNER,
    PartnerWorkspaceRole.ADMINISTRATOR,
  ]);

  useClickOutside(cardRef, () => setOpenDropdown(false));

  const deleteDraft = (id) => {
    setShowDeleteModal({ show: true, id: id });
  };
  const editJob = ({ jobPostData, showModalData }) => {
    setShowEditModal({ ...showEditModal, ...showModalData, show: true, jobPostData: jobPostData });
  };

  const cleanFilters = () => {
    setFilters(initialFilter);
    setStageData(initialDates);
    setStartDate(initialDates.startDate.day);
    setEndDate(initialDates.endDate.day);
  };

  return (
    <div data-testid="jobs-page" className="container-fluid">
      <div className="position-relative w-100 d-flex flex-column overflow-md-hidden min-vh-100">
        <section className="container-fluid">
          <BackgroundTop
            title={t('VIEW_ALL_JOB_POST')}
            routes={[
              {
                text: 'Bolsa de trabajo',
                clickable: false,
              },
            ]}
            className="position-relative mb-3 py-3"
            titleClassname="text-xl"
            contained={false}
          />
        </section>
        <div className="position-relative d-flex justify-content-center w-100">
          <div class="d-flex justify-content-center w-100">
            <div class="d-flex justify-content-center flex-wrap w-100">
              <div class="col-12 col-sm-10 col-md-10 col-lg-8 col-xl-6">
                {loadingFiltersData ? (
                  <>
                    <FiltersSkeleton dashboard />
                    {/* <Spinner show={loadingFiltersData} /> */}
                  </>
                ) : (
                  <section
                    data-testid="jobs-filters"
                    className="d-flex flex-wrap align-items-center justify-content-start w-100 my-4"
                  >
                    <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                      <div className="d-flex">
                        <div className="d-flex flex-column ">
                          <span className={`font-montserrat text-xs font-weight-bold ml-2`}>
                            {t('CREATE_EVALUATION_START_DATE_DATEPICKER_PLACEHOLDER')}
                          </span>
                          <CalendarPicker
                            stageData={stageData}
                            dateState={{ date: startDate, setDate: setStartDate }}
                            dateType="startDate"
                            setStageData={(value) => {
                              setStageData({ ...stageData, startDate: value?.startDate });
                              onChangeDate('startDate', value?.startDate?.day);
                            }}
                            buttonClassNames="text-nowrap"
                            iconClassNames="ml-4 pl-2"
                            showIconAlways
                            placeholder={t('CREATE_EVALUATION_START_DATE_DATEPICKER_PLACEHOLDER')}
                          />
                        </div>
                        <div className="d-flex flex-column  ">
                          <span className={`font-montserrat text-xs font-weight-bold ml-2`}>
                            {t('CREATE_EVALUATION_END_DATE_DATEPICKER_PLACEHOLDER')}
                          </span>
                          <CalendarPicker
                            stageData={stageData}
                            dateState={{ date: endDate, setDate: setEndDate }}
                            dateType="endDate"
                            setStageData={(value) => {
                              setStageData({ ...stageData, endDate: value?.endDate });
                              onChangeDate('endDate', value?.endDate?.day);
                            }}
                            buttonClassNames="text-nowrap create-evaluation-datepickers"
                            iconClassNames="ml-4 pl-2"
                            showIconAlways
                            placeholder={t('CREATE_EVALUATION_END_DATE_DATEPICKER_PLACEHOLDER')}
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="d-flex align-items-center justify-content-end w-100 ">
                          <ButtonFilters
                            text={t('SCREENING_SEARCH_OPEN')}
                            index={0}
                            selected={filters['isOpen']}
                            className="mr-2 px-3"
                            buttonSelected={!filters['isOpen'] ?? false}
                            setButtonSelected={() => {}}
                            onClick={() => {
                              setFilters({ ...filters, ['isOpen']: !filters['isOpen'] });
                            }}
                            key={t('SCREENING_SEARCH_OPEN')}
                          />
                          <ButtonFilters
                            text={t('SCREENING_SEARCH_DRAFT')}
                            index={1}
                            selected={filters['isDraft']}
                            className="mr-2 px-3"
                            buttonSelected={filters['isDraft'] ?? false}
                            setButtonSelected={() => {}}
                            onClick={() => {
                              setFilters({ ...filters, ['isDraft']: !filters['isDraft'] });
                            }}
                            key={t('SCREENING_SEARCH_DRAFT')}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-start align-self-stretch w-100 mt-4 min-h-100">
                      <MultipleSelect
                        title={t('JOB_LABEL_ROLE')}
                        classNames="mx-2 select-color drops-filter flex-grow-1 min-h-100 align-self-stretch"
                        buttonClassNames={
                          'bg-white shadow-sm min-h-100 d-flex align-items-center  justify-content-center'
                        }
                        onSelect={(e) => onSelectFilters('rolesId', e)}
                        selectedList={filters.rolesId}
                        options={filtersData?.roles ?? []}
                      />
                      <MultipleSelect
                        title={t('SENIORITY')}
                        classNames="mx-2 select-color drops-filter flex-grow-1 min-h-100 align-self-stretch"
                        buttonClassNames={
                          'bg-white shadow-sm min-h-100 d-flex align-items-center  justify-content-center'
                        }
                        onSelect={(e) => onSelectFilters('seniorities', e)}
                        selectedList={filters.seniorities}
                        options={filtersData?.seniorities ?? []}
                      />
                      <MultipleSelect
                        title={t('CONTRACT_MODALITY')}
                        classNames="mx-2 select-color drops-filter flex-grow-1 min-h-100 align-self-stretch"
                        buttonClassNames={
                          'bg-white shadow-sm min-h-100 d-flex align-items-center  justify-content-center'
                        }
                        onSelect={(e) => onSelectFilters('workModalities', e)}
                        selectedList={filters.workModalities}
                        options={filtersData?.workModalities ?? []}
                      />
                      <MultipleSelect
                        title={t('WORK_MODALITY')}
                        classNames="mx-2 select-color drops-filter flex-grow-1 min-h-100 align-self-stretch"
                        buttonClassNames={
                          'bg-white shadow-sm min-h-100 d-flex align-items-center  justify-content-center'
                        }
                        onSelect={(e) => onSelectFilters('modalities', e)}
                        selectedList={filters.modalities}
                        options={filtersData?.modalities ?? []}
                      />
                      {filtersData?.studyAreas && (
                        <MultipleSelect
                          title={t('STUDY_AREA')}
                          classNames="mx-2 select-color drops-filter flex-grow-1 min-h-100 align-self-stretch"
                          buttonClassNames={
                            'bg-white shadow-sm min-h-100 d-flex align-items-center  justify-content-center'
                          }
                          onSelect={(e) => onSelectFilters('studyAreasId', e)}
                          selectedList={filters.studyAreasId}
                          options={filtersData?.studyAreas ?? []}
                        />
                      )}
                    </div>
                    <div className="d-flex align-items-center justify-content-start mt-4">
                      <button
                        type="button"
                        onClick={cleanFilters}
                        className="btn btn-link font-montserrat text-xs font-weight-bold border-0 text-dark d-flex align-items-center"
                      >
                        <BiTrash className="mr-2" />
                        {t('QUALITATIVE_FILTERS_CLEAR')}
                      </button>
                    </div>
                  </section>
                )}

                <div className="mt-2">
                  <div data-testid="jobs-container" className="d-flex flex-column">
                    {data && (
                      <div data-testid="jobs-card-view-container">
                        {data?.pages?.map((group, i) => (
                          <React.Fragment key={i}>
                            {group?.collection?.length
                              ? group?.collection?.map((job, index) => (
                                  <Job
                                    key={'job-' + job.id}
                                    job={job}
                                    user={user}
                                    deleteDraft={deleteDraft}
                                    editJob={editJob}
                                    shouldDisableOptions={shouldDisableOptions}
                                  />
                                ))
                              : !isFetching &&
                                !isFetchingNextPage && (
                                  <div
                                    data-testid="jobs-posts-empty-screen"
                                    className="empty-state-container mx-auto"
                                  >
                                    <Lottie
                                      animationData={EmptyState}
                                      loop={false}
                                      className="my-5"
                                    />
                                    <EmptyComponent
                                      className="mt-3 py-4"
                                      title={t('JOB_POSTS_EMPTY_TITLE')}
                                      subtitle={t('JOB_POSTS_EMPTY_SUBTITLE')}
                                    />
                                  </div>
                                )}
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                    <div>{isFetchingNextPage ? <FiltersSkeleton /> : hasNextPage ? '' : ''}</div>
                    <div>{isFetching && !isFetchingNextPage ? <FiltersSkeleton /> : null}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DeleteJob
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          refetch={refetch}
        />
        <EditJob showModal={showEditModal} setShowModal={setShowEditModal} refetch={refetch} />
      </div>
    </div>
  );
};

Jobs.propTypes = {};

export default Jobs;
