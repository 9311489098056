const ArrowWithCircle = ({ className, rotate = false, fill = '#D9D9D9' }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 57 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transform={`rotate(${rotate ? '0' : '180'})`}
    className={className}
    style={{ pointerEvents: 'none', cursor: 'pointer !important' }}
  >
    <rect x="0.802734" y="0.5" width="56.1977" height="57.9992" rx="28.0988" fill={fill} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.2174 28.0997L30.0086 17.8934C29.7458 17.6306 29.5981 17.2741 29.5981 16.9024C29.5981 16.5307 29.7458 16.1742 30.0086 15.9113C30.2715 15.6485 30.628 15.5008 30.9997 15.5008C31.3714 15.5008 31.7279 15.6485 31.9907 15.9113L44.5888 28.5094C44.7191 28.6394 44.8225 28.7939 44.8931 28.9639C44.9637 29.134 45 29.3163 45 29.5004C45 29.6845 44.9637 29.8668 44.8931 30.0369C44.8225 30.207 44.7191 30.3614 44.5888 30.4915L31.9907 43.0895C31.7279 43.3523 31.3714 43.5 30.9997 43.5C30.628 43.5 30.2715 43.3523 30.0086 43.0895C29.7458 42.8267 29.5981 42.4702 29.5981 42.0985C29.5981 41.7267 29.7458 41.3702 30.0086 41.1074L40.2193 30.8992L14.2021 30.8992C13.8308 30.8992 13.4748 30.7518 13.2123 30.4893C12.9498 30.2268 12.8023 29.8707 12.8023 29.4995C12.8023 29.1282 12.9498 28.7722 13.2123 28.5097C13.4748 28.2472 13.8308 28.0997 14.2021 28.0997L40.2174 28.0997Z"
      fill="white"
    />
  </svg>
);

export default ArrowWithCircle;
