const PhoneIcon = ({ color = '#FD7779', className }) => (
  <svg
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_5727_32663)">
      <path
        d="M18.0223 23.7991C18.057 23.6915 18.0735 23.5117 18.0559 23.4058C18.0289 23.2391 16.442 17.9636 16.3862 17.8527C16.2331 17.5515 15.9284 17.4181 15.5953 17.5088C15.5469 17.5213 15.0942 17.745 14.5885 18.0041C13.6369 18.4929 13.5985 18.509 13.4083 18.5034C13.2158 18.4987 12.9892 18.4019 12.8175 18.2517C12.6237 18.0802 12.4093 17.7756 12.2117 17.3913C11.9484 16.8776 11.9218 16.7963 11.2217 14.4327L10.5541 12.1878L10.4571 11.6531C10.2647 10.6016 10.2216 9.98528 10.3084 9.53543C10.3398 9.36844 10.344 9.35812 10.4472 9.17941C10.5605 8.98314 10.6236 8.91533 10.7637 8.83877C10.7961 8.82128 11.2481 8.69077 11.7653 8.5511C12.2825 8.41143 12.7357 8.28166 12.7729 8.2648C13.1002 8.10701 13.3034 7.6988 13.2361 7.34084C13.2118 7.21402 11.6383 1.95687 11.592 1.8472C11.4564 1.5306 11.0988 1.37307 10.7607 1.48424C10.6872 1.50776 9.16193 2.35259 8.84038 2.54777C8.33406 2.85541 7.74852 3.579 7.34202 4.40169C6.19957 6.71852 6.06447 10.0127 6.95834 13.8434C7.64422 16.7861 8.71234 19.4878 9.9361 21.3772C10.708 22.5696 11.5268 23.4466 12.4751 24.0962C13.4379 24.7563 14.6015 25.0835 15.4757 24.9393C15.6193 24.9159 17.4814 24.3506 17.5911 24.2969C17.7141 24.2381 17.8397 24.1213 17.9231 23.9887C17.9644 23.9231 18.0085 23.8379 18.0223 23.7991Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_5727_32663">
        <rect
          width="18.9778"
          height="18.9778"
          fill="white"
          transform="translate(26 10.0105) rotate(120)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default PhoneIcon;
