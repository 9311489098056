import React, { useEffect, useState } from 'react';
import CheckIcon from '../../assets/login/CheckIcon';
import SearchIcon from '../../assets/screening/SearchIcon';
import { t } from '../../i18n/i18n';
import Spinner from '../spinner/Spinner';

const ResetTestgroupStep1 = ({
  companies,
  fetchCompanies,
  loading,
  selectedCompany,
  handleSelectCompany,
  handleNextStep,
}) => {
  const [search, setSearch] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchCompanies({ search });
  };

  useEffect(() => {
    fetchCompanies({ search });
  }, []);

  return (
    <>
      <div className="d-flex flex-column">
        <span className="font-montserrat font-weight-bold text-base mt-2 mb-3">
          {t('RESET_TESTGROUP_STEP_1_TITLE')}
        </span>
        <form
          onSubmit={handleSubmit}
          className={`my-1 my-sm-0 rounded transition-2-ms p-2 d-flex align-items-center ml-auto
        ${
          search?.length ? 'tests-search-input-full bg-white' : 'tests-search-input-empty bg-gray'
        }`}
        >
          <SearchIcon color={search?.length ? '#1aacf0' : '#424242'} />
          <input
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            className="font-montserrat text-xs bg-transparent border-0 outline-none ml-2"
            placeholder={t('RESET_TESTGROUP_STEP_1_SEARCH_PLACEHOLDER')}
            type="text"
          />
        </form>
        <div className="w-100 d-flex flex-column mt-2 reset-testgroup-lists-container overflow-auto">
          {loading ? (
            <Spinner show />
          ) : (
            companies?.collection?.map((company) => (
              <button
                key={company?.id}
                type="button"
                onClick={() => handleSelectCompany(company.id)}
                className={`bg-transparent tech-button-border my-1 px-2 py-3 d-flex justify-content-between align-items-center ${
                  selectedCompany === company.id ? 'selected-border' : 'unselected-border'
                }`}
              >
                <span className="font-montserrat font-weight-bold text-muted text-xs">
                  {company.displayName}
                </span>
                {selectedCompany === company.id && (
                  <CheckIcon className="mr-1" width="19" height="19" />
                )}
              </button>
            ))
          )}
        </div>
      </div>
      <div className="d-flex flex-grow-1 justify-content-end">
        <button
          onClick={handleNextStep}
          disabled={!selectedCompany}
          className={`align-self-end mt-4 px-3 py-1 rounded-pill font-roboto text-white text-sm font-weight-bold border-0 outline-none ${
            !selectedCompany ? 'bg-disabled' : 'bg-maya-blue'
          }`}
          type="button"
        >
          {t('RESET_TESTGROUP_NEXT_BUTTON')}
        </button>
      </div>
    </>
  );
};

export default ResetTestgroupStep1;
