export const TrackEvent = (action, data) => {
  if (process.env.RAZZLE_RUNTIME_ENABLE_SEGMENT === 'true') {
    analytics.track(`[${process.env.RAZZLE_RUNTIME_ENV}]: ${action}`, data);
  }
};

export const PageLoad = (pathname, properties = {}) => {
  if (process.env.RAZZLE_RUNTIME_ENABLE_SEGMENT === 'true') {
    analytics.page(`[${process.env.RAZZLE_RUNTIME_ENV}]: ${pathname}`, properties);
  }
};

export const Identify = (id, data) => {
  if (process.env.RAZZLE_RUNTIME_ENABLE_SEGMENT === 'true') {
    analytics.identify(`[${process.env.RAZZLE_RUNTIME_ENV}]: ${id}`, data);
  }
};
