import { useSelector } from 'react-redux';
import { getLocalStorageWorkspace } from '../utils/workspaceUtils';

const useMissingData = () => {
  const { user } = useSelector((state) => state.auth);
  const requiredFieldsLength = 5;

  const requiredFieldsCompleted = [
    user.firstName,
    user.lastName,
    user.email,
    getLocalStorageWorkspace()?.companyLogo,
    getLocalStorageWorkspace()?.companyPrimaryColor,
  ].filter((index) => index !== null);

  // completed / required * 100%
  return (requiredFieldsCompleted.length / requiredFieldsLength) * 100;
};

export { useMissingData };
