import React from 'react';

const InputArrow = ({ className }) => (
  <svg
    className={className}
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5746 0.705353H1.40053C0.755462 0.705353 0.43293 1.48606 0.888092 1.94122L4.47815 5.52826C4.75848 5.81161 5.21665 5.81161 5.5 5.52826L9.08704 1.94122C9.5422 1.48606 9.21967 0.705353 8.5746 0.705353Z"
      fill="#4F4F4F"
    />
  </svg>
);

export default InputArrow;
