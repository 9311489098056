import { SET_JOBPOST_DATA, RESET_JOBPOST_DATA, EDIT_JOBPOST_DATA } from '../actionTypes';

const initialState = {
  newJobPost: {
    title: '',
    description: '',
    locationsToOffer: [],
    jobPostPlan: false,
    salaryInCentsMin: '',
    salaryInCentsMax: '',
    currency: '',
    isFree: true,
    confidential: false,
  },
};

function jobsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_JOBPOST_DATA: {
      return { ...state, ...action.payload };
    }
    case EDIT_JOBPOST_DATA: {
      return { ...state, newJobPost: { ...state.newJobPost, ...action.payload.newJobPost } };
    }
    case RESET_JOBPOST_DATA: {
      return initialState;
    }
    default:
      return state;
  }
}

export default jobsReducer;
