import AudioFileIcon from '../../assets/testgroupABM/AudioFileIcon';
import { TestFileType } from '../../constants/enums';
import { t } from '../../i18n/i18n';
import { fileToObject } from '../../utils/fileToObject';

const UploadTestAudio = ({ addFile, id }) => (
  <div data-testid="upload-test-audio" className="d-flex">
    <label
      data-testid="upload-test-audio-label"
      className="text-purple text-xs font-montserrat font-weight-500 cursor-pointer m-0"
      htmlFor={`audio-${id}`}
    >
      <AudioFileIcon className="mr-1" /> {t('CREATE_TEST_GROUP_ADD_AUDIO')}
    </label>
    <input
      data-testid="upload-test-audio-input"
      onChange={(e) => addFile(fileToObject(e.target.files[0]), TestFileType.AUDIO)}
      type="file"
      accept="audio/*"
      id={`audio-${id}`}
      className="d-none"
      value=""
    />
  </div>
);

export default UploadTestAudio;
