import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import {
  getStudyAreas,
  getCountries,
  uploadJobFileDescription,
  postNewJobPost,
  getAllTechnologies,
  getJobPost,
  patchEditJobPost,
} from '../API/services/alkymersService';
import { getCustomSearchs, getRolesFilters } from '../API/services/screeningService';
import {
  Modality,
  WorkModality,
  Seniority,
  JobPostRequiredFields,
  Currencies,
  TimeFrequency,
} from '../constants/enums';
import { regexUrlScreenings } from '../constants/regexValidations';
import { t } from '../i18n/i18n';
import { setNewJobPostData, editJobPostData } from '../redux/actions/jobActions';

const useCreateJobPost = () => {
  const { workspacesList, currentWorkspace } = useSelector((state) => state.auth);
  const [studyAreaOptions, setStudyAreaOptions] = useState([]);
  const [roleOptions, setRolesOptions] = useState([]);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [technologiesOptions, setTechnologiesOptions] = useState([]);
  const [screeningOptions, setscreeningOptions] = useState([]);
  const [preventReload, setPreventReload] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [workspace, setWorkspace] = useState({});
  const dispatch = useDispatch();
  const { newJobPost } = useSelector((state) => state.jobs);
  const fetchStudyAreas = async () => {
    await getStudyAreas()
      .then((res) => {
        setStudyAreaOptions(res);
        return res;
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  useEffect(() => {
    const actualWorkspace =
      workspacesList.find((workspace) => workspace.id === currentWorkspace) ??
      JSON.parse(localStorage.getItem('workspaceInfo'));
    setWorkspace({
      id: actualWorkspace?.id,
      name: actualWorkspace?.name,
      company: {
        id: actualWorkspace?.companyId,
        name: actualWorkspace?.companyName,
        logo: actualWorkspace?.companyLogo,
        primaryColor: actualWorkspace?.companyPrimaryColor,
      },
    });
  }, [currentWorkspace]);

  const fetchRolesOptions = async () => {
    await getRolesFilters()
      .then((res) => {
        setRolesOptions(res);
        return res;
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  const fetchCountryOptions = async () => {
    await getCountries()
      .then((res) => {
        setCountriesOptions(res.filter((country) => country?.cities?.length > 0));
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  const fetchTechnologiesOptions = async () => {
    await getAllTechnologies()
      .then((res) => {
        setTechnologiesOptions(res);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchRecruitSessionsOpen = async () => {
    try {
      const rsOpen = await getCustomSearchs({ offset: 0, active: true, limit: 150 });
      const data =
        rsOpen.collection.length > 0
          ? rsOpen.collection.map((rs) => ({
              displayName: rs.recruitSessionStageName,
              id: rs.invitationLink,
              active: Boolean(rs.isActive),
            }))
          : [
              {
                displayName: t('JOBPOST_FIELD_EMPTY_SCREENINGS_OPTIONS'),
                active: true,
              },
            ];
      setscreeningOptions(data);
    } catch (e) {
      console.log(e);
    }
  };

  const ModalityOptions = [
    {
      id: Modality.PARTTIME,
      name: t('JOBPOST_WORK_MODALITY_PARTTIME'),
    },
    {
      id: Modality.FULLTIME,
      name: t('JOBPOST_WORK_MODALITY_FULLTIME'),
    },
    {
      id: Modality.VOLUNTEER,
      name: t('JOBPOST_WORK_MODALITY_VOLUNTEER'),
    },
    {
      id: Modality.CONTRACTOR,
      name: t('JOBPOST_WORK_MODALITY_CONTRACTOR'),
    },
    {
      id: Modality.PER_HOUR,
      name: t('JOBPOST_WORK_MODALITY_PER_HOUR'),
    },
    {
      id: Modality.INTERNSHIP,
      name: t('JOBPOST_WORK_MODALITY_INTERSHIP'),
    },
    {
      id: Modality.NIGHTLY,
      name: t('JOBPOST_WORK_MODALITY_NIGTHLY'),
    },
  ];

  const WorkModalityOptions = [
    {
      id: WorkModality.REMOTE,
      name: t('LABEL_JOBPOST_WORK_MODE_REMOTE'),
    },
    {
      id: WorkModality.HIBRID,
      name: t('LABEL_JOBPOST_WORK_MODE_HIBRID'),
    },
    {
      id: WorkModality.ON_SITE,
      name: t('LABEL_JOBPOST_WORK_MODE_ON_SITE'),
    },
  ];

  const SeniorityLevelOptions = [
    {
      id: Seniority.TRAINEE,
      name: t('LABEL_JOBPOST_SENIORITY_TRAINEE'),
    },
    {
      id: Seniority.JUNIOR,
      name: t('LABEL_JOBPOST_SENIORITY_JUNIOR'),
    },
    {
      id: Seniority.SEMI_SENIOR,
      name: t('LABEL_JOBPOST_SENIORITY_SEMI_SENIOR'),
    },
    {
      id: Seniority.SENIOR,
      name: t('LABEL_JOBPOST_SENIORITY_SENIOR'),
    },
    {
      id: Seniority.TECH_LEAD,
      name: t('LABEL_JOBPOST_SENIORITY_TECH_LEAD'),
    },
  ];

  const requiredJobPostFields = [
    {
      id: JobPostRequiredFields.TITLE,
      displayName: t('JOBPOST_FIELD_REQUIRED_TITLE'),
    },
    {
      id: JobPostRequiredFields.INDUSTRY,
      displayName: t('JOBPOST_FIELD_REQUIRED_INDUSTRY'),
    },
    {
      id: JobPostRequiredFields.MODALITY,
      displayName: t('JOBPOST_FIELD_REQUIRED_MODALITY'),
    },
    {
      id: JobPostRequiredFields.WORKMODALITY,
      displayName: t('JOBPOST_FIELD_REQUIRED_CONTRACT_MODALITY'),
    },
    {
      id: JobPostRequiredFields.ROLEID,
      displayName: t('JOBPOST_FIELD_REQUIRED_ROL'),
    },
    {
      id: JobPostRequiredFields.SCREENINGS,
      displayName: t('JOBPOST_FIELD_REQUIRED_SCREENINIGS'),
    },
    {
      id: JobPostRequiredFields.COUNTRY,
      displayName: t('JOBPOST_FIELD_REQUIRED_COUNTRY'),
    },
  ];

  const currenciesOptions = [
    {
      id: Currencies.ARS,
      displayName: Currencies.ARS,
      isoCode: 'ARG',
    },
    {
      id: Currencies.USD,
      displayName: Currencies.USD,
      isoCode: 'USA',
    },
    {
      id: Currencies.CLP,
      displayName: Currencies.CLP,
      isoCode: 'CHL',
    },
    {
      id: Currencies.EUR,
      displayName: Currencies.EUR,
      isoCode: 'EU',
    },
  ];

  const salaryFrequencyOptions = [
    {
      id: TimeFrequency.DAILY,
      displayName: t('JOBPOST_SALARY_FREQUENCY_DAILY'),
    },
    {
      id: TimeFrequency.WEEKLY,
      displayName: t('JOBPOST_SALARY_FREQUENCY_WEEKLY'),
    },
    {
      id: TimeFrequency.SEMI_MONTHLY,
      displayName: t('JOBPOST_SALARY_FREQUENCY_SEMI_MONTHLY'),
    },
    {
      id: TimeFrequency.MONTHLY,
      displayName: t('JOBPOST_SALARY_FREQUENCY_MONTHLY'),
    },
    {
      id: TimeFrequency.ANNUAL,
      displayName: t('JOBPOST_SALARY_FREQUENCY_ANNUAL'),
    },
  ];

  const getIsoCode = (currencyId) => {
    const currency = currenciesOptions.find((currency) => currency.id === currencyId);
    return currency?.isoCode;
  };

  const validateJobPostFields = () => {
    const jobPostOffer = newJobPost;
    let validated = true;
    requiredJobPostFields.forEach((field) => {
      if (
        !jobPostOffer[field.id] ||
        jobPostOffer[field.id] === '' ||
        jobPostOffer[field.id] === undefined ||
        jobPostOffer[field.id].length < 1
      ) {
        notification.open({
          message: `${t('LABEL_JOBPOST_CREATE_EMPTY_FIELD_SING')}: ${field.displayName}`,
          icon: <i className="fas fa-times" style={{ color: '#F11010' }} />,
        });
        validated = false;
      }
    });
    return validated;
  };

  const validateSalaryInput = (salaryInUnits) => {
    if (
      (/^\d+$/.test(salaryInUnits) && salaryInUnits.length < 11) ||
      salaryInUnits === null ||
      salaryInUnits === ''
    ) {
      return true;
    }
    notification.open({
      message: `${t('LABEL_JOBPOST_WRONG_SALARY_RANGE')}`,
      icon: <i className="fas fa-times" style={{ color: '#F11010' }} />,
    });
    return false;
  };

  const uploadJobDescriptionFile = async (e) => {
    setPreventReload(true);
    const fmData = new FormData();
    fmData.append('file', e.target.files[0]);
    const response = await uploadJobFileDescription(fmData);
    return response;
  };

  const handleNewTechsInputs = () => {
    const techsId = newJobPost?.technologyIds?.filter((id) => Number(id));
    handleAddJobOfferDetail('technologyIds', techsId);
  };

  const getJobPostToSave = () => {
    handleNewTechsInputs();
    if (newJobPost?.fileUrl?.trim().lenght === 0) {
      delete newJobPost.fileUrl;
    }
    const jobPostToSave = {
      ...newJobPost,
      companyId: workspace?.company?.id,
    };
    return jobPostToSave;
  };

  const handleCreateJobPost = async () => {
    const jobPost = getJobPostToSave();
    const response = await postNewJobPost(jobPost);
    return response;
  };

  const validateUrlScreenings = (url) => {
    notification.destroy();
    if (regexUrlScreenings.test(url)) {
      return true;
    }
    notification.open({
      message: `${t('LABEL_JOBPOST_WRONG_URL_SCREENING')}`,
      icon: <i className="fas fa-times" style={{ color: '#F11010' }} />,
    });
    return false;
  };

  const getJobPostById = async (id) => {
    const jobOffer = await getJobPost(id);
    return jobOffer;
  };

  const handleEditJobPost = async () => {
    const jobPost = getJobPostToSave();
    const response = await patchEditJobPost(jobPost);
    return response;
  };

  const handleAddJobOfferDetail = (key, payload) => {
    dispatch(editJobPostData({ [key]: payload }));
  };

  const handleAddJobOffer = (jobPostRecovered) => {
    dispatch(setNewJobPostData({ ...jobPostRecovered }));
  };

  const searchApplicattionsAmount = (jobTotalApplied) => {
    if (!jobTotalApplied) return 0;
    if (jobTotalApplied <= 20 && jobTotalApplied >= 0) return `${jobTotalApplied}`;
    if (jobTotalApplied > 20 && jobTotalApplied <= 50) return '+20';
    if (jobTotalApplied > 50 && jobTotalApplied <= 100) return '+50';
    if (jobTotalApplied > 100 && jobTotalApplied <= 500) return '+100';
    if (jobTotalApplied > 500 && jobTotalApplied <= 1000) return '+500';
    return '+1000';
  };

  return {
    studyAreaOptions,
    roleOptions,
    ModalityOptions,
    WorkModalityOptions,
    countriesOptions,
    SeniorityLevelOptions,
    technologiesOptions,
    currenciesOptions,
    preventReload,
    salaryFrequencyOptions,
    isLoading,
    screeningOptions,
    workspace,
    searchApplicattionsAmount,
    fetchStudyAreas,
    fetchRolesOptions,
    fetchCountryOptions,
    fetchTechnologiesOptions,
    uploadJobDescriptionFile,
    handleCreateJobPost,
    getJobPostById,
    validateJobPostFields,
    getIsoCode,
    handleEditJobPost,
    setPreventReload,
    validateSalaryInput,
    validateUrlScreenings,
    handleAddJobOfferDetail,
    handleAddJobOffer,
    setIsLoading,
    setscreeningOptions,
    fetchRecruitSessionsOpen,
  };
};

export default useCreateJobPost;
