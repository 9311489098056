import introJs from 'intro.js';
import { t } from '../../i18n/i18n';

const onboardingTour = (setOpen) => {
  const onboarding = introJs().setOptions({
    steps: [
      {
        title: `<div class="font-montserrat text-base mt-1">${t(
          'ONBOARDING_TOUR_ONE_TITLE'
        )}</div>`,
        intro: `<div class="d-flex flex-column">
            <span class="font-montserrat text-sm">
            ${t('ONBOARDING_TOUR_ONE_SUBTITLE')}
            </span>
            <span class="font-montserrat text-sm">
            ${t('ONBOARDING_TOUR_ONE_SUBTITLE_1')}
            </span>
            </div>`,
      },
      {
        title: `<div class="font-montserrat text-base mt-1">${t(
          'ONBOARDING_TOUR_DROPDOWN_WORKSPACE_TITLE'
        )}</div>`,
        intro: `<span class="font-montserrat text-sm">${t(
          'ONBOARDING_TOUR_DROPDOWN_WORKSPACE_SUBTITLE'
        )}</span>`,
        element: '#DROPDOWN_WORKSPACE',
      },
      {
        title: `<div class="font-montserrat text-base mt-1">${t(
          'ONBOARDING_TOUR_CREATE_EVALUATION_TITLE'
        )}</div>`,
        intro: `<span class="font-montserrat text-sm">${t(
          'ONBOARDING_TOUR_CREATE_EVALUATION_SUBTITLE'
        )}</span>`,
        element: '#MENU_ITEM_CREATE_EVALUATION',
      },
      {
        title: `<div class="font-montserrat text-base mt-1">${t(
          'ONBOARDING_TOUR_SEARCHES_TITLE'
        )}</div>`,
        intro: `<span class="font-montserrat text-sm">${t(
          'ONBOARDING_TOUR_SEARCHES_SUBTITLE'
        )}</span>`,
        element: '#MENU_ITEM_SEARCHES',
      },
      {
        title: `<div class="font-montserrat text-base mt-1">${t(
          'ONBOARDING_TOUR_TALENT_TITLE'
        )}</div>`,
        intro: `<span class="font-montserrat text-sm">${t(
          'ONBOARDING_TOUR_TALENT_SUBTITLE'
        )}</span>`,
        element: '#TALENTS',
      },
      {
        title: `<div class="font-montserrat text-base mt-1">${t(
          'ONBOARDING_TOUR_MEMBERS_TITLE'
        )}</div>`,
        intro: `<span class="font-montserrat text-sm">${t(
          'ONBOARDING_TOUR_MEMBERS_SUBTITLE'
        )}</span>`,
        element: '#MENU_ITEM_MEMBERS',
      },
      {
        title: `<div class="font-montserrat text-base mt-1">${t(
          'ONBOARDING_TOUR_WHITELABEL_TITLE'
        )}</div>`,
        intro: `<span class="font-montserrat text-sm">${t(
          'ONBOARDING_TOUR_WHITELABEL_SUBTITLE'
        )}</span>`,
        element: '#MENU_ITEM_PERSONALIZATION',
      },
      {
        title: `<div class="font-montserrat text-base mt-1">${t(
          'ONBOARDING_TOUR_PLAN_TITLE'
        )}</div>`,
        intro: `<span class="font-montserrat text-sm">${t('ONBOARDING_TOUR_PLAN_SUBTITLE')}</span>`,
        element: '#MENU_ITEM_PLANS_CONSUMPTION',
      },
      {
        title: `<div class="font-montserrat text-base mt-1">${t(
          'ONBOARDING_TOUR_NOTIFICATION_TITLE'
        )}</div>`,
        intro: `<span class="font-montserrat text-sm">${t(
          'ONBOARDING_TOUR_NOTIFICATION_SUBTITLE'
        )}</span>`,
        element: '#NOTIFICATIONS',
      },
      {
        title: `<div class="font-montserrat text-base mt-1">${t(
          'ONBOARDING_TOUR_END_LINKS_TITLE'
        )}</div>`,
        intro: `<span class="font-montserrat text-sm">${t(
          'ONBOARDING_TOUR_END_LINKS_SUBTITLE'
        )}</span>`,
        element: '#HEADER_HELP_LINKS',
      },
    ],
    nextLabel: t('ONBOARDING_TOUR_NEXT'),
    prevLabel: t('ONBOARDING_TOUR_BACK'),
    doneLabel: t('ONBOARDING_TOUR_END'),
    exitOnOverlayClick: false,
  });

  onboarding
    .onexit(() => {
      localStorage.setItem('isOnboarding', false);
    })
    .start();
  /* .onbeforechange(() => {
    setOpen(true);
  }) */

  return onboarding;
};

export default onboardingTour;
