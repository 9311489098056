import { useEffect, useRef, useState } from 'react';
import { getSubmissionEventsDetails } from '../../../../API/services/alkycheatService';
import useOnScreen from '../../../../hooks/useOnScreen';
import ContentItem from '../ContentItem/ContentItem';

const CollapseWrapper = ({
  listDate,
  submissionId,
  userId,
  testGroupName,
  addEventsSubmissions,
  screeningInstanceId,
  addSubmissionListDetail,
}) => {
  const [nextPageUrl, setNextPageUrl] = useState(listDate?.pageInfo?.next ?? undefined);
  const fetchNextUrlRef = useRef();
  const fetchNextUrl = useOnScreen(fetchNextUrlRef, '0px', true);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    setNextPageUrl(listDate?.pageInfo?.next);
  }, [listDate?.pageInfo]);

  const getNextPage = async () => {
    const queryString = new URLSearchParams(nextPageUrl.split('?')[1]);
    getSubmissionEventsDetails(
      submissionId,
      userId,
      screeningInstanceId,
      queryString.get('offset'),
      queryString.get('limit')
    ).then((r) => {
      const newItems = r.collection.map((item) => item?.submissionId ?? 'sandunga');
      addEventsSubmissions(testGroupName, newItems);
      addSubmissionListDetail(submissionId, r.collection);
      setNextPageUrl(r.pageInfo?.next ?? undefined);
      setFetched(true);
    });
  };

  useEffect(() => {
    if (!fetchNextUrl) {
      setFetched(false);
    }

    if (fetchNextUrl && !fetched && nextPageUrl) {
      getNextPage();
    }
  }, [fetchNextUrl]);

  return (
    <>
      {listDate?.collection?.map((item) => (
        <ContentItem timeSubmitted={item.timeSubmitted} type={item.type} />
      ))}
      <div ref={fetchNextUrlRef} />
    </>
  );
};

export default CollapseWrapper;
