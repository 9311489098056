const BannerNavigationSeparator = ({ className }) => (
  <svg
    className={className}
    width="6"
    height="8"
    viewBox="0 0 6 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.95112 3.52935C5.21143 3.78967 5.21143 4.21242 4.95112 4.47273L1.61909 7.80476C1.35877 8.06508 0.93602 8.06508 0.675705 7.80476C0.41539 7.54445 0.41539 7.1217 0.675705 6.86138L3.53709 4L0.677787 1.13862C0.417472 0.878303 0.417472 0.455551 0.677787 0.195236C0.938102 -0.0650787 1.36085 -0.0650787 1.62117 0.195236L4.9532 3.52727L4.95112 3.52935Z"
      fill="#737373"
    />
  </svg>
);

export default BannerNavigationSeparator;
