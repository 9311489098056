const BannerHomeIcon = ({ className }) => (
  <svg
    className={className}
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4751 5.98828C13.4751 6.41016 13.1241 6.74063 12.7263 6.74063H11.9774L11.9938 10.4953C11.9938 10.5586 11.9891 10.6219 11.9821 10.6852V11.0625C11.9821 11.5805 11.5632 12 11.046 12H10.6715C10.6458 12 10.62 12 10.5943 11.9977C10.5615 12 10.5288 12 10.496 12H9.73542H9.17377C8.65657 12 8.23767 11.5805 8.23767 11.0625V10.5V9C8.23767 8.58516 7.90301 8.25 7.48879 8.25H5.99103C5.57681 8.25 5.24215 8.58516 5.24215 9V10.5V11.0625C5.24215 11.5805 4.82325 12 4.30605 12H3.74439H2.99786C2.96275 12 2.92765 11.9977 2.89254 11.9953C2.86446 11.9977 2.83638 12 2.8083 12H2.43386C1.91666 12 1.49776 11.5805 1.49776 11.0625V8.4375C1.49776 8.41641 1.49776 8.39297 1.5001 8.37188V6.74063H0.748879C0.327634 6.74063 0 6.4125 0 5.98828C0 5.77734 0.0702074 5.58984 0.234025 5.42578L6.23442 0.1875C6.39823 0.0234375 6.58545 0 6.74927 0C6.91309 0 7.10031 0.046875 7.24072 0.164062L13.2177 5.42578C13.4049 5.58984 13.4985 5.77734 13.4751 5.98828Z"
      fill="#737373"
    />
  </svg>
);

export default BannerHomeIcon;
