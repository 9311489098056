import processQueryString, { processQueryStringAmpersand } from './processQueryString';

export const formatAppliedFilters = (filters) =>
  filters?.map((item) => `${item.type}=${item.id}${item.name ? `,${item.name}` : ''}`)?.join('&');

export const formatMinMaxFilters = (filters) =>
  Object.entries(filters)
    ?.map((item) => `${item[0]}=${item[1]}`)
    ?.join('&');

export const formatMinMaxUrlFilters = (location, type1, type2) =>
  Object.entries(processQueryString(location))
    .map((item) => ({
      type: item[0],
      value: item[1],
    }))
    .filter((item) => item.type === type1 || item.type === type2);

export const formatUrlFilters = (location) => {
  const query = [];
  Object.entries(processQueryStringAmpersand(unescape(location))).forEach((item) => {
    if (Array.isArray(item[1])) {
      item[1].forEach((subItem) =>
        query.push({
          type: item[0],
          id: subItem.split(',')[0],
          name: subItem?.split(',')[1],
        })
      );
    } else {
      query.push({
        type: item[0],
        id: item[1].split(',')[0],
        name: item[1]?.split(',')[1],
      });
    }
  });
  return query.filter(
    (item) =>
      item.type !== 'min' &&
      item.type !== 'max' &&
      item.type !== 'minEngagement' &&
      item.type !== 'maxEngagement'
  );
};
