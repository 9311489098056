import { processQueryStringAmpersand } from '../processQueryString';
import { capitalizeString } from '../stringUtils';

export const formatSearchData = (search, t) =>
  Object.entries(processQueryStringAmpersand(search?.queryString))
    .map((item) => ({
      type: item[0],
      id: item[1],
      name: ['country', 'institutes', 'secondary_technologies', 'technologies', 'and'].includes(
        item[0]
      )
        ? capitalizeString(search[item[0]]?.toString())
        : item[0] === 'english_level'
        ? t(`LANGUAGES_LEVELS_${search[item[0]]?.toString()?.toUpperCase()}`)
        : t(
            `PROFILES_FILTER_${item[0]?.toUpperCase()}_${search[item[0]]
              ?.toString()
              ?.toUpperCase()}`
          ),
    }))
    .filter((item) => item.type !== 'max_age' && item.type !== 'min_age');
