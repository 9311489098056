import { t } from '../../../i18n/i18n';
import './languageCollapsed.scss';

const LanguageCollapsed = ({ language: { language, level, certificateUrl } }) => (
  <div className="d-flex flex-row align-items-center position-relative w-full my-3">
    <div className="d-flex flex-row w-full">
      <div className="d-flex flex-column">
        <span className="font-montserrat font-italic text-sm font-weight-300 dashboard-group-name">
          {t('LANGUAGE_COLLAPSED_TITLE')}
        </span>
        <span className="font-montserrat font-weight-600 text-xs dashboard-group-name formation-collapsed-label-anchor">
          {t(language?.displayName)}
        </span>
      </div>
      <div className="d-flex flex-column language-container-margin-custom">
        <span className="font-montserrat font-italic text-sm font-weight-300 dashboard-group-name">
          {t('LANGUAGE_COLLAPSED_LEVEL')}
        </span>
        <span className="font-montserrat font-weight-600 text-xs dashboard-group-name formation-collapsed-label-anchor">
          {t(level?.displayName)}
        </span>
      </div>
    </div>
    {!!certificateUrl && (
      <a
        href={certificateUrl}
        target="_blank"
        rel="noreferrer"
        className="font-montserrat text-sm font-weight-600 position-absolute right-0 total-number-text"
      >
        {t('COLLAPSED_SEE_CERTIFICATE')}
      </a>
    )}
  </div>
);

export default LanguageCollapsed;
