import { enGB, es, pt } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';
import store from '../redux/store';

let langKey = 'es';
setDefaultOptions({ locale: 'es' });

const localeDictionary = {
  es,
  en: enGB,
  pt,
};

export const setLangKey = (key) => {
  langKey = key;
  setDefaultOptions({ locale: localeDictionary[key] });
};

export const t = (name, defaultEmpty = false) => {
  const texts = store.getState().auth.whitelabel?.texts;
  const language = langKey;
  const values = name?.props?.id ?? defaultEmpty ? '' : name;
  if (texts && texts[language] && texts[language][name]) {
    return texts[language][name];
  }
  if (texts && texts?.es && texts?.es[name]) {
    return texts?.es[name];
  }

  return values;
};
