import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import useOnScreen from '../../hooks/useOnScreen';
import { useElementSize } from '../../hooks/useElementSize';
import { t } from '../../i18n/i18n';
import ProfileListItem from '../ProfileListItem/ProfileListItem';
import { spaceSeparatedIncludes } from '../../utils/spaceSeparatedIncludes';
import OrderingTriangles from '../OrderingTriangles/OrderingTriangles';
import { Orders, PartnerWorkspaceRole } from '../../constants/enums';
import { setOrder } from '../../redux/actions/profilesActions';
import './ProfilesList.scss';
import { getRecruitSessionStageActive } from '../../API/services/alkymersService';
import { setNewSearchData } from '../../redux/actions/screeningActions';
import { disableForWorkspaceRole } from '../../utils/workspaceRoleValidation';

const ProfilesList = ({ className = '', profiles = [], fetchProfiles, nextUrl }) => {
  const dispatch = useDispatch();
  const fetchNextUrlRef = useRef();
  const fetchNextUrl = useOnScreen(fetchNextUrlRef, '0px', true);
  const [fetched, setFetched] = useState(false);
  const { ref: containerRef, size } = useElementSize();
  const ordering = useSelector((state) => state.profiles.ordering);
  const profilesLoading = useSelector((state) => state.profiles.loading);
  const [recruitSessionStages, setRecruitSessionStages] = useState([]);
  const { currentWorkspace, currentRole } = useSelector((state) => state.auth);

  useEffect(async () => {
    const data = await getRecruitSessionStageActive(currentWorkspace);
    setRecruitSessionStages(data.collection);
    dispatch(
      setNewSearchData({
        name: '',
        stagesQuantity: 1,
        stages: [],
        step: 1,
      })
    );
  }, [currentWorkspace]);

  const workspaceDisable = disableForWorkspaceRole(currentRole, [
    PartnerWorkspaceRole.OWNER,
    PartnerWorkspaceRole.ADMINISTRATOR,
  ]);

  const handleOrder = (key) => {
    if (!profilesLoading) {
      const toggle = key === ordering.orderBy;
      const order = ordering.order === Orders.ASC ? Orders.DESC : Orders.ASC;
      dispatch(setOrder({ order: toggle ? order : Orders.DESC, orderBy: key }));
    }
  };

  useEffect(() => {
    if (!fetchNextUrl) setFetched(false);
    if (fetchNextUrl && !fetched && nextUrl && profiles?.length) {
      fetchProfiles(nextUrl);
      setFetched(true);
    }
  }, [fetchNextUrl]);

  const indicators = [
    {
      name: 'PROFILES_LIST_SCORE',
      className: 'profiles-list-score-width ml-2',
      order: true,
      orderKey: 'TECHNICAL_SCORE',
    },
    { name: 'PROFILES_LIST_TECHNOLOGY', className: 'profiles-list-tech-width ml-2' },
    {
      name: 'PROFILES_LIST_ENGLISH',
      className: 'profiles-list-english-width ml-2',
      order: false,
      orderKey: 'ENGLISH_LEVEL',
    },
    { name: 'PROFILES_LIST_COUNTRY', className: 'profiles-list-country-width ml-2' },
    { size: '500', className: 'profiles-list-actions-width' },
  ];

  return (
    <>
      <div ref={containerRef} className="w-100" />
      <div
        className={`non-selectable bg-white px-2 py-3 px-md-3 rounded position-relative profiles-list-container ${className}`}
        data-size={size}
      >
        <div className="min-width-100">
          <div className="d-flex flex-row justify-content-between text-uppercase align-items-center px-3">
            {spaceSeparatedIncludes(size, '450') && (
              <div className="py-1 d-flex profiles-list-name-width pr-1" />
            )}
            <div className="profiles-list-right-titles flex-grow-1">
              <div className="d-flex justify-content-between align-items-center profiles-list-titles">
                {indicators.map(
                  (indicator, i) =>
                    spaceSeparatedIncludes(size, indicator.size ?? '0') && (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{t(indicator.name)}</Tooltip>}
                        key={i}
                      >
                        <div
                          className={`profile-list-data-item text-center ${indicator.className} ${
                            indicator.order ? 'cursor-pointer' : ''
                          }`}
                          {...(indicator.order
                            ? { onClick: () => handleOrder(indicator.orderKey) }
                            : {})}
                        >
                          <span className="position-relative">
                            <span className="font-roboto font-weight-bold overflow-hidden text-truncate mw-100 d-inline-block">
                              {t(indicator.name)}
                            </span>
                            {indicator.order && (
                              <OrderingTriangles
                                order={ordering.orderBy === indicator.orderKey && ordering.order}
                              />
                            )}
                          </span>
                        </div>
                      </OverlayTrigger>
                    )
                )}
              </div>
            </div>
          </div>
          {profiles.map((profile, i) => (
            <div className="profiles-list-item-container my-2" key={profile?.id}>
              <ProfileListItem
                key={i}
                name={`${profile?.firstName} ${profile?.lastName}`}
                firstName={profile?.firstName}
                lastName={profile?.lastName}
                percent={
                  profile?.profileScore?.totalEngagement
                    ? Math.round(profile.profileScore.totalEngagement)
                    : 0
                }
                profileId={profile?.id}
                email={profile?.email}
                english={profile?.languages ?? []}
                country={profile?.address}
                technology={profile?.profileScore?.technology}
                alkymer={profile}
                containerSize={size}
                recruitSessionStages={recruitSessionStages}
                disabled={workspaceDisable}
              />
            </div>
          ))}
          <div ref={fetchNextUrlRef} />
        </div>
      </div>
    </>
  );
};

export default ProfilesList;
