const UploadFile = () => {
  return (
    <svg width="31" height="27" viewBox="0 0 34 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0891 21.1765C24.5032 21.1765 24.0428 20.7106 24.0428 20.1176C24.0428 19.5247 24.5032 19.0588 25.0891 19.0588C28.5417 19.0588 31.3666 16.2 31.3666 12.7059C31.3666 9.21176 28.5417 6.35294 25.0891 6.35294H22.8919C22.5153 6.35294 22.1805 6.16235 21.9922 5.82353C20.653 3.49412 18.3094 2.11765 15.6937 2.11765C11.6552 2.11765 8.37 5.44235 8.37 9.52941C8.37 10.1224 7.90965 10.5882 7.32375 10.5882H6.2775C3.97575 10.5882 2.0925 12.4941 2.0925 14.8235C2.0925 17.1529 3.97575 19.0588 6.2775 19.0588C6.8634 19.0588 7.32375 19.5247 7.32375 20.1176C7.32375 20.7106 6.8634 21.1765 6.2775 21.1765C2.82488 21.1765 0 18.3176 0 14.8235C0 11.3294 2.82488 8.47059 6.2775 8.47059H6.34027C6.8634 3.70588 10.8601 0 15.6937 0C18.8116 0 21.7411 1.60941 23.4778 4.23529H25.11C29.7344 4.23529 33.48 8.02588 33.48 12.7059C33.48 17.3859 29.7344 21.1765 25.11 21.1765H25.0891ZM15.6938 27C15.1079 27 14.6475 26.6339 14.6475 26.1681V16.8466L11.2128 20.8144C10.795 21.297 10.1474 21.297 9.7296 20.8144C9.3118 20.3317 9.3118 19.5836 9.7296 19.101L14.9521 13.0679C15.37 12.5852 16.0175 12.5852 16.4354 13.0679L21.6579 19.101C22.0757 19.5836 22.0757 20.3317 21.6579 20.8144C21.449 21.0557 21.1774 21.1764 20.9267 21.1764C20.7898 21.1783 20.6539 21.1471 20.528 21.0848C20.4021 21.0224 20.2888 20.9303 20.1956 20.8144L16.74 16.8225V26.1681C16.74 26.6339 16.2796 27 15.6938 27Z"
        fill="#686868"
      />
    </svg>
  );
};

export default UploadFile;
