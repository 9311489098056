const pushOrCreate = (object, key, item) => {
  if (object[key]) {
    object[key].push(item);
  } else {
    object[key] = [item];
  }
};

const normalizeString = (string) => string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const modalSearcher = (grouped, text, searcher) => {
  text = normalizeString(text.toLowerCase());
  const keys = Object.keys(grouped);
  const filtered = {};

  keys.forEach((key) => {
    if (grouped[key]) {
      grouped[key].forEach((item) => {
        const itemText = normalizeString(item[searcher].toLowerCase());
        if (itemText.includes(text)) {
          pushOrCreate(filtered, key, item);
        }
      });
    }
  });

  return filtered;
};

export default modalSearcher;
