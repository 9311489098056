import './Input.scss';

const Input = ({ error, placeholder, value, onChange, className = '', name }) => (
  <div className={`d-flex flex-column justify-content-start ${className}`}>
    <input
      type="text"
      className={`px-3 rounded screening-input font-montserrat flex-grow-1 ${
        error && 'border-danger text-danger'
      }`}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value, e.target.name)}
    />
    {error && <span className="text-danger font-montserrat text-sm">{error}</span>}
  </div>
);

export default Input;
