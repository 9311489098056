import { funnelColors } from '../../../constants/colors';
import { t } from '../../../i18n/i18n';
import './FunnelChart.scss';

// the excluded values will not be rendered but
// will be considered for the calculation of percentages
export const excludedBars = ['invitationsSent'];

export default function FunnelChart({ values, withPercentagesLabel = false }) {
  const numbers = values.map(({ val }) => val);
  const maxNumber = Math.max(...numbers);

  return (
    <div className="h-100 d-flex flex-column justify-content-around">
      {values
        .filter((val) => !excludedBars.includes(val.name))
        .map(({ val, name }, index) => {
          const width = val === 0 ? 0 : index === 0 ? 100 : Math.round((val / maxNumber) * 100);

          return (
            <div key={`${val}-${name}`} className="d-flex align-items-center my-1">
              <div className="funnel-chart-content-label">
                <p className="m-0 font-montserrat font-weight-500">
                  {t(`CHART_FUNNEL_CONVERSIONS_${name}`)}
                </p>
              </div>
              <div className="d-flex justify-content-center funnel-chart-content-progress">
                <div
                  className="d-flex justify-content-center funnel-chart-progress-bar"
                  style={{
                    background: funnelColors[index].background,
                    color: funnelColors[index].color,
                    width: `${width}%`,
                  }}
                >
                  <p className="m-0 font-montserrat p-2 font-weight-600">
                    {withPercentagesLabel ? `${width}%` : val}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}
