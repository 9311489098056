import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Input, notification, Spin, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { deleteVideoById, uploadWelcomeVideoAsync } from '../../API/services/authService';
import Modal from '../Modal/Modal';

export default function VideoUploader({
  t,
  setWelcomeVideos,
  newScreening,
  deleteCustomWelcomeVideo,
  uploadingFile,
  setUploadingFile,
}) {
  const [newVideoTitle, setNewVideoTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const [videoId, setVideoId] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const getVideoTimeDuration = async (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        const { duration } = video;
        resolve(duration <= 300);
      };

      video.onerror = function (e) {
        const { error, code } = video;
        console.error('Video load error:', error);
        reject(code);
      };
      video.src = URL.createObjectURL(file);
    });
  };

  const validateFile = async (file) => {
    if (!file) return false;
    if (file.type !== 'video/mp4') {
      notification.open({
        message: t('CREATE_EVALUATION_WELCOME_VIDEO_INVALID_TYPE'),
        type: 'error',
        className: 'z-1340',
      });
      return false;
    }
    if (!file.size / 1024 / 1024 > 200) {
      notification.open({
        message: t('CREATE_EVALUATION_WELCOME_VIDEO_INVALID_SIZE'),
        type: 'error',
        className: 'z-1340',
      });
      return false;
    }
    const duration = await getVideoTimeDuration(file);
    if (!duration) {
      notification.open({
        message: t('CREATE_EVALUATION_WELCOME_VIDEO_INVALID_DURATION'),
        type: 'error',
        className: 'z-1340',
      });
      return false;
    }
    setFileList([file]);
    return true;
  };

  const handleRemove = async () => {
    try {
      const videoIdToDelete = videoId;
      await deleteVideoById(videoIdToDelete);
      deleteCustomWelcomeVideo(newVideoTitle);
      setFileList([]);
      setOpenDeleteModal(false);
      notification.open({
        message: t('CREATE_EVALUATION_WELCOME_VIDEO_DELETED_SUCCESS'),
        type: 'success',
        className: 'z-1340',
      });
    } catch (e) {
      notification.open({
        message: t('CREATE_EVALUATION_WELCOME_VIDEO_DELETED_ERROR'),
        type: 'error',
        className: 'z-1340',
      });
    }
  };

  const handleUpload = async (options) => {
    const { file } = options;
    if (await validateFile(file)) {
      try {
        setUploadingFile(true);
        const videoInfo = await uploadWelcomeVideoAsync(file);
        setVideoId(videoInfo.videoId);
        setWelcomeVideos({
          welcomeVideoUrl: videoInfo.videoUrl,
          welcomeVideoTitle: newVideoTitle,
        });
        notification.open({
          message: t('CREATE_EVALUATION_WELCOME_VIDEO_SUCCESS_UPLOAD'),
          type: 'success',
          className: 'z-1340',
        });
        setUploadingFile(false);
        return true;
      } catch (e) {
        handleRemove();
        notification.open({
          message: t('CREATE_EVALUATION_WELCOME_VIDEO_UPLOAD_ERROR'),
          type: 'error',
          className: 'z-1340',
        });
      }
      setUploadingFile(false);
      return false;
    }
    return false;
  };

  useEffect(() => {
    if (newScreening.welcomeVideoTitle && newScreening.welcomeVideoUrl) {
      setNewVideoTitle(newScreening.welcomeVideoTitle);
      setFileList([{ name: newScreening.welcomeVideoTitle }]);
      setVideoId(newScreening.welcomeVideoUrl?.split('/')[5]);
    }
  }, []);

  return (
    <div className="d-flex flex-column" key="upload-video-custom">
      <Modal setShow={setOpenDeleteModal} show={openDeleteModal}>
        <div className="d-flex flex-column justify-content-center p-4">
          <span className="mt-5 h3 font-weight-bold font-montserrat text-base d-flex flex-row">
            {t('CREATE_EVALUATION_WELCOME_VIDEO_DELETE_TITLE')}
          </span>
          <div className="mt-5 justify-content-between justify-content-center d-flex flex-row">
            <Button
              className="button-rigth-modal px-1 px-lg-3 justify-content-center"
              onClick={() => setOpenDeleteModal(false)}
            >
              <span className="px-3 py-1 text-button-left-modal font-roboto d-flex justify-content-center align-items-center">
                {t('CONFIRM_MODAL_CANCEL')}
              </span>
            </Button>
            <button
              type="button"
              className="p-1 px-3 hover-danger modal-buttons-border bg-red-danger dont-delete-draft-button-border border-red-danger text-red-danger font-roboto outline-none text-sm font-weight-700 mr-2"
              onClick={() => handleRemove()}
            >
              <span className="px-3 py-1 text-button-right-modal font-roboto d-flex justify-content-center align-items-center">
                {t('CONFIRM_MODAL_CONFIRM')}
              </span>
            </button>
          </div>
        </div>
      </Modal>
      <Input
        type="text"
        onChange={(e) => {
          setNewVideoTitle(e.target.value);
        }}
        value={newVideoTitle}
        disabled={fileList.length > 0}
        title={
          fileList.length > 0
            ? t('CREATE_EVALUATION_WELCOME_VIDEO_EDIT_BLOCKED_HELP')
            : t('CREATE_EVALUATION_WELCOME_VIDEO_TITLE_PLACEHOLDER_HELP')
        }
        placeholder={t('CREATE_EVALUATION_WELCOME_VIDEO_TITLE_PLACEHOLDER')}
      />
      <Upload
        name="file"
        className="mx-3 mt-1"
        customRequest={handleUpload}
        onRemove={() => setOpenDeleteModal(true)}
        fileList={fileList}
        maxCount={1}
        accept="video/mp4"
        disabled={uploadingFile}
      >
        <>
          {uploadingFile ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 30,
                    color: 'maya-blue',
                  }}
                  spin
                />
              }
            />
          ) : (
            <Button
              className={`${fileList.length > 0 && 'd-none'}`}
              disabled={!newVideoTitle}
              title={
                newVideoTitle
                  ? t('CREATE_EVALUATION_WELCOME_VIDEO_UPLOAD_PLACEHOLDER')
                  : t('CREATE_EVALUATION_WELCOME_VIDEO_UPLOAD_BLOCKED_HELP')
              }
              icon={<UploadOutlined />}
            >
              {t('CREATE_EVALUATION_WELCOME_VIDEO_UPLOAD_BUTTON')}
            </Button>
          )}
        </>
      </Upload>
    </div>
  );
}
