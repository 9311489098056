import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { editScreening, editTestgroup } from '../../../API/services/screeningService';
import EditPen from '../../../assets/screening/EditPen';
import GenericToggle from '../../../components/GenericToggle/GenericToggle';
import { t } from '../../../i18n/i18n';
import './ListItem.scss';
import NumberPill from '../NumberPill/NumberPill';

// todo - modificar API para que devuelva enabled en ambos casos
const ListItem = ({
  id,
  name,
  enabled,
  isEnabled,
  levelTag,
  categoryTag,
  isTestgroup,
  metrics,
}) => {
  const [itemEnabled, setItemEnabled] = useState(enabled ?? isEnabled);
  const [fetchLoading, setFetchLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setItemEnabled(enabled ?? isEnabled);
  }, [enabled, isEnabled]);

  const changeTestgroupVisibility = () => {
    if (!fetchLoading) {
      setFetchLoading(true);
      setItemEnabled((state) => !state);
      if (isTestgroup) {
        editTestgroup({
          enabled: !itemEnabled,
          id,
        }).catch(() => {
          notification.open({
            message: t('ABM_TOGGLE_ITEM_ERROR').replace('{name}', name),
            type: 'error',
            duration: 3,
          });
        });
      } else {
        editScreening(id, {
          isEnabled: !itemEnabled,
        }).catch(() => {
          notification.open({
            message: t('ABM_TOGGLE_ITEM_ERROR').replace('{name}', name),
            type: 'error',
            duration: 3,
          });
        });
      }
      setFetchLoading(false);
    }
  };

  return (
    <div className="abm-list-item-container b-radius-8 bg-white border-maya-blue">
      <span className="abm-list-item-name pr-3 text-truncate font-montserrat font-weight-600 text-sm my-auto h-fit">
        {name}
      </span>
      <div className="abm-list-item-filters-container d-flex">
        {isTestgroup && (
          <>
            <NumberPill number={metrics?.median} className="mx-2 abm-list-pill" />
            <NumberPill number={metrics?.average} className="mx-2 abm-list-pill" />
          </>
        )}

        <div className="d-flex justify-content-end mx-2 abm-list-tags-container">
          {Boolean(categoryTag) && (
            <div
              className="abm-list-item-category font-montserrat font-weight-700 text-truncate"
              data-testid="category-tag"
            >
              {categoryTag}
            </div>
          )}
          {Boolean(levelTag) && (
            <div
              className="abm-list-item-level font-montserrat font-weight-700 text-truncate"
              data-testid="level-tag"
            >
              {levelTag}
            </div>
          )}
        </div>

        <div className="abm-list-item-toggle-container">
          <GenericToggle
            className="abm-toggle-container"
            leftTextClassName="toggle-left-text font-weight-700"
            rightTextClassname="toggle-right-text font-weight-700"
            rightText={itemEnabled ? t('ABM_TOGGLE_ENABLED') : t('ABM_TOGGLE_DISABLED')}
            actionFunction={changeTestgroupVisibility}
            loading={fetchLoading}
            defaultActive={itemEnabled}
          />
        </div>

        <button
          className="bg-transparent border-0 outline-none mx-1"
          onClick={() =>
            history.push(`edit-testgroup/${id}?isScreening=${isTestgroup ? '0' : '1'}`)
          }
          type="button"
        >
          <EditPen />
        </button>
      </div>
    </div>
  );
};

export default ListItem;
