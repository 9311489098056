import React from 'react';
import { t } from '../../../i18n/i18n';
import './CreateJobPost.scss';
import JobPostForm from '../../../components/JobPostForm/JobPostForm';
import BackgroundTop from '../../../components/BackgroundTop/BackgroundTop';
import { CREATE_JOB_POST } from '../../../constants/routes';

const CreateJobPost = () => (
  <div className="w-100 mx-auto h-100 pl-3 pr-3">
    <div>
      <BackgroundTop
        height="3rem"
        title={t('CREATE_JOBPOST_ROUTE_TITLE')}
        className="position-relative"
        titleClassname="text-xl"
        routes={[
          {
            text: 'JOB_POST',
            clickable: false,
          },
        ]}
      />
    </div>
    <div className="position-relative new-job-container p-4 bg-white d-flex w-100 flex-column rounded mx-auto window-height">
      <span className="create-jobpost-title font-weight-600 font-montserrat">
        {t('CREATE_JOBPOST_SECTION_TITLE')}
        <JobPostForm Route={CREATE_JOB_POST} />
      </span>
    </div>
  </div>
);

export default CreateJobPost;
