import CameraLogo from '../../assets/proctoring/CameraLogo';
import CopyPasteLogo from '../../assets/proctoring/CopyPasteLogo';
import FullScreenLogo from '../../assets/proctoring/FullScreenLogo';
import MouseLogo from '../../assets/proctoring/MouseLogo';
import ShortcutLogo from '../../assets/proctoring/ShortcutLogo';

export const ProctoringSummary = {
  userCameraAlwaysOn: {
    logo: <CameraLogo />,
    text: 'PROCTORING_DETAIL_CAMERA_ENABLED_QUESTION',
  },
  userScreenAlwaysFullscreen: {
    logo: <FullScreenLogo />,
    text: 'PROCTORING_DETAIL_FULLSCREEN_ENABLED_QUESTION',
  },
  userMouseAlwaysInScreen: {
    logo: <MouseLogo />,
    text: 'PROCTORING_DETAIL_MOUSE_ON_SCREEN_QUESTION',
  },
  userDidntCopiedOrPasted: {
    logo: <CopyPasteLogo />,
    text: 'PROCTORING_DETAIL_COPY_AND_PASTE_QUESTION',
  },
  userDidntExecutedShortcuts: {
    logo: <ShortcutLogo />,
    text: 'PROCTORING_DETAIL_SHORTCUTS_QUESTION',
  },
};
