const InvitedIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="15.5" fill="#E4E6E7" stroke="#E6E6E6" />
    <path
      d="M9.5 10C8.67188 10 8 10.6719 8 11.5C8 11.9719 8.22188 12.4156 8.6 12.7L15.4 17.8C15.7562 18.0656 16.2438 18.0656 16.6 17.8L23.4 12.7C23.7781 12.4156 24 11.9719 24 11.5C24 10.6719 23.3281 10 22.5 10H9.5ZM8 13.5V20C8 21.1031 8.89688 22 10 22H22C23.1031 22 24 21.1031 24 20V13.5L17.2 18.6C16.4875 19.1344 15.5125 19.1344 14.8 18.6L8 13.5Z"
      fill="#AEB4B7"
    />
  </svg>
);

export default InvitedIcon;
